import { makeStyles, TablePagination, Theme, useMediaQuery } from "@material-ui/core";
import React, { ReactNode, useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

export interface ResponsiveTableColumn<T> {
	header: string;
	selector: (data: T) => ReactNode;
}

interface ResponsiveTableProps<T> {
	data: T[];
	columns: ResponsiveTableColumn<T>[];
	emptyMessage: string;
	onSelection: (selectedData: T) => void;
	// searchQuery: string | undefined;
	// filterFunction: (searchQuery: string | undefined, data: T[]) => T[];
}

export function ResponsiveTable<T>(props: ResponsiveTableProps<T>) {
	const { data, columns } = props;
	const classes = useStyles();
	const matches = useMediaQuery("screen and (max-width: 40em)");

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [resultCount, setResultCount] = useState(0);
	const [pagedData, setPagedData] = useState<T[]>([]);

	useEffect(() => setPage(0), [data]);

	useEffect(() => {
		setResultCount(data.length);
		const slicedData = rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;
		setPagedData(slicedData);
	}, [data, page, rowsPerPage]);

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Table className={classes.table}>
			<Thead>
				<Tr>
					{columns.map((c, i) => (
						<Th align="center" key={i}>
							{c.header}
						</Th>
					))}
				</Tr>
			</Thead>
			<Tbody>
				{matches && (
					<tr className={classes.noRowBorder}>
						<TablePagination
							rowsPerPageOptions={[10, 20, 50, { label: "All", value: -1 }]}
							colSpan={columns.length}
							count={resultCount}
							rowsPerPage={rowsPerPage}
							labelRowsPerPage={"Show"}
							page={page}
							SelectProps={{
								inputProps: { "aria-label": "rows per page" },
								native: true,
							}}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
                            classes={{
                                root: classes.pagingRoot
                            }}
						/>
					</tr>
				)}
				{pagedData.map((d, i) => (
					<Tr key={i} onClick={() => props.onSelection(d)} className={classes.tableRow}>
						{columns.map((c, i) => (
							<Td key={i} align="center" className={classes.tableData}>
								{c.selector(d)}
							</Td>
						))}
					</Tr>
				))}
				{pagedData.length === 0 && (
					<Tr className={classes.noRowBorder}>
						<Td colSpan={columns.length} style={{padding: 30}} align="center">
							{props.emptyMessage}
						</Td>
					</Tr>
				)}
				{/* {
					emptyRows > 0 && (
					<Tr style={{ height: 53 * emptyRows }}>
						<Td colSpan={colSpan} />
					</Tr>
				)} */}
				<tr className={classes.noRowBorder}>
					<TablePagination
						rowsPerPageOptions={[10, 20, 50, { label: "All", value: -1 }]}
						colSpan={columns.length}
						count={resultCount}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage={"Show"}
						page={page}
						SelectProps={{
							inputProps: { "aria-label": "rows per page" },
							native: true,
						}}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
                        classes={{
                            root: classes.pagingRoot
                        }}
					/>
				</tr>
			</Tbody>
		</Table>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	table: {
        borderCollapse: "collapse",
        width: "100%",
    },
    tableRow: {
		cursor: "pointer",
		"@media screen and (max-width: 40em)": {
            borderColor: `${theme.palette.divider} !important`
		},
        '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
         },
	},
	tableData: {
		paddingTop: 10,
		paddingBottom: 10,
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
    pagingRoot: {
        border: "unset !important",
    },
	noRowBorder: {
		"@media screen and (max-width: 40em)": {
			border: "unset !important",
		},
	},
}));
