import { Hidden, AppBar, Toolbar, IconButton, Grid, Typography, makeStyles, Drawer as DrawerMui } from "@material-ui/core";
import React, { useMemo } from "react";
import { GridGrow } from "../components/GridGrow";
import { UserAvatar } from "../components/UserAvatar";
import MainDrawerItems from "./MainDrawerItems";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import MenuIcon from "@material-ui/icons/Menu";
import { usePageTitle } from "../components/PageTitle";
import { useHistory, useRouteMatch } from "react-router-dom";
import BusinessDrawerItems from "./BusinessDrawerItems";
import { AppMainRoutes } from "./AppMainRoutes";
import { BusinessRoutes } from "./BusinessRoutes";
import { useAppUser } from "../providers/AppUserProvider";
import { UserClaim } from "../entities/auth/UserClaim";
import AdminDrawerItems from "./AdminDrawerItems";
import { AdminRoutes } from "./AdminRoutes";
import { useOfflineStatus } from "../providers/OfflineStatusProvider";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import { routes } from "../routes";
import { RouteBuilderArea } from "../pages/route-builder/RouteBuilderArea";

export function AppLayout() {
	const user = useAppUser();
	const history = useHistory();
	const { userClaims } = user;
	const businessRoute = useRouteMatch("/app/business");
	const adminRoute = useRouteMatch("/app/admin");
	const routeBuilderRoute = useRouteMatch(routes.routeBuilder.routeBuilderArea);

	const area = useMemo(() => {
		if (adminRoute && userClaims.some((c) => c === UserClaim.Super_Admin)) {
			return "admin";
		}
		if (businessRoute !== null && userClaims.some((c) => c === UserClaim.Admin || c === UserClaim.Super_Admin)) {
			return "business";
		}
		if (routeBuilderRoute) {
			return "route-builder";
		}
		return "app";
	}, [adminRoute, businessRoute, routeBuilderRoute, userClaims]);

	const goToDriverRoute = () => {
		if (user.driverId) {
			history.push(routes.app.resolve.driverRoutePage(user.driverId));
		}
	};

	const isOffline = useOfflineStatus();
	const classes = useStyles();
	const [title] = usePageTitle();

	const [mobileOpen, setMobileOpen] = React.useState(false);

	if (area === "route-builder") {
		return <RouteBuilderArea />;
	}

	return (
		<div
			style={{
				width: "100%",
				height: "100vh",
				zIndex: 1,
				overflow: "hidden",
			}}
		>
			<div
				style={{
					position: "relative",
					display: "flex",
					width: "100%",
					height: "100%",
				}}
			>
				{!user.appSignIn && (
					<>
						<Hidden mdUp>
							<AppBar className={classes.appBar} color="secondary">
								<Toolbar>
									<IconButton color="inherit" aria-label="open drawer" onClick={() => setMobileOpen((open) => !open)} className={classes.navIconHide}>
										<MenuIcon />
									</IconButton>
									<Grid container spacing={1} wrap="nowrap" alignItems="center">
										<Grid item>
											<Typography variant="h6" color="inherit" noWrap={true} style={{ maxWidth: "calc(100vw - 130px)" }}>
												{title}
											</Typography>
										</Grid>
										{user.driverId && (
											<Grid item>
												<IconButton onClick={goToDriverRoute}>
													<LocalShippingRoundedIcon />
												</IconButton>
											</Grid>
										)}
										<GridGrow />
										{isOffline && (
											<Grid item>
												<CloudOffIcon style={{ marginTop: 2 }} />
											</Grid>
										)}
										<Grid item>
											<UserAvatar />
										</Grid>
									</Grid>
								</Toolbar>
							</AppBar>
							<DrawerMui
								variant="temporary"
								anchor={"left"}
								open={mobileOpen}
								classes={{
									paper: classes.drawerPaper,
								}}
								onClose={() => setMobileOpen(false)}
								ModalProps={{
									keepMounted: true, // Better open performance on mobile.
								}}
							>
								{area === "app" && <MainDrawerItems onClose={() => setMobileOpen(false)} />}
								{area === "business" && <BusinessDrawerItems onClose={() => setMobileOpen(false)} />}
								{area === "admin" && <AdminDrawerItems onClose={() => setMobileOpen(false)} />}
							</DrawerMui>
						</Hidden>
						<Hidden smDown>
							<DrawerMui
								variant="permanent"
								open
								classes={{
									paper: classes.drawerPaper,
								}}
							>
								{area === "app" && <MainDrawerItems onClose={() => setMobileOpen(false)} />}
								{area === "business" && <BusinessDrawerItems onClose={() => setMobileOpen(false)} />}
								{area === "admin" && <AdminDrawerItems onClose={() => setMobileOpen(false)} />}
							</DrawerMui>
						</Hidden>
					</>
				)}
				<div className={classes.contentContainer}>
					<Hidden smDown>
						<div
							style={{
								paddingTop: 15,
								paddingBottom: 15,
								paddingRight: 25,
							}}
						>
							<Grid container justify="flex-end" alignItems="flex-end">
								{isOffline && (
									<Grid item>
										<CloudOffIcon style={{ marginRight: 15 }} />
									</Grid>
								)}
								<Grid item>
									<UserAvatar />
								</Grid>
							</Grid>
						</div>
					</Hidden>
					<div className={user.appSignIn ? classes.appWebViewContent : classes.content}>
						{area === "app" && <AppMainRoutes />}
						{area === "business" && <BusinessRoutes />}
						{area === "admin" && <AdminRoutes />}
					</div>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		position: "absolute",
	},
	navIconHide: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	drawerPaper: {
		width: 250,
		height: "100%",
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			width: 215,
			position: "relative",
		},
	},
	contentContainer: {
		backgroundColor: theme.palette.background.default,
		height: "100%",
		width: "100%",
		overflowY: "auto",
	},
	content: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 65px)",
		paddingTop: 5,
		paddingBottom: 25,
		paddingLeft: 25,
		paddingRight: 25,
		[theme.breakpoints.down("sm")]: {
			padding: 40,
			paddingTop: 15,
			paddingLeft: 5,
			marginTop: 56,
			height: "calc(100% - 56px)",
		},
	},
	appWebViewContent: {
		backgroundColor: theme.palette.background.default,
		width: "100%",
		height: "calc(100% - 65px)",
		paddingTop: 5,
		paddingBottom: 25,
		paddingLeft: 25,
		paddingRight: 25,
		[theme.breakpoints.down("sm")]: {
			padding: 15,
			height: "calc(100% - 5px)",
		},
	}
}));
