import {
	Box,
	Button,
	Card,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemText,
	Switch,
	TextField,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { useHistory } from "react-router-dom";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { GridGrow } from "../../components/GridGrow";
import { GasRegulator } from "../../entities/routing/GasRegulator";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { GasRegulatorService } from "../../services/routing/GasRegulatorService";
import { orderBy } from "../../utility/orderBy";
import { usePropaneTankDetailPage } from "./PropaneTankDetailPage";

export function TankRegulatorsCard() {
	const alert = useAlert();
	const history = useHistory();
	const context = usePropaneTankDetailPage();
	const { tankRegulators } = context;

	const [addRegulator, setAddRegulator] = React.useState(false);
	const [showRemoved, setShowRemoved] = React.useState(false);
	const orderedTankRegulators = React.useMemo(
		() => [...tankRegulators].filter((r) => (showRemoved ? true : r.dateRemoved == null)).sort(orderBy.date((r) => r.dateInstalled, "Descending")),
		[tankRegulators, showRemoved]
	);

	const onAdd = (tankRegulator: GasRegulator) => {
		context.saveTankRegulator(tankRegulator);
		setAddRegulator(false);
		alert.success("Tank regulator added");
	};

	return (
		<Card>
			<AddTankRegulatorDialog open={addRegulator} onClose={() => setAddRegulator(false)} onSave={onAdd} key={orderedTankRegulators.length} />
			<Grid container wrap="nowrap" alignItems="center">
				<Grid item>
					<CardHeader title="Tank Regulators" />
				</Grid>
				<GridGrow />
				<Grid item>
					<Box mr={1}>
						<FormControlLabel
							style={{ marginLeft: 0 }}
							control={<Switch checked={showRemoved} onChange={() => setShowRemoved(!showRemoved)} />}
							label="Show Removed"
							labelPlacement="start"
						/>
					</Box>
				</Grid>
			</Grid>
			{tankRegulators == null ? (
				<CenteredLoadingSpinner />
			) : (
				<List dense style={{ paddingBottom: 0 }}>
					{orderedTankRegulators.map((regulator, i) => (
						<React.Fragment key={regulator.id}>
							{i > 0 && <Divider component="li" />}
							<ListItem button onClick={() => history.push(routes.app.resolve.tankRegulatorDetailPage(regulator.tankId, regulator.id))}>
								<ListItemText
									primary={regulator.type}
									secondary={
										<>
											Installed {regulator.dateInstalled.toLocaleDateString()}
											{/* {regulator.dateRemoved && <Typography variant="overline"> [Removed {regulator.dateRemoved.toLocaleDateString()}]</Typography>} */}
											{regulator.dateRemoved && (
												<>
													<br />
													Removed {regulator.dateRemoved.toLocaleDateString()}
												</>
											)}
										</>
									}
								/>
							</ListItem>
						</React.Fragment>
					))}
				</List>
			)}
			<Button fullWidth size="small" variant="contained" style={{ borderRadius: 0 }} onClick={() => setAddRegulator(true)}>
				Add Regulator
			</Button>
		</Card>
	);
}

interface AddTankRegulatorDialogProps {
	open: boolean;
	onClose: () => void;
	onSave: (regulator: GasRegulator) => void;
}

function AddTankRegulatorDialog(props: AddTankRegulatorDialogProps) {
	const alert = useAlert();
	const context = usePropaneTankDetailPage();
	const { propaneTank } = context;
	const propaneTankId = propaneTank.id;

	const [type, setType] = React.useState("");
	const [serialNumber, setSerialNumber] = React.useState("");
	const [notes, setNotes] = React.useState("");
	const [dateInstalled, setDateInstalled] = React.useState<Date | null>(new Date());
	const [disabled, setDisabled] = React.useState(false);
	const disableSave = React.useMemo(() => type.trim() === "" || !dateInstalled, [type, dateInstalled]);

	const onSave = async () => {
		if (!dateInstalled) return;
		setDisabled(true);
		const result = await GasRegulatorService.create({
			tankId: propaneTankId,
			type,
			serialNumber,
			notes,
			dateInstalled,
		});
		setDisabled(false);
		if (result.success) {
			props.onSave(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSave();
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="sm">
			<DialogTitle>Add Tank Regulator</DialogTitle>
			<DialogContent>
				<form onSubmit={onSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField required label="Type" fullWidth variant="outlined" value={type} onChange={(e) => setType(e.target.value)} disabled={disabled} />
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Serial Number"
								fullWidth
								variant="outlined"
								value={serialNumber}
								onChange={(e) => setSerialNumber(e.target.value)}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField label="Notes" multiline fullWidth variant="outlined" value={notes} onChange={(e) => setNotes(e.target.value)} disabled={disabled} />
						</Grid>
						<Grid item xs={12}>
							<KeyboardDatePicker
								label="Date Installed"
								format="MM/dd/yyyy"
								required
								maxDate={new Date()}
								maxDateMessage="Cannot be in the future"
								fullWidth
								inputVariant="outlined"
								value={dateInstalled}
								onChange={(date) => setDateInstalled(date)}
								disabled={disabled}
							/>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="default" variant="outlined" disabled={disabled}>
					Cancel
				</Button>
				<GridGrow />
				<Button onClick={onSave} variant="contained" color="primary" disabled={disableSave}>
					Add Regulator
				</Button>
			</DialogActions>
		</Dialog>
	);
}
