import { OnlineProfile } from "../../entities/business/OnlineProfile";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreateOnlineProfileRequest {
    subdomain: string;
}

interface UpdateInvoiceEmailSettingsRequest {
    replyTo: string | null;
    bcc: string | null;
    subject: string;
    header: string;
    onlinePaymentNotificationEmail: string | null;
}

interface UpdatePayOnlineSettingsRequest {
    pageEnabled: boolean;
    addPaymentInstructionsToInvoice: boolean;
    addPaymentInstructionsToStatement: boolean;
}

const toUploadLogoRequest = (logoFile: File) => {
    const formData = new FormData();
    formData.append("logo", logoFile);
    return formData;
}

export const OnlineProfileService = {
    get: () => WebClient.GetOptional<OnlineProfile | null>(`${apiServer}/api/online-profiles`),
    create: (request: CreateOnlineProfileRequest) => WebClient.Post.Validated<OnlineProfile>(`${apiServer}/api/online-profiles`, request),
    uploadLogo: (logoFile: File) => WebClient.Put.Validated<OnlineProfile>(`${apiServer}/api/online-profiles/logo`, toUploadLogoRequest(logoFile)),
    updateInvoiceEmailSettings: (request: UpdateInvoiceEmailSettingsRequest) => WebClient.Put.Validated<OnlineProfile>(`${apiServer}/api/online-profiles/invoice-email`, request),
    updatePayOnlineSettings: (request: UpdatePayOnlineSettingsRequest) => WebClient.Put.Validated<OnlineProfile>(`${apiServer}/api/online-profiles/pay-online`, request),
    sendSampleInvoiceEmail: () => WebClient.Post.Unvalidated(`${apiServer}/api/online-profiles/sample-invoice`, {}),
};