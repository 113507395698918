import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Route, Switch } from "react-router-dom";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../components/ServerErrorView";
import { PropaneTank } from "../../entities/customer/PropaneTank";
import { GasRegulator } from "../../entities/routing/GasRegulator";
import { routes } from "../../routes";
import { PropaneTankService } from "../../services/customer/PropaneTankService";
import { GasRegulatorService } from "../../services/routing/GasRegulatorService";
import { NotFoundResult, ServerError } from "../../services/server/WebClient";

import { PropaneTankDetailView } from "./PropaneTankDetailView";
import { TankRegulatorDetailView } from "./regulators/TankRegulatorDetailView";
import { Customer } from "../../entities/customer/Customer";
import { CustomerService } from "../../services/customer/CustomerService";

interface PropaneTankDetailPageContext {
	propaneTank: PropaneTank;
	customer: Customer;
	tankRegulators: GasRegulator[];
	pageDisabled: boolean;
	updatePropaneTank: (propaneTank: PropaneTank) => void;
	updatePageDisabled: (disabled: boolean) => void;
	saveTankRegulator: (tankRegulator: GasRegulator) => void;
}

const PropaneTankDetailPageReactContext = React.createContext<PropaneTankDetailPageContext>({} as PropaneTankDetailPageContext);

export function usePropaneTankDetailPage() {
	return React.useContext(PropaneTankDetailPageReactContext);
}

export function PropaneTankDetailPage() {
	const params = useParams<{ propaneTankId: string; gasRegulatorId: string | undefined }>();

	const [propaneTank, setPropaneTank] = React.useState<PropaneTank>();
	const [customer, setCustomer] = React.useState<Customer>();
	const [tankRegulators, setTankRegulators] = React.useState<GasRegulator[]>();
	const [pageDisabled, setPageDisabled] = React.useState(false);
	const [serverError, setServerError] = React.useState<ServerError | NotFoundResult>();

	const customerId = useMemo(() => propaneTank?.customerId, [propaneTank]);

	const selectedRegulator = useMemo(() => tankRegulators?.find((r) => r.id === params.gasRegulatorId), [tankRegulators, params.gasRegulatorId]);

	React.useEffect(() => {
		async function fetchPropaneTank(propaneTankId: string) {
			const result = await PropaneTankService.get(propaneTankId);
			if (result.success) {
				setPropaneTank(result.data);
			} else {
				setServerError(result);
			}
		}
		if (params.propaneTankId) {
			fetchPropaneTank(params.propaneTankId);
		}
	}, [params.propaneTankId]);

	React.useEffect(() => {
		async function fetchCustomer(customerId: string) {
			const result = await CustomerService.get(customerId);
			if (result.success) {
				setCustomer(result.data);
			} else {
				setServerError(result);
			}
		}

		if (customerId) {
			fetchCustomer(customerId);
		}
	}, [customerId]);

	useEffect(() => {
		const fetchRegulators = async () => {
			const result = await GasRegulatorService.getTankRegulators(params.propaneTankId);
			if (result.success) setTankRegulators(result.data);
			else setServerError(result);
		};
		fetchRegulators();
	}, [params.propaneTankId]);

	const saveTankRegulator = async (tankRegulator: GasRegulator) => {
		setTankRegulators((regulators) => {
			const updatedRegulators = [...(regulators ?? [])];
			const index = updatedRegulators.findIndex((r) => r.id === tankRegulator.id);
			if (index >= 0) {
				updatedRegulators[index] = tankRegulator;
			} else {
				updatedRegulators.push(tankRegulator);
			}
			return updatedRegulators;
		});
	};

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!propaneTank || !tankRegulators || !customer) {
		return <OverlayLoadingScreen />;
	}

	return (
		<PropaneTankDetailPageReactContext.Provider
			value={{
				propaneTank,
				customer,
				pageDisabled,
				tankRegulators,
				updatePropaneTank: setPropaneTank,
				updatePageDisabled: setPageDisabled,
				saveTankRegulator,
			}}
		>
			<Switch>
				<Route exact path={routes.app.propaneTankDetailPage}>
					<PropaneTankDetailView />
				</Route>
				{selectedRegulator && (
					<Route exact path={routes.app.tankRegulatorDetailPage}>
						<TankRegulatorDetailView tankRegulator={selectedRegulator} />
					</Route>
				)}
			</Switch>
		</PropaneTankDetailPageReactContext.Provider>
	);
}
