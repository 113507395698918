import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Invoice } from "../../../entities/billing/Invoice";
import { InvoiceService } from "../../../services/billing/InvoiceService";
import { useAlert } from "../../../hooks/useAlert";


export function EditNotesDialog(props: { open: boolean; onClose: () => void; onUpdate: (invoice: Invoice) => void; invoice: Invoice }) {
	const alert = useAlert();
	const { open, onClose, onUpdate, invoice } = props;

	const [notes, setNotes] = useState(invoice.notes ?? "");
	const [disabled, setDisabled] = useState(false);

	const saveNotes = async () => {
		setDisabled(true);
		const result = await InvoiceService.update({invoiceId: invoice.id, notes});
		setDisabled(false);
		if (result.success) {
			onUpdate(result.data);
			alert.success("Notes updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Edit Notes</DialogTitle>
			<DialogContent>
				<TextField label="Notes" variant="outlined" multiline rows={4} fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined">
					Cancel
				</Button>
				<Button onClick={saveNotes} disabled={disabled} variant="contained" color="primary">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
