import {
	Grid,
	IconButton,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DeviceDetailCard } from "./DetailCards/DeviceDetailCard";
import { SensorDetailCard } from "./DetailCards/SensorDetailCard";
import { showTankSpecsDetailCard, TankSpecsDetailCard } from "./DetailCards/TankSpecsDetailCard";
import { BatteryDetailCard } from "./DetailCards/BatteryDetailCard";
import { GasDetailCard } from "./DetailCards/GasDetailCard";
import { OtherInfoDetailCard, showOtherInfoDetailCard } from "./DetailCards/OtherInfoDetailCard";
import { TankLevelsCard } from "./DetailCards/TankLevelsCard";
import { OtodataDevice } from "../../entities/routing/OtodataTankMonitor";
import { TankMonitorService } from "../../services/routing/TankMonitorService";
import { useAlert } from "../../hooks/useAlert";
import { CustomerTankMonitor } from "../../entities/routing/TankMonitor";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { AssignedCustomerCard } from "./DetailCards/AssignedCustomerCard";

interface OtodataDeviceDetailPageContext {
	device: OtodataDevice;
	dispatchProMonitor: CustomerTankMonitor | null;
	updateDevice: (device: OtodataDevice) => void;
	updateDispatchProMonitor: (tankMonitor: CustomerTankMonitor | null) => void;
}

const OtodataDeviceDetailPageReactContext = React.createContext<OtodataDeviceDetailPageContext>({} as OtodataDeviceDetailPageContext);

export const useOtodataDeviceDetailPage = () => React.useContext(OtodataDeviceDetailPageReactContext);

export function OtodataDeviceDetailPage() {
	const params = useParams<{ otodataDeviceId: string }>();
	const deviceId = useMemo(() => parseInt(params.otodataDeviceId), [params.otodataDeviceId]);

	const alert = useAlert();
	const [device, setDevice] = React.useState<OtodataDevice>();
	const [dispatchProMonitor, setDispatchProMonitor] = React.useState<CustomerTankMonitor | null>();

	useEffect(() => {
		async function fetch() {
			const result = await TankMonitorService.getOtodataDevice(deviceId);
			if (result.success) {
				setDevice(result.data.otodataDevice);
				setDispatchProMonitor(result.data.customerTankMonitor);
			} else {
				alert.serverError(result);
			}
		}
		fetch();
	}, [deviceId, alert]);

	if (!device || dispatchProMonitor === undefined) {
		return <CenteredLoadingSpinner />;
	}

	return (
		<OtodataDeviceDetailPageReactContext.Provider value={{ device, dispatchProMonitor, updateDevice: setDevice, updateDispatchProMonitor: setDispatchProMonitor }}>
			<DeviceDetailView />
		</OtodataDeviceDetailPageReactContext.Provider>
	);
}

function DeviceDetailView() {
	const history = useHistory();
	const { device } = useOtodataDeviceDetailPage();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h5">
					<IconButton onClick={history.goBack}>
						<ArrowBackIcon />
					</IconButton>
					Tank Monitor
				</Typography>
			</Grid>
			<Grid item md={"auto"} sm={12}>
				<DeviceDetailCard device={device} />
			</Grid>
			<Grid item md={"auto"} sm={12}>
				<SensorDetailCard device={device} />
			</Grid>
			{showTankSpecsDetailCard(device) && (
				<Grid item md={"auto"} sm={12}>
					<TankSpecsDetailCard device={device} />
				</Grid>
			)}
			<Grid item md={"auto"} sm={12}>
				<BatteryDetailCard device={device} />
			</Grid>
			<Grid item md={"auto"} sm={12}>
				<GasDetailCard device={device} />
			</Grid>
			{showOtherInfoDetailCard(device) && (
				<Grid item md={"auto"} sm={12}>
					<OtherInfoDetailCard device={device} />
				</Grid>
			)}
			<Grid item md={"auto"} sm={12}>
				<AssignedCustomerCard />
			</Grid>
			<Grid item xs={12}>
				<TankLevelsCard deviceId={device.id} />
			</Grid>
		</Grid>
	);
}
