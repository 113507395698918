import { Modify } from "../../utility/modifyType";
import { DriverStatus } from "./DriverStatus";

export interface Driver {
	id: string;
	name: string;
	status: DriverStatus;
	created: Date;
	lastModified: Date;
}

export type SerializedDriver = Modify<Driver, {
	created: string;
	lastModified: string;
}>

export const deserializeDriver = (serverModel: SerializedDriver): Driver => {
	return {
		...serverModel,
		created: new Date(serverModel.created),
		lastModified: new Date(serverModel.lastModified)
	}
}

export const serializeDriver = (driver: Driver): SerializedDriver => {
	return {
		...driver,
		created: driver.created.toISOString(),
		lastModified: driver.lastModified.toISOString()
	}
}