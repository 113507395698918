import { Button, DialogActions, DialogContent, Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { LedgerClassification, getAvailableLedgerAccountTypes } from "../../../../entities/accounting/GeneralLedgerAccount";
import { useAlert } from "../../../../hooks/useAlert";
import { LedgerAccountService } from "../../../../services/accounting/LedgerAccountsService";
import { useLedgerAccountPage } from "../LedgerAccountPage";
import { SelectLedgerClassification } from "./SelectLedgerClassification";
import { SelectLedgerType } from "./SelectLedgerType";

export function AddQuickBooksDesktopAccountView(props: { onClose: () => void }) {
	const alert = useAlert();
	const context = useLedgerAccountPage();
	const [name, setName] = React.useState("");
	const [classification, setClassification] = React.useState(LedgerClassification.Asset);
	const [type, setType] = React.useState(getAvailableLedgerAccountTypes(classification)[0]);
	const [quickBooksAccountName, setQuickBooksAccountName] = React.useState("");

	const [disabled, setDisabled] = React.useState(false);

	useEffect(() => {
		setType(getAvailableLedgerAccountTypes(classification)[0]);
	}, [classification]);

	const onSave = async () => {
		setDisabled(true);
		const result = await LedgerAccountService.createQuickBooksDesktopAccount({ name, classification, type, quickBooksAccountName });
		setDisabled(false);
		if (result.success) {
			alert.success(`${result.data.name} Added`);
			context.addLedgerAccount(result.data);
			props.onClose();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							label="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
							helperText="Name you will see in DispatchPro"
						/>
					</Grid>
					<Grid item xs={6}>
						<SelectLedgerClassification value={classification} onChange={setClassification} />
					</Grid>
					<Grid item xs={6}>
						<SelectLedgerType value={type} onChange={setType} classification={classification} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							label="QuickBooks Account Name"
							value={quickBooksAccountName}
							onChange={(e) => setQuickBooksAccountName(e.target.value)}
							fullWidth
							helperText="Exact name of the account name in your QuickBooks"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={disabled} variant="outlined" color="secondary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={onSave} disabled={disabled}>
					Add Account
				</Button>
			</DialogActions>
		</>
	);
}
