import { deserializeCustomer } from "../../entities/customer/Customer";
import { Tag, TagColor, TagType } from "../../entities/customer/Tag";
import { deserializeDriverTicket } from "../../entities/routing/DriverTicket";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreateTagRequest {
    text: string;
    tagColor: TagColor;
    type: TagType;
}

interface UpdateTagRequest {
    id: number;
    text?: string;
    tagColor?: TagColor;
}

interface AddTagToCustomerRequest {
    customerId: string;
    tagId: number;
}

interface RemoveTagFromCustomerRequest {
    customerId: string;
    tagId: number;
}

interface AddTagToTicketRequest {
    ticketId: string;
    tagId: number;
}

interface RemoveTagFromTicketRequest {
    ticketId: string;
    tagId: number;
}

export const TagService = {
    getAll: () => WebClient.Get<Tag[]>(`${apiServer}/api/tags`),
    create: (request: CreateTagRequest) => WebClient.Post.Validated<Tag>(`${apiServer}/api/tags`, request),
    update: (request: UpdateTagRequest) => WebClient.Put.Validated<Tag>(`${apiServer}/api/tags`, request),
    delete: (id: number) => WebClient.Delete(`${apiServer}/api/tags/${id}`),
    addToCustomer: (request: AddTagToCustomerRequest) => WebClient.Post.Validated(`${apiServer}/api/tags/${request.tagId}/customer/${request.customerId}`, {}, deserializeCustomer),
    removeFromCustomer: (request: RemoveTagFromCustomerRequest) => WebClient.Delete(`${apiServer}/api/tags/${request.tagId}/customer/${request.customerId}`, deserializeCustomer),
    addToTicket: (request: AddTagToTicketRequest) => WebClient.Post.Validated(`${apiServer}/api/tags/${request.tagId}/driver-ticket/${request.ticketId}`, {}, deserializeDriverTicket),
    removeFromTicket: (request: RemoveTagFromTicketRequest) => WebClient.Delete(`${apiServer}/api/tags/${request.tagId}/driver-ticket/${request.ticketId}`, deserializeDriverTicket),
    setTicketTags: (request: {driverTicketId: string, tagIds: number[]}) => WebClient.Put.Validated(`${apiServer}/api/tags/driver-ticket`, request, deserializeDriverTicket),
}