import { Link, Theme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { GeocodedAddress, formatAddress, navigateToAddressLink } from "../../entities/customer/GeocodedAddress";
import { HighlightedText } from "../HighlightedText";

export function CustomerAddressNavigationLink(props: { address: GeocodedAddress, query?: string }) {
    const { address, query } = props;
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const formattedAddress = formatAddress(address);
    const link = navigateToAddressLink(address);
	return (
		<Link
			href={link}
            onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
			target="_blank"
			rel="noopener noreferrer"
			color="inherit"
			underline={isMobile ? "always" : "hover"}
		>
			{
				query
				?
				<HighlightedText query={query}>{formattedAddress}</HighlightedText>
				:
				formattedAddress
			}
		</Link>
	);
}
