import { Card, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { BackButton } from "../../../components/BackButton";
import { LinkTableRow } from "../../../components/LinkTableRow";
import { routes } from "../../../routes";
import { PageTitle } from "../../../components/PageTitle";
import { TransactionExportBatch } from "../../../entities/accounting/TransactionExportBatch";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { useAlert } from "../../../hooks/useAlert";
import { TransactionExportBatchService } from "../../../services/accounting/TransactionExportBatchService";

export function TransactionExportBatchPage() {
	const alert = useAlert();
	const [batches, setBatches] = React.useState<TransactionExportBatch[]>();

	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const pagedCheckBatches = React.useMemo(() => batches?.slice(page * pageSize, page * pageSize + pageSize) ?? [], [batches, page, pageSize]);

	useEffect(() => {
		async function load() {
			const response = await TransactionExportBatchService.getAll();
			if (response.success) {
				setBatches(response.data);
			} else {
				alert.serverError(response);
			}
		}
		load();
	}, [alert]);

	const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
	};

	if (!batches) return <OverlayLoadingScreen />;

	return (
		<Grid container>
			<PageTitle title="Transaction Export Batches" />
			<Grid item>
				<BackButton />
			</Grid>
			<Grid item>
				<Typography variant="h2" gutterBottom>
					Transaction Export Batches
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item lg={8} md={9} sm={10} xs={12}>
						<Card>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Batch Number</TableCell>
										<TableCell>Number Of Transactions</TableCell>
										<TableCell>Date Range</TableCell>
										<TableCell>Download Date</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{batches.length === 0 && (
										<TableRow>
											<TableCell colSpan={5} style={{ textAlign: "center" }}>
												No transaction batches found.
											</TableCell>
										</TableRow>
									)}
									{pagedCheckBatches.map((checkBatch) => (
										<LinkTableRow to={routes.app.resolve.transactionExportBatchDetailPage(checkBatch.id)} key={checkBatch.batchNumber}>
											<TableCell>
												{checkBatch.created.toLocaleDateString()}
												<br />
												{checkBatch.created.toLocaleTimeString()}
											</TableCell>
											<TableCell>{checkBatch.batchNumber}</TableCell>
											<TableCell>{checkBatch.transactionCount}</TableCell>
											<TableCell>
												{checkBatch.firstTransactionDate.toLocaleString()}
												<br />
												{checkBatch.lastTransactionDate.toLocaleString()}
											</TableCell>
											<TableCell>{checkBatch.downloadDate ? checkBatch.downloadDate.toLocaleString() : "Not Downloaded"}</TableCell>
										</LinkTableRow>
									))}
									<TableRow>
										<TablePagination
											rowsPerPageOptions={[5, 10, 15, 20]}
											colSpan={5}
											count={batches.length}
											rowsPerPage={pageSize}
											labelRowsPerPage={"Show"}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</TableRow>
								</TableBody>
							</Table>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
