import React from "react";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { CustomerNote, CustomerNoteType } from "../../../entities/customer/CustomerNotes";
import { CustomerService } from "../../../services/customer/CustomerService";
import { useAlert } from "../../../hooks/useAlert";
import { Box, Button, Card, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from "@material-ui/core";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { Pagination } from "@material-ui/lab";
import { GridGrow } from "../../../components/GridGrow";
import { BoxIconButton } from "../../../components/BoxIconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";

export function CustomerNotesCard() {
	const alert = useAlert();
	const context = useCustomerDetailPage();
	const { customer } = context;
	const customerId = customer.id;

	const [notes, setNotes] = React.useState<CustomerNote[]>();
	const [notesPerPage] = React.useState(5);
	const [currentPage, setCurrentPage] = React.useState(1);
	const displayNotes = notes?.slice((currentPage - 1) * notesPerPage, currentPage * notesPerPage) ?? [];
	const [addMode, setAddMode] = React.useState(false);
	const [newNote, setNewNote] = React.useState("");
	const [disabled, setDisabled] = React.useState(false);

	React.useEffect(() => {
		async function getNotes() {
			const result = await CustomerService.getNotes(customerId);
			if (result.success) {
				setNotes(result.data);
			} else {
				alert.error(result.message);
			}
		}
		getNotes();
	}, [customerId, alert]);

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const addNote = async () => {
		setDisabled(true);
		const result = await CustomerService.addAccountNote({ customerId, note: newNote });
		setDisabled(false);

		if (result.success) {
			setNotes((notes) => [result.data, ...(notes ?? [])]);
			setAddMode(false);
			setNewNote("");
			setCurrentPage(1);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onDelete = async (note: CustomerNote) => {
		setDisabled(true);
		const result = await CustomerService.removeNote(note.id);
		setDisabled(false);
		if (result.success) {
			setNotes((notes) => notes?.filter((n) => n.id !== note.id));
			alert.success("Note Deleted");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<Box paddingLeft={2} paddingTop={1} paddingRight={2}>
				<Grid container>
					<Grid item>
						<Typography variant="h6">Notes</Typography>
					</Grid>
					<GridGrow />
					<Grid>
						<Button size="small" variant="outlined" onClick={() => setAddMode(true)} disabled={addMode}>
							Add Note
						</Button>
					</Grid>
				</Grid>
			</Box>
			{addMode && (
				<>
					<Box paddingLeft={2} paddingTop={1} paddingRight={2} paddingBottom={1}>
						<TextField
							autoFocus
							multiline
							fullWidth
							rows={3}
							variant="outlined"
							placeholder="Account Note"
							value={newNote}
							onChange={(e) => setNewNote(e.target.value)}
						/>
					</Box>
					<Grid container>
						<Grid item xs={6}>
							<Button
								variant="outlined"
								color="secondary"
								fullWidth
								onClick={() => {
									setNewNote("");
									setAddMode(false);
								}}
								disabled={disabled}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button variant="contained" color="primary" fullWidth onClick={addNote} disabled={disabled}>
								Add
							</Button>
						</Grid>
					</Grid>
				</>
			)}
			{notes === undefined && <CenteredLoadingSpinner />}
			{notes !== undefined && notes.length === 0 && !addMode && (
				<Box paddingLeft={2} paddingTop={1} paddingRight={2}>
					<Typography variant="body1" gutterBottom>
						No notes yet
					</Typography>
				</Box>
			)}
			{notes !== undefined && notes.length > 0 && (
				<>
					<List>
						{displayNotes.map((note, i) => (
							<React.Fragment key={note.id}>
								{i !== 0 && <Divider />}
								<ListItem>
									<ListItemText
										primary={note.note}
										secondary={
											<>
												{note.created.toLocaleString()} by {note.createdBy}
												{note.type === CustomerNoteType.Ticket && (
													<>
														{" "}
														<ClientLink to={routes.app.resolve.ticketDetailPage(note.ticketId)} underline="always">
															See Ticket
														</ClientLink>
													</>
												)}
											</>
										}
										primaryTypographyProps={{
											style: {
												whiteSpace: "pre-wrap",
												wordBreak: "break-word",
											},
										}}
									/>
									<ListItemSecondaryAction>
										<BoxIconButton color="secondary" onClick={() => onDelete(note)} disabled={disabled}>
											<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
										</BoxIconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</React.Fragment>
						))}
					</List>
					<Pagination style={{ margin: 5 }} count={Math.ceil(notes.length / notesPerPage)} page={currentPage} onChange={handlePageChange} />
				</>
			)}
		</Card>
	);
}
