import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@material-ui/core";
import React from "react";
import { Invoice } from "../../../entities/billing/Invoice";
import { useAlert } from "../../../hooks/useAlert";
import { InvoiceService } from "../../../services/billing/InvoiceService";



interface ChangeInvoiceCodeDialogProps {
	open: boolean;
	invoice: Invoice;
	onClose: () => void;
	onCodeChanged: (invoice: Invoice) => void;
}

export function ChangeInvoiceCodeDialog(props: ChangeInvoiceCodeDialogProps) {
	const { open, invoice } = props;

	const alert = useAlert();

	const [invoiceCode, setInvoiceCode] = React.useState<string>(invoice.shortId);
	const [disabled, setDisabled] = React.useState(false);

	const onSubmit = async () => {

		setDisabled(true);
		const result = await InvoiceService.update({ invoiceId: invoice.id, code: invoiceCode});
		setDisabled(false);

		if (result.success) {
			props.onCodeChanged(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={props.onClose} maxWidth="xs">
			<DialogTitle>Change Invoice Code</DialogTitle>
			<DialogContent>
			<DialogContentText>The invoice code must be unique and less than 10 characters</DialogContentText>
				<TextField label="Invoice Code" variant="outlined" fullWidth value={invoiceCode} onChange={(e) => setInvoiceCode(e.target.value)} />
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} disabled={disabled} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button onClick={onSubmit} disabled={disabled} variant="contained" color="primary">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
