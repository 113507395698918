import { Typography } from "@material-ui/core";
import React, {  } from "react";
import { routes } from "../../../routes";
import { TicketInvoice } from "../../../entities/routing/DriverTicket";
import { ClientLink } from "../../../components/ClientLink";
import { Price } from "../../../components/Price";

export function TicketInvoiceDetail(props: { invoice: TicketInvoice }) {
	const { invoice } = props;
	return (
		<>
			<Typography variant="h6" color="textSecondary" gutterBottom>
				Invoice
			</Typography>
			<Typography variant="body1">
				<ClientLink to={routes.app.resolve.invoiceDetailPage(invoice.id)}>{invoice.shortId}</ClientLink>
			</Typography>
			<Typography variant="body1">
				{!invoice.closed && (
					<>
						Remaining balance of <Price value={invoice.balance} />
					</>
				)}
				{invoice.closed && (
					<>
						Paid Total: <Price value={invoice.total} />
					</>
				)}
			</Typography>
		</>
	);
}