import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { UserClaim } from "../entities/auth/UserClaim";
import { AccountPage } from "../pages/account/AccountPage";
import { AccountingDashboardPage } from "../pages/accounting/AccountingDashboardPage";
import { CheckBatchDetailPage } from "../pages/accounting/check-batch/CheckBatchDetailPage";
import { CheckBatchesPage } from "../pages/accounting/check-batch/CheckBatchesPage";
import { CreateCheckBatchPage } from "../pages/accounting/check-batch/CreateCheckBatchPage";
import { CreateTransactionExportBatchPage } from "../pages/accounting/transaction-export-batch/CreateTransactionExportBatchPage";
import { TransactionExportBatchDetailPage } from "../pages/accounting/transaction-export-batch/TransactionExportBatchDetailPage";
import { TransactionExportBatchPage } from "../pages/accounting/transaction-export-batch/TransactionExportBatchPage";
import { CustomerPage } from "../pages/customers/CustomerPage";
import { InvoicePage } from "../pages/invoices/InvoicePage";
import { InvoiceSearchPage } from "../pages/invoices/InvoiceSearchPage";
import { PaymentDetailPage } from "../pages/payments/PaymentDetailPage";
import { PaymentsSearchPage } from "../pages/payments/PaymentsSearchPage";
import { PropaneTankDetailPage } from "../pages/propane-tanks/PropaneTankDetailPage";
import { ReportsPage } from "../pages/reports/ReportsPage";
import { RoutePage } from "../pages/routes/RoutePage";
import { DriverTicketDetailPage } from "../pages/tickets/DriverTicketDetailPage";
import { DriverTicketSearchPage } from "../pages/tickets/DriverTicketSearchPage";
import { OpenDriverTicketsListPage } from "../pages/tickets/OpenDriverTicketsListPage";
import { ReviewDeliveredTicketsPage } from "../pages/tickets/ReviewDeliveredTicketsPage";
import { useAppUser } from "../providers/AppUserProvider";
import { routes } from "../routes";
import { PropaneTankSearchPage } from "../pages/propane-tanks/PropaneTankSearchPage";
import { CustomerContractDetailPage } from "../pages/customers/contracts/CustomerContractDetailPage";
import { TankMonitorsPage } from "../pages/tank-monitors/TankMonitorsPage";
import { OtodataDeviceDetailPage } from "../pages/tank-monitors/OtodataDeviceDetailPage";

export function AppMainRoutes() {
	const user = useAppUser();

	const { driverId } = user;
	let appDefaultRedirect = routes.app.resolve.customerListPage();
	if (driverId) {
		appDefaultRedirect = routes.app.resolve.driverRoutePage(driverId);
	}

	const isAdmin = user.userClaims.some((c) => c === UserClaim.Admin || c === UserClaim.Super_Admin);

	return (
		<Switch>
			<Route exact path={[routes.app.routeListPage, routes.app.driverRoutePage, routes.app.routeTicketPage]}>
				<RoutePage />
			</Route>
			<Route exact path={routes.app.customerContractDetailPage}>
				<CustomerContractDetailPage />
			</Route>
			<Route exact path={[routes.app.customerPage, routes.app.customerDetailPage]}>
				<CustomerPage />
			</Route>
			<Route exact path={routes.app.propaneTankSearchPage}>
				<PropaneTankSearchPage />
			</Route>
			<Route exact path={[routes.app.propaneTankDetailPage, routes.app.tankRegulatorDetailPage]}>
				<PropaneTankDetailPage />
			</Route>
			<Route exact path={routes.app.ticketSearchPage}>
				<DriverTicketSearchPage />
			</Route>
			<Route exact path={routes.app.openTicketsPage}>
				<OpenDriverTicketsListPage />
			</Route>
			<Route exact path={routes.app.reviewTicketsPage}>
				<ReviewDeliveredTicketsPage />
			</Route>
			<Route exact path={routes.app.ticketDetailPage}>
				<DriverTicketDetailPage />
			</Route>
			<Route exact path={[routes.app.tankMonitorDispatchProListPage, routes.app.tankMonitorOtodataListPage]}>
				<TankMonitorsPage />
			</Route>
			<Route exact path={routes.app.otodataTankMonitorDetailPage}>
				<OtodataDeviceDetailPage />
			</Route>
			<Route exact path={routes.app.accountPage}>
				<AccountPage />
			</Route>
			<Route exact path={routes.app.invoiceSearchPage}>
				<InvoiceSearchPage />
			</Route>

			<Route exact path={[routes.app.invoicePage, routes.app.invoiceDetailPage]}>
				<InvoicePage />
			</Route>

			<Route
				exact
				path={[
					routes.app.reportListPage,
					routes.app.statementReportPage,
					routes.app.customerBalanceReportPage,
					routes.app.salesReportPage,
					routes.app.invoiceReportPage,
					routes.app.paymentsReportPage,
					routes.app.taxReportPage,
					routes.app.customerPropaneFillReportPage,
					routes.app.driverFillReportPage,
					routes.app.tankAlertReportPage,
					routes.app.tankLastFillReportPage,
					routes.app.degreeDayReportPage,
				]}
			>
				<ReportsPage />
			</Route>

			<Route exact path={routes.app.paymentsPage}>
				<PaymentsSearchPage />
			</Route>
			<Route exact path={routes.app.paymentDetailPage}>
				<PaymentDetailPage />
			</Route>
			{isAdmin && (
				<Route exact path={routes.app.accountingDashboardPage}>
					<AccountingDashboardPage />
				</Route>
			)}
			{isAdmin && (
				<Route exact path={routes.app.checkBatchPage}>
					<CheckBatchesPage />
				</Route>
			)}
			{isAdmin && (
				<Route exact path={routes.app.checkBatchCreatePage}>
					<CreateCheckBatchPage />
				</Route>
			)}
			{isAdmin && (
				<Route exact path={routes.app.checkBatchDetailPage}>
					<CheckBatchDetailPage />
				</Route>
			)}
			{isAdmin && (
				<Route exact path={routes.app.transactionExportBatchPage}>
					<TransactionExportBatchPage />
				</Route>
			)}
			{isAdmin && (
				<Route exact path={routes.app.transactionExportBatchCreatePage}>
					<CreateTransactionExportBatchPage />
				</Route>
			)}
			{isAdmin && (
				<Route exact path={routes.app.transactionExportBatchDetailPage}>
					<TransactionExportBatchDetailPage />
				</Route>
			)}
			<Redirect to={appDefaultRedirect} />
		</Switch>
	);
}
