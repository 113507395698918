import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { PaymentTerms } from "../../../entities/billing/PaymentTerms";
import { routes } from "../../../routes";
import { PaymentTermsService } from "../../../services/billing/PaymentTermsService";
import { NotFoundResult, ServerError } from "../../../services/server/WebClient";
import { PaymentTermsDetailView } from "./PaymentTermsDetailView";
import { PaymentTermsListView } from "./PaymentTermsListView";

export function PaymentTermsPage() {
	const [paymentTerms, setPaymentTerms] = React.useState<PaymentTerms[]>();
	const [serverError, setServerError] = React.useState<ServerError| NotFoundResult>();

	useEffect(() => {
		async function fetchPaymentTerms() {
			const result = await PaymentTermsService.getAll();
			if (result.success) {
				setPaymentTerms(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchPaymentTerms();
	}, []);

	if (serverError) return <ServerErrorView serverError={serverError} />;
	if (!paymentTerms) return <OverlayLoadingScreen />;

	return (
		<PaymentTermsPageProvider paymentTerms={paymentTerms} setPaymentTerms={setPaymentTerms}>
			<PaymentTermsPageRouter />
		</PaymentTermsPageProvider>
	);
}

interface PaymentTermsPageContext {
	paymentTerms: PaymentTerms[];
	selectedPaymentTerms: PaymentTerms | undefined;
	addPaymentTerms: (paymentTerms: PaymentTerms) => void;
	updatePaymentTerms: (paymentTerms: PaymentTerms) => void;
	setDefaultPaymentTerms: (paymentTerms: PaymentTerms) => void;
}

const PaymentTermsPageReactContext = React.createContext<PaymentTermsPageContext>({} as PaymentTermsPageContext);
export const usePaymentTermsPage = () => React.useContext(PaymentTermsPageReactContext);

function PaymentTermsPageProvider(props: PropsWithChildren<{ paymentTerms: PaymentTerms[]; setPaymentTerms: (paymentTerms: PaymentTerms[]) => void }>) {
	const { paymentTerms, setPaymentTerms } = props;
	const params = useParams<{ paymentTermsId: string | undefined }>();
	const paymentTermsId = params.paymentTermsId;

	const selectedPaymentTerms = useMemo(() => paymentTerms.find((u) => u.id === paymentTermsId), [paymentTerms, paymentTermsId]);

	const addPaymentTerms = (newPaymentTerms: PaymentTerms) => setPaymentTerms([...paymentTerms, newPaymentTerms]);
	const updatePaymentTerms = (updatedPaymentTerms: PaymentTerms) => setPaymentTerms(paymentTerms.map((t) => (t.id === updatedPaymentTerms.id ? updatedPaymentTerms : t)));
	const setDefaultPaymentTerms = (updatedPaymentTerms: PaymentTerms) => {
		setPaymentTerms(paymentTerms.map((t) => (t.id === updatedPaymentTerms.id ? updatedPaymentTerms : { ...t, isDefault: false })));
	};

	return (
		<PaymentTermsPageReactContext.Provider value={{ paymentTerms, selectedPaymentTerms, addPaymentTerms, updatePaymentTerms, setDefaultPaymentTerms }}>
			{props.children}
		</PaymentTermsPageReactContext.Provider>
	);
}

function PaymentTermsPageRouter() {
	const { selectedPaymentTerms } = usePaymentTermsPage();
	return (
		<Switch>
			<Route exact path={routes.business.paymentTermsPage}>
				<PaymentTermsListView />
			</Route>
			{selectedPaymentTerms && (
				<Route exact path={routes.business.paymentTermsDetailPage}>
					<PaymentTermsDetailView paymentTerms={selectedPaymentTerms} />
				</Route>
			)}
		</Switch>
	);
}
