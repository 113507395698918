export const NodaTime = {
    serializeToLocalDate: (date: Date): string => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;

    },
    deserializeFromLocalDate: (date: string): Date => {
        return new Date(date+"T00:00:00");
    }
}