import React from "react";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { useCustomerPaymentCardContext } from "./CustomerPaymentCard";
import { CustomerTaxExemption, ExemptionType } from "../../../../entities/accounting/TaxExemption";
import { TaxExemptionStatus } from "../../../../components/taxExemptions/TaxExemptionStatus";
import { TaxExemptionDialog } from "../../../../components/taxExemptions/TaxExemptionDialog";
import { useCustomerDetailPage } from "../../CustomerDetailPage";

export function TaxExemptionSection() {
	const customerContext = useCustomerDetailPage();
	const { profile, updateProfile } = customerContext;

	const context = useCustomerPaymentCardContext();
	const { disabled } = context;

	const [openTaxExemptionDialog, setOpenTaxExemptionDialog] = React.useState(false);
	const customerTaxExemption = (profile.taxExemptions.find((taxExemption) => taxExemption.type === ExemptionType.Customer) as CustomerTaxExemption) ?? null;

	const onUpdateTaxExemption = (taxExemption: CustomerTaxExemption | null) => updateProfile(p => {
		const currentExemptions = p.taxExemptions.filter((t) => t.type !== ExemptionType.Customer);
		if (taxExemption) {
			currentExemptions.push(taxExemption);
		}
		return { ...p, taxExemptions: currentExemptions };
	});

	return (
		<>
			{customerTaxExemption !== undefined && (
				<TaxExemptionDialog
					open={openTaxExemptionDialog}
					onClose={() => setOpenTaxExemptionDialog(false)}
					taxExemption={customerTaxExemption}
					onChange={(t) => onUpdateTaxExemption(t as CustomerTaxExemption)}
					customerId={profile.customer.id}
				/>
			)}
			<Typography variant="overline">Tax Status</Typography>
			<List dense style={{ padding: 0 }}>
				<ListItem button onClick={() => setOpenTaxExemptionDialog(true)} disabled={disabled}>
					<ListItemText
						primary={<TaxExemptionStatus taxExemption={customerTaxExemption} />}
						primaryTypographyProps={{
							color: customerTaxExemption == null ? "textSecondary" : undefined,
						}}
					/>
				</ListItem>
			</List>
		</>
	);
}
