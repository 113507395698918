import { Chip, Theme, useTheme } from "@material-ui/core";
import React from "react";
import { getTagColorTheme, Tag } from "../entities/customer/Tag";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

interface Props {
	tag: Tag;
	disabled?: boolean;
	selected?: boolean;
	onDelete?: (tag: Tag) => void;
	onClick?: (tag: Tag) => void;
}

export function TagBubble(props: Props) {
	const { tag, selected } = props;
	const { palette } = useTheme<Theme>();

	const { background, text } = getTagColorTheme(tag.color, palette.type);

	const onDelete = () => {
		if (props.onDelete) {
			return () => (props.onDelete ? props.onDelete(tag) : undefined);
		}
		return undefined;
	};

	const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (props.onClick) {
			e.stopPropagation();
			props.onClick(tag);
		}
	};

	return (
		<Chip
			style={{ color: text, backgroundColor: selected ? background + "73" : background }}
			disabled={props.disabled}
			label={tag.text}
			onDelete={onDelete()}
			onClick={props.onClick ? onClick : undefined}
			deleteIcon={<CancelRoundedIcon style={{color: text}} />}
		/>
	);
}
