import { Box, Typography, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, List } from "@material-ui/core";
import React, { useState } from "react";
import { TaxExemption, TaxExemptionDocument } from "../../entities/accounting/TaxExemption";
import { TaxExemptionService } from "../../services/accounting/TaxExemptionService";
import { UploadDocumentForm } from "./UploadDocumentForm";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from '@material-ui/icons/GetApp';


export function TaxExemptionDocuments(props: { taxExemption: TaxExemption, onChange: (taxExemption: TaxExemption) => void; }) {
	const { taxExemption } = props;
	const documents = taxExemption.documents;

    const [disabled, setDisabled] = useState(false);

	const onDocumentClick = async (document: TaxExemptionDocument) => {
		setDisabled(true);
        await TaxExemptionService.downloadDocument(document);
        setDisabled(false);
	};

	const documentDescription = (document: TaxExemptionDocument) => {
		let description = `Uploaded ${document.createdAt.toLocaleDateString()}.`;
		if (document.expirationDate) {
			description += ` Expires ${document.expirationDate.toLocaleDateString()}.`;
		}
		return description;
	};

	return (
		<Box pb={2}>
			<UploadDocumentForm taxExemption={taxExemption} onUpload={props.onChange} />
			{documents.length > 0 && <>
			<Typography variant="h6">Documents</Typography>
			<List>
				{documents.map((document) => (
					<ListItem key={document.id}>
						<ListItemIcon>
							<DescriptionIcon />
						</ListItemIcon>
						<ListItemText primary={document.fileName} secondary={documentDescription(document)} />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => onDocumentClick(document)} disabled={disabled}>
                                <GetAppIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</>}
		</Box>
	);
}

