import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomerService } from "../../services/customer/CustomerService";
import { CustomerTankDetailCard } from "./components/CustomerTankDetailCard";
import { GridGrow } from "../../components/GridGrow";
import { RedButton } from "../../components/RedButton";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { CustomerContactCard } from "./components/CustomerContactCard";
import { CustomerPaymentCard } from "./components/CustomerPaymentCard/CustomerPaymentCard";
import { CustomerTicketsCard } from "./components/CustomerTicketsCard";
import { useCustomerDetailPage } from "./CustomerDetailPage";
import MuiAlert from "@material-ui/lab/Alert";
import { BackButton } from "../../components/BackButton";
import { CustomerNotesCard } from "./components/CustomerNotesCard";
import { CustomerDocumentsCard } from "./components/CustomerDocumentsCard";

export function CustomerDetailView() {
	const history = useHistory();
	const alert = useAlert();

	const [disabled, setDisabled] = useState(false);

	const context = useCustomerDetailPage();
	const { customer, canDelete } = context;

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [showCustomerAlertDialog, setShowCustomerAlertDialog] = useState(false);

	const onDelete = async () => {
		setDisabled(true);
		const result = await CustomerService.remove(customer.id);
		setDisabled(false);
		if (result.success) {
			alert.success("Customer Deleted");
			history.replace(routes.app.resolve.customerListPage());
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onChangeStatus = async () => {
		setDisabled(true);
		const result = await CustomerService.update({ customerId: customer.id, inactive: !customer.inactive });
		setDisabled(false);

		if (result.success) {
			alert.success("Customer Status Updated");
			context.updateCustomer(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	if (!customer) {
		return null;
	}

	return (
		<Grid container spacing={1} alignItems="center">
			<DeleteCustomerDialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)} onConfirm={onDelete} />
			<CustomerAlertDialog open={showCustomerAlertDialog} onClose={() => setShowCustomerAlertDialog(false)} />
			<Grid item>
				<BackButton />
			</Grid>
			<Grid item>
				<Typography variant="h5">
					{customer.name} {customer.inactive ? "(Inactive)" : ""}
				</Typography>
			</Grid>
			<GridGrow />
			{!customer.alert && (
				<Grid item>
					<Button variant="outlined" disabled={disabled} onClick={() => setShowCustomerAlertDialog(true)}>
						Add Alert
					</Button>
				</Grid>
			)}
			{canDelete && (
				<Grid item>
					<RedButton variant="outlined" onClick={() => setShowDeleteConfirmation(true)} disabled={disabled}>
						Delete Customer
					</RedButton>
				</Grid>
			)}
			<Grid item>
				<Button variant="outlined" color="default" onClick={onChangeStatus} disabled={disabled}>
					Mark as {customer.inactive ? "Active" : "Inactive"}
				</Button>
			</Grid>
			{customer.alert && (
				<Grid item xs={12}>
					<Grid container justify="center">
						<Grid item>
							<MuiAlert
								onClick={() => setShowCustomerAlertDialog(true)}
								elevation={6}
								variant="standard"
								severity="warning"
								style={{ minWidth: 200, cursor: "pointer" }}
							>
								{customer.alert}
							</MuiAlert>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item xs={12}>
				<Grid container spacing={3} justify="space-evenly">
					<Grid item xl={5} sm={6} xs={12}>
						<CustomerTicketsCard />
						<Box marginTop={1}>
							<CustomerContactCard />
						</Box>
						<Box marginTop={1}>
							<CustomerTankDetailCard />
						</Box>
						<Box marginTop={1}>
							<CustomerNotesCard />
						</Box>
					</Grid>
					<Grid item xl={5} sm={6} xs={12}>
						<CustomerPaymentCard />
						<Box marginTop={1}>
							<CustomerDocumentsCard />
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

function DeleteCustomerDialog(props: { open: boolean; onClose: () => void; onConfirm: () => void }) {
	const { open } = props;
	return (
		<Dialog open={open} onClose={props.onClose} aria-labelledby="delete-dialog-title" aria-describedby="delete-dialog-description">
			<DialogTitle id="delete-dialog-title">Are you sure you want to delete?</DialogTitle>
			<DialogContent>
				<DialogContentText id="delete-dialog-description">
					This customer has no history, so it is safe to delete, but all contact and tank information will be lost.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button onClick={props.onConfirm} color="primary">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function CustomerAlertDialog(props: { open: boolean; onClose: () => void }) {
	const { open} = props;

	const context = useCustomerDetailPage();
	const { customer } = context;

	const alert = useAlert();

	const [customerAlert, setCustomerAlert] = useState(customer.alert ?? "");
	const [disabled, setDisabled] = useState(false);

	useEffect(() => setCustomerAlert(customer.alert ?? ""), [customer.alert, open]);

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const newValue = customerAlert.trim() === "" ? null : customerAlert.trim();
		setDisabled(true);
		const result = await CustomerService.update({ customerId: customer.id, alert: newValue });
		setDisabled(false);
		if (result.success) {
			context.updateCustomer(result.data);
			alert.success(result.data.alert ? "Customer Alert Updated" : "Customer Alert Removed");
			props.onClose();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onClose = () => {
		if(disabled) return;
		props.onClose();
	}

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">Customer Alert</DialogTitle>
			<form onSubmit={onSubmit}>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Customer alerts will display prominently on the customer page. This is a good place to put important information.
					</DialogContentText>
					<TextField autoFocus variant="outlined" label="Alert" fullWidth value={customerAlert} onChange={(e) => setCustomerAlert(e.target.value)} />
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} variant="outlined" disabled={disabled}>
						Cancel
					</Button>
					<Button type="submit" color="primary" variant="contained" disabled={disabled}>
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
