import * as React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import { appVersion } from "./appVersion";
import * as serviceWorker from "./serviceWorker";
import { runningLocal, runningOnProd } from "./AppEnv";

const history = createBrowserHistory();

const urlParams = new URLSearchParams(window.location.search);
const isAMonitorBot = urlParams.get("ignoreRefreshToken") != null;
const swRefresh = urlParams.get("swRefresh");

if (runningOnProd() && !isAMonitorBot) {
	Sentry.init({
		dsn: "https://f14708c81dec49479195afce19eef05d@o458125.ingest.sentry.io/5722273",
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
			}),
		],
		release: `dispatch-pro-app@${appVersion}`,
		tracesSampler: (context) => {
			if (context.parentSampled !== undefined) {
				return context.parentSampled;
			}
			return 0.2;
		},
	});
}

const rootEl = document.getElementById("root");
ReactDOM.render(<App history={history} />, rootEl);
if (swRefresh) {
	serviceWorker.unregister(decodeURIComponent(swRefresh));
} else if (runningLocal()) {
	serviceWorker.unregister();
} else if (!isAMonitorBot) {
	serviceWorker.register();
}
