export const printDate = {
	shortDate: (date: Date, yearAware = false) => {
		const now = new Date();
		if (!yearAware || now.getFullYear() !== date.getFullYear()) return date.toLocaleDateString("en-US", { day: "numeric", month: "numeric", year: "2-digit" });

		return date.toLocaleDateString("en-US", { day: "numeric", month: "numeric" });
	},
	fileFriendlyDate: (date: Date) => {
		// print date in format: 2020-01-01
		return date.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/\//g, "-");
	},
	timeAgo: (date: Date) => {
		const now = new Date();
		const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
	
		if (diffInSeconds < 60) {
		  return "Moments ago";
		} else if (diffInSeconds < 3600) {
		  const minutes = Math.floor(diffInSeconds / 60);
		  return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
		} else if (diffInSeconds < 86400) {
		  const hours = Math.floor(diffInSeconds / 3600);
		  return `${hours} hour${hours > 1 ? 's' : ''} ago`;
		} else {
		  return printDate.shortDate(date, true);
		}
	  },
};
