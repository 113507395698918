import React from "react";
import { CustomerTankMonitor, MonitorType } from "../../entities/routing/TankMonitor";
import { Grid, Paper, Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Typography } from "@material-ui/core";
import { GridGrow } from "../../components/GridGrow";
import { SearchTextField } from "../../components/SearchTextField";
import { SortableTableHeader, TableHeader } from "../../components/table/SortableTableHeader";
import { orderBy } from "../../utility/orderBy";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../../utility/useLocalStorage";
import { routes } from "../../routes";

function applySearchFilter(devices: CustomerTankMonitor[], query: string): CustomerTankMonitor[] {
	if (query === "") {
		return devices;
	}

	return devices.filter((device) => {
		if (device.customerName.toLowerCase().includes(query)) {
			return true;
		}

		if (device.id.toString().startsWith(query)) {
			return true;
		}

		if (device.lastReportedAt?.toLocaleDateString().includes(query)) {
			return true;
		}

		return false;
	});
}

function applySort(devices: CustomerTankMonitor[], orderedBy: keyof CustomerTankMonitor, order: "asc" | "desc"): CustomerTankMonitor[] {
	if (orderedBy === "customerName") {
		return devices.sort(orderBy.string((d) => d.customerName, order));
	}
	if (orderedBy === "lastReportedPercentFull") {
		return devices.sort(orderBy.optional.number((d) => d.lastReportedPercentFull, order, "optionalEnd"));
	}
	if (orderedBy === "gallonCapacity") {
		return devices.sort(orderBy.number((d) => d.gallonCapacity, order));
	}
	if (orderedBy === "lastReportedAt") {
		return devices.sort(orderBy.optional.date((d) => d.lastReportedAt, order, "optionalEnd"));
	}
	return devices;
}

export function DispatchProMonitorsListView(props: { customerMonitors: CustomerTankMonitor[] }) {
	const { customerMonitors } = props;
	const history = useHistory();

	const [searchText, setSearchText] = useLocalStorage("dp-monitors-search", "");
	const [order, setOrder] = React.useState<"asc" | "desc">("asc");
	const [orderBy, setOrderBy] = useLocalStorage<keyof CustomerTankMonitor>("dp-monitors-orderBy", "customerName");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const query = React.useMemo(() => searchText.toLowerCase().trim(), [searchText]);

	const searchedDevices = React.useMemo(() => applySearchFilter(customerMonitors, query), [customerMonitors, query]);
	const sortedDevices = React.useMemo(() => applySort([...searchedDevices], orderBy, order), [searchedDevices, orderBy, order]);
	const paginatedDevices = React.useMemo(() => {
		const start = page * rowsPerPage;
		const end = start + rowsPerPage;
		return sortedDevices.slice(start, end);
	}, [sortedDevices, page, rowsPerPage]);

	const onSortChanged = (property: string, order: "asc" | "desc") => {
		if (property !== "customerName" && property !== "lastReportedPercentFull" && property !== "gallonCapacity" && property !== "lastReportedAt") {
			return;
		}
		setOrderBy(property);
		setOrder(order);
	};

	const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearchChange = (value: string) => {
		setSearchText(value);
		setPage(0);
	};

	const onSelectMonitor = (monitor: CustomerTankMonitor) => {
		if(monitor.type === MonitorType.Otodata) {
			history.push(routes.app.resolve.otodataTankMonitorDetailPage(monitor.externalId));
		}
	}
	

    if(customerMonitors.length === 0) {
        return (
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h5">No Configured Monitors</Typography>
                </Grid>
            </Grid>
        );
    }

	return (
		<Grid container spacing={2}>
			<Grid item>
				<Typography variant="h5">Configured Monitors</Typography>
			</Grid>
			<GridGrow />
			<Grid item>
				<SearchTextField value={searchText} onChange={handleSearchChange} />
			</Grid>
			<Grid item xs={12}>
				<Paper>
					<Table>
						<SortableTableHeader order={order} orderBy={orderBy}>
							<TableHeader property="customerName" onSort={onSortChanged}>
								Customer
							</TableHeader>
							<TableHeader property="gallonCapacity" onSort={onSortChanged}>
								Tank
							</TableHeader>
							<TableHeader property="lastReportedPercentFull" onSort={onSortChanged}>
								Level
							</TableHeader>
							<TableHeader property="lastReportedAt" onSort={onSortChanged}>
								Last Update
							</TableHeader>
							<TableHeader>
								Status
							</TableHeader>
							<TableHeader>Id</TableHeader>
						</SortableTableHeader>
						<TableBody>
							{paginatedDevices.map((device) => (
								<TableRow key={device.id} hover onClick={() => onSelectMonitor(device)}>
									<TableCell>{device.customerName}</TableCell>
									<TableCell>{device.tankLocation ?? device.tankSerialNumber ?? `${device.gallonCapacity} gal`}</TableCell>
									<TableCell>{device.lastReportedPercentFull ?? ""}%</TableCell>
									<TableCell>{device.lastReportedAt?.toLocaleDateString() ?? "Never"}</TableCell>
									<TableCell>{device.alert ?? "Ok"}</TableCell>
									<TableCell>{device.externalId}</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5, 10, 15, 20]}
									colSpan={6}
									count={sortedDevices.length}
									rowsPerPage={rowsPerPage}
									labelRowsPerPage={"Show"}
									page={page}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</TableRow>
						</TableFooter>
					</Table>
				</Paper>
			</Grid>
		</Grid>
	);
}
