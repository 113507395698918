import { fade, Grid, Hidden, InputBase, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useReducer } from "react";
import { GridGrow } from "../GridGrow";
import { ResponsiveTable } from "../ResponsiveTable";
import { getListViewReducerInitState, ListViewAction, ListViewReducer, ListViewState } from "./ListLiewReducer";
import { ListViewConfig, TableConfig } from "./ListViewConfig";
import { ListViewTableTabs } from "./ListViewTableTabs";

export interface ListViewProps<TData, TTableData> {
	tableConfig: TableConfig<TData>;
	listViewConfig: ListViewConfig<TData, TTableData>;
	title: string | React.ReactNode;
	action?: React.ReactNode;
	onSelect: (value: TTableData) => void;
}

export function ListView<TData, TTableData>(props: ListViewProps<TData, TTableData>) {
	const { tableConfig, listViewConfig, title, action } = props;
	const { data, tabConfig } = tableConfig;
	const classes = useStyles();

	type ListViewReducerType = (state: ListViewState<TData, TTableData>, action: ListViewAction<TData>) => ListViewState<TData, TTableData>;
	const [state, dispatch] = useReducer<ListViewReducerType>(ListViewReducer, getListViewReducerInitState(props));

	useEffect(() => { dispatch({type: "base_data_changed", baseData: data})}, [data])

	const onTabChange = (tabNumber: number) => {
		dispatch({type: "tab_changed", tabNumber});
	}

	return (
		<Grid container alignItems="flex-end" justify="flex-end">
			<Hidden smDown>
				<Grid item>
					<Typography variant="h4">
						{title}
					</Typography>
				</Grid>
			</Hidden>
			<GridGrow />
			<Grid item>
				<Grid container>
					<Grid item>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search…"
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ "aria-label": "search" }}
								onChange={(e) => dispatch({type: "search_changed", query: e.target.value})}
								autoFocus
							/>
						</div>
					</Grid>
					{action && <Grid item>{action}</Grid>}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Paper className={classes.tableContainer}>
					{tabConfig && (
						<ListViewTableTabs tabConfig={tabConfig} onChange={onTabChange}/>
					)}
					<div style={{ marginTop: 15 }}>
						<ResponsiveTable
							key={state.selectedTabNumber}
							data={state.displayedData}
							emptyMessage={listViewConfig.emptyMessage(state.selectedTabNumber)}
							onSelection={props.onSelect}
							columns={listViewConfig.tableColumns(data, state.search, state.selectedTabNumber)}
						/>
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.background.paper, 0.7),
		"&:hover": {
			backgroundColor: fade(theme.palette.background.paper, 0.9),
		},
		marginLeft: 0,
		marginRight: 5,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto",
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "12ch",
			"&:focus": {
				width: "20ch",
			},
		},
	},
	tableContainer: {
		width: "100%",
		overflowX: "auto",
		marginRight: "auto",
		marginLeft: "auto",
		marginTop: 20,
		padding: "10px",
	},
}));
