import React, { useEffect, useState } from "react";
import { deserializeProductCatalogData, ProductCatalog, ProductCatalogData, SerializedProductCatalogData, serializeProductCatalogData } from "../entities/products/ProductCatalog";
import { ProductCategoryType } from "../entities/products/ProductCategoryType";
import { ProductLine } from "../entities/products/ProductLine";
import { ProductListing } from "../entities/products/ProductListing";
import { useCachedOfflineState } from "../hooks/useCachedOfflineState";
import { ProductCatalogService } from "../services/products/ProductCatalogService";
import { createServerError, ServerError } from "../services/server/WebClient";
import { AppDataContext, AppDataLoadStatus } from "./AppDataProviders";
import { useAppUser } from "./AppUserProvider";
import { useOfflineStatus } from "./OfflineStatusProvider";

interface ProductCatalogProviderProps extends AppDataContext {
	productCatalog: ProductCatalog;
	propaneListing: ProductListing;
	addProductLine: (productLine: ProductLine) => void;
	updateProductLine: (productLine: ProductLine) => void;
	addProductListing: (productListing: ProductListing) => ProductLine;
	updateProductListing: (productListing: ProductListing) => ProductLine;
}

const tempPropaneListing: ProductListing = {
	id: "temp",
	name: "Propane",
	price: 5,
	productLineId: "temp",
	productLineType: ProductCategoryType.Propane,
	revenueAccountId: 0,
	unitName: "Gallon",
	disabled: null,
	productCode: null
};

const ProductCatalogContext = React.createContext<ProductCatalogProviderProps>({} as ProductCatalogProviderProps);
ProductCatalogContext.displayName = "ProductCatalogAppData";

export function useProductCatalog() {
	return React.useContext(ProductCatalogContext);
}

export function ProductCatalogProvider(props: React.PropsWithChildren<{}>) {
	const isOffline = useOfflineStatus();
	const user = useAppUser();

	const [productCatalog, setProductCatalog] = useCachedOfflineState<ProductCatalog, SerializedProductCatalogData>(
		`offline-productcatalog-${user.tenantId}-v2`,
		ProductCatalog.empty,
		(c) => serializeProductCatalogData(c.data),
		(s) => new ProductCatalog(deserializeProductCatalogData(s)),
	);
	const [loadStatus, setLoadStatus] = useState<AppDataLoadStatus>(isOffline ? "offline" : "loading");
	const [serverError, setServerError] = useState<ServerError | null>(null);

	useEffect(() => {
		if (window.navigator.onLine) {
			ProductCatalogService.get().then((result) => {
				if (result.success) {
					setProductCatalog(result.data);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Only attempt load on first mount
	useEffect(() => {
		if (!isOffline) {
			ProductCatalogService.get().then((result) => {
				if (result.success) {
					setProductCatalog(result.data);
					setLoadStatus("ready");
				} else {
					setServerError(result);
					setLoadStatus("error");
				}
			}).catch((e: string) => {
				console.error(e);
				setServerError(createServerError(e, 999));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onProductLineCreated = (productLine: ProductLine) => {
		const updatedProductCatalog: ProductCatalogData = { ...productCatalog.data, productLines: [...productCatalog.productLines, productLine] };
		setProductCatalog(new ProductCatalog(updatedProductCatalog));
	};

	const onProductLineUpdated = (productLine: ProductLine) => {
		const updatedProductCatalog: ProductCatalogData = {
			...productCatalog.data,
			productLines: productCatalog.productLines.map((pl) => (pl.id === productLine.id ? productLine : pl)),
		};
		setProductCatalog(new ProductCatalog(updatedProductCatalog));
	};

	const onProductListingCreated = (productListing: ProductListing) => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const parentProductLine = productCatalog.productLines.find((pl) => pl.id === productListing.productLineId)!;
		const updatedProductLine: ProductLine = ProductLine.create({ ...parentProductLine, listings: [productListing, ...parentProductLine.allListings] });
		onProductLineUpdated(updatedProductLine);
		return updatedProductLine;
	};

	const onProductListingUpdated = (productListing: ProductListing) => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const parentProductLine = productCatalog.productLines.find((pl) => pl.id === productListing.productLineId)!;
		const updatedProductLine: ProductLine = ProductLine.create({
			...parentProductLine,
			listings: parentProductLine.allListings.map((listing) => (listing.id === productListing.id ? productListing : listing)),
		});
		onProductLineUpdated(updatedProductLine);
		return updatedProductLine;
	};

	return (
		<ProductCatalogContext.Provider
			value={{
				productCatalog: productCatalog,
				propaneListing: productCatalog.data.productLines.find((l) => l.type === ProductCategoryType.Propane)?.availableListings[0] ?? tempPropaneListing,
				addProductLine: onProductLineCreated,
				updateProductLine: onProductLineUpdated,
				addProductListing: onProductListingCreated,
				updateProductListing: onProductListingUpdated,
				loadStatus,
				serverError
			}}
		>
			{props.children}
		</ProductCatalogContext.Provider>
	);
}
