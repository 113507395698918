import { Button, Card, CardContent, Grid, List, ListItem, ListItemText, MenuItem, TextField, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../../components/BackButton";
import { LedgerAccountType, LedgerClassification, QuickBooksOnlineLedgerAccount } from "../../../../entities/accounting/GeneralLedgerAccount";
import { useAlert } from "../../../../hooks/useAlert";
import { LedgerAccountService, QuickBooksOnlineAccount } from "../../../../services/accounting/LedgerAccountsService";
import { useLedgerAccountPage } from "../LedgerAccountPage";
import { Enum } from "../../../../utility/Enum";
import OverlayLoadingScreen from "../../../../components/OverlayLoadingScreen";

export function QuickBooksOnlineLedgerAccountDetailView(props: { account: QuickBooksOnlineLedgerAccount }) {
	const { account } = props;
	const alert = useAlert();
	const context = useLedgerAccountPage();

	const [name, setName] = React.useState(account.name);
	const [quickBooksAccounts, setQuickBooksAccounts] = React.useState<QuickBooksOnlineAccount[]>();
	const [quickBooksAccountId, setQuickBooksAccountId] = React.useState(account.quickBooksAccountId);
	const [disabled, setDisabled] = React.useState(false);

	const selectedQuickBooksAccount = React.useMemo(() => quickBooksAccounts?.find((x) => x.id === quickBooksAccountId), [quickBooksAccounts, quickBooksAccountId]);
	const selectableAccounts = React.useMemo(() => quickBooksAccounts?.filter((x) => x.type === account.type) ?? [], [quickBooksAccounts, account.type]);

	React.useEffect(() => {
		const getQuickBooksAccounts = async () => {
			const result = await LedgerAccountService.getQuickBooksOnlineAccounts();
			if (result.success) {
				setQuickBooksAccounts(result.data);
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		};
		getQuickBooksAccounts();
	}, [alert]);

	const onSave = async () => {
		setDisabled(true);
		const result = await LedgerAccountService.updateQuickBooksOnlineAccount({ accountId: account.id, name, quickBooksAccountId });
		setDisabled(false);
		if (result.success) {
			context.updateLedgerAccount(result.data);
			alert.success("Account Updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	if (!quickBooksAccounts) return <OverlayLoadingScreen />;

	return (
		<Grid container>
			<Grid item xl={4} sm={6} xs={12}>
				<Card>
					<Grid container alignItems="center" style={{padding: 5}}>
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<Typography variant="h5">QuickBooks Online Account</Typography>
						</Grid>
					</Grid>
					<CardContent>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<TextField variant="outlined" label="DispatchPro Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth disabled={disabled} helperText="Name you will see in DispatchPro" />
							</Grid>
							<Grid item xs={12}>
								<List>
									<ListItem>
										<ListItemText
											primary="Classification"
											secondary={
												selectedQuickBooksAccount != null ? Enum.print(LedgerClassification, selectedQuickBooksAccount.classification) : "Loading..."
											}
										/>
										<ListItemText
											primary="Account Type"
											secondary={selectedQuickBooksAccount != null ? Enum.print(LedgerAccountType, selectedQuickBooksAccount.type) : "Loading..."}
										/>
									</ListItem>
								</List>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									select
									label="QuickBooks Account"
									value={quickBooksAccountId ?? null}
									onChange={(e) => setQuickBooksAccountId(e.target.value)}
									fullWidth
									helperText="Accounts from your QuickBooks Online account"
								>
									{selectableAccounts.map((account) => (
										<MenuItem key={account.id} value={account.id}>
											{account.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						</Grid>
					</CardContent>
					<Button variant="contained" color="primary" fullWidth disabled={disabled} onClick={onSave}>
						Save
					</Button>
				</Card>
			</Grid>
		</Grid>
	);
}
