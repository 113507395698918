import { Modify } from "../../utility/modifyType";
import { ProductCategoryType } from "./ProductCategoryType";
import { deserializeProductListing, ProductListing, SerializedProductListing, serializeProductListing } from "./ProductListing";


export class ProductLine {
    id: string;
    name: string;
    type: ProductCategoryType;
    singleListing: ProductListing | null;
    allListings: ProductListing[];
    availableListings: ProductListing[];

    constructor(id: string, name: string, type: ProductCategoryType, listings: ProductListing[]) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.allListings = listings;
        this.availableListings = listings.filter((listing) => !listing.disabled);
        this.singleListing = listings.length === 1 ? listings[0] : null;
    }

    getListing = (listingId: string): ProductListing | undefined => this.allListings.find((listing) => listing.id === listingId);

    static create(data: {id: string, name: string, type: ProductCategoryType, listings: ProductListing[]}): ProductLine {
        return new ProductLine(data.id, data.name, data.type, data.listings);
    }
}

export type SerializedProductLine = Modify<ProductLine, { listings: SerializedProductListing[] }>;
export const deserializeProductLine = (model : SerializedProductLine) => ProductLine.create({
    ...model,
    listings: model.listings.map(deserializeProductListing)
});
export const serializeProductLine = (model: ProductLine): SerializedProductLine => ({ ...model, listings: model.allListings.map(serializeProductListing) });
