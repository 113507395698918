import { PaletteType } from "@material-ui/core";

export type TagColor = "red" | "green" | "blue" | "yellow" | "purple" | "cyan" | "orange" | "gray" | "black";
export type TagType = "Customer" | "Driver Ticket";
export const TagColors: TagColor[] = ["red", "green", "blue", "yellow", "purple", "cyan", "orange", "gray", "black"];

export interface Tag {
    id: number;
    text: string;
    color: TagColor;
    type: TagType;
}

export const getTagColorTheme = (color: TagColor, palette: PaletteType) => {
    if(palette === "light") {
        return LightThemeTagColorsMap[color];
    } else {
        return DarkThemeTagColorsMap[color];
    }
}

type TagColorTheme = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [key in TagColor]: {
        background: string;
        text: string;
    };
};


const LightThemeTagColorsMap: TagColorTheme = {
    red: {
        background: "#f44336",
        text: "#ffffff"
    },
    green: {
        background: "#689f38",
        text: "#ffffff"
    },
    blue: {
        background: "#448aff",
        text: "#ffffff"
    },
    yellow: {
        background: "#ffee58",
        text: "#000000"
    },
    purple: {
        background: "#7e57c2",
        text: "#ffffff"
    },
    cyan: {
        background: "#0097a7",
        text: "#ffffff"
    },
    orange: {
        background: "#ff9800",
        text: "#000000"
    },
    gray: {
        background: "#5c5c5c",
        text: "#ffffff"
    },
    black: {
        background: "#000000",
        text: "#ffffff"
    },
};

const DarkThemeTagColorsMap: TagColorTheme = {
    red: {
        background: "#c62828",
        text: "#ffffff"
    },
    green: {
        background: "#388e3c",
        text: "#ffffff"
    },
    blue: {
        background: "#1565c0",
        text: "#ffffff"
    },
    yellow: {
        background: "#fdd835",
        text: "#000000"
    },
    purple: {
        background: "#5e35b1",
        text: "#ffffff"
    },
    cyan: {
        background: "#00838f",
        text: "#ffffff"
    },
    orange: {
        background: "#e65100",
        text: "#ffffff"
    },
    gray: {
        background: "#9d9d9d",
        text: "#ffffff"
    },
    black: {
        background: "#000000",
        text: "#ffffff"
    },
};
