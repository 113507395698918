import {
	deserializeTankFillConfiguration,
	deserializeTankFillConfigurations,
	ScheduledFillPeriodType,
	SerializedTankFillConfiguration,
} from "../../entities/routing/TankFillConfiguration";
import { Modify } from "../../utility/modifyType";
import { NodaTime } from "../../utility/NodaTime";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreateScheduledFillRequest {
	tankId: string;
	startDate: Date;
	coldPeriodType: ScheduledFillPeriodType;
	coldPeriodValue: number;
	warmPeriodType: ScheduledFillPeriodType;
	warmPeriodValue: number;
	businessDaysBeforeAlert: number;
}

type SerializedCreateScheduledFillRequest = Modify<
	CreateScheduledFillRequest,
	{
		startDate: string;
	}
>;

const serializeCreateScheduledFillRequest = (request: CreateScheduledFillRequest): SerializedCreateScheduledFillRequest => ({
	...request,
	startDate: NodaTime.serializeToLocalDate(request.startDate),
});

interface UpdateScheduledFillRequest {
	id: string;
	nextFillDate?: Date;
	coldPeriodType?: ScheduledFillPeriodType;
	coldPeriodValue?: number;
	warmPeriodType?: ScheduledFillPeriodType;
	warmPeriodValue?: number;
	businessDaysBeforeAlert?: number;
}

type SerializedUpdateScheduledFillRequest = Modify<
	UpdateScheduledFillRequest,
	{
		nextFillDate?: string;
	}
>;

const serializeUpdateScheduledFillRequest = (request: UpdateScheduledFillRequest): SerializedUpdateScheduledFillRequest => ({
	...request,
	nextFillDate: request.nextFillDate ? NodaTime.serializeToLocalDate(request.nextFillDate) : undefined,
});

export const TankFillConfigurationService = {
	getByTank: (tankId: string) =>
		WebClient.GetOptional(`${apiServer}/api/tank-fill-configuration/tank/${tankId}`, (r: SerializedTankFillConfiguration | null) =>
			r ? deserializeTankFillConfiguration(r) : null
		),
	getByCustomer: (customerId: string) => WebClient.GetOptional(`${apiServer}/api/tank-fill-configuration/customer/${customerId}`, deserializeTankFillConfigurations),
	createScheduledFill: (request: CreateScheduledFillRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/tank-fill-configuration/scheduled-fill`, serializeCreateScheduledFillRequest(request), deserializeTankFillConfiguration),
	updateScheduledFill: (request: UpdateScheduledFillRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/tank-fill-configuration/scheduled-fill`, serializeUpdateScheduledFillRequest(request), deserializeTankFillConfiguration),
	remove: (id: string) => WebClient.Delete(`${apiServer}/api/tank-fill-configuration/${id}`),
};
