import { Button, DialogActions, DialogContent, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useAlert } from "../../../../hooks/useAlert";
import { LedgerAccountService, QuickBooksOnlineAccount } from "../../../../services/accounting/LedgerAccountsService";
import { useLedgerAccountPage } from "../LedgerAccountPage";
import CenteredLoadingSpinner from "../../../../components/CenteredLoadingSpinner";
import { LedgerClassification, getAvailableLedgerAccountTypes } from "../../../../entities/accounting/GeneralLedgerAccount";
import { SelectLedgerType } from "./SelectLedgerType";
import { SelectLedgerClassification } from "./SelectLedgerClassification";

export function AddQuickBooksOnlineAccountView(props: { onClose: () => void }) {
	const alert = useAlert();
	const context = useLedgerAccountPage();

	const [quickBooksAccounts, setQuickBooksAccounts] = React.useState<QuickBooksOnlineAccount[]>();
	const [name, setName] = React.useState("");
	const [classification, setClassification] = React.useState(LedgerClassification.Asset);
	const [type, setType] = React.useState(getAvailableLedgerAccountTypes(classification)[0]);
	const [quickBooksAccountId, setQuickBooksAccountId] = React.useState<string>();

	const accountsMatchingType = React.useMemo(() => {
		if (quickBooksAccounts == null) {
			return [];
		}
		return quickBooksAccounts.filter((x) => x.type === type);
	}, [quickBooksAccounts, type]);

	const [disabled, setDisabled] = React.useState(false);

	useEffect(() => {
		setType(getAvailableLedgerAccountTypes(classification)[0]);
	}, [classification]);

	useEffect(() => {
		setQuickBooksAccountId(undefined);
	}, [type, classification]);


	useEffect(() => {
		const getQuickBooksAccounts = async () => {
			const result = await LedgerAccountService.getQuickBooksOnlineAccounts();
			if (result.success) {
				setQuickBooksAccounts(result.data);
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		};
		getQuickBooksAccounts();
	}, [alert]);

	const onSave = async () => {
		if (!quickBooksAccountId) {
			return;
		}
		setDisabled(true);
		const result = await LedgerAccountService.createQuickBooksOnlineAccount({ name, quickBooksAccountId });
		setDisabled(false);
		if (result.success) {
			alert.success(`${result.data.name} Added`);
			context.addLedgerAccount(result.data);
			props.onClose();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	if (quickBooksAccounts == null) {
		return <CenteredLoadingSpinner />;
	}

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField variant="outlined" label="DispatchPro Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth helperText="Name you will see in DispatchPro" />
					</Grid>
					<Grid item xs={6}>
						<SelectLedgerClassification value={classification} onChange={setClassification} />
					</Grid>
					<Grid item xs={6}>
						<SelectLedgerType value={type} onChange={setType} classification={classification} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							select
							variant="outlined"
							label="QuickBooks Account"
							value={quickBooksAccountId ?? null}
							onChange={(e) => setQuickBooksAccountId(e.target.value)}
							fullWidth
							helperText="Accounts from your QuickBooks Online account"
						>
							{accountsMatchingType.map((account) => (
								<MenuItem key={account.id} value={account.id}>
									{account.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={disabled} variant="outlined" color="secondary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={onSave} disabled={disabled || quickBooksAccountId == null}>
					Add Account
				</Button>
			</DialogActions>
		</>
	);
}
