import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { routes } from "../../routes";

export function ReportListPage() {
    const history = useHistory();

	return (
		<Grid container spacing={2}>
			<PageTitle title="Reports" />
            <Grid item xs={12}>
                <Typography variant="h4">Reports</Typography>
            </Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Statement Report</Typography>
						<Typography variant="body1">Generate statements for many customers at once.</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.statementReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Customer Balance Report</Typography>
						<Typography variant="body1">Report of outstanding customers balances.</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.customerBalanceReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Sales Report</Typography>
						<Typography variant="body1">Report on sales and payments</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.salesReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Invoice Report</Typography>
						<Typography variant="body1">Report on invoices and bulk download</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.invoiceReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Payments Report</Typography>
						<Typography variant="body1">Report payments on a day/period</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.paymentsReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Tax Report</Typography>
						<Typography variant="body1">Report on taxes collected on a day/period</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.taxReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Customer Propane Fill Report</Typography>
						<Typography variant="body1">Track number of fills and amount of gas purchased</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.customerPropaneFillReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>

			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Driver Fill Report</Typography>
						<Typography variant="body1">Track number of fills and amount of gas filled by drivers</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.driverFillReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>

			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Tank Alert Report</Typography>
						<Typography variant="body1">See which customers have tanks with automatic tank alerts setup</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.tankAlertReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Tank Last Fill Report</Typography>
						<Typography variant="body1">See the last fill date for active customer tank</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.tankLastFillReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<Card>
					<CardContent>
						<Typography variant="h5">Degree Day Report</Typography>
						<Typography variant="body1">See what tanks might need to be filled based a Degree Day average</Typography>
					</CardContent>
					<Button fullWidth variant="outlined" onClick={() => history.push(routes.app.degreeDayReportPage)}>
						Generate
					</Button>
				</Card>
			</Grid>
		</Grid>
	);
}
