import { Card, CardHeader, List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { Enum } from "../../../utility/Enum";
import { AlertIcon } from "./AlertIcon";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { DetailCardStyles } from "./DetailCardStyles";
import { DeviceType, OtodataDevice, TankFormType } from "../../../entities/routing/OtodataTankMonitor";

export const showTankSpecsDetailCard = (device: OtodataDevice) => {
    return device.type === DeviceType.UltraSonic || device.tankNumber || device.tankSerialNumber || device.capacity > 0;
}

export function TankSpecsDetailCard(props: {device: OtodataDevice}){
    const { device } = props;
    const classes = DetailCardStyles();

    return(
        <Card>
        <CardHeader
            action={
                <AlertIcon alert={device.batteryAlarm}>
                    <ListAltIcon fontSize={"large"} />
                </AlertIcon>
            }
            title="Tank Specs"
            className={classes.denseCardHeader}
        />
        <List>
            {device.tankNumber && (
                <ListItem>
                    <ListItemText primary="Tank Number" secondary={device.tankNumber} />
                </ListItem>
            )}
            {device.tankSerialNumber && (
                <ListItem>
                    <ListItemText primary="Tank Serial Number" secondary={device.tankSerialNumber} />
                </ListItem>
            )}
            {device.capacity > 0 && (
                <ListItem>
                    <ListItemText primary="Capacity" secondary={`${device.capacity.toFixed(0)} Gallons`} />
                </ListItem>
            )}
            {device.type === DeviceType.UltraSonic && (
                <>
                    <ListItem>
                        <ListItemText primary="Form" secondary={Enum.print(TankFormType, device.tankFormType ?? TankFormType.Unknown)} />
                        <ListItemText primary="Depth" secondary={`${device.measureDepth ?? "?"} cm`} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Height" secondary={`${device.measureHeight ?? "?"} cm`} />
                        <ListItemText primary="Width" secondary={`${device.measureWidth ?? "?"} cm`} />
                    </ListItem>
                </>
            )}
        </List>
    </Card>
    );
}