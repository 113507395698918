import { NodaTime } from "../../utility/NodaTime";

export enum ExemptionType {
    Customer = 0,
    PropaneTank = 1,
}

export interface TaxExemptionDocument {
    id: string;
    taxExemptionId: string;
    fileName: string;
    createdAt: Date;
    expirationDate: Date | null;
}


interface TaxExemptionBase {
    id: string;
    customerId: string;
    expirationDate: Date | null;
    goodUntil: Date | null;
    valid: boolean;
    notes: string;
    documents: TaxExemptionDocument[];
}

export interface CustomerTaxExemption extends TaxExemptionBase {
    type: ExemptionType.Customer;
}

export interface PropaneTankTaxExemption extends TaxExemptionBase {
    type: ExemptionType.PropaneTank;
    tankId: string;
}

export type TaxExemption = CustomerTaxExemption | PropaneTankTaxExemption;

type SerializedTaxExemptionDocument = Omit<TaxExemptionDocument, "createdAt" | "expirationDate"> & {
    createdAt: string;
    expirationDate: string | null;
}

function deserializeTaxExemptionDocument(serialized: SerializedTaxExemptionDocument): TaxExemptionDocument {
    const { createdAt, expirationDate, ...rest } = serialized;
    return {
        ...rest,
        createdAt: new Date(createdAt),
        expirationDate: expirationDate ? NodaTime.deserializeFromLocalDate(expirationDate) : null,
    };
}

type SerializedTaxExemptionBase = Omit<TaxExemptionBase, "expirationDate" | "goodUntil" | "documents"> & {
    expirationDate: string | null;
    goodUntil: string | null;
    documents: SerializedTaxExemptionDocument[];
}

type SerializedTaxExemptionCustomer = SerializedTaxExemptionBase & {
    type: ExemptionType.Customer;
}

type SerializedTaxExemptionPropaneTank = SerializedTaxExemptionBase & {
    type: ExemptionType.PropaneTank;
    tankId: string;
}

export type SerializedTaxExemption = SerializedTaxExemptionCustomer | SerializedTaxExemptionPropaneTank;

export function deserializeTaxExemption(serialized: SerializedTaxExemption): TaxExemption {
    const { expirationDate, goodUntil, ...rest } = serialized;
    return {
        ...rest,
        expirationDate: expirationDate ? NodaTime.deserializeFromLocalDate(expirationDate) : null,
        goodUntil: goodUntil ? NodaTime.deserializeFromLocalDate(goodUntil) : null,
        documents: serialized.documents.map(deserializeTaxExemptionDocument),
    };
}

export function deserializeTaxExemptions(serialized: SerializedTaxExemption[]): TaxExemption[] {
    return serialized.map(deserializeTaxExemption);
}