export type TypescriptNumberEnum =  {[key: number]: string};
type UnraveledEnum = {
	id: number;
	name: string;
}

export const Enum = {
    print: <T extends TypescriptNumberEnum>(e: T, value: number): string => {
        return e[value].split("_").join(" ");
    },
    list: <T extends TypescriptNumberEnum>(e: T): UnraveledEnum[] => {
        const list: UnraveledEnum[] = [];

        const keys = Object.keys(e);
        const names = keys.filter((key) => isNaN(Number(key)));
        const ids = keys.map((key) => Number(key)).filter((id) => !isNaN(id));
    
        for (let i = 0; i < names.length; i++) {
            const name = names[i].split("_").join(" ");
            const id = ids[i];
            list.push({ name, id });
        }
    
        return list;
    }
}