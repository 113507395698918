import { Button, Card, CardContent, CardHeader, Chip, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useMemo, useState } from "react";
import { BackButton } from "../../components/BackButton";
import { GridGrow } from "../../components/GridGrow";
import { PageTitle } from "../../components/PageTitle";
import { Price } from "../../components/Price";
import { useAlert } from "../../hooks/useAlert";
import {
	ReportService,
	SalesItem,
	SalesReport,
	PaymentItem,
	PaymentReversalItem,
	DiscountItem,
	SalesReportItem,
	SalesReportItemType,
} from "../../services/report/ReportService";
import { ClientLink } from "../../components/ClientLink";
import { routes } from "../../routes";
import { useProductCatalog } from "../../providers/ProductCatalogProvider";
import { ProductListing } from "../../entities/products/ProductListing";
import { Autocomplete } from "@material-ui/lab";
import { printDate } from "../../utility/PrintDate";

export function SalesReportPage() {
	const alert = useAlert();
	const beginningOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
	const today = new Date();
	const { productCatalog } = useProductCatalog();

	const [disabled, setDisabled] = useState(false);
	const [startDate, setStartDate] = useState(beginningOfMonth);
	const [endDate, setEndDate] = useState(today);
	const [selectedProductListings, setSelectedProductListings] = useState<ProductListing[]>([]);
	const [report, setReport] = useState<SalesReport>();

	const availableListings = useMemo(() => {
		return productCatalog.productLines.flatMap((l) => l.availableListings).filter((l) => selectedProductListings.every((sl) => sl.id !== l.id));
	}, [productCatalog.productLines, selectedProductListings]);

	const generateReport = async () => {
		setDisabled(true);
		const result = await ReportService.salesReport({ startDate, endDate, filterProductListingIds: selectedProductListings.map((l) => l.id) });
		setDisabled(false);

		if (result.success) {
			setReport(result.data);
		} else {
			alert.serverError(result);
		}
	};

	const print = () => {
		if (!report) return;
		// print report in a new window
		const printWindow = window.open("", "PRINT", "height=400,width=600");
		const dateRange =
			startDate.toLocaleDateString() === endDate.toLocaleDateString()
				? startDate.toLocaleDateString()
				: `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;

		if (!printWindow) return;
		printWindow.document.write(`
				<html>
					<head>
						<title>Sales Report</title>
						<style>
							@page {
								size: auto;
								margin: 5mm;
							}
							body {
								margin: 5mm;
							}
							table {
								width: 100%;
								border-collapse: collapse;
								font-size: 10px;
							}
							table, th, td {
								border: 1px solid black;
							}
							th, td {
								padding: 5px;
							}
							th {
								text-align: left;
							}
							.center {
								text-align: center;
							}
							.right {
								text-align: right;
							}
							h1 {
								font-size: 20px;
							}
							h2 {
								font-size: 16px;
							}
						</style>
					</head>
					<body>
						<h1>Sales Report</h1>
						<h2>${dateRange}</h2>
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Description</th>
									<th>Payment</th>
									<th>Gross Sales</th>
									<th>Tax</th>
									<th>Total Sales</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td></td>
									<td>${report.items.length} Item${report.items.length > 1 ? "s" : ""}</td>
									<td>${report.totalPayments}</td>
									<td>${report.grossSales}</td>
									<td>${report.totalTax}</td>
									<td>${report.total}</td>
								</tr>
								${report.items
									.map((item) => {
										switch (item.type) {
											case SalesReportItemType.Sale:
												return `
												<tr>
													<td>${item.date.toLocaleDateString()}</td>
													<td>${item.description}</td>
													<td></td>
													<td class="right">${item.saleAmount}</td>
													<td class="right">${item.taxAmount}</td>
													<td class="right">${item.totalAmount}</td>
												</tr>
											`;
											case SalesReportItemType.Payment:
												return `
												<tr>
													<td>${item.date.toLocaleDateString()}</td>
													<td>${item.description}</td>
													<td class="right">${item.amount}</td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
											`;
											case SalesReportItemType.PaymentReversal:
												return `
												<tr>
													<td>${item.date.toLocaleDateString()}</td>
													<td>${item.description}</td>
													<td class="right">${item.amount * -1}</td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
											`;
											case SalesReportItemType.Discount:
												return `
												<tr>
													<td>${item.date.toLocaleDateString()}</td>
													<td>${item.description}</td>
													<td></td>
													<td class="right">${item.saleAmount * -1}</td>
													<td class="right">${item.taxAmount * -1}</td>
													<td class="right">${item.totalAmount * -1}</td>
												</tr>
											`;
											default:
												return "";
										}
									})
									.join("")}
							</tbody>
						</table>
					</body>
				</html>
			`);
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	};

	const download = () => {
		if (!report) return;
		// download report as a file
		const dateRange =
			startDate.toLocaleDateString() === endDate.toLocaleDateString()
				? printDate.fileFriendlyDate(startDate)
				: `${printDate.fileFriendlyDate(startDate)}-${printDate.fileFriendlyDate(endDate)}`;
		const filename = `Sales Report - ${dateRange}.csv`;
		const csv = `Date,Type,Description,Payment,Gross Sales,Tax,Total Sales\n${report.items
			.map((item) => {
				switch (item.type) {
					case SalesReportItemType.Sale:
						return `${item.date.toLocaleDateString()},Sale,${item.description.replaceAll(",", " ")},,${item.saleAmount},${item.taxAmount},${item.totalAmount}`;
					case SalesReportItemType.Payment:
						return `${item.date.toLocaleDateString()},Payment,${item.description.replaceAll(",", " ")},${item.amount},,,`;
					case SalesReportItemType.PaymentReversal:
						return `${item.date.toLocaleDateString()},Payment Reversal,${item.description.replaceAll(",", " ")},${item.amount * -1},,,`;
					case SalesReportItemType.Discount:
						return `${item.date.toLocaleDateString()},Discount,${item.description.replaceAll(",", " ")},,${item.saleAmount * -1},${item.taxAmount* -1},${item.totalAmount* -1}`;
					default:
						return "";
				}
			})
			.join("\n")}`;
		const blob = new Blob([csv], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = filename;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Sales Report" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Sales Report" subheader="Report on sales and payments." />
						</Grid>
					</Grid>

					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="Start Date"
									inputVariant="outlined"
									fullWidth
									value={startDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setStartDate(date);
									}}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="End Date"
									inputVariant="outlined"
									fullWidth
									value={endDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setEndDate(date);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container alignItems="center" spacing={2}>
									<Grid item>
										<Typography variant="overline">Options</Typography>
									</Grid>
									<GridGrow>
										<Divider />
									</GridGrow>
								</Grid>
								<Grid container spacing={2}>
									<Grid item sm={4} xs={6}>
										<Autocomplete
											options={availableListings}
											getOptionLabel={(option) => option.name}
											onChange={(_, listing) => {
												if (!listing) return;
												setSelectedProductListings((l) => [...l, listing]);
											}}
											renderInput={(params) => <TextField {...params} label="Select Product" variant="outlined" />}
										/>
									</Grid>
									<Grid item sm={8} xs={6}>
										<Grid container spacing={2}>
											{selectedProductListings.map((listing, i) => (
												<Grid item key={i}>
													<Chip
														label={listing.name}
														onDelete={() => setSelectedProductListings((selectedListings) => selectedListings.filter((l) => l.id !== listing.id))}
													/>
												</Grid>
											))}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item>
										<Button variant="contained" color="primary" disabled={disabled} onClick={generateReport}>
											Generate
										</Button>
									</Grid>
									<GridGrow />
									<Grid item>
										<Button variant="outlined" disabled={disabled || !report} onClick={download}>
											Download
										</Button>
									</Grid>
									<Grid item>
										<Button variant="outlined" disabled={disabled || !report} onClick={print}>
											Print
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{report && (
				<Grid item sm={10} xs={12}>
					<Card>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Date</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Description</TableCell>
									<TableCell>Payment</TableCell>
									<TableCell>Gross Sales</TableCell>
									<TableCell>Tax</TableCell>
									<TableCell>Total Sales</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell />
									<TableCell />
									<TableCell>{report.items.length} Items</TableCell>
									<TableCell>
										<Price value={report.totalPayments} />
									</TableCell>
									<TableCell>
										<Price value={report.grossSales} />
									</TableCell>
									<TableCell>
										<Price value={report.totalTax} />
									</TableCell>
									<TableCell>
										<Price value={report.total} />
									</TableCell>
								</TableRow>
								{report.items.map((item, i) => (
									<SalesReportItemRow key={i} item={item} />
								))}
							</TableBody>
						</Table>
					</Card>
				</Grid>
			)}
		</Grid>
	);
}

function SalesReportItemRow(props: { item: SalesReportItem }) {
	const { item } = props;
	if (item.type === SalesReportItemType.Sale) {
		return <SalesRow item={item} />;
	} else if (item.type === SalesReportItemType.Payment) {
		return <PaymentRow item={item} />;
	} else if (item.type === SalesReportItemType.Discount) {
		return <DiscountRow item={item} />;
	} else {
		return <PaymentReversalRow item={item} />;
	}
}

function SalesRow(props: { item: SalesItem }) {
	const { item } = props;
	return (
		<TableRow>
			<TableCell>{item.date.toLocaleDateString()}</TableCell>
			<TableCell>Sale</TableCell>
			<TableCell>
				{item.invoiceId ? (
					<ClientLink newTab to={routes.app.resolve.invoiceDetailPage(item.invoiceId)}>
						{item.description}
					</ClientLink>
				) : (
					item.description
				)}
			</TableCell>
			<TableCell />
			<TableCell>
				<Price value={item.saleAmount} />
			</TableCell>
			<TableCell>
				<Price value={item.taxAmount} />
			</TableCell>
			<TableCell>
				<Price value={item.totalAmount} />
			</TableCell>
		</TableRow>
	);
}

function PaymentRow(props: { item: PaymentItem }) {
	const { item } = props;
	return (
		<TableRow>
			<TableCell>{item.date.toLocaleDateString()}</TableCell>
			<TableCell>Payment</TableCell>
			<TableCell>
				<ClientLink newTab to={routes.app.resolve.paymentDetailPage(item.paymentId)}>
					{item.description}
				</ClientLink>
			</TableCell>
			<TableCell>
				<Price value={item.amount} />
			</TableCell>
			<TableCell />
			<TableCell />
			<TableCell />
		</TableRow>
	);
}

function PaymentReversalRow(props: { item: PaymentReversalItem }) {
	const { item } = props;
	return (
		<TableRow>
			<TableCell>{item.date.toLocaleDateString()}</TableCell>
			<TableCell>Payment Reversal</TableCell>
			<TableCell>
				<ClientLink newTab to={routes.app.resolve.paymentDetailPage(item.paymentId)}>
					{item.description}
				</ClientLink>
			</TableCell>
			<TableCell>
				<Price value={item.amount * -1} />
			</TableCell>
			<TableCell />
			<TableCell />
			<TableCell />
		</TableRow>
	);
}

function DiscountRow(props: { item: DiscountItem }) {
	const { item } = props;
	return (
		<TableRow>
			<TableCell>{item.date.toLocaleDateString()}</TableCell>
			<TableCell>Discount</TableCell>
			<TableCell>
				{item.invoiceId ? (
					<ClientLink newTab to={routes.app.resolve.invoiceDetailPage(item.invoiceId)}>
						{item.description}
					</ClientLink>
				) : (
					item.description
				)}
			</TableCell>
			<TableCell />
			<TableCell>
				<Price value={item.saleAmount * -1} />
			</TableCell>
			<TableCell>
				<Price value={item.taxAmount * -1} />
			</TableCell>
			<TableCell>
				<Price value={item.totalAmount * -1} />
			</TableCell>
		</TableRow>
	);
}
