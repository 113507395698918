import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { PageTitle } from "../../components/PageTitle";
import { SortableTableHeader, TableHeader } from "../../components/table/SortableTableHeader";
import { formatAddress } from "../../entities/customer/GeocodedAddress";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { useAlert } from "../../hooks/useAlert";
import { useDrivers } from "../../providers/DriverProvider";
import { routes } from "../../routes";
import { DriverTicketService, TicketSearchResult } from "../../services/routing/DriverTicketService";
import { TicketStatus } from "./common/TicketStatus";
import { TagText } from "../../components/TagText";


type SearchStatus = "Open" | "Assigned" | "Unassigned" | "Pending" | "Past Due" | "Closed" | "All";
type SortOptions = "Customer" | "Due Date" | "Address" | "Creation Date" | "Ticket Number" | "Delivery Date" | "Completed Date"

const statusOptions: SearchStatus[] = ["Open", "Assigned", "Unassigned", "Pending", "Past Due", "Closed", "All"];
const sortOptions: SortOptions[] = ["Customer", "Due Date", "Address", "Creation Date", "Ticket Number", "Delivery Date", "Completed Date"];

function getQueryParams(search: string) {
	const queryParams = new URLSearchParams(search);
	return {
		customerCode: queryParams.get("customerCode"),
		ticketNumber: queryParams.get("ticketNumber"),
		status: queryParams.get("status") as SearchStatus | null,
		driverId: queryParams.get("driverId"),
		page: queryParams.get("page"),
		pageSize: queryParams.get("pageSize"),
		sortBy: queryParams.get("sortBy") as SortOptions | null,
		sortOrder: queryParams.get("sortOrder") as "asc" | "desc" | null,
	};
}

export function DriverTicketSearchPage() {
	const alert = useAlert();
	const history = useHistory();
    const location = useLocation();
	const queryString = location.search;
	const query = getQueryParams(queryString);
	const {drivers} = useDrivers();

	const [status, setStatus] = useState<SearchStatus>(query.status ?? "All");
	const [customerCode, setCustomerCode] = useState(query.customerCode ?? "");
	const [ticketNumber, setTicketNumber] = useState(query.ticketNumber ?? "");
	const [assignedDriverId, setAssignedDriverId] = useState(query.driverId ?? "");
	const [page, setPage] = useState(query.page ? parseInt(query.page) : 1);
	const [pageSize, setPageSize] = useState(query.pageSize ? parseInt(query.pageSize) : 10);
	const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">(query.sortOrder ?? "desc");
	const [sortBy, setSortBy] = React.useState<SortOptions>(query.sortBy ?? "Creation Date");

	const [disabled, setDisabled] = React.useState(false);
	const [initialLoadComplete, setInitialLoadComplete] = React.useState(false);
	const [searchResult, setSearchResult] = useState<TicketSearchResult>();

    const assignedDriver = drivers.find(d => d.id === assignedDriverId);

	useEffect(() => {
		if(!initialLoadComplete) return;
		const searchParams = new URLSearchParams();
		if (status !== "All") {
			searchParams.set("status", status);
		}
		if (customerCode) {
			searchParams.set("customerCode", customerCode);
		}
		if (ticketNumber) {
			searchParams.set("ticketNumber", ticketNumber);
		}
		if (assignedDriverId) {
			searchParams.set("driverId", assignedDriverId);
		}
		if (page > 1) {
			searchParams.set("page", page.toString());
		}
		if (pageSize !== 10) {
			searchParams.set("pageSize", pageSize.toString());
		}
		if (sortBy !== "Creation Date") {
			searchParams.set("sortBy", sortBy);
		}
		if (sortOrder !== "desc") {
			searchParams.set("sortOrder", sortOrder);
		}
		history.replace(`${routes.app.ticketSearchPage}?${searchParams.toString()}`);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, customerCode, ticketNumber, history, assignedDriverId, page, pageSize, sortBy, sortOrder, initialLoadComplete]);

	useEffect(() => {
		if(initialLoadComplete) return;

		async function initialLoad() {
			const initialParams = getQueryParams(queryString);
			setDisabled(true);
			const result = await DriverTicketService.search({
				customerCode: initialParams.customerCode,
				ticketNumber: initialParams.ticketNumber,
				status: initialParams.status,
				driverId: initialParams.driverId,
				page: initialParams.page ? parseInt(initialParams.page) : 0,
				pageSize: initialParams.pageSize ? parseInt(initialParams.pageSize) : 10,
				sortBy: initialParams.sortBy,
				sortOrder: initialParams.sortOrder,
			});
			setDisabled(false);
			if (result.success) {
				setSearchResult(result.data);
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
			setInitialLoadComplete(true);
		}
		if (queryString) {
			initialLoad();
		} else {
			setInitialLoadComplete(true);
		}
	}, [alert, initialLoadComplete, queryString]);

    
    const loadResults = async (page: number, pageSize: number, sortBy: string | null, sortOrder: "asc" | "desc") => {
        setPage(page);
        setDisabled(true);
        const result = await DriverTicketService.search({
            customerCode: customerCode && customerCode.trim().length > 0 ? customerCode : null,
			ticketNumber: ticketNumber && ticketNumber.trim().length > 0 ? ticketNumber : null,
            status,
            driverId: assignedDriver?.id,
            page,
            pageSize,
            sortBy,
            sortOrder,
        });
        setDisabled(false);
        if (result.success) {
            setSearchResult(result.data);
        } else if (result.validation) {
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

    const search = () => loadResults(1, pageSize, sortBy, sortOrder);

    const onPageSizeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const pageSize = parseInt(event.target.value);
		setPageSize(pageSize);
		await loadResults(1, pageSize, sortBy, sortOrder);
	};

    const onChangeSort = async (property: string, order: "asc" | "desc") => {
        const sortOption = sortOptions.find(option => option === property);
        if(!sortOption) return;
		setSortBy(sortOption);
		setSortOrder(order);
		await loadResults(page, pageSize, sortOption, order);
	};

    const onClickTicket = (ticket: DriverTicket) => history.push(routes.app.resolve.ticketDetailPage(ticket.id));

	return (
		<Grid container spacing={2} justify="center">
			<PageTitle title="Search Tickets" />
			<Grid item md={8} sm={10} xs={12}>
				<Card>
                    <Grid container alignItems="center">
                        <Grid item>
                            <BackButton />
                        </Grid>
                        <Grid item>
                            <CardHeader title="Search Tickets" />
                        </Grid>
                    </Grid>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									select
									disabled={disabled}
									fullWidth
									label="Ticket Status"
									value={status}
									onChange={(e) => setStatus(e.target.value as SearchStatus)}
								>
									{statusOptions.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
                            <Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									select
									disabled={disabled}
									fullWidth
									label="Driver"
									value={assignedDriverId}
									onChange={(e) => setAssignedDriverId(e.target.value)}
								>
									{drivers.map((driver) => (
										<MenuItem key={driver.id} value={driver.id}>
											{driver.name}
										</MenuItem>
									))}
                                    <MenuItem key="All" value="">All</MenuItem>
								</TextField>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									disabled={disabled}
									fullWidth
									label="Customer Code"
									value={customerCode}
									onChange={(e) => setCustomerCode(e.target.value)}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									disabled={disabled}
									fullWidth
									label="Ticket Number"
									value={ticketNumber}
									onChange={(e) => setTicketNumber(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" color="primary" onClick={search} disabled={disabled}>
									Search
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{searchResult && (
				<Grid item md={12} sm={10} xs={12}>
					<Card>
						{searchResult.results.length === 0 && <CardHeader title="No Tickets Found" />}
						{searchResult.results.length > 0 && (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TablePagination
												rowsPerPageOptions={[10, 20, 50]}
												colSpan={9}
												nextIconButtonProps={{ disabled }}
												backIconButtonProps={{ disabled: disabled || page === 1 }}
												count={searchResult.totalCount}
												rowsPerPage={pageSize}
												labelRowsPerPage={"Show"}
												page={page - 1}
												onChangePage={(_, page) => loadResults(page + 1, pageSize, sortBy, sortOrder)}
												onChangeRowsPerPage={onPageSizeChange}
											/>
										</TableRow>
									</TableHead>
									<SortableTableHeader order={sortOrder} orderBy={sortBy}>
										<TableHeader property="Customer" onSort={onChangeSort}>
											Customer
										</TableHeader>
										<TableHeader property="Ticket Number" onSort={onChangeSort}>
											Ticket #
										</TableHeader>
										<TableHeader property="Address" onSort={onChangeSort}>
											Address
										</TableHeader>
										<TableHeader>Status</TableHeader>
										<TableHeader property="Due Date" onSort={onChangeSort} defaultSortOrder="desc">
											Due
										</TableHeader>
										<TableHeader property="Creation Date" onSort={onChangeSort} defaultSortOrder="desc">Created</TableHeader>
										<TableHeader property="Delivery Date" onSort={onChangeSort} defaultSortOrder="desc">Delivery</TableHeader>
										<TableHeader property="Completed Date" onSort={onChangeSort} defaultSortOrder="desc">Completed</TableHeader>
										<TableHeader>Tags</TableHeader>
									</SortableTableHeader>
									<TableBody>
										{searchResult.results.map((ticket) => (
											<TableRow key={ticket.id} hover style={{ cursor: "pointer" }} onClick={() => onClickTicket(ticket)}>
												<TableCell>{ticket.customer.name}</TableCell>
												<TableCell>{ticket.ticketNumber}</TableCell>
												<TableCell>
													{formatAddress(ticket.location)}
												</TableCell>
												<TableCell><TicketStatus ticket={ticket}/></TableCell>
												<TableCell>
													{ticket.dueBy ? ticket.dueBy.toLocaleDateString() : "None"}
												</TableCell>
                                                <TableCell>
                                                    {ticket.created.toLocaleDateString()}
                                                </TableCell>
												<TableCell>
													{ticket.timeOfDelivery ? ticket.timeOfDelivery.toLocaleDateString() : ""}
												</TableCell>
												<TableCell>
													{ticket.timeOfCompletion ? ticket.timeOfCompletion.toLocaleDateString() : ""}
												</TableCell>
												<TableCell>
													{ticket.tags.map((tag) => (
														<TagText key={tag.id} tag={tag} />
													))}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
