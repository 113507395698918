
export const routes = {
	public: {
		signInPage: "/sign-in",
		signOutPage: "/logout",
		forgotPasswordPage: "/forgot-password",
		resetForgotPasswordPage: "/reset-password/:userId/:resetToken",
		acceptInvite: "/accept-invite/:userId/:inviteToken",
		redirect: "/redirect",
		termsOfUse: "/terms-of-use",
		privacyPolicy: "/privacy-policy",
		resolve: {
			signInPage: () => routes.public.signInPage,
			forgotPasswordPage: () => routes.public.forgotPasswordPage,
			resetForgotPasswordPage: (userId: string, resetToken: string) => `/reset-password/${userId}/${resetToken}`,
			acceptInvite: (userId: string, inviteToken: string) => `/accept-invite/${userId}/${inviteToken}`,
		},
	},
	app: {
		routeListPage: "/app/routes",
		driverRoutePage: "/app/routes/:driverId",
		routeTicketPage: "/app/routes/ticket/:ticketId",

		customerPage: "/app/customers",
		customerDetailPage: "/app/customers/:customerId",
		customerContractDetailPage: "/app/customers/:customerId/contracts/:contractId",

		propaneTankSearchPage: "/app/propane-tanks/search",
		propaneTankDetailPage: "/app/propane-tanks/:propaneTankId",
		tankRegulatorDetailPage: "/app/propane-tanks/:propaneTankId/regulators/:gasRegulatorId",

		ticketSearchPage: "/app/tickets/search",
		ticketDetailPage: "/app/tickets/:ticketId",
		reviewTicketsPage: "/app/tickets/review",
		openTicketsPage: "/app/tickets/open",

		tankMonitorDispatchProListPage: "/app/tank-monitors/dispatch-pro",
		tankMonitorOtodataListPage: "/app/tank-monitors/otodata",
		otodataTankMonitorDetailPage: "/app/tank-monitors/otodata/:otodataDeviceId",

		accountPage: "/app/my-account",

		reportListPage: "/app/reports",
		statementReportPage: "/app/reports/statement",
		customerBalanceReportPage: "/app/reports/customer-balance",
		salesReportPage: "/app/reports/sales",
		invoiceReportPage: "/app/reports/invoice",
		paymentsReportPage: "/app/reports/payments",
		taxReportPage: "/app/reports/tax",
		customerPropaneFillReportPage: "/app/reports/customer-propane-fill",
		driverFillReportPage: "/app/reports/driver-fill",
		tankAlertReportPage: "/app/reports/tank-alert",
		tankLastFillReportPage: "/app/reports/tank-last-fill",
		degreeDayReportPage: "/app/reports/degree-day",

		invoicePage: "/app/invoices",
		invoiceSearchPage: "/app/invoices/search",
		invoiceDetailPage: "/app/invoices/:invoiceId",

		paymentsPage: "/app/payments",
		paymentDetailPage: "/app/payments/:paymentTransactionId",

		accountingDashboardPage: "/app/accounting-dashboard",

		checkBatchPage: "/app/check-batches",
		checkBatchDetailPage: "/app/check-batches/:checkBatchId",
		checkBatchCreatePage: "/app/check-batches/create",

		transactionExportBatchPage: "/app/transaction-export-batches",
		transactionExportBatchDetailPage: "/app/transaction-export-batches/:transactionExportBatchId",
		transactionExportBatchCreatePage: "/app/transaction-export-batches/create",

		resolve: {
			routeListPage: () => routes.app.routeListPage,
			driverRoutePage: (driverId: string) => `/app/routes/${driverId}`,
			routeTicketPage: (ticketId: string) => `/app/routes/ticket/${ticketId}`,

			customerListPage: () => routes.app.customerPage,
			customerDetailPage: (customerId: string) => `/app/customers/${customerId}`,
			customerContractDetailPage: (customerId: string, contractId: string) => `/app/customers/${customerId}/contracts/${contractId}`,

			propaneTankSearchPage: () => routes.app.propaneTankSearchPage,
			propaneTankDetailPage: (propaneTankId: string) => `/app/propane-tanks/${propaneTankId}`,
			tankRegulatorDetailPage: (propaneTankId: string, gasRegulatorId: string) => `/app/propane-tanks/${propaneTankId}/regulators/${gasRegulatorId}`,

			ticketSearchPage: (params?: {customerCode?: string | null}) => {
				const { customerCode } = params || {};
				return customerCode ? `/app/tickets/search?customerCode=${customerCode}` : routes.app.ticketSearchPage;
			},
			ticketDetailPage: (driverTicketId: string) => `/app/tickets/${driverTicketId}`,
			reviewTicketsPage: () => routes.app.reviewTicketsPage,
			openTicketsPage: () => routes.app.openTicketsPage,

			tankMonitorDispatchProListPage: () => routes.app.tankMonitorDispatchProListPage,
			tankMonitorOtodataListPage: () => routes.app.tankMonitorOtodataListPage,
			otodataTankMonitorDetailPage: (otodataDeviceId: number | string) => `/app/tank-monitors/otodata/${otodataDeviceId}`,

			accountPage: () => routes.app.accountPage,

			reportListPage: () => routes.app.reportListPage,
			statementReportPage: () => routes.app.statementReportPage,
			customerBalanceReportPage: () => routes.app.customerBalanceReportPage,
			salesReportPage: () => routes.app.salesReportPage,
			invoiceReportPage: () => routes.app.invoiceReportPage,
			paymentsReportPage: () => routes.app.paymentsReportPage,
			customerPropaneFillReportPage: () => routes.app.customerPropaneFillReportPage,
			driverFillReportPage: () => routes.app.driverFillReportPage,
			tankAlertReportPage: () => routes.app.tankAlertReportPage,
			tankLastFillReportPage: () => routes.app.tankLastFillReportPage,
			degreeDayReportPage: () => routes.app.degreeDayReportPage,

			invoicePage: () => routes.app.invoicePage,
			invoiceDetailPage: (invoiceId: string) => `/app/invoices/${invoiceId}`,
			invoiceSearchPage: (params?: {customerCode?: string}) => {
				const { customerCode } = params || {};
				return customerCode ? `/app/invoices/search?customerCode=${customerCode}` : routes.app.invoiceSearchPage;
			},

			paymentsPage: (customerCode?: string) => customerCode ? `/app/payments?customerCode=${customerCode}` : routes.app.paymentsPage,
			paymentDetailPage: (paymentTransactionId: string) => `/app/payments/${paymentTransactionId}`,

			accountingDashboardPage: () => routes.app.accountingDashboardPage,

			checkBatchPage: () => routes.app.checkBatchPage,
			checkBatchDetailPage: (checkBatchId: string) => `/app/check-batches/${checkBatchId}`,
			checkBatchCreatePage: () => routes.app.checkBatchCreatePage,

			transactionExportBatchPage: () => routes.app.transactionExportBatchPage,
			transactionExportBatchDetailPage: (transactionExportBatchId: string) => `/app/transaction-export-batches/${transactionExportBatchId}`,
			transactionExportBatchCreatePage: () => routes.app.transactionExportBatchCreatePage
		},
	},
	business: {
		companyProfilePage: "/app/business/profile",

		documentsPage: "/app/business/documents",

		userPage: "/app/business/users",
		userDetailPage: "/app/business/users/:userId",

		productCatalogPage: "/app/business/product-catalog",
		productLinePage: "/app/business/product-catalog/:productLineId",
		productListingPage: "/app/business/product-catalog/:productLineId/:productListingId",

		generalLedgerAccountsPage: "/app/business/general-ledger-accounts",
		generalLedgerAccountDetailPage: "/app/business/general-ledger-accounts/:generalLedgerAccountId",

		onlineProfilePage: "/app/business/online-profile",

		taxRulesPage: "/app/business/tax-rules",
		taxRuleDetailPage: "/app/business/tax-rules/:taxRuleId",

		paymentTermsPage: "/app/business/payment-terms",
		paymentTermsDetailPage: "/app/business/payment-terms/:paymentTermsId",

		trucksPage: "/app/business/trucks",
		truckDetailPage: "/app/business/trucks/:truckId",

		inspectionListDetailPage: "/app/business/inspection-lists/:inspectionListId",

		planPage: "/app/business/plan",
		planInvoicePage: "/app/business/plan/invoices/:invoiceId",

		integrationPage: "/app/business/integrations",
		integrations: {
			qualpay: {
				connectPage: "/app/business/integrations/qualpay/connect",
				configurationPage: "/app/business/integrations/qualpay/configure",
			},
			quickBooksOnline: {
				connectPage: "/app/business/integrations/quickbooks-online/connect",
				configurationPage: "/app/business/integrations/quickbooks-online/configure",
			},
			otodata: {
				connectPage: "/app/business/integrations/otodata/connect",
				configurationPage: "/app/business/integrations/otodata/configure",
			},
			dispatchProPayments: {
				connectPage: "/app/business/integrations/dispatchpro-payments/connect",
				configurationPage: "/app/business/integrations/dispatchpro-payments/configure",
			}
		},
		integrationDetailPages: () => [
			routes.business.integrations.qualpay.connectPage,
			routes.business.integrations.qualpay.configurationPage,
			routes.business.integrations.quickBooksOnline.connectPage,
			routes.business.integrations.quickBooksOnline.configurationPage,
			routes.business.integrations.otodata.connectPage,
			routes.business.integrations.otodata.configurationPage,
			routes.business.integrations.dispatchProPayments.connectPage,
			routes.business.integrations.dispatchProPayments.configurationPage
		],

		resolve: {
			companyProfilePage: () => routes.business.companyProfilePage,

			documentsPage: () => routes.business.documentsPage,

			userPage: () => routes.business.userPage,
			userDetailPage: (userId: string) => `/app/business/users/${userId}`,

			productCatalogPage: () => routes.business.productCatalogPage,
			productLinePage: (productLineId: string) => `/app/business/product-catalog/${productLineId}`,
			productListingPage: (productLineId: string, productListingId: string) => `/app/business/product-catalog/${productLineId}/${productListingId}`,

			generalLedgerAccountsPage: () => routes.business.generalLedgerAccountsPage,
			generalLedgerAccountDetailPage: (generalLedgerAccountId: number) => `/app/business/general-ledger-accounts/${generalLedgerAccountId}`,

			onlineProfilePage: () => routes.business.onlineProfilePage,

			taxRulesPage: () => routes.business.taxRulesPage,
			taxRuleDetailPage: (taxRuleId: number) => `/app/business/tax-rules/${taxRuleId}`,

			paymentTermsPage: () => routes.business.paymentTermsPage,
			paymentTermsDetailPage: (paymentTermId: string) => `/app/business/payment-terms/${paymentTermId}`,

			trucksPage: () => routes.business.trucksPage,
			truckDetailPage: (truckId: string) => `/app/business/trucks/${truckId}`,

			inspectionListDetailPage: (vehicleInspectionListId: string) => `/app/business/inspection-lists/${vehicleInspectionListId}`,

			planPage: () => routes.business.planPage,
			planInvoicePage: (invoiceId: number) => `/app/business/plan/invoices/${invoiceId}`,

			integrationPage: () => routes.business.integrationPage,
			integrationDetailPage: (uriId: string, action: "connect" | "configure") => `${routes.business.integrationPage}/${uriId}/${action}`,
			integrations: {
				qualpay: {
					connectPage: () => routes.business.integrations.qualpay.connectPage,
					configurationPage: () => routes.business.integrations.qualpay.configurationPage,
				},
				quickBooksOnline: {
					connectPage: () => routes.business.integrations.quickBooksOnline.connectPage,
					configurationPage: () => routes.business.integrations.quickBooksOnline.configurationPage,
				},
				otodata: {
					connectPage: () => routes.business.integrations.otodata.connectPage,
					configurationPage: () => routes.business.integrations.otodata.configurationPage,
				}
			},
		}
	},
	routeBuilder: {
		routeBuilderArea: "/app/route-builder",
		builderPage: "/app/route-builder/builder",
		optimizerPage: "/app/route-builder/optimize",
		routeListPage: "/app/route-builder/routes",
		resolve: {
			builderPage: () => routes.routeBuilder.builderPage,
			optimizerPage: () => routes.routeBuilder.optimizerPage,
			routeListPage: () => routes.routeBuilder.routeListPage,
		}
	},
	admin: {
		tenantListPage: "/app/admin/tenants",
		resolve: {
			tenantListPage: () => routes.admin.tenantListPage,
		}
	}
};
