import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, TextField, DialogActions, Button } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { ExemptionType, TaxExemption } from "../../entities/accounting/TaxExemption";
import { useAlert } from "../../hooks/useAlert";
import { TaxExemptionService } from "../../services/accounting/TaxExemptionService";
import { GridGrow } from "../GridGrow";
import { RedButton } from "../RedButton";
import { TaxExemptionDocuments } from "./TaxExemptionDocuments";

export function TaxExemptionDialog(props: {
	open: boolean;
	taxExemption: TaxExemption | null;
    customerId: string;
    tankId?: string;
	onClose: () => void;
	onChange: (taxExemption: TaxExemption | null) => void;
}) {
	const { open, taxExemption, customerId, tankId, onClose } = props;
	const alert = useAlert();

	const createMode = taxExemption == null;

	const [expirationDate, setExpirationDate] = React.useState(taxExemption?.goodUntil ?? null);
	const [notes, setNotes] = React.useState(taxExemption?.notes ?? "");

	const [disabled, setDisabled] = React.useState(false);

	const createCustomerExemption = async () => {
		setDisabled(true);
		const result = await TaxExemptionService.createCustomerTaxExemption({ customerId, expirationDate, notes });
		setDisabled(false);
		if (result.success) {
			props.onChange(result.data);
			alert.success("Customer Tax Exemption Created");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

    const createTankExemption = async (tankId: string) => {
		setDisabled(true);
		const result = await TaxExemptionService.createTankTaxExemption({ customerId, tankId, expirationDate, notes });
		setDisabled(false);
		if (result.success) {
			props.onChange(result.data);
			alert.success("Tank Tax Exemption Created");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onUpdate = async (taxExemptionId: string) => {
		setDisabled(true);
		const result = await TaxExemptionService.update({ taxExemptionId, expirationDate, notes });
		setDisabled(false);
		if (result.success) {
			props.onChange(result.data);
			alert.success("Tax Exemption Updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onSave = async () => {
        if(taxExemption)
            onUpdate(taxExemption.id);
        else if(tankId)
            createTankExemption(tankId);
        else
            createCustomerExemption();
    }

	const onDelete = async (taxExemptionId: string) => {
		setDisabled(true);
		const result = await TaxExemptionService.delete(taxExemptionId);
		setDisabled(false);
		if (result.success) {
			props.onChange(null);
			onClose();
			alert.success("Tax Exemption Removed");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{taxExemption?.type === ExemptionType.Customer ? "Customer" : "Tank"} Tax Exemption</DialogTitle>
			<DialogContent>
				<DialogContentText>Setup a tax exemption prevents all orders {tankId && "associated with this tank"} from having tax. We recommend uploading digital copies of supporting documents with expiration dates.</DialogContentText>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/dd/yyyy"
							label="Expiration Date"
							inputVariant="outlined"
							fullWidth
							value={expirationDate}
							disabled={disabled}
							onChange={setExpirationDate}
							helperText={taxExemption != null && taxExemption.goodUntil == null ? "This exemption will never expire" : ""}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField label="Notes" variant="outlined" fullWidth value={notes} disabled={disabled} onChange={(e) => setNotes(e.target.value)} multiline />
					</Grid>
					{taxExemption && (
						<Grid item xs={12}>
							<TaxExemptionDocuments taxExemption={taxExemption} onChange={t => props.onChange(t)} />
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				{taxExemption && (
					<RedButton onClick={() => onDelete(taxExemption.id)} variant="contained" disabled={disabled}>
						Remove Exemption
					</RedButton>
				)}
				<GridGrow />
				<Button onClick={onClose} color="secondary" variant="outlined" disabled={disabled}>
					Close
				</Button>
				<Button onClick={onSave} color="primary" variant="contained" disabled={disabled}>
					{createMode ? "Create Tax Exemption" : "Update"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
