import React, { useContext } from "react";
import { Avatar, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Popover, Theme, Typography } from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import PersonIcon from "@material-ui/icons/Person";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AppThemeContext } from "../providers/AppThemeProvider";

import AuthService from "../services/auth/AuthService";
import { useHistory } from "react-router";
import { routes } from "../routes";
import { useTenant } from "../providers/TenantProvider";
import { RestrictToRole } from "./RestrictToRole";
import { useAppUser } from "../providers/AppUserProvider";

export function UserAvatar() {
	const classes = useStyles();
	const history = useHistory();
	const user = useAppUser();
	const {tenant} = useTenant();
	const [appTheme, setTheme] = useContext(AppThemeContext);
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const toggleTheme = () => {
		setTheme(appTheme === "light" ? "dark" : "light");
		closeMenu();
	};

	const myAccountSelected = () => {
		history.push(routes.app.accountPage);
		closeMenu();
	};

	const businessSelected = () => {
		history.push(routes.business.resolve.companyProfilePage());
		closeMenu();
	};

	const adminSelected = () => {
		history.push(routes.admin.resolve.tenantListPage());
		closeMenu();
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	return (
		<>
			<Hidden mdUp>
				<IconButton aria-describedby={id} className={classes.avatarButton} onClick={handleClick}>
					<PersonIcon />
				</IconButton>
			</Hidden>
			<Hidden smDown>
				<Avatar aria-describedby={id} className={classes.avatar} onClick={handleClick}>
					<PersonIcon />
				</Avatar>
			</Hidden>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={closeMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div style={{ width: 250 }}>
					<div style={{ padding: 16, borderBottom: "1px solid #0000001a" }}>
						<Typography variant="body1">
							{user.firstName} {user.lastName}
						</Typography>
						<Typography variant="subtitle2">{user.email}</Typography>
					</div>
					<List component="nav" aria-label="user options">
						<ListItem button onClick={myAccountSelected}>
							<ListItemIcon>
								<AccountBoxIcon />
							</ListItemIcon>
							<ListItemText primary="My Account" />
						</ListItem>
						<RestrictToRole admin>
							<ListItem button onClick={businessSelected}>
								<ListItemIcon>
									<BusinessIcon />
								</ListItemIcon>
								<ListItemText primary={tenant.name} />
							</ListItem>
						</RestrictToRole>
						<RestrictToRole superAdmin>
							<ListItem button onClick={adminSelected}>
								<ListItemIcon>
									<SettingsIcon />
								</ListItemIcon>
								<ListItemText primary="Admin Tools" />
							</ListItem>
						</RestrictToRole>
						<ListItem button onClick={toggleTheme}>
							<ListItemIcon>
								<Brightness4Icon />
							</ListItemIcon>
							<ListItemText primary={`Use ${appTheme === "light" ? "Dark" : "Light"} Theme`} />
						</ListItem>
						<ListItem button onClick={() => AuthService.signOut()}>
							<ListItemIcon>
								<ExitToAppIcon />
							</ListItemIcon>
							<ListItemText primary={`Logout`} />
						</ListItem>
					</List>
				</div>
			</Popover>
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	avatarButton: {
		padding: 4,
		color: theme.palette.primary.contrastText,
	},
	avatar: {
		boxShadow: theme.shadows[3],
		cursor: "pointer",
		height: 32,
		width: 32,
		padding: 5,
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.getContrastText(theme.palette.secondary.main),
	},
}));
