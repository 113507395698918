import { BatchCheck, deserializeBatchCheck, deserializeCheckBatch, SerializedBatchCheck } from "../../entities/accounting/CheckBatch";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CheckBatchListItem {
    id: string;
    batchNumber: number;
    created: Date;
    checkCount: number;
    totalAmount: number;
}

type SerializedCheckBatchListItem = Omit<CheckBatchListItem, 'created'> & { created: string };

function deserializeCheckBatchListItem(item: SerializedCheckBatchListItem): CheckBatchListItem {
    return {
        ...item,
        created: new Date(item.created),
    };
}

export interface CheckBatchList {
    batches: CheckBatchListItem[];
}

type SerializedCheckBatchList = Omit<CheckBatchList, 'batches'> & {
    batches: SerializedCheckBatchListItem[];
};

function deserializeCheckBatchList(list: SerializedCheckBatchList): CheckBatchList {
    return {
        ...list,
        batches: list.batches.map(deserializeCheckBatchListItem),
    };
}

export interface UnBatchedChecks {
    numberOfChecks: number;
    totalAmount: number;
    checks: BatchCheck[];
}

type SerializedUnBatchedChecks = Omit<UnBatchedChecks, 'checks'> & {
    checks: SerializedBatchCheck[];
};

function deserializeUnBatchedChecks(checks: SerializedUnBatchedChecks): UnBatchedChecks {
    return {
        ...checks,
        checks: checks.checks.map(deserializeBatchCheck),
    };
}

export const CheckBatchService = {
    getAll: () => WebClient.Get(`${apiServer}/api/check-batches`, deserializeCheckBatchList),
    get: (id: string) => WebClient.GetOptional(`${apiServer}/api/check-batches/${id}`, deserializeCheckBatch),
    getPending: () => WebClient.GetOptional(`${apiServer}/api/check-batches/pending`, deserializeUnBatchedChecks),
    create: (checkIds: string[]) => WebClient.Post.Validated(`${apiServer}/api/check-batches`, {checkIds}, deserializeCheckBatch),
}