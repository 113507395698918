import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useEffect } from "react";
import { deserializeDriverTicket, DriverTicket, SerializedDriverTicket } from "../entities/routing/DriverTicket";
import AuthService from "../services/auth/AuthService";
import { apiServer } from "../services/server/Setting";
import React from "react";
import { useOfflineContext } from "./OfflineStatusProvider";


export function DriverTicketEventHub(props: {
    onCreate: (ticket: DriverTicket) => void;
    onUpdate: (ticket: DriverTicket) => void;
    onUpdateMany: (tickets: DriverTicket[]) => void;
    onRemove: (ticketId: string) => void;
}) {
    const offlineContext = useOfflineContext();
    const { isOffline, confirmOnlineStatus } = offlineContext;
    const { onCreate, onUpdate, onUpdateMany: onManyUpdate, onRemove } = props;

    

    useEffect(() => {
        const connection = new HubConnectionBuilder().withUrl(`${apiServer}/events/hub`, { accessTokenFactory: AuthService.getJwt }).withAutomaticReconnect().build();

        connection.on("Message", (message: string) => console.log("EventHub Message:", message));

        connection.on("DriverTicketCreated", (serializedTicket: SerializedDriverTicket) => {
            console.log("DriverTicketCreated:", serializedTicket);
            onCreate(deserializeDriverTicket(serializedTicket));
        });
        connection.on("DriverTicketUpdated", (serializedTicket: SerializedDriverTicket) => {
            console.log("DriverTicketUpdated:", serializedTicket);
            onUpdate(deserializeDriverTicket(serializedTicket));
        });
        connection.on("DriverRouteOrderChanged", (serializedTickets: SerializedDriverTicket[]) => {
            console.log("DriverRouteOrderChanged:", serializedTickets);
            onManyUpdate(serializedTickets.map(deserializeDriverTicket));
        });
        connection.on("DriverTicketCanceled", (cancelEvent: {ticketId: string}) => {
            console.log("DriverTicketCanceled:", cancelEvent);
            onRemove(cancelEvent.ticketId);
        });

        connection.onreconnecting(e => {
            console.log("DriverTicketProvider", "Reconnecting", e);
            confirmOnlineStatus();
        });

        connection
            .start()
            .then(() => {
                if (connection.state === HubConnectionState.Connected) {
                    console.log("DriverTicketProvider", "Connected");
                } else {
                    console.error("DriverTicketProvider", "Failed to connect");
                }
            })
            .catch((e) => {
                console.error("DriverTicketProvider", "Failed to connect", e);
            });

        return () => {
            connection.stop();
        };
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [isOffline]);

    return <></>;
}