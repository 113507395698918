import * as React from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import AppContent from "./layouts/AppContent";
import SignIn from "./pages/auth/SignInPage";
import { GlobalLayout } from "./layouts/GlobalLayout";
import * as H from "history";
import * as Sentry from "@sentry/react";
import { ForgotPasswordPage } from "./pages/auth/ForgotPasswordPage";
import { ResetForgottenPage } from "./pages/auth/ResetForgottenPage";
import { AcceptInvitePage } from "./pages/auth/AcceptInvitePage";
import Auth from "./authorization/Auth";
import OverlayLoadingScreen from "./components/OverlayLoadingScreen";
import { TermsOfUse } from "./pages/legal/TermsOfUse";
import { PrivacyPolicy } from "./pages/legal/PrivacyPolicy";
import { OfflineStatusProvider } from "./providers/OfflineStatusProvider";
import { runningOnProd } from "./AppEnv";

function App(props: { history: H.History }) {
	return (
		<Router history={props.history}>
			<OfflineStatusProvider>
				<GlobalLayout>
					<Switch>
						<Route exact path={routes.public.redirect}>
							<OverlayLoadingScreen />
						</Route>
						<Route exact path={routes.public.signInPage}>
							<SignIn />
						</Route>
						<Route exact path={routes.public.signOutPage}>
							<SignIn />
						</Route>
						<Route exact path={routes.public.forgotPasswordPage}>
							<ForgotPasswordPage />
						</Route>
						<Route exact path={routes.public.resetForgotPasswordPage}>
							<ResetForgottenPage />
						</Route>
						<Route exact path={routes.public.acceptInvite}>
							<AcceptInvitePage />
						</Route>
						<Route path={routes.public.termsOfUse}>
							<TermsOfUse />
						</Route>
						<Route path={routes.public.privacyPolicy}>
							<PrivacyPolicy />
						</Route>
						<Route path="/app">
							<AppContent />
						</Route>
						<Redirect to={Auth.signedIn() ? "/app" : routes.public.signInPage} />
					</Switch>
				</GlobalLayout>
			</OfflineStatusProvider>
		</Router>
	);
}

export default runningOnProd() ? Sentry.withProfiler(App) : App;
