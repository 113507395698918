import React from "react";
import { User } from "../../../entities/auth/User";
import { UserClaim } from "../../../entities/auth/UserClaim";
import { UserStatus } from "../../../entities/auth/UserStatus";
import { Enum } from "../../../utility/Enum";
import { orderBy } from "../../../utility/orderBy";
import { HighlightedText } from "../../HighlightedText";
import { ResponsiveTableColumn } from "../../ResponsiveTable";
import { ListViewConfig } from "../ListViewConfig";

type UserDataType = {
	users: User[];
};

const tableColumns = (_data: UserDataType, query: string): ResponsiveTableColumn<User>[] => {
	const name: ResponsiveTableColumn<User> = {
		header: "Name",
		selector: (u) => <HighlightedText query={query}>{u.fullName}</HighlightedText>,
	};

	const email: ResponsiveTableColumn<User> = {
		header: "Email",
		selector: (u) => <HighlightedText query={query}>{u.email}</HighlightedText>,
	};
	const roles: ResponsiveTableColumn<User> = { header: "Roles", selector: (u) => u.claims.map((c) => Enum.print(UserClaim, c)).join(", ") };

	const status: ResponsiveTableColumn<User> = { header: "Status", selector: (u) => Enum.print(UserStatus, u.status) };

	return [name, email, roles, status];
};

const filter = (data: UserDataType) => {
	return data.users;
};

const search = (users: User[], query: string) => {
	const q = query.toLowerCase().trim();
	if (q) {
		return users.filter((u) => u.fullName.toLowerCase().includes(q) || u.email.toLowerCase().includes(q));
	}
	return users;
};

const sort = (users: User[]) => {
	return [...users].sort(orderBy.string((o) => o.lastName, "Ascending"));
};

const emptyMessage = (): string => {
	return "No Users";
};

export const UserListViewConfig: ListViewConfig<UserDataType, User> = {
	tableColumns,
	filter,
	search,
	sort,
	emptyMessage,
};
