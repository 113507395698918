import { SerializedVehicleInspection, VehicleInspection, deserializeVehicleInspection } from "../../entities/routing/VehicleInspection";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface GetTruckInspectionsReportRequest {
	truckId: string;
	page?: number;
	pageSize?: number;
}

export interface TruckInspectionsReportModel {
	count: number;
	page: number;
	pageSize: number;
	inspections: VehicleInspection[];
}

type SerializedTruckInspectionsReportModel = Omit<TruckInspectionsReportModel, "inspections"> & { inspections: SerializedVehicleInspection[] };

function deserializeTruckInspectionsReportModel(serialized: SerializedTruckInspectionsReportModel): TruckInspectionsReportModel {
	return {
		...serialized,
		inspections: serialized.inspections.map(deserializeVehicleInspection),
	};
}

function reportFileName(vehicleInspection: VehicleInspection): string {
	const date = vehicleInspection.createdAt;
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();
	const firstFourOfId = vehicleInspection.id.substring(0, 4);
	return `${vehicleInspection.truckName}-inspection_${month}-${day}-${year}_${firstFourOfId}.pdf`;
}

export const VehicleInspectionService = {
	getCompleted: (request: GetTruckInspectionsReportRequest) =>
		WebClient.Post.Unvalidated(`${apiServer}/api/inspections/report/truck`, request, deserializeTruckInspectionsReportModel),
	downloadReport: (vehicleInspection: VehicleInspection) =>
		WebClient.Download.Get(`${apiServer}/api/inspections/${vehicleInspection.id}/pdf`, reportFileName(vehicleInspection), "application/pdf"),
};
