import { InputBaseComponentProps } from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField/TextField";
import React from "react";
import MaskedInput from "react-text-mask";
import { PhoneNumber } from "../entities/customer/PhoneNumber";
import { Modify } from "../utility/modifyType";

type Props = Modify<TextFieldProps, {
	onChangePhoneNumber: (phoneNumber: PhoneNumber | null) => void;
	allowExtensions: boolean;
	value: PhoneNumber | null;
}>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PhoneMask = (props: InputBaseComponentProps) => {
	const { inputRef, ...other } = props;
	const mask = (rawValue: string) => {
		const maskArray: (string | RegExp)[] = [];
		const value = rawValue.replace(/[^0-9]/g, "");
		if (value.length > 0) {
			maskArray.push("(", /[1-9]/, /\d/, /\d/);
		}
		if (value.length > 3) {
			maskArray.push(")", " ", /\d/, /\d/, /\d/);
		}
		if (value.length > 6) {
			maskArray.push("-", /\d/, /\d/, /\d/, /\d/);
		}
		if (value.length > 10) {
			maskArray.push(" ");
			maskArray.push("x");
			const extraDigits = value.length - 10;
			for (let i = 0; i < extraDigits; i++) {
				maskArray.push(/\d/);
			}
		}
		return maskArray;
	}
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={mask}
			guide={false}
			// placeholderChar={"\u2000"}
			showMask
		/>
	);
};

export function PhoneTextField(props: TextFieldProps & Props) {
	const { onChangePhoneNumber, allowExtensions, value, ...textFieldProps } = props;

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const numbers = e.target.value.replace(/\D/g, "");
		if(numbers.length === 0) {
			onChangePhoneNumber(null);
		}
		else if(numbers.length <= 10) {
			onChangePhoneNumber({number: numbers, extension: null});
		} else {
			const phoneNumber = {number: numbers.substring(0, 10), extension: allowExtensions ? numbers.substring(10) : null};
			onChangePhoneNumber(phoneNumber);
		}
	}

	const numberValue = value ? `${value.number}${value.extension??""}` : "";

	return (
		<TextField
			{...textFieldProps}
			value={numberValue}
			onChange={onChange}
			type="tel"
			role="presentation"
			autoComplete="off"
			onFocus={(e) => {
				e.preventDefault();
				const { target } = e;
				target.focus();
				const blankIndex = (target.value ?? "").indexOf("_");

				if (blankIndex !== -1) {
					target.setSelectionRange(blankIndex, blankIndex + 1);
				}
			}}
			InputProps={{
				...textFieldProps.InputProps,
				inputComponent: PhoneMask,
			}}
		/>
	);
}
