import { Tab, Tabs, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Enum } from "../../utility/Enum";
import { useLocalStorage } from "../../utility/useLocalStorage";
import { TabConfig } from "./ListViewConfig";

export function ListViewTableTabs(props: { tabConfig: TabConfig; onChange: (tab: number) => void }) {
	const { tabConfig } = props;
	const theme = useTheme();
	const darkMode = theme.palette.type === "dark";
	const [tabs] = useState(Enum.list(tabConfig.tabType));
	const [selectedTabId, setSelectedTabId] = useLocalStorage<number>(tabConfig.tabLocalStorageName, tabs[0].id);

	// Broadcast the last known localstorage state as a change event
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => props.onChange(selectedTabId), []);

	const onTabChange = (tab: number) => {
		setSelectedTabId(tab);
		props.onChange(tab);
	};

	return (
		<Tabs
			value={selectedTabId}
			onChange={(_event, value) => onTabChange(value)}
			indicatorColor={darkMode ? "primary" : "secondary"}
			textColor={darkMode ? "primary" : "secondary"}
			variant="scrollable"
		>
			{tabs.map((tab) => (
				<Tab key={tab.id} value={tab.id} label={tab.name} />
			))}
		</Tabs>
	);
}
