import { Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { BackButton } from "../../components/BackButton";
import { DegreeDayReport, ReportService } from "../../services/report/ReportService";
import { useAlert } from "../../hooks/useAlert";
import { NumberTextField } from "../../components/NumberTextField";
import { ClientLink } from "../../components/ClientLink";
import { routes } from "../../routes";

export function DegreeDayReportPage() {
	const alert = useAlert();

	const [degreeDayMaxTemperature, setDegreeDayMaxTemperature] = useState<number | null>(65);
	const [minDaysSinceFill, setMinDaysSinceFill] = useState<number | null>(null);
	const [deliveriesToInclude, setDeliveriesToInclude] = useState<number | null>(2);
	const [disabled, setDisabled] = useState(false);
	const [report, setReport] = useState<DegreeDayReport>();

	const generateReport = async () => {
		if (!deliveriesToInclude || deliveriesToInclude < 2) {
			alert.error("Must include at least 2 deliveries");
			return;
		}
		setDisabled(true);
		const result = await ReportService.degreeDayReport({ degreeDayMaxTemperature, minDaysSinceFill, deliveriesToInclude });
		setDisabled(false);

		if (result.success) {
			setReport(result.data);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Degree Day Report" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Degree Day Report" subheader="See what tanks might need to be filled based a Degree Day average" />
						</Grid>
					</Grid>

					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<NumberTextField
									label="Degree Day Max Temperature"
                                    variant="outlined"
									value={degreeDayMaxTemperature}
									onNumberChange={setDegreeDayMaxTemperature}
									disabled={disabled}
									helperText="The temperature Fahrenheit below which a degree day is counted"
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<NumberTextField
									label="Minimum Days Since Fill"
                                    variant="outlined"
									value={minDaysSinceFill}
									onNumberChange={setMinDaysSinceFill}
									disabled={disabled}
									helperText="Minimum days since last fill to include in report"
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<NumberTextField
									label="Deliveries to Include"
                                    variant="outlined"
									value={deliveriesToInclude}
									onNumberChange={setDeliveriesToInclude}
									disabled={disabled}
									helperText="Max number of deliveries to use to calculate degree day average"
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1" color="textSecondary">
									Warning: This report may take a long time to generate depending on whether the weather data has been used before. First time runs will take
									a long time, but it will be faster on subsequent runs.
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item>
										<Button variant="contained" color="primary" disabled={disabled} onClick={generateReport}>
											Generate
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{report && (
				<Grid item sm={10} xs={12}>
					<Report report={report} />
				</Grid>
			)}
		</Grid>
	);
}

function Report(props: { report: DegreeDayReport }) {
	const { report } = props;

	return (
		<Card>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Customer</TableCell>
						<TableCell>Tank</TableCell>
						<TableCell>Location</TableCell>
						<TableCell>Last Fill</TableCell>
						<TableCell>Avg Gal/DD</TableCell>
						<TableCell>DD Since Last Fill</TableCell>
						<TableCell>~Gal Left</TableCell>
						<TableCell>~DD Before Empty</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{report.items.map((item, index) => (
						<TableRow key={index}>
							<TableCell>
								<ClientLink to={routes.app.resolve.customerDetailPage(item.customerId)}>{item.customerName}</ClientLink>
							</TableCell>
							<TableCell>
								<ClientLink to={routes.app.resolve.propaneTankDetailPage(item.tankId)}>{item.tankGallons} Gal</ClientLink>
							</TableCell>
							<TableCell>{item.weatherLocationName}</TableCell>
							<TableCell>
								{item.daysSinceLastFill} days [{item.lastFillDate.toLocaleDateString()}]
							</TableCell>
							<TableCell>{item.averageGallonsPerDegreeDay}</TableCell>
							<TableCell>{item.degreeDaysSinceLastFill}</TableCell>
							<TableCell>{item.estimatedGallonsRemaining}</TableCell>
							<TableCell>{item.estimatedDegreeDaysRemaining}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Card>
	);
}
