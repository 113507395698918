import { Box, Button, Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { GridGrow } from "../../../components/GridGrow";
import { Invoice, InvoiceItemType } from "../../../entities/billing/Invoice";
import { Price } from "../../../components/Price";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { routes } from "../../../routes";
import { Link } from "react-router-dom";
import { ButtonLink } from "../../../components/ButtonLink";
import { orderBy } from "../../../utility/orderBy";
import { ChangeInvoicePaymentTermsDialog } from "../dialog/ChangeInvoicePaymentTermsDialog";
import { InvoiceItemRow } from "./InvoiceItemRow";
import { AddFeeToInvoiceDialog } from "../dialog/AddFeeToInvoiceDialog";
import { SoldInvoiceItemDetailDialog } from "../dialog/SoldInvoiceItemDetailDialog";
import { ChangeInvoiceDueDateDialog } from "../dialog/ChangeInvoiceDueDateDialog";
import { EditNotesDialog } from "../dialog/EditNotesDialog";
import { AddSaleToInvoiceDialog } from "../dialog/AddSaleToInvoiceDialog";
import { ClientLink } from "../../../components/ClientLink";
import { AddTaxToInvoiceDialog } from "../dialog/AddTaxToInvoiceDialog";
import { TransferInvoiceDialog } from "../dialog/TransferInvoiceDialog";
import { SafeMoney } from "../../../utility/Money";
import { ChangeInvoiceCodeDialog } from "../dialog/ChangeInvoiceCodeDialog";
import { VoidInvoiceDialog } from "../dialog/VoidInvoiceDialog";

interface Props {
	invoice: Invoice;
	onUpdate: (invoice: Invoice) => void;
}

export function InvoiceDetailCard(props: Props) {
	const { invoice } = props;
	const theme = useTheme();

	const displayItems = useMemo(
		() => invoice.items.filter((i) => i.type !== InvoiceItemType.PaymentReversal).sort(orderBy.date((i) => i.created, "Ascending")),
		[invoice.items]
	);
	const [showChangeDueDate, setShowChangeDueDate] = useState(false);
	const [showChangeInvoiceCode, setShowChangeInvoiceCode] = useState(false);
	const [showChangeTerms, setShowChangeTerms] = useState(false);
	const [showInvoiceItemId, setShowInvoiceItemId] = useState<string>();
	const [showTransfer, setShowTransfer] = useState(false);
	const [addFee, setAddFee] = useState(false);
	const [addSale, setAddSale] = useState(false);
	const [addTax, setAddTax] = useState(false);
	const [voidInvoice, setVoidInvoice] = useState(false);
	const [editNotes, setEditNotes] = useState(false);

	const onInvoiceCodeChanged = (invoice: Invoice) => {
		setShowChangeInvoiceCode(false);
		props.onUpdate(invoice);
	};

	const onDueDateChanged = (invoice: Invoice) => {
		setShowChangeDueDate(false);
		props.onUpdate(invoice);
	};

	const onTermsChanged = (invoice: Invoice) => {
		setShowChangeTerms(false);
		props.onUpdate(invoice);
	};

	const onFeeAdded = (invoice: Invoice) => {
		setAddFee(false);
		props.onUpdate(invoice);
	};

	const onSaleAdded = (invoice: Invoice) => {
		setAddSale(false);
		props.onUpdate(invoice);
	};

	const onTaxAdded = (invoice: Invoice) => {
		setAddTax(false);
		props.onUpdate(invoice);
	};

	const onVoidInvoice = (invoice: Invoice) => {
		setVoidInvoice(false);
		props.onUpdate(invoice);
	};

	const onSoldInvoiceItemChanged = (invoice: Invoice) => {
		props.onUpdate(invoice);
	};

	const onNotesChanged = (invoice: Invoice) => {
		setEditNotes(false);
		props.onUpdate(invoice);
	};

	const onTransfer = (invoice: Invoice) => {
		setShowTransfer(false);
		props.onUpdate(invoice);
	};

	return (
		<Card>
			<ChangeInvoiceCodeDialog invoice={invoice} open={showChangeInvoiceCode} onClose={() => setShowChangeInvoiceCode(false)} onCodeChanged={onInvoiceCodeChanged} />
			<ChangeInvoiceDueDateDialog invoice={invoice} open={showChangeDueDate} onClose={() => setShowChangeDueDate(false)} onDueDateChanged={onDueDateChanged} />
			<ChangeInvoicePaymentTermsDialog invoice={invoice} open={showChangeTerms} onClose={() => setShowChangeTerms(false)} onTermsChanged={onTermsChanged} />
			<AddFeeToInvoiceDialog invoice={invoice} open={addFee} onClose={() => setAddFee(false)} onFeeAdded={onFeeAdded} />
			<AddSaleToInvoiceDialog invoice={invoice} open={addSale} onClose={() => setAddSale(false)} onSaleAdded={onSaleAdded} />
			<AddTaxToInvoiceDialog invoice={invoice} open={addTax} onClose={() => setAddTax(false)} onTaxAdded={onTaxAdded} />
			<VoidInvoiceDialog invoice={invoice} open={voidInvoice} onClose={() => setVoidInvoice(false)} onVoidInvoice={onVoidInvoice} />
			<SoldInvoiceItemDetailDialog
				invoiceItemId={showInvoiceItemId}
				invoice={invoice}
				onClose={() => setShowInvoiceItemId(undefined)}
				onChange={onSoldInvoiceItemChanged}
			/>
			<EditNotesDialog invoice={invoice} open={editNotes} onClose={() => setEditNotes(false)} onUpdate={onNotesChanged} />
			<TransferInvoiceDialog invoice={invoice} open={showTransfer} onClose={() => setShowTransfer(false)} onTransfer={onTransfer} />
			<CardHeader
				title={
					<Grid container alignItems="flex-start" spacing={4}>
						<Grid item>
							<Typography variant="h6">Bill To:</Typography>
							<Typography>
								<Link to={routes.app.resolve.customerDetailPage(invoice.customerId)} style={{ cursor: "pointer", fontWeight: 500, color: "unset" }}>
									{invoice.customerName}
								</Link>
							</Typography>
							<Typography>{invoice.customerAddress.street}</Typography>
							<Typography>
								{invoice.customerAddress.city}, {invoice.customerAddress.state} {invoice.customerAddress.postalCode}
							</Typography>
							<Button variant="outlined" size="small" onClick={() => setShowTransfer(true)}>
								Change Customer
							</Button>
						</Grid>
						<GridGrow />
						<Grid item xs={12} sm={6} lg={4}>
							<Grid container alignItems="flex-end" style={{ paddingRight: 12 }}>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="h6">Status:</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="h6">{invoice.voided ? "Void" : invoice.closed ? "Paid" : "Unpaid"}</Typography>
									</Box>
								</Grid>
							</Grid>
							<Grid container alignItems="flex-end" style={{ paddingRight: 12 }}>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
											Code:
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<ButtonLink onClick={() => setShowChangeInvoiceCode(true)}>
											<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
												{invoice.shortId}
											</Typography>
										</ButtonLink>
									</Box>
								</Grid>
							</Grid>
							<Grid container alignItems="flex-end" style={{ paddingRight: 12 }}>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
											Issued On:
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
											{invoice.issuedOn.toLocaleDateString()}
										</Typography>
									</Box>
								</Grid>
							</Grid>
							<Grid container alignItems="flex-end" style={{ paddingRight: 12 }}>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
											Due Date:
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<ButtonLink onClick={() => setShowChangeDueDate(true)}>
											<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
												{invoice.dueBy.toLocaleDateString()}
											</Typography>
										</ButtonLink>
									</Box>
								</Grid>
							</Grid>
							<Grid container alignItems="flex-end" style={{ paddingRight: 12 }}>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
											Terms:
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<ButtonLink onClick={() => setShowChangeTerms(true)}>
											<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
												{invoice.paymentTermsName}
											</Typography>
										</ButtonLink>
									</Box>
								</Grid>
							</Grid>
							{invoice.ticket && (
								<Grid container alignItems="flex-end" style={{ paddingRight: 12 }}>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-end">
											<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
												Ticket:
											</Typography>
										</Box>
									</Grid>
									<Grid item xs={6}>
										<Box display="flex" justifyContent="flex-end">
											<ClientLink to={routes.app.resolve.ticketDetailPage(invoice.ticket.id)}>
												<Typography variant="subtitle2" style={{ fontSize: "0.90rem", paddingTop: 4 }}>
													{invoice.ticket.ticketNumber ? `Ticket ${invoice.ticket.ticketNumber}` : "See Ticket"}
												</Typography>
											</ClientLink>
										</Box>
									</Grid>
								</Grid>
							)}
							<Grid container alignItems="flex-end" style={{ paddingTop: 6, paddingBottom: 6, paddingRight: 12 }}>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="h6">Balance Due:</Typography>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Typography variant="h6">
											<Price value={invoice.balance} />
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				}
			/>
			<CardContent>
				<TableContainer component={Paper}>
					<Table className="" size="small" aria-label="a dense table of orders">
						<TableHead>
							<TableRow>
								<TableCell>Item</TableCell>
								<TableCell align="right">Date</TableCell>
								<TableCell align="right">Quantity</TableCell>
								<TableCell align="right">Subtotal</TableCell>
								<TableCell align="right">Tax</TableCell>
								<TableCell align="right">Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{displayItems.map((item) => (
								<InvoiceItemRow key={item.id} item={item} invoiceItems={invoice.items} onShowSaleDetails={(i) => setShowInvoiceItemId(i.id)} />
							))}
						</TableBody>
					</Table>
				</TableContainer>

				<Grid container spacing={2} style={{ paddingTop: 14 }} direction="row-reverse">
					<Grid item>
						<Table size="small">
							<TableBody>
								<SummaryRow label="Subtotal" value={invoice.subtotal} />
								<SummaryRow label="Tax" value={invoice.tax} />
								<SummaryRow label="Total" value={invoice.total} />
								<SummaryRow label="Paid" value={invoice.paid} />
							</TableBody>
						</Table>
					</Grid>
					<GridGrow />
					<Grid item>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item>
										<Button variant="outlined" onClick={() => setAddFee(true)}>
											Add Fee
										</Button>
									</Grid>
									<Grid item>
										<Button variant="outlined" onClick={() => setAddSale(true)}>
											Add Sale
										</Button>
									</Grid>
									<Grid item>
										<Button variant="outlined" onClick={() => setAddTax(true)}>
											Add Tax
										</Button>
									</Grid>
									{!invoice.notes && (
										<Grid item>
											<Button variant="outlined" onClick={() => setEditNotes(true)}>
												Add Notes
											</Button>
										</Grid>
									)}
									<Grid item>
										<Button variant="outlined" onClick={() => setVoidInvoice(true)} disabled={invoice.voided} color="secondary">
											Void Invoice
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{invoice.notes && (
						<Grid item xs={12}>
							<Box p={2} pt={1} style={{ backgroundColor: theme.palette.type === "dark" ? "#525252" : "#eeeeee" }}>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Typography variant="h6">Notes</Typography>
									</Grid>
									<GridGrow />
									<Grid item>
										<Typography variant="body2">
											<ButtonLink onClick={() => setEditNotes(true)}>Edit Notes</ButtonLink>
										</Typography>
									</Grid>
								</Grid>
								<Typography variant="body2" style={{ whiteSpace: "pre-wrap", marginTop: 7 }}>
									{invoice.notes}
								</Typography>
							</Box>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
}

function SummaryRow(props: { label: string; value: SafeMoney }) {
	const { label, value } = props;
	return (
		<TableRow>
			<TableCell align="right" style={{ padding: 2, paddingRight: 4, border: "none", fontSize: "0.85rem", fontWeight: 500 }}>
				{label}:
			</TableCell>
			<TableCell align="right" style={{ padding: 2, paddingRight: 10, border: "none", fontSize: "0.85rem", fontWeight: 500 }}>
				<Price value={value} />
			</TableCell>
		</TableRow>
	);
}
