import React from "react";
import { useAlert } from "../../../hooks/useAlert";
import { Grid, Typography, Card, TableHead, TableRow, TableCell, TableBody, Table, Button, TablePagination } from "@material-ui/core";
import { BackButton } from "../../../components/BackButton";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { Price } from "../../../components/Price";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes";
import { TransactionExportGroup } from "../../../entities/accounting/TransactionExportBatch";
import { TransactionExportBatchService } from "../../../services/accounting/TransactionExportBatchService";
import { GridGrow } from "../../../components/GridGrow";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { addDays } from "date-fns";
import { ExportBalanceChangesCard } from "./ExportBalanceChangesCard";

function getEarliestDate(groups: TransactionExportGroup[]) {
	if (!groups.length) return null;
	return groups.reduce((earliest, group) => {
		if (group.date < earliest) return group.date;
		return earliest;
	}, groups[0].date);
}

function getLatestDate(groups: TransactionExportGroup[]) {
	if (!groups.length) return null;
	return groups.reduce((latest, group) => {
		if (group.date > latest) return group.date;
		return latest;
	}, groups[0].date);
}

export function CreateTransactionExportBatchPage() {
	const alert = useAlert();
	const history = useHistory();

	const [transactionGroups, setTransactionGroups] = React.useState<TransactionExportGroup[]>();
	const [selectedTransactionIds, setSelectedTransactionIds] = React.useState<Set<string>>(new Set());
	const [disabled, setDisabled] = React.useState(false);
	const [earliestDate, setEarliestDate] = React.useState<Date | null>(null);
	const [latestDate, setLatestDate] = React.useState<Date | null>(null);
	const hasDateRange = earliestDate && latestDate && earliestDate.toDateString() !== latestDate.toDateString();
	const [page, setPage] = React.useState(0);
	const rowsPerPage = 10;

	const selectedTransactions = React.useMemo(
		() => transactionGroups?.filter((group) => selectedTransactionIds.has(group.transactionId)) ?? [],
		[transactionGroups, selectedTransactionIds]
	);
	const displayedTransactions = React.useMemo(() => {
		if (!transactionGroups) return [];
		return transactionGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
	}, [transactionGroups, page]);
	const earliestTransactionDate = React.useMemo(() => getEarliestDate(selectedTransactions), [selectedTransactions]);
	const latestTransactionDate = React.useMemo(() => getLatestDate(selectedTransactions), [selectedTransactions]);

	React.useEffect(() => {
		async function loadUnBatchedChecks() {
			const result = await TransactionExportBatchService.getUnBatchedExports();
			if (result.success) {
				setTransactionGroups(result.data.groups);
				setEarliestDate(getEarliestDate(result.data.groups));
				setLatestDate(getLatestDate(result.data.groups));
				setSelectedTransactionIds(new Set(result.data.groups.map((g) => g.transactionId)));
			} else {
				alert.serverError(result);
			}
		}
		loadUnBatchedChecks();
	}, [alert]);

	const createBatch = async () => {
		if (!transactionGroups) return;
		setDisabled(true);
		const result = await TransactionExportBatchService.create({ transactionIds: Array.from(selectedTransactionIds) });
		setDisabled(false);
		if (result.success) {
			alert.success("Transaction batch created successfully.");
			history.replace(routes.app.resolve.transactionExportBatchDetailPage(result.data.id));
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onChangeEarliestDate = (date: Date | null) => {
		if (!date) return;
		setEarliestDate(date);

		setSelectedTransactionIds(() => {
			if (!transactionGroups) return new Set();
			const latestDateCompare = latestDate ? addDays(new Date(latestDate.toDateString()), 1) : null;
			return new Set(
				transactionGroups.filter((group) => group.date >= date && (latestDateCompare == null || group.date <= latestDateCompare)).map((group) => group.transactionId)
			);
		});
	};

	const onChangeLatestDate = (date: Date | null) => {
		if (!date) return;
		setLatestDate(date);

		setSelectedTransactionIds(() => {
			if (!transactionGroups) return new Set();
			const latestDateCompare = addDays(new Date(date.toDateString()), 1);
			return new Set(
				transactionGroups
					.filter((group) => group.date <= latestDateCompare && (earliestDate == null || group.date >= earliestDate))
					.map((group) => group.transactionId)
			);
		});
	};

	if (!transactionGroups) return <OverlayLoadingScreen />;

	return (
		<>
			<PageTitle title="Create Accounting Export Batch" />
			<Grid container>
				<Grid item>
					<BackButton />
				</Grid>
				<Grid item>
					<Typography variant="h2" gutterBottom>
						Create Accounting Export Batch
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item lg={6} md={8} sm={10} xs={12}>
							<Card style={{ marginBottom: 10, padding: 10 }}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													format="MM/dd/yyyy"
													label="Start Date"
													inputVariant="outlined"
													fullWidth
													value={earliestDate}
													disabled={disabled}
													onChange={onChangeEarliestDate}
													style={{ maxWidth: 200 }}
												/>
											</Grid>
											<Grid item>
												<KeyboardDatePicker
													disableToolbar
													variant="inline"
													format="MM/dd/yyyy"
													label="End Date"
													inputVariant="outlined"
													fullWidth
													value={latestDate}
													disabled={disabled}
													onChange={onChangeLatestDate}
													style={{ maxWidth: 200 }}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Typography variant="h6" gutterBottom>
											Selected Transactions: {selectedTransactions.length}
											{selectedTransactions.length !== transactionGroups.length && ` of ${transactionGroups.length}`}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="h6" gutterBottom>
											Date{hasDateRange && " Range"}: {earliestTransactionDate?.toLocaleDateString() ?? "None"}
											{hasDateRange && ` - ${latestTransactionDate?.toLocaleDateString()}`}
										</Typography>
									</Grid>
									<GridGrow />
									<Grid item>
										<Button variant="contained" color="primary" onClick={createBatch} disabled={disabled || selectedTransactions.length === 0}>
											Create Batch
										</Button>
									</Grid>
								</Grid>
							</Card>
							<Card>
								<Table size="small">
									<TableHead>
										{transactionGroups.length > 0 && (
											<TableRow>
												<TablePagination
													colSpan={4}
													rowsPerPageOptions={[]}
													count={selectedTransactions.length}
													rowsPerPage={10}
													page={page}
													onChangePage={(_, p) => setPage(p)}
												/>
											</TableRow>
										)}
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Type</TableCell>
											<TableCell>Customer</TableCell>
											<TableCell>Balance Change</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{transactionGroups.length === 0 && (
											<TableRow>
												<TableCell colSpan={4} style={{ textAlign: "center" }}>
													No pending transactions found.
												</TableCell>
											</TableRow>
										)}

										{displayedTransactions.map((group) => (
											<Row key={group.transactionId} group={group} />
										))}
									</TableBody>
								</Table>
							</Card>
						</Grid>
						<Grid item lg={6} md={8} sm={10} xs={12}>
							<ExportBalanceChangesCard selectedTransactions={selectedTransactions} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

function Row(props: { group: TransactionExportGroup }) {
	const { group } = props;

	return (
		<TableRow key={group.transactionId}>
			<TableCell style={{ fontSize: 15 }}>
				{group.date.toLocaleDateString()}
				<br />
				{group.date.toLocaleTimeString()}
			</TableCell>
			<TableCell style={{ fontSize: 15 }}>{group.type}</TableCell>
			<TableCell style={{ fontSize: 15 }}>{group.customerCode}</TableCell>
			<TableCell style={{ fontSize: 20 }}>
				<Price value={group.customerBalanceChange} />
			</TableCell>
		</TableRow>
	);
}
