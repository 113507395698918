import React from "react";
import { LedgerAccountType, LedgerClassification, getAvailableLedgerAccountTypes } from "../../../../entities/accounting/GeneralLedgerAccount";
import { MenuItem, TextField } from "@material-ui/core";
import { Enum } from "../../../../utility/Enum";

interface Props {
    onChange: (type: LedgerAccountType) => void;
    value: LedgerAccountType;
    classification: LedgerClassification;
    disabled?: boolean;
}


export function SelectLedgerType(props: Props){
    const { onChange, value: type, classification, disabled } = props;

    const availableTypes = React.useMemo(() => getAvailableLedgerAccountTypes(classification), [classification]);
    const validType = availableTypes.includes(type);

    const onChangeType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(Number(e.target.value));
    };

    if(!validType){
        return (
            <TextField label="Account Type" disabled select fullWidth>
                <MenuItem value="Invalid">
                    Loading Types...
                </MenuItem>
            </TextField>
        );
    }

    return (
        <TextField variant="outlined" label="Account Type" value={type} select onChange={onChangeType} fullWidth disabled={disabled}>
            {availableTypes.map((type) => (
                <MenuItem key={type} value={type}>
                    {Enum.print(LedgerAccountType, type)}
                </MenuItem>
            ))}
        </TextField>
    );
}