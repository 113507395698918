import { Box, Button, Card, CardContent, CardHeader, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GridGrow } from "../../components/GridGrow";
import { PageTitle } from "../../components/PageTitle";
import { Price } from "../../components/Price";
import { PriceTextField } from "../../components/PriceTextField";
import { RedText } from "../../components/RedText";
import { PaymentTransaction } from "../../entities/accounting/PaymentTransaction";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { TransactionService } from "../../services/accounting/TransactionService";

type SearchPaymentMethod = "cash" | "check" | "all";

export function PaymentsSearchPage() {
	const alert = useAlert();
	const history = useHistory();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const query = { customerCode: queryParams.get("customerCode") };

	const [paymentMethod, setPaymentMethod] = React.useState<SearchPaymentMethod>("all");
	const [checkNumber, setCheckNumber] = React.useState("");
	const [amount, setAmount] = React.useState<number | null>(null);
	const [customerCode, setCustomerCode] = React.useState(query.customerCode ?? "");
	const [disabled, setDisabled] = React.useState(false);
	const [maxResults, setMaxResults] = React.useState(15);

	const [paymentTransactions, setPaymentTransactions] = React.useState<PaymentTransaction[]>();

	React.useEffect(() => {
		async function initialSearch(customerCode: string) {
			const result = await TransactionService.searchPaymentTransactions({ type: "all", customerCode });
			if (result.success) {
				setPaymentTransactions(result.data);
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		}
		const customerCode = query.customerCode;
		if (customerCode) {
			initialSearch(customerCode);
		}
	}, [alert, query.customerCode]);

	const searchPayments = async () => {
		setDisabled(true);
		const result = await TransactionService.searchPaymentTransactions({
			type: paymentMethod,
			checkNumber: checkNumber.trim() === "" ? undefined : checkNumber.trim(),
			amount: amount === null ? undefined : amount,
			customerCode: customerCode.trim() === "" ? undefined : customerCode.trim(),
			maxResults: maxResults === 0 ? undefined : maxResults,
		});
		setDisabled(false);

		if (result.success) {
			setPaymentTransactions(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onClickPayment = (paymentTransaction: PaymentTransaction) => history.push(routes.app.resolve.paymentDetailPage(paymentTransaction.id));

	return (
		<Grid container spacing={2} justify="center">
			<PageTitle title="Search Payments" />
			<Grid item md={8} sm={10} xs={12}>
				<Card>
					<CardHeader title="Search Payments" />
					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={6} xs={12}>
								<TextField
									variant="outlined"
									select
									disabled={disabled}
									fullWidth
									label="Payment Method"
									value={paymentMethod}
									onChange={(e) => setPaymentMethod(e.target.value as SearchPaymentMethod)}
								>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="cash">Cash</MenuItem>
									<MenuItem value="check">Check</MenuItem>
								</TextField>
							</Grid>
							<Grid item sm={6} xs={12}>
								<PriceTextField variant="outlined" label="Amount" value={amount} onPriceChanged={setAmount} fullWidth />
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									variant="outlined"
									disabled={disabled}
									fullWidth
									label="Check Number"
									value={checkNumber}
									onChange={(e) => setCheckNumber(e.target.value)}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									variant="outlined"
									disabled={disabled}
									fullWidth
									label="Customer Code"
									value={customerCode}
									onChange={(e) => setCustomerCode(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item>
										<Button variant="contained" color="primary" onClick={searchPayments}>
											Search
										</Button>
									</Grid>
									<GridGrow />
									<Grid item>
										<TextField
											select
											variant="outlined"
											label="Max Results"
											value={maxResults}
											onChange={(e) => setMaxResults(Number(e.target.value))}
											disabled={disabled}
											style={{ width: 100 }}
										>
											<MenuItem value={0}>All</MenuItem>
											<MenuItem value={15}>15</MenuItem>
											<MenuItem value={30}>30</MenuItem>
											<MenuItem value={50}>50</MenuItem>
										</TextField>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{paymentTransactions !== undefined && (
				<Grid item md={8} sm={10} xs={12}>
					<Card>
						{paymentTransactions.length === 0 && <CardHeader title="No Payments Found" />}
						{paymentTransactions.length > 0 && (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Payment Method</TableCell>
											<TableCell>Amount</TableCell>
											<TableCell>Check Number</TableCell>
											<TableCell>Applied To</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{paymentTransactions.map((paymentTransaction) => (
											<TableRow key={paymentTransaction.id} hover style={{ cursor: "pointer" }} onClick={() => onClickPayment(paymentTransaction)}>
												<TableCell>{paymentTransaction.created.toLocaleDateString()}</TableCell>
												<TableCell>{paymentTransaction.type}</TableCell>
												<TableCell>
													<Box>
														<Price value={paymentTransaction.amount} />
													</Box>
													{paymentTransaction.reversalTransactionId != null && <RedText>Reversed</RedText>}
												</TableCell>
												<TableCell>{paymentTransaction.type === "Check" ? paymentTransaction.checkNumber : ""}</TableCell>
												<TableCell>
													{paymentTransaction.allocations.map((a) => (
														<Box key={a.invoiceShortId}>
															<Price value={a.amountApplied} doNotRound /> - {a.invoiceShortId}
														</Box>
													))}
													{paymentTransaction.unallocatedAmount > 0 && (
														<Box>
															<RedText>
																<Price value={paymentTransaction.unallocatedAmount} doNotRound /> - Unallocated
															</RedText>
														</Box>
													)}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
