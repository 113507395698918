import { WebClient } from "../server/WebClient";
import { apiServer } from "../server/Setting";
import { deserializeBatchedTransactionExport, deserializeTransactionExportBatch, deserializeTransactionExportBatches, TransactionExportBatch } from "../../entities/accounting/TransactionExportBatch";

interface CreateTransactionExportBatch {
    transactionIds: string[];
}

function exportFileName(batch: TransactionExportBatch, extension: string): string {
    const date = new Date();
    const fileTimestamp = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}${date.getMinutes()}`;
    return `quickbooks-batch${batch.batchNumber}-${fileTimestamp}.${extension}`;
}

function deserializeStats(serialized: { count: number, lastBatchDate: string | null }): { count: number, lastBatchDate: Date | null } {
    return {
        count: serialized.count,
        lastBatchDate: serialized.lastBatchDate ? new Date(serialized.lastBatchDate) : null,
    }
}

export const TransactionExportBatchService = {
    getAll: () => WebClient.Get(`${apiServer}/api/transaction-export-batches`, deserializeTransactionExportBatches),
    get: (batchId: string) => WebClient.GetOptional(`${apiServer}/api/transaction-export-batches/${batchId}`, deserializeTransactionExportBatch),
    getTransactionExports:(batchId: string) => WebClient.Get(`${apiServer}/api/transaction-export-batches/${batchId}/transaction-exports`, deserializeBatchedTransactionExport),
    getUnBatchedExports: () => WebClient.Get(`${apiServer}/api/transaction-export-batches/pending/transaction-exports`, deserializeBatchedTransactionExport),
    getUnBatchedExportsStats: () => WebClient.Get(`${apiServer}/api/transaction-export-batches/pending/stats`, deserializeStats),
    create: (request: CreateTransactionExportBatch) => WebClient.Post.Validated(`${apiServer}/api/transaction-export-batches`, request, deserializeTransactionExportBatch),
    downloadExportToQuickBooks: (batch: TransactionExportBatch) =>
		WebClient.Download.Post(`${apiServer}/api/transaction-export-batches/${batch.id}/download/quickbooks`, {}, exportFileName(batch, "iif"), "text/plain"),
};