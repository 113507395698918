import { TextField, TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { InputAttributes, NumericFormat, NumericFormatProps } from "react-number-format";
import { Modify } from "../utility/modifyType";

type Props = Modify<TextFieldProps, 
  {
    value: number | null;
    max?: number;
    onNumberChange: (value: number | null) => void;
  }>


  interface FormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
  
  const CurrencyNumberFormat = React.forwardRef<NumericFormatProps<InputAttributes>, FormatCustomProps>(function NumberFormatCustom(props, ref) {
    const { ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        thousandSeparator
        valueIsNumericString
        allowNegative={false}        
      />
    );
  });


export function NumberTextField(props: TextFieldProps & Props){
    const { onNumberChange, ...textFieldProps } = props;

    const number = props.value;
    const error = number != null && props.max != null && number > props.max;


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value !== "" ? Number(event.target.value.replace(",", "")) : null;
        const numberValue = inputValue !== null && isNaN(inputValue) ? null : inputValue;
        if (numberValue)
        {
          if (props.max && numberValue > props.max) {
              return;
          }
        }
        
        onNumberChange(numberValue);
      };

    return (
        <TextField
            {...textFieldProps}
            value={number}
            onChange={handleChange}
            error={error || props.error}
            placeholder={"#"}
			      InputLabelProps={{ shrink: true, ...textFieldProps.InputLabelProps }}
            InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: CurrencyNumberFormat as any,
                inputMode: "numeric",
                ...textFieldProps.InputProps,
              }}
            helperText={error ? `Must be less than ${props.max}` : props.helperText}
        />
    );
}