import React, { useState } from "react";
import { useAlert } from "../../hooks/useAlert";
import { DriverFillReport, DriverFillReportItem, ReportService } from "../../services/report/ReportService";
import { Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { BackButton } from "../../components/BackButton";
import { PageTitle } from "../../components/PageTitle";
import { SortableTableHeader, TableHeader } from "../../components/table/SortableTableHeader";

type SortOption = "Driver" | "FillCount" | "Gallons";

export function DriverFillReportPage() {
	const alert = useAlert();
	const beginningOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
	const today = new Date();

	const [disabled, setDisabled] = useState(false);
	const [startDate, setStartDate] = useState(beginningOfMonth);
	const [endDate, setEndDate] = useState(today);
	const [report, setReport] = useState<DriverFillReport>();

	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [pagedItems, setPagedItems] = React.useState<DriverFillReportItem[]>([]);

	const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
	const [sortBy, setSortBy] = React.useState<SortOption>("Gallons");

	React.useEffect(() => {
		if (!report) return;
		const sorted = report.items.sort((a, b) => {
			if (sortBy === "Driver" && sortOrder === "asc") {
				return a.driverName.localeCompare(b.driverName);
			} else if (sortBy === "Driver" && sortOrder === "desc") {
				return b.driverName.localeCompare(a.driverName);
			} else if (sortBy === "FillCount" && sortOrder === "asc") {
				return a.numberOfFills - b.numberOfFills;
			} else if (sortBy === "FillCount") {
				return b.numberOfFills - a.numberOfFills;
			} else if (sortBy === "Gallons" && sortOrder === "asc") {
				return a.gallons - b.gallons;
			} else if (sortBy === "Gallons" && sortOrder === "desc") {
				return b.gallons - a.gallons;
			}
			return 0;
		});
		setPagedItems(sorted.slice(page * pageSize, page * pageSize + pageSize));
	}, [report, page, pageSize, sortBy, sortOrder]);

	const generateReport = async () => {
		setDisabled(true);
		const result = await ReportService.driverFillReport({ startDate, endDate });
		setDisabled(false);

		if (result.success) {
			setReport(result.data);
		} else {
			alert.serverError(result);
		}
	};

	const onChangeSort = async (property: string, order: "asc" | "desc") => {
		setSortBy(property as SortOption);
		setSortOrder(order);
	};

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Driver Fill Report" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Driver Fill Report" subheader="Track number of fills and amount of gas filled by drivers." />
						</Grid>
					</Grid>

					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="Start Date"
									inputVariant="outlined"
									fullWidth
									value={startDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setStartDate(date);
									}}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="End Date"
									inputVariant="outlined"
									fullWidth
									value={endDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setEndDate(date);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item>
										<Button variant="contained" color="primary" disabled={disabled} onClick={generateReport}>
											Generate
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{report && (
				<Grid item sm={10} xs={12}>
					<Card>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>Total: {report.items.length} Drivers</TableCell>
									<TableCell>Total: {report.totalGallons.toLocaleString("en-US")} Gallons</TableCell>
									<TableCell>Total: {report.totalNumberOfFills.toLocaleString("en-US")} Fills</TableCell>
								</TableRow>
							</TableBody>
						</Table>

						<Table>
							<TableHead>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[10, 20, 50]}
										colSpan={5}
										nextIconButtonProps={{ disabled }}
										backIconButtonProps={{ disabled: disabled || page === 0 }}
										count={report.items.length}
										rowsPerPage={pageSize}
										labelRowsPerPage={"Show"}
										page={page}
										onChangePage={(_, page) => setPage(page)}
										onChangeRowsPerPage={(event) => {
											setPageSize(parseInt(event.target.value));
											setPage(1);
										}}
									/>
								</TableRow>
							</TableHead>
							<SortableTableHeader order={sortOrder} orderBy={sortBy}>
								<TableHeader property="Driver" onSort={onChangeSort}>
									Driver
								</TableHeader>
								<TableHeader property="Gallons" onSort={onChangeSort} defaultSortOrder="desc">
									Gallons
								</TableHeader>
								<TableHeader property="FillCount" onSort={onChangeSort} defaultSortOrder="desc">
									Number of Fills
								</TableHeader>
							</SortableTableHeader>

							<TableBody>
								{pagedItems.map((item) => (
									<TableRow key={item.driverId}>
										<TableCell>{item.driverName}</TableCell>
										<TableCell>{item.gallons.toLocaleString("en-US")}</TableCell>
										<TableCell>{item.numberOfFills.toLocaleString("en-US")}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
