import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, Button, DialogActions } from "@material-ui/core";
import React, { useState } from "react";
import { Invoice } from "../../../entities/billing/Invoice";
import { useAlert } from "../../../hooks/useAlert";
import { InvoiceService } from "../../../services/billing/InvoiceService";

interface Props {
	invoice: Invoice;
	open: boolean;
	onClose: () => void;
	onVoidInvoice: (invoice: Invoice) => void;
}

export function VoidInvoiceDialog(props: Props) {
	const alert = useAlert();
	const { open, invoice } = props;

	const [reason, setReason] = useState<null | string>(null);
	const [disabled, setDisabled] = useState(false);

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setDisabled(true);
		const result = await InvoiceService.voidInvoice({ invoiceId: invoice.id, reason: reason?.trim() === "" ? null : reason });
		setDisabled(false);
		if (result.success) {
			props.onVoidInvoice(result.data);
			alert.success("Invoice voided");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Void Invoice</DialogTitle>
			<form onSubmit={onSubmit}>
				<DialogContent>
                    <DialogContentText>
                        Are you sure you want to void all items in this invoice? This action cannot be undone.
                    </DialogContentText>
					<TextField
						label="Reason"
						variant="outlined"
						fullWidth
						value={reason}
						onChange={(e) => setReason(e.target.value)}
						helperText="(Optional) Description of why the invoice is being voided. Appears on statements."
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.onClose} variant="outlined">
						Cancel
					</Button>
					<Button disabled={disabled} variant="contained" color="primary" type="submit">
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
