import { Money, SafeMoney } from "../../utility/Money";
import { Modify } from "../../utility/modifyType";
import { Customer, deserializeCustomer, serializeCustomer, SerializedCustomer } from "../customer/Customer";
import { GeocodedAddress } from "../customer/GeocodedAddress";
import { Tag } from "../customer/Tag";

export enum TicketQuoteType {
    Product = 0,
    TankFill = 1,
}

interface TicketQuoteTank {
    id: string;
    gallons: number;
    description: string | null;
    address: GeocodedAddress;
    lastFillDate: string | null;
    lastFillAmount: number | null;
}

interface TicketNote {
    id: string;
    note: string;
    created: Date;
    createdBy: string;
}

type SerializedTicketNote = Modify<TicketNote, {
    created: string;
}>;

const deserializeTicketNote = (serverModel: SerializedTicketNote): TicketNote => {
    return {
        ...serverModel,
        created: new Date(serverModel.created)
    };
};

const serializeTicketNote = (note: TicketNote): SerializedTicketNote => {
    return {
        ...note,
        created: note.created.toISOString()
    };
}

export interface TicketQuoteTaxRule {
    taxRuleId: number | null;
    taxAccountId: number;
    taxAccountName: string;
    name: string;
    ratePercent: number | null;
    fixedAmount: number | null;
    description: string;
}

interface TicketQuoteItemBase {
    id: string;
    driverTicketId: string;
    productListingId: string;
    productName: string;
    unitPrice: SafeMoney;
    quantity: number | null;
    contractId: string | null;
    taxRules: TicketQuoteTaxRule[];
    saleAmount: SafeMoney | null;
    taxAmount: SafeMoney | null;
    totalAmount: SafeMoney | null;
}

type SerializedTicketQuoteItemBase = Modify<TicketQuoteItemBase, {
    unitPrice: number;
    saleAmount: number | null;
    taxAmount: number | null;
    totalAmount: number | null;
}>;

function deserializeTicketQuoteItemBase(serverModel: SerializedTicketQuoteItemBase): TicketQuoteItemBase {
    return {
        ...serverModel,
        unitPrice: Money.fromDecimal(serverModel.unitPrice, 3),
        saleAmount: serverModel.saleAmount ? Money.fromDecimal(serverModel.saleAmount) : null,
        taxAmount: serverModel.taxAmount ? Money.fromDecimal(serverModel.taxAmount) : null,
        totalAmount: serverModel.totalAmount ? Money.fromDecimal(serverModel.totalAmount) : null
    };
}

export interface TicketProduct extends TicketQuoteItemBase {
    type: TicketQuoteType.Product;
    tank: TicketQuoteTank | null;
}

type SerializedTicketProduct = SerializedTicketQuoteItemBase & {
    type: TicketQuoteType.Product;
    tank: TicketQuoteTank | null;
};

export interface TicketTankFill extends TicketQuoteItemBase {
    type: TicketQuoteType.TankFill;
    tank: TicketQuoteTank;
    reportedTankPercentage: number | null;
    estimatedUllage: number | null;
    isEstimate: boolean;
    isPending: boolean;
}

type SerializedTicketTankFill = SerializedTicketQuoteItemBase & {
    type: TicketQuoteType.TankFill;
    tank: TicketQuoteTank;
    reportedTankPercentage: number | null;
    estimatedUllage: number | null;
    isEstimate: boolean;
    isPending: boolean;
};

export type TicketQuoteItem = TicketProduct | TicketTankFill;

type SerializedTicketQuoteItem = SerializedTicketProduct | SerializedTicketTankFill;

function deserializeTicketQuoteItem(serverModel: SerializedTicketQuoteItem): TicketQuoteItem {
    if (serverModel.type === TicketQuoteType.Product) {
        return {
            ...deserializeTicketQuoteItemBase(serverModel),
            type: TicketQuoteType.Product,
            tank: serverModel.tank
        };
    } else {
        return {
            ...deserializeTicketQuoteItemBase(serverModel),
            type: TicketQuoteType.TankFill,
            tank: serverModel.tank,
            reportedTankPercentage: serverModel.reportedTankPercentage,
            estimatedUllage: serverModel.estimatedUllage,
            isEstimate: serverModel.isEstimate,
            isPending: serverModel.isPending
        };
    }
}

function serializeTicketQuoteItem(item: TicketQuoteItem): SerializedTicketQuoteItem {
    if (item.type === TicketQuoteType.Product) {
        return {
            ...item,
            unitPrice: item.unitPrice.toRoundedUnit(3),
            saleAmount: item.saleAmount ? item.saleAmount.toRoundedUnit(2) : null,
            taxAmount: item.taxAmount ? item.taxAmount.toRoundedUnit(2) : null,
            totalAmount: item.totalAmount ? item.totalAmount.toRoundedUnit(2) : null,
            type: TicketQuoteType.Product
        };
    } else {
        return {
            ...item,
            unitPrice: item.unitPrice.toRoundedUnit(3),
            saleAmount: item.saleAmount ? item.saleAmount.toRoundedUnit(2) : null,
            taxAmount: item.taxAmount ? item.taxAmount.toRoundedUnit(2) : null,
            totalAmount: item.totalAmount ? item.totalAmount.toRoundedUnit(2) : null,
            type: TicketQuoteType.TankFill
        };
    }
}

export interface TicketInvoice {
    id: string;
    shortId: string;
    total: number;
    balance: number;
    closed: boolean;
}

export interface TicketPriceLockContract {
    id: string;
    productListingId: string;
    price: SafeMoney;
    maxQuantity: number | null;
    priceCeiling: boolean;
    quantityRemaining: number | null;
}

type SerializedTicketPriceLockContract = Modify<TicketPriceLockContract, {
    price: number;
}>;

function deserializeTicketPriceLockContract(serverModel: SerializedTicketPriceLockContract): TicketPriceLockContract {
    return {
        ...serverModel,
        price: Money.fromDecimal(serverModel.price, 3)
    };
}

function serializeTicketPriceLockContract(contract: TicketPriceLockContract): SerializedTicketPriceLockContract {
    return {
        ...contract,
        price: contract.price.toRoundedUnit(3)
    };
}

export interface DriverTicket {
    id: string;
    created: Date;
    lastModified: Date;
    location: GeocodedAddress;
    instructions: string;
    dueBy: Date | null;
    ticketNumber: string | null;
    timeOfDelivery: Date | null;
    timeOfCompletion: Date | null;
    customer: Customer;
    driverId: string | null;
    driverName: string | null;
    routeSequence: number;
    urgencyScore: number;
    quoteItems: TicketQuoteItem[];
    tags: Tag[];
    invoice: TicketInvoice | null;
    lastFillDate: string | null;
    priceLocks: TicketPriceLockContract[];
    ticketNotes: TicketNote[];
    subtotal: SafeMoney | null;
    tax: SafeMoney | null;
    total: SafeMoney | null;
    isEstimate: boolean;
}

export type SerializedDriverTicket = Modify<DriverTicket, {
    created: string;
    lastModified: string;
	timeOfDelivery?: string;
	timeOfCompletion?: string;
	dueBy?: string;
	customer: SerializedCustomer;
    ticketNotes: SerializedTicketNote[];
    quoteItems: SerializedTicketQuoteItem[];
    priceLocks: SerializedTicketPriceLockContract[];
    subtotal: number | null;
    tax: number | null;
    total: number | null;
}>;

export const deserializeDriverTicket = (serverModel: SerializedDriverTicket): DriverTicket => {
	return {
        ...serverModel,
        created: new Date(serverModel.created),
        lastModified: new Date(serverModel.lastModified),
        timeOfDelivery: serverModel.timeOfDelivery ? new Date(serverModel.timeOfDelivery) : null,
        timeOfCompletion: serverModel.timeOfCompletion ? new Date(serverModel.timeOfCompletion) : null,
        dueBy: serverModel.dueBy ? new Date(serverModel.dueBy) : null,
		customer: deserializeCustomer(serverModel.customer),
        ticketNotes: serverModel.ticketNotes.map(deserializeTicketNote),
        quoteItems: serverModel.quoteItems.map(deserializeTicketQuoteItem),
        priceLocks: serverModel.priceLocks.map(deserializeTicketPriceLockContract),
        subtotal: serverModel.subtotal ? Money.fromDecimal(serverModel.subtotal, 2) : null,
        tax: serverModel.tax ? Money.fromDecimal(serverModel.tax, 2) : null,
        total: serverModel.total ? Money.fromDecimal(serverModel.total, 2) : null
    };
};

export const deserializeDriverTickets = (serverModels: SerializedDriverTicket[]): DriverTicket[] => serverModels.map(deserializeDriverTicket);

export const serializeDriverTicket = (ticket: DriverTicket): SerializedDriverTicket => {
	return {
        ...ticket,
        created: ticket.created.toISOString(),
        lastModified: ticket.lastModified.toISOString(),
        timeOfDelivery: ticket.timeOfDelivery ? ticket.timeOfDelivery.toISOString() : undefined,
        timeOfCompletion: ticket.timeOfCompletion ? ticket.timeOfCompletion.toISOString() : undefined,
        dueBy: ticket.dueBy ? ticket.dueBy.toISOString() : undefined,
		customer: serializeCustomer(ticket.customer),
        ticketNotes: ticket.ticketNotes.map(serializeTicketNote),
        quoteItems: ticket.quoteItems.map(serializeTicketQuoteItem),
        priceLocks: ticket.priceLocks.map(serializeTicketPriceLockContract),
        subtotal: ticket.subtotal ? ticket.subtotal.toRoundedUnit(2) : null,
        tax: ticket.tax ? ticket.tax.toRoundedUnit(2) : null,
        total: ticket.total ? ticket.total.toRoundedUnit(2) : null
    };
};

export function getTicketLetter(number: number) {
	if (number < 26) {
		return String.fromCharCode(65 + number);
	}
	return String.fromCharCode(65 + Math.floor(number / 26)) + String.fromCharCode(65 + (number % 26));
}