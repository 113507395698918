import { Card, CardHeader, List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { AlertIcon } from "./AlertIcon";
import BatteryStdIcon from "@material-ui/icons/BatteryStd";
import { DetailCardStyles } from "./DetailCardStyles";
import { OtodataDevice } from "../../../entities/routing/OtodataTankMonitor";

export function BatteryDetailCard(props: { device: OtodataDevice }) {
    const { device } = props;
    const classes = DetailCardStyles()
    
	return (
		<Card>
			<CardHeader
				action={
					<AlertIcon alert={device.batteryAlarm}>
						<BatteryStdIcon fontSize={"large"} />
					</AlertIcon>
				}
				title="Battery"
				subheader={device.batteryAlarm ? "Low Battery" : "Ok"}
				className={classes.denseCardHeader}
			/>
			<List>
				<ListItem>
					<ListItemText primary="Charge" secondary={`${device.batteryLevel} Volts`} />
				</ListItem>
			</List>
		</Card>
	);
}
