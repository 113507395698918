import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, Link, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PhoneTextField } from "../../components/PhoneTextField";
import { PhoneNumber } from "../../entities/customer/PhoneNumber";
import { useAlert } from "../../hooks/useAlert";
import { useUser } from "../../hooks/useUser";
import { useAppUser } from "../../providers/AppUserProvider";
import { AccountService } from "../../services/business/AccountService";
import { AuthService } from "../../services/auth/AuthService";
import { FieldValidationError, validationError } from "../../services/server/ServerValidationError";
import { PasswordSuggester } from "../../utility/PasswordSuggestor";

export function AccountPage() {
	const alert = useAlert();
	const user = useAppUser();
	const [, setUser] = useUser();
	const [disabled, setDisabled] = useState(false);
	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	const [password, setPassword] = useState("");
	const [passwordSuggestion, setPasswordSuggestion] = useState<string>();

	const [firstName, setFirstName] = useState(user.firstName);
	const [lastName, setLastName] = useState(user.lastName);
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | null>({ number : user.phoneNumber ?? "", extension : null});
	const [optInToNotifications, setOptInToNotifications] = useState(user.optedIntoNotifications);

	useEffect(() => {
		PasswordSuggester().then(setPasswordSuggestion);
	}, []);

	const onChangePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		const result = await AccountService.changePassword(password);
		setDisabled(false);

		if (result.success) {
			alert.success("Password change successful");
			setPassword("");
			setValidationErrors([]);
		} else if (result.validation) {
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};

	const onChangeContactInfoSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		const updatedPhoneNumber = phoneNumber ? phoneNumber.number : null;
		const result = await AccountService.changeContact({ firstName, lastName, phoneNumber: updatedPhoneNumber, enableNotifications: optInToNotifications });
		setDisabled(false);

		if (result.success) {
			alert.success("Contact change successful");
			const result = await AuthService.attemptTokenRefreshAndSet();
			if (result) {
				setUser(result.jwt);
			}
			setValidationErrors([]);
		} else if (result.validation) {
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};

	const reloadSuggestion = async () => {
		const suggestion = await PasswordSuggester();
		setPasswordSuggestion(suggestion);
	};

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography variant="h5">My Account</Typography>
			</Grid>
			<Grid item md={6} xs={12}>
				<Card>
					<CardHeader title="Change Contact Info" />
					<form onSubmit={onChangeContactInfoSubmit}>
						<CardContent>
							<Grid container spacing={4}>
								<Grid item md={6} xs={12}>
									<TextField
										required
										fullWidth
										disabled={disabled}
										label="First Name"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
										error={validationError.isFieldInError(validationErrors, "FirstName")}
										helperText={validationError.getFieldError(validationErrors, "FirstName")}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										required
										fullWidth
										disabled={disabled}
										label="Last Name"
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
										error={validationError.isFieldInError(validationErrors, "LastName")}
										helperText={validationError.getFieldError(validationErrors, "LastName")}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<PhoneTextField
										fullWidth
										allowExtensions={false}
										disabled={disabled}
										label="Phone"
										value={phoneNumber}
										onChangePhoneNumber={(p) => setPhoneNumber(p)}
										error={validationError.isFieldInError(validationErrors, "PhoneNumber")}
										helperText={validationError.getFieldError(validationErrors, "PhoneNumber")}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<FormControlLabel
										control={<Checkbox color="primary" checked={optInToNotifications} onChange={(e) => setOptInToNotifications(e.target.checked)} />}
										label="Enable SMS Notifications"
									/>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions>
							<Button type="submit">Save</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
			<Grid item md={6} xs={12}>
				<Card>
					<CardHeader title="Change Password" />
					<form onSubmit={onChangePasswordSubmit}>
						<CardContent>
							<TextField
								required
								fullWidth
								disabled={disabled}
								label="New Password"
								value={password}
								type="password"
								onChange={(e) => setPassword(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "Password")}
								helperText={validationError.getFieldError(validationErrors, "Password") ?? "Must be at least 10 characters"}
							/>
							<Typography>
								Need inspiration? Try {passwordSuggestion ?? "..."} (<Link onClick={reloadSuggestion}>Load Another</Link>)
							</Typography>
						</CardContent>
						<CardActions>
							<Button type="submit">Save</Button>
						</CardActions>
					</form>
				</Card>
			</Grid>
		</Grid>
	);
}
