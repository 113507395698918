import { Button, Card, CardContent, CardHeader, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import { BackButton } from "../../components/BackButton";
import { GridGrow } from "../../components/GridGrow";
import { PageTitle } from "../../components/PageTitle";
import { Price } from "../../components/Price";
import { useAlert } from "../../hooks/useAlert";
import { ReportService, PaymentReport, PaymentReportType } from "../../services/report/ReportService";

export function PaymentsReportPage() {
	const alert = useAlert();
	const today = new Date();

	const [disabled, setDisabled] = useState(false);
	const [startDate, setStartDate] = useState(today);
	const [endDate, setEndDate] = useState(today);
	const [type, setType] = useState<PaymentReportType>("check");
	const [report, setReport] = useState<PaymentReport>();
	const sameDate = startDate.toLocaleDateString() === endDate.toLocaleDateString();

	const generateReport = async () => {
		setDisabled(true);
		const result = await ReportService.paymentReport({ startDate, endDate, type });
		setDisabled(false);

		if (result.success) {
			setReport(result.data);
		} else {
			alert.serverError(result);
		}
	};

	const printReport = () => {
		if (!report) return;
		// print report in a new window
		const printWindow = window.open("", "PRINT", "height=400,width=600");
		const dateRange = sameDate ? startDate.toLocaleDateString() : `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;

		if (!printWindow) return;
		printWindow.document.write(`
				<html>
					<head>
						<title>Payment Report</title>
						<style>
							@page {
								size: auto;
								margin: 5mm;
							}
							body {
								margin: 5mm;
							}
							table {
								width: 100%;
								border-collapse: collapse;
							}
							table, th, td {
								border: 1px solid black;
							}
							th, td {
								padding: 5px;
							}
							th {
								text-align: left;
							}
						</style>
					</head>
					<body>
						<h1>Payment Report</h1>
						<p>Date: ${dateRange}</p>
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Customer</th>	
									<th>Customer Code</th>
									<th>Description</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								${report.items.map(
									(item) => `
									<tr>
										<td>${item.date.toLocaleDateString()}</td>
										<td>${item.customerName}</td>
										<td>${item.customerCode}</td>
										<td>${item.description}</td>
										<td>${item.amount.toFormat("$0,0.00")}</td>
									</tr>
								`
								)}
							</tbody>
						</table>
						<p>Total: ${report.total}</p>
					</body>
				</html>
			`);

		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	};

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Payment Report" />
			<Grid item lg={7} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Payment Report" subheader="Report on payments made within a time period." />
						</Grid>
					</Grid>

					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="Start Date"
									inputVariant="outlined"
									fullWidth
									value={startDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setStartDate(date);
									}}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="End Date"
									inputVariant="outlined"
									fullWidth
									value={endDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setEndDate(date);
									}}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField select fullWidth label="Type" variant="outlined" value={type} onChange={(e) => setType(e.target.value as PaymentReportType)}>
									<MenuItem value="check">Check</MenuItem>
									<MenuItem value="cash">Cash</MenuItem>
									<MenuItem value="card">Credit Card</MenuItem>
									<MenuItem value="all">All</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item><Button variant="contained" color="primary" disabled={disabled} onClick={generateReport}>
									Generate
								</Button></Grid>
								<GridGrow/>
									<Grid item>
										<Button variant="outlined" disabled={!report} onClick={printReport}>
											Print
										</Button>
									</Grid>
								</Grid>
								
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{report && (
				<Grid item sm={10} xs={12}>
					<Card>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Date</TableCell>
									<TableCell>Customer Name</TableCell>
									<TableCell>Code</TableCell>
									<TableCell>Description</TableCell>
									<TableCell>Payment Amount</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{report.items.map((item, i) => (
									<TableRow key={i}>
										<TableCell>{item.date.toLocaleDateString()}</TableCell>
										<TableCell>{item.customerName}</TableCell>
										<TableCell>{item.customerCode}</TableCell>
										<TableCell>{item.description}</TableCell>
										<TableCell>
											<Price value={item.amount} />
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									<TableCell>
										{report.startDate.toLocaleDateString() === report.endDate.toLocaleDateString() && startDate.toLocaleDateString()}
										{report.startDate.toLocaleDateString() !== report.endDate.toLocaleDateString() &&
											`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}{" "}
									</TableCell>
									<TableCell>{report.items.length} Items</TableCell>
									<TableCell colSpan={2} />
									<TableCell>
										<Price value={report.total} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
