import { Grid, Card, CardHeader, Typography, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { BackButton } from "../../../../components/BackButton";
import { PageTitle } from "../../../../components/PageTitle";
import { QuickBooksOnlineIntegrationOption } from "../../../../entities/auth/IntegrationConfiguration";
import { useAlert } from "../../../../hooks/useAlert";
import { IntegrationService } from "../../../../services/auth/IntegrationService";

interface Props {
	option: QuickBooksOnlineIntegrationOption;
}

export function ConnectQuickBooksOnlineView(props: Props) {
	const { option } = props;
	const alert = useAlert();

    const [authorizationUrl, setAuthorizationUrl] = React.useState<string>();

    useEffect(() => {
        async function getAuthorizationUrl() {
            const result = await IntegrationService.getQuickBooksAuthorizationUrl();
            if (result.success) {
                setAuthorizationUrl(result.data.authorizationUrl);
            } else {
                alert.serverError(result);
            }
        }
        getAuthorizationUrl();
    }, [alert]);

    const onConnect = () => {
        if(authorizationUrl) {
            window.location.href = authorizationUrl;
        }
    }



	return (
		<Grid container justify="center">
            <PageTitle title="Integrations - QuickBooks Online" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Connect to QuickBooks Online" />
						</Grid>
					</Grid>

						<Grid container justify="center">
							<Grid item style={{backgroundColor: "white", borderRadius: 5}}>
								<img src={option.largeLogoUrl} alt="QuickBooks Logo" style={{ maxWidth: "100%", maxHeight: 150, marginBottom: 15 }} />
							</Grid>
						</Grid>
						<Typography variant="body1" style={{padding: 15}}>{option.description}</Typography>
						<Typography variant="body1" style={{padding: 15}}>
							In order to connect to QuickBooks, you will need to authorize us to access your QuickBooks account. This will allow us to post transactions to your
							QuickBooks account.
						</Typography>

						<Button
							color="primary"
							fullWidth
							variant="contained"
							disabled={!authorizationUrl}
							style={{ height: 55, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            onClick={onConnect}
						>   
                            {authorizationUrl && "Connect to QuickBooks"}
                            {!authorizationUrl && "Loading..."}
						</Button>

				</Card>
			</Grid>
		</Grid>
	);
}
