import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { apiServer } from "../services/server/Setting";
import OverlayLoadingScreen from "../components/OverlayLoadingScreen";
import { OfflineRequestManagerProvider } from "./OfflineRequestManager";
import { WebClient } from "../services/server/WebClient";

const IsOfflineStatusReactContext = createContext<boolean>(false);

export function useOfflineContext() {
	return useContext(OfflineStatusReactContext);
}

interface OfflineStatusContext {
	isOffline: boolean;
	onlineCheckSuccessCount: number;
	optBackOnline: () => void;
	confirmOnlineStatus: () => void;
}

const OfflineStatusReactContext = createContext<OfflineStatusContext>({} as OfflineStatusContext);

export function useOfflineStatus() {
	return useContext(IsOfflineStatusReactContext);
}

export function OfflineStatusProvider(props: React.PropsWithChildren<{}>) {
	const [allowFirstRender, setAllowFirstRender] = useState(false);
	const [isOffline, setIsOffline] = useState(true);
	const [onlineCheckSuccessCount, setOnlineCheckSuccessCount] = useState(0);

	const onBrowserReportedOffline = useCallback(() => {
		console.log("Browser reported being offline");
		setIsOffline(true);
	}, []);

	const confirmOnlineStatus = useCallback(() => {
		console.log(`[${new Date().toLocaleString()}] Confirming online status...`);
		WebClient.GetOptional<"pong">(`${apiServer}/ping`).then((result) => {
			if (result.success) {
				console.log(`[${new Date().toLocaleString()}] Confirmed online status`);
				setOnlineCheckSuccessCount(count => count + 1);
				setIsOffline(false);
				setAllowFirstRender(true);
			} else {
				console.log(`[${new Date().toLocaleString()}] ${result.offline ? "Confirmed is offline" : "Failed to confirm online status"}`);
				setOnlineCheckSuccessCount(0);
				setIsOffline(true);
			}
		});
	}, []);

	useEffect(() => {
		window.addEventListener("offline", onBrowserReportedOffline);
		return () => {
			window.removeEventListener("offline", onBrowserReportedOffline);
		};
	}, [onBrowserReportedOffline]);

	useEffect(() => {
		if (isOffline) {
			setOnlineCheckSuccessCount(0);
			confirmOnlineStatus();
			const intervalId = setInterval(confirmOnlineStatus, 5_000);
			return () => {
				clearInterval(intervalId);
			};
		}
	}, [isOffline, confirmOnlineStatus]);

	if (!allowFirstRender) return <OverlayLoadingScreen />;

	return (
		<IsOfflineStatusReactContext.Provider value={isOffline}>
			<OfflineStatusReactContext.Provider value={{
				isOffline,
				onlineCheckSuccessCount,
				optBackOnline: () => setIsOffline(false),
				confirmOnlineStatus,
			}}>
				<OfflineRequestManagerProvider>{props.children}</OfflineRequestManagerProvider>
			</OfflineStatusReactContext.Provider>
		</IsOfflineStatusReactContext.Provider>
	);
}
