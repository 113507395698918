import { PriceLockContract, deserializeCustomerContract } from "../../entities/customer/CustomerContract";
import { NodaTime } from "../../utility/NodaTime";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreatePriceLockContractRequest {
	customerId: string;
	startDate: Date;
	expiration: Date | null;
	productListingId: string;
	price: number;
	priceCeiling: boolean;
	maxQuantity: number | null;
}

function serializeCreatePriceLockContractRequest(request: CreatePriceLockContractRequest) {
	return {
		...request,
		startDate: NodaTime.serializeToLocalDate(request.startDate),
		expiration: request.expiration ? NodaTime.serializeToLocalDate(request.expiration) : null,
	};
}

interface CancelContractRequest {
    contractId: string;
    reason: string | null;
}

export const ContractService = {
	get: (contractId: string) => WebClient.GetOptional(`${apiServer}/api/contracts/${contractId}`, deserializeCustomerContract),
	createPriceLockContract: (request: CreatePriceLockContractRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/contracts/price-lock`, serializeCreatePriceLockContractRequest(request), (r) =>
			deserializeCustomerContract(r) as PriceLockContract
		),
    cancel: (request: CancelContractRequest) => WebClient.Put.Validated(`${apiServer}/api/contracts/cancel`, request, deserializeCustomerContract),
};
