import React, { useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ServerErrorContext } from "../hooks/useServerErrorAlert";
import { SuccessAlertContext } from "../hooks/useSuccessAlert";
import Auth from "../authorization/Auth";
import { UserContext } from "../hooks/useUser";
import AppThemeProvider from "../providers/AppThemeProvider";
import { ValidationAlertContext } from "../hooks/useValidationAlert";
import { FieldValidationError } from "../services/server/ServerValidationError";
import { PageTitleContext } from "../components/PageTitle";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";

// Force reload page if a new service worker is found so user isn't stuck with old version
const checkForServiceWorkerUpdates = () => {
	if (window.navigator.onLine) {
		navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
	}
};

export function GlobalLayout(props: React.PropsWithChildren<{}>) {
	const history = useHistory();

	const [serverError, setServerError] = React.useState<{ message: string } | undefined>();
	const [serverValidationError, setServerValidationError] = React.useState<{ errors: FieldValidationError[] } | undefined>();
	const [successMessage, setSuccessMessage] = React.useState<string | undefined>();
	const [user, setUser] = React.useState(Auth.getJwtUser());
	const [pageTitle, setPageTitle] = React.useState("Dispatch Pro");

	// Checking for service work updates during navigation is the most ideal
	useEffect(() => history.listen(checkForServiceWorkerUpdates), [history]);
	// But Drivers may keep their route up and never update, so check every hour just in case
	useEffect(() => {
		setInterval(checkForServiceWorkerUpdates, 1000 * 60 * 60);
	}, []);

	const onPageTitleChange = (title: string) => {
		if (pageTitle === title) {
			return;
		}
		document.title = pageTitle;
		setPageTitle(title);
	};

	const setJwtUser = (jwt: string) => {
		const user = Auth.setToken(jwt);
		Sentry.setUser(user);
		setUser(user);
	};

	return (
		<AppThemeProvider>
			<UserContext.Provider value={[user, setJwtUser]}>
				<ValidationAlertContext.Provider value={setServerValidationError}>
					<ServerErrorContext.Provider value={setServerError}>
						<SuccessAlertContext.Provider value={setSuccessMessage}>
							<PageTitleContext.Provider value={[pageTitle, onPageTitleChange]}>{props.children}</PageTitleContext.Provider>
						</SuccessAlertContext.Provider>
					</ServerErrorContext.Provider>
				</ValidationAlertContext.Provider>
			</UserContext.Provider>

			<Snackbar open={serverError != null} autoHideDuration={6000} onClose={() => setServerError(undefined)}>
				<MuiAlert elevation={6} variant="standard" severity="error">
					{serverError?.message}
				</MuiAlert>
			</Snackbar>

			<Snackbar open={serverValidationError != null} autoHideDuration={6000} onClose={() => setServerValidationError(undefined)}>
				<MuiAlert elevation={6} variant="standard" severity="warning">
					{serverValidationError ? `${serverValidationError.errors[0].field}: ${serverValidationError.errors[0].errors[0]}` : ""}
				</MuiAlert>
			</Snackbar>
			<Snackbar open={successMessage != null} autoHideDuration={6000} onClose={() => setSuccessMessage(undefined)}>
				<MuiAlert elevation={6} variant="standard" severity="success">
					{successMessage}
				</MuiAlert>
			</Snackbar>
		</AppThemeProvider>
	);
}
