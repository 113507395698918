import { Button, Typography } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";

export function NotFound(props: { message: string, action: {prompt: string, route: string} }) {
    const history = useHistory();
    
    const onAction = () => history.replace(props.action.route);

    return (<div style={{padding: 15}}>
        <Typography variant="h2" gutterBottom>Page Not Found</Typography>
        <Typography variant="h6">{props.message}</Typography>
        <Button color="primary" onClick={onAction}>{"< "}{props.action.prompt}</Button>
    </div>)
}