import { SafeMoney, Money } from "../../utility/Money";

export interface BatchCheck {
    id: string;
    created: Date;
    amount: SafeMoney;
    checkNumber: string | null;
}

export type SerializedBatchCheck = Omit<BatchCheck, 'created' | 'amount'> & { 
    created: string;
    amount: number;
};

export function deserializeBatchCheck(check: SerializedBatchCheck): BatchCheck {
    return {
        ...check,
        created: new Date(check.created),
        amount: Money.fromDecimal(check.amount),
    };
}

export interface CheckBatch {
    id: string;
    batchNumber: number;
    created: Date;
    checkCount: number;
    totalAmount:  SafeMoney;
    checks: BatchCheck[];
}

type SerializedCheckBatch = Omit<CheckBatch, 'created' | 'checks'> & {
    created: string;
    totalAmount: number;
    checks: SerializedBatchCheck[];
};

export function deserializeCheckBatch(batch: SerializedCheckBatch): CheckBatch {
    return {
        ...batch,
        created: new Date(batch.created),
        totalAmount: Money.fromDecimal(batch.totalAmount),
        checks: batch.checks.map(deserializeBatchCheck),
    };
}
