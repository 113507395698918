import { PaymentTerms } from "../../entities/billing/PaymentTerms";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreatePaymentTermsRequest {
	name: string;
	description: string;
	dueDays: number;
	graceDays: number;
	isDefault: boolean;
}

interface AddOverdueFeeRequest {
	paymentTermsId: string;
	productListingId: string;
	name: string;
	percentFee: number | null;
	fixedFee: number | null;
	minimumFeeAmount: number | null;
	daysToApply: number;
}

interface UpdatePaymentTermsRequest {
	paymentTermsId: string;
	name?: string;
	description?: string;
	dueDays?: number;
	graceDays?: number;
	isDefault?: boolean;
}

export const PaymentTermsService = {
	getAll: () => WebClient.Get<PaymentTerms[]>(`${apiServer}/api/payment-terms`),
	get: (id: string) => WebClient.GetOptional<PaymentTerms>(`${apiServer}/api/payment-terms/${id}`),
	getCustomerPaymentTerms: (customerId: string) => WebClient.GetOptional<PaymentTerms>(`${apiServer}/api/payment-terms/customer/${customerId}`),
	create: (request: CreatePaymentTermsRequest) => WebClient.Post.Validated<PaymentTerms>(`${apiServer}/api/payment-terms`, request),
	update: (request: UpdatePaymentTermsRequest) => WebClient.Put.Validated<PaymentTerms>(`${apiServer}/api/payment-terms`, request),
    addOverdueFee: (request: AddOverdueFeeRequest) => WebClient.Post.Validated<PaymentTerms>(`${apiServer}/api/payment-terms/overdue-fee`, request),
    removeOverdueFee: (paymentTermsId: string) => WebClient.Delete<PaymentTerms>(`${apiServer}/api/payment-terms/${paymentTermsId}/overdue-fee`),
	assignToCustomer: (request: { paymentTermsId: string; customerId: string }) => WebClient.Put.Validated<PaymentTerms>(`${apiServer}/api/payment-terms/customer`, request),
};
