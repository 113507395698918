import { Tag } from "../../entities/customer/Tag";
import { SafeMoney, Money } from "../../utility/Money";
import { NodaTime } from "../../utility/NodaTime";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface StatementReportRequest {
	month: number;
	year: number;
	minimumBalance: number | null;
	daysSinceLastStatement: number | null;
	markAsSent: boolean;
	includeInactive: boolean;
}

interface CustomerBalanceReportRequest {
	includeBalanceGreaterThan: number | null;
	includeBalanceLessThan: number | null;
	includeInactive: boolean;
	city: string | null;
	minBalanceAge: number | null;
}

interface CustomerBalanceReportItem {
	customerId: string;
	customerName: string;
	customerNumber: number;
	customerCode: string;
	customerAddress: string;
	tags: Tag[];
	balance: number;
	overdue30: number;
	overdue60: number;
	overdue90: number;
	overdue120: number;
	overdueOver120: number;
}

export interface CustomerBalanceReport {
	items: CustomerBalanceReportItem[];
	totalBalance: number;
	totalOverdue30: number;
	totalOverdue60: number;
	totalOverdue90: number;
	totalOverdue120: number;
	totalOverdueOver120: number;
}

interface SalesReportRequest {
	startDate: Date;
	endDate: Date;
	filterProductListingIds: string[];
}

export enum SalesReportItemType {
	Sale = 1,
	Payment = 2,
	Discount = 3,
	PaymentReversal = 4,
}

interface SalesReportItemBase {
	date: Date;
}

export interface SalesItem extends SalesReportItemBase {
	type: SalesReportItemType.Sale;
	description: string;
	invoiceId: string | null;
	saleAmount: number;
	taxAmount: number;
	totalAmount: number;
}

export interface PaymentItem extends SalesReportItemBase {
	type: SalesReportItemType.Payment;
	paymentId: string;
	description: string;
	amount: number;
}

export interface DiscountItem extends SalesReportItemBase {
	type: SalesReportItemType.Discount;
	description: string;
	invoiceId: string | null;
	saleAmount: number;
	taxAmount: number;
	totalAmount: number;
}

export interface PaymentReversalItem extends SalesReportItemBase {
	type: SalesReportItemType.PaymentReversal;
	paymentId: string;
	description: string;
	amount: number;
}

export type SalesReportItem = SalesItem | PaymentItem | DiscountItem | PaymentReversalItem;

export interface SalesReport {
	startDate: Date;
	endDate: Date;
	items: SalesReportItem[];
	grossSales: number;
	totalTax: number;
	total: number;
	totalPaymentsReceived: number;
	totalPaymentsReversed: number;
	totalPayments: number;
}

type SerializedSalesReportItem = Omit<SalesReportItem, "date"> & { date: string };

type SerializedSalesReport = Omit<SalesReport, "startDate" | "endDate"> & {
	startDate: string;
	endDate: string;
	items: SerializedSalesReportItem[];
};

export const deserializeSalesReport = (report: SerializedSalesReport): SalesReport => ({
	...report,
	startDate: NodaTime.deserializeFromLocalDate(report.startDate),
	endDate: NodaTime.deserializeFromLocalDate(report.endDate),
	items: report.items.map((item) => ({
		...item,
		date: new Date(item.date),
	})),
});

export type PaymentReportType = "cash" | "check" | "card" | "all";

interface PaymentReportRequest {
	startDate: Date;
	endDate: Date;
	type: PaymentReportType;
}

export type PaymentReportItem = {
	date: Date;
	description: string;
	amount: SafeMoney;
	customerName: string;
	customerCode: string;
};

export interface PaymentReport {
	startDate: Date;
	endDate: Date;
	items: PaymentReportItem[];
	total: number;
}

type SerializedPaymentReportItem = Omit<PaymentReportItem, "date" | "amount"> & { date: string; amount: number };

type SerializedPaymentReport = Omit<PaymentReport, "items" | "startDate" | "endDate"> & {
	startDate: string;
	endDate: string;
	items: SerializedPaymentReportItem[];
};

export const deserializePaymentReport = (report: SerializedPaymentReport): PaymentReport => ({
	...report,
	startDate: NodaTime.deserializeFromLocalDate(report.startDate),
	endDate: NodaTime.deserializeFromLocalDate(report.endDate),
	items: report.items.map((item) => ({
		...item,
		date: new Date(item.date),
		amount: Money.fromDecimal(item.amount),
	})),
});

interface CustomerPropaneFillReportRequest {
	startDate: Date;
	endDate: Date;
}

export interface CustomerPropaneFillReportItem {
	customerId: string;
	customerName: string;
	gallons: number;
	numberOfFills: number;
}
export interface CustomerPropaneFillReport {
	totalGallons: number;
	totalNumberOfFills: number;
	items: CustomerPropaneFillReportItem[];
}

interface DriverFillReportRequest {
	startDate: Date;
	endDate: Date;
}

export interface DriverFillReportItem {
	driverId: string;
	driverName: string;
	gallons: number;
	numberOfFills: number;
}
export interface DriverFillReport {
	totalGallons: number;
	totalNumberOfFills: number;
	items: DriverFillReportItem[];
}

interface TankAlertReportItem {
	customerId: string;
	customerName: string;
	tankId: string;
	tankDescription: string;
	gallonCapacity: number;
	alertType: string;
	lastFillDate: Date | null;
	daysSinceLastFill: number | null;
	daysUntilNextFill: number | null;
	lastFillGallons: number | null;
	lastFillPercentage: number | null;
}

export interface TankAlertReport {
	items: TankAlertReportItem[];
	totalCustomersWithAlertConfigurations: number;
	totalTanksWithAlertConfigurations: number;
}

type SerializedTankAlertReportItem = Omit<TankAlertReportItem, "lastFillDate"> & { lastFillDate: string | null };

type SerializedTankAlertReport = Omit<TankAlertReport, "items"> & {
	items: SerializedTankAlertReportItem[];
};

const deserializeTankAlertReport = (report: SerializedTankAlertReport): TankAlertReport => ({
	...report,
	items: report.items.map((item) => ({
		...item,
		lastFillDate: item.lastFillDate ? new Date(item.lastFillDate) : null,
	})),
});

interface TaxReportRequest {
	startDate: Date;
	endDate: Date;
	accounting: "Cash" | "Accrual";
}

interface TaxReportItem {
	taxRuleId: number | null;
	ruleName: string;
	taxableAmount: number;
	taxedAmount: number;
}

export interface TaxReport {
	startDate: Date;
	endDate: Date;
	accounting: "Cash" | "Accrual";
	items: TaxReportItem[];
	totalSalesAmount: number;
	totalTaxableAmount: number;
	totalTaxedAmount: number;
	totalExemptAmount: number;
}

type SerializedTaxReport = Omit<TaxReport, "startDate" | "endDate"> & {
	startDate: string;
	endDate: string;
};

const deserializeTaxReport = (report: SerializedTaxReport): TaxReport => ({
	...report,
	startDate: NodaTime.deserializeFromLocalDate(report.startDate),
	endDate: NodaTime.deserializeFromLocalDate(report.endDate),
});

interface Fills {
	date: Date;
	daysSinceLastFill: number;
	delivered: number;
	price: number;
}

interface TankLastFillReportItem {
	customerId: string;
	customerName: string;
	tankId: string;
	tankDescription: string;
	gallons: number;
	fills: Fills[];
}

export interface TankLastFillReport {
	items: TankLastFillReportItem[];
}

type SerializedFill = Omit<Fills, "date"> & { date: string };
type SerializedTankLastFillReportItem = Omit<TankLastFillReportItem, "fills"> & { fills: SerializedFill[] };

type SerializedTankLastFillReport = Omit<TankLastFillReport, "items"> & {
	items: SerializedTankLastFillReportItem[];
};

const deserializeFill = (fill: SerializedFill): Fills => ({
	...fill,
	date: new Date(fill.date),
});

const deserializeTankLastFillReportItem = (item: SerializedTankLastFillReportItem): TankLastFillReportItem => ({
	...item,
	fills: item.fills.map(deserializeFill),
});

export const deserializeTankLastFillReport = (report: SerializedTankLastFillReport): TankLastFillReport => ({
	...report,
	items: report.items.map(deserializeTankLastFillReportItem),
});

interface TankLastFillReportRequest {
	numberOfFillsToInclude: number;
	onlyRentalTanks: boolean;
}

interface DownloadBulkInvoicePdfRequest {
	invoiceIds: string[];
}

interface InvoiceReportRequest {
	minDate: Date | null;
	maxDate: Date | null;
	minBalance: number | null;
	minDaysOverdue: number | null;
	status: "Open" | "Closed" | "All";
}

interface InvoiceReportItem {
	invoiceId: string;
	invoiceShortId: string;
	customerId: string;
	customerName: string;
	issuedOn: Date;
	dueBy: Date;
	daysOverdue: number;
	subTotal: SafeMoney;
	paymentTotal: SafeMoney;
	balance: SafeMoney;
}
type SerializedInvoiceReportItem = Omit<InvoiceReportItem, "issuedOn" | "dueBy"> & {
	issuedOn: string;
	dueBy: string;
	subTotal: number;
	paymentTotal: number;
	balance: number;
};
const deserializeInvoiceReportItem = (item: SerializedInvoiceReportItem): InvoiceReportItem => ({
	...item,
	issuedOn: new Date(item.issuedOn),
	dueBy: new Date(item.dueBy),
	subTotal: Money.fromDecimal(item.subTotal),
	paymentTotal: Money.fromDecimal(item.paymentTotal),
	balance: Money.fromDecimal(item.balance),
});

export interface InvoiceReport {
	totalBalance: SafeMoney;
	totalPayments: SafeMoney;
	totalBilledAmount: SafeMoney;
	items: InvoiceReportItem[];
}
type SerializedInvoiceReport = {
	totalBalance: number;
	totalPayments: number;
	totalBilledAmount: number;
	items: SerializedInvoiceReportItem[];
};
const deserializeInvoiceReport = (report: SerializedInvoiceReport): InvoiceReport => ({
	...report,
	totalBalance: Money.fromDecimal(report.totalBalance),
	totalPayments: Money.fromDecimal(report.totalPayments),
	totalBilledAmount: Money.fromDecimal(report.totalBilledAmount),
	items: report.items.map(deserializeInvoiceReportItem),
});

export interface DegreeDayReportRequest {
	degreeDayMaxTemperature: number | null;
	minDaysSinceFill: number | null;
	deliveriesToInclude: number;
}

interface DegreeDayReportItem {
	customerId: string;
	customerName: string;
	tankId: string;
	tankGallons: number;
	tankUllage: number;
	weatherLocationName: string;
	lastFillDate: Date;
	daysSinceLastFill: number;
	averageGallonsPerDegreeDay: number;
	deliveriesConsidered: number;
	degreeDaysSinceLastFill: number;
	estimatedGallonsUsed: number;
	estimatedGallonsRemaining: number;
	estimatedDegreeDaysRemaining: number;
}

export interface DegreeDayReport {
	items: DegreeDayReportItem[];
}

type SerializedDegreeDayReportItem = Omit<DegreeDayReportItem, "lastFillDate"> & { lastFillDate: string };

type SerializedDegreeDayReport = Omit<DegreeDayReport, "items"> & {
	items: SerializedDegreeDayReportItem[];
};

const deserializeDegreeDayReport = (report: SerializedDegreeDayReport): DegreeDayReport => ({
	...report,
	items: report.items.map((item) => ({
		...item,
		lastFillDate: NodaTime.deserializeFromLocalDate(item.lastFillDate),
	})),
});

export const ReportService = {
	downloadStatementReport: (request: StatementReportRequest) =>
		WebClient.Download.Post(`${apiServer}/api/reports/statements/zip`, request, "statements-report.zip", "application/zip"),
	customerBalanceReport: (request: CustomerBalanceReportRequest) => WebClient.Post.Unvalidated<CustomerBalanceReport>(`${apiServer}/api/reports/customer-balance`, request),
	salesReport: (request: SalesReportRequest) =>
		WebClient.Post.Unvalidated(
			`${apiServer}/api/reports/sales`,
			{
				...request,
				startDate: NodaTime.serializeToLocalDate(request.startDate),
				endDate: NodaTime.serializeToLocalDate(request.endDate),
			},
			deserializeSalesReport
		),
	invoiceReport: (request: InvoiceReportRequest) => WebClient.Post.Unvalidated<InvoiceReport>(`${apiServer}/api/reports/invoices`, request, deserializeInvoiceReport),
	bulkDownloadInvoicePdf: (request: DownloadBulkInvoicePdfRequest) =>
		WebClient.Download.Post(`${apiServer}/api/reports/invoices/zip`, request, "invoices.zip", "application/zip"),
	paymentReport: (request: PaymentReportRequest) =>
		WebClient.Post.Unvalidated(
			`${apiServer}/api/reports/payments`,
			{
				...request,
				startDate: NodaTime.serializeToLocalDate(request.startDate),
				endDate: NodaTime.serializeToLocalDate(request.endDate),
			},
			deserializePaymentReport
		),

	customerPropaneFillReport: (request: CustomerPropaneFillReportRequest) =>
		WebClient.Post.Unvalidated<CustomerPropaneFillReport>(`${apiServer}/api/reports/customer-propane-fills`, {
			...request,
			startDate: NodaTime.serializeToLocalDate(request.startDate),
			endDate: NodaTime.serializeToLocalDate(request.endDate),
		}),
	driverFillReport: (request: DriverFillReportRequest) =>
		WebClient.Post.Unvalidated<DriverFillReport>(`${apiServer}/api/reports/driver-fills`, {
			...request,
			startDate: NodaTime.serializeToLocalDate(request.startDate),
			endDate: NodaTime.serializeToLocalDate(request.endDate),
		}),
	tankAlertReport: () => WebClient.Post.Unvalidated<TankAlertReport>(`${apiServer}/api/reports/tank-alerts`, {}, deserializeTankAlertReport),
	tankLastFillReport: (request: TankLastFillReportRequest) => WebClient.Post.Unvalidated(`${apiServer}/api/reports/tank-last-fill`, request, deserializeTankLastFillReport),
	taxReport: (request: TaxReportRequest) =>
		WebClient.Post.Validated(
			`${apiServer}/api/reports/tax`,
			{
				...request,
				startDate: NodaTime.serializeToLocalDate(request.startDate),
				endDate: NodaTime.serializeToLocalDate(request.endDate),
			},
			deserializeTaxReport
		),
	degreeDayReport: (request: DegreeDayReportRequest) =>
		WebClient.Post.Unvalidated<DegreeDayReport>(`${apiServer}/api/reports/degree-day`, request, deserializeDegreeDayReport),
};
