import React, { useMemo } from "react";
import { Card, TableHead, TableRow, TableCell, TableBody, Table, CardHeader, CardContent } from "@material-ui/core";
import { Price } from "../../../components/Price";
import { TransactionExportGroup } from "../../../entities/accounting/TransactionExportBatch";
import { Money, SafeMoney } from "../../../utility/Money";

type AccountBalanceChange = { id: number; name: string; type: string; creditAmount: SafeMoney; debitAmount: SafeMoney; balance: SafeMoney; balanceType: "Credit" | "Debit" | "No Change" };

export function ExportBalanceChangesCard(props: { selectedTransactions: TransactionExportGroup[] }) {
	const { selectedTransactions } = props;

	const items = useMemo(() => selectedTransactions.flatMap((t) => t.items), [selectedTransactions]);
	const accountIds = useMemo(() => new Set(items.map((i) => i.ledgerAccountId)), [items]);
	const balanceChanges = useMemo(() => {
		const changes = [] as AccountBalanceChange[];
		for (const accountId of accountIds) {
			const accountItems = items.filter((i) => i.ledgerAccountId === accountId);
			const creditAmount = accountItems.filter((i) => i.isCredit).reduce((sum, i) => sum.add(i.amount), Money.zero);
			const debitAmount = accountItems.filter((i) => !i.isCredit).reduce((sum, i) => sum.add(i.amount), Money.zero);

			const balanceType = creditAmount.greaterThan(debitAmount) ? "Credit" : debitAmount.greaterThan(creditAmount) ? "Debit" : "No Change";
			const balance = creditAmount.greaterThan(debitAmount) ? creditAmount.subtract(debitAmount) : debitAmount.subtract(creditAmount);
			changes.push({
				id: accountId,
				name: accountItems[0].ledgerAccountName,
				type: accountItems[0].ledgerAccountType,
				creditAmount,
				debitAmount,
				balance,
				balanceType
			});
		}
		return changes;
	}, [accountIds, items]);

	return (
		<Card>
			<CardHeader title="Balance Changes" />
			<CardContent>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Account</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Debit</TableCell>
							<TableCell>Credit</TableCell>
							<TableCell>Balance</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{balanceChanges.map((change) => (
							<TableRow key={change.id}>
								<TableCell>{change.name}</TableCell>
								<TableCell>{change.type}</TableCell>
								<TableCell>
									<Price value={change.debitAmount} />
								</TableCell>
								<TableCell>
									<Price value={change.creditAmount} />
								</TableCell>
								<TableCell>
									{change.balanceType} {change.balanceType !== "No Change" && <Price value={change.balance}/>}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
}
