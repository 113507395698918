import React, { useEffect, useMemo, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	List,
	ListItem,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import { InvoiceSalesItem, InvoiceFeeItem, InvoiceItemType, InvoiceDiscountItem, Invoice } from "../../../entities/billing/Invoice";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { TransactionService } from "../../../services/accounting/TransactionService";
import { useAlert } from "../../../hooks/useAlert";
import { SalesTransaction } from "../../../entities/accounting/SalesTransaction";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { GeneralLedgerAccount } from "../../../entities/accounting/GeneralLedgerAccount";
import { LedgerAccountService } from "../../../services/accounting/LedgerAccountsService";
import { Price } from "../../../components/Price";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";
import { GridGrow } from "../../../components/GridGrow";
import { PriceTextField } from "../../../components/PriceTextField";
import { InvoiceService } from "../../../services/billing/InvoiceService";
import { ButtonLink } from "../../../components/ButtonLink";
import { Alert, AlertTitle } from "@material-ui/lab";
import { RedButton } from "../../../components/RedButton";
import { Money } from "../../../utility/Money";
import { EditableField } from "../../../components/SubmitTextField";

interface Props {
	invoiceItemId: string | undefined;
	invoice: Invoice;
	onClose: () => void;
	onChange: (invoice: Invoice) => void;
}

export function SoldInvoiceItemDetailDialog(props: Props) {
	const { invoiceItemId, invoice } = props;
	const invoiceItems = invoice.items;
	const invoiceItem = useMemo(
		() =>
			invoiceItems.find((i) => i.id === invoiceItemId && (i.type === InvoiceItemType.Sales || i.type === InvoiceItemType.Fee)) as
				| InvoiceSalesItem
				| InvoiceFeeItem
				| undefined,
		[invoiceItemId, invoiceItems]
	);

	const discounts = useMemo(
		() => invoiceItems.filter((i) => i.type === InvoiceItemType.Discount && i.appliedToId === invoiceItemId) as InvoiceDiscountItem[],
		[invoiceItemId, invoiceItems]
	);

	const [view, setView] = useState<"details" | "tax" | "void">("details");
	const [taxDetail, setTaxDetail] = useState<InvoiceSalesItem | InvoiceFeeItem | InvoiceDiscountItem>();

	const onClose = () => {
		setView("details");
		setTaxDetail(undefined);
		props.onClose();
	};

	const showTaxDetail = (item: InvoiceSalesItem | InvoiceFeeItem | InvoiceDiscountItem) => {
		setTaxDetail(item);
		setView("tax");
	};

	const backToDetailView = () => {
		setTaxDetail(undefined);
		setView("details");
	};

	const onVoidedSale = (invoice: Invoice) => {
		props.onChange(invoice);
		onClose();
	};

	const taxRemoved = (invoice: Invoice) => {
		props.onChange(invoice);
		setView("details");
	};

	return (
		<Dialog open={invoiceItem != null} onClose={onClose} maxWidth="sm">
			{!invoiceItem && <CenteredLoadingSpinner />}
			{invoiceItem && view === "details" && (
				<DetailView
					invoiceItem={invoiceItem}
					discounts={discounts}
					onUpdateInvoice={props.onChange}
					onShowTaxDetail={showTaxDetail}
					onVoidRequested={() => setView("void")}
					onClose={onClose}
				/>
			)}
			{invoiceItem && view === "tax" && taxDetail && (
				<InvoiceItemTaxDetail invoiceItem={taxDetail} onClose={backToDetailView} onTaxRemoved={taxRemoved} discounts={discounts} />
			)}
			{invoiceItem && view === "void" && <RemoveTransaction invoiceItem={invoiceItem} discounts={discounts} invoice={invoice} onVoided={onVoidedSale} onCancel={backToDetailView} />}
		</Dialog>
	);
}

interface DetailViewProps {
	invoiceItem: InvoiceSalesItem | InvoiceFeeItem;
	discounts: InvoiceDiscountItem[];
	onUpdateInvoice: (invoice: Invoice) => void;
	onShowTaxDetail: (invoiceItem: InvoiceSalesItem | InvoiceFeeItem | InvoiceDiscountItem) => void;
	onVoidRequested: () => void;
	onClose: () => void;
}

function DetailView(props: DetailViewProps) {
	const { invoiceItem, discounts } = props;
	const { transactionId } = invoiceItem;
	const alert = useAlert();
	const { productCatalog } = useProductCatalog();

	const [salesTransaction, setSalesTransaction] = useState<SalesTransaction>();
	const [accounts, setAccounts] = useState<GeneralLedgerAccount[]>();
	const [addDiscount, setAddDiscount] = useState(false);

	const product = productCatalog.productLines.flatMap((l) => l.availableListings).find((listing) => listing.id === salesTransaction?.productListingId);
	const revenueAccount = accounts?.find((a) => a.id === salesTransaction?.revenueAccountId);
	const taxAccount = accounts?.find((a) => a.id === salesTransaction?.taxAccountId);
	const discountTotal = discounts.reduce((sum, d) => sum.add(d.totalAmount), Money.zero);
	const canBeDiscounted = Money.isPositive(invoiceItem.totalAmount.subtract(discountTotal));

	useEffect(() => {
		async function loadSalesTransaction() {
			const result = await TransactionService.getSalesTransaction(transactionId);
			if (result.success) {
				setSalesTransaction(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadSalesTransaction();
	}, [alert, transactionId]);

	useEffect(() => {
		async function loadAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setAccounts(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadAccounts();
	}, [alert]);

	const onDiscountAdded = (invoice: Invoice) => {
		props.onUpdateInvoice(invoice);
		setAddDiscount(false);
	};

	return (
		<>
			{(!salesTransaction || !accounts) && (
				<DialogContent>
					<CenteredLoadingSpinner />
				</DialogContent>
			)}
			{salesTransaction && accounts && (
				<DialogContent>
					<EditableField<Invoice>
						type="text"
						size="small"
						value={invoiceItem.description}
						fullWidth
						style={{ maxWidth: 400 }}
						update={{
							onSuccess: props.onUpdateInvoice,
							request: (description: string) =>
								InvoiceService.updateItem({
									invoiceId: invoiceItem.invoiceId,
									invoiceItemId: invoiceItem.id,
									description,
								}),
							successMessage: "Description updated",
							required: true
						}}
						view={(onEdit) => (
							<>
								<Typography variant="h6">{invoiceItem.description}</Typography>
								<ButtonLink onClick={onEdit} color="primary">
									Change Description
								</ButtonLink>
							</>
						)}
					/>
					<Grid container spacing={2}>
						<Grid item>
							<List dense>
								{invoiceItem.type === InvoiceItemType.Sales && (
									<>
										<ListItem>
											<ListItemText primary="Unit Price" secondary={<Price value={invoiceItem.unitPrice} doNotRound />} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Quantity" secondary={invoiceItem.quantity} />
										</ListItem>
									</>
								)}
								<ListItem>
									<ListItemText primary="Discount" secondary={<Price value={discountTotal} />} />
								</ListItem>
							</List>
						</Grid>
						<Grid item>
							<List dense>
								<ListItem>
									<ListItemText primary="Subtotal" secondary={<Price value={invoiceItem.saleAmount} />} />
								</ListItem>
								<ListItem>
									<ListItemText
										primary="Tax"
										secondary={
											invoiceItem.appliedTaxes.length > 0 ? (
												<ButtonLink onClick={() => props.onShowTaxDetail(invoiceItem)} color="primary">
													<Price value={invoiceItem.taxAmount} />
												</ButtonLink>
											) : (
												"None"
											)
										}
									/>
								</ListItem>
								<ListItem>
									<ListItemText primary="Total" secondary={<Price value={invoiceItem.totalAmount} />} />
								</ListItem>
							</List>
						</Grid>
						<Grid item>
							<List dense>
								<ListItem>
									{product && (
										<ListItemText
											primary="Product"
											secondary={
												<ClientLink to={routes.business.resolve.productListingPage(product.productLineId, product.id)}>{product.name}</ClientLink>
											}
										/>
									)}
								</ListItem>
								{revenueAccount && (
									<ListItem>
										<ListItemText
											primary="Revenue Account"
											secondary={
												<ClientLink to={routes.business.resolve.generalLedgerAccountDetailPage(revenueAccount.id)}>{revenueAccount.name}</ClientLink>
											}
										/>
									</ListItem>
								)}
								{taxAccount && (
									<ListItem>
										<ListItemText
											primary="Tax Account"
											secondary={<ClientLink to={routes.business.resolve.generalLedgerAccountDetailPage(taxAccount.id)}>{taxAccount.name}</ClientLink>}
										/>
									</ListItem>
								)}
							</List>
						</Grid>
					</Grid>
					<Grid container spacing={1} style={{ marginBottom: 20, marginTop: 10 }}>
						<Grid item xs={12}>
							<Typography variant="h6">Discounts Applied</Typography>
						</Grid>
						{discounts.length === 0 && !addDiscount && (
							<Grid item xs={12}>
								<Typography>None</Typography>
							</Grid>
						)}
						{discounts.length > 0 && (
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Discount</TableCell>
										<TableCell>Sale</TableCell>
										<TableCell>Tax</TableCell>
										<TableCell>Total</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{discounts.map((discount, index) => (
										<TableRow key={index}>
											<TableCell>{discount.description}</TableCell>
											<TableCell>
												<Price value={discount.saleAmount} />
											</TableCell>
											<TableCell>
												<ButtonLink onClick={() => props.onShowTaxDetail(discount)}>
													<Price value={discount.taxAmount} />
												</ButtonLink>
											</TableCell>
											<TableCell>
												<Price value={discount.totalAmount} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}

						{addDiscount && (
							<AddDiscount appliedDiscounts={discounts} invoiceItem={invoiceItem} onCancel={() => setAddDiscount(false)} onDiscountAdded={onDiscountAdded} />
						)}
					</Grid>
					<Grid container spacing={2}>
						{canBeDiscounted && (
							<Grid item>
								<Button variant="outlined" color="primary" onClick={() => setAddDiscount(true)} disabled={addDiscount}>
									Add Discount
								</Button>
							</Grid>
						)}
						<Grid item>
							<Button variant="outlined" color="secondary" disabled={addDiscount} onClick={props.onVoidRequested}>
								Remove From Invoice
							</Button>
						</Grid>
						<GridGrow />
						<Grid item>
							<Button variant="outlined" onClick={props.onClose}>
								Close
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			)}
		</>
	);
}

function calculateDiscountTotal(invoiceItem: InvoiceSalesItem | InvoiceFeeItem, discountAmount: Dinero.Dinero) {
	const taxes = invoiceItem.appliedTaxes;
	if (taxes.length === 0) return { sale: discountAmount, tax: Money.zero, total: discountAmount };

	const tax = taxes.reduce((acc, tax) => {
		if (tax.ratePercent === null) return acc;
		return acc.add(discountAmount.multiply(tax.ratePercent));
	}, Money.zero);

	return { sale: discountAmount, tax, total: discountAmount.add(tax) };
}

interface AddDiscountProps {
	invoiceItem: InvoiceSalesItem | InvoiceFeeItem;
	appliedDiscounts: InvoiceDiscountItem[];
	onDiscountAdded: (invoice: Invoice) => void;
	onCancel: () => void;
}

function AddDiscount(props: AddDiscountProps) {
	const { invoiceItem } = props;
	const alert = useAlert();

	const [description, setDescription] = useState("");
	const [inputAmount, setInputAmount] = useState(0);
	const [disabled, setDisabled] = useState(false);

	const hasDescription = description.trim().length > 0;

	const originalBasis = invoiceItem.saleAmount;
	const discountedBasis = props.appliedDiscounts.reduce((acc, d) => acc.add(d.saleAmount), Money.zero);
	const adjustedBasis = originalBasis.subtract(discountedBasis);
	const amount = Money.fromDecimal(inputAmount);
	const calculatedDiscount = calculateDiscountTotal(invoiceItem, amount);

	const hasValidAmount = amount.lessThanOrEqual(adjustedBasis);

	const canSubmit = hasDescription && !amount.isZero() && hasValidAmount;

	const submit = async () => {
		setDisabled(true);
		const result = await InvoiceService.addDiscount({ invoiceId: invoiceItem.invoiceId, invoiceItemId: invoiceItem.id, description, amount: inputAmount });
		setDisabled(false);
		if (result.success) {
			props.onDiscountAdded(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Grid container spacing={1} alignItems="center" style={{ marginBottom: 20 }}>
			<Grid item xs={12}>
				<Typography>Add New Discount</Typography>
			</Grid>
			<Grid item sm={5} xs={7}>
				<TextField
					variant="outlined"
					autoFocus
					label="Description"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					fullWidth
					disabled={disabled}
				/>
			</Grid>
			<Grid item sm={3} xs={5}>
				<PriceTextField
					variant="outlined"
					label="Amount"
					value={inputAmount}
					onPriceChanged={(price) => setInputAmount(price ?? 0)}
					fullWidth
					disabled={disabled}
					error={!hasValidAmount}
				/>
			</Grid>
			<Grid item sm={4} xs={12}>
				<Grid container wrap="nowrap">
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							disabled={!canSubmit || disabled}
							onClick={submit}
							style={{ width: 85, height: 55, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
						>
							Save
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="outlined"
							color="secondary"
							onClick={props.onCancel}
							style={{ width: 85, height: 55, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
							disabled={disabled}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Grid>
			{!calculatedDiscount.total.isZero() && (
				<Typography variant="overline">
					Discounted Subtotal: <Price decimalOptional value={calculatedDiscount.sale} />
					<br />
					Discounted Tax: <Price decimalOptional value={calculatedDiscount.tax} />
					<br />
					Total Discount: <Price decimalOptional value={calculatedDiscount.total} />
					<br />
					Remaining Amount After Discount: <Price decimalOptional value={adjustedBasis.subtract(calculatedDiscount.total)} />
				</Typography>
			)}
			{!hasValidAmount && (
				<Typography color="error">
					Max discount amount is <Price value={adjustedBasis} />
				</Typography>
			)}
		</Grid>
	);
}

interface VoidTransactionProps {
	invoiceItem: InvoiceSalesItem | InvoiceFeeItem;
	discounts: InvoiceDiscountItem[];
	invoice: Invoice;
	onVoided: (invoice: Invoice) => void;
	onCancel: () => void;
}

function RemoveTransaction(props: VoidTransactionProps) {
	const { invoiceItem, discounts, invoice } = props;
	const alert = useAlert();

	const [disabled, setDisabled] = useState(false);
	const [reason, setReason] = useState("");

	const discountTotal = discounts.reduce((sum, d) => sum.add(d.totalAmount), Money.zero);
	const amountToVoid = invoiceItem.totalAmount.subtract(discountTotal);
	const willMakeInvoiceNegative = Money.isNegative(invoice.balance.subtract(amountToVoid));

	const submit = async () => {
		setDisabled(true);
		const givenReason = reason.trim() === "" ? null : reason;

		const result = await InvoiceService.removeSale({ invoiceId: invoiceItem.invoiceId, invoiceItemId: invoiceItem.id, reason: givenReason });
		setDisabled(false);
		if (result.success) {
			props.onVoided(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<>
			<DialogTitle>Remove Invoice Item Confirmation</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">Are you sure you want to remove this item?</DialogContentText>
				<DialogContentText color="textPrimary">
					- This will remove {`"${invoiceItem.description}"`} from this invoice and the customer&apos;s account.
					<br />
					{discounts.length > 0 && (
						<>
							- This will remove the {discounts.length === 1 ? "discount" : `${discounts.length} discounts`} applied to this item.
							<br />
						</>
					)}
					{Money.isPositive(amountToVoid) && (
						<>
							- {invoice.customerName}&apos;s balance will decrease <Price value={amountToVoid} />
							<br />
						</>
					)}
					- This action cannot be undone.
				</DialogContentText>

				<TextField
					autoFocus
					label="Reason (Optional)"
					type="text"
					fullWidth
					value={reason}
					onChange={(e) => setReason(e.target.value)}
					disabled={disabled || willMakeInvoiceNegative}
					variant="outlined"
				/>

				{willMakeInvoiceNegative && (
					<Alert severity="warning">
						<AlertTitle>Cannot remove this transaction</AlertTitle>
						Removing this transaction would make this invoice negative.
						<br />
						If you want to void this transaction, you must first unallocated <Price value={amountToVoid.subtract(invoice.balance)} /> from payments applied to this
						invoice.
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} color="secondary" disabled={disabled} variant="outlined">
					Cancel
				</Button>
				<Button onClick={submit} color="primary" disabled={disabled || willMakeInvoiceNegative} variant="contained">
					Remove Transaction
				</Button>
			</DialogActions>
		</>
	);
}

interface InvoiceItemTaxDetailProps {
	invoiceItem: InvoiceSalesItem | InvoiceFeeItem | InvoiceDiscountItem;
	discounts: InvoiceDiscountItem[];
	onTaxRemoved: (invoice: Invoice) => void;
	onClose: () => void;
}

function InvoiceItemTaxDetail(props: InvoiceItemTaxDetailProps) {
	const { invoiceItem } = props;
	const alert = useAlert();

	const [disabled, setDisabled] = useState(false);
	const [removeTaxes, setRemoveTaxes] = useState(false);
	const [reason, setReason] = useState("");

	const discountedTaxAmount = props.discounts.reduce((sum, discount) => sum.add(discount.taxAmount), Money.zero);
	const remainingTaxAmount = invoiceItem.taxAmount.subtract(discountedTaxAmount);
	const hasRemainingTax = remainingTaxAmount.getAmount() > 0;
	const isDiscount = invoiceItem.type === InvoiceItemType.Discount;

	const removeTax = async () => {
		setDisabled(true);
		const result = await InvoiceService.removeTax({ invoiceId: invoiceItem.invoiceId, invoiceItemId: invoiceItem.id, reason });
		setDisabled(false);
		if (result.success) {
			alert.success("Tax removed");
			props.onTaxRemoved(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<>
			<DialogTitle>Tax Details</DialogTitle>
			<DialogContent>
				<DialogContentText>Tax applied to: {invoiceItem.description}</DialogContentText>
				<TableContainer component={Paper}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Tax</TableCell>
								<TableCell align="right">Rate</TableCell>
								<TableCell align="right">Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{invoiceItem.appliedTaxes.map((tax) => (
								<TableRow key={tax.taxRuleId}>
									<TableCell component="th" scope="row">
										{tax.name}
									</TableCell>
									<TableCell align="right">{tax.rateDescription}</TableCell>
									<TableCell align="right">
										<Price value={tax.amountApplied} />
									</TableCell>
								</TableRow>
							))}
							<TableRow>
								<TableCell component="th" scope="row">
									{isDiscount ? "Discounted Tax" : "Total Tax"}
								</TableCell>
								<TableCell align="right" colSpan={2}>
									<Price value={invoiceItem.taxAmount} />
								</TableCell>
							</TableRow>
							{!isDiscount && discountedTaxAmount.getAmount() > 0 && (
								<>
									<TableRow>
										<TableCell component="th" scope="row">
											Discounted Tax
										</TableCell>
										<TableCell align="right" colSpan={2}>
											<Price value={discountedTaxAmount} />
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell component="th" scope="row">
											Total Tax After Discounts
										</TableCell>
										<TableCell align="right" colSpan={2}>
											<Price value={remainingTaxAmount} />
										</TableCell>
									</TableRow>
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				{!removeTaxes && !isDiscount && hasRemainingTax && (
					<RedButton onClick={() => setRemoveTaxes(true)} variant="outlined" disabled={disabled}>
						Remove Tax
					</RedButton>
				)}
				{removeTaxes && (
					<>
						<TextField
							autoFocus
							label="Removal Reason"
							type="text"
							fullWidth
							value={reason}
							onChange={(e) => setReason(e.target.value)}
							disabled={disabled}
							variant="outlined"
							helperText="Reason for removing tax (optional)"
						/>
						<Button onClick={removeTax} variant="contained" color="primary" disabled={disabled}>
							Confirm Tax Removal
						</Button>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary" variant="outlined" disabled={disabled}>
					Close
				</Button>
			</DialogActions>
		</>
	);
}
