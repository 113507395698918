import { makeStyles, Theme } from "@material-ui/core";
import React from "react";

type ColorOption = "textPrimary" | "primary" | "secondary" | "error" | undefined;
type UnderlineOption = "none" | "hover" | "always";

interface ButtonLinkProps {
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	underline?: UnderlineOption;
	disabled?: boolean;
	style?: React.CSSProperties | undefined;
	color?: ColorOption;
}

export function ButtonLink(props: React.PropsWithChildren<ButtonLinkProps>) {
	const classes = useStyles({color: props.color, disabled: props.disabled === true, underline: props.underline ?? "hover"});
	
	return (
		<button
			style={{
				fontSize: "inherit",
				fontWeight: "bold",
				...(props.style ?? {}),
			}}
			className={classes.link}
			disabled={props.disabled}
			onClick={props.onClick}
			type="button"
		>
			{props.children}
		</button>
	);
}

const getColor = (theme: Theme, color: ColorOption, disabled: boolean) => {
	if (disabled) {
		return theme.palette.text.disabled;
	}

	switch (color) {
		case "primary":
			return theme.palette.primary.main;
		case "secondary":
			return theme.palette.secondary.main;
		case "error":
			return theme.palette.error.main;
		case "textPrimary":
			return theme.palette.text.primary;
		default:
			return theme.palette.primary.light;
	}
};

const useStyles = makeStyles<Theme, { disabled: boolean, color: ColorOption, underline: UnderlineOption }>((theme) => ({
	link: (props) => ({
		backgroundColor: "transparent",
		border: "none",
		cursor: props.disabled ? "not-allowed" : "pointer",
		display: "inline",
		margin: 0,
		padding: 0,
		textDecoration: props.underline === "always" ? "underline" : "none",
		color: getColor(theme, props.color, props.disabled),
		"&:hover": {
			textDecoration: props.underline !== "none" ? "underline" : "none",
		},
	}),
}));
