import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";
import { LinkTableRow } from "../../components/LinkTableRow";
import { NumberTextField } from "../../components/NumberTextField";
import { PageTitle } from "../../components/PageTitle";
import { Price } from "../../components/Price";
import { PriceTextField } from "../../components/PriceTextField";
import { RedText } from "../../components/RedText";
import { SortableTableHeader, TableHeader } from "../../components/table/SortableTableHeader";
import { SearchableInvoice } from "../../entities/billing/Invoice";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { InvoiceService } from "../../services/billing/InvoiceService";
import { Money } from "../../utility/Money";

type InvoiceStatus = "open" | "closed" | "all";

export function InvoiceSearchPage() {
	const alert = useAlert();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const query = { customerCode: queryParams.get("customerCode") };

	const [status, setStatus] = React.useState<InvoiceStatus>("all");
	const [invoiceCode, setInvoiceCode] = React.useState("");
	const [amount, setAmount] = React.useState<number | null>(null);
	const [daysOverdue, setDaysOverdue] = React.useState<number | null>(null);
	const [customerCode, setCustomerCode] = React.useState(query.customerCode ?? "");
	const [ticketNumber, setTicketNumber] = React.useState("");
	const [disabled, setDisabled] = React.useState(false);
	const [pageSize, setPageSize] = React.useState(10);
	const [page, setPage] = React.useState(1);
	const [totalCount, setTotalCount] = React.useState(0);
	const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
	const [sortBy, setSortBy] = React.useState<string | null>("IssuedOn");

	const [invoices, setInvoices] = React.useState<SearchableInvoice[]>();

	React.useEffect(() => {
		async function initialSearch(customerCode: string) {
			const result = await InvoiceService.search({ status: "all", customerCode, page: 1, pageSize: 10, sortBy: "IssuedOn", sortOrder: "desc"});
			if (result.success) {
				setInvoices(result.data.results);
				setTotalCount(result.data.totalCount);
				setPageSize(10);
				setPage(1);
				setSortBy("IssuedOn");
				setSortOrder("desc");
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		}
		const customerCode = query.customerCode;
		if (customerCode) {
			initialSearch(customerCode);
		}
	}, [alert, query.customerCode]);

	const searchInvoices = async () => {
		await loadResults(1, pageSize, sortBy, sortOrder);
	};
	const onPageSizeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const pageSize = parseInt(event.target.value);
		setPageSize(pageSize);
		await loadResults(1, pageSize, sortBy, sortOrder);
	};

	const loadResults = async (page: number, pageSize: number, sortBy: string | null, sortOrder: "asc" | "desc") => {
		setDisabled(true);
		const result = await InvoiceService.search({
			status: status,
			amount: amount === null ? undefined : amount,
			invoiceCode: invoiceCode.trim() === "" ? undefined : invoiceCode,
			daysOverdue: daysOverdue === null ? undefined : daysOverdue,
			customerCode: customerCode.trim() === "" ? undefined : customerCode.trim(),
			ticketNumber: ticketNumber.trim() === "" ? undefined : ticketNumber.trim(),
			page,
			pageSize: pageSize,
			sortBy: sortBy,
			sortOrder: sortOrder,
		});
		setDisabled(false);
		if (result.success) {
			setInvoices(result.data.results);
			setTotalCount(result.data.totalCount);
			setPage(result.data.page);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onChangeSort = async (property: string, order: "asc" | "desc") => {
		setSortBy(property);
		setSortOrder(order);
		await loadResults(page, pageSize, property, order);
	};

	return (
		<Grid container spacing={2} justify="center">
			<PageTitle title="Search Invoices" />
			<Grid item md={8} sm={10} xs={12}>
				<Card>
					<CardHeader title="Search Invoices" />
					<form>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									select
									disabled={disabled}
									fullWidth
									label="Invoice Status"
									value={status}
									onChange={(e) => setStatus(e.target.value as InvoiceStatus)}
								>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="open">Open</MenuItem>
									<MenuItem value="closed">Closed</MenuItem>
								</TextField>
							</Grid>
							<Grid item sm={4} xs={12}>
								<PriceTextField variant="outlined" label="Invoice Amount" value={amount} onPriceChanged={setAmount} fullWidth disabled={disabled} />
							</Grid>
							<Grid item sm={4} xs={12}>
								<NumberTextField
									variant="outlined"
									label="Min Days Overdue"
									value={daysOverdue}
									onNumberChange={setDaysOverdue}
									fullWidth
									disabled={disabled}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									disabled={disabled}
									fullWidth
									label="Invoice Code"
									value={invoiceCode}
									onChange={(e) => setInvoiceCode(e.target.value)}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									disabled={disabled}
									fullWidth
									label="Customer Code"
									value={customerCode}
									onChange={(e) => setCustomerCode(e.target.value)}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									variant="outlined"
									disabled={disabled}
									fullWidth
									label="Ticket Number"
									value={ticketNumber}
									onChange={(e) => setTicketNumber(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" type="submit" color="primary" onClick={searchInvoices} disabled={disabled}>
									Search
								</Button>
							</Grid>
						</Grid>
					</CardContent>
					</form>
				</Card>
			</Grid>
			{invoices !== undefined && (
				<Grid item md={8} sm={10} xs={12}>
					<Card>
						{invoices.length === 0 && <CardHeader title="No Invoices Found" />}
						{invoices.length > 0 && (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TablePagination
												rowsPerPageOptions={[10, 20, 50]}
												colSpan={5}
												nextIconButtonProps={{ disabled }}
												backIconButtonProps={{ disabled: disabled || page === 1 }}
												count={totalCount}
												rowsPerPage={pageSize}
												labelRowsPerPage={"Show"}
												page={page - 1}
												onChangePage={(_, page) => loadResults(page + 1, pageSize, sortBy, sortOrder)}
												onChangeRowsPerPage={onPageSizeChange}
											/>
										</TableRow>
									</TableHead>
									<SortableTableHeader order={sortOrder} orderBy={sortBy}>
										<TableHeader>Code</TableHeader>
										<TableHeader property="CustomerName" onSort={onChangeSort}>
											Customer
										</TableHeader>
										<TableHeader property="Total" onSort={onChangeSort}>
											Amount
										</TableHeader>
										<TableHeader property="IssuedOn" onSort={onChangeSort}>
											Issued
										</TableHeader>
										<TableHeader>Status</TableHeader>
									</SortableTableHeader>
									<TableBody>
										{invoices.map((invoice) => (
											<LinkTableRow key={invoice.id} to={routes.app.resolve.invoiceDetailPage(invoice.id)} newTab>
												<TableCell>{invoice.shortId}</TableCell>
												<TableCell>{invoice.customerName}</TableCell>
												<TableCell>
													{Money.isPositive(invoice.balance) && (
														<>
															<Price value={invoice.total} />
															{invoice.balance !== invoice.total && (
																<>
																	{" "}
																	(<Price value={invoice.balance} /> Remaining)
																</>
															)}
														</>
													)}
													{invoice.closed && <Price value={invoice.total} />}
												</TableCell>
												<TableCell>{invoice.issuedOn.toLocaleDateString()}</TableCell>
												<TableCell>
													{invoice.overdue && <RedText>{invoice.status}</RedText>}
													{!invoice.overdue && invoice.status}
												</TableCell>
											</LinkTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
