import React, { useMemo } from "react";
import { CardContent, TextField, MenuItem, FormHelperText, Divider, Grid, Typography, Button, CardHeader, Card } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { addDays, addMonths, addWeeks } from "date-fns";
import { NumberTextField } from "../../components/NumberTextField";
import { ScheduledFill, ScheduledFillPeriodType } from "../../entities/routing/TankFillConfiguration";
import { useAlert } from "../../hooks/useAlert";
import { TankFillConfigurationService } from "../../services/routing/TankFillConfigurationService";
import { usePropaneTankDetailPage } from "./PropaneTankDetailPage";

interface Period {
	value: number;
	type: ScheduledFillPeriodType;
}

function incrementByPeriod(date: Date, period: Period) {
	switch (period.type) {
		case "Days":
			return addDays(date, period.value);
		case "Weeks":
			return addWeeks(date, period.value);
		case "Months":
			return addMonths(date, period.value);
		default:
			throw new Error("Unknown period");
	}
}

function getNextScheduledDate(date: Date, coldPeriod: Period, warmPeriod: Period) {
	const coldMonths = [1, 2, 3, 10, 11, 12];
	const today = new Date(new Date().toDateString());
	let nextScheduledDate = new Date(date.toDateString());
	do {
		const period = coldMonths.includes(nextScheduledDate.getMonth() + 1) ? coldPeriod : warmPeriod;
		if (period.value <= 0) {
			return nextScheduledDate;
		}
		nextScheduledDate = incrementByPeriod(nextScheduledDate, period);
	} while (nextScheduledDate < today);

	return nextScheduledDate;
}

function getNextThreeDeliveries(startDate: Date | null, coldPeriod: Period, warmPeriod: Period) {
	if (!startDate || isNaN(startDate.getTime())) return [];

	const firstDeliveryDate = getNextScheduledDate(startDate, coldPeriod, warmPeriod);
	const secondDeliveryDate = getNextScheduledDate(firstDeliveryDate, coldPeriod, warmPeriod);
	const thirdDeliveryDate = getNextScheduledDate(secondDeliveryDate, coldPeriod, warmPeriod);

	return [firstDeliveryDate, secondDeliveryDate, thirdDeliveryDate];
}

export function CreateScheduledFillCardView(props: { onCreate: (config: ScheduledFill) => void }) {
	const alert = useAlert();
	const context = usePropaneTankDetailPage();
	const { propaneTank } = context;
	const propaneTankId = propaneTank.id;

	const [coldPeriodType, setColdPeriodType] = React.useState<ScheduledFillPeriodType>("Weeks");
	const [coldPeriodValue, setColdPeriodValue] = React.useState<number>(2);
	const coldPeriod: Period = useMemo(() => ({ value: coldPeriodValue, type: coldPeriodType }), [coldPeriodType, coldPeriodValue]);
	const [warmPeriodType, setWarmPeriodType] = React.useState<ScheduledFillPeriodType>("Weeks");
	const [warmPeriodValue, setWarmPeriodValue] = React.useState<number>(4);
	const warmPeriod: Period = useMemo(() => ({ value: warmPeriodValue, type: warmPeriodType }), [warmPeriodType, warmPeriodValue]);
	const [businessDaysBeforeAlert, setBusinessDaysBeforeAlert] = React.useState<number>(3);
	const [startDate, setStartDate] = React.useState<Date | null>(new Date());
	const [disabled, setDisabled] = React.useState<boolean>(false);

	const nextThreeDeliveries = useMemo(() => getNextThreeDeliveries(startDate, coldPeriod, warmPeriod), [startDate, coldPeriod, warmPeriod]);

	const createScheduledFill = async () => {
		if (!startDate || isNaN(startDate.getTime()) || nextThreeDeliveries.length === 0) {
			alert.validation({ errors: [{ field: "startDate", errors: ["Invalid start date"] }] });
			return;
		}

		setDisabled(true);
		const result = await TankFillConfigurationService.createScheduledFill({
			tankId: propaneTankId,
			startDate,
			coldPeriodType,
			coldPeriodValue,
			warmPeriodType,
			warmPeriodValue,
			businessDaysBeforeAlert,
		});
		setDisabled(false);

		if (result.success) {
			props.onCreate(result.data);
			alert.success("Scheduled fill created");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onChangePeriod = (period: Period, type: "cold" | "warm") => {
		if (type === "cold") {
			setColdPeriodType(period.type);
			setColdPeriodValue(period.value);
		} else {
			setWarmPeriodType(period.type);
			setWarmPeriodValue(period.value);
		}
	};

	return (
		<>
			<CardContent>
				<Divider />
				<FormHelperText style={{ textAlign: "center", marginBottom: 15 }}>Automatically create tickets when getting close to next delivery date</FormHelperText>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PeriodTextField label="Cold Month Deliveries" value={coldPeriod} disabled={disabled} onChange={(period) => onChangePeriod(period, "cold")} />
						<FormHelperText style={{ textAlign: "center" }}>Time between deliveries during Oct, Nov, Dec, Jan, Feb, and Mar</FormHelperText>
					</Grid>
					<Grid item xs={12}>
						<PeriodTextField label="Warm Month Deliveries" value={warmPeriod} disabled={disabled} onChange={(period) => onChangePeriod(period, "warm")} />
						<FormHelperText style={{ textAlign: "center" }}>Time between deliveries during Apr, May, Jun, Jul, Aug, and Sept</FormHelperText>
					</Grid>
					<Grid item sm={6} xs={12}>
						<KeyboardDatePicker
							disableToolbar
							margin="normal"
							variant="inline"
							format="MM/dd/yyyy"
							label="Schedule Start Date"
							inputVariant="outlined"
							fullWidth
							value={startDate}
							onChange={setStartDate}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							disabled={disabled}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<NumberTextField
							label={`Business days before alert`}
							value={businessDaysBeforeAlert}
							onNumberChange={n => setBusinessDaysBeforeAlert(n ?? 0)}
							fullWidth
							margin="normal"
							variant="outlined"
							error={businessDaysBeforeAlert === 0}
							disabled={disabled}
						/>
					</Grid>
				</Grid>

				{nextThreeDeliveries.length > 0 && <Typography variant="body2">Next three fill dates:</Typography>}

				{nextThreeDeliveries.map((date, index) => (
					<Typography key={index} variant="body2">
						{date.toLocaleDateString()}
					</Typography>
				))}
			</CardContent>
			<Button variant="contained" color="primary" onClick={createScheduledFill} fullWidth disabled={disabled}>
				Create
			</Button>
		</>
	);
}

export function UpdateScheduledFillCard(props: { config: ScheduledFill; onUpdate: (config: ScheduledFill | null) => void }) {
	const { config } = props;
	const alert = useAlert();

	const [coldPeriodType, setColdPeriodType] = React.useState<ScheduledFillPeriodType>(config.coldPeriodType);
	const [coldPeriodValue, setColdPeriodValue] = React.useState<number>(config.coldPeriodValue);
	const coldPeriod: Period = useMemo(() => ({ value: coldPeriodValue, type: coldPeriodType }), [coldPeriodType, coldPeriodValue]);
	const [warmPeriodType, setWarmPeriodType] = React.useState<ScheduledFillPeriodType>(config.warmPeriodType);
	const [warmPeriodValue, setWarmPeriodValue] = React.useState<number>(config.warmPeriodValue);
	const warmPeriod: Period = useMemo(() => ({ value: warmPeriodValue, type: warmPeriodType }), [warmPeriodType, warmPeriodValue]);
	const [businessDaysBeforeAlert, setBusinessDaysBeforeAlert] = React.useState<number>(config.businessDaysBeforeAlert);
	const [nextFillDate, setFillDate] = React.useState<Date | null>(config.nextFillDate);
	const [disabled, setDisabled] = React.useState<boolean>(false);

	const nextThreeDeliveries = useMemo(() => getNextThreeDeliveries(nextFillDate, coldPeriod, warmPeriod), [nextFillDate, coldPeriod, warmPeriod]);

	const updateScheduledFill = async () => {
		if (!nextFillDate || isNaN(nextFillDate.getTime()) || nextThreeDeliveries.length === 0) {
			alert.validation({ errors: [{ field: "nextFillDate", errors: ["Invalid next fill date"] }] });
			return;
		}

		setDisabled(true);
		const result = await TankFillConfigurationService.updateScheduledFill({
			id: config.id,
			nextFillDate,
			coldPeriodType,
			coldPeriodValue,
			warmPeriodType,
			warmPeriodValue,
			businessDaysBeforeAlert,
		});
		setDisabled(false);

		if (result.success) {
			props.onUpdate(result.data);
			alert.success("Scheduled fill updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const removeScheduledFill = async () => {
		setDisabled(true);
		const result = await TankFillConfigurationService.remove(config.id);
		setDisabled(false);
		if (result.success) {
			alert.success("Scheduled fill removed");
			props.onUpdate(null);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onChangePeriod = (period: Period, type: "cold" | "warm") => {
		if (type === "cold") {
			setColdPeriodType(period.type);
			setColdPeriodValue(period.value);
		} else {
			setWarmPeriodType(period.type);
			setWarmPeriodValue(period.value);
		}
	};

	return (
		<Card style={{ maxWidth: 455 }}>
			<CardHeader title={`Scheduled Fill Alert`} style={{ paddingBottom: 0 }} />
			<CardContent>
				<FormHelperText style={{ textAlign: "center", marginBottom: 15 }}>Automatically create tickets when getting close to next delivery date</FormHelperText>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<PeriodTextField label="Cold Month Deliveries" value={coldPeriod} disabled={disabled} onChange={(period) => onChangePeriod(period, "cold")} />
						<FormHelperText style={{ textAlign: "center" }}>Time between deliveries during Oct, Nov, Dec, Jan, Feb, and Mar</FormHelperText>
					</Grid>
					<Grid item xs={12}>
						<PeriodTextField label="Warm Month Deliveries" value={warmPeriod} disabled={disabled} onChange={(period) => onChangePeriod(period, "warm")} />
						<FormHelperText style={{ textAlign: "center" }}>Time between deliveries during Apr, May, Jun, Jul, Aug, and Sept</FormHelperText>
					</Grid>
					<Grid item sm={6} xs={12}>
						<KeyboardDatePicker
							disableToolbar
							margin="normal"
							variant="inline"
							format="MM/dd/yyyy"
							label="Next Scheduled Delivery"
							inputVariant="outlined"
							fullWidth
							value={nextFillDate}
							onChange={setFillDate}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							disabled={disabled}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<NumberTextField
							label={`Business days before alert`}
							value={businessDaysBeforeAlert}
							onNumberChange={n => setBusinessDaysBeforeAlert(n ?? 0)}
							fullWidth
							margin="normal"
							variant="outlined"
							error={businessDaysBeforeAlert === 0}
							disabled={disabled}
						/>
					</Grid>
				</Grid>

				{nextThreeDeliveries.length > 0 && <Typography variant="body2">Next three delivery dates:</Typography>}
				{nextThreeDeliveries.map((date, index) => (
					<Typography key={index} variant="body2">
						{date.toLocaleDateString()}
					</Typography>
				))}
			</CardContent>
			<Grid container>
				<Grid item xs={6}>
					<Button
						variant="contained"
						color="secondary"
						onClick={removeScheduledFill}
						fullWidth
						disabled={disabled}
						style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
					>
						Remove
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant="contained"
						color="primary"
						onClick={updateScheduledFill}
						fullWidth
						disabled={disabled}
						style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
					>
						Update
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
}

interface PeriodTextFieldProps {
	value: Period;
	label: string;
	disabled: boolean;
	onChange: (period: Period) => void;
}

function PeriodTextField(props: PeriodTextFieldProps) {
	const { disabled, label } = props;

	const [periodType, setPeriodType] = React.useState<ScheduledFillPeriodType>(props.value.type);
	const [periodValue, setPeriodValue] = React.useState<number>(props.value.value);

	const onChangeType = (type: ScheduledFillPeriodType) => {
		setPeriodType(type);
		props.onChange({ type, value: periodValue });
	};

	const onChangeValue = (value: number) => {
		setPeriodValue(value);
		props.onChange({ type: periodType, value: value });
	};

	return (
		<Grid container wrap="nowrap">
			<Grid item xs={7}>
				<NumberTextField
					label={label}
					value={periodValue}
					onNumberChange={n => onChangeValue(n ?? 0)}
					fullWidth
					variant="outlined"
					error={periodValue === 0}
					disabled={disabled}
					InputProps={{
						style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
					}}
				/>
			</Grid>
			<Grid item xs={5}>
				<TextField
					select
					variant="outlined"
					fullWidth
					value={periodType}
					disabled={disabled}
					InputProps={{
						style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
					}}
				>
					<MenuItem value="Days" onClick={() => onChangeType("Days")}>
						Days
					</MenuItem>
					<MenuItem value="Weeks" onClick={() => onChangeType("Weeks")}>
						Weeks
					</MenuItem>
					<MenuItem value="Months" onClick={() => onChangeType("Months")}>
						Months
					</MenuItem>
				</TextField>
			</Grid>
		</Grid>
	);
}
