import { TextField, TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { InputAttributes, NumericFormat, NumericFormatProps } from "react-number-format";

interface Props {
	value: number | null;
	max?: number;
	onPercentChange: (value: number | null) => void;
}

interface FormatCustomProps {
	name: string;
	inputRef: unknown;
}

const PercentFormat = React.forwardRef<NumericFormatProps<InputAttributes>, FormatCustomProps>(function NumberFormatCustom(props, ref) {
	const { ...other} = props;
	return <NumericFormat {...other} getInputRef={ref} allowNegative={false} decimalScale={2} suffix={"%"} />;
});

export function PercentTextField(props: TextFieldProps & Props) {
	const { onPercentChange, max, value, ...textFieldProps } = props;

	const error = value != null && max != null && Number(value) > max;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value.replace("%", "") === "" ? NaN : Number(event.target.value.replace("%", ""));
		const numberValue = isNaN(inputValue) ? null : inputValue;
		if(numberValue === 0){
			onPercentChange(0);
			return;
		}
		const percentValue = numberValue ? numberValue / 100 : null;
		onPercentChange(percentValue);
	};

	return (
		<TextField
			{...textFieldProps}
			value={value != null ? value * 100 : null}
			onChange={handleChange}
			error={error || props.error}
			placeholder={"%"}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				inputComponent: PercentFormat as any,
			}}
			helperText={error ? `Must be less than ${max}%` : props.helperText}
		/>
	);
}
