import React from "react";
import { Grid } from "@material-ui/core";
import { PageTitle } from "../../components/PageTitle";
import { OverdueInvoicesCard } from "./components/OverdueInvoicesCard";
import { PendingCheckBatchCard } from "./components/PendingCheckBatchCard";
import { PendingTransactionExportBatchCard } from "./components/PendingTransactionExportBatchCard";
import { useAppUser } from "../../providers/AppUserProvider";
import { IntegrationType } from "../../entities/auth/IntegrationConfiguration";

export function AccountingDashboardPage() {
	const user = useAppUser();
	const { enabledIntegrations } = user;

	const hasQbo = enabledIntegrations.some((i) => i === IntegrationType.QuickBooksOnline);

	return (
		<Grid container spacing={3}>
			<PageTitle title="Accounting Dashboard" />
			{!hasQbo && (
				<Grid item xs={12} md={6} lg={5} xl={4}>
					<PendingTransactionExportBatchCard />
				</Grid>
			)}
			<Grid item xs={12} md={6} lg={5} xl={4}>
				<PendingCheckBatchCard />
			</Grid>
			<Grid item xs={12} md={6} lg={5} xl={4}>
				<OverdueInvoicesCard />
			</Grid>
		</Grid>
	);
}
