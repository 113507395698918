import { deserializeGasRegulator, SerializedGasRegulator } from "../../entities/routing/GasRegulator";
import { Modify } from "../../utility/modifyType";
import { NodaTime } from "../../utility/NodaTime";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreateFixedTankRouteRequest{
    tankId: string;
    serialNumber: string;
    type: string;
    dateInstalled: Date;
    notes: string;
}

type SerializedCreateFixedTankRouteRequest = Modify<CreateFixedTankRouteRequest, {
    dateInstalled: string;
}>;

const serializeCreateFixedTankRouteRequest = (request: CreateFixedTankRouteRequest): SerializedCreateFixedTankRouteRequest => ({
    ...request,
    dateInstalled: NodaTime.serializeToLocalDate(request.dateInstalled)
});

interface UpdateFixedTankRouteRequest{
    id: string;
    serialNumber?: string;
    type?: string;
    notes?: string;
}

export const GasRegulatorService = {
	getTankRegulators: (tankId: string) =>  WebClient.GetOptional(`${apiServer}/api/gas-regulators/tank/${tankId}`, (d: SerializedGasRegulator[]) => d.map(deserializeGasRegulator)),
	create: (request: CreateFixedTankRouteRequest) =>  WebClient.Post.Validated(`${apiServer}/api/gas-regulators`, serializeCreateFixedTankRouteRequest(request), deserializeGasRegulator),
	update: (request: UpdateFixedTankRouteRequest) => WebClient.Put.Validated(`${apiServer}/api/gas-regulators`, request, deserializeGasRegulator),
	markAsRemoved: (id: string) => WebClient.Put.Validated(`${apiServer}/api/gas-regulators/remove/${id}`, {}, deserializeGasRegulator)
}
