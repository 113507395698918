import { Modify } from "../../utility/modifyType";
import { SerializedTankMonitor, TankMonitor, deserializeTankMonitor } from "../routing/TankMonitor";
import { GeocodedAddress } from "./GeocodedAddress";

export interface PropaneTank {
	id: string;
	customerId: string;
	gallons: number;
	address: GeocodedAddress;
	description: string;
	serialNumber: string | null;
	locationDescription: string | null;
	notes: string;
	isRental: boolean;
	lastGasCheck: Date | null;
	fixedTankRouteId?: string;
	weatherLocation: {
		id: number;
		name: string;
	} | null
	tankMonitor: TankMonitor | null;
}


export type SerializedPropaneTank = Modify<PropaneTank, {
	lastGasCheck: string | null;
	tankMonitor: SerializedTankMonitor | null;
}>;

export const deserializePropaneTank = (model: SerializedPropaneTank): PropaneTank => {
	return {
		...model,
		lastGasCheck: model.lastGasCheck ? new Date(model.lastGasCheck) : null,
		tankMonitor: model.tankMonitor ? deserializeTankMonitor(model.tankMonitor) : null
	}
}

export const deserializePropaneTanks = (models: SerializedPropaneTank[]): PropaneTank[] => models.map(deserializePropaneTank);
