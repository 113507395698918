import { Button, Card, CardContent, CardHeader, Grid, Link, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { User } from "../../entities/auth/User";
import { useAlert } from "../../hooks/useAlert";
import AuthService from "../../services/auth/AuthService";

import { FieldValidationError, validationError } from "../../services/server/ServerValidationError";
import { PasswordSuggester } from "../../utility/PasswordSuggestor";

export function AcceptInvitePage() {
	const alert = useAlert();
	const params = useParams<{ userId: string; inviteToken: string }>();
	const { userId, inviteToken } = params;

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<User>();
	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	const [disabled, setDisabled] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordSuggestion, setPasswordSuggestion] = useState<string>();

	useEffect(() => {
		async function validateInvite() {
			const result = await AuthService.validUserInviteRequest(userId, inviteToken);
			setLoading(false);
			if (result.success && result.data) {
				const { firstName, lastName, email } = result.data;
				setFirstName(firstName);
				setLastName(lastName);
				setEmail(email);
				setUser(result.data);
			}
		}
		validateInvite();
		PasswordSuggester().then(setPasswordSuggestion);
	}, [inviteToken, userId]);

	const onAcceptSignUpSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setValidationErrors([]);
		setDisabled(true);
		const result = await AuthService.acceptInvite({userId, token: inviteToken, firstName, lastName, password});
		setDisabled(false);

		if (result.success) {
			window.location.href = "/app";
		} else if (result.validation) {
			setValidationErrors(result.errors)
		} else {
			alert.serverError(result);
		}
	};

	const reloadSuggestion = async () => {
		const suggestion = await PasswordSuggester();
		setPasswordSuggestion(suggestion);
	};

	if (loading) {
		return <OverlayLoadingScreen />;
	}

	return (
		<Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
			<Grid item>
				{!user ? (
					<ExpiredLink />
				) : (
					<Card style={{ minWidth: 300, maxWidth: 500, padding: 5 }}>
						<CardHeader title="Confirm Your Account" style={{ paddingBottom: 0 }} />
						<form onSubmit={onAcceptSignUpSubmit}>
							<div style={{ padding: 16, paddingTop: 0 }}>
								<TextField
									required
									fullWidth
									margin="normal"
									disabled={disabled}
									label="First Name"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									error={validationError.isFieldInError(validationErrors, "FirstName")}
									helperText={validationError.getFieldError(validationErrors, "FirstName")}
								/>
								<TextField
									required
									fullWidth
									margin="normal"
									disabled={disabled}
									label="Last Name"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									error={validationError.isFieldInError(validationErrors, "LastName")}
									helperText={validationError.getFieldError(validationErrors, "LastName")}
								/>
								<TextField
									required
									fullWidth
									margin="normal"
									disabled={true}
									label="Email"
									value={email}
								/>
								<TextField
									required
									fullWidth
									disabled={disabled}
									label="Password"
									value={password}
									type="password"
									onChange={(e) => setPassword(e.target.value)}
									error={validationError.isFieldInError(validationErrors, "Password")}
									helperText={validationError.getFieldError(validationErrors, "Password")}
								/>
								<Typography style={{ marginTop: 12 }}>
									Need password inspiration? <br />
									Try {passwordSuggestion ?? "..."} (<Link onClick={reloadSuggestion}>Load Another Suggestion</Link>)
								</Typography>
							</div>
							<Button variant="contained" color="primary" fullWidth type="submit" disabled={disabled}>
								Sign Up
							</Button>
						</form>
					</Card>
				)}
			</Grid>
		</Grid>
	);
}

function ExpiredLink() {
	return (
		<Card style={{ minWidth: 300, padding: 5 }}>
			<CardHeader title="Link Expired" style={{ paddingBottom: 0 }} />
			<CardContent>
				<Typography>This invitation has expired</Typography>
			</CardContent>
		</Card>
	);
}
