import { Box, Button, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import { Price } from "../../../components/Price";
import { Money } from "../../../utility/Money";
import { TankFillQuoteItem } from "./TankFillQuoteItem";
import { useCreateTicketContext } from "./NewCreateTicketPage";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { ProductCategoryType } from "../../../entities/products/ProductCategoryType";
import { ProductLine } from "../../../entities/products/ProductLine";
import { Autocomplete } from "@material-ui/lab";
import { ProductListing } from "../../../entities/products/ProductListing";
import { NewTicketProduct } from "./TicketProduct";
import { TicketProductQuoteItem } from "./TicketProductQuoteItem";
import { GridGrow } from "../../../components/GridGrow";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { ExemptionType } from "../../../entities/accounting/TaxExemption";

export function QuoteSection() {
	const context = useCreateTicketContext();
	const { tankFills, ticketProducts, appliedPriceLocks } = context;

	const { productCatalog, loadStatus } = useProductCatalog();

	const theme = useTheme();
	const isXS = useMediaQuery(theme.breakpoints.down("xs"));
	const showSubtotal = !isXS;
	const colSpan = showSubtotal ? 5 : 4;

	const [addQuoteItem, setAddQuoteItem] = React.useState(false);

	const total = useMemo(() => {
		if (tankFills.some((x) => x.subtotal == null)) return null;
		if (ticketProducts.some((x) => x.subtotal == null)) return null;

		const tankFillSubtotal = tankFills.reduce((a, b) => (b.subtotal == null ? a : a.add(b.subtotal)), Money.zero);
		const tankFillTax = tankFills.reduce((a, b) => (b.tax == null ? a : a.add(b.tax)), Money.zero);
		const ticketProductSubtotal = ticketProducts.reduce((a, b) => (b.subtotal == null ? a : a.add(b.subtotal)), Money.zero);
		const ticketProductTax = ticketProducts.reduce((a, b) => (b.tax == null ? a : a.add(b.tax)), Money.zero);

		const appliedPriceLockSubtotal = appliedPriceLocks.reduce((a, b) => a.add(b.priceDifference), Money.zero);
		const appliedPriceLockTaxes = appliedPriceLocks.reduce((a, b) => a.add(b.taxDifference), Money.zero);

		return tankFillSubtotal.add(ticketProductSubtotal).add(tankFillTax).add(ticketProductTax).add(appliedPriceLockSubtotal).add(appliedPriceLockTaxes);
	}, [tankFills, ticketProducts, appliedPriceLocks]);
	const isEstimate = useMemo(() => tankFills.some((x) => x.isEstimate), [tankFills]);

	const disableAddQuoteItem = loadStatus !== "ready" || productCatalog.productLines.length === 0 || addQuoteItem;

	return (
		<>
			<Box mt={1} mb={1}>
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<Typography variant="h6">Quote:</Typography>
					</Grid>
					{tankFills.length === 0 && ticketProducts.length === 0 && (
						<Grid item>
							<Typography variant="h6" color="textSecondary">
								None
							</Typography>
						</Grid>
					)}
					<GridGrow>
						<Grid container justify="flex-end">
							<Grid item>
								<Button color="primary" variant="outlined" onClick={() => setAddQuoteItem(true)} disabled={disableAddQuoteItem} style={{ marginRight: 6 }}>
									Add Product
								</Button>
							</Grid>
						</Grid>
					</GridGrow>
				</Grid>
			</Box>
			{(tankFills.length > 0 || ticketProducts.length > 0) && (
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell style={{ paddingLeft: 0, paddingRight: 5 }}>Product</TableCell>
							<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
								{isXS ? "Qty" : "Quantity"}
							</TableCell>
							<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
								Price
							</TableCell>
							{showSubtotal && (
								<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
									Sale
								</TableCell>
							)}
							<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
								Tax
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tankFills.map((tankFill, i) => (
							<TankFillQuoteItem key={i} tankFill={tankFill} showSubtotal={showSubtotal} />
						))}
						{ticketProducts.map((ticketProduct, i) => (
							<TicketProductQuoteItem key={i} ticketProduct={ticketProduct} showSubtotal={showSubtotal} />
						))}
						<AppliedContractItems showSubtotal={showSubtotal} />
						<TableRow>
							<TableCell colSpan={2} style={{ fontSize: 18 }}>
								{isEstimate ? "Estimated " : ""}Total:
							</TableCell>
							<TableCell colSpan={colSpan - 2} align="right" style={{ fontSize: 18 }}>
								{isEstimate ? "~" : ""}
								{total ? <Price value={total} /> : "Pending"}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			)}
			{addQuoteItem && <AddQuoteItem onCompleted={() => setAddQuoteItem(false)} />}
		</>
	);
}

function AppliedContractItems(props: { showSubtotal: boolean }) {
	const { showSubtotal } = props;
	const context = useCreateTicketContext();
	const { appliedPriceLocks } = context;

	if (appliedPriceLocks.length === 0) return null;

	const colSpan = showSubtotal ? 5 : 4;

	return (
		<>
			<TableRow>
				<TableCell colSpan={colSpan}>
					<Typography variant="h6">Applied Contracts:</Typography>
				</TableCell>
			</TableRow>
			{appliedPriceLocks.map((apl, i) => (
				<React.Fragment key={i}>
					<TableRow>
						<TableCell colSpan={2}>
							<Typography variant="subtitle1">{apl.priceLock.priceCeiling ? "Price Ceiling:" : "Price Lock:"}</Typography>
						</TableCell>
						<TableCell colSpan={colSpan - 2}>
							{apl.quantityRemaining != null && (
								<Typography variant="subtitle1" color="textSecondary" align="right">
									{apl.quantityRemaining} of {apl.priceLock.quantityRemaining} remaining
								</Typography>
							)}
						</TableCell>
					</TableRow>
					{apl.products.map((p, i) => (
						<TableRow key={i}>
							<TableCell>{p.name}</TableCell>
							<TableCell align="right">
								{p.isEstimate ? "~" : ""}
								{p.contractQuantity === 0 ? "Pending" : p.contractQuantity}
							</TableCell>
							<TableCell align="right">
								<Price value={p.contractPrice} />
							</TableCell>
							<TableCell align="right">
								{p.isEstimate ? "~" : ""}
								{!p.priceDifference.isZero() && p.priceDifference.isNegative() && (
									<>
										{" -"}<Price value={p.priceDifference.multiply(-1)} />
									</>
								)}
								{!p.priceDifference.isZero() && p.priceDifference.isPositive() && <Price value={p.priceDifference} />}
							</TableCell>
							<TableCell align="right">
								{p.isEstimate ? "~" : ""}
								{!p.taxDifference.isZero() && p.taxDifference.isNegative() && (
									<>
										{" -"}<Price value={p.taxDifference.multiply(-1)} />
									</>
								)}
								{!p.taxDifference.isZero() && p.taxDifference.isPositive() && <Price value={p.taxDifference} />}
							</TableCell>
						</TableRow>
					))}
				</React.Fragment>
			))}
		</>
	);
}

function AddQuoteItem(props: { onCompleted: () => void }) {
	const context = useCreateTicketContext();
	const { ticketProducts, setTicketProducts, address } = context;
	const customerContext = useCustomerDetailPage();
	const { taxExemptions } = customerContext.profile;
	const { productCatalog } = useProductCatalog();

	const [selectedProductLine, setSelectedProductLine] = React.useState<ProductLine | null>(null);
	const [selectedProductListing, setSelectedProductListing] = React.useState<ProductListing | null>(null);

	const selectableProductLines = productCatalog.productLines.filter((l) => l.type !== ProductCategoryType.Propane);
	const selectableProductListings = selectedProductLine?.availableListings.filter((l) => !ticketProducts.some((x) => x.listing.id === l.id)) ?? [];

	const onSelectProductLine = (event: React.ChangeEvent<{ value: unknown }>) => {
		const productLineId = event.target.value as string;
		const productLine = productCatalog.productLines.find((x) => x.id === productLineId);
		setSelectedProductLine(productLine ?? null);
		setSelectedProductListing(null);
	};

	const onSelectProductListing = (event: React.ChangeEvent<{}>, value: ProductListing | null) => {
		setSelectedProductListing(value);
	};

	const addQuoteItemToTicket = () => {
		if (selectedProductListing == null) return;
		const taxExemption = taxExemptions.find((te) => te.type === ExemptionType.Customer && te.valid) ?? null;
		const taxRules = taxExemption ? [] : productCatalog.getTaxRules(selectedProductListing, address);
		setTicketProducts((tp) => [...tp, NewTicketProduct.create(selectedProductListing, taxRules, taxExemption)]);
		props.onCompleted();
	};

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item>
				<TextField label="Product Line" select variant="outlined" style={{ minWidth: 200 }} value={selectedProductLine?.id ?? "None"} onChange={onSelectProductLine}>
					<MenuItem value="None">Select Product Line</MenuItem>
					{selectableProductLines.map((productLine) => (
						<MenuItem key={productLine.id} value={productLine.id}>
							{productLine.name}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item>
				{selectedProductLine == null && (
					<TextField label="Product" select variant="outlined" style={{ minWidth: 250 }} disabled>
						<MenuItem value="None">Select Product</MenuItem>
					</TextField>
				)}
				{selectedProductLine != null && (
					<Autocomplete
						options={selectableProductListings}
						filterOptions={(options, { inputValue }) =>
							options.filter(
								(option) =>
									option.name.toLowerCase().includes(inputValue.toLowerCase()) || (option.productCode ?? "").toLowerCase().includes(inputValue.toLowerCase())
							)
						}
						getOptionLabel={(option) => option.name}
						value={selectedProductListing}
						onChange={onSelectProductListing}
						renderInput={(params) => <TextField {...params} label="Product Line" variant="outlined" style={{ minWidth: 250 }} />}
						renderOption={(option) => (
							<div>
								<Typography variant="body1">{option.name}</Typography>
								{option.productCode && (
									<Typography variant="overline" color="textSecondary">
										{option.productCode}
									</Typography>
								)}
							</div>
						)}
					/>
				)}
			</Grid>
			<Grid item>
				<Button size="large" color="primary" variant="contained" disabled={selectedProductListing == null} onClick={addQuoteItemToTicket}>
					Add
				</Button>
			</Grid>
			<Grid item>
				<Button size="large" color="secondary" variant="outlined" onClick={props.onCompleted}>
					Cancel
				</Button>
			</Grid>
		</Grid>
	);
}
