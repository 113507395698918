import { PhoneNumber } from "../entities/customer/PhoneNumber";

export function formatPhoneNumber(phoneNumber: PhoneNumber) {
	const phoneNumberString = phoneNumber.number;
	const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		let formattedNumber = "(" + match[1] + ") " + match[2] + "-" + match[3];
		if(phoneNumber.extension) {
			formattedNumber += " x" + phoneNumber.extension;
		}
		return formattedNumber;
	}
	return null;
}




