import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTicketDetail } from "./DriverTicketDetailView";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useAlert } from "../../../hooks/useAlert";
import { printDate } from "../../../utility/PrintDate";
import { GridGrow } from "../../../components/GridGrow";
import { BoxIconButton } from "../../../components/BoxIconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CustomerService } from "../../../services/customer/CustomerService";

export function TicketNotes() {
	const alert = useAlert();
	const { disabled, setDisabled, ticket, updateTicket } = useTicketDetail();
	const [addNoteDialogOpen, setAddNoteDialogOpen] = useState(false);

	const notes = ticket.ticketNotes;

	const deleteNote = async (noteId: string) => {
		setDisabled(true);
		const result = await CustomerService.removeNote(noteId);
		setDisabled(false);
		if (result.success) {
			updateTicket({ ...ticket, ticketNotes: ticket.ticketNotes.filter((note) => note.id !== noteId) });
			alert.success("Note Deleted");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Grid container spacing={1}>
			<AddNoteDialog open={addNoteDialogOpen} onClose={() => setAddNoteDialogOpen(false)} />
			<Grid item xs={12}>
				<Grid container spacing={1} alignItems="center">
					<Grid item>
						<Button
							color="primary"
							disabled={disabled}
							variant="text"
							style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }}
							onClick={() => setAddNoteDialogOpen(true)}
						>
							Notes:
						</Button>
					</Grid>
					{notes.length === 0 && (
						<Grid item>
							<Typography variant="h6" color="textSecondary">
								None
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
			{notes.map((note) => (
				<Grid item xs={12} sm={6} key={note.id}>
					<Card variant="outlined">
						<Box p={1} pt={0}>
							<Grid container spacing={1} alignItems="center">
								<Grid item>
									<Typography variant="body2" color="textSecondary">
										{note.createdBy}
									</Typography>
								</Grid>
								<GridGrow />
								<Grid item>
									<Typography variant="overline" color="textSecondary">
										{printDate.shortDate(note.created)}
									</Typography>
								</Grid>
								<Grid item>
									<BoxIconButton color="secondary" onClick={() => deleteNote(note.id)} disabled={disabled}>
										<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
									</BoxIconButton>
								</Grid>
							</Grid>
							<Typography variant="body1" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
								{note.note}
							</Typography>
						</Box>
					</Card>
				</Grid>
			))}
		</Grid>
	);
}

function AddNoteDialog(props: { open: boolean; onClose: () => void }) {
	const { ticket, updateTicket } = useTicketDetail();

	const alert = useAlert();

	const [note, setNote] = useState("");
	const [disabled, setDisabled] = useState(false);

	const onClose = () => {
		if (disabled) return;
		setNote("");
		props.onClose();
	};

	const addNote = async () => {
		setDisabled(true);
		const result = await DriverTicketService.addTicketNote({
			customerId: ticket.customer.id,
			ticketId: ticket.id,
			note,
		});
		setDisabled(false);

		if (result.success) {
			alert.success("Note added");
			updateTicket(result.data);
			onClose();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={props.open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Add Note</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					multiline
					variant="outlined"
					fullWidth
					value={note}
					onChange={(e) => setNote(e.target.value)}
					disabled={disabled}
					InputProps={{ rows: 4 }}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={onClose} disabled={disabled}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={addNote} disabled={disabled || note.trim() === ""}>
					Add Note
				</Button>
			</DialogActions>
		</Dialog>
	);
}
