import { Dialog, DialogTitle, Button, Grid, Chip, Typography, TextField, Box, IconButton, Theme } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import React, { useEffect } from "react";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { GridGrow } from "../../../components/GridGrow";
import { TagBubble } from "../../../components/TagBubble";

import { getTagColorTheme, Tag, TagColor, TagColors, TagType } from "../../../entities/customer/Tag";
import { TagService } from "../../../services/customer/TagService";
import CheckIcon from "@material-ui/icons/Check";
import { useAlert } from "../../../hooks/useAlert";

interface SelectTagDialogProps {
	open: boolean;
	disableTags: Tag[];
	type: TagType;
	onClose: () => void;
	onTagSelected: (tag: Tag) => void;
}

export function SelectTagDialog(props: SelectTagDialogProps) {
	const { disableTags, open, type } = props;
	const [tags, setTags] = React.useState<Tag[]>();
	const [view, setView] = React.useState<"select" | "create">("select");
	const selectableTags = tags?.filter((t) => t.type === type);

	useEffect(() => {
		async function fetchTags() {
			const result = await TagService.getAll();
			if (result.success) {
				setTags(result.data);
			}
		}
		if (open) 
			fetchTags();
	}, [open]);

	const onClose = () => {
		setView("select");
		props.onClose();
	};

	const onTagCreated = (tag: Tag) => {
		setTags([...(tags ?? []), tag]);
		setView("select");
		props.onTagSelected(tag);
	};

	return (
		<Dialog open={props.open} onClose={onClose} maxWidth="xs">
			<DialogTitle>{view === "select" ? "Select Tag" : "Create Tag"}</DialogTitle>
			<Box p={2}>
				{!selectableTags ? (
					<CenteredLoadingSpinner />
				) : view === "select" ? (
					<SelectTagView tags={selectableTags} disableTags={disableTags} onTagSelected={props.onTagSelected} onCreateTag={() => setView("create")} onClose={onClose} />
				) : (
					<CreateTagView onClose={() => setView("select")} onTagCreated={onTagCreated} type={type} />
				)}
			</Box>
		</Dialog>
	);
}

interface SelectTagViewProps {
	tags: Tag[];
	disableTags: Tag[];
	onTagSelected: (tag: Tag) => void;
	onCreateTag: () => void;
	onClose: () => void;
}
function SelectTagView(props: SelectTagViewProps) {
	const { tags, disableTags: excludeTags } = props;

	return (
		<>
			<Grid container spacing={2}>
				{tags.map((tag) => (
					<Grid item key={tag.id}>
						<TagBubble tag={tag} onClick={props.onTagSelected} disabled={excludeTags.some((t) => t.id === tag.id)} />
					</Grid>
				))}
				<Grid item>
					<Chip color="primary" label="Create Tag" icon={<AddIcon />} onClick={props.onCreateTag} />
				</Grid>
				<Grid item xs={12}>
					<Button variant="outlined" onClick={props.onClose}>
						Cancel
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

interface CreateTagViewProps {
	type: TagType;
	onTagCreated: (tag: Tag) => void;
	onClose: () => void;
}
function CreateTagView(props: CreateTagViewProps) {
	const { type } = props;
	const theme = useTheme<Theme>();
	const alert = useAlert();

	const [selectedColor, setSelectedColor] = React.useState<TagColor>("blue");
	const selectedTheme = getTagColorTheme(selectedColor, theme.palette.type);
	const [name, setName] = React.useState<string>("");
	const [disabled, setDisabled] = React.useState<boolean>(false);
	const disableSave = name.trim().length === 0 || disabled;

	const colorRows = [TagColors.slice(0, 3), TagColors.slice(3, 6), TagColors.slice(6, 9)];

	const onCreateTag = async () => {
		setDisabled(true);
		const result = await TagService.create({ text: name, tagColor: selectedColor, type });
		setDisabled(false);

		if (result.success) {
			props.onTagCreated(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={2} alignContent="center" alignItems="center">
						<Grid item sm={6} xs={12}>
							<TextField label="Tag Name" value={name} variant="outlined" required disabled={disabled} onChange={(e) => setName(e.target.value)} fullWidth />
							<Chip label={name === "" ? "Example Tag" : name} style={{ color: selectedTheme.text, backgroundColor: selectedTheme.background, marginTop: 15 }} />
						</Grid>
						<Grid item sm={6} xs={12}>
							<Typography>Choose a color</Typography>
							{colorRows.map((colorRow, index) => (
								<Box key={index}>
									{colorRow.map((color) => (
										<IconButton
											key={color}
											disabled={disabled}
											onClick={() => setSelectedColor(color)}
											style={{
												backgroundColor: getTagColorTheme(color, theme.palette.type).background,
												color: getTagColorTheme(color, theme.palette.type).text,
												borderRadius: 0,
												padding: selectedColor === color ? 6 : 18,
											}}
										>
											{selectedColor === color ? <CheckIcon /> : null}
										</IconButton>
									))}
								</Box>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item>
							<Button variant="outlined" disabled={disabled} onClick={props.onClose}>
								Cancel
							</Button>
						</Grid>
						<GridGrow />
						<Grid item>
							<Button variant="contained" color="primary" disabled={disableSave} onClick={onCreateTag}>
								Create
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
