import { Modify } from "../../utility/modifyType";
import { ProductCategoryType } from "./ProductCategoryType";

export interface ProductListing {
    id: string; 
    productLineId: string;
    productLineType: ProductCategoryType;
    name: string;
    price: number;
    unitName: string | null;
    productCode: string | null;
    disabled: Date | null;
    revenueAccountId: number;
}

export type SerializedProductListing = Modify<ProductListing, { disabled: string | null }>;
export const deserializeProductListing = (model: SerializedProductListing): ProductListing => ({ ...model, disabled: model.disabled ? new Date(model.disabled) : null });
export const serializeProductListing = (model: ProductListing): SerializedProductListing => ({ ...model, disabled: model.disabled ? model.disabled.toISOString() : null });