import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { deserializeDriverTicket, DriverTicket, SerializedDriverTicket, serializeDriverTicket } from "../entities/routing/DriverTicket";
import { useCachedOfflineState } from "../hooks/useCachedOfflineState";
import { ServerError, ValidatedServerResult } from "../services/server/WebClient";
import { DriverTicketService, DeliverTicketRequest, AddTicketNoteRequest } from "../services/routing/DriverTicketService";
import { AppDataContext, AppDataLoadStatus } from "./AppDataProviders";
import { useOfflineRequestManager } from "./OfflineRequestManager";
import { DriverTicketEventHub } from "./DriverTicketEventHub";
import { useOfflineStatus } from "./OfflineStatusProvider";
import { CustomerService, UpdateDeliveryInstructionsRequest } from "../services/customer/CustomerService";
import { Customer } from "../entities/customer/Customer";

interface OfflineTicketContext extends AppDataContext {
	openTickets: DriverTicket[];
	lastRefresh: Date;
	create: (ticket: DriverTicket) => void;
	update: (ticket: DriverTicket) => void;
	updateMany: (tickets: DriverTicket[]) => void;
	changeOrder: (driverId: string, orderedTickets: DriverTicket[]) => void;
	remove: (ticket: DriverTicket) => void;
	completeDelivery: (ticket: DeliverTicketRequest) => Promise<ValidatedServerResult<DriverTicket> | "offline">;
	addTicketNote: (ticket: AddTicketNoteRequest) => Promise<ValidatedServerResult<DriverTicket> | "offline">;
	updateDeliveryInstructions: (request: UpdateDeliveryInstructionsRequest) => Promise<ValidatedServerResult<Customer> | "offline">;
	refresh: () => Promise<ServerError | null | "offline">;
}

const OpenTicketReactContext = createContext<OfflineTicketContext>({} as OfflineTicketContext);
OpenTicketReactContext.displayName = "OpenTicketAppData";

export function useOfflineTickets() {
	return useContext(OpenTicketReactContext);
}

export function OfflineTicketProvider(props: PropsWithChildren<{}>) {
	const offlineRequestManager = useOfflineRequestManager();
	const isOffline = useOfflineStatus();

	const [openTickets, setOpenTickets] = useCachedOfflineState<DriverTicket[], SerializedDriverTicket[]>(
		"offline-open-tickets-v5",
		[],
		(d) => d.map(serializeDriverTicket),
		(d) => d.map(deserializeDriverTicket)
	);
	const [lastRefresh, setLastRefresh] = useCachedOfflineState<Date, string>("offline-open-tickets-last-refresh", new Date(), (d) => d.toISOString(), (d) => new Date(d));
	const [loadStatus, setLoadStatus] = useState<AppDataLoadStatus>("loading");
	const [serverError, setServerError] = useState<ServerError | null>(null);

	useEffect(() => {
		async function loadOpenTickets() {
			setLoadStatus("loading");
			const result = await DriverTicketService.getOpenTickets();
			if (result.success) {
				setOpenTickets(result.data);
				setLastRefresh(new Date());
				setLoadStatus("ready");
			} else if (result.offline) {
				setLoadStatus("offline");
			} else {
				setLoadStatus("error");
				setServerError(result);
			}
		}

		if (!isOffline) {
			loadOpenTickets();
		} else {
			setLoadStatus("offline");
		}
	}, [isOffline, setLastRefresh, setOpenTickets]);

	const create = (driverTicket: DriverTicket) => setOpenTickets((stops) => addOrUpdateTicket(stops, driverTicket));
	const update = (driverTicket: DriverTicket) => setOpenTickets((stops) => addOrUpdateTicket(stops, driverTicket));
	const updateMany = (driverTickets: DriverTicket[]) => {
		return setOpenTickets((stops) => {
			return updateManyTickets(stops, driverTickets);
		});
	};
	const removeById = (driverTicketId: string) => setOpenTickets((stops) => removeTicket(stops, driverTicketId));
	const remove = (driverTicket: DriverTicket) => removeById(driverTicket.id);

	const changeOrder = (driverId: string, driverTickets: DriverTicket[]) => {
		const request = { driverId, orderedDriverTicketIds: driverTickets.map((rs) => rs.id) };
		if (!isOffline) {
			DriverTicketService.updateRouteSequence(request);
		} else {
			DriverTicketService.offline.updateRouteSequence(offlineRequestManager, request);
		}
		setOpenTickets((driverTicketsState) => updateManyTickets(driverTicketsState, driverTickets));
	};

	const complete = async (request: DeliverTicketRequest) => {
		if (!isOffline) {
			return await DriverTicketService.deliverTicket(request);
		} else {
			await DriverTicketService.offline.deliverTicket(offlineRequestManager, request);
			return "offline";
		}
	};

	const addTicketNote = async (request: AddTicketNoteRequest) => {
		if (!isOffline) {
			return await DriverTicketService.addTicketNote(request);
		} else {
			await DriverTicketService.offline.addTicketNote(offlineRequestManager, request);
			return "offline";
		}
	}

	const updateDeliveryInstructions = async (request: UpdateDeliveryInstructionsRequest) => {
		if (!isOffline) {
			return await CustomerService.updateDeliveryInstructions(request);
		} else {
			await CustomerService.offline.updateDeliveryInstructions(offlineRequestManager, request);
			return "offline";
		}
	}


	const refresh = async () => {
		if (!isOffline) {
			const result = await DriverTicketService.getOpenTickets();
			if (result.success) {
				setOpenTickets(result.data);
				setLastRefresh(new Date());
				return null;
			} else {
				return result;
			}
		} else {
			return "offline";
		}
	}
	

	return (
		<>
		<DriverTicketEventHub onCreate={create} onUpdate={update} onUpdateMany={updateMany} onRemove={removeById} />
		<OpenTicketReactContext.Provider
			value={{
				openTickets: openTickets,
				lastRefresh,
				create,
				update,
				updateMany,
				changeOrder,
				remove,
				completeDelivery: complete,
				addTicketNote,
				updateDeliveryInstructions,
				refresh,
				loadStatus,
				serverError,
			}}
		>
			{props.children}
		</OpenTicketReactContext.Provider>
		</>
	);
}

const updateManyTickets = (driverTicketsState: DriverTicket[], updatedTickets: DriverTicket[]) => {
	const ticketsNotUpdated = driverTicketsState.filter((r) => !updatedTickets.some((updatedStop) => updatedStop.id === r.id));
	return [...ticketsNotUpdated, ...updatedTickets].filter((r) => r.timeOfCompletion == null);
};

const addOrUpdateTicket = (driverTicketsState: DriverTicket[], ticket: DriverTicket) => {
	const ticketsWithoutNewTicket = driverTicketsState.filter((r) => r.id !== ticket.id);
	return [...ticketsWithoutNewTicket, ticket].filter((r) => r.timeOfCompletion == null);
};

const removeTicket = (driverTicketsState: DriverTicket[], ticketId: string) => [...driverTicketsState].filter((r) => r.id !== ticketId);
