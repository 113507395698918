import { Button, Card, CardContent, CardHeader, Grid, Link, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import AuthService from "../../services/auth/AuthService";

import { PasswordSuggester } from "../../utility/PasswordSuggestor";

export function ResetForgottenPage() {
	const alert = useAlert();
    const history = useHistory();
	const params = useParams<{ userId: string; resetToken: string }>();
	const { userId, resetToken } = params;

	const [loading, setLoading] = useState(true);
	const [expired, setExpired] = useState(false);

	const [disabled, setDisabled] = useState(false);
	const [password, setPassword] = useState("");
	const [passwordSuggestion, setPasswordSuggestion] = useState<string>();

	useEffect(() => {
		async function validateReset() {
			const valid = await AuthService.validForgottenPasswordResetRequest(userId, resetToken);
			setLoading(false);
			if (!valid) {
				setExpired(true);
			}
		}
		validateReset();
		PasswordSuggester().then(setPasswordSuggestion);
	}, [resetToken, userId]);

	const onChangePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		const result = await AuthService.forgottenPasswordReset(userId, resetToken, password);
		setDisabled(false);

		if (result.success) {
			alert.success("Password change successful");
			setPassword("");
            history.replace(routes.public.signInPage);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const reloadSuggestion = async () => {
		const suggestion = await PasswordSuggester();
		setPasswordSuggestion(suggestion);
	};

	if (loading) {
		return <OverlayLoadingScreen />;
	}

	return (
		<Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
			<Grid item>
				{expired ? (
					<ExpiredLink />
				) : (
					<Card style={{ minWidth: 300, padding: 5 }}>
						<CardHeader title="Reset Password" style={{ paddingBottom: 0 }} />
						<form onSubmit={onChangePasswordSubmit}>
							<div style={{ padding: 16, paddingTop: 0 }}>
								<TextField
									required
									fullWidth
									disabled={disabled}
									label="New Password"
									value={password}
									type="password"
									onChange={(e) => setPassword(e.target.value)}
								/>
								<Typography style={{ marginTop: 12 }}>
									Need inspiration? <br />
									Try {passwordSuggestion ?? "..."} (<Link onClick={reloadSuggestion}>Load Another</Link>)
								</Typography>
							</div>
							<Button variant="contained" color="primary" fullWidth type="submit" disabled={disabled}>
								Change Password
							</Button>
						</form>
					</Card>
				)}
			</Grid>
		</Grid>
	);
}

function ExpiredLink() {
    const history = useHistory();
	return (
		<Card style={{ minWidth: 300, padding: 5 }}>
			<CardHeader title="Password Reset Link Expired" style={{ paddingBottom: 0 }} />
            <CardContent>
                <Typography>Too much time has passed since password reset was requested</Typography>
            </CardContent>
			<Button variant="contained" color="primary" fullWidth onClick={() => history.replace(routes.public.forgotPasswordPage)}>
				Request New Link
			</Button>
		</Card>
	);
}
