import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes } from "../../routes";
import { CustomerBalanceReportPage } from "./CustomerBalanceReportPage";
import { PaymentsReportPage } from "./PaymentsReportPage";
import { ReportListPage } from "./ReportListPage";
import { SalesReportPage } from "./SalesReportPage";
import { StatementReportPage } from "./StatementReportPage";
import { CustomerPropaneFillReportPage } from "./CustomerPropaneFillReportPage";
import { DriverFillReportPage } from "./DriverFillReportPage";
import { TankAlertReportPage } from "./TankAlertReportPage";
import { TaxReportPage } from "./TaxReportPage";
import { TankLastFillReportPage } from "./TankLastFillReportPage";
import { InvoiceReportPage } from "./InvoiceReportPage";
import { DegreeDayReportPage } from "./DegreeDayReportPage";

export function ReportsPage() {
	return (
		<Switch>
			<Route exact path={routes.app.reportListPage}>
				<ReportListPage />
			</Route>
			<Route exact path={routes.app.statementReportPage}>
				<StatementReportPage />
			</Route>
			<Route exact path={routes.app.customerBalanceReportPage}>
				<CustomerBalanceReportPage />
			</Route>
			<Route path={routes.app.salesReportPage}>
				<SalesReportPage />
			</Route>
			<Route path={routes.app.invoiceReportPage}>
				<InvoiceReportPage />
			</Route>
			<Route path={routes.app.paymentsReportPage}>
				<PaymentsReportPage />
			</Route>
			<Route path={routes.app.taxReportPage}>
				<TaxReportPage />
			</Route>
			<Route path={routes.app.customerPropaneFillReportPage}>
				<CustomerPropaneFillReportPage />
			</Route>
			<Route path={routes.app.driverFillReportPage}>
				<DriverFillReportPage />
			</Route>
			<Route path={routes.app.tankAlertReportPage}>
				<TankAlertReportPage />
			</Route>
			<Route path={routes.app.tankLastFillReportPage}>
				<TankLastFillReportPage />
			</Route>
			<Route path={routes.app.degreeDayReportPage}>
				<DegreeDayReportPage />
			</Route>

		</Switch>
	);
}
