import { Box, Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../components/BackButton";
import { GridGrow } from "../../../components/GridGrow";
import { PageTitle } from "../../../components/PageTitle";
import { RedButton } from "../../../components/RedButton";
import { GasRegulator } from "../../../entities/routing/GasRegulator";
import { useAlert } from "../../../hooks/useAlert";
import { GasRegulatorService } from "../../../services/routing/GasRegulatorService";
import { usePropaneTankDetailPage } from "../PropaneTankDetailPage";

export function TankRegulatorDetailView(props: { tankRegulator: GasRegulator }) {
	const { tankRegulator } = props;
	const context = usePropaneTankDetailPage();
	const alert = useAlert();

	const [type, setType] = React.useState(tankRegulator.type);
	const [serialNumber, setSerialNumber] = React.useState(tankRegulator.serialNumber);
	const [notes, setNotes] = React.useState(tankRegulator.notes);
	const [saving, setSaving] = React.useState(false);

	const disableUpdates = saving || tankRegulator.dateRemoved != null;

	const update = async () => {
		setSaving(true);
		const result = await GasRegulatorService.update({
			id: tankRegulator.id,
			type,
			serialNumber,
			notes,
		});
		setSaving(false);
		if (result.success) {
			context.saveTankRegulator(result.data);
			alert.success("Tank regulator updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const markAsRemoved = async () => {
		setSaving(true);
		const result = await GasRegulatorService.markAsRemoved(tankRegulator.id);
		setSaving(false);
		if (result.success) {
			context.saveTankRegulator(result.data);
			alert.success("Tank regulator marked as removed");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<BackButton />
				<PageTitle title={`Tank Regulator - ${tankRegulator.type}`} />
			</Grid>
			<Grid item xs={12}>
				<Grid container justify="center">
					<Grid item xl={4} lg={5} md={7} sm={8} xs={12}>
						<Card>
							<Grid container alignItems="center" wrap="nowrap">
								<Grid item>
									<CardHeader title="Tank Regulator" subheader={`Installed ${tankRegulator.dateInstalled.toLocaleDateString()}`} />
								</Grid>
								<GridGrow />
								<Grid item>
									<Box mr={2}>
										{tankRegulator.dateRemoved ? (
											<Typography variant="overline" style={{ fontSize: "1rem" }}>
												Removed {tankRegulator.dateRemoved.toLocaleDateString()}
											</Typography>
										) : (
											<RedButton variant="outlined" style={{ marginRight: 5 }} disabled={saving} onClick={markAsRemoved}>
												Mark as Removed
											</RedButton>
										)}
									</Box>
								</Grid>
							</Grid>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									update();
								}}
							>
								<CardContent>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												variant="outlined"
												label="Type"
												disabled={disableUpdates}
												value={type}
												fullWidth
												required
												error={type.trim() === ""}
												onChange={(e) => setType(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												variant="outlined"
												label="Serial Number"
												disabled={disableUpdates}
												value={serialNumber}
												fullWidth
												onChange={(e) => setSerialNumber(e.target.value)}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												variant="outlined"
												label="Notes"
												disabled={saving}
												value={notes}
												fullWidth
												multiline
												onChange={(e) => setNotes(e.target.value)}
											/>
										</Grid>
									</Grid>
								</CardContent>

								<Button fullWidth variant="contained" color="primary" disabled={saving} type="submit">
									Save
								</Button>
							</form>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
