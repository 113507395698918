import {
	Card,
	CardContent,
	TextField,
	Typography,
	Button,
	MenuItem,
	CardHeader,
	ListItem,
	List,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	ListItemIcon,
	ListItemText,
	Divider,
} from "@material-ui/core";
import React from "react";
import { useAlert } from "../../../hooks/useAlert";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { TaxRuleService } from "../../../services/accounting/TaxRuleService";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes";
import { useTaxRuleDetailContext } from "./TaxRuleDetailView";

export function AppliedToCard() {
	const context = useTaxRuleDetailContext();
	const { taxRule } = context;
	const alert = useAlert();
	const history = useHistory();

	const [disabled, setDisabled] = React.useState(false);
	const [showAssignToProductLine, setShowAssignToProductLine] = React.useState(false);
	const [showAssignToProductListing, setShowAssignToProductListing] = React.useState(false);

	const onClickProductLine = (productLineId: string) => history.push(routes.business.resolve.productLinePage(productLineId));
	const onClickProductListing = (productLineId: string, productListingId: string) =>
		history.push(routes.business.resolve.productListingPage(productLineId, productListingId));

	const onUnassignProductLine = async (productLineId: string) => {
		setDisabled(true);
		const result = await TaxRuleService.unassignFromProductLine(taxRule.id, productLineId);
		setDisabled(false);
		if (result.success) {
			alert.success("Unassigned product line");
			context.updateTaxRule(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onUnassignProductListing = async (productListingId: string) => {
		setDisabled(true);
		const result = await TaxRuleService.unassignFromProductListing(taxRule.id, productListingId);
		setDisabled(false);
		if (result.success) {
			alert.success("Unassigned product listing");
			context.updateTaxRule(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card style={{ marginTop: 15 }}>
			<AssignToProductLineDialog open={showAssignToProductLine} onClose={() => setShowAssignToProductLine(false)} />
			<AssignToProductListingDialog open={showAssignToProductListing} onClose={() => setShowAssignToProductListing(false)} />
			<CardHeader title="Assign to Product Catalog" />
			<CardContent>
				<Typography variant="h6">Product Lines</Typography>
				<List dense>
					{taxRule.assignedProductLines.map((productLine, i) => (
						<React.Fragment key={productLine.productLineId}>
							{i > 0 && <Divider component="li" />}
							<ListItem button disabled={disabled} onClick={() => onClickProductLine(productLine.productLineId)}>
								<ListItemText>{productLine.name}</ListItemText>
								<ListItemIcon>
									<Button
										variant="outlined"
										color="secondary"
										size="small"
										disabled={disabled}
										onClick={(e) => {
											e.stopPropagation();
											onUnassignProductLine(productLine.productLineId);
										}}
									>
										Remove
									</Button>
								</ListItemIcon>
							</ListItem>
						</React.Fragment>
					))}
					{taxRule.assignedProductLines.length === 0 && (
						<ListItem>
							<ListItemText>No product lines assigned</ListItemText>
						</ListItem>
					)}
				</List>
				<Button variant="outlined" size="small" color="primary" style={{ marginBottom: 10 }} onClick={() => setShowAssignToProductLine(true)} disabled={disabled}>
					+ Add
				</Button>

				<Typography variant="h6">Product Listings</Typography>
				<List dense>
					{taxRule.assignedProductListings.map((productListing, i) => (
						<React.Fragment key={productListing.listingId}>
							{i > 0 && <Divider component="li" />}
							<ListItem button disabled={disabled} onClick={() => onClickProductListing(productListing.productLineId, productListing.listingId)}>
								<ListItemText>{productListing.name}</ListItemText>
								<ListItemIcon>
									<Button
										variant="outlined"
										color="secondary"
										size="small"
										disabled={disabled}
										onClick={(e) => {
											e.stopPropagation();
											onUnassignProductListing(productListing.listingId);
										}}
									>
										Remove
									</Button>
								</ListItemIcon>
							</ListItem>
						</React.Fragment>
					))}
					{taxRule.assignedProductListings.length === 0 && (
						<ListItem>
							<ListItemText>No product listings assigned</ListItemText>
						</ListItem>
					)}
				</List>
				<Button size="small" variant="outlined" color="primary" onClick={() => setShowAssignToProductListing(true)} disabled={disabled}>
					+ Add
				</Button>
			</CardContent>
		</Card>
	);
}

interface AssignToProductLineDialogProps {
	open: boolean;
	onClose: () => void;
}

function AssignToProductLineDialog(props: AssignToProductLineDialogProps) {
	const { open, onClose } = props;
	const context = useTaxRuleDetailContext();
	const { taxRule } = context;
	const alert = useAlert();
	const productCatalogContext = useProductCatalog();
	const productLines = productCatalogContext.productCatalog.productLines.filter((pl) => !taxRule.assignedProductLines.some((a) => a.productLineId === pl.id));

	const [selectedProductLineId, setSelectedProductLineId] = React.useState<string>();
	const selectedProductLine = productLines.find((productLine) => productLine.id === selectedProductLineId);

	const [disabled, setDisabled] = React.useState(false);

	const onSelect = (e: React.ChangeEvent<{ value: string }>) => setSelectedProductLineId(e.target.value);

	const onAssign = async () => {
		if (!selectedProductLine) {
			return;
		}

		setDisabled(true);
		const result = await TaxRuleService.assignToProductLine(taxRule.id, selectedProductLine.id);
		setDisabled(false);

		if (result.success) {
			context.updateTaxRule(result.data);
			onClose();
			alert.success(`Assigned to ${selectedProductLine.name}`);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Assign To Product Line</DialogTitle>
			<DialogContent>
				<DialogContentText>Select the product line to assign this tax rule to.</DialogContentText>
				<TextField select label="Product Line" value={selectedProductLine ? selectedProductLineId : "None"} fullWidth onChange={onSelect} disabled={disabled}>
					<MenuItem value="None">Select Product Line</MenuItem>
					{productLines.map((productLine) => (
						<MenuItem key={productLine.id} value={productLine.id}>
							{productLine.name}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="secondary" variant="outlined" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={onAssign} color="primary" variant="contained" disabled={disabled || !selectedProductLine}>
					Assign
				</Button>
			</DialogActions>
		</Dialog>
	);
}

interface AssignToProductListingDialogProps {
	open: boolean;
	onClose: () => void;
}

function AssignToProductListingDialog(props: AssignToProductListingDialogProps) {
	const { open, onClose } = props;
	const alert = useAlert();
	const context = useTaxRuleDetailContext();
	const { taxRule } = context;

	const productCatalogContext = useProductCatalog();

	const productLines = productCatalogContext.productCatalog.productLines.filter((pl) => !taxRule.assignedProductLines.some((a) => a.productLineId === pl.id));

	const [selectedProductLineId, setSelectedProductLineId] = React.useState<string>();
	const [selectedProductListingId, setSelectedProductListingId] = React.useState<string>();

	const selectedProductLine = productLines.find((productLine) => productLine.id === selectedProductLineId);
	const productListings = selectedProductLine?.availableListings.filter((pl) => !taxRule.assignedProductListings.some((a) => a.listingId === pl.id));
	const selectedProductListing = productListings?.find((productListing) => productListing.id === selectedProductListingId);

	const [disabled, setDisabled] = React.useState(false);

	const onSelectLine = (e: React.ChangeEvent<{ value: string }>) => setSelectedProductLineId(e.target.value);
	const onSelectListing = (e: React.ChangeEvent<{ value: string }>) => setSelectedProductListingId(e.target.value);

	const onAssign = async () => {
		if (!selectedProductListing) {
			return;
		}

		setDisabled(true);
		const result = await TaxRuleService.assignToProductListing(taxRule.id, selectedProductListing.id);
		setDisabled(false);

		if (result.success) {
			context.updateTaxRule(result.data);
			onClose();
			alert.success(`Assigned to ${selectedProductListing.name}`);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Assign To Product Listing</DialogTitle>
			<DialogContent>
				<DialogContentText>Select the product listing to assign this tax rule to.</DialogContentText>
				<TextField
					select
					label="Product Line"
					value={selectedProductLine ? selectedProductLineId : "None"}
					fullWidth
					onChange={onSelectLine}
					disabled={disabled}
					style={{ marginBottom: 14 }}
				>
					<MenuItem value="None">Select Product Line</MenuItem>
					{productLines.map((productLine) => (
						<MenuItem key={productLine.id} value={productLine.id}>
							{productLine.name}
						</MenuItem>
					))}
				</TextField>
				<TextField
					select
					label="Product Listing"
					value={selectedProductListing ? selectedProductListingId : "None"}
					fullWidth
					onChange={onSelectListing}
					disabled={disabled || !productListings}
				>
					<MenuItem value="None">{!productListings ? "Must Select Product Line" : "Select Product Listing"}</MenuItem>
					{productListings?.map((productListing) => (
						<MenuItem key={productListing.id} value={productListing.id}>
							{productListing.name}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="secondary" variant="outlined" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={onAssign} color="primary" variant="contained" disabled={disabled || !selectedProductListing}>
					Assign
				</Button>
			</DialogActions>
		</Dialog>
	);
}
