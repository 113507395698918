import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Invoice } from "../../../entities/billing/Invoice";
import { PaymentTerms } from "../../../entities/billing/PaymentTerms";
import { PaymentTermsService } from "../../../services/billing/PaymentTermsService";
import { InvoiceService } from "../../../services/billing/InvoiceService";
import { useAlert } from "../../../hooks/useAlert";
import { Alert } from "@material-ui/lab";

export function ChangeInvoicePaymentTermsDialog(props: { invoice: Invoice; open: boolean; onClose: () => void; onTermsChanged: (invoice: Invoice) => void }) {
	const { invoice, open, onClose } = props;
	const alert = useAlert();

	const [terms, setTerms] = useState<PaymentTerms[]>();
	const [selectedTermsId, setSelectedTermsId] = useState(invoice.paymentTermsId);
	const [disabled, setDisabled] = useState(false);

	const ogTerms = useMemo(() => terms?.find((t) => t.id === invoice.paymentTermsId), [invoice, terms]);
	const selectedTerms = useMemo(() => terms?.find((t) => t.id === selectedTermsId), [selectedTermsId, terms]);

	const daysDifference = (selectedTerms?.dueDays ?? 0) - (ogTerms?.dueDays ?? 0);

	useEffect(() => {
		async function fetchTerms() {
			const result = await PaymentTermsService.getAll();
			if (result.success) {
				setTerms(result.data);
			}
		}
		fetchTerms();
	}, []);

	const onTermsSelectionChange = (event: React.ChangeEvent<{ value: string }>) => setSelectedTermsId(event.target.value);

	const onSave = async () => {
		setDisabled(true);
		const result = await InvoiceService.update({ invoiceId: invoice.id, paymentTermsId: selectedTermsId });
		setDisabled(false);

		if (result.success) {
			const updatedInvoice = result.data;
			props.onTermsChanged(updatedInvoice);
			alert.success(`Invoice terms changed to ${updatedInvoice.paymentTermsName}`);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Change Terms</DialogTitle>
			<DialogContent>
				<DialogContentText>Select new terms for invoice {invoice.shortId}</DialogContentText>
				<TextField select variant="outlined" fullWidth label="Terms" value={selectedTermsId} onChange={onTermsSelectionChange} disabled={disabled}>
					{terms?.map((term) => (
						<MenuItem key={term.id} value={term.id}>
							{term.name}
						</MenuItem>
					))}
				</TextField>
				{daysDifference !== 0 ? (
					<Alert severity="warning" style={{ marginTop: "1rem" }}>
						Invoice will be due {Math.abs(daysDifference)} days {daysDifference > 0 ? "later" : "earlier"}.
					</Alert>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="secondary" variant="outlined" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={onSave} color="primary" variant="contained" disabled={disabled}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
