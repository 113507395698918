type OptionalDirection = "optionalStart" | "optionalEnd";
export type SortDirection = "Ascending" | "Descending" | "asc" | "desc";

function isAscending(direction: SortDirection): boolean {
	return direction === "Ascending" || direction === "asc";
}

export const orderBy = {
	optional: {
		date<T>(selector: (obj: T) => Date | null | undefined, direction: SortDirection, order: OptionalDirection): (a: T, b: T) => number {
			return (a, b) => {
				const dateA = selector(a);
				const dateB = selector(b);

				if (dateA === dateB) {
					return 0;
				}

				const optionalDirection = order === "optionalStart" ? 1 : -1;
				if (dateA == null || dateB == null) {
					return optionalDirection;
				}

				if (isAscending(direction)) {
					return dateA.getTime() - dateB.getTime();
				}

				return dateB.getTime() - dateA.getTime();
			};
		},
		number<T>(selector: (obj: T) => number | null | undefined, direction: SortDirection, order: OptionalDirection): (a: T, b: T) => number {
			return (a, b) => {
				const numberA = selector(a);
				const numberB = selector(b);

				if (numberA === numberB) {
					return 0;
				}

				const optionalDirection = order === "optionalStart" ? 1 : -1;
				if (numberA == null || numberB == null) {
					return optionalDirection;
				}

				if (isAscending(direction)) {
					return numberA - numberB;
				}
				return numberB - numberA;
			};
		},
		string<T>(selector: (obj: T) => string | null | undefined, direction: SortDirection, order: OptionalDirection): (a: T, b: T) => number {
			return (a, b) => {
				const stringA = selector(a);
				const stringB = selector(b);

				if (stringA === stringB) {
					return 0;
				}

				const optionalDirection = order === "optionalStart" ? 1 : -1;
				if (stringA == null || stringB == null) {
					return optionalDirection;
				}

				const value = stringA.localeCompare(stringB);
				if (value > 0) {
					return isAscending(direction) ? 1 : -1;
				}

				if (value < 0) {
					return isAscending(direction) ? -1 : 1;
				}

				return 0;
			};
		},
	},
	boolean<T>(selector: (obj: T) => boolean, direction: SortDirection): (a: T, b: T) => number {
		return (a, b) => {
			const numberA = selector(a) ? 1 : 0;
			const numberB = selector(b) ? 1 : 0;

			if (numberA === numberB) {
				return 0;
			}

			if (isAscending(direction)) {
				return numberA - numberB;
			}
			return numberB - numberA;
		};
	},
	date<T>(selector: (obj: T) => Date, direction: SortDirection): (a: T, b: T) => number {
		return (a, b) => (isAscending(direction) ? selector(a).getTime() - selector(b).getTime() : selector(b).getTime() - selector(a).getTime());
	},
	number<T>(selector: (obj: T) => number, direction: SortDirection): (a: T, b: T) => number {
		return (a, b) => (isAscending(direction) ? selector(a) - selector(b) : selector(b) - selector(a));
	},
	string<T>(selector: (obj: T) => string, direction: SortDirection): (a: T, b: T) => number {
		return (a, b) => {
			const value = selector(a).localeCompare(selector(b));
			if (value > 0) {
				return isAscending(direction) ? 1 : -1;
			}

			if (value < 0) {
				return isAscending(direction) ? -1 : 1;
			}

			return 0;
		};
	},
};
