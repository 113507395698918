import { Button, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PropaneTank } from "../../entities/customer/PropaneTank";
import { formatAddress } from "../../entities/customer/GeocodedAddress";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { addDays } from "date-fns";
import { AddressAutoComplete, AutoCompleteAddress } from "../../components/address/AddressAutoComplete";
import { useAlert } from "../../hooks/useAlert";
import { usePropaneTankDetailPage } from "./PropaneTankDetailPage";
import { FieldValidationError, validationError } from "../../services/server/ServerValidationError";
import { PropaneTankService } from "../../services/customer/PropaneTankService";
import { ButtonLink } from "../../components/ButtonLink";
import { ExemptionType, PropaneTankTaxExemption } from "../../entities/accounting/TaxExemption";
import { TaxExemptionStatus } from "../../components/taxExemptions/TaxExemptionStatus";
import { TaxExemptionDialog } from "../../components/taxExemptions/TaxExemptionDialog";
import { TaxExemptionService } from "../../services/accounting/TaxExemptionService";
import { NumberTextField } from "../../components/NumberTextField";

interface PropaneTankDetailContext {
	propaneTank: PropaneTank;
	taxExemption: PropaneTankTaxExemption | null | undefined;
	pageDisabled: boolean;
	updatePropaneTank: (tank: PropaneTank) => void;
	setTaxExemption: (taxExemption: PropaneTankTaxExemption | null | undefined) => void;
	setEdit: (edit: boolean) => void;
	updatePageDisabled: (disabled: boolean) => void;
	editTaxExemption: () => void;
}

const PropaneTankDetailReactContext = React.createContext<PropaneTankDetailContext>({} as PropaneTankDetailContext);
const usePropaneTankDetailContext = () => React.useContext(PropaneTankDetailReactContext);

export function PropaneTankDetailsCard() {
	const alerts = useAlert();
	const context = usePropaneTankDetailPage();
	const { pageDisabled, propaneTank, updatePageDisabled, updatePropaneTank } = context;
	const customerId = propaneTank.customerId;
	const tankId = propaneTank.id;

	const [edit, setEdit] = useState(false);
	const [taxExemption, setTaxExemption] = useState<PropaneTankTaxExemption | null | undefined>();
	const [editTaxExemption, setEditTaxExemption] = useState(false);

	useEffect(() => {
		async function fetchTaxExemptions() {
			const result = await TaxExemptionService.getCustomerTaxExemptions(customerId);
			if (result.success) {
				const tankTaxExemption =(result.data.find((taxExemption) => taxExemption.type === ExemptionType.PropaneTank && taxExemption.tankId === tankId) as PropaneTankTaxExemption) ?? null;
				setTaxExemption(tankTaxExemption);
			} else {
				alerts.serverError(result);
			}
		}
		fetchTaxExemptions();
	}, [customerId, tankId, alerts]);

	return (
		<>
			<PropaneTankDetailReactContext.Provider
				value={{
					pageDisabled,
					propaneTank,
					taxExemption,
					updatePropaneTank,
					setTaxExemption,
					updatePageDisabled,
					setEdit,
					editTaxExemption: () => setEditTaxExemption(true),
				}}
			>
				{edit ? <EditInfoCard /> : <DisplayInfoCard />}
				{taxExemption !== undefined && (
					<TaxExemptionDialog
						open={editTaxExemption}
						onClose={() => setEditTaxExemption(false)}
						onChange={(t) => setTaxExemption(t as PropaneTankTaxExemption)}
						taxExemption={taxExemption}
						tankId={tankId}
						customerId={customerId}
					/>
				)}
			</PropaneTankDetailReactContext.Provider>
		</>
	);
}

function DisplayInfoCard() {
	const context = usePropaneTankDetailContext();
	const { propaneTank, taxExemption } = context;

	return (
		<Card>
			<CardHeader title="Tank Info" style={{ paddingBottom: 0 }} />
			<List>
				<ListItem>
					<ListItemText primary="Capacity" secondary={`${propaneTank.gallons} gallons`} />
					<ListItemText primary="Serial Number" secondary={propaneTank.serialNumber ? propaneTank.serialNumber : "Not Set"} />
				</ListItem>
				<ListItem>
					<ListItemText primary="Is Rental" secondary={propaneTank.isRental ? "Yes" : "No"} />
					<ListItemText primary="Last Gas Check" secondary={propaneTank.lastGasCheck ? propaneTank.lastGasCheck.toLocaleDateString() : "Never"} />
				</ListItem>
				<ListItem>
					<ListItemText primary="Location Description" secondary={propaneTank.locationDescription ? propaneTank.locationDescription : "Not Set"} />
				</ListItem>
				<ListItem>
					<ListItemText primary="Location" secondary={formatAddress(propaneTank.address)} />
				</ListItem>
				<ListItem>
					<ListItemText
						primary="Tax Status"
						secondary={
							<ButtonLink onClick={context.editTaxExemption}>
								<TaxExemptionStatus taxExemption={taxExemption} />
							</ButtonLink>
						}
					/>
				</ListItem>
			</List>
			<Button size="small" fullWidth variant="contained" style={{ borderRadius: 0 }} onClick={() => context.setEdit(true)}>
				Edit Tank Info
			</Button>
		</Card>
	);
}

function EditInfoCard() {
	const context = usePropaneTankDetailContext();
	const alert = useAlert();
	const { propaneTank, pageDisabled, taxExemption } = context;

	const [capacity, setCapacity] = useState<number | null>(propaneTank.gallons);
	const [serialNumber, setSerialNumber] = useState(propaneTank.serialNumber);
	const [locationDescription, setLocationDescription] = useState(propaneTank.locationDescription);
	const [isRental, setIsRental] = useState(propaneTank.isRental);
	const [lastGasCheck, setLastGasCheck] = useState(propaneTank.lastGasCheck);
	const [address, setAddress] = useState(propaneTank.address);
	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	const onAddressChange = (googleMapsAddress: AutoCompleteAddress) => {
		setAddress({ ...googleMapsAddress, googleMapsPlaceId: googleMapsAddress.placeId });
	};

	const onSave = async () => {
		setValidationErrors([]);
		context.updatePageDisabled(true);
		const result = await PropaneTankService.update({
			propaneTankId: propaneTank.id,
			capacity: capacity ? capacity : propaneTank.gallons,
			serialNumber,
			locationDescription,
			isRental,
			lastGasCheck,
			address,
		});
		context.updatePageDisabled(false);
		if (result.success) {
			alert.success("Tank Info Updated");
			context.updatePropaneTank(result.data);
			context.setEdit(false);
		} else if (result.validation) {
			setValidationErrors(result.errors);
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const tomorrow = addDays(new Date(), 1);

	return (
		<Card>
			<CardHeader title="Tank Info" style={{ paddingBottom: 0 }} />
			<CardContent>
				<NumberTextField
					fullWidth
					required
					variant="outlined"
					placeholder="Enter Tank Capacity"
					label="Capacity"
					value={capacity}
					onNumberChange={setCapacity}
					margin="normal"
					disabled={pageDisabled}
					error={validationError.isFieldInError(validationErrors, "Capacity")}
					helperText={validationError.getFieldError(validationErrors, "Capacity")}
				/>
				<TextField
					fullWidth
					variant="outlined"
					placeholder="Enter Tank Serial Number"
					label="Serial Number"
					value={serialNumber ?? ""}
					onChange={(e) => setSerialNumber(e.target.value)}
					margin="normal"
					disabled={pageDisabled}
					error={validationError.isFieldInError(validationErrors, "SerialNumber")}
					helperText={validationError.getFieldError(validationErrors, "SerialNumber")}
				/>
				<Grid container spacing={1} style={{ marginTop: 5 }}>
					<Grid item xs={4}>
						<TextField
							select
							fullWidth
							variant="outlined"
							label="Is Rental"
							value={isRental}
							onChange={(e) => setIsRental(e.target.value === "true")}
							disabled={pageDisabled}
							error={validationError.isFieldInError(validationErrors, "IsRental")}
							helperText={validationError.getFieldError(validationErrors, "IsRental")}
						>
							<MenuItem value={"true"}>Yes</MenuItem>
							<MenuItem value={"false"}>No</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={8}>
						<KeyboardDatePicker
							fullWidth
							disableToolbar
							variant="inline"
							format="MM/dd/yy"
							label="Last Gas Check"
							inputVariant="outlined"
							maxDate={new Date(tomorrow.toDateString())}
							maxDateMessage="Gas Check cannot be in the future"
							value={lastGasCheck}
							onChange={setLastGasCheck}
							disabled={pageDisabled}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							error={validationError.isFieldInError(validationErrors, "LastGasCheck")}
							helperText={validationError.getFieldError(validationErrors, "LastGasCheck")}
						/>
					</Grid>
				</Grid>
				<TextField
					fullWidth
					variant="outlined"
					placeholder="Enter Location Description"
					label="Location Description"
					value={locationDescription}
					onChange={(e) => setLocationDescription(e.target.value)}
					disabled={pageDisabled}
					margin="normal"
					helperText="Example: Front House, Barn, etc."
				/>
				<AddressAutoComplete
					label="Address"
					style={{ marginTop: 10 }}
					fullWidth
					disabled={pageDisabled}
					value={address ? formatAddress(address) : undefined}
					required
					onAddressChange={onAddressChange}
					error={validationError.isFieldInError(validationErrors, "Address")}
					helperText={validationError.getFieldError(validationErrors, "Address")}
				/>
				<ListItem>
					<ListItemText
						primary="Tax Status"
						secondary={
							<ButtonLink onClick={context.editTaxExemption}>
								<TaxExemptionStatus taxExemption={taxExemption} />
							</ButtonLink>
						}
					/>
				</ListItem>
			</CardContent>
			<Grid container>
				<Grid item xs={6}>
					<Button size="small" fullWidth variant="contained" style={{ borderRadius: 0 }} onClick={() => context.setEdit(false)} disabled={pageDisabled}>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button size="small" color="primary" fullWidth variant="contained" style={{ borderRadius: 0 }} onClick={onSave} disabled={pageDisabled}>
						Save Changes
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
}
