import { Box, Button, Card, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import { BackButton } from "../../../components/BackButton";
import { Driver } from "../../../entities/routing/Driver";
import { AddInstructionsSection } from "./AddInstructionsSection";
import { AddDueDateSection } from "./AddDueDateSection";
import { AssignDriverSection } from "./AssignDriverSection";
import { TicketTankSection } from "./TicketTankSection";
import { Tag } from "../../../entities/customer/Tag";
import { AddTagSection } from "./AddTagSection";
import { GeocodedAddress } from "../../../entities/customer/GeocodedAddress";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { NewTicketTankFill } from "./TicketTankFill";
import { QuoteSection } from "./QuoteSection";
import { NewTicketProduct } from "./TicketProduct";
import { useAlert } from "../../../hooks/useAlert";
import { CreateDriverTicketRequest, DriverTicketService } from "../../../services/routing/DriverTicketService";
import { DriverTicket } from "../../../entities/routing/DriverTicket";
import { LastInvoiceSection } from "./LastInvoiceSection";
import { useOfflineTickets } from "../../../providers/OfflineTicketProvider";
import { AppliedPriceLock, CustomerContractType, PriceLockContract, applyPriceLockToNewTicket } from "../../../entities/customer/CustomerContract";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";

interface CreateTicketContext {
	address: GeocodedAddress;
	setAddress: React.Dispatch<React.SetStateAction<GeocodedAddress>>;
	driver: Driver | null;
	setDriver: React.Dispatch<React.SetStateAction<Driver | null>>;
	dueDate: Date | null;
	setDueDate: React.Dispatch<React.SetStateAction<Date | null>>;
	tags: Tag[];
	setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
	instructions: string | null;
	setInstructions: React.Dispatch<React.SetStateAction<string | null>>;
	tankFills: NewTicketTankFill[];
	setTankFills: React.Dispatch<React.SetStateAction<NewTicketTankFill[]>>;
	ticketProducts: NewTicketProduct[];
	setTicketProducts: React.Dispatch<React.SetStateAction<NewTicketProduct[]>>;
	appliedPriceLocks: AppliedPriceLock[];
}

const CreateTicketReactContext = React.createContext<CreateTicketContext>({} as CreateTicketContext);
export const useCreateTicketContext = () => React.useContext(CreateTicketReactContext);

export const NewCreateTicketPage = (props: { onClose: () => void; onTicketAdded: (ticket: DriverTicket) => void }) => {
	const alert = useAlert();
	const customerContext = useCustomerDetailPage();
	const { customer, profile } = customerContext;
	const contracts = profile.contracts;

	const productCatalogContext = useProductCatalog();

	const offlineTicketContext = useOfflineTickets();

	const theme = useTheme();
	const isXS = useMediaQuery(theme.breakpoints.down("xs"));

	const [address, setAddress] = React.useState<GeocodedAddress>(customer.address);
	const [driver, setDriver] = React.useState<Driver | null>(null);
	const [dueDate, setDueDate] = React.useState<Date | null>(null);
	const [tags, setTags] = React.useState<Tag[]>([]);
	const [instructions, setInstructions] = React.useState<string | null>(null);
	const [tankFills, setTankFills] = React.useState<NewTicketTankFill[]>([]);
	const [ticketProducts, setTicketProducts] = React.useState<NewTicketProduct[]>([]);

	const [disabled, setDisabled] = React.useState(false);

	const priceLocks = useMemo(() => contracts.filter((c) => c.type === CustomerContractType.PriceLock && c.status === "Active") as PriceLockContract[], [contracts]);
	const appliedPriceLocks = useMemo(
		() => priceLocks.map((pl) => applyPriceLockToNewTicket(pl, tankFills, ticketProducts)).filter((apl) => apl.products.length > 0),
		[priceLocks, tankFills, ticketProducts]
	);

	const createTicket = async () => {
		const request: CreateDriverTicketRequest = {
			customerId: customer.id,
			location: address,
			driverId: driver?.id,
			dueDate: dueDate,
			tagIds: tags.map((t) => t.id),
			instructions: instructions,
			priceLockContractIds: appliedPriceLocks.map((l) => l.priceLock.id),
			tankFills: tankFills.map((t) => t.toTankFillRequest()),
			products: ticketProducts.map((t) => t.toTicketProductRequest()),
		};
		setDisabled(true);
		const result = await DriverTicketService.create(request);
		setDisabled(false);

		if (result.success) {
			alert.success("Ticket created");
			props.onTicketAdded(result.data);
			offlineTicketContext.create(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const isEmpty = instructions === null && tankFills.length === 0 && ticketProducts.length === 0;
	const disableCreate = isEmpty || disabled;

	if (productCatalogContext.loadStatus !== "ready") return <OverlayLoadingScreen />;

	return (
		<CreateTicketReactContext.Provider
			value={{
				address,
				setAddress,
				driver,
				setDriver,
				dueDate,
				setDueDate,
				tags,
				setTags,
				instructions,
				setInstructions,
				tankFills,
				setTankFills,
				ticketProducts,
				setTicketProducts,
				appliedPriceLocks,
			}}
		>
			<Box m={isXS ? 0 : 2}>
				<Grid container alignItems="center">
					<Grid item>
						<BackButton onClick={props.onClose} />
					</Grid>
					<Grid item>
						<Typography variant="h5">New Ticket</Typography>
					</Grid>
				</Grid>
				<Grid container justify="center" spacing={isXS ? undefined : 4}>
					<Grid item xl={5} lg={6} md={7} sm={10} xs={12}>
						<Card>
							<Box m={1}>
								<Grid container spacing={1}>
									<Grid item sm={4} xs={12}>
										<AssignDriverSection />
									</Grid>
									<Grid item sm={4} xs={12}>
										<AddDueDateSection />
									</Grid>
									<Grid item sm={4} xs={12}>
										<AddTagSection />
									</Grid>
									<Grid item xs={12}>
										<AddInstructionsSection />
									</Grid>
									<Grid item xs={12}>
										<TicketTankSection />
									</Grid>
									<Grid item xs={12}>
										<QuoteSection />
									</Grid>
									<Grid item xs={12}>
										<AddressSection />
									</Grid>
								</Grid>
							</Box>
							<Box mt={2}>
								<Button variant="contained" color="primary" size="large" fullWidth disabled={disableCreate} onClick={createTicket}>
									Create Ticket
								</Button>
							</Box>
						</Card>
					</Grid>
					<Grid item xl={4} lg={4} md={5} sm={10} xs={12}>
						<Box mt={isXS ? 2 : 0}>
							<LastInvoiceSection />
						</Box>
					</Grid>
				</Grid>
			</Box>
		</CreateTicketReactContext.Provider>
	);
};

function AddressSection() {
	const context = useCreateTicketContext();
	const { address, tankFills } = context;

	const customerContext = useCustomerDetailPage();
	const { customer } = customerContext;

	const isCustomerAddress = address.street === customer.address.street;

	// get distinct street addresses from tank fills
	const tankFillAddresses = tankFills.map((t) => t.tank.address.street).filter((v, i, a) => a.indexOf(v) === i);
	const tooManyAddresses = tankFillAddresses.length > 1;

	return (
		<Box>
			<Grid container alignItems="center">
				<Grid item style={{ flexShrink: 0 }}>
					<Typography variant="h6" style={{ fontSize: "1rem", paddingRight: 5 }}>
						Address [{isCustomerAddress ? "Customer" : "Tank"}]:
					</Typography>
				</Grid>
				<Grid item style={{ width: "100%" }}>
					<Typography
						variant="h6"
						color="textSecondary"
						style={{
							fontSize: "1rem",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							maxWidth: "100%",
						}}
						noWrap
					>
						{address.street} {address.city}, {address.state} {address.postalCode}
					</Typography>
					{tooManyAddresses && (
						<Typography variant="h6" color="error" style={{ fontSize: "1rem" }}>
							Tanks at multiple addresses
						</Typography>
					)}
				</Grid>
			</Grid>
		</Box>
	);
}
