import { Button, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useDrivers } from "../../../providers/DriverProvider";
import { useCreateTicketContext } from "./NewCreateTicketPage";

export function AssignDriverSection() {
	const { drivers } = useDrivers();
	const { driver, setDriver } = useCreateTicketContext();

	const [assignMode, setAssignMode] = React.useState(false);

	const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as string;
		const driver = drivers.find((driver) => driver.id === value);
		setDriver(driver ?? null);
		setAssignMode(false);
	};

	if (assignMode) {
		return (
			<TextField
				style={{ marginTop: 5 }}
				size="small"
				label="Driver"
				variant="outlined"
				helperText="Assign a driver to this ticket"
				value={driver?.id ?? "None"}
				onChange={onChange}
				autoFocus
				onBlur={() => setAssignMode(false)}
				select
			>
				<MenuItem value="None">None</MenuItem>
				{drivers.map((driver) => (
					<MenuItem key={driver.id} value={driver.id}>
						{driver.name}
					</MenuItem>
				))}
			</TextField>
		);
	}

	return (
		<Grid container alignItems="center">
			<Grid item>
				<Button color="primary" variant="text" style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }} onClick={() => setAssignMode(true)}>
					Driver:
				</Button>
			</Grid>
			<Grid item>
				<Typography variant="h6" color={driver == null ? "textSecondary" : "textPrimary"}>
					{driver?.name ?? "Unassigned"}
				</Typography>
			</Grid>
		</Grid>
	);
}

