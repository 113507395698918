import { Money, SafeMoney } from "../../utility/Money";

export interface DispatchProInvoiceLineItem {
    planItemId?: string;
    name: string;
    price: number;
    quantity: number;
    total: number;
} 

export interface DispatchProInvoicePayment {
    amount: number;
    paymentDate: Date;
    description: string;
    reference: string;
}

type SerializedDispatchProInvoicePayment = Omit<DispatchProInvoicePayment, "paymentDate"> & { paymentDate: string };

function deserializeDispatchProInvoicePayment(payment: SerializedDispatchProInvoicePayment): DispatchProInvoicePayment {
    return {
        ...payment,
        paymentDate: new Date(payment.paymentDate),
    };
}

export interface DispatchProInvoice {
    id: number;
    invoiceDate: Date;
    dueDate: Date;
    total: SafeMoney;
    amountPaid: SafeMoney;
    balance: SafeMoney;
    lineItems: DispatchProInvoiceLineItem[];
    payments: DispatchProInvoicePayment[];
}

type SerializedDispatchProInvoice = Omit<DispatchProInvoice, "invoiceDate" | "dueDate" | "payments" | "total" | "amountPaid" | "balance"> & {
    invoiceDate: string;
    dueDate: string;
    total: number;
    amountPaid: number;
    balance: number;
    payments: SerializedDispatchProInvoicePayment[];
};

export function deserializeDispatchProInvoice(invoice: SerializedDispatchProInvoice): DispatchProInvoice {
    return {
        ...invoice,
        invoiceDate: new Date(invoice.invoiceDate),
        dueDate: new Date(invoice.dueDate),
        total: Money.fromDecimal(invoice.total),
        amountPaid: Money.fromDecimal(invoice.amountPaid),
        balance: Money.fromDecimal(invoice.balance),
        payments: invoice.payments.map(deserializeDispatchProInvoicePayment),
    };
}

export function deserializeDispatchProInvoices(invoice: SerializedDispatchProInvoice[]): DispatchProInvoice[] {
    return invoice.map(deserializeDispatchProInvoice);
}