import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { Invoice } from "../../../entities/billing/Invoice";
import { useAlert } from "../../../hooks/useAlert";
import { InvoiceService } from "../../../services/billing/InvoiceService";

interface ChangeInvoiceDueDateDialogProps {
	open: boolean;
	invoice: Invoice;
	onClose: () => void;
	onDueDateChanged: (invoice: Invoice) => void;
}

export function ChangeInvoiceDueDateDialog(props: ChangeInvoiceDueDateDialogProps) {
	const { open, invoice } = props;

	const alert = useAlert();

	const [dueDate, setDueDate] = React.useState<Date | null>(invoice.dueBy);
	const [disabled, setDisabled] = React.useState(false);

	const onSubmit = async () => {
		if (!dueDate) {
			alert.validation({ errors: [{ field: "dueDate", errors: ["Due date is required"] }] });
			return;
		}

		setDisabled(true);
		const result = await InvoiceService.update({ invoiceId: invoice.id, dueBy: dueDate});
		setDisabled(false);

		if (result.success) {
			props.onDueDateChanged(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={props.onClose} maxWidth="xs">
			<DialogTitle>Change Due Date</DialogTitle>
			<DialogContent>
			<DialogContentText>The invoice will will be due on this date regardless of the payment terms</DialogContentText>
				<KeyboardDatePicker
					fullWidth
					margin="normal"
					required
					disableToolbar
					disabled={disabled}
					variant="inline"
					format="MM/dd/yy"
					label="Invoice Due Date"
					inputVariant="outlined"
					minDate={invoice.issuedOn}
					minDateMessage="Due date should not be before the invoice issue date"
					value={dueDate}
					onChange={setDueDate}
					KeyboardButtonProps={{
						"aria-label": "change invoice due date",
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} disabled={disabled} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button onClick={onSubmit} disabled={disabled} variant="contained" color="primary">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
