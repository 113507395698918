import { Modify } from "../../utility/modifyType";

export enum LedgerClassification {
	Asset = 1,
	Liability = 2,
	Equity = 3,
	Revenue = 4,
	Expense = 5,
}

export enum LedgerAccountType {
	Accounts_Payable = 1,
	Accounts_Receivable = 2,
	Asset = 3,
	Bank = 4,
	Cost_Of_Goods_Sold = 5,
	Equity = 6,
	Expense = 7,
	Income = 8,
	Liability = 9,
	Tax = 10,
}

export enum LedgerIntegration {
	None = 0,
	QuickBooks_Desktop = 1,
	QuickBooks_Online = 2,
}

interface GeneralLedgerAccountBase {
	id: number;
	name: string;
	classification: LedgerClassification;
	integration: LedgerIntegration;
	type: LedgerAccountType;
}

export type DispatchProLedgerAccount = Modify<
GeneralLedgerAccountBase,
{
	integration: LedgerIntegration.None;
}
>;

export type QuickBooksDesktopLedgerAccount = Modify<
	GeneralLedgerAccountBase,
	{
		integration: LedgerIntegration.QuickBooks_Desktop;
		quickBooksAccountName: string;
	}
>;

export type QuickBooksOnlineLedgerAccount = Modify<
	GeneralLedgerAccountBase,
	{
		integration: LedgerIntegration.QuickBooks_Online;
		quickBooksAccountName: string;
		quickBooksAccountId: string;
	}
>;

export type GeneralLedgerAccount = DispatchProLedgerAccount | QuickBooksDesktopLedgerAccount | QuickBooksOnlineLedgerAccount;

export function getAvailableLedgerAccountTypes(classification: LedgerClassification): LedgerAccountType[] {
	const classificationMapping: Record<LedgerClassification, LedgerAccountType[]> = {
		[LedgerClassification.Asset]: [LedgerAccountType.Asset, LedgerAccountType.Accounts_Receivable, LedgerAccountType.Bank],
		[LedgerClassification.Liability]: [LedgerAccountType.Liability, LedgerAccountType.Accounts_Payable, LedgerAccountType.Tax],
		[LedgerClassification.Equity]: [LedgerAccountType.Equity],
		[LedgerClassification.Revenue]: [LedgerAccountType.Income],
		[LedgerClassification.Expense]: [LedgerAccountType.Expense, LedgerAccountType.Cost_Of_Goods_Sold],
	};

	return classificationMapping[classification] || [];
}
