import React, { useEffect, useState } from "react";
import { Tenant, InvoicePreferences, StatementPreferences } from "../../../entities/auth/Tenant";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { AccountService } from "../../../services/business/AccountService";
import { FetchOptionalResult } from "../../../services/server/WebClient";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { useAlert } from "../../../hooks/useAlert";
import { BusinessService } from "../../../services/business/BusinessService";

export function DocumentsPage() {
	const [tenantResult, setTenantResult] = useState<FetchOptionalResult<Tenant> | null>(null);

	useEffect(() => {
		async function fetchTenant() {
			const result = await AccountService.getUserTenant();
			setTenantResult(result);
		}
		fetchTenant();
	}, []);

	if (!tenantResult) {
		return <OverlayLoadingScreen />;
	}

	if (tenantResult.error) {
		return <ServerErrorView serverError={tenantResult} />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h4">Documents</Typography>
			</Grid>
			<Grid item lg={7} xs={12}>
				<InvoicePreferencesCard invoicePreferences={tenantResult.data.invoicePreferences} />
			</Grid>
			<Grid item lg={7} xs={12}>
				<StatementPreferencesCard statementPreferences={tenantResult.data.statementPreferences} />
			</Grid>
		</Grid>
	);
}

function InvoicePreferencesCard(props: { invoicePreferences: InvoicePreferences }) {
	const { invoicePreferences } = props;

	const alert = useAlert();

	const [headerText, setHeaderText] = useState<string | null>(invoicePreferences.headerText);
	const [footerText, setFooterText] = useState<string | null>(invoicePreferences.footerText);
	const [pastDueNotice, setPastDueNotice] = useState<boolean>(invoicePreferences.pastDueNotice);
	const [showPrice, setShowPrice] = useState<boolean>(invoicePreferences.showPrice);
	const [useTicketNumberForInvoiceCode, setUseTicketNumberForInvoiceCode] = useState<boolean>(invoicePreferences.useTicketNumberForInvoiceCode);
	const [includePageNumbers, setIncludePageNumbers] = useState<boolean>(invoicePreferences.includePageNumbers);
	const [addReturnAddressToFooter, setAddReturnAddressToFooter] = useState<boolean>(invoicePreferences.addReturnAddressToFooter);
	const [invoiceVersion, setInvoiceVersion] = useState<1 | 2>(invoicePreferences.version);

	const [disable, setDisable] = useState(false);

	const savePreferences = async () => {
		setDisable(true);
		const result = await BusinessService.updateInvoicePreferences({
			headerText,
			footerText,
			pastDueNotice,
			showPrice,
			useTicketNumberForInvoiceCode,
			includePageNumbers,
			addReturnAddressToFooter,
			version: invoiceVersion,
		});
		setDisable(false);
		if (result.success) {
			alert.success("Invoice preferences saved");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<CardHeader title="Invoice Preferences" subheader="These settings will be used for all invoices generated in the system." />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							disabled={disable}
							label="Invoice Version"
							fullWidth
							variant="outlined"
							select
							value={invoiceVersion}
							onChange={(e) => setInvoiceVersion(Number(e.target.value) as 1 | 2)}
						>
							<MenuItem value={1}>Version 1</MenuItem>
							<MenuItem value={2}>Version 2</MenuItem>
						</TextField>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							disabled={disable}
							label="Show Price"
							fullWidth
							variant="outlined"
							select
							helperText="Show the unit price of each product"
							value={showPrice.toString()}
							onChange={(e) => setShowPrice(e.target.value === "true")}
						>
							<MenuItem value="true">Yes</MenuItem>
							<MenuItem value="false">No</MenuItem>
						</TextField>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							disabled={disable}
							label="Show Past Due Image"
							fullWidth
							variant="outlined"
							select
							helperText="Add a red PAST DUE to the invoice if past due"
							value={pastDueNotice.toString()}
							onChange={(e) => setPastDueNotice(e.target.value === "true")}
						>
							<MenuItem value="true">Yes</MenuItem>
							<MenuItem value="false">No</MenuItem>
						</TextField>
					</Grid>

					<Grid item md={4} sm={6} xs={12}>
						<TextField
							disabled={disable}
							label="Include Page Numbers"
							fullWidth
							variant="outlined"
							select
							helperText="Add page numbers to the bottom of each page"
							value={includePageNumbers.toString()}
							onChange={(e) => setIncludePageNumbers(e.target.value === "true")}
						>
							<MenuItem value="true">Yes</MenuItem>
							<MenuItem value="false">No</MenuItem>
						</TextField>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							disabled={disable}
							label="Add Return Address to Footer"
							fullWidth
							variant="outlined"
							select
							helperText="Add the your mailing address to the bottom of each page"
							value={addReturnAddressToFooter.toString()}
							onChange={(e) => setAddReturnAddressToFooter(e.target.value === "true")}
						>
							<MenuItem value="true">Yes</MenuItem>
							<MenuItem value="false">No</MenuItem>
						</TextField>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<TextField
							disabled={disable}
							label="Invoice Code Generation"
							fullWidth
							variant="outlined"
							select
							value={useTicketNumberForInvoiceCode}
							helperText={
								useTicketNumberForInvoiceCode ? "Invoice codes will be generated using the ticket number if set" : "Invoice codes will be auto generated"
							}
							onChange={(e) => setUseTicketNumberForInvoiceCode(Boolean(e.target.value))}
						>
							<MenuItem value="false">Use Auto Generated Code</MenuItem>
							<MenuItem value="true">Use Ticket Number</MenuItem>
						</TextField>
					</Grid>

					<Grid item md={6} xs={12}>
						<TextField
							disabled={disable}
							label="Header Text"
							fullWidth
							variant="outlined"
							multiline
							value={headerText}
							onChange={(e) => setHeaderText(e.target.value)}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<TextField
							disabled={disable}
							label="Footer Text"
							fullWidth
							variant="outlined"
							multiline
							value={footerText}
							onChange={(e) => setFooterText(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" onClick={savePreferences} disabled={disable}>
							Save Preferences
						</Button>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function StatementPreferencesCard(props: { statementPreferences: StatementPreferences }) {
	const alert = useAlert();
	const { statementPreferences } = props;

	const [disabled, setDisabled] = useState(false);
	const [memo, setMemo] = useState(statementPreferences.memo);

	const onStatementMemoSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		const result = await BusinessService.updateStatementPreferences({ memo: memo });
		setDisabled(false);
		if (result.success) {
			alert.success("Statement memo updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<CardHeader title="Statement Preferences" />
			<form onSubmit={onStatementMemoSubmit}>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<TextField
								multiline
								fullWidth
								disabled={disabled}
								label="Statement Memo"
								InputLabelProps={{ shrink: true }}
								variant="outlined"
								placeholder="Enter a memo to be printed on customer statements."
								value={memo}
								onChange={(e) => setMemo(e.target.value)}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Button type="submit">Save</Button>
				</CardActions>
			</form>
		</Card>
	);
}
