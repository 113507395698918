import React from "react";
import { NotFound } from "../../../components/NotFound";
import {
	GeneralLedgerAccount,
	LedgerIntegration,
} from "../../../entities/accounting/GeneralLedgerAccount";
import { routes } from "../../../routes";
import { QuickBooksDesktopLedgerAccountDetailView } from "./components/QuickBooksDesktopLedgerAccountDetailView";
import { QuickBooksOnlineLedgerAccountDetailView } from "./components/QuickBooksOnlineLedgerAccountDetailView";
import { DispatchProLedgerAccountDetailView } from "./components/DispatchProLedgerAccountDetailView";

export function LedgerAccountDetailView(props: { account: GeneralLedgerAccount }) {
	const { account } = props;

	if (account.integration === LedgerIntegration.QuickBooks_Desktop) {
		return <QuickBooksDesktopLedgerAccountDetailView account={account} />;
	}
	if (account.integration === LedgerIntegration.QuickBooks_Online) {
		return <QuickBooksOnlineLedgerAccountDetailView account={account} />;
	}
	if(account.integration === LedgerIntegration.None){
		return <DispatchProLedgerAccountDetailView account={account} />;
	}
	

	return (
		<NotFound
			message={"Cannot recognize account type"}
			action={{
				prompt: "Back To Ledger Accounts",
				route: routes.business.generalLedgerAccountsPage,
			}}
		/>
	);
}



