import { Card, TableHead, TableRow, TableCell, TableBody, Typography, TablePagination, Grid, Table } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../components/BackButton";
import { LinkTableRow } from "../../../components/LinkTableRow";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { Price } from "../../../components/Price";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { CheckBatchList, CheckBatchService } from "../../../services/accounting/CheckBatchService";

export function CheckBatchesPage() {
	const alert = useAlert();
	const [checkBatchList, setCheckBatchList] = React.useState<CheckBatchList>();
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const pagedCheckBatches = React.useMemo(() => checkBatchList?.batches.slice(page * pageSize, page * pageSize + pageSize) ?? [], [checkBatchList, page, pageSize]);

	const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
	};

	React.useEffect(() => {
		async function loadCheckBatchList() {
			const result = await CheckBatchService.getAll();
			if (result.success) {
				setCheckBatchList(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadCheckBatchList();
	}, [alert]);

	if (!checkBatchList) return <OverlayLoadingScreen />;

	return (
		<>
			<PageTitle title="Check Batches" />
			<Grid container>
				<Grid item>
					<BackButton />
				</Grid>
				<Grid item>
					<Typography variant="h2" gutterBottom>
						Check Batches
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item lg={6} md={8} sm={10} xs={12}>
							<Card>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Batch Number</TableCell>
											<TableCell>Number Of Checks</TableCell>
											<TableCell>Amount</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{checkBatchList.batches.length === 0 && (
											<TableRow>
												<TableCell colSpan={4} style={{ textAlign: "center" }}>
													No check batches found.
												</TableCell>
											</TableRow>
										)}
										{pagedCheckBatches.map((checkBatch) => (
											<LinkTableRow to={routes.app.resolve.checkBatchDetailPage(checkBatch.id)} key={checkBatch.batchNumber}>
												<TableCell>{checkBatch.created.toLocaleDateString()}<br/>{checkBatch.created.toLocaleTimeString()}</TableCell>
												<TableCell>{checkBatch.batchNumber}</TableCell>
												<TableCell>{checkBatch.checkCount}</TableCell>
												<TableCell>
													<Price value={checkBatch.totalAmount} />
												</TableCell>
											</LinkTableRow>
										))}
										<TableRow>
											<TablePagination
												rowsPerPageOptions={[5, 10, 15, 20]}
												colSpan={4}
												count={checkBatchList.batches.length}
												rowsPerPage={pageSize}
												labelRowsPerPage={"Show"}
												page={page}
												onChangePage={handleChangePage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
											/>
										</TableRow>
									</TableBody>
								</Table>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
