import React, { useMemo } from "react";
import { useTicketDetail } from "./DriverTicketDetailView";
import { TicketProduct, TicketQuoteType, TicketTankFill } from "../../../entities/routing/DriverTicket";
import { Box, Button, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { TankFillQuoteItem } from "./TankFillQuoteItem";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { ProductLine } from "../../../entities/products/ProductLine";
import { ProductListing } from "../../../entities/products/ProductListing";
import { ProductCategoryType } from "../../../entities/products/ProductCategoryType";
import { Autocomplete } from "@material-ui/lab";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useAlert } from "../../../hooks/useAlert";
import { ProductQuoteItem } from "./ProductQuoteItem";
import { Price } from "../../../components/Price";
import { AppliedPriceLock, applyPriceLock } from "../../../entities/customer/CustomerContract";
import { Money } from "../../../utility/Money";

export function TicketQuoteDetails() {
	const { ticket, disabled } = useTicketDetail();
	const { priceLocks, quoteItems } = ticket;
	const isComplete = ticket.timeOfCompletion !== null;

	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down(450));

	const appliedPriceLocks = useMemo(() => quoteItems.some(i => i.contractId != null) ? [] : priceLocks.map((pl) => applyPriceLock(pl, quoteItems)), [priceLocks, quoteItems]);

	const [addQuoteItem, setAddQuoteItem] = React.useState(false);

	const tankFills = ticket.quoteItems.filter((item) => item.type === TicketQuoteType.TankFill) as TicketTankFill[];
	const products = ticket.quoteItems.filter((item) => item.type === TicketQuoteType.Product) as TicketProduct[];

	return (
		<Box>
			<Typography variant="h6">Quote:</Typography>
			<QuoteTable tankFills={tankFills} products={products} isXs={isXs} appliedPriceLocks={appliedPriceLocks}  />
			<QuoteTotal appliedPriceLocks={appliedPriceLocks}  />
			{!addQuoteItem && !isComplete && (
				<Button color="primary" variant="outlined" disabled={disabled} style={{ marginTop: 20 }} onClick={() => setAddQuoteItem(true)}>
					Add To Quote
				</Button>
			)}
			{addQuoteItem && <AddQuoteItem ticketProducts={products} onCompleted={() => setAddQuoteItem(false)} />}
		</Box>
	);
}

function AppliedContracts(props: { appliedPriceLocks: AppliedPriceLock[]; isXs: boolean }) {
	const { appliedPriceLocks, isXs } = props;

	if ( appliedPriceLocks.length === 0) return null;

	const colSpan = !isXs ? 5 : 4;

	return (
		<>
			<TableRow>
				<TableCell colSpan={colSpan}>
					<Typography variant="h6">Applied Contracts:</Typography>
				</TableCell>
			</TableRow>
			{appliedPriceLocks.map((apl, i) => (
				<React.Fragment key={i}>
					<TableRow>
						<TableCell colSpan={2}>
							<Typography variant="subtitle1">{apl.priceLock.priceCeiling ? "Price Ceiling:" : "Price Lock:"}</Typography>
						</TableCell>
						<TableCell colSpan={colSpan - 2}>
							{apl.quantityRemaining != null && (
								<Typography variant="subtitle1" color="textSecondary" align="right">
									{apl.quantityRemaining} of {apl.priceLock.quantityRemaining} remaining
								</Typography>
							)}
						</TableCell>
					</TableRow>
					{apl.products.map((p, i) => (
						<TableRow key={i}>
							<TableCell>{p.name}</TableCell>
							{isXs && (
								<TableCell align="right">
									{p.isEstimate ? "~" : ""}
									{p.contractQuantity === 0 ? "Pending" : p.contractQuantity} @ <Price value={p.contractPrice} />
								</TableCell>
							)}
							{!isXs && (
								<>
									<TableCell align="right">
										{p.isEstimate ? "~" : ""}
										{p.contractQuantity === 0 ? "Pending" : p.contractQuantity}
									</TableCell>
									<TableCell align="right">
										<Price value={p.contractPrice} />
									</TableCell>
								</>
							)}
							<TableCell align="right">
								{p.isEstimate ? "~" : ""}
								{!p.priceDifference.isZero() && p.priceDifference.isNegative() && (
									<>
										{" -"}
										<Price value={p.priceDifference.multiply(-1)} />
									</>
								)}
								{!p.priceDifference.isZero() && p.priceDifference.isPositive() && <Price value={p.priceDifference} />}
							</TableCell>
							<TableCell align="right">
								{p.isEstimate ? "~" : ""}
								{!p.taxDifference.isZero() && p.taxDifference.isNegative() && (
									<>
										{" -"}
										<Price value={p.taxDifference.multiply(-1)} />
									</>
								)}
								{!p.taxDifference.isZero() && p.taxDifference.isPositive() && <Price value={p.taxDifference} />}
							</TableCell>
						</TableRow>
					))}
				</React.Fragment>
			))}
		</>
	);
}

function AddQuoteItem(props: { ticketProducts: TicketProduct[]; onCompleted: () => void }) {
	const { ticketProducts } = props;
	const alert = useAlert();

	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const { productCatalog } = useProductCatalog();

	const [selectedProductLine, setSelectedProductLine] = React.useState<ProductLine | null>(null);
	const [selectedProductListing, setSelectedProductListing] = React.useState<ProductListing | null>(null);

	const selectableProductLines = productCatalog.productLines.filter((l) => l.type !== ProductCategoryType.Propane);
	const selectableProductListings = selectedProductLine?.availableListings.filter((l) => !ticketProducts.some((x) => x.productListingId === l.id)) ?? [];

	const onSelectProductLine = (event: React.ChangeEvent<{ value: unknown }>) => {
		const productLineId = event.target.value as string;
		const productLine = productCatalog.productLines.find((x) => x.id === productLineId);
		setSelectedProductLine(productLine ?? null);
		setSelectedProductListing(null);
	};

	const onSelectProductListing = (_event: React.ChangeEvent<{}>, value: ProductListing | null) => {
		setSelectedProductListing(value);
	};

	const addQuoteItemToTicket = async () => {
		if (selectedProductListing == null) return;

		setDisabled(true);
		const result = await DriverTicketService.addQuoteProduct({ driverTicketId: ticket.id, productListingId: selectedProductListing.id });
		setDisabled(false);

		if (result.success) {
			alert.success("Quote item added");
			updateTicket(result.data);
			props.onCompleted();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Box mt={2}>
			<Grid container spacing={2} alignItems="center">
				<Grid item>
					<TextField
						label="Product Line"
						select
						variant="outlined"
						disabled={disabled}
						style={{ minWidth: 200 }}
						value={selectedProductLine?.id ?? "None"}
						onChange={onSelectProductLine}
					>
						<MenuItem value="None">Select Product Line</MenuItem>
						{selectableProductLines.map((productLine) => (
							<MenuItem key={productLine.id} value={productLine.id}>
								{productLine.name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item>
					{selectedProductLine == null && (
						<TextField label="Product" select variant="outlined" style={{ minWidth: 250 }} disabled>
							<MenuItem value="None">Select Product</MenuItem>
						</TextField>
					)}
					{selectedProductLine != null && (
						<Autocomplete
							options={selectableProductListings}
							filterOptions={(options, { inputValue }) =>
								options.filter(
									(option) =>
										option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
										(option.productCode ?? "").toLowerCase().includes(inputValue.toLowerCase())
								)
							}
							getOptionLabel={(option) => option.name}
							disabled={disabled}
							value={selectedProductListing}
							onChange={onSelectProductListing}
							renderInput={(params) => <TextField {...params} label="Product Line" variant="outlined" style={{ minWidth: 250 }} />}
							renderOption={(option) => (
								<div>
									<Typography variant="body1">{option.name}</Typography>
									{option.productCode && (
										<Typography variant="overline" color="textSecondary">
											{option.productCode}
										</Typography>
									)}
								</div>
							)}
						/>
					)}
				</Grid>
				<Grid item>
					<Button size="large" color="primary" variant="contained" disabled={selectedProductListing == null || disabled} onClick={addQuoteItemToTicket}>
						Add
					</Button>
				</Grid>
				<Grid item>
					<Button size="large" color="secondary" variant="outlined" onClick={props.onCompleted} disabled={disabled}>
						Cancel
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

function QuoteTotal(props: { appliedPriceLocks: AppliedPriceLock[] }) {
	const { appliedPriceLocks } = props;
	const { ticket } = useTicketDetail();
	const { isEstimate } = ticket;

	const priceDifference = appliedPriceLocks.reduce((acc, apl) => acc.add(apl.priceDifference), Money.zero);
	const subtotal = ticket.subtotal ? ticket.subtotal.add(priceDifference) : null;

	const taxDifference = appliedPriceLocks.reduce((acc, apl) => acc.add(apl.taxDifference), Money.zero);
	const tax = ticket.tax ? ticket.tax.add(taxDifference) : null;

	const total = ticket.total ? ticket.total.add(priceDifference).add(taxDifference) : null;

	return (
		<Grid container spacing={2} justify="flex-end">
			<Grid item>
				<Grid container spacing={1}>
					<Grid item>
						<Typography variant="h6">Subtotal:</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6" color={subtotal == null ? "textSecondary" : undefined}>
							{subtotal == null ? (
								"Pending"
							) : (
								<>
									{isEstimate ? "~" : ""}
									<Price value={subtotal} />
								</>
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={1}>
					<Grid item>
						<Typography variant="h6">Tax:</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6" color={tax == null ? "textSecondary" : undefined}>
							{tax == null ? (
								"Pending"
							) : (
								<>
									{isEstimate ? "~" : ""}
									<Price value={tax} />
								</>
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={1}>
					<Grid item>
						<Typography variant="h6">{isEstimate && "Estimated "}Total:</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6" color={total == null ? "textSecondary" : undefined}>
							{total == null ? (
								"Pending"
							) : (
								<>
									{isEstimate ? "~" : ""}
									<Price value={total} />
								</>
							)}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

function QuoteTable(props: { tankFills: TicketTankFill[]; products: TicketProduct[];  appliedPriceLocks: AppliedPriceLock[]; isXs: boolean }) {
	const { tankFills, products } = props;

	if (tankFills.length === 0 && products.length === 0) {
		return (
			<Typography color="textSecondary" variant="body1">
				No quote items
			</Typography>
		);
	}

	return (
		<>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell style={{ paddingLeft: 0, paddingRight: 5 }}>Product</TableCell>
						<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
							{props.isXs ? "Qty" : "Quantity"}
						</TableCell>
						<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
							Price
						</TableCell>
						{!props.isXs && (
							<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
								Sale
							</TableCell>
						)}
						<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
							Tax
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tankFills.map((tankFill) => (
						<TankFillQuoteItem tankFill={tankFill} key={tankFill.id} isXs={props.isXs} multipleFills={tankFills.length > 1} />
					))}
					{products.map((product) => (
						<ProductQuoteItem ticketProduct={product} key={product.id} isXs={props.isXs} />
					))}
					<AppliedContracts isXs={props.isXs} appliedPriceLocks={props.appliedPriceLocks} />
				</TableBody>
			</Table>
		</>
	);
}
