export interface OptionalValue<T>{
    value: T
}

type NonUndefined<T> = T extends undefined ? never : T;

export function toOptional<T>(value: T): OptionalValue<NonUndefined<T>> | undefined {
    if(value === undefined) return undefined;
    return {
        value: value as NonUndefined<T>
    }
} 