import React, { useEffect, useMemo } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	List,
	ListItem,
	ListItemText,
	Typography,
	TextField,
	MenuItem,
} from "@material-ui/core";
import { DriverTicket, getTicketLetter } from "../../../entities/routing/DriverTicket";
import { DriverRouteOptimization, DriverTicketService, OptimizeRouteRequest, Waypoint } from "../../../services/routing/DriverTicketService";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { GeocodedAddress, formatAddress } from "../../../entities/customer/GeocodedAddress";
import { useAlert } from "../../../hooks/useAlert";
import { useOfflineTickets } from "../../../providers/OfflineTicketProvider";
import { useTenant } from "../../../providers/TenantProvider";

interface OptimizeRouteDialogProps {
	open: boolean;
	driverId: string;
	stops: DriverTicket[];
	onClose: () => void;
}

type DialogState =
	| { name: "inactive" }
	| { name: "no office address" }
	| { name: "not enough stops" }
	| { name: "configure"; officeAddress: GeocodedAddress }
	| { name: "loading" }
	| { name: "accuracy warning"; request: OptimizeRouteRequest; stopsWithoutGeoCode: Waypoint[] }
	| { name: "error"; error: string }
	| { name: "ready"; result: DriverRouteOptimization };

interface DialogContext {
	driverId: string;
	state: DialogState;
	setDialogState: React.Dispatch<React.SetStateAction<DialogState>>;
	stops: DriverTicket[];
	closeDialog: () => void;
	selectRoute: (request: OptimizeRouteRequest) => void;
	optimize: (request: OptimizeRouteRequest) => void;
	changeOrder: (orderedDriverTicketIds: string[]) => void;
}

const DialogReactContext = React.createContext<DialogContext>({} as DialogContext);

function useDialogContext() {
	return React.useContext(DialogReactContext);
}

export function OptimizeRouteDialog(props: OptimizeRouteDialogProps) {
	const { open, driverId, stops } = props;

	const alert = useAlert();
	const openTicketContext = useOfflineTickets();

	const { tenant } = useTenant();
	const officeAddress = tenant.officeAddress;

	const [dialogState, setDialogState] = React.useState<DialogState>({ name: "inactive" });

	useEffect(() => {
		if (open && dialogState.name === "inactive") {
			if (!officeAddress) {
				setDialogState({ name: "no office address" });
			} else if (stops.length < 3) {
				setDialogState({ name: "not enough stops" });
			} else {
				setDialogState({ name: "configure", officeAddress });
			}
		}
	}, [open, dialogState.name, officeAddress, stops.length]);

	const closeDialog = () => {
		if (dialogState.name === "loading") return;

		props.onClose();
		setDialogState({ name: "inactive" });
	};

	const selectRoute = (request: OptimizeRouteRequest) => {
		const waypoints = [request.origin, ...request.stops, request.destination];
		const stopsWithoutGeoCode = waypoints.filter((s) => s.address.latitude === 0 && s.address.longitude === 0);
		if (stopsWithoutGeoCode.length > 0) {
			setDialogState({ name: "accuracy warning", request, stopsWithoutGeoCode });
		} else {
			optimize(request);
		}
	}

	const optimize = async (request: OptimizeRouteRequest) => {
		setDialogState({ name: "loading" });
		const result = await DriverTicketService.getRouteOptimization(request);
		if (result.success) {
			setDialogState({ name: "ready", result: result.data });
		} else if(result.validation) {
			setDialogState({ name: "error", error: result.errors.map(e => e.errors.join("; ")).join("; ") });
		} else {
			setDialogState({ name: "error", error: result.message });
		}
	};

	const changeOrder = async (orderedDriverTicketIds: string[]) => {
		setDialogState({ name: "loading" });
		const result = await DriverTicketService.updateRouteSequence({ driverId, orderedDriverTicketIds });
		if (result.success) {
			alert.success("Route optimized!");
			openTicketContext.updateMany(result.data);
			closeDialog();
		} else {
			setDialogState({ name: "error", error: result.message });
		}
	};

	return (
		<Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
			<DialogReactContext.Provider value={{ driverId, state: dialogState, setDialogState, stops, closeDialog, optimize, selectRoute, changeOrder }}>
				<DialogStateContent />
			</DialogReactContext.Provider>
		</Dialog>
	);
}

function DialogStateContent() {
	const { state } = useDialogContext();
	if (state.name === "inactive") {
		return null;
	}
	if (state.name === "no office address") {
		return <NoOfficeAddressDialogContent />;
	}
	if (state.name === "not enough stops") {
		return <NotEnoughStopsDialogContent />;
	}
	if (state.name === "loading") {
		return <LoadingDialogContent />;
	}
	if (state.name === "configure") {
		return <Configure officeAddress={state.officeAddress} />;
	}
	if (state.name === "accuracy warning") {
		return <AccuracyWarningDialogContent request={state.request} stopsWithoutGeoCode={state.stopsWithoutGeoCode} />;
	}
	if (state.name === "error") {
		return <ErrorDialogContent error={state.error} />;
	}
	if (state.name === "ready") {
		return state.result.canBeOptimized ? <OptimizationReadyDialogContent optimization={state.result} /> : <AlreadyOptimizedDialogContent />;
	}
	return <BadStateDialogContent />;
}

function getEndRouteOptions(waypoints: Waypoint[], start: Waypoint, officeAddress: GeocodedAddress) {
	const options = waypoints.filter((w) => w.stopNumber > start.stopNumber);
	// Cannot select stops more than 25 stops away
	if (options.length >= 26) {
		return options.slice(0, 26);
	}
	return options.concat({ address: officeAddress, description: "Office", ticketId: null, stopNumber: waypoints.length + 1 });
}

function Configure(props: { officeAddress: GeocodedAddress }) {
	const { officeAddress } = props;
	const context = useDialogContext();
	const { stops } = context;

	const waypoints = useMemo(
		() =>
			stops.map((s, i) => ({
				address: s.location,
				description: `(${getTicketLetter(i)}) ${formatAddress(s.location)}`,
				ticketId: s.id,
				stopNumber: i + 1,
			})),
		[stops]
	);
	const tooManyStops = waypoints.length > 25;

	const startOptions = useMemo(() => [{ address: officeAddress, description: "Office", ticketId: null, stopNumber: 0 }, ...waypoints], [officeAddress, waypoints]);

	const [start, setStart] = React.useState<Waypoint>(startOptions[0]);

	const endOptions = getEndRouteOptions(waypoints, start, officeAddress);

	const [end, setEnd] = React.useState<Waypoint>(endOptions[endOptions.length - 1]);

	useEffect(() => {
		if (start.stopNumber > end.stopNumber) {
			setEnd(start);
		}
	}, [start, end]);

	const selectStart = (ticketId: string) => {
		const newStart = ticketId === "Office" ? startOptions[0] : waypoints.find((w) => w.ticketId === ticketId);
		if (newStart) {
			const endOptions = getEndRouteOptions(waypoints, newStart, officeAddress);
			if (!endOptions.find((e) => e.ticketId === end.ticketId)) {
				setEnd(endOptions[endOptions.length - 1]);
			}
			setStart(newStart);
		}
	};

	const selectEnd = (ticketId: string) => {
		if (ticketId === "Office") {
			setEnd(endOptions[endOptions.length - 1]);
			return;
		}
		const waypoint = waypoints.find((w) => w.ticketId === ticketId);
		if (waypoint) {
			setEnd(waypoint);
		}
	};

	const onOptimize = () => {
		const origin = start;
		const stops = waypoints.filter((w) => w.stopNumber > start.stopNumber && w.stopNumber < end.stopNumber);
		const destination = end;

		const request: OptimizeRouteRequest = { driverId: context.driverId, origin, stops, destination };
		context.selectRoute(request);
	};

	return (
		<>
			<DialogTitle>Route Optimizer</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">Choose a start and end point for your route.</DialogContentText>
				{tooManyStops && (
					<DialogContentText color="textPrimary">
						Note: This route has more than 25 stops. The entire route cannot be optimized at once. Please optimize in smaller chunks.
					</DialogContentText>
				)}
				<TextField
					label="Starting Location"
					variant="outlined"
					margin="normal"
					fullWidth
					select
					value={start.ticketId ?? "Office"}
					onChange={(e) => selectStart(e.target.value)}
				>
					{startOptions.map((w, i) => (
						<MenuItem key={i} value={w.ticketId ?? "Office"}>
							{w.description}
						</MenuItem>
					))}
				</TextField>
				<TextField
					label="Ending Location"
					variant="outlined"
					margin="normal"
					fullWidth
					select
					value={end?.ticketId ?? "Office"}
					onChange={(e) => selectEnd(e.target.value)}
				>
					{endOptions.map((w, i) => (
						<MenuItem key={i} value={w.ticketId ?? "Office"}>
							{w.description}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Close
				</Button>
				<Button onClick={onOptimize} variant="contained" color="primary">
					Optimize
				</Button>
			</DialogActions>
		</>
	);
}

function NotEnoughStopsDialogContent() {
	const context = useDialogContext();
	return (
		<>
			<DialogTitle>Route Optimizer</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">You must have at least 3 stops to optimize your route.</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Close
				</Button>
			</DialogActions>
		</>
	);
}

function NoOfficeAddressDialogContent() {
	const context = useDialogContext();
	return (
		<>
			<DialogTitle>Route Optimizer</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">You must set an office address before you can optimize your route.</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Close
				</Button>
			</DialogActions>
		</>
	);
}

function OptimizationReadyDialogContent(props: { optimization: DriverRouteOptimization }) {
	const { optimization } = props;
	const context = useDialogContext();
	return (
		<>
			<DialogTitle>Faster Route Found!</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">
					Distance Saved: {optimization.distanceSaved} | Driving Time Saved: {optimization.timeSaved}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Close
				</Button>
				<Button onClick={() => context.changeOrder(optimization.proposedTicketOrder)} variant="contained" color="primary">
					Optimize
				</Button>
			</DialogActions>
		</>
	);
}

const praise = [
	"You are a true route ninja 🥷",
	"You are a route master 🔥",
	"You are a route wizard 🧙‍♂️",
	"You are a route genius 🧠",
	"You are a route legend 🚀",
	"You are a route prodigy 👨‍🎓",
	"You are a route virtuoso 🧑‍🎨",
	"You are a route whiz 🤖",
	"You are a route guru 🧘‍♂️",
	"You are a route champion 🏆",
	"You are a route hero 🦸‍♂️",
	"You are a route expert 🧑‍🔬",
	"You are a route aficionado 🤓",
	"You are a route connoisseur 🍷",
	"You are a route maestro 🎻",
	"You are a routing pro 🏌️‍♂️",
	"You are a route shark 🦈",
	"You are a star router ⭐️",
	"You are a route legend 🏅",
	"You are a route boss 👑"
];
function AlreadyOptimizedDialogContent() {
	const context = useDialogContext();
	// Praise with emojis
	const randomPraise = useMemo(() => praise[Math.floor(Math.random() * praise.length)], []);
	return (
		<>
			<DialogTitle>Route Already Optimized</DialogTitle>
			<Typography color="textPrimary" align="center" variant="h5">
				{randomPraise}
			</Typography>

			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Close
				</Button>
			</DialogActions>
		</>
	);
}

function LoadingDialogContent() {
	return (
		<>
			<DialogTitle>Optimizing Route</DialogTitle>
			<DialogContent>
				<CenteredLoadingSpinner size="5rem" />
			</DialogContent>
		</>
	);
}

function ErrorDialogContent(props: { error: string }) {
	const { error } = props;
	const context = useDialogContext();
	return (
		<>
			<DialogTitle>Error Optimizing Route</DialogTitle>
			<DialogContent>
				<DialogContentText>{error}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Close
				</Button>
			</DialogActions>
		</>
	);
}

function AccuracyWarningDialogContent(props: { request: OptimizeRouteRequest; stopsWithoutGeoCode: Waypoint[] }) {
	const { request, stopsWithoutGeoCode } = props;

	const context = useDialogContext();
	return (
		<>
			<DialogTitle>Optimizing Route</DialogTitle>
			<DialogContent>
				<DialogContentText>The following stops are not geocoded yet:</DialogContentText>
				<List>
					{stopsWithoutGeoCode.map((s, i) => (
						<ListItem key={i}>
							<ListItemText primary={s.description} />
						</ListItem>
					))}
				</List>
				<DialogContentText>We can still attempt optimization but it may be less accurate</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button onClick={() => context.optimize(request)} variant="contained" color="primary">
					Continue
				</Button>
			</DialogActions>
		</>
	);
}

function BadStateDialogContent() {
	const context = useDialogContext();
	return (
		<>
			<DialogTitle>Error Optimizing Route</DialogTitle>
			<DialogContent>
				<DialogContentText>Something unexpected happened.</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={context.closeDialog} variant="outlined" color="secondary">
					Close
				</Button>
			</DialogActions>
		</>
	);
}
