import { deserializeProductCatalog } from "../../entities/products/ProductCatalog";
import { deserializeProductLine } from "../../entities/products/ProductLine";
import { deserializeProductListing } from "../../entities/products/ProductListing";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface AddProductLineRequest {
	name: string;
}

interface AddProductListingRequest {
	productLineId: string;
	name: string;
	price: number;
	unitName: string | null;
	productCode: string | null;
	revenueAccountId: number;
}

interface UpdateProductListingRequest {
	id: string;
	name?: string;
	price?: number;
	unitName?: string | null;
	productCode?: string | null;
	revenueAccountId?: number;
	disabled?: boolean;
}

type SerializedUpdateProductListingRequest = Omit<UpdateProductListingRequest, "unitName" | "productCode"> & {
	unitName?: OptionalValue<string | null>;
	productCode?: OptionalValue<string | null>;
};
const serializeUpdateProductListingRequest = (request: UpdateProductListingRequest): SerializedUpdateProductListingRequest => ({
	...request,
	unitName: toOptional(request.unitName),
	productCode: toOptional(request.productCode),
});

export const ProductCatalogService = {
	get: () => WebClient.Get(`${apiServer}/api/product-catalog`, deserializeProductCatalog),
	getListing: (id: string) => WebClient.GetOptional(`${apiServer}/api/product-catalog/listing/${id}`, deserializeProductListing),
	addProductLine: (request: AddProductLineRequest) => WebClient.Post.Validated(`${apiServer}/api/product-catalog/product-line`, request, deserializeProductLine),
	addProductListing: (request: AddProductListingRequest) => WebClient.Post.Validated(`${apiServer}/api/product-catalog/product-listing`, request, deserializeProductListing),
	updateProductListing: (request: UpdateProductListingRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/product-catalog/product-listing`, serializeUpdateProductListingRequest(request), deserializeProductListing),
};
