import { Box, TableCell, TableRow, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { ButtonLink } from "../../../components/ButtonLink";
import { NumberTextField } from "../../../components/NumberTextField";
import { PriceTextField } from "../../../components/PriceTextField";
import { Price } from "../../../components/Price";
import { NewTicketTankFill } from "./TicketTankFill";
import { EditTicketTaxesDialog } from "./EditTicketTaxesDialog";
import { BoxIconButton } from "../../../components/BoxIconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useCreateTicketContext } from "./NewCreateTicketPage";
import { Money } from "../../../utility/Money";

export function TankFillQuoteItem(props: { tankFill: NewTicketTankFill; showSubtotal: boolean }) {
	const { tankFill } = props;

	const context = useCreateTicketContext();
	const { tankFills, setTankFills } = context;

	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down(450));

	const onDelete = () => setTankFills((tf) => tf.filter((t) => t.tank.id !== tankFill.tank.id));

	return (
		<TableRow>
			<TableCell style={{ paddingLeft: 0, paddingRight: 5 }}>
				<Box display="flex" alignItems={isXs ? "flex-start" : "center"} flexDirection={isXs ? "column-reverse" : "row"}>
					<Box display="flex" width={isXs ? "100%" : undefined} marginRight={isXs ? 0 : 1}>
						<BoxIconButton color="secondary" onClick={onDelete} style={{ marginTop: isXs ? 6 : 0 }}>
							<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
						</BoxIconButton>
					</Box>
					<Box overflow="hidden" whiteSpace={isXs ? "nowrap" : undefined} textOverflow="ellipsis" maxWidth={isXs ? 165 : undefined} alignSelf="flex-start">
						<Typography variant="body1" noWrap={isXs ? true : undefined}>
						{tankFill.listing.name}
						</Typography>
						{tankFills.length > 1 && (
							<Typography variant="caption" color="textSecondary" noWrap={isXs ? true : undefined}>
								{tankFill.tank.gallons} gal {tankFill.tank.description ?? ""}
							</Typography>
						)}
					</Box>
				</Box>
			</TableCell>
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ItemQuantity tankFill={tankFill} />
				{tankFill.estimatedUllage != null && (
					<>
						<br />
						<Typography variant="overline" color="textSecondary">
							~{tankFill.estimatedUllage.toFixed(2)}
						</Typography>
					</>
				)}
			</TableCell>
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ItemPrice tankFill={tankFill} />
			</TableCell>
			{props.showSubtotal && (
				<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
					<ItemSubtotal tankFill={tankFill} />
				</TableCell>
			)}
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ItemTax tankFill={tankFill} />
			</TableCell>
		</TableRow>
	);
}

function ItemSubtotal(props: { tankFill: NewTicketTankFill }) {
	const { tankFill } = props;
	if (tankFill.subtotal === null) {
		return <>Pending</>;
	}
	return (
		<>
			{tankFill.isEstimate ? "~" : ""}
			<Price value={tankFill.subtotal} />
		</>
	);
}

function ItemQuantity(props: { tankFill: NewTicketTankFill }) {
	const { tankFill } = props;

	const context = useCreateTicketContext();
	const { setTankFills } = context;

	const [editQuantity, setEditQuantity] = React.useState(false);
	const [quantity, setQuantity] = React.useState(tankFill.quantity);

	const onQuantitySubmit = () => {
		setTankFills((tf) => tf.map((t) => (t.tank.id === tankFill.tank.id ? t.copy({ quantity }) : t)));
		setEditQuantity(false);
	};

	const onQuantityEscape = () => {
		setEditQuantity(false);
		setQuantity(tankFill.quantity);
	};

	if (editQuantity) {
		return (
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onQuantitySubmit();
				}}
			>
				<NumberTextField
					size="small"
					variant="outlined"
					value={quantity}
					onNumberChange={setQuantity}
					autoFocus
					onBlur={onQuantitySubmit}
					onKeyDown={(e) => {
						if (e.key === "Escape") {
							e.stopPropagation();
							onQuantityEscape();
						}
					}}
				/>
			</form>
		);
	}
	if (tankFill.quantity) {
		return <ButtonLink onClick={() => setEditQuantity(true)}>{tankFill.quantity}</ButtonLink>;
	}
	return <ButtonLink onClick={() => setEditQuantity(true)}>Fill Up</ButtonLink>;
}

function ItemPrice(props: { tankFill: NewTicketTankFill }) {
	const { tankFill } = props;

	const context = useCreateTicketContext();
	const { setTankFills } = context;

	const [editPrice, setEditPrice] = React.useState(false);
	const [priceValue, setPriceValue] = React.useState(tankFill.priceValue);

	const onPriceSubmit = () => {
		setTankFills((tf) => tf.map((t) => (t.tank.id === tankFill.tank.id ? t.copy({ priceValue }) : t)));
		setEditPrice(false);
	};

	const onPriceEscape = () => {
		setEditPrice(false);
		setPriceValue(tankFill.priceValue);
	};

	if (editPrice) {
		return (
			<div tabIndex={0} onBlur={onPriceSubmit}>
				<PriceTextField
					size="small"
					variant="outlined"
					value={priceValue}
					onPriceChanged={setPriceValue}
					autoFocus
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onPriceSubmit();
						}
						if (e.key === "Escape") {
							e.stopPropagation();
							onPriceEscape();
						}
					}}
				/>
			</div>
		);
	}
	if (tankFill.price) {
		return (
			<ButtonLink onClick={() => setEditPrice(true)}>
				<Price value={tankFill.price} decimalOptional doNotRound />
			</ButtonLink>
		);
	}
	return <ButtonLink onClick={() => setEditPrice(true)}><Price value={Money.zero} decimalOptional doNotRound /></ButtonLink>;
}

function ItemTax(props: { tankFill: NewTicketTankFill }) {
	const { tankFill } = props;

	const context = useCreateTicketContext();
	const { setTankFills } = context;

	const [editTaxes, setEditTaxes] = React.useState(false);

	return (
		<>
			{tankFill.tax ? (
				<ButtonLink onClick={() => setEditTaxes(true)}>
					{tankFill.isEstimate ? "~" : ""}
					<Price value={tankFill.tax} />
				</ButtonLink>
			) : (
				<ButtonLink onClick={() => setEditTaxes(true)}>TBD</ButtonLink>
			)}
			<EditTicketTaxesDialog
				taxRules={tankFill.taxRules}
				subtotal={tankFill.subtotal}
				exemption={tankFill.taxExemption}
				onChange={(taxRules) => setTankFills((tf) => tf.map((t) => (t.tank.id === tankFill.tank.id ? t.copy({ taxRules }) : t)))}
				open={editTaxes}
				onClose={() => setEditTaxes(false)}
			/>
		</>
	);
}
