import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { RedButton } from "../../../components/RedButton";
import { SearchTextField } from "../../../components/SearchTextField";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { SearchableCustomer, CustomerService } from "../../../services/customer/CustomerService";
import { CustomerTank, PropaneTankService } from "../../../services/customer/PropaneTankService";
import { TankMonitorService } from "../../../services/routing/TankMonitorService";
import { useOtodataDeviceDetailPage } from "../OtodataDeviceDetailPage";
import PersonIcon from "@material-ui/icons/Person";


export function AssignedCustomerCard() {
	const context = useOtodataDeviceDetailPage();
	const { dispatchProMonitor } = context;
	const history = useHistory();
	const alert = useAlert();

	const [showDialog, setShowDialog] = React.useState(false);
	const [disabled, setDisabled] = React.useState(false);

	const unassignTank = async (tankMonitorId: string) => {
		setDisabled(true);
		const result = await TankMonitorService.unassignOtodataDispatchProTankMonitor(tankMonitorId);
		if (result.success) {
			alert.success("Tank unassigned successfully");
			context.updateDispatchProMonitor(null);
		} else if (result.validation) {
			alert.validation(result);
		} 
		else {
			alert.serverError(result);
		}
		setDisabled(false);
	}

	return (
		<Card>
			<CardHeader action={<PersonIcon fontSize={"large"} />} title="Customer" style={{ paddingBottom: 0 }} />
			{!dispatchProMonitor && (
				<>
					<CardContent>
						<Typography variant="body1" align="center" color="textSecondary">
							No customer tank assigned
						</Typography>
					</CardContent>
					<Button fullWidth variant="contained" color="primary" onClick={() => setShowDialog(true)}>
						Assign Tank
					</Button>
					<CustomerTankLookupDialog open={showDialog} onClose={() => setShowDialog(false)} />
				</>
			)}
			{dispatchProMonitor && (
				<>
					<List>
						<ListItem button onClick={() => history.push(routes.app.resolve.customerDetailPage(dispatchProMonitor.customerId))} disabled={disabled}>
							<ListItemText primary={dispatchProMonitor.customerName} />
						</ListItem>
						<ListItem button onClick={() => history.push(routes.app.resolve.propaneTankDetailPage(dispatchProMonitor.tankId))} disabled={disabled}>
							<ListItemText
								primary="Tank"
								secondary={dispatchProMonitor.tankLocation ?? dispatchProMonitor.tankSerialNumber ?? `${dispatchProMonitor.gallonCapacity} gal`}
							/>
						</ListItem>
					</List>
					<RedButton fullWidth variant="outlined"  onClick={() => unassignTank(dispatchProMonitor.id)} disabled={disabled}>
						Unassign Tank
					</RedButton>
				</>
			)}
		</Card>
	);
}

function filterCustomers(customers: SearchableCustomer[], searchText: string): SearchableCustomer[] {
	if (searchText.trim() === "") {
		return customers;
	}
	const query = searchText.toLowerCase();

	return customers.filter((c) => {
		return (
			c.name.toLowerCase().includes(query) ||
			c.customerCode.toLowerCase().includes(query) ||
			c.address.street.toLowerCase().includes(query) ||
			c.address.city.toLowerCase().includes(query) ||
			c.address.postalCode.toLowerCase().includes(query)
		);
	});
}

function CustomerTankLookupDialog(props: { open: boolean; onClose: () => void }) {
	const [selectedCustomer, setSelectedCustomer] = React.useState<SearchableCustomer | null>(null);

	const onClose = () => {
		props.onClose();
		setTimeout(() => {
			setSelectedCustomer(null);
		}, 500);
	};

	return (
		<Dialog open={props.open} onClose={onClose} fullWidth maxWidth="sm">
			{!selectedCustomer && <SearchCustomerDialogView onSelect={(customer) => setSelectedCustomer(customer)} />}
			{selectedCustomer && <SelectTankDialogView customer={selectedCustomer} onCanceled={() => setSelectedCustomer(null)} onAssigned={onClose} />}
		</Dialog>
	);
}

function SelectTankDialogView(props: { customer: SearchableCustomer; onCanceled: () => void; onAssigned: () => void }) {
	const { customer } = props;
	const customerId = customer.customerId;

	const alert = useAlert();
	const context = useOtodataDeviceDetailPage();
	const otodataDeviceId = context.device.id;

	const [customerTanks, setCustomerTanks] = React.useState<CustomerTank[]>();
	const [disabled, setDisabled] = React.useState(false);

	useEffect(() => {
		async function fetch() {
			const result = await PropaneTankService.getCustomerTanks(customerId);
			if (result.success) {
				setCustomerTanks(result.data);
			} else {
				alert.serverError(result);
			}
		}
		fetch();
	}, [customerId, alert]);

	const assignTank = async (tank: CustomerTank) => {
		setDisabled(true);
		const result = await TankMonitorService.createOtodataDispatchProTankMonitor({ tankId: tank.id, otodataDeviceId });
		if (result.success) {
			alert.success("Tank assigned successfully");
			context.updateDispatchProMonitor(result.data);
			props.onAssigned();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
		setDisabled(false);
	};

	if (!customerTanks) {
		return <CenteredLoadingSpinner />;
	}

	return (
		<>
			<DialogTitle>Select Tank from {customer.name}</DialogTitle>
			<DialogContent>
				<List>
					{customerTanks.map((tank) => (
						<ListItem key={tank.id} button onClick={() => assignTank(tank)} disabled={disabled}>
							<ListItemText primary={tank.description} secondary={`${tank.gallons} gal`} />
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCanceled} color="primary" variant="outlined" disabled={disabled}>
					Back to Search
				</Button>
			</DialogActions>
		</>
	);
}

function SearchCustomerDialogView(props: { onSelect: (customer: SearchableCustomer) => void }) {
	const { device } = useOtodataDeviceDetailPage();

	const [searchText, setSearchText] = React.useState("");
	const [customers, setCustomers] = React.useState<SearchableCustomer[]>([]);
	const [bestGuess, setBestGuess] = React.useState<SearchableCustomer | null>(null);

	const filteredCustomers = useMemo(() => filterCustomers(customers, searchText), [customers, searchText]);
	const firstFiveCustomers = filteredCustomers.slice(0, 5);

	useEffect(() => {
		async function fetch() {
			const result = await CustomerService.search({ excludeInactive: true });
			if (result.success) {
				setCustomers(result.data);
			}
		}
		fetch();
	}, []);

	useEffect(() => {
		let customerGuessList = customers;
		const { address, city, postalCode } = device;

		if (address && address.trim() !== "") {
			const streetNumber = address.split(" ")[0];
			customerGuessList = customerGuessList.filter((c) => c.address.street.startsWith(streetNumber));
		}

		if (customerGuessList.length > 1 && city && city.trim() !== "") {
			customerGuessList = customerGuessList.filter((c) => c.address.city.includes(city.trim()));
		}

		if (customerGuessList.length > 1 && postalCode && postalCode.trim() !== "") {
			customerGuessList = customerGuessList.filter((c) => c.address.postalCode.startsWith(postalCode.trim()));
		}

		if (customerGuessList.length === 1) {
			setBestGuess(customerGuessList[0]);
		} else {
			setBestGuess(null);
		}
	}, [device, customers]);

	return (
		<>
			<DialogTitle>Assign Tank</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<SearchTextField value={searchText} onChange={setSearchText} />
					</Grid>
					<Grid item xs={12}>
						<Box minHeight={400}>
							{bestGuess && !searchText && (
								<>
									<Typography>Our best guess based on monitor info </Typography>
									<List>
										<ListItem button onClick={() => props.onSelect(bestGuess)}>
											<ListItemText primary={bestGuess.name} secondary={bestGuess.address.street} />
										</ListItem>
									</List>
								</>
							)}
							{!bestGuess && !searchText && <Typography>Search for a customer</Typography>}
							{searchText && (
								<>
									{filteredCustomers.length === 0 && <Typography>No customers found</Typography>}
									{filteredCustomers.length > 0 && (
										<>
											<Typography>
												Search results (Showing {firstFiveCustomers.length} of {filteredCustomers.length}){" "}
											</Typography>
											<List>
												{firstFiveCustomers.map((c) => (
													<ListItem button onClick={() => props.onSelect(c)} key={c.customerId}>
														<ListItemText primary={c.name} secondary={c.address.street} />
													</ListItem>
												))}
											</List>
										</>
									)}
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</>
	);
}
