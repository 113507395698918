import { TaxExemption } from "../../../entities/accounting/TaxExemption";
import { TaxRule, calculateTaxes } from "../../../entities/accounting/TaxRule";
import { ProductListing } from "../../../entities/products/ProductListing";
import { CreateTicketProductRequest } from "../../../services/routing/DriverTicketService";
import { Money, SafeMoney } from "../../../utility/Money";

export class NewTicketProduct {
    readonly name: string;
	readonly quantity: number | null;
	readonly priceValue: number | null;
	readonly listing: ProductListing;
	readonly taxRules: TaxRule[];
    readonly taxExemption: TaxExemption | null;

    readonly price: SafeMoney | null;
    readonly subtotal: SafeMoney | null;
    readonly tax: SafeMoney | null;

    constructor(
        listing: ProductListing,
        taxRules: TaxRule[],
        taxExemption: TaxExemption | null,
        name: string,
        quantity: number | null,
        price: number | null
    ) {
        this.listing = listing;
        this.taxRules = taxRules;
        this.taxExemption = taxExemption;
        this.name = name;
        this.quantity = quantity;
        this.priceValue = price;
        this.price = price ? Money.fromDecimal(price, 3) : null;

        this.subtotal = this.quantity == null || this.price == null ? null : this.price.multiply(this.quantity);
        this.tax = this.subtotal === null ? null : calculateTaxes(this.taxRules, this.subtotal);
    }

    static create(listing: ProductListing, taxRules: TaxRule[], taxExemption: TaxExemption | null): NewTicketProduct {
        return new NewTicketProduct(listing, taxRules, taxExemption, listing.name, 1, listing.price);
    }

    copy(props: Partial<NewTicketProduct>): NewTicketProduct {
        return new NewTicketProduct(
            props.listing === undefined ? this.listing : props.listing,
            props.taxRules === undefined ? this.taxRules : props.taxRules,
            props.taxExemption === undefined ? this.taxExemption : props.taxExemption,
            props.name === undefined ? this.name : props.name,
            props.quantity === undefined ? this.quantity : props.quantity,
            props.priceValue === undefined ? this.priceValue : props.priceValue
        );
    }

    toTicketProductRequest(): CreateTicketProductRequest {
		return {
            productListingId: this.listing.id,
            productName: this.name,
            quantity: this.quantity,
            price: this.priceValue ?? 0,
            taxRules: this.taxRules.map(taxRule => ({
                taxRuleId: taxRule.id,
                taxAccountId: taxRule.taxAccountId,
                taxAccountName: taxRule.taxAccountName,
                name: taxRule.name,
                ratePercent: taxRule.ratePercent,
                fixedAmount: taxRule.fixedAmount
            }))
        };
	}
}