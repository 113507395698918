import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useCreateTicketContext } from "./NewCreateTicketPage";

export function AddInstructionsSection() {
	const { instructions, setInstructions } = useCreateTicketContext();

	const [editMode, setEditMode] = React.useState(false);

	const onUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInstructions(value.trim() === "" ? null : value);
	}

	if (editMode) {
		return (
			<TextField
				style={{ marginTop: 5 }}
				size="small"
				label="Instructions"
				variant="outlined"
				fullWidth
				autoFocus
				multiline
				rows={3}
				helperText="Add instructions for the driver"
				value={instructions}
				onChange={onUpdate}
				onFocus={(e) => e.target.selectionStart = e.target.selectionEnd = e.target.value.length}
				onBlur={() => setEditMode(false)}
			/>
		);
	}

	return (
		<Grid container alignItems="center">
			<Grid item>
				<Button color="primary" variant="text" style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }} onClick={() => setEditMode(true)}>
					Instructions:
				</Button>
			</Grid>

			{instructions === null || instructions.trim() === "" ? (
				<Grid item>
					<Typography variant="h6" color="textSecondary">
						None
					</Typography>
				</Grid>
			) : (
				<Grid item xs={12}>
					<Typography variant="body1" gutterBottom>
						{instructions}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
}
