import React from "react";
import PlaceIcon from "@material-ui/icons/Place";
import { create } from "zustand";
import { DriverTicketService, TicketAgeStats, TicketLocation } from "../../services/routing/DriverTicketService";
import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";
import { Theme } from "@material-ui/core";
import ReactDOM from "react-dom";
import { useRouteBuilderStore } from "./RouteBuilderStore";
import { ColorGradient } from "../../utility/ColorGradient";
import { GoogleMap } from "../../utility/GoogleMap";

export interface ColoredMapLegend {
	minValue: number;
	minColor: string;
	averageValue: number;
	averageColor: string;
	maxValue: number;
	maxColor: string;
}

export interface BuilderPageState {
	googleLibraries?: {
		mapLibrary: google.maps.MapsLibrary;
		markerLibrary: google.maps.MarkerLibrary;
		geometryLibrary: google.maps.GeometryLibrary;
		drawingLibrary: google.maps.DrawingLibrary;
	};
	drawingManager?: google.maps.drawing.DrawingManager;
	map?: google.maps.Map;
	initializeMap: (element: HTMLDivElement, officeAddress: GeocodedAddress | null) => void;
	unmountMap: () => void;
	setMapType: (type: "roadmap" | "satellite") => void;
	markers: google.maps.marker.AdvancedMarkerElement[];
	updateMarkers: (visibleTickets: TicketLocation[]) => void;
	visibleTickets: TicketLocation[];
	changeMapBounds: (bounds: google.maps.LatLngBounds) => void;
	selectionMode: "circle" | "rectangle" | "polygon" | "single";
	setSelectionMode: (shape: "circle" | "rectangle" | "polygon" | "single") => void;
	onShapeDrawn: (shape: google.maps.Polygon | google.maps.Rectangle | google.maps.Circle) => void;
	selectedTicketLocations: TicketLocation[];
	setSelectedTicketLocations: (tickets: TicketLocation[]) => void;
	clearSelection: () => void;
	toggleTicketLocation: (ticket: TicketLocation) => void;
	initializeStore: (theme: Theme) => Promise<void>;
	legend?: ColoredMapLegend;
	loaded: boolean;
}

export const useBuilderPageStore = create<BuilderPageState>()((set, get) => ({
	initializeMap: (element, office) =>
		set((state: BuilderPageState) => {
			const { googleLibraries, drawingManager, legend } = state;
			if (!googleLibraries || !drawingManager) {
				console.warn("Google libraries not ready");
				return {};
			}
			const map = initializeMap({ element, office, legend, mapLibrary: googleLibraries.mapLibrary, drawingManager });
			map.addListener("rightclick", () => {
				useBuilderPageStore.getState().setSelectionMode("single");
			});
			return { map };
		}),
	unmountMap: () => {
		const { map, markers } = get() as BuilderPageState;
		if (!map) {
			console.warn("Map not ready");
			return;
		}
		console.log("Unmounting map");
		// Remove all event listeners
		google.maps.event.clearInstanceListeners(map);
		markers.forEach((marker) => {
			marker.position = null;
			if (marker.content) ReactDOM.unmountComponentAtNode(marker.content);
		});

		set({ map: undefined, markers: [] });
	},
	setMapType: (type) => {
		const { map } = get();
		if (!map) {
			console.warn("Map not ready");
			return;
		}
		if (type === "satellite") {
			map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
		} else {
			map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
		}
	},
	markers: [],
	updateMarkers: (visibleTickets) =>
		set((state: BuilderPageState) => {
			const { map, markers, googleLibraries } = state;
			if (!map || !googleLibraries) {
				console.warn("Map or Google libraries not ready");
				return {};
			}
			return {
				markers: getUpdatedMarkers({
					visibleTickets,
					markers,
					map,
					markerLibrary: googleLibraries.markerLibrary,
				}),
			};
		}),
	visibleTickets: [],
	changeMapBounds: async (bounds) => {
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest();
		const result = await DriverTicketService.geoSearch({
			northEastLatitude: ne.lat(),
			northEastLongitude: ne.lng(),
			southWestLatitude: sw.lat(),
			southWestLongitude: sw.lng(),
		});
		if (result.success) {
			set({ visibleTickets: result.data });
			useBuilderPageStore.getState().updateMarkers(result.data);
		}
	},
	selectedTicketLocations: [],
	setSelectedTicketLocations: (tickets) => {
		set({ selectedTicketLocations: tickets });
		useRouteBuilderStore.getState().loadRoute(tickets.map((t) => t.ticketId));
	},
	clearSelection: () => get().setSelectedTicketLocations([]),
	toggleTicketLocation: (ticket) => {
		const { selectedTicketLocations } = get() as BuilderPageState;
		const updatedTicketSelection = selectedTicketLocations.some((t) => t.ticketId === ticket.ticketId)
			? selectedTicketLocations.filter((t) => t.ticketId !== ticket.ticketId)
			: [ticket, ...selectedTicketLocations];
		get().setSelectedTicketLocations(updatedTicketSelection);
	},
	initializeStore: async (theme: Theme) => {
		if (get().googleLibraries) return;
		const [mapLibrary, markerLibrary, geometryLibrary, drawingLibrary, ticketStatsResult] = await Promise.all([
			google.maps.importLibrary("maps") as Promise<google.maps.MapsLibrary>,
			google.maps.importLibrary("marker") as Promise<google.maps.MarkerLibrary>,
			google.maps.importLibrary("geometry") as Promise<google.maps.GeometryLibrary>,
			google.maps.importLibrary("drawing") as Promise<google.maps.DrawingLibrary>,
			DriverTicketService.getTicketAgeStats(),
		]);
		const drawingManager = createThemedDrawingManager(drawingLibrary, theme);
		drawingManager.addListener("overlaycomplete", handleOverlayComplete);

		set({
			googleLibraries: { mapLibrary, markerLibrary, geometryLibrary, drawingLibrary },
			drawingManager,
			legend: ticketStatsResult.success ? statsToColoredMapLegend(ticketStatsResult.data) : undefined,
			loaded: true,
		});
	},
	selectionMode: "single",
	setSelectionMode: (shape) => {
		const { drawingManager, map } = get();
		if (!drawingManager || !map) {
			console.warn("Drawing manager not ready");
			return;
		}

		switch (shape) {
			case "circle":
				drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
				break;
			case "rectangle":
				drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE);
				break;
			case "polygon":
				drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
				break;
			case "single":
				drawingManager.setDrawingMode(null);
				break;
			default:
				console.warn("Unsupported shape", shape);
		}
		drawingManager.setMap(map);
		// disable dragging the map when in drawing mode
		map.setOptions({ draggable: shape === "single" });

		set({ selectionMode: shape });
	},
	onShapeDrawn: (shape) => {
		const { visibleTickets, selectedTicketLocations, googleLibraries, drawingManager } = get() as BuilderPageState;
		if (!googleLibraries || !drawingManager) {
			console.warn("Google libraries not ready");
			return {};
		}

		const ticketsInShape = selectTicketsInShape({ shape, visibleTickets, geometryLibrary: googleLibraries.geometryLibrary });
		const selectedTicketIds = new Set(selectedTicketLocations.map((t) => t.ticketId));
		const newSelectedTickets = ticketsInShape.filter((t) => !selectedTicketIds.has(t.ticketId));
		// Remove shape
		shape.setMap(null);
		get().setSelectedTicketLocations([...newSelectedTickets, ...selectedTicketLocations]);
	},
	loaded: false,
}));

function statsToColoredMapLegend(stats: TicketAgeStats): ColoredMapLegend {
	const { quickestCompletionDays, averageCompletionDays, slowestCompletionDays } = stats;
	return {
		minValue: quickestCompletionDays,
		minColor: ColorGradient.getUrgencyColor(quickestCompletionDays, slowestCompletionDays, quickestCompletionDays),
		averageValue: averageCompletionDays,
		averageColor: ColorGradient.getUrgencyColor(quickestCompletionDays, slowestCompletionDays, averageCompletionDays),
		maxValue: 20,
		maxColor: ColorGradient.getUrgencyColor(quickestCompletionDays, 20, 20),
	};
}

function handleOverlayComplete(event: google.maps.drawing.OverlayCompleteEvent) {
	const { overlay } = event;
	if (!overlay || overlay instanceof google.maps.Marker || overlay instanceof google.maps.Polyline) {
		console.warn("Unsupported overlay type", overlay);
		return;
	}
	useBuilderPageStore.getState().onShapeDrawn(overlay);
}

const initializeMap = (options: {
	element: HTMLDivElement;
	office: GeocodedAddress | null;
	legend: ColoredMapLegend | undefined;
	mapLibrary: google.maps.MapsLibrary;
	drawingManager: google.maps.drawing.DrawingManager;
}): google.maps.Map => {
	const { element, office, legend, mapLibrary } = options;
	const map = new mapLibrary.Map(element, {
		center: office ? { lat: office.latitude, lng: office.longitude } : null,
		zoom: 10,
		clickableIcons: false,
		streetViewControl: false,
		fullscreenControl: false,
		mapTypeControl: false,
		mapId: GoogleMap.mapId,
	});

	if (legend) {
		const legendElement = document.createElement("div");
		ReactDOM.render(<MapLegend legend={legend} />, legendElement);
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legendElement);
	}

	console.log("Map initialized", map);
	map.addListener("idle", () => {
		const bounds = map.getBounds();
		if (bounds) {
			useBuilderPageStore.getState().changeMapBounds(bounds);
		}
	});
	return map;
};

const getUpdatedMarkers = (options: {
	visibleTickets: TicketLocation[];
	markers: google.maps.marker.AdvancedMarkerElement[];
	map: google.maps.Map;
	markerLibrary: google.maps.MarkerLibrary;
}): google.maps.marker.AdvancedMarkerElement[] => {
	const { visibleTickets, markers, map, markerLibrary } = options;
	const visibleTicketIds = new Set(visibleTickets.map((t) => t.ticketId));

	// Remove markers for tickets that are no longer visible
	const markersToRemove = markers.filter((marker) => !visibleTicketIds.has(marker.id));
	markersToRemove.forEach((marker) => {
		marker.position = null;
		if (marker.content) ReactDOM.unmountComponentAtNode(marker.content);
	});

	const stillVisibleMarkers = markers.filter((marker) => visibleTicketIds.has(marker.id));

	const ticketsToAdd = visibleTickets.filter((ticket) => !stillVisibleMarkers.some((marker) => marker.id === ticket.ticketId));

	const newMarkers = ticketsToAdd.map((ticket) => {
		const element = document.createElement("div");
		ReactDOM.render(<MapPin ticket={ticket} />, element);
		const marker = new markerLibrary.AdvancedMarkerElement({
			position: { lat: ticket.latitude, lng: ticket.longitude },
			map: map,
			content: element,
		});
		marker.addListener("click", () => {
			useBuilderPageStore.getState().toggleTicketLocation(ticket);
		});
		marker.id = ticket.ticketId;
		return marker;
	});

	const updatedMarkers = [...stillVisibleMarkers, ...newMarkers];
	return updatedMarkers;
};

const selectTicketsInShape = (options: {
	shape: google.maps.Polygon | google.maps.Rectangle | google.maps.Circle;
	visibleTickets: TicketLocation[];
	geometryLibrary: google.maps.GeometryLibrary;
}): TicketLocation[] => {
	const { shape: overlay, visibleTickets, geometryLibrary } = options;
	if (overlay instanceof google.maps.Circle) {
		const center = overlay.getCenter();
		const radius = overlay.getRadius();
		if (!center) {
			console.log("Circle has no center");
			return [];
		}
		return visibleTickets.filter((ticket) => {
			const ticketLocation = new google.maps.LatLng(ticket.latitude, ticket.longitude);
			const distance = geometryLibrary.spherical.computeDistanceBetween(center, ticketLocation);
			return distance <= radius;
		});
	}
	if (overlay instanceof google.maps.Rectangle) {
		const bounds = overlay.getBounds();
		if (!bounds) {
			console.log("Rectangle has no bounds");
			return [];
		}
		return visibleTickets.filter((ticket) => {
			const ticketLocation = new google.maps.LatLng(ticket.latitude, ticket.longitude);
			return bounds.contains(ticketLocation);
		});
	}
	if (overlay instanceof google.maps.Polygon) {
		const selectedTickets = visibleTickets.filter((ticket) => {
			const ticketLocation = new google.maps.LatLng(ticket.latitude, ticket.longitude);
			return google.maps.geometry.poly.containsLocation(ticketLocation, overlay);
		});
		return selectedTickets;
	}

	return [];
};

const createThemedDrawingManager = (drawingLibrary: google.maps.DrawingLibrary, theme: Theme): google.maps.drawing.DrawingManager =>
	new drawingLibrary.DrawingManager({
		drawingControl: false,
		circleOptions: {
			fillColor: theme.palette.primary.light,
			fillOpacity: 0.5,
			strokeColor: theme.palette.primary.dark,
			strokeWeight: 2,
		},
		rectangleOptions: {
			fillColor: theme.palette.primary.light,
			fillOpacity: 0.5,
			strokeColor: theme.palette.primary.dark,
			strokeWeight: 2,
		},
		polygonOptions: {
			fillColor: theme.palette.primary.light,
			fillOpacity: 0.5,
			strokeColor: theme.palette.primary.dark,
			strokeWeight: 2,
		},
	});

function MapPin(props: { ticket: TicketLocation }) {
	const { ticket } = props;
	const selectedTicketLocations = useBuilderPageStore((state: BuilderPageState) => state.selectedTicketLocations) as TicketLocation[];
	const legend = useBuilderPageStore((state: BuilderPageState) => state.legend) as ColoredMapLegend | undefined;
	let color = legend ? ColorGradient.getUrgencyColor(0, legend.maxValue, ticket.daysOld) : "red";
	if (selectedTicketLocations.some((t) => t.ticketId === ticket.ticketId)) {
		color = "#0288d1";
	}

	return (
		<div>
			<PlaceIcon style={{ fontSize: 30, color: color }} />
		</div>
	);
}

function MapLegend(props: { legend: ColoredMapLegend }) {
	const { legend } = props;
	return (
		<div style={{ padding: 10, backgroundColor: "white", color: "black", borderRadius: 5 }}>
			{/* Table of colors and values */}
			<table>
				<tbody>
					<tr>
						<th colSpan={2}>Completion Time</th>
					</tr>
					<tr>
						<td style={{ backgroundColor: legend.minColor, width: 20, height: 20 }}></td>
						<td>Min: {legend.minValue}</td>
					</tr>
					<tr>
						<td style={{ backgroundColor: legend.averageColor, width: 20, height: 20 }}></td>
						<td>Avg: {legend.averageValue}</td>
					</tr>
					<tr>
						<td style={{ backgroundColor: legend.maxColor, width: 20, height: 20 }}></td>
						<td>Max: {legend.maxValue}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
