import { Box, Button, MenuItem, TextField, Typography } from "@material-ui/core";
import React from "react";
import { Driver } from "../../../entities/routing/Driver";
import { useAlert } from "../../../hooks/useAlert";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useDrivers } from "../../../providers/DriverProvider";
import { useTicketDetail } from "./DriverTicketDetailView";
import { ClientLink } from "../../../components/ClientLink";
import { routes } from "../../../routes";

export function AssignedTicketDriver() {
	const alert = useAlert();
	const context = useTicketDetail();
	const { ticket, disabled, setDisabled, updateTicket } = context;

	const { driverId } = ticket;
	const { drivers } = useDrivers();

	const [editMode, setEditMode] = React.useState(false);

	const updateDriver = async (driver: Driver | null) => {
		setDisabled(true);
		const result = driver ? await DriverTicketService.assignDriver(ticket.id, driver.id) : await DriverTicketService.unassignDriver(ticket.id);
		setDisabled(false);

		if (result.success) {
			updateTicket(result.data);
			alert.success(driver ? `${driver.name} assigned to ticket` : `Driver unassigned from ticket`);
			setEditMode(false);
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	function DriverName() {
		return (
			<Typography
				variant={ticket.driverName ? "body1" : "h6"}
				color={ticket.driverName ? "textPrimary" : "textSecondary"}
				style={{ fontSize: ticket.driverName ? "1.15rem" : undefined }}
			>
				{
					ticket.driverId ? 
					<ClientLink to={routes.app.resolve.driverRoutePage(ticket.driverId)}>
						{ticket.driverName ?? "Driver"}
					</ClientLink>
					:
					"None"
				}
			</Typography>
		);
	}

	if (ticket.timeOfCompletion) {
		return (
			<>
				<Typography variant="h6">
					Driver:
				</Typography>
				<DriverName />
			</>
		);
	}

	return (
		<Box>
			<Button
				color="primary"
				disabled={disabled}
				variant="text"
				style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }}
				onClick={() => setEditMode(true)}
			>
				Driver:
			</Button>
			{editMode && (
				<TextField
					label="Driver"
					variant="outlined"
					fullWidth
					autoFocus
					disabled={disabled}
					style={{ maxWidth: 250 }}
					value={driverId ?? "None"}
					select
					onChange={(event) => {
						const value = event.target.value as string;
						const driver = drivers.find((driver) => driver.id === value);
						updateDriver(driver ?? null);
					}}
					onBlur={() => setEditMode(false)}
					onKeyDown={(event) => {
						if (event.key === "Escape") {
							event.stopPropagation();
							setEditMode(false);
						}
					}}
				>
					<MenuItem value="None">None</MenuItem>
					{drivers.map((driver) => (
						<MenuItem key={driver.id} value={driver.id}>
							{driver.name}
						</MenuItem>
					))}
				</TextField>
			)}
			{!editMode && <DriverName />}
		</Box>
	);
}
