import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonLink } from "../../../components/ButtonLink";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { Price } from "../../../components/Price";
import { PriceTextField } from "../../../components/PriceTextField";
import { RedText } from "../../../components/RedText";
import { RestrictToRole } from "../../../components/RestrictToRole";
import { PaymentTransaction } from "../../../entities/accounting/PaymentTransaction";
import { SearchableInvoice } from "../../../entities/billing/Invoice";
import { useAlert } from "../../../hooks/useAlert";
import { TransactionService } from "../../../services/accounting/TransactionService";
import { InvoiceService } from "../../../services/billing/InvoiceService";
import { Money } from "../../../utility/Money";
import { orderBy } from "../../../utility/orderBy";

interface Props {
	open: boolean;
	paymentTransaction: PaymentTransaction;
	onClose: () => void;
	onPaymentApplied: (paymentTransaction: PaymentTransaction) => void;
}

export function AllocatePaymentTransactionDialog(props: Props) {
	const { open, paymentTransaction } = props;
	const { customerId, allocations } = paymentTransaction;

	const alert = useAlert();

	const [openInvoices, setOpenInvoices] = useState<SearchableInvoice[]>();

	useEffect(() => {
		async function fetchInvoices() {
			const result = await InvoiceService.getCustomerInvoices(customerId);
			if (result.success) {
				const invoices = result.data
					.filter((i) => i.closed === false || allocations.some((a) => a.invoiceId === i.id))
					.sort(orderBy.date((i) => i.dueBy, "Ascending"));
				setOpenInvoices(invoices);
			} else {
				alert.serverError(result);
			}
		}

		if (open) {
			fetchInvoices();
		} else {
			setOpenInvoices(undefined);
		}
	}, [open, customerId, allocations, alert]);

	return (
		<Dialog open={open} onClose={props.onClose} maxWidth="md" fullWidth>
			<DialogTitle>Change Payment Allocation</DialogTitle>
			{openInvoices === undefined && (
				<DialogContent>
					<CenteredLoadingSpinner />
				</DialogContent>
			)}
			{openInvoices !== undefined && (
				<PaymentDialogContent openInvoices={openInvoices} paymentTransaction={paymentTransaction} onClose={props.onClose} onPaymentApplied={props.onPaymentApplied} />
			)}
		</Dialog>
	);
}

interface PaymentDialogContentProps {
	paymentTransaction: PaymentTransaction;
	openInvoices: SearchableInvoice[];
	onClose: () => void;
	onPaymentApplied: (paymentTransaction: PaymentTransaction) => void;
}

function PaymentDialogContent(props: PaymentDialogContentProps) {
	const { openInvoices, paymentTransaction } = props;
	const { allocations } = paymentTransaction;

	const alert = useAlert();

	const amount = useMemo(() => {
		const total = Money.fromDecimal(paymentTransaction.amount);
		if(paymentTransaction.type === "Card" && paymentTransaction.surchargeAmount){
			return total.subtract(Money.fromDecimal(paymentTransaction.surchargeAmount));
		}
		return total;
	}, [paymentTransaction]);
	const [appliesTo, setAppliesTo] = useState<{ invoice: SearchableInvoice; amountApplied: Dinero.Dinero }[]>([]);
	const [disabled, setDisabled] = useState(false);

	const anyInvoicesOverpaid = useMemo(() => appliesTo.some((a) => a.amountApplied > a.invoice.total), [appliesTo]);
	const totalAppliedToInvoices = useMemo(() => appliesTo.reduce((acc, curr) => acc.add(curr.amountApplied), Money.zero), [appliesTo]);
	const amountRemaining = Money.max(Money.zero, amount.subtract(totalAppliedToInvoices));
	const excessAppliedAmount = Money.max(Money.zero, totalAppliedToInvoices.subtract(amount));

	const disableSubmit = anyInvoicesOverpaid || Money.isPositive(excessAppliedAmount) || amount.isZero() || disabled;
	

	useEffect(() => {
		setAppliesTo(openInvoices.map((invoice) => ({ invoice, amountApplied: allocations.find((a) => a.invoiceId === invoice.id)?.amountApplied ?? Money.zero })));
	}, [openInvoices, allocations]);

	const onAppliedAmountChange = (amount: Dinero.Dinero, invoiceId: string) => {
		const updatedAppliedTo = appliesTo.map((a) => {
			if (a.invoice.id === invoiceId) {
				return { ...a, amountApplied: amount };
			}
			return a;
		});
		setAppliesTo(updatedAppliedTo);
	};

	const onSubmit = async () => {
		if (disableSubmit) {
			return;
		}

		setDisabled(true);
		const result = await TransactionService.allocatePayment({
			paymentTransactionId: paymentTransaction.id,
			allocations: appliesTo.map((a) => ({ invoiceId: a.invoice.id, amountApplied: a.amountApplied.toRoundedUnit(2) })),
		});
		setDisabled(false);

		if (result.success) {
			alert.success("Payment successfully applied");
			props.onPaymentApplied(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="body1" align="center">
							Total applied to invoices: <Price value={totalAppliedToInvoices} />{" "}
							{Money.isPositive(excessAppliedAmount) && (
								<RedText>
									Excess applied amount: <Price value={excessAppliedAmount} />
								</RedText>
							)}
						</Typography>
						<Paper>
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Invoice</TableCell>
											<TableCell>Invoice Date</TableCell>
											<TableCell>Due Date</TableCell>
											<TableCell>Balance</TableCell>
											<TableCell>Amount Applied</TableCell>
											<TableCell>Remaining</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{appliesTo.map((applyTo) => (
											<InvoiceTableRow
												invoice={applyTo.invoice}
												amountApplied={applyTo.amountApplied}
												currentAllocation={allocations.find((a) => a.invoiceId === applyTo.invoice.id)?.amountApplied ?? Money.zero}
												totalAmountRemaining={amountRemaining}
												onChange={onAppliedAmountChange}
												key={applyTo.invoice.id}
											/>
										))}
										<TableRow>
											<TableCell align="right" colSpan={5}>
												Unallocated amount
											</TableCell>
											<TableCell>
												<Price value={amountRemaining} />
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={disabled} onClick={props.onClose} variant="outlined" color="secondary">
					Cancel
				</Button>
				<RestrictToRole admin>
					<Button onClick={onSubmit} disabled={disableSubmit} variant="contained" color="primary">
						Apply Payment
					</Button>
				</RestrictToRole>
			</DialogActions>
		</>
	);
}

function InvoiceTableRow(props: {
	invoice: SearchableInvoice;
	currentAllocation: Dinero.Dinero;
	amountApplied: Dinero.Dinero;
	totalAmountRemaining: Dinero.Dinero;
	onChange: (amountApplied: Dinero.Dinero, invoiceId: string) => void;
}) {
	const { invoice, amountApplied, totalAmountRemaining, currentAllocation } = props;
	const { id: invoiceId, shortId, issuedOn, dueBy, balance } = invoice;

	const [editMode, setEditMode] = useState(false);

	const amountRemaining = Money.max(Money.zero, balance.subtract(amountApplied).add(currentAllocation));
	const amountToApply = Money.min(amountRemaining.add(amountApplied), totalAmountRemaining.add(amountApplied));
	const excessAmount = Money.max(Money.zero, amountApplied.subtract(balance));

	return (
		<TableRow>
			<TableCell>{shortId}</TableCell>
			<TableCell>{issuedOn.toLocaleDateString()}</TableCell>
			<TableCell>{dueBy.toLocaleDateString()}</TableCell>
			<TableCell>
				<Price value={invoice.balance} />{" "}
				{Money.isPositive(currentAllocation) && (
					<>
						(Allocated: <Price value={currentAllocation} />)
					</>
				)}
			</TableCell>
			<TableCell>
				{editMode ? (
					<PriceTextField
						autoFocus
						value={amountApplied.toRoundedUnit(2)}
						onPriceChanged={(p) => props.onChange(Money.fromDecimal(p ?? 0), invoiceId)}
						error={Money.isPositive(excessAmount)}
						helperText={Money.isPositive(excessAmount) ? `Applied ${excessAmount.toRoundedUnit(2)} more than balance` : ""}
						style={{ maxWidth: 150 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setEditMode(false)} size="small">
										<Close />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				) : (
					<Grid container alignItems="center">
						<Grid item>
							<Box minWidth={70}>
								<ButtonLink onClick={() => setEditMode(true)}>
									<Price value={amountApplied} />
								</ButtonLink>
							</Box>
						</Grid>
						<Grid item>
							<Button size="small" onClick={() => props.onChange(amountToApply, invoiceId)}>
								Apply
							</Button>
							<Button size="small" onClick={() => props.onChange(Money.zero, invoiceId)}>
								Clear
							</Button>
						</Grid>
					</Grid>
				)}
			</TableCell>
			<TableCell>
				<Price value={amountRemaining} />
			</TableCell>
		</TableRow>
	);
}
