import { Box, TableCell, TableRow, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useCreateTicketContext } from "./NewCreateTicketPage";
import { NewTicketProduct } from "./TicketProduct";
import { BoxIconButton } from "../../../components/BoxIconButton";
import CloseIcon from "@material-ui/icons/Close";
import { NumberTextField } from "../../../components/NumberTextField";
import { ButtonLink } from "../../../components/ButtonLink";
import { Price } from "../../../components/Price";
import { EditTicketTaxesDialog } from "./EditTicketTaxesDialog";
import { PriceTextField } from "../../../components/PriceTextField";
import { Money } from "../../../utility/Money";

export function TicketProductQuoteItem(props: { ticketProduct: NewTicketProduct; showSubtotal: boolean }) {
	const { ticketProduct, showSubtotal } = props;

	const context = useCreateTicketContext();
	const { setTicketProducts } = context;

	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down(450));

	const onDelete = () => setTicketProducts((tp) => tp.filter((t) => t.listing.id !== ticketProduct.listing.id));

	return (
		<TableRow>
			<TableCell style={{ paddingLeft: 0, paddingRight: 5 }}>
				<Box display="flex" alignItems={isXs ? "flex-start" : "center"} flexDirection={isXs ? "column-reverse" : "row"}>
					<Box display="flex" width={isXs ? "100%" : undefined} marginRight={isXs ? 0 : 1}>
						<BoxIconButton color="secondary" onClick={onDelete} style={{ marginTop: isXs ? 6 : 0 }}>
							<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
						</BoxIconButton>
					</Box>
					<Box overflow="hidden" whiteSpace={isXs ? "nowrap" : undefined} textOverflow="ellipsis" maxWidth={isXs ? 165 : undefined} alignSelf="flex-start">
						<Typography variant="body1" noWrap={isXs ? true : undefined}>
							{ticketProduct.name}
						</Typography>
						{ticketProduct.name !== ticketProduct.listing.name && (
							<Typography variant="caption" color="textSecondary" noWrap={isXs ? true : undefined}>
								{ticketProduct.listing.name}
							</Typography>
						)}
					</Box>
				</Box>
			</TableCell>

			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ItemQuantity ticketProduct={ticketProduct} />
			</TableCell>
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ItemPrice ticketProduct={ticketProduct} />
			</TableCell>
			{showSubtotal && (
				<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
					<ItemSubtotal ticketProduct={ticketProduct} />
				</TableCell>
			)}
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ItemTax ticketProduct={ticketProduct} />
			</TableCell>
		</TableRow>
	);
}

function ItemSubtotal(props: { ticketProduct: NewTicketProduct }) {
	const { ticketProduct } = props;

	if (ticketProduct.subtotal === null) {
		return <>Pending</>;
	}
	return <Price value={ticketProduct.subtotal} />;
}

function ItemQuantity(props: { ticketProduct: NewTicketProduct }) {
	const { ticketProduct } = props;

	const context = useCreateTicketContext();
	const { setTicketProducts } = context;

	const [editQuantity, setEditQuantity] = React.useState(false);
	const [quantity, setQuantity] = React.useState(ticketProduct.quantity);

	const onQuantitySubmit = () => {
		setTicketProducts((tp) => tp.map((p) => (p.listing.id === ticketProduct.listing.id ? p.copy({ quantity }) : p)));
		setEditQuantity(false);
	};

	const onQuantityEscape = () => {
		setEditQuantity(false);
		setQuantity(ticketProduct.quantity);
	};

	if (editQuantity) {
		return (
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onQuantitySubmit();
				}}
			>
				<NumberTextField
					size="small"
					variant="outlined"
					value={quantity}
					onNumberChange={setQuantity}
					autoFocus
					onBlur={onQuantitySubmit}
					onKeyDown={(e) => {
						if (e.key === "Escape") {
							e.stopPropagation();
							onQuantityEscape();
						}
					}}
				/>
			</form>
		);
	}
	if (ticketProduct.quantity) {
		return <ButtonLink onClick={() => setEditQuantity(true)}>{ticketProduct.quantity}</ButtonLink>;
	}
	return <ButtonLink onClick={() => setEditQuantity(true)}>Pending</ButtonLink>;
}

function ItemPrice(props: { ticketProduct: NewTicketProduct }) {
	const { ticketProduct } = props;

	const context = useCreateTicketContext();
	const { setTicketProducts } = context;

	const [editPrice, setEditPrice] = React.useState(false);
	const [priceValue, setPriceValue] = React.useState(ticketProduct.priceValue);

	const onPriceSubmit = () => {
		setTicketProducts((tp) => tp.map((p) => (p.listing.id === ticketProduct.listing.id ? p.copy({ priceValue }) : p)));
		setEditPrice(false);
	};

	const onPriceEscape = () => {
		setEditPrice(false);
		setPriceValue(ticketProduct.priceValue);
	};

	if (editPrice) {
		return (
			<div tabIndex={0} onBlur={onPriceSubmit}>
				<PriceTextField
					size="small"
					variant="outlined"
					value={priceValue}
					onPriceChanged={setPriceValue}
					autoFocus
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onPriceSubmit();
						}
						if (e.key === "Escape") {
							e.stopPropagation();
							onPriceEscape();
						}
					}}
				/>
			</div>
		);
	}
	if (ticketProduct.price) {
		return (
			<ButtonLink onClick={() => setEditPrice(true)}>
				<Price value={ticketProduct.price} decimalOptional doNotRound />
			</ButtonLink>
		);
	}
	return <ButtonLink onClick={() => setEditPrice(true)}><Price value={Money.zero} decimalOptional doNotRound /></ButtonLink>;
}

function ItemTax(props: { ticketProduct: NewTicketProduct }) {
	const { ticketProduct } = props;

	const context = useCreateTicketContext();
	const { setTicketProducts } = context;

	const [editTaxes, setEditTaxes] = React.useState(false);

	return (
		<>
			{ticketProduct.tax ? (
				<ButtonLink onClick={() => setEditTaxes(true)}>
					<Price value={ticketProduct.tax} />
				</ButtonLink>
			) : (
				<ButtonLink onClick={() => setEditTaxes(true)}>TBD</ButtonLink>
			)}
			<EditTicketTaxesDialog
				taxRules={ticketProduct.taxRules}
				exemption={ticketProduct.taxExemption}
				subtotal={ticketProduct.subtotal}
				onChange={(taxRules) => setTicketProducts((tp) => tp.map((p) => (p.listing.id === ticketProduct.listing.id ? p.copy({ taxRules }) : p)))}
				open={editTaxes}
				onClose={() => setEditTaxes(false)}
			/>
		</>
	);
}
