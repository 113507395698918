import React from "react";
import { Grid, Card, CardHeader, CardContent, Typography, Button, TextField } from "@material-ui/core";
import { BackButton } from "../../../../components/BackButton";
import { DispatchProPaymentsIntegrationOption } from "../../../../entities/auth/IntegrationConfiguration";
import { PageTitle } from "../../../../components/PageTitle";
import { RestrictToRole } from "../../../../components/RestrictToRole";

export function ConfigureDPPaymentsView(props: { integration: DispatchProPaymentsIntegrationOption; }) {
	const { integration } = props;

	return (
		<Grid container justify="center">
			<PageTitle title="Integrations - DP Payments" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="DispatchPro Payments" />
						</Grid>
					</Grid>
					<CardContent>
						<Grid container justify="center">
							<Grid item>
								<img src={integration.largeLogoUrl} alt="Dispatch Pro Payments Logo" style={{ maxWidth: "100%", maxHeight: 150, marginBottom: 15 }} />
							</Grid>
						</Grid>
						<Typography variant="body1" gutterBottom>
							{integration.description}
						</Typography>
						<RestrictToRole superAdmin>
						<Grid container alignItems="center" style={{ marginTop: 15 }}>
							<Grid item lg={5} sm={6} xs={8}>
								<TextField variant="outlined" label="Merchant ID" value={integration.configuration?.merchantId ?? ""} disabled={true} fullWidth />
							</Grid>
							<Grid md={3} sm={2} xs={4}>
								<Button
									color="secondary"
									fullWidth
									variant="contained"
									disabled={true}
									style={{ height: 55, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
								>
									Connected!
								</Button>
							</Grid>
						</Grid>
						</RestrictToRole>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
