import { BatchedTransactionExport, TransactionExportBatch } from "../../../entities/accounting/TransactionExportBatch";
import React, { useEffect, useState } from "react";
import { TransactionExportBatchService } from "../../../services/accounting/TransactionExportBatchService";
import { useAlert } from "../../../hooks/useAlert";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { Grid, Typography, Card, TableHead, TableRow, TableCell, TableBody, Button, TablePagination } from "@material-ui/core";
import { Table } from "react-super-responsive-table";
import { BackButton } from "../../../components/BackButton";
import { Price } from "../../../components/Price";
import { useParams } from "react-router";
import { GridGrow } from "../../../components/GridGrow";
import { ExportBalanceChangesCard } from "./ExportBalanceChangesCard";

export function TransactionExportBatchDetailPage() {
	const alert = useAlert();
	const params = useParams<{ transactionExportBatchId: string }>();
	const { transactionExportBatchId } = params;

	const [transactionExport, setTransactionExport] = useState<BatchedTransactionExport>();
	const [downloadDate, setDownloadDate] = useState<Date | null>(null);
	const [batch, setBatch] = useState<TransactionExportBatch>();
	const [disabled, setDisabled] = useState(false);
	const [page, setPage] = useState(0);
	const rowsPerPage = 10;

	const displayedTransactions = transactionExport?.groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ?? [];

	useEffect(() => {
		async function load() {
			const [exportResponse, batchResponse] = await Promise.all([
				TransactionExportBatchService.getTransactionExports(transactionExportBatchId),
				TransactionExportBatchService.get(transactionExportBatchId),
			]);
			if (exportResponse.success) {
				setTransactionExport(exportResponse.data);
			} else {
				alert.serverError(exportResponse);
			}
			if (batchResponse.success) {
				setBatch(batchResponse.data);
				setDownloadDate(batchResponse.data.downloadDate);
			} else {
				alert.serverError(batchResponse);
			}
		}
		load();
	}, [alert, transactionExportBatchId]);

	const downloadQuickBooksFile = async () => {
		if (!batch) return;
		setDisabled(true);
		const response = await TransactionExportBatchService.downloadExportToQuickBooks(batch);
		setDisabled(false);
		if (response.success) {
			setDownloadDate(new Date());
		} else {
			alert.serverError(response);
		}
	};

	if (!transactionExport || !batch) return <OverlayLoadingScreen />;

	return (
		<Grid container>
			<Grid item>
				<BackButton />
			</Grid>
			<Grid item>
				<Typography variant="h2">Transaction Batch {batch.batchNumber}</Typography>
				<Typography variant="overline">Created: {batch.created.toLocaleString()}</Typography>
				<br />
				<Typography variant="overline" gutterBottom>
					Downloaded: {downloadDate?.toLocaleString() ?? "Not downloaded"}
				</Typography>
			</Grid>
			<GridGrow />
			<Grid item>
				<Button variant="contained" color="primary" onClick={downloadQuickBooksFile} disabled={disabled}>
					Download QuickBooks File
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item lg={6} md={8} sm={10} xs={12}>
						<Card>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TablePagination
											colSpan={4}
											rowsPerPageOptions={[]}
											count={transactionExport.groups.length}
											rowsPerPage={10}
											page={page}
											onChangePage={(_, p) => setPage(p)}
										/>
									</TableRow>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Type</TableCell>
										<TableCell>Balance Change</TableCell>
										<TableCell>Customer</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{transactionExport.groups.length === 0 && (
										<TableRow>
											<TableCell colSpan={4} style={{ textAlign: "center" }}>
												No transactions found.
											</TableCell>
										</TableRow>
									)}
									{displayedTransactions.map((group) => (
										<TableRow key={group.transactionId}>
											<TableCell>
												{group.date.toLocaleDateString()} {group.date.toLocaleTimeString()}
											</TableCell>
											<TableCell>{group.type}</TableCell>
											<TableCell>
												<Price value={group.customerBalanceChange} />
											</TableCell>
											<TableCell>{group.customerCode}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Card>
					</Grid>
					<Grid item lg={6} md={8} sm={10} xs={12}>
						<ExportBalanceChangesCard selectedTransactions={transactionExport.groups} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
