import { Modify } from "../../utility/modifyType";

export enum TaxCriteriaType {
    StateMatch = 1,
    ZipMatch = 2,
    CountyMatch = 3,
    CityMatch = 4
}

interface TaxCriteriaBase {
    id: string;
    type: TaxCriteriaType;
}

export type StateTaxCriteria = Modify<TaxCriteriaBase, {
    type: TaxCriteriaType.StateMatch;
    state: string;
}>

export type ZipTaxCriteria = Modify<TaxCriteriaBase, {
    type: TaxCriteriaType.ZipMatch;
    zip: string;
}>

export type CountyTaxCriteria = Modify<TaxCriteriaBase, {
    type: TaxCriteriaType.CountyMatch;
    county: string;
}>

export type CityTaxCriteria = Modify<TaxCriteriaBase, {
    type: TaxCriteriaType.CityMatch;
    city: string;
}>

export type TaxCriteria = StateTaxCriteria | ZipTaxCriteria | CountyTaxCriteria | CityTaxCriteria;



export const printTaxCriteria = (criteria: TaxCriteria) => {
    switch(criteria.type) {
        case TaxCriteriaType.StateMatch: return `State: "${criteria.state}"`;
        case TaxCriteriaType.ZipMatch: return `Zip: "${criteria.zip}"`;
        case TaxCriteriaType.CountyMatch: return `County: "${criteria.county}"`;
        case TaxCriteriaType.CityMatch: return `City: "${criteria.city}"`;
        default: return "Unknown";
    }
}