import * as React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AppDataProviders } from "../providers/AppDataProviders";
import { AppUserProvider } from "../providers/AppUserProvider";
import { AppLayout } from "./AppLayout";


export default function AppContent() {
	
	return (
		<AppUserProvider>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<AppDataProviders>
					<AppLayout/>
				</AppDataProviders>
			</MuiPickersUtilsProvider>
		</AppUserProvider>
	);
}
