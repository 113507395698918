import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { PhoneTextField } from "../../../components/PhoneTextField";
import { useAlert } from "../../../hooks/useAlert";
import { AccountService } from "../../../services/business/AccountService";
import AuthService from "../../../services/auth/AuthService";

import { FieldValidationError, validationError } from "../../../services/server/ServerValidationError";
import { PhoneNumber } from "../../../entities/customer/PhoneNumber";
import { useUser } from "../../../hooks/useUser";

export function EnableSmsNotificationsDialog(props: { open: boolean; onClose: () => void }) {
	const alert = useAlert();
	const [disabled, setDisabled] = useState(false);
	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | null>(null);
	const [, setUser] = useUser();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if(!phoneNumber){
			setValidationErrors([{field: "PhoneNumber", errors: ["Missing Phone Number"]}]);
			return;
		}
		setValidationErrors([]);
		setDisabled(true);
		const result = await AccountService.enableSmsNotifications(phoneNumber.number);
		setDisabled(false);
		if(result.success){
			const result = await AuthService.attemptTokenRefreshAndSet();
			if (result) {
				setUser(result.jwt);
			}
			alert.success("Notifications Enabled");
			props.onClose();
		} else if(result.validation){
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Enable SMS Notifications</DialogTitle>
			<form onSubmit={onSubmit}>
				<DialogContent>
					<DialogContentText>Enter your phone number to recieve SMS messages whenever there is an update to your route.</DialogContentText>
					<PhoneTextField
						required
						fullWidth
						disabled={disabled}
						margin="dense"
						label="Phone"
						allowExtensions={false}
						value={phoneNumber}
						onChangePhoneNumber={(p) => setPhoneNumber(p)}
						error={validationError.isFieldInError(validationErrors, "PhoneNumber")}
						helperText={validationError.getFieldError(validationErrors, "PhoneNumber")}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.onClose} color="primary" disabled={disabled}>
						Cancel
					</Button>
					<Button type="submit" color="primary" disabled={disabled}>
						Subscribe
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}