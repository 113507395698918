import {
	Box,
	Button,
	Card,
	Dialog,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { GridGrow } from "../../../components/GridGrow";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { NewTicketTankFill } from "./TicketTankFill";
import { NewTicketProduct } from "./TicketProduct";
import { CustomerService, LastSale, LastSaleItem } from "../../../services/customer/CustomerService";
import { useAlert } from "../../../hooks/useAlert";
import { Price } from "../../../components/Price";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { ProductCategoryType } from "../../../entities/products/ProductCategoryType";
import { Money } from "../../../utility/Money";
import { Strikethrough } from "../../../components/Strikethrough";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { CustomerTank } from "../../../services/customer/PropaneTankService";
import { printDate } from "../../../utility/PrintDate";
import { ExemptionType } from "../../../entities/accounting/TaxExemption";
import { useCreateTicketContext } from "./NewCreateTicketPage";

export function LastInvoiceSection() {
	const customerContext = useCustomerDetailPage();
	const { customer } = customerContext;
	const customerId = customer.id;
	const alert = useAlert();

	const [lastSale, setLastSale] = React.useState<LastSale | null | undefined>();

	useEffect(() => {
		async function loadInvoice() {
			const result = await CustomerService.getLastSale(customerId);
			if (result.success) {
				setLastSale(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadInvoice();
	}, [customerId, alert]);

	return (
		<Card>
			{lastSale === undefined && <CenteredLoadingSpinner />}
			{lastSale === null && (
				<Typography variant="h6" style={{ padding: 20 }}>
					No invoices yet
				</Typography>
			)}
			{lastSale && <LastSaleView lastSale={lastSale} />}
			<Box m={1}>
				<Grid container spacing={1}>
					<Grid item>
						<Typography variant="h6" gutterBottom>
							Account Balance
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6" gutterBottom>
							<Price value={customer.balance} />
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Card>
	);
}

function LastSaleView(props: { lastSale: LastSale }) {
	const { lastSale } = props;

	const customerContext = useCustomerDetailPage();
	const { profile } = customerContext;
	const { tanks, taxExemptions } = profile;

	const { ticketProducts, setTicketProducts, tankFills, setTankFills, address } = useCreateTicketContext();

	const { productCatalog } = useProductCatalog();

	const [confirmTanks, setConfirmTanks] = React.useState<{ item: LastSaleItem; tanks: CustomerTank[] } | undefined>(undefined);

	const toTicketProduct = (item: LastSaleItem) => {
		const productListing = productCatalog.getProductListing(item.productListingId);
		if (!productListing) throw new Error(`Product listing ${item.productListingId} not found`);
		const taxExemption = taxExemptions.find((te) => te.type === ExemptionType.Customer && te.valid) ?? null;
		const taxRules = taxExemption ? [] : productCatalog.getTaxRules(productListing, address);
		const ticketProduct = NewTicketProduct.create(productListing, taxRules, taxExemption);
		return ticketProduct.copy({ quantity: item.quantity, priceValue: item.price, name: item.description });
	};

	const toTicketTankFill = (item: LastSaleItem, customerTank: CustomerTank) => {
		const productListing = productCatalog.getProductListing(item.productListingId);
		if (!productListing) throw new Error(`Product listing ${item.productListingId} not found`);
		const taxExemption =
			taxExemptions.find((te) => te.type === ExemptionType.Customer && te.valid) ??
			taxExemptions.find((te) => te.type === ExemptionType.PropaneTank && te.tankId === customerTank.id && te.valid) ??
			null;
		const taxRules = taxExemption ? [] : productCatalog.getTaxRules(productListing, address);
		const tankFill = NewTicketTankFill.create(customerTank, productListing, taxRules, taxExemption);
		return tankFill.copy({ priceValue: item.price });
	};

	const addTankFillRequested = (item: LastSaleItem) => {
		const remainingTanks = tanks.filter((t) => !tankFills.some((x) => x.tank.id === t.id));
		if (remainingTanks.length === 1) {
			addTankFillToQuote(item, remainingTanks[0]);
		} else {
			setConfirmTanks({ item, tanks: remainingTanks });
		}
	};

	const addTicketProductToQuote = (item: LastSaleItem) => setTicketProducts((ticketProducts) => [...ticketProducts, toTicketProduct(item)]);
	const addTankFillToQuote = (item: LastSaleItem, customerTank: CustomerTank) => setTankFills((tankFills) => [...tankFills, toTicketTankFill(item, customerTank)]);

	const addAllToQuote = () => {
		const { ticketProductsToAdd, tankFillsToAdd } = availableProducts();
		setTicketProducts((ticketProducts) => [...ticketProducts, ...ticketProductsToAdd]);
		setTankFills((tankFills) => [...tankFills, ...tankFillsToAdd]);
	};

	const availableProducts = () => {
		const remainingTanks = tanks.filter((t) => !tankFills.some((x) => x.tank.id === t.id));
		let tankFillsToAdd: NewTicketTankFill[] = [];
		const propaneItems = lastSale.items.filter((i) => i.productLine === ProductCategoryType.Propane);

		if (propaneItems.length > 0 && remainingTanks.length > 0) {
			const item = propaneItems[0];
			const tanks = remainingTanks.slice(0, propaneItems.length);
			tankFillsToAdd = tankFillsToAdd.concat(tanks.map((t) => toTicketTankFill(item, t)));
		}

		const ticketProductsToAdd = lastSale.items
			.filter((i) => i.productLine !== ProductCategoryType.Propane)
			.filter((i) => !ticketProducts.some((x) => x.listing.id === i.productListingId))
			.map(toTicketProduct);

		return { ticketProductsToAdd, tankFillsToAdd };
	};

	const canAddAll = () => {
		const { ticketProductsToAdd, tankFillsToAdd } = availableProducts();
		return ticketProductsToAdd.length > 0 || tankFillsToAdd.length > 0;
	};
	const canAdd = canAddAll();

	return (
		<>
			<Box m={1}>
				<Grid container>
					<Grid item>
						<Typography variant="h6" gutterBottom>
							Last Sale - {printDate.shortDate(lastSale.timeOfSale)}
						</Typography>
					</Grid>
					<GridGrow />
					<Grid item>
						<Button variant="outlined" color="primary" disabled={!canAdd} onClick={addAllToQuote}>
							Add To Quote
						</Button>
					</Grid>
				</Grid>
			</Box>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Product</TableCell>
						<TableCell>Quantity</TableCell>
						<TableCell>Price</TableCell>
						<TableCell>Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lastSale.items.map((item, i) => (
						<SaleItemRow key={i} item={item} onAddTicketProduct={addTicketProductToQuote} onAddTankFill={addTankFillRequested} />
					))}
				</TableBody>
			</Table>

			<Dialog open={confirmTanks != null} onClose={() => setConfirmTanks(undefined)}>
				<DialogTitle>Choose Tank</DialogTitle>
				{confirmTanks && (
					<List>
						{confirmTanks.tanks.map((t) => (
							<React.Fragment key={t.id}>
								<Divider />
								<ListItem
									button
									onClick={() => {
										addTankFillToQuote(confirmTanks.item, t);
										setConfirmTanks(undefined);
									}}
								>
									<ListItemText primary={`${t.gallons}`} secondary={t.description ? t.description : undefined} />
								</ListItem>
							</React.Fragment>
						))}
					</List>
				)}
			</Dialog>
		</>
	);
}

interface SaleItemRowProps {
	item: LastSaleItem;
	onAddTicketProduct: (item: LastSaleItem) => void;
	onAddTankFill: (item: LastSaleItem) => void;
}

function canAddToQuote(item: LastSaleItem, ticketProducts: NewTicketProduct[], tanks: CustomerTank[]) {
	if (item.productLine === ProductCategoryType.Propane) {
		return tanks.length > 0;
	} else {
		return !ticketProducts.some((x) => x.listing.id === item.productListingId);
	}
}

function SaleItemRow(props: SaleItemRowProps) {
	const { item } = props;

	const alert = useAlert();
	const customerContext = useCustomerDetailPage();
	const { profile } = customerContext;
	const { tanks } = profile;

	const { ticketProducts, tankFills } = useCreateTicketContext();
	const remainingTanks = tanks.filter((t) => !tankFills.some((x) => x.tank.id === t.id));
	const canBeAdded = canAddToQuote(item, ticketProducts, remainingTanks);

	const price = Money.fromDecimal(item.price, 3);
	const subtotal = price.multiply(item.quantity);
	const discount = Money.fromDecimal(item.discount);
	const isDiscounted = discount.greaterThan(Money.zero);

	const onAdd = () => {
		if (item.productLine === ProductCategoryType.Propane) {
			if (!canBeAdded) {
				alert.error(`All tanks are already added to quote`);
			} else {
				props.onAddTankFill(item);
			}
		} else {
			if (!canBeAdded) {
				alert.error(`${item.productListingName} is already added to quote`);
			} else {
				props.onAddTicketProduct(item);
			}
		}
	};

	return (
		<>
			<TableRow hover={canBeAdded} style={{ opacity: !canBeAdded ? 0.5 : undefined }} onClick={onAdd}>
				<TableCell>
					{item.description}
					{item.description !== item.productListingName && (
						<>
							<br />
							<Typography variant="caption">{item.productListingName}</Typography>
						</>
					)}
				</TableCell>
				<TableCell>{item.quantity}</TableCell>
				<TableCell>
					<Price value={item.price} doNotRound />
				</TableCell>
				<TableCell>
					{!isDiscounted && <Price value={subtotal} />}
					{isDiscounted && (
						<>
							<Strikethrough>
								<Price value={subtotal} />
							</Strikethrough>
							<br />
							- <Price value={discount} />
							<br />
							<Price value={subtotal.subtract(discount)} />
						</>
					)}
				</TableCell>
			</TableRow>
		</>
	);
}
