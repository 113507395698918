import { useAlert } from "../../../hooks/useAlert";
import React, { useMemo } from "react";
import { useTaxRuleDetailContext } from "./TaxRuleDetailView";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import { TaxMatchGroup, printTaxMatchGroup } from "../../../entities/accounting/TaxRule";
import { TaxCriteria, TaxCriteriaType, printTaxCriteria } from "../../../entities/accounting/TaxCriteria";
import { TaxRuleService } from "../../../services/accounting/TaxRuleService";
import DeleteIcon from "@material-ui/icons/Delete";

export function TaxMatchCriteriaCard() {
	const alert = useAlert();
	const context = useTaxRuleDetailContext();
	const { taxRule } = context;

	const [disabled, setDisabled] = React.useState(false);
	const [addMatchGroup, setAddMatchGroup] = React.useState(false);

	const createGroup = async (criteria: TaxCriteria) => {
		setDisabled(true);
		const result = await TaxRuleService.createMatchGroup(taxRule.id, criteria);
		setDisabled(false);
		if (result.success) {
			context.updateTaxRule(result.data);
			alert.success("Rule Group created");
			setAddMatchGroup(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card style={{ marginTop: 15 }}>
			<CardHeader title="Tax Match Rules" style={{ paddingBottom: 5 }} />
			{taxRule.matchGroups.length !== 0 && (
				<>
					{taxRule.matchGroups.map((group, index) => (
						<React.Fragment key={group.id}>
							{index !== 0 && (
								<Typography variant="caption" color="textSecondary" style={{ paddingLeft: 15, fontSize: 18, display: "block" }}>
									OR
								</Typography>
							)}
							<Typography variant="caption" color="textSecondary" style={{ paddingLeft: 15, fontSize: 18 }}>
								{printTaxMatchGroup(group)}
							</Typography>
						</React.Fragment>
					))}
                    {
                        taxRule.exclusions.length !== 0 && (<Typography variant="caption" color="textSecondary" style={{ paddingLeft: 15, fontSize: 18, display: "block" }}>
                        Excludes {taxRule.exclusions.map((exclusion) => printTaxCriteria(exclusion)).join(" and ")}
                    </Typography>)
                    }
				</>
			)}
			<CardContent>
				{taxRule.matchGroups.map((group, index) => (
					<MatchGroupRules group={group} number={index + 1} key={group.id} />
				))}
				{addMatchGroup && <AddCriteria disabled={disabled} onAdd={createGroup} onCancel={() => setAddMatchGroup(false)} />}
				{!addMatchGroup && (
					<Button variant="contained" color="primary" style={{ marginTop: 10, marginBottom: 10 }} size="small" onClick={() => setAddMatchGroup(true)}>
						+ Add Rule Group
					</Button>
				)}
				<TaxExclusionList />
			</CardContent>
		</Card>
	);
}

function TaxExclusionList() {
	const alert = useAlert();
	const context = useTaxRuleDetailContext();
	const { taxRule } = context;
	const [disabled, setDisabled] = React.useState(false);
	const [addExclusion, setAddExclusion] = React.useState(false);

	const noExclusions = taxRule.exclusions.length === 0;

	const addNewExclusion = async (criteria: TaxCriteria) => {
		setDisabled(true);
		const result = await TaxRuleService.addExclusion(taxRule.id, criteria);
		setDisabled(false);
		if (result.success) {
			context.updateTaxRule(result.data);
			alert.success("Exclusion created");
			setAddExclusion(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const removeExclusion = async (criteria: TaxCriteria) => {
		setDisabled(true);
		const result = await TaxRuleService.removeExclusion(taxRule.id, criteria.id);
		setDisabled(false);
		if (result.success) {
			context.updateTaxRule(result.data);
			alert.success("Exclusion removed");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<>
			<Typography variant="h6" style={{ marginBottom: 5 }}>
				{noExclusions && "No "}Exclusions
			</Typography>
			{!noExclusions && (
				<List dense>
					{taxRule.exclusions.map((exclusion) => (
						<ListItem key={exclusion.id}>
							<ListItemText primary={printTaxCriteria(exclusion)} />
							<ListItemSecondaryAction>
								<Button variant="outlined" color="secondary" size="small" onClick={() => removeExclusion(exclusion)} disabled={disabled}>
									Remove
								</Button>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			)}
			{addExclusion && <AddCriteria disabled={disabled} onAdd={addNewExclusion} onCancel={() => setAddExclusion(false)} />}
			{!addExclusion && (
				<Button variant="contained" color="secondary" size="small" style={{ marginTop: 10 }} onClick={() => setAddExclusion(true)}>
					+ Add Exclusion
				</Button>
			)}
		</>
	);
}

function MatchGroupRules(props: { group: TaxMatchGroup; number: number }) {
	const { group, number } = props;

	const alert = useAlert();
	const context = useTaxRuleDetailContext();
	const { taxRule } = context;
	const [disabled, setDisabled] = React.useState(false);
	const [addAdditionalCriteria, setAddAdditionalCriteria] = React.useState(false);
	const usedCriteriaTypes = group.criteria.map((criteria) => criteria.type);
	const haveUsedAllCriteriaTypes = Object.values(TaxCriteriaType).every((type) => typeof type !== "string" && usedCriteriaTypes.includes(type));

	const deleteGroup = async () => {
		setDisabled(true);
		const result = await TaxRuleService.deleteMatchGroup(taxRule.id, group.id);
		setDisabled(false);
		if (result.success) {
			context.updateTaxRule(result.data);
			alert.success("Rule Group removed");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const addCriteria = async (criteria: TaxCriteria) => {
		setDisabled(true);
		const result = await TaxRuleService.addCriteriaToGroup(taxRule.id, group.id, criteria);
		setDisabled(false);
		if (result.success) {
			context.updateTaxRule(result.data);
			alert.success("Criteria added");
			setAddAdditionalCriteria(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const removeCriteria = async (criteria: TaxCriteria) => {
		setDisabled(true);
		const result = await TaxRuleService.removeCriteriaFromGroup(taxRule.id, group.id, criteria.id);
		setDisabled(false);
		if (result.success) {
			context.updateTaxRule(result.data);
			alert.success("Criteria removed");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<>
			<Grid container alignItems="center">
				<Grid item>
					<IconButton size="small" color="secondary" disabled={disabled} onClick={deleteGroup}>
						<DeleteIcon />
					</IconButton>
				</Grid>
				<Grid item>
					<Typography variant="h6">Rule Set {number}</Typography>
				</Grid>
			</Grid>
			<List dense>
				{group.criteria.map((criteria, index) => (
					<ListItem key={criteria.id}>
						<ListItemText primary={`${index > 0 ? "AND " : ""}${printTaxCriteria(criteria)}`} />
						{group.criteria.length > 1 && (
							<ListItemSecondaryAction>
								<Button variant="outlined" color="secondary" size="small" disabled={disabled} onClick={() => removeCriteria(criteria)}>
									Remove
								</Button>
							</ListItemSecondaryAction>
						)}
					</ListItem>
				))}
				{!addAdditionalCriteria && !haveUsedAllCriteriaTypes && (
					<ListItem>
						<Button variant="outlined" color="primary" size="small" disabled={disabled} onClick={() => setAddAdditionalCriteria(true)}>
							+ Add
						</Button>
					</ListItem>
				)}
			</List>
			{addAdditionalCriteria && (
				<AddCriteria onAdd={addCriteria} onCancel={() => setAddAdditionalCriteria(false)} disabled={disabled} excludeTypes={usedCriteriaTypes} />
			)}
		</>
	);
}

const initializeType = (type: TaxCriteriaType): TaxCriteria => {
	if (type === TaxCriteriaType.CountyMatch) {
		return { id: "", type: TaxCriteriaType.CountyMatch, county: "" };
	} else if (type === TaxCriteriaType.StateMatch) {
		return { id: "", type: TaxCriteriaType.StateMatch, state: "" };
	} else if (type === TaxCriteriaType.ZipMatch) {
		return { id: "", type: TaxCriteriaType.ZipMatch, zip: "" };
	} else if (type === TaxCriteriaType.CityMatch) {
		return { id: "", type: TaxCriteriaType.CityMatch, city: "" };
	}
	return { id: "", type: TaxCriteriaType.CountyMatch, county: "" };
};

const remainingCriteriaTypes = (excludeTypes: TaxCriteriaType[]): TaxCriteriaType[] => {
	const allTypes = Object.values(TaxCriteriaType).filter((type) => typeof type !== "string") as TaxCriteriaType[];
	return allTypes.filter((type) => !excludeTypes.includes(type));
};

const printTaxCriteriaType = (type: TaxCriteriaType): string => {
	if (type === TaxCriteriaType.CountyMatch) {
		return "County";
	} else if (type === TaxCriteriaType.StateMatch) {
		return "State";
	} else if (type === TaxCriteriaType.ZipMatch) {
		return "Zip";
	} else if (type === TaxCriteriaType.CityMatch) {
		return "City";
	}
	return "Unknown";
};

function AddCriteria(props: { disabled: boolean; excludeTypes?: TaxCriteriaType[]; onAdd: (criteria: TaxCriteria) => void; onCancel: () => void }) {
	const { disabled, excludeTypes } = props;

	const remainingTypes = useMemo(() => remainingCriteriaTypes(excludeTypes ?? []), [excludeTypes]);
	const [criteria, setCriteria] = React.useState<TaxCriteria>(initializeType(remainingTypes[0]));

	const selectType = (event: React.ChangeEvent<HTMLInputElement>) => {
		const typeNumber = parseInt(event.target.value);
		const type = typeNumber as TaxCriteriaType;
		setCriteria(initializeType(type));
	};

	const onStateChange = (event: React.ChangeEvent<HTMLInputElement>) => setCriteria({ id: "", type: TaxCriteriaType.StateMatch, state: event.target.value });

	const onZipChange = (event: React.ChangeEvent<HTMLInputElement>) => setCriteria({ id: "", type: TaxCriteriaType.ZipMatch, zip: event.target.value });

	const onCountyChange = (event: React.ChangeEvent<HTMLInputElement>) => setCriteria({ id: "", type: TaxCriteriaType.CountyMatch, county: event.target.value });

	const onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => setCriteria({ id: "", type: TaxCriteriaType.CityMatch, city: event.target.value });

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				props.onAdd(criteria);
			}}
		>
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<TextField select label="Criteria" variant="outlined" value={criteria.type} onChange={selectType} fullWidth disabled={disabled}>
						{remainingTypes.map((type) => (
							<MenuItem key={type} value={type}>
								Match {printTaxCriteriaType(type)}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item sm={6} xs={12}>
					{criteria.type === TaxCriteriaType.StateMatch && (
						<TextField
							variant="outlined"
							value={criteria.state}
							onChange={onStateChange}
							label="State"
							fullWidth
							disabled={disabled}
							helperText="Don't abbreviate Ex: New York instead of NY"
						/>
					)}
					{criteria.type === TaxCriteriaType.ZipMatch && (
						<TextField variant="outlined" value={criteria.zip} onChange={onZipChange} fullWidth disabled={disabled} label="Zip Code" />
					)}
					{criteria.type === TaxCriteriaType.CountyMatch && (
						<TextField variant="outlined" value={criteria.county} onChange={onCountyChange} fullWidth disabled={disabled} label="County" />
					)}
					{criteria.type === TaxCriteriaType.CityMatch && (
						<TextField variant="outlined" value={criteria.city} onChange={onCityChange} fullWidth disabled={disabled} label="City" />
					)}
				</Grid>
				<Grid item xs={12}>
					<Button variant="outlined" color="secondary" onClick={() => props.onCancel()} style={{ marginRight: 10 }} disabled={disabled}>
						Cancel
					</Button>
					<Button type="submit" variant="contained" color="primary" onClick={() => props.onAdd(criteria)} disabled={disabled}>
						Confirm
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}
