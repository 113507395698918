import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useOfflineStatus } from "../providers/OfflineStatusProvider";

export function useCachedOfflineState<TModel, TSerializeModel>(key: string, initialValue: TModel, serialize: (model: TModel) => TSerializeModel, deserialize: (model: TSerializeModel) => TModel): [TModel, Dispatch<SetStateAction<TModel>>]{
    const isOffline = useOfflineStatus();

    // only retrieve offline data when first render is offline in order to ensure data is fresh 
    const [state, setState] = useState<TModel>(() => {
        try{
            if(isOffline){
                const storedValue = window.localStorage.getItem(key);
                return storedValue != null ? deserialize(JSON.parse(storedValue)) : initialValue;
            }
            return initialValue;
        } catch(e){
            console.error(e);
            return initialValue;
        }
    });

    useEffect(() => {
        try{
            const serializedValue = JSON.stringify(serialize(state));
            window.localStorage.setItem(key, serializedValue);
        } catch(e){
            console.error(e);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])


    return [state, setState];
}