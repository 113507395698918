export enum IntegrationType {
    Qualpay = 1,
    QuickBooksOnline = 2,
    Otodata = 3,
    DispatchProPayments = 4,
}

export enum IntegrationOptionCategory {
    Payment = 1,
    Accounting = 2,
    Monitoring = 3,
}

interface IntegrationConfigurationBase{
    id: string;
}

export interface QualPayIntegration extends IntegrationConfigurationBase {
    type: IntegrationType.Qualpay;
    merchantId: number;
}

export interface DispatchProPaymentsIntegration extends IntegrationConfigurationBase {
    type: IntegrationType.DispatchProPayments;
    merchantId: string;
}

export interface QuickBooksOnlineIntegration extends IntegrationConfigurationBase {
    type: IntegrationType.QuickBooksOnline;
    realmId: string;
}

export interface OtodataIntegration extends IntegrationConfigurationBase {
    type: IntegrationType.Otodata;
    apiKey: string;
}

interface IntegrationOptionBase {
    name: string;
    shortDescription: string;
    description: string;
    smallLogoUrl: string;
    largeLogoUrl: string;
    uriId: string;
    category: IntegrationOptionCategory;
    type: IntegrationType;
    configuration: IntegrationConfigurationBase | null;
}

export interface QualPayIntegrationOption extends IntegrationOptionBase {
    category: IntegrationOptionCategory.Payment;
    type: IntegrationType.Qualpay;
    configuration: QualPayIntegration | null;
}

export interface DispatchProPaymentsIntegrationOption extends IntegrationOptionBase {
    category: IntegrationOptionCategory.Payment;
    type: IntegrationType.DispatchProPayments;
    configuration: DispatchProPaymentsIntegration | null;
}

export interface QuickBooksOnlineIntegrationOption extends IntegrationOptionBase {
    category: IntegrationOptionCategory.Accounting;
    type: IntegrationType.QuickBooksOnline;
    configuration: QuickBooksOnlineIntegration | null;
    sync: boolean;
}

export interface OtodataIntegrationOption extends IntegrationOptionBase {
    category: IntegrationOptionCategory.Monitoring;
    type: IntegrationType.Otodata;
    configuration: OtodataIntegration | null;
}

export type IntegrationOption = QualPayIntegrationOption | QuickBooksOnlineIntegrationOption | OtodataIntegrationOption | DispatchProPaymentsIntegrationOption;