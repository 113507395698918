import React from "react";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { CustomerService } from "../../../services/customer/CustomerService";
import { useAlert } from "../../../hooks/useAlert";
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { GridGrow } from "../../../components/GridGrow";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { CustomerDocument } from "../../../entities/customer/CustomerDocument";
import { EditableField } from "../../../components/SubmitTextField";

export function CustomerDocumentsCard() {
	const alert = useAlert();
	const context = useCustomerDetailPage();
	const { customer, profile } = context;

	const [documents, setDocuments] = React.useState<CustomerDocument[]>(profile.documents);
	const [documentsPerPage] = React.useState(5);
	const [currentPage, setCurrentPage] = React.useState(1);
	const displayDocuments = documents?.slice((currentPage - 1) * documentsPerPage, currentPage * documentsPerPage) ?? [];
	const [disabled, setDisabled] = React.useState(false);
	const [documentPendingDelete, setDocumentPendingDelete] = React.useState<CustomerDocument | null>(null);
	const [documentToEdit, setDocumentToEdit] = React.useState<CustomerDocument | null>(null);
	const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	const onDelete = async () => {
		const document = documentPendingDelete;
		if (!document) return;
		setDisabled(true);
		const result = await CustomerService.removeDocument(document.id);
		setDisabled(false);
		if (result.success) {
			setDocuments((notes) => notes?.filter((n) => n.id !== document.id));
			alert.success("Document Deleted");
			setDocumentPendingDelete(null);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setDisabled(true);
			const result = await CustomerService.uploadDocument(customer.id, file);
			setDisabled(false);
			if (result.success) {
				setDocuments((docs) => [...docs, result.data]);
				alert.success("Document Uploaded");
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		}
	};

	const onUpload = () => {
		const fileInput = document.getElementById("customerDocumentFile");
		if (fileInput) {
			fileInput.click();
		}
	};
	const onDownload = async (document: CustomerDocument) => {
		setDisabled(true);
		const result = await CustomerService.downloadDocument(document);
		setDisabled(false);
		if (!result.success) {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<Box paddingLeft={2} paddingTop={1} paddingRight={2}>
				<Grid container>
					<Grid item>
						<Typography variant="h6">Documents</Typography>
					</Grid>
					<GridGrow />
					<Grid>
						<input type="file" id="customerDocumentFile" style={{ display: "none" }} onChange={onFileChange} />
						<Button size="small" variant="outlined" disabled={disabled} onClick={onUpload}>
							Upload
						</Button>
					</Grid>
				</Grid>
			</Box>
			{documents !== undefined && documents.length === 0 && (
				<Box paddingLeft={2} paddingTop={1} paddingRight={2}>
					<Typography variant="body1" gutterBottom>
						No documents uploaded yet
					</Typography>
				</Box>
			)}
			{documents !== undefined && documents.length > 0 && (
				<>
					<List>
						{displayDocuments.map((document, i) => (
							<React.Fragment key={document.id}>
								{i !== 0 && <Divider />}
								<ListItem button onClick={() => setDocumentToEdit(document)}>
									<ListItemAvatar>
										<IconButton
											onClick={(e) => {
												e.stopPropagation();
												onDownload(document);
											}}
											disabled={disabled}
										>
											<CloudDownloadIcon />
										</IconButton>
									</ListItemAvatar>
									<ListItemText
										primary={document.name}
										secondary={
											<>
												{document.createdAt.toLocaleDateString()} | {document.description}
											</>
										}
										primaryTypographyProps={{
											style: {
												whiteSpace: "pre-wrap",
												wordBreak: "break-word",
											},
										}}
									/>
								</ListItem>
							</React.Fragment>
						))}
					</List>
					<Pagination style={{ margin: 5 }} count={Math.ceil(documents.length / documentsPerPage)} page={currentPage} onChange={handlePageChange} />
				</>
			)}
			<Dialog open={documentPendingDelete != null} onClose={() => setDocumentPendingDelete(null)} fullWidth maxWidth="xs">
				<DialogTitle>Are you sure you want to delete this document?</DialogTitle>
				<DialogContent>
					<Typography>This document will be permanently deleted and cannot be recovered.</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDocumentPendingDelete(null)} disabled={disabled}>
						Cancel
					</Button>
					<Button onClick={onDelete} color="primary" variant="contained" disabled={disabled}>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<EditDocumentDialog
				document={documentToEdit}
				onClose={() => setDocumentToEdit(null)}
				onChange={(doc) => {
					setDocuments((docs) => docs.map((d) => (d.id === doc.id ? doc : d)));
					setDocumentToEdit(null);
				}}
				onDelete={(doc) => {
					setDocumentPendingDelete(doc);
					setDocumentToEdit(null);
				}}
			/>
		</Card>
	);
}

function EditDocumentDialog(props: {
	document: CustomerDocument | null;
	onClose: () => void;
	onChange: (document: CustomerDocument) => void;
	onDelete: (document: CustomerDocument) => void;
}) {
	const { document } = props;
	const alert = useAlert();

	const onDeleteClick = () => {
		if (document) {
			props.onDelete(document);
		}
	};

	const onDownloadClick = async () => {
		if (!document) return;
		const result = await CustomerService.downloadDocument(document);
		if (!result.success) {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={document != null} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Edit Document</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<List>
							<ListItem>
								<ListItemText primary="Created" secondary={document?.createdAt.toLocaleDateString()} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Uploaded By" secondary={document?.uploadedBy} />
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12} sm={6}>
						<List>
							<ListItem>
								<ListItemText primary="Size" secondary={document?.size} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Type" secondary={document?.description} />
							</ListItem>
						</List>
					</Grid>

					<Grid item xs={12}>
						<EditableField<CustomerDocument>
							type="text"
							value={document?.name ?? ""}
							fullWidth
							update={{
								request: (name) => CustomerService.updateDocument({ id: document?.id ?? "", name }),
								onSuccess: props.onChange,
								successMessage: "Document updated",
								required: true,
							}}
							view={(onEdit) => (
								<List>
									<ListItem button onClick={onEdit}>
										<ListItemText primary="Name" secondary={document?.name} secondaryTypographyProps={{ color: "primary" }} />
									</ListItem>
								</List>
							)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDeleteClick} color="secondary" variant="outlined">
					Delete
				</Button>
				<GridGrow />
                <Button onClick={props.onClose} color="default" variant="outlined">
                    Close
                </Button>
				<Button onClick={onDownloadClick} color="primary" variant="contained"  startIcon={<CloudDownloadIcon />}>
					Download
				</Button>
			</DialogActions>
		</Dialog>
	);
}
