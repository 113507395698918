import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { fade, InputBase, makeStyles, Theme } from "@material-ui/core";

interface Props {
	value: string;
	placeholder?: string;
	onChange: (value: string) => void;
	autoFocus?: boolean | undefined;
	onEnter?: () => void;
}

export function SearchTextField(props: Props) {
	const classes = useStyles();

	return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				value={props.value}
				placeholder={props.placeholder ?? "Search..."}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				inputProps={{ "aria-label": "search", autoComplete: "off", spellCheck: "false" }}
				onChange={(e) => props.onChange(e.target.value)}
				autoFocus={props.autoFocus}
				onKeyDown={(e) => {
					if (e.key === "Enter" && props.onEnter) {
						props.onEnter();
					}
				}}
			/>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.background.paper, 0.7),
		"&:hover": {
			backgroundColor: fade(theme.palette.background.paper, 0.9),
		},
		marginLeft: 0,
		marginRight: 5,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "auto",
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "13ch",
			"&:focus": {
				width: "20ch",
			},
		},
	},
}));
