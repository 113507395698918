import React, { useEffect } from "react";

export const PageTitleContext = React.createContext<[string, (title: string) => void]>(["Dispatch Pro", () => {}]);

export function usePageTitle() {
	return React.useContext(PageTitleContext);
}

export function PageTitle(props: { title: string }) {
	const title = props.title;
	const [, setPageTitle] = usePageTitle();

	useEffect(() => {
		setPageTitle(title);
		document.title = title;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [title]);

	return null;
}
