import React from 'react';

export function TimeBetween(props: { start: Date, end: Date }) {
    const { start, end } = props;
    const diff = Math.abs(end.getTime() - start.getTime());
    const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    if(years > 0) {
        return <>{years} year{years > 1 && "s"}</>;
    }

    const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    if(months > 0) {
        return <>{months} month{months > 1 && "s"}</>;
    }

    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if(days > 0) {
        return <>{days} day{days > 1 && "s"}</>;
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    if(hours > 0) {
        return <>{hours} hour{hours > 1 && "s"}</>;
    }

    const minutes = Math.floor(diff / (1000 * 60));
    if(minutes > 0) {
        return <>{minutes} minute{minutes > 1 && "s"}</>;
    }

    const seconds = Math.floor(diff / (1000));
    if(seconds > 0) {
        return <>{seconds} second{seconds > 1 && "s"}</>;
    }

    return null;
}
