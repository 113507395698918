import { Box, Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useTicketDetail } from "./DriverTicketDetailView";
import { useAlert } from "../../../hooks/useAlert";

export function TicketNumber() {
	const alert = useAlert();
	const context = useTicketDetail();
	const { ticket, disabled, setDisabled, updateTicket } = context;

	const [editMode, setEditMode] = React.useState(false);
	const [ticketNumber, setTicketNumber] = React.useState(ticket.ticketNumber);

	const updateTicketNumber = async (ticketNumber: string | null) => {
		setDisabled(true);
		const result = await DriverTicketService.update({ driverTicketId: ticket.id, ticketNumber });
		setDisabled(false);

		if (result.success) {
			updateTicket(result.data);
			alert.success("Ticket number updated");
			setEditMode(false);
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	return (
		<Box>
			<Button
				color="primary"
				disabled={disabled}
				variant="text"
				style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }}
				onClick={() => setEditMode(true)}
			>
				Ticket Number:
			</Button>

			{editMode && (
				<>
					<TextField
						label="Ticket Number"
						variant="outlined"
						fullWidth
						autoFocus
						disabled={disabled}
						style={{ maxWidth: 250 }}
						value={ticketNumber}
						onChange={(event) => setTicketNumber(event.target.value)}
						onKeyDown={(event) => {
							if (event.key === "Backspace" && ticketNumber === "") {
								updateTicketNumber(null);
							}

							if (event.key === "Escape") {
								event.stopPropagation();
								setEditMode(false);
							}

							if (event.key === "Enter") {
								event.stopPropagation();
								updateTicketNumber(ticketNumber);
							}
						}}
						onBlur={() => updateTicketNumber(ticketNumber)}
					/>
				</>
			)}
			{!editMode && (
				<Typography variant="h6" color={ticket.ticketNumber ? "textPrimary" : "textSecondary"}>
					{ticket.ticketNumber ?? "None"}
				</Typography>
			)}
		</Box>
	);
}
