export interface CustomerDocument {
    id: string;
    customerId: string;
    name: string;
    contentType: string;
    description: string;
    size: string;
    uploadedBy: string;
    createdAt: Date;
}

export type SerializedCustomerDocument = Omit<CustomerDocument, 'createdAt'> & { createdAt: string };

export const deserializeCustomerDocument = (document: SerializedCustomerDocument): CustomerDocument => ({
    ...document,
    createdAt: new Date(document.createdAt),
});

export const deserializeCustomerDocuments = (documents: SerializedCustomerDocument[]): CustomerDocument[] => documents.map(deserializeCustomerDocument);