import { Box, Dialog, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { GridGrow } from "../../components/GridGrow";
import { HighlightedText } from "../../components/HighlightedText";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { PageTitle } from "../../components/PageTitle";
import { SearchTextField } from "../../components/SearchTextField";
import { formatAddress } from "../../entities/customer/GeocodedAddress";
import { Driver } from "../../entities/routing/Driver";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { useAlert } from "../../hooks/useAlert";
import { DriverTicketService, PendingTickets } from "../../services/routing/DriverTicketService";
import { DriverTicketDetailView } from "./ticket-detail/DriverTicketDetailView";

function updatePendingTickets(pendingTickets: PendingTickets | undefined, ticket: DriverTicket): PendingTickets | undefined {
	if (!pendingTickets) return pendingTickets;
	if (ticket.timeOfCompletion) {
		return {
			driverTickets: pendingTickets.driverTickets.map((dt) => ({ ...dt, tickets: dt.tickets.filter((t) => t.id !== ticket.id) })),
			unassignedTickets: pendingTickets.unassignedTickets.filter((t) => t.id !== ticket.id),
		};
	}
	return {
		driverTickets: pendingTickets.driverTickets.map((dt) => ({ ...dt, tickets: dt.tickets.map((t) => (t.id === ticket.id ? ticket : t)) })),
		unassignedTickets: pendingTickets.unassignedTickets.map((t) => (t.id === ticket.id ? ticket : t)),
	};
}

function searchTickets(tickets: DriverTicket[], searchText: string): DriverTicket[] {
    const query = searchText.toLowerCase().trim();
    if (!query) return tickets;
    return tickets.filter((t) => {
        const address = formatAddress(t.location);
        return (
            t.customer.name.toLowerCase().includes(query) ||
            t.instructions.toLowerCase().includes(query) ||
            address.toLowerCase().includes(query) ||
            (t.driverName && t.driverName.toLowerCase().includes(query))
        );
    });
}

export function ReviewDeliveredTicketsPage() {
	const alert = useAlert();
    const theme = useTheme();

	const [pendingTickets, setPendingTickets] = React.useState<PendingTickets>();
	const [selectedTicket, setSelectedTicket] = React.useState<DriverTicket>();
    const [searchText, setSearchText] = React.useState("");

	React.useEffect(() => {
		async function fetchDriverTickets() {
			const result = await DriverTicketService.getPendingTickets();
			if (result.success) {
				setPendingTickets(result.data);
			} else {
				alert.serverError(result);
			}
		}
		fetchDriverTickets();
	}, [alert]);

	const onUpdate = (ticket: DriverTicket) => {
		setSelectedTicket(ticket);
		setPendingTickets((pt) => updatePendingTickets(pt, ticket));
	};

	if (!pendingTickets) {
		return <OverlayLoadingScreen />;
	}

	return (
		<>
			<PageTitle title="Review Delivered Tickets" />
            <Grid container>
                <Grid item>
                    <Typography variant="h4">Delivered Tickets</Typography>
                </Grid>
                <GridGrow/>
                <Grid item>
                    <SearchTextField value={searchText} onChange={setSearchText} autoFocus />
                </Grid>
            </Grid>
			{pendingTickets.driverTickets.length === 0 && pendingTickets.unassignedTickets.length === 0 && <Typography variant="h6">No tickets to review</Typography>}
            {pendingTickets.driverTickets.map((dt) => (
                <PendingDriverTickets key={dt.driver.id} driver={dt.driver} tickets={dt.tickets} searchText={searchText} onSelect={setSelectedTicket} />
            ))}
            <PendingDriverTickets driver={null} tickets={pendingTickets.unassignedTickets} searchText={searchText} onSelect={setSelectedTicket} />
            <Dialog open={selectedTicket !== undefined} onClose={() => setSelectedTicket(undefined)} fullScreen PaperProps={{
                style: {
                    backgroundColor: theme.palette.background.default,
                    boxShadow: "none",
                },
            }}>
                {selectedTicket && <DriverTicketDetailView ticket={selectedTicket} updateTicket={onUpdate} onBack={() => setSelectedTicket(undefined)} />}
            </Dialog>
		</>
	);
}

function PendingDriverTickets(props: { driver: Driver | null; tickets: DriverTicket[]; searchText: string, onSelect: (ticket: DriverTicket) => void }) {
	const { driver, tickets, searchText } = props;

    const filteredTickets = searchTickets(tickets, searchText);

    if(filteredTickets.length === 0) return null;

	return (
		<Box m={2} mb={4}>
			<Typography variant="h6">{driver ? <HighlightedText query={searchText}>{driver.name}</HighlightedText> : "Unassigned"}</Typography>
			<TableContainer component={Paper}>
            <Table>
				<TableHead>
					<TableRow>
						<TableCell width={125}>Delivered</TableCell>
						<TableCell width={150}>Customer</TableCell>
						<TableCell width={325}>Address</TableCell>
						<TableCell>Instructions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredTickets.map((ticket) => (
						<TableRow key={ticket.id} hover onClick={() => props.onSelect(ticket)}>
							<TableCell width={125}>{ticket.timeOfDelivery?.toLocaleString()}</TableCell>
							<TableCell width={150}><HighlightedText query={searchText}>{ticket.customer.name}</HighlightedText></TableCell>
							<TableCell width={325}><HighlightedText query={searchText}>{formatAddress(ticket.location)}</HighlightedText></TableCell>
							<TableCell><HighlightedText query={searchText}>{ticket.instructions}</HighlightedText></TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
            </TableContainer>
		</Box>
	);
}
