import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { SelectTagDialog } from "../dialog/SelectTagDialog";
import { Tag } from "../../../entities/customer/Tag";
import { TagBubble } from "../../../components/TagBubble";
import { useCreateTicketContext } from "./NewCreateTicketPage";

export function AddTagSection() {
	const context = useCreateTicketContext();
	const { tags, setTags } = context;

	const [showSelectTag, setShowSelectTag] = React.useState(false);

	const onAddTag = (tag: Tag) => {
		setTags(tags => [...tags, tag]);
		setShowSelectTag(false);
	};

	const onRemoveTag = (tag: Tag) => {
		setTags(tags => tags.filter((t) => t.id !== tag.id));
	};

	return (
		<>
			<SelectTagDialog open={showSelectTag} type="Driver Ticket" onClose={() => setShowSelectTag(false)} onTagSelected={onAddTag} disableTags={tags} />
			<Grid container alignItems="center" spacing={1}>
				<Grid item>
					<Button color="primary" variant="text" style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }} onClick={() => setShowSelectTag(true)}>
						Tags:
					</Button>
				</Grid>
				{
					tags.length === 0 && (
						<Grid item>
							<Typography variant="h6" color="textSecondary">
								None
							</Typography>
						</Grid>
					)
				}
				{tags.map((t) => (
					<Grid key={t.id} item>
						<TagBubble tag={t} onDelete={onRemoveTag} />
					</Grid>
				))}
			</Grid>
		</>
	);
}
