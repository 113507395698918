import { Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { getTagColorTheme, Tag } from "../entities/customer/Tag";

interface Props {
	tag: Tag;
}

export function TagText(props: Props) {
	const { tag } = props;
	const { palette } = useTheme<Theme>();

	const { background, text } = getTagColorTheme(tag.color, palette.type);


	return (
		<Typography style={{ color: text, backgroundColor: background, borderRadius: 5

         }} align="center" variant="body2">
            {tag.text}
        </Typography>
	);
}
