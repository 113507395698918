import { Modify } from "../../utility/modifyType";


export interface GasRegulator {
    id: string;
    tankId: string;
    serialNumber: string;
    type: string;
    dateInstalled: Date;
    dateRemoved: Date | null;
    notes: string;
}

export type SerializedGasRegulator = Modify<GasRegulator, {
    dateInstalled: string;
    dateRemoved: string | null;
}>;

export const deserializeGasRegulator = (serverModel: SerializedGasRegulator): GasRegulator => {
    return {
        ...serverModel,
        dateInstalled: new Date(serverModel.dateInstalled),
        dateRemoved: serverModel.dateRemoved ? new Date(serverModel.dateRemoved) : null
    }
}
