import { Button, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { ManualAddress } from "../../entities/customer/ManualAddress";
import { FieldValidationError, validationError } from "../../services/server/ServerValidationError";
import { LatLongMapPicker, PickedLocation } from "./LatLongMapPicker";
import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";
import { useTenant } from "../../providers/TenantProvider";

interface ManualAddressFormViewProps {
	address?: GeocodedAddress;
	onCancel: () => void;
	onSubmit: (address: ManualAddress) => void;
}

export function ManualAddressFormView(props: ManualAddressFormViewProps) {
	const { address } = props;
	const theme = useTheme();
	const smWidth = useMediaQuery(theme.breakpoints.up("sm"));

	const tenantContext = useTenant();
	const tenantAddress = tenantContext.loadStatus === "ready" ? tenantContext.tenant.officeAddress : undefined;
	const startAddress = address ?? tenantAddress;

	const startLocation = startAddress && startAddress.latitude && startAddress.longitude ? { lat: startAddress.latitude, lng: startAddress.longitude } : undefined;

	const [latLng, setLatLng] = useState<{ latitude: number; longitude: number } | undefined>(
		address ? { latitude: address.latitude, longitude: address.longitude } : undefined
	);

	const [street, setStreet] = useState(address?.street ?? "");
	const [city, setCity] = useState(address?.city ?? "");
	const [state, setState] = useState(address?.state ?? "");
	const [postalCode, setPostalCode] = useState(address?.postalCode ?? "");
	const [county, setCounty] = useState(address?.county ?? "");

	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	const onAddressSelected = (location: PickedLocation) => {
		const { latitude, longitude, city, state, postalCode, county } = location;
		setLatLng({ latitude, longitude });

		if (city) {
			setCity(city);
		}
		if (state) {
			setState(state);
		}
		if (postalCode) {
			setPostalCode(postalCode);
		}
		if (county) {
			setCounty(county);
		}
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const errors: FieldValidationError[] = [];

		if (!street.trim()) {
			errors.push({ field: "Street", errors: ["Street required"] });
		}
		if (!city.trim()) {
			errors.push({ field: "City", errors: ["City required"] });
		}
		if (!state.trim()) {
			errors.push({ field: "State", errors: ["State required"] });
		}
		if (!postalCode.trim()) {
			errors.push({ field: "Zip Code", errors: ["Zip Code required"] });
		}
		if (!county.trim()) {
			errors.push({ field: "County", errors: ["County required"] });
		}
		if (!latLng) {
			errors.push({ field: "latLng", errors: ["Must select address location on the map"] });
		}

		if (errors.length > 0 || !latLng) {
			setValidationErrors(errors);
			return;
		}

		const address: ManualAddress = {
			street,
			city,
			state,
			postalCode,
			county,
			latitude: latLng.latitude,
			longitude: latLng.longitude,
		};

		props.onSubmit(address);
	};

	return (
		<form onSubmit={onSubmit}>
			<DialogTitle style={{ paddingBottom: 0 }}>Manual Address Entry</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle2">Select Location on Map</Typography>
				<LatLongMapPicker height={smWidth ? 500 : 400} geoCodeResult onSelect={onAddressSelected} startLocation={startLocation} />
				<div>
					<Typography variant="caption">
						Selected location: {latLng ? `Latitude: ${latLng.latitude.toFixed(4)} Longitude: ${latLng.longitude.toFixed(4)}` : "Unset"}
					</Typography>
					<FormHelperText error>{validationError.getFieldError(validationErrors, "latLng")}</FormHelperText>
				</div>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<TextField
							required
							fullWidth
							label="Street"
							value={street}
							onChange={(e) => setStreet(e.target.value)}
							error={validationError.isFieldInError(validationErrors, "Street")}
							helperText={validationError.getFieldError(validationErrors, "Street")}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							required
							fullWidth
							label="City"
							value={city}
							onChange={(e) => setCity(e.target.value)}
							error={validationError.isFieldInError(validationErrors, "City")}
							helperText={validationError.getFieldError(validationErrors, "City")}
						/>
					</Grid>
					<Grid item sm={3} xs={6}>
						<TextField
							required
							fullWidth
							label="State"
							value={state}
							onChange={(e) => setState(e.target.value)}
							error={validationError.isFieldInError(validationErrors, "State")}
							helperText={validationError.getFieldError(validationErrors, "State")}
						/>
					</Grid>
					<Grid item sm={3} xs={6}>
						<TextField
							required
							fullWidth
							label="Zip Code"
							value={postalCode}
							onChange={(e) => setPostalCode(e.target.value)}
							error={validationError.isFieldInError(validationErrors, "Zip Code")}
							helperText={validationError.getFieldError(validationErrors, "Zip Code")}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							required
							fullWidth
							label="County"
							value={county}
							onChange={(e) => setCounty(e.target.value)}
							error={validationError.isFieldInError(validationErrors, "County")}
							helperText={validationError.getFieldError(validationErrors, "County")}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel}>Cancel</Button>
				<Button type="submit">Add Address</Button>
			</DialogActions>
		</form>
	);
}
