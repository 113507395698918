import * as React from "react";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { blue, lightBlue, red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useLocalStorage } from "../utility/useLocalStorage";
import { AppEnv, getAppEnv } from "../AppEnv";

const typography = {
	typography: {
		h1: {
			fontSize: 50
		},
		h2: {
			fontSize: 35
		},
		h3: {
			fontSize: 25
		}
	}
}

const lightTheme = createMuiTheme({
	palette: {
		type: "light",
		primary: {
			main: getAppEnv() === AppEnv.Dev ? "#ff9800" :lightBlue[700],
		},
		secondary: {
			main: getAppEnv() === AppEnv.Dev ? "#ff3d00" : "#F44336",
			light: "#FF5252",
		},
		background: {
			default: "#f3f3f3",
		},
	},
	overrides:{
		MuiTooltip:{
			tooltip:{
				fontSize: "1rem"
			}
		},
		MuiTableRow:{
			root: {
				'&.Mui-selected': {
					backgroundColor: lightBlue[50],
				},
				'&.Mui-selected:hover': {
					backgroundColor: lightBlue[50],
				}
			}
		}
	},
	...typography
});

const darkTheme = createMuiTheme({
	palette: {
		type: "dark",
		primary: {
			main: getAppEnv() === AppEnv.Dev ? "#ff9800" : "#6683C4",
			light: "#64b5f6",
		},
		secondary: {
			main: getAppEnv() === AppEnv.Dev ? "#ff3d00" : "#993A3A",
		},
	},
	...typography,
	overrides:{
		MuiButton:{
			outlinedSecondary:{
				color: red[300],
				borderColor: red[300],
			},
			outlinedPrimary:{
				color: blue[300],
				borderColor: blue[300],
			},
			textPrimary:{
				color: blue[300],
			}
		},
		MuiTooltip:{
			tooltip:{
				fontSize: "1rem"
			}
		},
		MuiTableRow:{
			root: {
				'&.Mui-selected': {
					backgroundColor: "rgba(0, 0, 0, 0.62)",
				},
				'&.Mui-selected:hover': {
					backgroundColor: "rgba(0, 0, 0, 0.62)",
				},
			  }
		}
	}
});

type ThemeMode = "light" | "dark" | "system";
type ThemeName = "light" | "dark";

export const AppThemeContext = React.createContext<[ThemeName, (theme: ThemeName) => void]>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{} as any
);

type Props = {};

const toThemeName = (mode: ThemeMode, prefersDarkMode: boolean): ThemeName =>{
	if(mode === "system"){
		return prefersDarkMode ? "dark" : "light";
	}
	return mode;
}

export function AppThemeProvider(props: React.PropsWithChildren<Props>) {
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const [themeMode, setThemeMode] = useLocalStorage<ThemeMode>("PreferredAppTheme", "system");

	const [themeName, setThemeName] = React.useState(toThemeName(themeMode, prefersDarkMode));
	const [theme, setTheme] = React.useState(themeName === "dark" ? darkTheme : lightTheme);

	React.useEffect(() => {
		const name = toThemeName(themeMode, prefersDarkMode); 
		setThemeName(name);
		setTheme(name === "dark" ? darkTheme : lightTheme);	
	}, [prefersDarkMode, themeMode])

	const onChangeTheme = (theme: ThemeName) => {
		setThemeMode(theme);
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<AppThemeContext.Provider value={[themeName, onChangeTheme]}>{props.children}</AppThemeContext.Provider>
		</ThemeProvider>
	);
}

export default AppThemeProvider;
