interface GeolocationCoordinates {
    readonly accuracy: number;
    readonly altitude: number | null;
    readonly altitudeAccuracy: number | null;
    readonly heading: number | null;
    readonly latitude: number;
    readonly longitude: number;
    readonly speed: number | null;
}

export const Geolocation = {
    requestLocation: (resultCallback: (result: string | GeolocationCoordinates) => void ) => {
        // Try HTML5 geolocation.
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resultCallback(position.coords)
          },
          (e) => {
            resultCallback(e.message)
          }
        );
      } else {
        resultCallback("This browser doesn't support Geolocation")
      }
    }
}