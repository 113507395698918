import React, { useEffect } from "react";
import { Card, CardHeader, CardContent, TextField, MenuItem, FormHelperText } from "@material-ui/core";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { TankFillConfiguration } from "../../entities/routing/TankFillConfiguration";
import { useAlert } from "../../hooks/useAlert";
import { TankFillConfigurationService } from "../../services/routing/TankFillConfigurationService";
import { usePropaneTankDetailPage } from "./PropaneTankDetailPage";
import { CreateScheduledFillCardView, UpdateScheduledFillCard } from "./ScheduledFillCardViews";

export function TankFillConfigurationCard() {
	const context = usePropaneTankDetailPage();
	const { propaneTank } = context;
	const propaneTankId = propaneTank.id;
	const alert = useAlert();
	const [tankFillConfiguration, setTankFillConfiguration] = React.useState<TankFillConfiguration | null>();

	useEffect(() => {
		async function fetch() {
			const result = await TankFillConfigurationService.getByTank(propaneTankId);
			if (result.success) setTankFillConfiguration(result.data);
			else alert.serverError(result);
		}
		fetch();
	}, [alert, propaneTankId]);

	if (tankFillConfiguration === undefined) {
		return (
			<Card style={{ minWidth: 400 }}>
				<CenteredLoadingSpinner />
			</Card>
		);
	}

	if (tankFillConfiguration === null) return <UnconfiguredTankFillConfigurationCard onCreate={setTankFillConfiguration} />;

	if (tankFillConfiguration.type === "ScheduledFill") return <UpdateScheduledFillCard config={tankFillConfiguration} onUpdate={setTankFillConfiguration} />;

	return null;
}

function UnconfiguredTankFillConfigurationCard(props: { onCreate: (config: TankFillConfiguration) => void }) {
	const [selectType, setSelectType] = React.useState<"None" | "Schedule">("None");

	return (
		<Card style={{maxWidth: 455}}>
			<CardHeader title="Setup Tank Alert" />
			<CardContent>
				<TextField label="Alert Type" select variant="outlined" fullWidth value={selectType}>
					<MenuItem value="None" onClick={() => setSelectType("None")}>
						None
					</MenuItem>
					<MenuItem value="Schedule" onClick={() => setSelectType("Schedule")}>
						Schedule
					</MenuItem>
				</TextField>
				<FormHelperText>Select the way this tank is filled and receive alerts when {"it's"} time to deliver</FormHelperText>
			</CardContent>
			{selectType === "Schedule" && <CreateScheduledFillCardView onCreate={props.onCreate} />}
		</Card>
	);
}
