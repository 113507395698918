import React, { useEffect } from "react";
import { Box, FormControlLabel, Grid, Paper, Switch, Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, Typography, useTheme } from "@material-ui/core";
import { GridGrow } from "../../components/GridGrow";
import { SearchTextField } from "../../components/SearchTextField";
import { SortableTableHeader, TableHeader } from "../../components/table/SortableTableHeader";
import { orderBy } from "../../utility/orderBy";
import { Enum } from "../../utility/Enum";
import { DeviceStatus, OtodataDevice } from "../../entities/routing/OtodataTankMonitor";
import { useAlert } from "../../hooks/useAlert";
import { TankMonitorService } from "../../services/routing/TankMonitorService";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes";
import { useLocalStorage } from "../../utility/useLocalStorage";

export function OtodataDevicesListView(props: {assignedOtodataDeviceIds: Set<number> }) {
	const { assignedOtodataDeviceIds } = props;
	console.log(assignedOtodataDeviceIds);
	const alert = useAlert();
	const [devices, setDevices] = React.useState<OtodataDevice[]>();

	useEffect(() => {
		async function fetch() {
			const result = await TankMonitorService.getOtodataDevices();
			if (result.success) {
				setDevices(result.data);
			} else {
				alert.serverError(result);
			}
		}
		fetch();
	}, [alert]);

	if (!devices) {
		return <CenteredLoadingSpinner />;
	}

	return <DeviceListView devices={devices} assignedOtodataDeviceIds={assignedOtodataDeviceIds} />;
}

type OrderByOptions = "Name" | "Level" | "Last Fill" | "Status";

function applySearchFilter(devices: OtodataDevice[], query: string): OtodataDevice[] {
	if (query === "") {
		return devices;
	}

	return devices.filter((device) => {
		if (device.name && device.name.toLowerCase().includes(query)) {
			return true;
		}

		if (device.id.toString().startsWith(query)) {
			return true;
		}

		if (device.lastFill?.toLocaleDateString().includes(query)) {
			return true;
		}

		if (Enum.print(DeviceStatus, device.status).toLowerCase().includes(query)) {
			return true;
		}

		return false;
	});
}

function applySort(devices: OtodataDevice[], orderedBy: OrderByOptions, order: "asc" | "desc"): OtodataDevice[] {
	console.log(orderedBy, order);
	if (orderedBy === "Name" && order === "asc") {
		return devices.sort(orderBy.optional.string((d) => d.name, order, "optionalEnd"));
	}
	if (orderedBy === "Level") {
		return devices.sort(orderBy.number((d) => d.lastLevel, order));
	}
	if (orderedBy === "Last Fill") {
		return devices.sort(orderBy.date((d) => d.lastFill ?? new Date(0), order));
	}
	if (orderedBy === "Status") {
		return devices.sort(orderBy.string((d) => Enum.print(DeviceStatus, d.status), order));
	}
	return devices;
}

function DeviceListView(props: { devices: OtodataDevice[]; assignedOtodataDeviceIds: Set<number> }) {
	const { devices, assignedOtodataDeviceIds} = props;
	const theme = useTheme();
	const secondaryTextColor = theme.palette.text.secondary;
    const history = useHistory();

	const [searchText, setSearchText] = useLocalStorage("otodata-searchText", "");
	const [order, setOrder] = React.useState<"asc" | "desc">("asc");
	const [orderBy, setOrderBy] = useLocalStorage<OrderByOptions>("otodata-orderBy", "Name");
	const [showUninstalled, setShowUninstalled] = useLocalStorage("otodata-showUninstalled", false);
	const [hideAssigned, setHideAssigned] = useLocalStorage("otodata-hideAssigned", false);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const query = React.useMemo(() => searchText.toLowerCase().trim(), [searchText]);
	const filteredDevices = React.useMemo(() => {
		let result = devices;
		if (hideAssigned) {
			result = result.filter((d) => !assignedOtodataDeviceIds.has(d.id));
		}
		if (!showUninstalled) {
			result = result.filter((d) => d.status !== DeviceStatus.Uninstalled);
		}
		return result;
	}, [devices, showUninstalled, hideAssigned, assignedOtodataDeviceIds]);
	const searchedDevices = React.useMemo(() => applySearchFilter(filteredDevices, query), [filteredDevices, query]);
	const sortedDevices = React.useMemo(() => applySort([...searchedDevices], orderBy, order), [searchedDevices, orderBy, order]);
	const paginatedDevices = React.useMemo(() => {
		const start = page * rowsPerPage;
		const end = start + rowsPerPage;
		return sortedDevices.slice(start, end);
	}, [sortedDevices, page, rowsPerPage]);

	const onSortChanged = (property: string, order: "asc" | "desc") => {
		console.log(property, order);
		if (property !== "Name" && property !== "Level" && property !== "Last Fill" && property !== "Status") {
			return;
		}
		setOrderBy(property);
		setOrder(order);
	};

	const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearchChange = (value: string) => {
		setSearchText(value);
		setPage(0);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item>
					<Typography variant="h5">Otodata Monitors</Typography>
				</Grid>
				<GridGrow />
				<Grid item>
					<FormControlLabel control={<Switch checked={hideAssigned} onChange={() => setHideAssigned(!hideAssigned)} />} label="Hide Assigned" />
				</Grid>
				<Grid item>
					<FormControlLabel control={<Switch checked={showUninstalled} onChange={() => setShowUninstalled(!showUninstalled)} />} label="Show Uninstalled" />
				</Grid>
				<Grid item>
					<SearchTextField value={searchText} onChange={handleSearchChange} />
				</Grid>
				<Grid item xs={12}>
					<Paper>
						<Table>
							<SortableTableHeader order={order} orderBy={orderBy}>
								<TableHeader property="Name" onSort={onSortChanged}>
									Name
								</TableHeader>
								<TableHeader property="Level" onSort={onSortChanged}>
									Level
								</TableHeader>
								<TableHeader property="Last Fill" onSort={onSortChanged}>
									Last Fill
								</TableHeader>
								<TableHeader property="Status" onSort={onSortChanged}>
									Status
								</TableHeader>
								<TableHeader>Id</TableHeader>
							</SortableTableHeader>
							<TableBody>
								{paginatedDevices.map((device) => (
									<TableRow key={device.id} hover onClick={() => {history.push(routes.app.resolve.otodataTankMonitorDetailPage(device.id))}}>
										<TableCell>
											<Box color={device.name == null ? secondaryTextColor : undefined}>{device.name ?? "Unset"}</Box>
										</TableCell>
										<TableCell>{device.lastLevel}%</TableCell>
										<TableCell>{device.lastFill?.toLocaleDateString() ?? "Never"}</TableCell>
										<TableCell>{Enum.print(DeviceStatus, device.status)}</TableCell>
										<TableCell>{device.id}</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 15, 20]}
										colSpan={6}
										count={sortedDevices.length}
										rowsPerPage={rowsPerPage}
										labelRowsPerPage={"Show"}
										page={page}
										onChangePage={handleChangePage}
										onChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}
