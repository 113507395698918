import React from "react";
import { FetchOptionalResult } from "../../../services/server/WebClient";
import { DispatchProInvoice } from "../../../entities/admin/DispatchProInvoice";
import { BusinessService } from "../../../services/business/BusinessService";
import { useParams } from "react-router-dom";
import { LoadingOverlay } from "@material-ui/data-grid";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { Box, Card, Grid, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { BackButton } from "../../../components/BackButton";
import { Price } from "../../../components/Price";
import { Money } from "../../../utility/Money";

export function PlanInvoicePage() {
	const params = useParams<{ invoiceId?: string }>();
	const invoiceId = Number(params.invoiceId ?? 0);
	const [invoicesResult, setInvoicesResult] = React.useState<FetchOptionalResult<DispatchProInvoice>>();

	React.useEffect(() => {
		async function fetchInvoices() {
			const result = await BusinessService.getPlanInvoice(invoiceId);
			setInvoicesResult(result);
		}
		fetchInvoices();
	}, [invoiceId]);

	if (!invoicesResult) return <LoadingOverlay />;
	if (invoicesResult.type !== "success") return <ServerErrorView serverError={invoicesResult} />;
	return <PlanInvoiceView invoice={invoicesResult.data} />;
}

function PlanInvoiceView(props: { invoice: DispatchProInvoice }) {
	const { invoice } = props;

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item>
				<BackButton />
			</Grid>
			<Grid item>
				<Typography variant="h2">Invoice {invoice.id}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item lg={8} md={10} sm={11} xs={12}>
						<Card>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<List>
										<ListItem>
											<ListItemText primary="Invoice #" secondary={invoice.id} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Issued" secondary={invoice.invoiceDate.toLocaleDateString()} />
											<ListItemText primary="Due By" secondary={invoice.dueDate.toLocaleDateString()} />
										</ListItem>
									</List>
								</Grid>
								<Grid item xs={6}>
									<List>
										<ListItem>
											<ListItemText primary="Status" secondary={invoice.balance.isZero() ? "Paid" : "Unpaid"} />
											<ListItemText primary="Balance" secondary={<Price value={invoice.balance} />} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Total" secondary={<Price value={invoice.total} />} />
											<ListItemText primary="Amount Paid" secondary={<Price value={invoice.amountPaid} />} />
										</ListItem>
									</List>
								</Grid>
								<Grid item xs={12}>
									<Box p={2}>
										<Typography variant="h6">Line Items</Typography>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Name</TableCell>
													<TableCell>Price</TableCell>
													<TableCell>Quantity</TableCell>
													<TableCell>Total</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{invoice.lineItems.map((item, index) => (
													<TableRow key={index}>
														<TableCell>{item.name}</TableCell>
														<TableCell>
															<Price value={Money.fromDecimal(item.price)} />
														</TableCell>
														<TableCell>{item.quantity}</TableCell>
														<TableCell>
															<Price value={Money.fromDecimal(item.total)} />
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Box p={2}>
										<Typography variant="h6">Payments</Typography>
										{invoice.payments.length === 0 && <Typography>No payments yet.</Typography>}
										{invoice.payments.length > 0 && (
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Date</TableCell>
														<TableCell>Amount</TableCell>
														<TableCell>Description</TableCell>
														<TableCell>Reference</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{invoice.payments.map((payment, index) => (
														<TableRow key={index}>
															<TableCell>{payment.paymentDate.toLocaleDateString()}</TableCell>
															<TableCell>
																<Price value={Money.fromDecimal(payment.amount)} />
															</TableCell>
															<TableCell>{payment.description}</TableCell>
															<TableCell>{payment.reference}</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										)}
									</Box>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
