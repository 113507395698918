import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../components/BackButton";
import { GridGrow } from "../../../components/GridGrow";
import { NumberTextField } from "../../../components/NumberTextField";
import { PercentTextField } from "../../../components/PercentTextField";
import { PriceTextField } from "../../../components/PriceTextField";
import { PaymentTerms } from "../../../entities/billing/PaymentTerms";
import { useAlert } from "../../../hooks/useAlert";
import { PaymentTermsService } from "../../../services/billing/PaymentTermsService";
import { usePaymentTermsPage } from "./PaymentTermsPage";
import { AddOverdueFeeDialog } from "./AddOverdueFeeDialog";

export function PaymentTermsDetailView(props: { paymentTerms: PaymentTerms }) {
	const { paymentTerms } = props;

	const context = usePaymentTermsPage();
	const alert = useAlert();

	const [disabled, setDisabled] = React.useState(false);

	const [name, setName] = React.useState(paymentTerms.name);
	const [description, setDescription] = React.useState(paymentTerms.description);
	const [daysToPay, setDaysToPay] = React.useState(paymentTerms.dueDays);
	const [graceDays, setGraceDays] = React.useState(paymentTerms.graceDays);
	const [overdueFee, setOverdueFee] = React.useState(paymentTerms.overdueFee);

	const [showAddOverdueFee, setShowAddOverdueFee] = React.useState(false);

	const setToDefault = async () => {
		setDisabled(true);
		const result = await PaymentTermsService.update({ paymentTermsId: paymentTerms.id, isDefault: true });
		setDisabled(false);
		if (result.success) {
			alert.success(`${paymentTerms.name} is now the default payment terms`);
			context.setDefaultPaymentTerms(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onSave = async () => {
		setDisabled(true);
		const result = await PaymentTermsService.update({
			paymentTermsId: paymentTerms.id,
			name,
			description,
			dueDays: daysToPay,
			graceDays,
		});
		setDisabled(false);
		if (result.success) {
			alert.success(`${paymentTerms.name} has been updated`);
			context.updatePaymentTerms(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const removeOverdueFee = async () => {
		setDisabled(true);
		const result = await PaymentTermsService.removeOverdueFee(paymentTerms.id);
		setDisabled(false);
		if (result.success) {
			alert.success("Overdue fee removed");
			setOverdueFee(null);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	}

	return (
		<Grid container spacing={2}>
			<Grid item xl={5} lg={7} md={9} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Update payment terms" />
						</Grid>
						<GridGrow />

						<Grid item>
							<Box mr={1}>
								{paymentTerms.isDefault ? (
									<Typography variant="body2" color="textSecondary">
										Default Terms
									</Typography>
								) : (
									<Button variant="outlined" color="primary" onClick={setToDefault} disabled={disabled} style={{ marginRight: 5 }}>
										Set as Default
									</Button>
								)}
							</Box>
						</Grid>
					</Grid>
					<CardContent>
						<Grid spacing={2} container>
							<Grid item sm={6} xs={12}>
								<TextField label="Name" variant="outlined" fullWidth value={name} onChange={(e) => setName(e.target.value)} disabled={disabled} />
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									label="Description"
									variant="outlined"
									fullWidth
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									disabled={disabled}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<NumberTextField
									label="Days to Pay"
									variant="outlined"
									fullWidth
									value={daysToPay}
									onNumberChange={(n) => setDaysToPay(n ?? 0)}
									disabled={disabled}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<NumberTextField
									label="Grace Days"
									variant="outlined"
									fullWidth
									value={graceDays}
									onNumberChange={(n) => setGraceDays(n ?? 0)}
									disabled={disabled}
								/>
							</Grid>

							<Grid item xs={12}>
								{!overdueFee && (
									<Button variant="outlined" onClick={() => setShowAddOverdueFee(true)} disabled={disabled} style={{ marginRight: 10 }}>
										Add Overdue Fee
									</Button>
								)}
								<AddOverdueFeeDialog
									open={showAddOverdueFee}
									paymentTerms={paymentTerms}
									onClose={() => setShowAddOverdueFee(false)}
									onAdd={(terms) => {
										setOverdueFee(terms.overdueFee);
										setShowAddOverdueFee(false);
									}}
								/>
							</Grid>
						</Grid>
					</CardContent>
					{overdueFee && (
						<>
							<Divider />
							<CardHeader title="Overdue Fee" subheader="Overdue fee will be applied to invoices that are overdue at the end of the month" />
							<CardContent>
								<Grid container spacing={2}>
									<Grid item sm={6} xs={12}>
										<PercentTextField
											label="Percent Fee"
											variant="outlined"
											fullWidth
											value={overdueFee.percentFee ?? 0}
											onPercentChange={(percentFee) => setOverdueFee({ ...overdueFee, percentFee })}
											disabled={disabled}
											helperText="Charge a percentage of the invoice amount"
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<PriceTextField
											label="Fixed Fee"
											variant="outlined"
											fullWidth
											value={overdueFee.fixedFee ?? 0}
											onPriceChanged={(fixedFee) => setOverdueFee({ ...overdueFee, fixedFee })}
											disabled={disabled}
											helperText="Charge a fixed late fee dollar amount"
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<PriceTextField
											label="Minimum Fee"
											variant="outlined"
											fullWidth
											value={overdueFee.minimumFeeAmount ?? 0}
											onPriceChanged={(minimumFeeAmount) => setOverdueFee({ ...overdueFee, minimumFeeAmount })}
											disabled={disabled}
											helperText="Minimum fee to charge if above fee is less than this amount"
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<Button variant="outlined" color="secondary" onClick={removeOverdueFee} disabled={disabled}>
											Remove Fee
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</>
					)}
					<Button variant="contained" color="primary" onClick={onSave} disabled={disabled} fullWidth>
						Save
					</Button>
				</Card>
			</Grid>
		</Grid>
	);
}
