import { Button, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { classnames } from "@material-ui/data-grid";
import React from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { GridGrow } from "../../components/GridGrow";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import PersonIcon from "@material-ui/icons/Person";
import LocationIcon from "@material-ui/icons/LocationOn";
import { formatAddress, navigateToAddressLink } from "../../entities/customer/GeocodedAddress";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useRoutePage } from "./RoutePage";
import { DriverTicket, getTicketLetter } from "../../entities/routing/DriverTicket";
import { TagBubble } from "../../components/TagBubble";

interface Props {
	driverTickets: DriverTicket[];
	onOrderChanged: (orderedStops: DriverTicket[]) => void;
}

export function OrderableRouteStops(props: Props) {
	const classes = useStyles();
	const { driverTickets } = props;
	const routePageContext = useRoutePage();

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const reorderedStops = [...driverTickets];
		const [removed] = reorderedStops.splice(result.source.index, 1);
		reorderedStops.splice(result.destination.index, 0, removed);

		props.onOrderChanged(reorderedStops.map((s, i) => ({ ...s, routeSequence: i })));
	};

	return (
		<DragDropContext onDragEnd={(r) => onDragEnd(r)}>
			<Droppable droppableId="routeStops">
				{(provided) => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{driverTickets.map((driverTicket, index) => (
							<Draggable key={driverTicket.id} draggableId={driverTicket.id} index={index}>
								{(provided, snapshot) => (
									<Card
										className={classnames(classes.stopCard, snapshot.isDragging && classes.isDraggingCard)}
										elevation={3}
										innerRef={provided.innerRef}
										{...provided.draggableProps}
									>
										<Grid container>
											<Grid item xs={12}>
												<Grid container wrap="nowrap" alignItems="center" className={classes.cardItem}>
													<Grid item>
														<Grid container wrap="nowrap" alignItems="center" spacing={1}>
															<Grid item>
																<PersonIcon style={{ display: "block" }} />
															</Grid>
															<Grid item>
																<Typography>{driverTicket.customer.name}</Typography>
															</Grid>
														</Grid>
													</Grid>
													<GridGrow />
													<Grid item>
														<Grid container wrap="nowrap" alignItems="center">
															<Grid item>
																<Typography color="textSecondary" style={{ fontSize: "1.15rem", paddingBottom: 2 }}>
																	{getTicketLetter(index)}
																</Typography>
															</Grid>
															<Grid item>
																<div {...provided.dragHandleProps} style={{ padding: 5 }}>
																	<DragIndicatorIcon />
																</div>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container wrap="nowrap" alignItems="center" spacing={1} className={classes.cardItem}>
													<Grid item>
														<LocationIcon style={{ display: "block" }} />
													</Grid>
													<Grid item>
														<Typography>{formatAddress(driverTicket.location)}</Typography>
													</Grid>
												</Grid>
											</Grid>
											{(driverTicket.instructions.trim() !== "" || driverTicket.customer.deliveryInstructions != null) && (
												<Grid item xs={12}>
													<Grid container wrap="nowrap" alignItems="center" spacing={1} className={classes.cardItem}>
														<Grid item>
															<ListAltIcon style={{ display: "block" }} />
														</Grid>
														<Grid item>
															{
																driverTicket.instructions.trim() !== "" &&
																<Typography style={{ whiteSpace: "pre-wrap" }}>{driverTicket.instructions}</Typography>
															}
															{
																driverTicket.customer.deliveryInstructions &&
																<Typography  style={{ whiteSpace: "pre-wrap"

																 }}>{driverTicket.customer.deliveryInstructions}</Typography>
															}
														</Grid>
													</Grid>
												</Grid>
											)}
	
											{driverTicket.tags.length > 0 && (
												<Grid item xs={12}>
													<Grid container wrap="nowrap" alignItems="center" spacing={1} className={classes.cardItem}>
														{driverTicket.tags.map((tag, index) => (
															<Grid item key={index}>
																<TagBubble tag={tag} />
															</Grid>
														))}
													</Grid>
												</Grid>
											)}

											<Grid item xs={12}>
												<Grid container wrap="nowrap" alignItems="flex-end">
													<Grid item md={4} xs={5}>
														<Button
															style={{ borderTopRightRadius: 5 }}
															fullWidth
															variant="contained"
															disabled={driverTicket.location.latitude === 0 || driverTicket.location.longitude === 0}
															className={classnames(classes.cardAction, snapshot.isDragging && classes.isDraggingButton)}
															onClick={() => window.open(navigateToAddressLink(driverTicket.location))}
														>
															{snapshot.isDragging ? "Changing Order" : "Navigate"}
														</Button>
													</Grid>
													<GridGrow />
													<Grid item md={4} xs={5}>
														{!snapshot.isDragging && (
															<Button
																style={{ borderTopLeftRadius: 5 }}
																fullWidth
																variant="contained"
																className={classnames(classes.cardAction)}
																onClick={() => routePageContext.selectTicket(driverTicket)}
															>
																See Ticket
															</Button>
														)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Card>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

const useStyles = makeStyles((theme) => ({
	stopCard: {
		marginBottom: 15,
		marginLeft: 8,
	},
	isDraggingCard: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.getContrastText(theme.palette.primary.dark),
	},
	cardItem: {
		paddingLeft: 10,
		marginBottom: 4,
	},
	cardAction: {
		borderRadius: 0,
		padding: 3,
		backgroundColor: "#bdbdbd",
		"&:hover": {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.getContrastText(theme.palette.primary.dark),
		},
	},
	isDraggingButton: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.getContrastText(theme.palette.primary.dark),
		boxShadow: "unset",
	},
}));
