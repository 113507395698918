import { Grid, IconButton, InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import React, { useState } from "react";
import { useAlert } from "../hooks/useAlert";
import { ValidatedServerResult } from "../services/server/WebClient";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { FieldValidationError } from "../services/server/ServerValidationError";
import { PhoneTextField } from "./PhoneTextField";
import { PhoneNumber } from "../entities/customer/PhoneNumber";
import { NumberTextField } from "./NumberTextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ButtonLink } from "./ButtonLink";

type BaseUpdateRequest<OutputModel> = {
	onSuccess: (result: OutputModel) => void;
	successMessage?: string;
};

type RequiredUpdateRequest<ValueType, OutputModel> = BaseUpdateRequest<OutputModel> & {
	request: (value: NonNullable<ValueType>) => Promise<ValidatedServerResult<OutputModel>>;
	required: true;
};

type OptionalUpdateRequest<ValueType, OutputModel> = BaseUpdateRequest<OutputModel> & {
	request: (value: ValueType | null) => Promise<ValidatedServerResult<OutputModel>>;
	required: false;
};

type ValueUpdateRequest<ValueType, OutputModel> = RequiredUpdateRequest<ValueType, OutputModel> | OptionalUpdateRequest<ValueType, OutputModel>;
type TextUpdateRequest<OutputModel> = ValueUpdateRequest<string, OutputModel>;
type DateUpdateRequest<OutputModel> = ValueUpdateRequest<Date, OutputModel>;
type NumberUpdateRequest<OutputModel> = ValueUpdateRequest<number, OutputModel>;
type PhoneUpdateRequest<OutputModel> = ValueUpdateRequest<PhoneNumber, OutputModel>;

type BaseSubmitFieldProps = Omit<TextFieldProps, "value" | "onChange"> & {
	onCancel?: () => void;
	selectOnFocus?: boolean;
};

type SubmitTextFieldProps<OutputModel> = BaseSubmitFieldProps & {
	value: string | null;
	type: "text";
	update?: TextUpdateRequest<OutputModel>;
};

type SubmitDateFieldProps<OutputModel> = BaseSubmitFieldProps & {
	value: Date | null;
	type: "date";
	update?: DateUpdateRequest<OutputModel>;
	maxDate?: Date;
	maxDateMessage?: string;
	minDate?: Date;
	minDateMessage?: string;
};

type SubmitNumberProps<OutputModel> = BaseSubmitFieldProps & {
	value: number | null;
	type: "number";
	update?: NumberUpdateRequest<OutputModel>;
};

type SubmitPhoneFieldProps<OutputModel> = BaseSubmitFieldProps & {
	value: PhoneNumber | null;
	type: "phone";
	allowExtensions: boolean;
	update?: PhoneUpdateRequest<OutputModel>;
};

type SubmitFieldProps<OutputModel> =
	| SubmitTextFieldProps<OutputModel>
	| SubmitPhoneFieldProps<OutputModel>
	| SubmitNumberProps<OutputModel>
	| SubmitDateFieldProps<OutputModel>;

function getTextFieldProps<OutputModel>(props: SubmitFieldProps<OutputModel>): TextFieldProps {
	if (props.type === "phone") {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { update, onCancel, selectOnFocus, value, allowExtensions, ...textFieldProps } = props;
		return textFieldProps;
	}

	if (props.type === "number") {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { update, onCancel, selectOnFocus, value, type, ...textFieldProps } = props;
		return textFieldProps;
	}

	if (props.type === "date") {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { update, onCancel, selectOnFocus, value, type, maxDate, maxDateMessage, minDate, minDateMessage, ...textFieldProps } = props;
		return textFieldProps;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { update, onCancel, selectOnFocus, ...textFieldProps } = props;
	return textFieldProps;
}

export function SubmitField<OutputModel>(props: SubmitFieldProps<OutputModel>) {
	const alert = useAlert();
	const { onCancel } = props;
	const update = props.update as ValueUpdateRequest<string | number | Date | PhoneNumber, OutputModel> | undefined;

	const textFieldProps = getTextFieldProps(props);

	const [value, setValue] = useState(props.value);
	const [updateInProgress, setUpdateInProgress] = useState(false);
	const [validationError, setValidationError] = useState<FieldValidationError>();

	const disabled = props.disabled || updateInProgress;

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (update && e.key === "Enter") {
			executeUpdate();
		}
		if (onCancel && e.key === "Escape") {
			e.stopPropagation();
			setValue(props.value);
			onCancel();
		}
	};

	const getRequiredValue = (): false | PhoneNumber | number | Date | string => {
		if (value == null) return false;
		if (typeof value === "string" && value.trim() === "") return false;
		return value;
	};

	const getOptionalValue = (): null | PhoneNumber | number | Date | string => {
		if (value == null) return null;
		if (typeof value === "string" && value.trim() === "") return null;
		return value;
	};

	const executeUpdate = async () => {
		if (!update) return;

		let request: Promise<ValidatedServerResult<OutputModel>>;

		if (update.required) {
			const requiredValue = getRequiredValue();
			if (requiredValue === false) {
				setValidationError({ field: "", errors: ["This field is required"] });
				return;
			}
			update.request(requiredValue);
			request = update.request(requiredValue);
		} else {
			const optionalValue = getOptionalValue();
			request = update.request(optionalValue);
		}

		setUpdateInProgress(true);
		const result = await request;
		setUpdateInProgress(false);

		if (result.success) {
			update.onSuccess(result.data);
			if (update.successMessage) {
				alert.success(update.successMessage);
			}
		} else if (result.validation) {
			setValidationError(result.errors[0]);
		} else {
			alert.serverError(result);
		}
	};

	const defaultTextFieldProps: TextFieldProps = {
		...textFieldProps,
		variant: textFieldProps.variant ?? "outlined",
		disabled,
		onKeyDown,
		autoFocus: textFieldProps.autoFocus ?? true,
		error: validationError != null || textFieldProps.error,
		helperText: validationError?.errors[0] ?? textFieldProps.helperText,
		InputProps: {
			...textFieldProps.InputProps,
			endAdornment: (
				<InputAdornment position="end">
					{onCancel && (
						<IconButton onClick={onCancel} disabled={disabled} color="secondary" size="small">
							<CloseIcon />
						</IconButton>
					)}
					{update && (
						<IconButton onClick={executeUpdate} disabled={disabled} color="primary" size="small" style={{ marginLeft: onCancel != null ? 7 : undefined }}>
							<CheckIcon />
						</IconButton>
					)}
				</InputAdornment>
			),
		},
		inputProps: {
			...textFieldProps.inputProps,
			onFocus: props.selectOnFocus
				? (e: React.FocusEvent<HTMLInputElement>) => {
						e.target.select();
				  }
				: undefined,
		},
	};

	if (props.type === "number" && typeof value === "number") {
		return <NumberTextField {...defaultTextFieldProps} value={value} onNumberChange={setValue} />;
	}

	if (props.type === "date") {
		return (
			<DateTextField
				{...defaultTextFieldProps}
				value={value as Date}
				onChangeDate={setValue}
				onCancel={onCancel}
				onUpdate={update ? executeUpdate : undefined}
				maxDate={props.maxDate}
				maxDateMessage={props.maxDateMessage}
				minDate={props.minDate}
				minDateMessage={props.minDateMessage}
			/>
		);
	}

	if (props.type === "phone") {
		return <PhoneTextField {...defaultTextFieldProps} value={value as PhoneNumber} allowExtensions={props.allowExtensions} onChangePhoneNumber={setValue} />;
	}

	return <TextField {...defaultTextFieldProps} value={value ?? ""} onChange={(e) => setValue(e.target.value)} />;
}

type EditableFieldProps<OutputModel> = { view: (onEdit: () => void) => JSX.Element } & (
	| (Omit<SubmitTextFieldProps<OutputModel>, "onCancel" | "update"> & {
			update: TextUpdateRequest<OutputModel>;
	  })
	| (Omit<SubmitDateFieldProps<OutputModel>, "onCancel" | "update"> & {
			update: DateUpdateRequest<OutputModel>;
	  })
	| (Omit<SubmitPhoneFieldProps<OutputModel>, "onCancel" | "update"> & {
			update: PhoneUpdateRequest<OutputModel>;
	  })
	| (Omit<SubmitNumberProps<OutputModel>, "onCancel" | "update"> & {
			update: NumberUpdateRequest<OutputModel>;
	  })
);

export function EditableField<OutputModel>(props: EditableFieldProps<OutputModel>) {
	const { view, ...rest } = props;
	const submitFieldProps = rest as SubmitFieldProps<OutputModel>;
	const [editing, setEditing] = useState(false);

	if (editing) {
		return (
			<SubmitField<OutputModel>
				{...submitFieldProps}
				onCancel={() => setEditing(false)}
				update={
					{
						...props.update,
						onSuccess: (data: OutputModel) => {
							props.update.onSuccess(data);
							setEditing(false);
						},
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} as any
				}
			/>
		);
	}

	return view(() => setEditing(true));
}

type DateTextFieldProps = TextFieldProps & {
	value: Date;
	onChangeDate: (date: Date | null) => void;
	onCancel?: () => void;
	onUpdate?: () => void;
	maxDate?: Date;
	maxDateMessage?: string;
	minDate?: Date;
	minDateMessage?: string;
};

function DateTextField(props: DateTextFieldProps) {
	const { value, onChangeDate, onCancel, onUpdate, maxDate, maxDateMessage, minDate, minDateMessage, ...textFieldProps } = props;

	const [errorText, setErrorText] = useState<string | undefined>(undefined);

	const submit = () => {
		if(!onUpdate) return;
		const validationError = validate();
		if(validationError) {
			setErrorText(validationError);
		} else {
			onUpdate();
			setErrorText(undefined);
		}
	}

	const validate = () => {
		if(value != null && value.toString() === "Invalid Date") {
			return "Invalid Date";
		}

		if(value == null && textFieldProps.required) {
			return "Required";
		}

		if(maxDate && value > maxDate) {
			return maxDateMessage ?? "Date is too far in the future";
		}

		if(minDate && value < minDate) {
			return minDateMessage ?? "Date is too far in the past";
		}
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="MM/dd/yyyy"
					label={textFieldProps.label}
					inputVariant={textFieldProps.variant ?? "outlined"}
					fullWidth={textFieldProps.fullWidth}
					value={value}
					disabled={textFieldProps.disabled}
					onChange={onChangeDate}
					helperText={errorText ?? textFieldProps.helperText}
					error={errorText != null}
					style={textFieldProps.style}
					size={textFieldProps.size}
					InputProps={textFieldProps.InputProps}
					autoFocus
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							submit();
						} else if (e.key === "Escape") {
							if(onCancel) onCancel();
						}
					}}
					maxDate={maxDate}
					minDate={minDate}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={2}>
					{onUpdate && (
						<Grid item>
							<ButtonLink onClick={submit} color="primary" style={{ marginTop: 5, marginBottom: 5 }} disabled={textFieldProps.disabled}>
								Update
							</ButtonLink>
						</Grid>
					)}
					{onCancel && (
						<Grid item>
							<ButtonLink onClick={onCancel} color="error" style={{ marginTop: 5, marginBottom: 5 }} disabled={textFieldProps.disabled}>
								Cancel
							</ButtonLink>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}
