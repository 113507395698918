import { IconButton } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export function BackButton(props: {onClick?: () => void}) {
    const history = useHistory();

	const handleClick = () => {
		if (props.onClick) {
			props.onClick();
		} else {
			history.goBack();
		}
	};

	return (
		<IconButton onClick={handleClick}>
			<ArrowBackIcon />
		</IconButton>
	);
}
