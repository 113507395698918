import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";
import { deserializePropaneTank } from "../../entities/customer/PropaneTank";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { Modify } from "../../utility/modifyType";
import { NodaTime } from "../../utility/NodaTime";

interface CreatePropaneTankRequest {
	customerId: string;
	gallons: number;
	serialNumber: string | null;
	locationDescription: string | null;
	address: GeocodedAddress;
	isRental: boolean;
}


interface UpdatePropaneTankRequest {
	propaneTankId: string;
	capacity?: number;
	address?: GeocodedAddress;
	serialNumber?: string | null;
	locationDescription?: string | null;
	notes?: string;
	isRental?: boolean;
	lastGasCheck?: Date | null;
}

type UpdatePropaneTankServerModel = Modify<
	UpdatePropaneTankRequest,
	{
		serialNumber?: OptionalValue<string | null>;
		locationDescription?: OptionalValue<string | null>;
		lastGasCheck?: OptionalValue<string | null>;
	}
>;

export interface LastTankFill {
	tankId: string;
	timeOfFill: Date;
	amountFilled: number;
}

type SerializedLastTankFill = Modify<LastTankFill, { timeOfFill: string }>;
const deserializeLastTankFills = (model: { lastTankFills: SerializedLastTankFill[] }): LastTankFill[] =>
	model.lastTankFills.map((f) => ({ ...f, timeOfFill: new Date(f.timeOfFill) }));

export interface TankFillHistory {
	customerId: string;
	tankId: string;
	driverTicketId: string | null;
	completedBy: string;
	driverId: string | null;
	driverName?: string | null;
	dateFilled: Date;
	amountFilled: number;
	tankPercentage?: number;
}

type SerializedTankFillHistory = Modify<TankFillHistory, { dateFilled: string }>;
const deserializeTankFillHistory = (model: SerializedTankFillHistory[]): TankFillHistory[] => model.map((f) => ({ ...f, dateFilled: new Date(f.dateFilled) }));

function serializeUpdatePropaneTankRequest(request: UpdatePropaneTankRequest): UpdatePropaneTankServerModel {
	return {
		...request,
		serialNumber: toOptional(request.serialNumber),
		locationDescription: toOptional(request.locationDescription),
		lastGasCheck: toOptional(request.lastGasCheck?.toISOString().split("T")[0] ?? undefined),
	};
}

export interface SearchPropaneTankRequest {
	serialNumber?: string;
	isRental?: boolean;
	pageSize?: number;
	page?: number;
}

export interface SearchablePropaneTank {
	id: string;
	gallons: number;
	address: GeocodedAddress;
	description: string;
	serialNumber?: string;
	isRental: boolean;
	customerName: string;
	customerId: string;
}

export interface PropaneTankSearchResults {
	results: SearchablePropaneTank[];
	totalCount: number;
	page: number;
	pageSize: number;
}

export interface CustomerTank {
	id: string;
	gallons: number;
	description: string | null;
	address: GeocodedAddress;
	isInAlert: boolean;
	nextExpectedFill: Date | null;
	lastReading: {
		timeOfReading: Date;
		percentRemaining: number;
	} | null;
	lastThreeFills: {
		timeOfFill: Date;
		amountFilled: number;
		price: number;
	}[];

}

export type SerializedCustomerTank = Modify<
	CustomerTank,
	{
		nextExpectedFill: string | null;
		lastReading: {
			timeOfReading: string;
			percentRemaining: number;
		} | null;
		lastThreeFills: {
			timeOfFill: string;
			amountFilled: number;
			price: number;
		}[];
	}
>;

export function deserializeCustomerTank(model: SerializedCustomerTank): CustomerTank {
	return {
		...model,
		lastReading: model.lastReading ? { ...model.lastReading, timeOfReading: new Date(model.lastReading.timeOfReading) } : null,
		nextExpectedFill: model.nextExpectedFill ? NodaTime.deserializeFromLocalDate(model.nextExpectedFill) : null,
		lastThreeFills: model.lastThreeFills.map((h) => ({ ...h, timeOfFill: new Date(h.timeOfFill) })),
	};
}
export function deserializeCustomerTanks(model: SerializedCustomerTank[]): CustomerTank[] {
	return model.map(deserializeCustomerTank);
}

export const PropaneTankService = {
	get: (propaneTankId: string) => WebClient.GetOptional(`${apiServer}/api/propane-tanks/${propaneTankId}`, deserializePropaneTank),
	getCustomerTanks: (customerId: string) => WebClient.GetOptional(`${apiServer}/api/propane-tanks/customer/${customerId}`, deserializeCustomerTanks),
	getLastTankFills: (customerId: string) => WebClient.GetOptional(`${apiServer}/api/propane-tanks/customer/${customerId}/last-fill`, deserializeLastTankFills),
	getTankFillHistory: (customerId: string, tankId: string) =>
		WebClient.GetOptional(`${apiServer}/api/propane-tanks/customer/${customerId}/${tankId}/fill-history`, deserializeTankFillHistory),
	search: (request: SearchPropaneTankRequest) => WebClient.Post.Validated<PropaneTankSearchResults>(`${apiServer}/api/propane-tanks/search`, request),
	create: (request: CreatePropaneTankRequest) => WebClient.Post.Validated(`${apiServer}/api/propane-tanks/customer/${request.customerId}`, request, deserializeCustomerTank),
	update: (request: UpdatePropaneTankRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/propane-tanks/${request.propaneTankId}`, serializeUpdatePropaneTankRequest(request), deserializePropaneTank),
	assignRoute: (propaneTankId: string, fixedTankRouteId?: string) =>
		WebClient.Put.Validated(`${apiServer}/api/propane-tanks/assign-route`, { propaneTankId, fixedTankRouteId }, deserializePropaneTank),
	transfer: (request: { propaneTankId: string; customerId: string }) => WebClient.Put.Validated(`${apiServer}/api/propane-tanks/transfer`, request, deserializePropaneTank),
};
