import { uniqueId } from "lodash";
import { calculateTaxes } from "../../../entities/accounting/TaxRule";
import { TicketPriceLockContract, TicketProduct, TicketQuoteTaxRule, TicketTankFill } from "../../../entities/routing/DriverTicket";
import { SafeMoney } from "../../../utility/Money";

type StopLineItemType = { name: "TankFill"; tankId: string; tankDescription: string | null } | { name: "Product" };

export class StopLineItem {
	readonly id: string;
	readonly quoteItemId: string;
	readonly productListingId: string;
	readonly productName: string;
	readonly priceLock: TicketPriceLockContract | null;
    readonly priceValue: number | null;
	readonly price: SafeMoney;
	readonly quantity: number | null;
	readonly taxRules: TicketQuoteTaxRule[];
	readonly type: StopLineItemType;

	readonly subtotal: SafeMoney | null;
	readonly tax: SafeMoney | null;

	constructor(quoteItemId: string, productListingId: string, productName: string, priceLock: TicketPriceLockContract | null, unitPrice: SafeMoney, quantity: number | null, taxRules: TicketQuoteTaxRule[], type: StopLineItemType) {
		this.id = uniqueId();
		this.quoteItemId = quoteItemId;
		this.productListingId = productListingId;
		this.productName = productName;
		this.priceLock = priceLock;
        this.priceValue = unitPrice.toRoundedUnit(3);
		this.price = unitPrice;
		this.quantity = quantity;
		this.taxRules = taxRules;
		this.type = type;

		this.subtotal = quantity == null ? null : this.price.multiply(quantity).convertPrecision(2, "HALF_AWAY_FROM_ZERO");
		this.tax = this.subtotal === null ? null : calculateTaxes(this.taxRules, this.subtotal);
	}

	static createTankFill(tankFill: TicketTankFill, priceLock: TicketPriceLockContract | null): StopLineItem {
		return new StopLineItem(tankFill.id, tankFill.productListingId, tankFill.productName, priceLock, tankFill.unitPrice, tankFill.quantity, tankFill.taxRules, {
			name: "TankFill",
			tankId: tankFill.tank.id,
			tankDescription:tankFill.tank.description,
		});
	}

	static createProduct(product: TicketProduct, priceLock: TicketPriceLockContract | null): StopLineItem {
		return new StopLineItem(product.id, product.productListingId, product.productName, priceLock, product.unitPrice, product.quantity, product.taxRules, {
			name: "Product",
		});
	}
}
