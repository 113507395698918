import React, { useState } from "react";
import { Button, Grid, Hidden, Paper, TextField, Typography } from "@material-ui/core";
import AuthService from "../../services/auth/AuthService";

import { useSignInStyles } from "./SignInPage";
import { useAlert } from "../../hooks/useAlert";
import { useHistory } from "react-router";
import { routes } from "../../routes";
import EmailIcon from '@material-ui/icons/Email';

export function ForgotPasswordPage() {
	const history = useHistory();
	const classes = useSignInStyles();
	const alert = useAlert();
	const [enabled, setEnabled] = useState(true);
	const [email, setEmail] = useState("");

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setEnabled(false);
		const result = await AuthService.forgotPassword(email);
		setEnabled(true);

		if (result.success) {
			alert.success(`A password reset email was sent to ${email}`);
		} else {
			alert.serverError(result);
		}
	};

	const onChangeEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const onSignIn = () => {
		history.replace(routes.public.signInPage);
	}

	return (
		<Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
			<Grid item>
				<Paper className={classes.signInPaper}>
					<Typography variant="h5">
						Forgot Your Password?
					</Typography>
					<Typography variant="subtitle2">(Happens to us all)</Typography>
					<form onSubmit={onSubmit}>
						<TextField
							label="Email"
							id="login-email"
							value={email}
							onChange={onChangeEmail}
							helperText={"If this email is not setup for DispatchPro, no email will be sent"}
							margin="normal"
							fullWidth
							required
							type="email"
							disabled={!enabled}
						/>
						<Hidden smDown>
							<Grid container direction="row" justify="space-between" alignItems="center" style={{ paddingTop: 30 }}>
								<Button variant="outlined" size="medium" color="primary" onClick={onSignIn} disabled={!enabled} style={{ minWidth: 150 }}>
									Sign In
								</Button>
								<Button variant="contained" size="medium" color="primary" type="submit" disabled={!enabled} style={{ marginLeft: 10, minWidth: 150 }} startIcon={<EmailIcon/>}>
									Send Password Reset
								</Button>
							</Grid>
						</Hidden>
						<Hidden mdUp>
							<Button fullWidth variant="contained" size="medium" color="primary" type="submit" disabled={!enabled} style={{ marginTop: 15 }} startIcon={<EmailIcon/>}>
								Send Password Reset
							</Button>
							<Button fullWidth variant="outlined" size="medium" color="primary" onClick={onSignIn} disabled={!enabled} style={{ marginTop: 10 }}>
								Sign In
							</Button>
						</Hidden>
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
}
