import { Button, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CenteredLoadingSpinner from "../../components/CenteredLoadingSpinner";
import { GridGrow } from "../../components/GridGrow";
import { HighlightedText } from "../../components/HighlightedText";
import { PhoneNumberLink } from "../../components/PhoneNumberLink";
import { Price } from "../../components/Price";
import { SearchTextField } from "../../components/SearchTextField";
import { ServerErrorView } from "../../components/ServerErrorView";
import { SortableTableHeader, TableHeader, TableSortOrder } from "../../components/table/SortableTableHeader";
import { TagBubble } from "../../components/TagBubble";
import { Customer } from "../../entities/customer/Customer";
import { formatAddress } from "../../entities/customer/GeocodedAddress";
import { Tag } from "../../entities/customer/Tag";
import { routes } from "../../routes";
import { CustomerService, SearchableCustomer, SearchCustomersRequest } from "../../services/customer/CustomerService";
import { ServerError } from "../../services/server/WebClient";
import { orderBy, SortDirection } from "../../utility/orderBy";
import { useLocalStorage } from "../../utility/useLocalStorage";
import { AddCustomerDialog } from "./dialog/AddCustomerDialog";

type ViewOption = "Active" | "All";

const getSearchRequest = (option: ViewOption): SearchCustomersRequest => {
	switch (option) {
		case "Active":
			return { excludeInactive: true };
		case "All":
			return {};
	}
};

function customerContainsTag(customer: SearchableCustomer, tag: Tag): boolean {
	return customer.tags.some((t) => t.id === tag.id);
}

function customerContainsAllTags(customer: SearchableCustomer, tags: Tag[]) {
	if (tags.length === 0) return true;

	if (customer.tags.length === 0) return false;

	return tags.every((tag) => customerContainsTag(customer, tag));
}

function searchStringContainsAll(tokenizedSearch: string[], text: string): boolean {
	const lowerText = text.toLowerCase();
	return tokenizedSearch.every((token) => lowerText.includes(token));
}


function SearchMatches(tokenizedSearch: string[], customer: SearchableCustomer): boolean {
	if (tokenizedSearch.length === 0) return true;
	if (searchStringContainsAll(tokenizedSearch, customer.name)) return true;
	if(tokenizedSearch.length === 1 && customer.customerCode.toLowerCase().includes(tokenizedSearch[0])) return true;
	if (searchStringContainsAll(tokenizedSearch, formatAddress(customer.address))) return true;
	if (tokenizedSearch.length === 1 && customer.phoneNumber?.number.toLowerCase().includes(tokenizedSearch[0])) return true;
	if (tokenizedSearch.length === 1 && customer.phoneNumberSecondary?.number.toLowerCase().includes(tokenizedSearch[0])) return true;
	if (tokenizedSearch.length === 1 && customer.email?.toLowerCase().includes(tokenizedSearch[0])) return true;
	if (tokenizedSearch.length === 1 && customer.emailSecondary?.toLowerCase().includes(tokenizedSearch[0])) return true;
	if(tokenizedSearch.length === 1 && customer.balance.toString().includes(tokenizedSearch[0])) return true;
	if(customer.tags.some(tag => searchStringContainsAll(tokenizedSearch, tag.text))) return true;
	return false;
}

function CustomerSortOrder(sortOrder: TableSortOrder, sortProperty: string): (a: SearchableCustomer, b: SearchableCustomer) => number {
	const sortDirection: SortDirection = sortOrder === "asc" ? "Ascending" : "Descending";
	switch (sortProperty) {
		case "CustomerName":
			return orderBy.string((c) => c.name, sortDirection);
		case "Address":
			return orderBy.string((c) => c.address.city, sortDirection);
		case "Balance":
			return orderBy.number((c) => c.balance, sortDirection);
		default:
			return () => 0;
	}
}

export function CustomerListView() {
	const history = useHistory();

	const [serverError, setServerError] = useState<ServerError>();
	const [customers, setCustomers] = useState<SearchableCustomer[]>();
	const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);

	const [view, setView] = useLocalStorage<ViewOption>("customer-list-view-option-v2", "Active");
	const [sortProperty, setSortProperty] = useLocalStorage<string>("customer-list-view-sortProperty", "CustomerName");
	const [sortOrder, setSortOrder] = useLocalStorage<TableSortOrder>("customer-list-view-sortOrder", "asc");
	const [searchText, setSearchText] = useState("");
	const [tagFilters, setTagFilters] = useState<Tag[]>([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(15);

	const orderedCustomers = useMemo(() => (customers ? [...customers] : []).sort(CustomerSortOrder(sortOrder, sortProperty)), [customers, sortProperty, sortOrder]);
	const tagFilteredCustomers = useMemo(
		() => tagFilters.length > 0 ? orderedCustomers.filter((c) => customerContainsAllTags(c, tagFilters)) : orderedCustomers,
		[orderedCustomers, tagFilters]
	);
	const tokenizedSearchText = useMemo(() => searchText.toLowerCase().trim().replace(/\s+/g, " ").split(" "), [searchText]);
	const filteredCustomers = useMemo(() => tagFilteredCustomers.filter((customer) => SearchMatches(tokenizedSearchText, customer)), [tagFilteredCustomers, tokenizedSearchText]);
	const pagedCustomers = useMemo(() => filteredCustomers.slice(page * pageSize, page * pageSize + pageSize), [filteredCustomers, page, pageSize]);

	useEffect(() => {
		setPage(0);
	}, [searchText]);

	useEffect(() => {
		async function fetch() {
			setCustomers(undefined);
			const result = await CustomerService.search(getSearchRequest(view));
			if (result.success) {
				setCustomers(result.data);
			} else {
				setServerError(result);
			}
		}
		fetch();
	}, [view]);

	const onCustomerAdded = (customer: Customer) => history.push(routes.app.resolve.customerDetailPage(customer.id));
	const onCustomerSelected = (customer: SearchableCustomer) => history.push(routes.app.resolve.customerDetailPage(customer.customerId));

	const sortTable = (property: string, order: TableSortOrder) => {
		setSortProperty(property);
		setSortOrder(order);
	};

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
	};

	const addTagFilter = (tag: Tag) => {
		if (!tagFilters.find((t) => t.id === tag.id)) {
			setTagFilters([...tagFilters, tag]);
		}
	};

	const removeTagFilter = (tag: Tag) => {
		setTagFilters(tagFilters.filter((t) => t.id !== tag.id));
	};

	const onSearchEnter = () => {
		if(filteredCustomers.length === 1) {
			onCustomerSelected(filteredCustomers[0]);
		}
	}

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item>
				<Typography variant="h2">Customers</Typography>
			</Grid>
			<GridGrow />
			{tagFilters.length > 0 && (
				<Grid item>
					<Grid container spacing={1}>
						{tagFilters.map((tag) => (
							<Grid item key={tag.id}>
								<TagBubble tag={tag} onDelete={removeTagFilter} />
							</Grid>
						))}
					</Grid>
				</Grid>
			)}
			<Grid item>
				<SearchTextField value={searchText} onChange={setSearchText} autoFocus onEnter={onSearchEnter} />
			</Grid>
			<Grid item>
				<AddCustomerDialog open={showAddCustomerDialog} onClose={() => setShowAddCustomerDialog(false)} onCustomerAdded={onCustomerAdded} />
				<Button variant="contained" color="primary" onClick={() => setShowAddCustomerDialog(true)}>
					+ Add Customer
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Paper elevation={3}>
					<TableContainer>
						<Tabs value={view} onChange={(_event, value) => setView(value)} indicatorColor="primary" textColor="primary" variant="scrollable">
							<Tab value={"Active"} label={"Active"} />
							<Tab value={"All"} label={"All"} />
						</Tabs>
						{!customers ? (
							<CenteredLoadingSpinner />
						) : (
							<Table stickyHeader size="small">
								<SortableTableHeader order={sortOrder} orderBy={sortProperty}>
									<TableHeader property="CustomerName" onSort={sortTable}>
										Customer
									</TableHeader>
									<TableHeader>Code</TableHeader>
									<TableHeader property="Address" onSort={sortTable}>
										Address
									</TableHeader>
									<TableHeader>Phone Number</TableHeader>
									<TableHeader property="Balance" onSort={sortTable}>Balance</TableHeader>
									{view === "All" && <TableHeader>Status</TableHeader>}
									<TableHeader align="center">Tags</TableHeader>
								</SortableTableHeader>
								<TableBody>
									{pagedCustomers.map((customer) => (
										<TableRow key={customer.customerId} hover onClick={() => onCustomerSelected(customer)}>
											<TableCell>
												<HighlightedText query={searchText}>{customer.name}</HighlightedText>
											</TableCell>
											<TableCell>
												<HighlightedText query={searchText}>{customer.customerCode}</HighlightedText>
											</TableCell>
											<TableCell>
												<HighlightedText query={searchText}>{formatAddress(customer.address)}</HighlightedText>
											</TableCell>
											<TableCell>
												<HighlightedText query={searchText}>
													{customer.phoneNumber ? <PhoneNumberLink phoneNumber={customer.phoneNumber} /> : "None"}
												</HighlightedText>
											</TableCell>
											<TableCell>
												<HighlightedText query={searchText}>
													<Price value={customer.balance} />
												</HighlightedText>
											</TableCell>
											{view === "All" && <TableCell>{customer.inactive ? "Inactive" : "Active"}</TableCell>}
											<TableCell align="center">
												<Grid container spacing={1} justify="center">
													{customer.tags.map((tag) => (
														<Grid item key={tag.id}>
															<TagBubble tag={tag} onClick={addTagFilter} />
														</Grid>
													))}
												</Grid>
											</TableCell>
										</TableRow>
									))}
									<TableRow>
										<TablePagination
											rowsPerPageOptions={[5, 10, 15, 20]}
											colSpan={view === "All" ? 5 : 4}
											count={filteredCustomers.length}
											rowsPerPage={pageSize}
											labelRowsPerPage={"Show"}
											page={page}
											onChangePage={handleChangePage}
											onChangeRowsPerPage={handleChangeRowsPerPage}
										/>
									</TableRow>
								</TableBody>
							</Table>
						)}
					</TableContainer>
				</Paper>
			</Grid>
		</Grid>
	);
}
