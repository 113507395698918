import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, useTheme } from "@material-ui/core";
import { DriverTicket } from "../../../entities/routing/DriverTicket";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { GeocodedAddress, formatAddress } from "../../../entities/customer/GeocodedAddress";
import { useTenant } from "../../../providers/TenantProvider";
import { GoogleMap } from "../../../utility/GoogleMap";

interface VisualizeRouteDialogProps {
	open: boolean;
	onClose: () => void;
	stops: DriverTicket[];
}

export function VisualizeRouteDialog(props: VisualizeRouteDialogProps) {
	const { open, stops } = props;

	const { tenant } = useTenant();
	const { officeAddress } = tenant;

	const [mapsLibrary, setMapsLibrary] = useState<google.maps.MapsLibrary>();
	const [markerLibrary, setMarkerLibrary] = useState<google.maps.MarkerLibrary>();
	const [routesLibrary, setRoutesLibrary] = useState<google.maps.RoutesLibrary>();

	useEffect(() => {
		async function loadLibraries() {
			const [mapsLib, markerLib, routesLib] = await Promise.all([
				google.maps.importLibrary("maps") as Promise<google.maps.MapsLibrary>,
				google.maps.importLibrary("marker") as Promise<google.maps.MarkerLibrary>,
				google.maps.importLibrary("routes") as Promise<google.maps.RoutesLibrary>,
			]);
			setMapsLibrary(mapsLib);
			setMarkerLibrary(markerLib);
			setRoutesLibrary(routesLib);
		}
		loadLibraries();
	}, []);

	const handleClose = () => {
		props.onClose();
	};

	function dialogContent() {
		if (!open) return null;
		if (!officeAddress) {
			return <NoOfficeAddressDialogContent onClose={handleClose} />;
		} else if (!mapsLibrary || !routesLibrary || !markerLibrary) {
			return <LoadingDialogContent />;
		} else {
			return <MappedRouteDialogContent onClose={handleClose} stops={stops} officeLocation={officeAddress} mapsLibrary={mapsLibrary} routesLibrary={routesLibrary} />;
		}
	}

	return (
		<Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
			{dialogContent()}
		</Dialog>
	);
}

function LoadingDialogContent() {
	return (
		<>
			<DialogTitle>Loading Map</DialogTitle>
			<DialogContent>
				<CenteredLoadingSpinner size="5rem" />
			</DialogContent>
		</>
	);
}

function NoOfficeAddressDialogContent(props: { onClose: () => void }) {
	return (
		<>
			<DialogTitle>No Office Address</DialogTitle>
			<DialogContent>
				<DialogContentText>You must set an office address in order to use routing.</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="secondary" variant="outlined">
					Close
				</Button>
			</DialogActions>
		</>
	);
}

interface MappedRouteDialogContentProps {
	onClose: () => void;
	stops: DriverTicket[];
	officeLocation: GeocodedAddress;
	mapsLibrary: google.maps.MapsLibrary;
	routesLibrary: google.maps.RoutesLibrary;
}

function MappedRouteDialogContent(props: MappedRouteDialogContentProps) {
	const { stops, officeLocation, mapsLibrary, routesLibrary } = props;

	const theme = useTheme();
	const darkMode = theme.palette.type === "dark";
	const mapDiv = React.useRef<HTMLDivElement>(null);

	const [origin] = React.useState(new google.maps.LatLng(officeLocation.latitude, officeLocation.longitude));
	const [destination] = React.useState(new google.maps.LatLng(officeLocation.latitude, officeLocation.longitude));
	const [waypoints] = useState<google.maps.DirectionsWaypoint[]>(
		stops.map((stop) => {
			if (stop.location.latitude !== 0 && stop.location.longitude !== 0) {
				return { location: new google.maps.LatLng(stop.location.latitude, stop.location.longitude) };
			} else {
				return { location: formatAddress(stop.location) };
			}
		})
	);
	const [map, setMap] = React.useState<google.maps.Map | null>(null);
	const [directionsRenderer] = React.useState<google.maps.DirectionsRenderer>(new routesLibrary.DirectionsRenderer({ suppressMarkers: true }));
	const [directionsService] = React.useState<google.maps.DirectionsService>(new routesLibrary.DirectionsService());
	const height = 500;

	React.useEffect(() => {
		if (mapDiv.current == null) return;
		const newMap = new mapsLibrary.Map(mapDiv.current, {
			zoom: 11,
			center: origin,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
			mapId: GoogleMap.mapId,
		});
		setMap(newMap);
		directionsRenderer.setMap(newMap);
	}, [darkMode, directionsRenderer, mapsLibrary, origin]);

	React.useEffect(() => {
		async function loadRoute() {
			const request: google.maps.DirectionsRequest = {
				origin: origin,
				destination: destination,
				waypoints: waypoints,
				travelMode: google.maps.TravelMode.DRIVING,
			};
			directionsService.route(request, (result, status) => {
				if (status === google.maps.DirectionsStatus.OK) {
					directionsRenderer.setDirections(result);
					if (result == null || result.routes.length === 0) return;

					const route = result.routes[0];
					const markerLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
					for (let i = 1; i < route.legs.length; i++) {
						const pinScaled = new google.maps.marker.PinElement({
							glyph: markerLetters[i - 1],
							scale: 0.9
						});
						const marker = new google.maps.marker.AdvancedMarkerElement({
							map,
							position: route.legs[i].start_location,
							content: pinScaled.element,
						});

						const ticket = stops[i - 1];
						// eslint-disable-next-line no-loop-func
						marker.addListener("click", () => {
							const infoWindow = new google.maps.InfoWindow({
								content: `
                                    <h3 style="color: black;">${ticket.customer.name}</h3>
                                    <p style="color: black;">${formatAddress(ticket.customer.address)}</p>
                                `,
							});
							infoWindow.open(map, marker);
						});
					}

					const officeMarkerIcon = {
						path: "M -10, 0 a 10, 10 0 1, 0 20, 0 a 10, 10 0 1, 0 -20, 0",
						fillColor: "red",
						fillOpacity: 0.8,
						strokeWeight: 0.5,
						scale: 1,
					};

					const officeMarker = new google.maps.Marker({
						position: origin,
						map: map,
						icon: officeMarkerIcon,
					});
					officeMarker.addListener("click", () => {
						const infoWindow = new google.maps.InfoWindow({
							content: `
                                <h3 style="color: black;">Office</h3>
                                <p style="color: black;">${formatAddress(officeLocation)}</p>
                            `,
						});
						infoWindow.open(map, officeMarker);
					});
				}
			});
		}
		if (!map) return;
		loadRoute();
	}, [destination, directionsRenderer, directionsService, map, officeLocation, origin, stops, waypoints]);

	return (
		<>
			<DialogTitle>Mapped Route</DialogTitle>
			<div style={{ width: "100%" }}>
				<div id="routeVisualizationMap" style={{ height: height }} ref={mapDiv} />
			</div>
			<DialogActions>
				<Button onClick={props.onClose} color="secondary" variant="outlined">
					Close
				</Button>
			</DialogActions>
		</>
	);
}


// function MapPin(props: { letter: string }) {
// 	const { letter } = props;


// 	return (
// 		<div>
// 			<PlaceIcon style={{ fontSize: 30, color: color }} />
// 		</div>
// 	);
// }