import { TaxExemption } from "../../../entities/accounting/TaxExemption";
import { TaxRule, calculateTaxes } from "../../../entities/accounting/TaxRule";
import { ProductListing } from "../../../entities/products/ProductListing";
import { CustomerTank } from "../../../services/customer/PropaneTankService";
import { CreateTankFillRequest } from "../../../services/routing/DriverTicketService";
import { Money, SafeMoney } from "../../../utility/Money";

export class NewTicketTankFill {
	readonly tank: CustomerTank;
	readonly gasLevel: number | null;
	readonly estimatedUllage: number | null;
	readonly quantity: number | null;
	readonly priceValue: number | null;
	readonly listing: ProductListing;
	readonly taxRules: TaxRule[];
	readonly taxExemption: TaxExemption | null;
    
    readonly price: SafeMoney | null;
    readonly subtotal: SafeMoney | null;
    readonly tax: SafeMoney | null;
    readonly isEstimate: boolean;

	constructor(
		tank: CustomerTank,
		gasLevel: number | null,
        estimatedUllage: number | null,
		listing: ProductListing,
		taxRules: TaxRule[],
		taxExemption: TaxExemption | null,
		quantity: number | null,
		price: number | null
	) {
        this.tank = tank;
		this.gasLevel = gasLevel;
		this.estimatedUllage = estimatedUllage; 
		this.listing = listing;
		this.taxRules = taxRules;
		this.taxExemption = taxExemption;
		this.quantity = quantity;
		this.priceValue = price;
        this.price = price ? Money.fromDecimal(price, 3) : null;

        const gallons = this.quantity ?? this.estimatedUllage;
        this.subtotal = gallons == null || this.price == null ? null : this.price.multiply(gallons);
        this.tax = this.subtotal === null ? null : calculateTaxes(this.taxRules, this.subtotal);
        this.isEstimate = this.quantity === null && this.subtotal !== null;
	}

	static create(customerTank: CustomerTank, listing: ProductListing, taxRules: TaxRule[], taxExemption: TaxExemption | null): NewTicketTankFill {
		return new NewTicketTankFill(customerTank, null, null, listing, taxRules, taxExemption, null, listing.price);
	}

	copy(props: Partial<NewTicketTankFill>): NewTicketTankFill {
		return new NewTicketTankFill(
			props.tank === undefined ? this.tank : props.tank,
			props.gasLevel === undefined ? this.gasLevel : props.gasLevel,
			props.estimatedUllage === undefined ? this.estimatedUllage : props.estimatedUllage,
			props.listing === undefined ? this.listing : props.listing,
			props.taxRules === undefined ? this.taxRules : props.taxRules,
			props.taxExemption === undefined ? this.taxExemption : props.taxExemption,
			props.quantity === undefined ? this.quantity : props.quantity,
			props.priceValue === undefined ? this.priceValue : props.priceValue
		);
	}

	toTankFillRequest(): CreateTankFillRequest {
		return{
			tankId: this.tank.id,
			price: this.priceValue ?? 0,
			productListingId: this.listing.id,
			productName: this.listing.name,
			quantity: this.quantity,
			reportedPercentage: this.gasLevel,
			taxRules: this.taxRules.map(taxRule => ({
				taxRuleId: taxRule.id === 0 ? null : taxRule.id,
				taxAccountId: taxRule.taxAccountId,
				taxAccountName: taxRule.taxAccountName,
				name: taxRule.name,
				fixedAmount: taxRule.fixedAmount,
				ratePercent: taxRule.ratePercent,
			})),

		}
	}
}
