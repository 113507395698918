import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useAlert } from "../../../hooks/useAlert";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useTicketDetail } from "./DriverTicketDetailView";

export function TicketInstructions() {
	const alert = useAlert();
	const context = useTicketDetail();
	const { ticket, disabled, setDisabled } = context;

	const [editMode, setEditMode] = useState(false);
	const [instructions, setInstructions] = useState(ticket.instructions);

	const saveInstructions = async () => {
		setDisabled(true);
		const result = await DriverTicketService.update({ driverTicketId: ticket.id, notes: instructions });
		setDisabled(false);
		if (result.success) {
			context.updateTicket(result.data);
			alert.success("Instructions updated");
			setEditMode(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onCancel = () => {
		setInstructions(ticket.instructions);
		setEditMode(false);
	};

	function DisplayInstructions() {
		if (ticket.instructions.trim() === "")
			return (
				<Typography variant="body1" color="textSecondary" style={{ fontSize: "1.15rem" }}>
					No Instructions
				</Typography>
			);

		return (
			<Typography variant="body1" style={{ whiteSpace: "break-spaces", fontSize: "1.15rem" }}>
				{ticket.instructions}
			</Typography>
		);
	}

	if (ticket.timeOfCompletion) {
		return (
			<Box>
				<Typography variant="h6">Instructions:</Typography>
				<DisplayInstructions />
			</Box>
		);
	}

	return (
		<Box>
			<Button
				color="primary"
				disabled={disabled}
				variant="text"
				style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }}
				onClick={() => setEditMode(true)}
			>
				Instructions:
			</Button>
			{editMode ? (
				<TextField
					label="Instructions"
					multiline
					rows={3}
					fullWidth
					value={instructions}
					variant="outlined"
					onChange={(e) => setInstructions(e.target.value)}
					disabled={disabled}
					autoFocus
					onFocus={(e) => e.target.selectionStart = e.target.selectionEnd = e.target.value.length}
					onKeyDown={(event) => {
						if (event.key === "Escape") {
							event.stopPropagation();
							onCancel();
						}
					}}
					onBlur={saveInstructions}
				/>
			) : (
				<DisplayInstructions />
			)}
		</Box>
	);
}
