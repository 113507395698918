import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { deserializeDriver, Driver, SerializedDriver, serializeDriver } from "../entities/routing/Driver";
import { useCachedOfflineState } from "../hooks/useCachedOfflineState";
import { DriverService } from "../services/routing/DriverService";
import { createServerError, ServerError } from "../services/server/WebClient";
import { AppDataContext, AppDataLoadStatus } from "./AppDataProviders";
import { useOfflineStatus } from "./OfflineStatusProvider";

interface DriverContext extends AppDataContext {
	drivers: Driver[];
	create: (driver: Driver) => void;
	update: (driver: Driver) => void;
	delete: (driver: Driver) => void;
}

const DriverReactContext = createContext<DriverContext>({} as DriverContext);
DriverReactContext.displayName = "DriverAppData";

export function useDrivers() {
	return useContext(DriverReactContext);
}

export function DriverProvider(props: PropsWithChildren<{}>) {
	const isOffline = useOfflineStatus();

	const [drivers, setDrivers] = useCachedOfflineState<Driver[], SerializedDriver[]>(
		"offline-drivers",
		[],
		(d) => d.map(serializeDriver),
		(d) => d.map(deserializeDriver)
	);
	const [loadStatus, setLoadStatus] = useState<AppDataLoadStatus>(isOffline ? "offline" : "loading");
	const [serverError, setServerError] = useState<ServerError | null>(null);

	useEffect(() => {
		if (!isOffline) {
			setLoadStatus("loading");
			DriverService.getAll().then((result) => {
				if (result.success) {
					setDrivers(result.data);
					setLoadStatus("ready");
				} else {
					setServerError(result);
					setLoadStatus("error");
				}
			}).catch((e: string) => {
				console.error(e);
				setServerError(createServerError(e, 999));
			});
		} else{
			setLoadStatus("offline")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOffline]);


	const create = (driver: Driver) => {
        setDrivers([...drivers.filter(g => g.id !== driver.id), driver])
    };

	const update = (driver: Driver) => {
        const updatedDrivers = drivers.map(g => g.id === driver.id ? driver : g);
        setDrivers([...updatedDrivers]);
    };

	const deleteDriver = (driver: Driver) => {
        const updatedDrivers = drivers.filter(g => g.id !== driver.id);
        setDrivers([...updatedDrivers]);
    };

	return (
		<DriverReactContext.Provider
			value={{
				drivers,
				create,
				update,
				delete: deleteDriver,
				loadStatus,
				serverError
			}}
		>
			{props.children}
		</DriverReactContext.Provider>
	);
}