import React, { PropsWithChildren, ReactElement } from "react";
import { ServerError } from "../services/server/WebClient";
import { DriverProvider } from "./DriverProvider";
import { ProductCatalogProvider } from "./ProductCatalogProvider";
import { OfflineTicketProvider } from "./OfflineTicketProvider";
import { TenantProvider } from "./TenantProvider";

export type AppDataLoadStatus = "ready" | "loading" | "error" | "offline";
export interface AppDataContext {
	loadStatus: AppDataLoadStatus;
	serverError: ServerError | null;
}

export function AppDataProviders(props: PropsWithChildren<{}>) {
	return(
		<Providers providers={[
			<TenantProvider key={1} />,
			<ProductCatalogProvider key={2} />,
			<DriverProvider key={3} />,
			<OfflineTicketProvider key={4} />
		]}>
			{props.children}
		</Providers>
	);
}

type ProviderNode = ReactElement<unknown>;

const Providers = (props: PropsWithChildren<{providers: ProviderNode[]}>) => {
	const renderProvider = (providers: ProviderNode[], children: React.ReactNode): React.ReactNode => {
	  const [provider, ...restProviders] = providers;
  
	  if (provider) {
		return React.cloneElement(
		  provider,
		  undefined,
		  renderProvider(restProviders, children)
		)
	  }
  
	  return children;
	}
  
	return (renderProvider(props.providers, props.children)) as ReactElement
  }