import { Modify } from "../../utility/modifyType";
import { SafeMoney, Money } from "../../utility/Money";
import { NodaTime } from "../../utility/NodaTime";
import { GeocodedAddress } from "../customer/GeocodedAddress";

export interface SearchableInvoice {
	id: string;
	shortId: string;
	customerId: string;
	customerName: string;
	paymentTermsId: string;
	paymentTermsName: string;
	issuedOn: Date;
	status: string;
	overdue: boolean;
	daysOverdue: number;
	dueBy: Date;
	total: SafeMoney;
	paid: SafeMoney;
	balance: SafeMoney;
	closed: boolean;
	voided: boolean;
}

type SerializedSearchableInvoice = Modify<SearchableInvoice, {
	issuedOn: string;
	dueBy: string;
	total: number;
	paid: number;
	balance: number;
}>;

const deserializeSearchableInvoice = (model: SerializedSearchableInvoice): SearchableInvoice => ({
	...model,
	issuedOn: NodaTime.deserializeFromLocalDate(model.issuedOn),
	dueBy: NodaTime.deserializeFromLocalDate(model.dueBy),
	total: Money.fromDecimal(model.total),
	paid: Money.fromDecimal(model.paid),
	balance: Money.fromDecimal(model.balance),
});

export const deserializeSearchableInvoices = (m: SerializedSearchableInvoice[]): SearchableInvoice[] => m.map(deserializeSearchableInvoice);

export enum InvoiceItemType
{
    Sales = 1,
    Fee = 2,
    Discount = 3,
    Payment = 4,
    PaymentReversal = 5
}

export interface InvoiceItemTax {
	taxRuleId: string | null;
	name: string;
	rateDescription: string;
	amountApplied: SafeMoney;
	ratePercent: number | null;
}

type SerializedInvoiceItemTax = Modify<InvoiceItemTax, {
	amountApplied: number;
}>;

function deserializeInvoiceItemTax(model: SerializedInvoiceItemTax): InvoiceItemTax {
	return {
		...model,
		amountApplied: Money.fromDecimal(model.amountApplied),
	};
}

interface InvoiceItemBase {
	id: string;
    invoiceId: string;
	transactionId: string;
    created: Date;
    description: string;
	totalAmount: SafeMoney;
}

type SerializedInvoiceItemBase = Modify<InvoiceItemBase, {
	totalAmount: number;
	created: string;
}>;

function deserializeInvoiceItemBase(model: SerializedInvoiceItemBase): InvoiceItemBase {
	return {
		...model,
		totalAmount: Money.fromDecimal(model.totalAmount),
		created: new Date(model.created),
	};
}

interface InvoiceItemSale extends InvoiceItemBase {
	saleAmount: SafeMoney;
	taxAmount: SafeMoney;
	appliedTaxes: InvoiceItemTax[];
}

type SerializedInvoiceItemSale = Modify<SerializedInvoiceItemBase, {
	saleAmount: number;
	taxAmount: number;
	appliedTaxes: SerializedInvoiceItemTax[];
}>;

function deserializeInvoiceItemSale(model: SerializedInvoiceItemSale): InvoiceItemSale {
	return {
		...deserializeInvoiceItemBase(model),
		saleAmount: Money.fromDecimal(model.saleAmount),
		taxAmount: Money.fromDecimal(model.taxAmount),
		appliedTaxes: model.appliedTaxes.map(deserializeInvoiceItemTax),
	};
}

export type InvoiceSalesItem = InvoiceItemSale & {
	type: InvoiceItemType.Sales;
	quantity: number;
	unitPrice: SafeMoney;
}

type SerializedInvoiceSalesItem = SerializedInvoiceItemSale & {
	type: InvoiceItemType.Sales;
	quantity: number;
	unitPrice: number;
};

export type InvoiceFeeItem = InvoiceItemSale & {
	type: InvoiceItemType.Fee;
}

type SerializedInvoiceFeeItem = SerializedInvoiceItemSale & {
	type: InvoiceItemType.Fee;
};

export type InvoiceDiscountItem = InvoiceItemSale & {
	type: InvoiceItemType.Discount;
	appliedToId: string;
}

type SerializedInvoiceDiscountItem = SerializedInvoiceItemSale & {
	type: InvoiceItemType.Discount;
	appliedToId: string;
};

export type InvoicePaymentItem = InvoiceItemBase & {
	type: InvoiceItemType.Payment;
}

type SerializedInvoicePaymentItem = SerializedInvoiceItemBase & {
	type: InvoiceItemType.Payment;
};

export type InvoicePaymentReversalItem = InvoiceItemBase & {
	type: InvoiceItemType.PaymentReversal;
	appliedToId: string;
}

type SerializedInvoicePaymentReversalItem = SerializedInvoiceItemBase & {
	type: InvoiceItemType.PaymentReversal;
	appliedToId: string;
};

export type InvoiceItem = InvoiceSalesItem | InvoiceFeeItem | InvoiceDiscountItem | InvoicePaymentItem | InvoicePaymentReversalItem;


type SerializedInvoiceItem = SerializedInvoiceSalesItem | SerializedInvoiceFeeItem | SerializedInvoiceDiscountItem | SerializedInvoicePaymentItem | SerializedInvoicePaymentReversalItem;


const deserializeInvoiceItem = (model: SerializedInvoiceItem): InvoiceItem => {
	switch (model.type) {
		case InvoiceItemType.Sales:
			return {
				...model,
				...deserializeInvoiceItemSale(model),
				quantity: model.quantity,
				unitPrice: Money.fromDecimal(model.unitPrice),
			}
		case InvoiceItemType.Fee:
			return {
				...model,
				...deserializeInvoiceItemSale(model),
			}
		case InvoiceItemType.Discount:
			return {
				...model,
				...deserializeInvoiceItemSale(model),
			}
		case InvoiceItemType.Payment:
			return {
				...model,
				...deserializeInvoiceItemBase(model),
			}
		case InvoiceItemType.PaymentReversal:
			return {
				...model,
				...deserializeInvoiceItemBase(model),
			}
	}
};

const deserializeInvoiceItems = (m: SerializedInvoiceItem[]): InvoiceItem[] => m.map(deserializeInvoiceItem);


export interface Invoice {
	id: string;
	shortId: string;
	customerId: string;
	customerName: string;
	customerEmail: string | null;
	customerEmailSecondary: string | null;
	allowSendEmail: boolean;
	customerAddress: GeocodedAddress;
	paymentTermsId: string;
	paymentTermsName: string;
	issuedOn: Date;
	dueBy: Date;
	notes: string | null;
	subtotal: SafeMoney;
	tax: SafeMoney;
	total: SafeMoney;
	paid: SafeMoney;
	balance: SafeMoney;
	closed: boolean;
	voided: boolean;
	ticket: { id: string; ticketNumber: string | null;} | null;
	items: InvoiceItem[];
}

export type SerializedInvoice = Modify<Invoice, { 
    issuedOn: string;
	dueBy: string;
	subtotal: number;
	tax: number;
	total: number;
	paid: number;
	balance: number;
	items: SerializedInvoiceItem[];
}>;

export const deserializeInvoice = (model: SerializedInvoice): Invoice => ({
    ...model,
	issuedOn: NodaTime.deserializeFromLocalDate(model.issuedOn),
	dueBy: NodaTime.deserializeFromLocalDate(model.dueBy),
	subtotal: Money.fromDecimal(model.subtotal),
	tax: Money.fromDecimal(model.tax),
	total: Money.fromDecimal(model.total),
	paid: Money.fromDecimal(model.paid),
	balance: Money.fromDecimal(model.balance),
	items: deserializeInvoiceItems(model.items),
});

export const deserializeInvoices = (m: SerializedInvoice[]): Invoice[] => m.map(deserializeInvoice);
