import { Box, Button, Card, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BackButton } from "../../../components/BackButton";
import { GridGrow } from "../../../components/GridGrow";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { IntegrationOption, IntegrationOptionCategory, IntegrationType } from "../../../entities/auth/IntegrationConfiguration";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { IntegrationService } from "../../../services/auth/IntegrationService";
import { NotFoundResult, ServerError } from "../../../services/server/WebClient";
import { Enum } from "../../../utility/Enum";
import { ConnectQualPayView } from "./qualpay/ConnectQualPayView";
import { ConfigureQualPayView } from "./qualpay/ConfigureQualPayView";
import { ConnectQuickBooksOnlineView } from "./quickbooks-online/ConnectQuickBooksOnlineView";
import { PageTitle } from "../../../components/PageTitle";
import { ConfigureQuickBooksOnlineView } from "./quickbooks-online/ConfigureQuickBooksOnlineView";
import { ConnectOtodataView } from "./otodata/ConnectOtodataView";
import { ConfigureOtodataView } from "./otodata/ConfigureOtodataView";
import { ConfigureDPPaymentsView } from "./dp-payments/ConfigureDPPaymentsView";
import { ConnectDPPaymentsView } from "./dp-payments/ConnectDPPaymentsView";

export function IntegrationsPage() {
	const alert = useAlert();
	const history = useHistory();
	const location = useLocation();
	const detailPath = location.pathname.split(routes.business.integrationPage).pop() ?? "";
	const action = detailPath.includes("configure") ? "configure" : "connect";

	const searchParams = new URLSearchParams(location.search);
	const successMessage = searchParams.get("successMessage");
	const errorMessage = searchParams.get("errorMessage");

	const [integrations, setIntegrations] = React.useState<IntegrationOption[]>();
	const [serverError, setServerError] = React.useState<ServerError| NotFoundResult>();

	const selectedType = useMemo(() => {
		if (detailPath.includes("qualpay")) {
			return IntegrationType.Qualpay;
		}
		if (detailPath.includes("quickbooks-online")) {
			return IntegrationType.QuickBooksOnline;
		}
		if (detailPath.includes("otodata")) {
			return IntegrationType.Otodata;
		}
		if (detailPath.includes("dispatchpro-payments")) {
			return IntegrationType.DispatchProPayments;
		}
		return null;
	}, [detailPath]);
	const selectedIntegration = useMemo(() => integrations?.find((i) => i.type === selectedType), [integrations, selectedType]);

	React.useEffect(() => {
		async function loadIntegrations() {
			const result = await IntegrationService.getIntegrationOptions();
			if (result.success) {
				setIntegrations(result.data);
			} else {
				setServerError(result);
			}
		}
		loadIntegrations();
	}, []);

	useEffect(() => {
		if (successMessage) {
			alert.success(successMessage);
		}
		else if (errorMessage) {
			alert.serverError({ message: errorMessage });
		}
		if (successMessage || errorMessage) {
			history.replace(routes.business.integrationPage);
		}
	}, [successMessage, errorMessage, alert, history]);


	const onUpdate = (integration: IntegrationOption) => {
		if (integrations) {
			setIntegrations(integrations.map((i) => (i.type === integration.type ? integration : i)));
		}
	};

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!integrations) {
		return <OverlayLoadingScreen />;
	}

	if (selectedIntegration && action === "connect") {
		return <ConnectView option={selectedIntegration} onConnect={onUpdate} />;
	}

	if (selectedIntegration && action === "configure") {
		return <ConfigurationView option={selectedIntegration} onUpdate={onUpdate} />;
	}

	return <IntegrationSelectionView integrations={integrations} />;
}

function IntegrationSelectionView(props: { integrations: IntegrationOption[] }) {
	const { integrations } = props;
	return (
		<Grid container spacing={2}>
			<PageTitle title="Integrations" />
			<Grid item xs={12}>
				<Typography variant="h4">Integrations</Typography>
			</Grid>
			{integrations.map((integration) => (
				<Grid item xs={12} md={6} lg={4} key={integration.type}>
					<IntegrationOptionCard integration={integration} />
				</Grid>
			))}
		</Grid>
	);
}

function IntegrationOptionCard(props: { integration: IntegrationOption }) {
	const { integration } = props;

	const history = useHistory();
	const isConfigured = integration.configuration !== null;

	const onSelect = () => {
		const action = isConfigured ? "configure" : "connect";
		history.push(routes.business.resolve.integrationDetailPage(integration.uriId, action));
	};

	return (
		<Card style={{ height: "100%" }}>
			<Grid container direction="column" style={{ height: "100%" }}>
				<Grid item>
					<Box p={1}>
						<Grid container alignItems="center">
							<Grid item>
								<Typography variant="h5">{integration.name}</Typography>
							</Grid>
							<GridGrow />
							<Grid item>
								<Typography variant="overline" color="textSecondary">
									{Enum.print(IntegrationOptionCategory, integration.category)}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item>
					<Grid container justify="center">
						<Grid item>
							<Box width={175} height={175}>
								<Grid container style={{ height: "100%" }} justify="center" alignItems="center">
									<Grid item>
										<img src={integration.smallLogoUrl} alt={integration.name} style={{ maxWidth: "100%", maxHeight: "100%" }} />
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant="body2" color="textSecondary" style={{ padding: 15 }}>
						{integration.shortDescription}
					</Typography>
				</Grid>
				<GridGrow />
				<Grid item>
					<Button size="small" color="primary" fullWidth variant={isConfigured ? "outlined" : "contained"} onClick={onSelect}>
						{isConfigured ? "Connected" : "See Details"}
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
}

function ConnectView(props: { option: IntegrationOption; onConnect: (option: IntegrationOption) => void }) {
	const { option } = props;

	if (option.type === IntegrationType.Qualpay) {
		if (option.configuration) {
			return <ConfigureQualPayView integration={option} />;
		}
		return <ConnectQualPayView option={option} onConnect={(configuration) => props.onConnect({ ...option, configuration })} />;
	}

	if (option.type === IntegrationType.DispatchProPayments) {
		if (option.configuration) {
			return <ConfigureDPPaymentsView integration={option} />;
		}
		return <ConnectDPPaymentsView option={option} onConnect={(configuration) => props.onConnect({ ...option, configuration })} />;
	}

	if (option.type === IntegrationType.QuickBooksOnline) {
		return <ConnectQuickBooksOnlineView option={option} />;
	}

	if (option.type === IntegrationType.Otodata) {
		return <ConnectOtodataView option={option} onConnect={(configuration) => props.onConnect({ ...option, configuration })} />;
	}

	return <div><BackButton/> Not implemented</div>;
}

function ConfigurationView(props: { option: IntegrationOption; onUpdate: (option: IntegrationOption) => void }) {
	const { option } = props;

	if (option.type === IntegrationType.Qualpay && option.configuration) {
		return <ConfigureQualPayView integration={option} />;
	}

	if (option.type === IntegrationType.QuickBooksOnline) {
		return <ConfigureQuickBooksOnlineView option={option} onUpdate={props.onUpdate} />;
	}

	if (option.type === IntegrationType.Otodata && option.configuration) {
		return <ConfigureOtodataView option={option} onUpdate={props.onUpdate} />;
	}

	return <div><BackButton/> Not implemented</div>;
}
