import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AdminTenantListPage } from "../pages/admin/tenants/AdminTenantListPage";
import { routes } from "../routes";

export function AdminRoutes() {
	return (
		<Switch>
			<Route exact path={routes.admin.tenantListPage}>
				<AdminTenantListPage />
			</Route>
			<Redirect to={routes.app.customerPage} />
		</Switch>
	);
}
