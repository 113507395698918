import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { NotFoundResult, ServerError } from "../services/server/WebClient";

export function ServerErrorView(props: { serverError: ServerError | NotFoundResult }) {
	const { serverError } = props;

	if(serverError.type === "missing") {
		return (
			<Grid container direction="column" justify="center" alignItems="center">
				<Grid item>
					<Grid container justify="center" alignItems="center">
						<Grid item>
							<Typography variant="h1">
								Not Found - {serverError.message}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container direction="column" justify="center" alignItems="center">
			<Grid item>
				<Grid container justify="center" alignItems="center">
					<Grid item>
						<Typography variant="h1">
							{serverError.statusCode} - {serverError.message}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
