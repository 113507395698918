import { DispatchProLedgerAccount, GeneralLedgerAccount, LedgerAccountType, LedgerClassification, QuickBooksDesktopLedgerAccount, QuickBooksOnlineLedgerAccount } from "../../entities/accounting/GeneralLedgerAccount";
import { apiServer } from "../server/Setting";
import { ValidatedServerResult, WebClient } from "../server/WebClient";

interface CreateGeneralLedgerAccountRequest{
    name: string;
    classification: LedgerClassification;
    type: LedgerAccountType;
}

interface CreateDispatchProAccountRequest extends CreateGeneralLedgerAccountRequest{}

interface CreateQuickBooksDesktopAccountRequest extends CreateGeneralLedgerAccountRequest {
    quickBooksAccountName: string;
}

interface CreateQuickBooksOnlineAccountRequest {
    name: string;
    quickBooksAccountId: string;
}

interface UpdateGeneralLedgerRequest {
    accountId: number;
    name?: string;
    classification?: LedgerClassification;
    type?: LedgerAccountType;
}

interface UpdateDispatchProAccountRequest {
    accountId: number;
    name?: string;
}

interface UpdateQuickBooksDesktopAccountRequest extends UpdateGeneralLedgerRequest {
    quickBooksAccountName?: string;
}

interface UpdateQuickBooksOnlineAccountRequest {
    accountId: number;
    name?: string;
    quickBooksAccountId?: string;
}

export interface QuickBooksOnlineAccount {
    id: string;
    name: string;
    type: LedgerAccountType;
    classification: LedgerClassification;
}

export const LedgerAccountService = {
    getAll: () => WebClient.Get<GeneralLedgerAccount[]>(`${apiServer}/api/ledger-accounts`),
    createDispatchProAccount: (request: CreateDispatchProAccountRequest) => WebClient.Post.Validated<DispatchProLedgerAccount>(`${apiServer}/api/ledger-accounts/dispatchpro`, request),
    updateDispatchProAccount: (request: UpdateDispatchProAccountRequest) => WebClient.Put.Validated<DispatchProLedgerAccount>(`${apiServer}/api/ledger-accounts/dispatchpro`, request),
    createQuickBooksDesktopAccount: (request: CreateQuickBooksDesktopAccountRequest) => WebClient.Post.Validated<QuickBooksDesktopLedgerAccount>(`${apiServer}/api/ledger-accounts/quickbooks/desktop`, request),
    updateQuickBooksDesktopAccount: (request: UpdateQuickBooksDesktopAccountRequest) => WebClient.Put.Validated<QuickBooksDesktopLedgerAccount>(`${apiServer}/api/ledger-accounts/quickbooks/desktop`, request),
    getQuickBooksOnlineAccounts: () => WebClient.GetOptional<QuickBooksOnlineAccount[]>(`${apiServer}/api/ledger-accounts/quickbooks/online/accounts`) as Promise<ValidatedServerResult<QuickBooksOnlineAccount[]>>,
    createQuickBooksOnlineAccount: (request: CreateQuickBooksOnlineAccountRequest) => WebClient.Post.Validated<QuickBooksOnlineLedgerAccount>(`${apiServer}/api/ledger-accounts/quickbooks/online`, request),
    updateQuickBooksOnlineAccount: (request: UpdateQuickBooksOnlineAccountRequest) => WebClient.Put.Validated<QuickBooksOnlineLedgerAccount>(`${apiServer}/api/ledger-accounts/quickbooks/online`, request),
}