import { Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { GridGrow } from "../../../components/GridGrow";
import { TaxRule, printCriteriaSummary, printTaxRate } from "../../../entities/accounting/TaxRule";
import { routes } from "../../../routes";
import { CreateTaxRuleDialog } from "./CreateTaxRuleDialog";
import { useTaxRulePage } from "./TaxRulePage";

export function TaxRuleListView() {
	const history = useHistory();
	const context = useTaxRulePage();
	const { taxRules } = context;

	const theme = useTheme();
	const errorColor = theme.palette.error.main;

	const [showCreate, setShowCreate] = React.useState(false);

	const onSelect = (taxRuleId: number) => history.push(routes.business.resolve.taxRuleDetailPage(taxRuleId));

	const printAssignedProducts = (taxRule: TaxRule) => {
		if (taxRule.assignedProductLines.length === 0 && taxRule.assignedProductListings.length === 0) {
			return "Not assigned to any products";
		}
		let description = "Assigned to ";
		if (taxRule.assignedProductLines.length > 0) {
			description += `product line${taxRule.assignedProductLines.length > 1 ? "s" : ""}: ${taxRule.assignedProductLines.map((x) => x.name).join(", ")}`;
		}

		if (taxRule.assignedProductListings.length > 0) {
			if (taxRule.assignedProductLines.length > 0) {
				description += " and ";
			}
			description += `product listing${taxRule.assignedProductListings.length > 1 ? "s" : ""}: ${taxRule.assignedProductListings.map((x) => x.name).join(", ")}`;
		}
		return description;
	};

	if (taxRules.length === 0)
		return (
			<>
				<CreateTaxRuleDialog open={showCreate} onClose={() => setShowCreate(false)} />
				<Typography variant="h4">No tax rules created yet</Typography>
				<Button variant="contained" color="primary" onClick={() => setShowCreate(true)}>
					Create tax rule
				</Button>
			</>
		);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Card>
					<CreateTaxRuleDialog open={showCreate} onClose={() => setShowCreate(false)} />
					<CardContent>
						<Grid container>
							<Grid item>
								<Typography variant="h5">Tax Rules</Typography>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button variant="contained" color="primary" onClick={() => setShowCreate(true)}>
									+ Add Tax Rule
								</Button>
							</Grid>
						</Grid>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Amount</TableCell>
									<TableCell>Criteria</TableCell>
									<TableCell>Products</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{taxRules.map((taxRule) => (
									<TableRow key={taxRule.id} hover onClick={() => onSelect(taxRule.id)}>
										<TableCell>{taxRule.name}</TableCell>
										<TableCell>{printTaxRate(taxRule)}</TableCell>
										<TableCell style={{ color: taxRule.matchGroups.length === 0 ? errorColor : undefined }}>{printCriteriaSummary(taxRule)}</TableCell>
										<TableCell
											style={{
												color: taxRule.assignedProductLines.length === 0 && taxRule.assignedProductListings.length === 0 ? errorColor : undefined,
											}}
										>
											{printAssignedProducts(taxRule)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
