import React from "react";
import { useAlert } from "../../../hooks/useAlert";
import { CheckBatchService, UnBatchedChecks } from "../../../services/accounting/CheckBatchService";
import { Grid, Typography, Card, TableHead, TableRow, TableCell, TableBody, Checkbox, Table, Button, IconButton } from "@material-ui/core";
import { BackButton } from "../../../components/BackButton";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { Price } from "../../../components/Price";
import { useHistory } from "react-router-dom";
import { LinkButton } from "../../../components/LinkButton";
import { routes } from "../../../routes";
import PrintIcon from "@material-ui/icons/Print";
import { Money } from "../../../utility/Money";

export function CreateCheckBatchPage() {
	const alert = useAlert();
	const history = useHistory();

	const [unBatchedChecks, setUnBatchedChecks] = React.useState<UnBatchedChecks>();
	const [selectedCheckIds, setSelectedCheckIds] = React.useState<string[]>([]);
	const [disabled, setDisabled] = React.useState(false);

	const selectedChecks = React.useMemo(() => unBatchedChecks?.checks.filter((check) => selectedCheckIds.includes(check.id)) ?? [], [unBatchedChecks, selectedCheckIds]);
	const selectedAmount = React.useMemo(() => selectedChecks.reduce((sum, check) => sum.add(check.amount), Money.zero), [selectedChecks]);

	React.useEffect(() => {
		async function loadUnBatchedChecks() {
			const result = await CheckBatchService.getPending();
			if (result.success) {
				setUnBatchedChecks(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadUnBatchedChecks();
	}, [alert]);

	const createBatch = async () => {
		if (!unBatchedChecks) return;
		setDisabled(true);
		const createdCheckBatchResult = await CheckBatchService.create(selectedCheckIds);
		const pendingChecksResults = await CheckBatchService.getPending();
		setDisabled(false);
		if (createdCheckBatchResult.success) {
			alert.success("Check batch created successfully.");
		} else if (createdCheckBatchResult.validation) {
			alert.validation(createdCheckBatchResult);
		} else {
			alert.serverError(createdCheckBatchResult);
		}

		if (pendingChecksResults.success) {
			if(pendingChecksResults.data.checks.length === 0){
				history.replace(routes.app.resolve.checkBatchPage());
			}
			setSelectedCheckIds([]);
			setUnBatchedChecks(pendingChecksResults.data);
		} else {
			alert.serverError(pendingChecksResults);
		}
	};

	const print = () => {
		const printWindow = window.open("", "New Check Batch", "width=800,height=600");
		if (printWindow) {
			printWindow.document.write(`
			<html>
				<head>
					<title>Open Tickets</title>
					<style>
						@page {
							size: auto;
							margin: 5mm;
						}
						body {
							margin: 5mm;
						}
						table {
							width: 100%;
							border-collapse: collapse;
							page-break-inside: auto;
						}
						tr {
							page-break-inside: avoid;
							page-break-after: auto;
						}
						table, th, td {
							border: 1px solid black;
						}
						th, td {
							padding: 5px;
						}
						th {
							text-align: left;
						}
						thead {
							display: table-header-group;
						}
					</style>
				</head>
				<body>
					<h4>Pending Check Batch</h4>`);
			printWindow.document.write("<table>");
			printWindow.document.write("<thead>");
			printWindow.document.write("<tr>");
			printWindow.document.write("<th>Date</th>");
			printWindow.document.write("<th>Amount</th>");
			printWindow.document.write("<th>Check Number</th>");
			printWindow.document.write("</tr>");
			printWindow.document.write("</thead>");
			printWindow.document.write("<tbody>");
			selectedChecks.forEach((check) => {
				printWindow.document.write("<tr>");
				printWindow.document.write(`<td>${check.created.toLocaleDateString()}<br/>${check.created.toLocaleTimeString()}</td>`);
				printWindow.document.write(`<td>${check.amount.toFormat("$0,0.00")}</td>`);
				printWindow.document.write(`<td>${check.checkNumber ?? "None"}</td>`);
				printWindow.document.write("</tr>");
			});
			printWindow.document.write("</tbody>");
			printWindow.document.write("<tfoot>");
			printWindow.document.write("<tr>");
			printWindow.document.write("<td>Checks:</td>");
			printWindow.document.write(`<td colspan="2">${selectedChecks.length}</td>`);
			printWindow.document.write("</tr>");
			printWindow.document.write("<tr>");
			printWindow.document.write("<td>Total:</td>");
			printWindow.document.write(`<td colspan="2">${selectedAmount.toFormat("$0,0.00")}</td>`);
			printWindow.document.write("</tr>");
			printWindow.document.write("</tfoot>");
			printWindow.document.write("</table>");
			printWindow.document.write("</body>");
			printWindow.document.write("</html>");
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}
	};

	if (!unBatchedChecks) return <OverlayLoadingScreen />;

	return (
		<>
			<PageTitle title="Create Check Batch" />
			<Grid container>
				<Grid item>
					<BackButton />
				</Grid>
				<Grid item>
					<Typography variant="h2" gutterBottom>
						Create Check Batch
					</Typography>
				</Grid>
				<Grid item>
					<IconButton onClick={print}>
						<PrintIcon />
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item lg={6} md={8} sm={10} xs={12}>
							<Card>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell width={100}>
												<Checkbox
													onChange={(e) => {
														if (e.target.checked) {
															setSelectedCheckIds(unBatchedChecks.checks.map((check) => check.id));
														} else {
															setSelectedCheckIds([]);
														}
													}}
												/>
											</TableCell>
											<TableCell>Date</TableCell>
											<TableCell>Amount</TableCell>
											<TableCell>Check Number</TableCell>
											<TableCell />
										</TableRow>
									</TableHead>
									<TableBody>
										{unBatchedChecks.checks.length === 0 && (
											<TableRow>
												<TableCell colSpan={5} style={{ textAlign: "center" }}>
													No pending checks found.
												</TableCell>
											</TableRow>
										)}
										{unBatchedChecks.checks.map((check) => (
											<TableRow key={check.id}>
												<TableCell width={100}>
													<Checkbox
														checked={selectedCheckIds.includes(check.id)}
														onChange={(e) => {
															if (e.target.checked) {
																setSelectedCheckIds([...selectedCheckIds, check.id]);
															} else {
																setSelectedCheckIds(selectedCheckIds.filter((id) => id !== check.id));
															}
														}}
													/>
												</TableCell>
												<TableCell style={{ fontSize: 15 }}>
													{check.created.toLocaleDateString()}
													<br />
													{check.created.toLocaleTimeString()}
												</TableCell>
												<TableCell style={{ fontSize: 20 }}>
													<Price value={check.amount} />
												</TableCell>
												<TableCell style={{ fontSize: 20 }}>{check.checkNumber ?? "None"}</TableCell>
												<TableCell>
													<LinkButton to={routes.app.resolve.paymentDetailPage(check.id)} newTab>
														See Details
													</LinkButton>
												</TableCell>
											</TableRow>
										))}
										<TableRow>
											<TableCell style={{ textAlign: "right" }}>Checks:</TableCell>
											<TableCell colSpan={4} style={{ fontSize: 25 }}>
												{selectedChecks.length}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{ textAlign: "right" }}>Batch Amount:</TableCell>
											<TableCell colSpan={4} style={{ fontSize: 25 }}>
												<Price value={selectedAmount} />
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={5}>
												<Button variant="contained" color="primary" onClick={createBatch} disabled={disabled || selectedChecks.length === 0}>
													Create Batch
												</Button>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
