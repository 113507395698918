export enum AppEnv {
    LocalDev = 1,
    Dev = 2,
    Prod = 3
}

const isLocal = window.location.host.includes("localhost:");
const isDev = window.location.host.includes("web.dispatchpro.dev");
const isProd = window.location.host.includes("web.dispatchpro.app");

export const runningOnProd = () => isProd;
export const runningOnDev = () => isDev;
export const runningLocal = () => isLocal;
export const getAppEnv = () => {
    if(isProd)
        return AppEnv.Prod;
    if(isLocal)    
        return AppEnv.LocalDev;
    return AppEnv.Dev;
};
export const isInAppEnv = (...includedEnvs: AppEnv[]) => includedEnvs.some(env => env === getAppEnv());