import { Typography, Dialog, DialogTitle, DialogContent, Grid, TextField, MenuItem, DialogActions, Button } from "@material-ui/core";
import { LoadingOverlay } from "@material-ui/data-grid";
import React, { useEffect, useState } from "react";
import { PriceTextField } from "../../../components/PriceTextField";
import { GeneralLedgerAccount, LedgerClassification } from "../../../entities/accounting/GeneralLedgerAccount";
import { Invoice } from "../../../entities/billing/Invoice";
import { useAlert } from "../../../hooks/useAlert";
import { LedgerAccountService } from "../../../services/accounting/LedgerAccountsService";
import { InvoiceService } from "../../../services/billing/InvoiceService";

export function AddTaxToInvoiceDialog(props: { open: boolean; onClose: () => void; invoice: Invoice; onTaxAdded: (invoice: Invoice) => void }) {
	const { open, invoice } = props;
	const alert = useAlert();

	const [liabilityAccounts, setLiabilityAccounts] = useState<GeneralLedgerAccount[]>();
	const [description, setDescription] = useState("");
	const [taxAmount, setTaxAmount] = useState<number | null>(null);
	const [taxAccountId, setTaxAccountId] = useState<number | null>(null);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		const getLiabilityAccounts = async () => {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setLiabilityAccounts(result.data.filter((account) => account.classification === LedgerClassification.Liability));
			} else {
				alert.serverError(result);
			}
		};
		getLiabilityAccounts();
	}, [alert]);

	const submit = async () => {
		if (!taxAccountId || !taxAmount) return;
		setDisabled(true);
		const result = await InvoiceService.addTaxToInvoice({ invoiceId: invoice.id, taxAccountId, taxAmount, description });
		setDisabled(false);

		if (result.success) {
			props.onTaxAdded(result.data);
			props.onClose();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	if (!liabilityAccounts) return <LoadingOverlay />;

	const canSubmit = taxAmount != null && taxAmount > 0 && taxAccountId != null;

	return (
		<Dialog open={open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Add Tax</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<TextField
							label="Tax Description"
							variant="outlined"
							fullWidth
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							disabled={disabled}
							helperText="This will be displayed on the invoice (Optional)"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<PriceTextField
							label="Tax Amount"
							variant="outlined"
							fullWidth
							value={taxAmount}
							onPriceChanged={(price) => setTaxAmount(price)}
							disabled={disabled}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						{liabilityAccounts.length === 0 && <Typography>No liability accounts configured</Typography>}
						{liabilityAccounts.length > 0 && (
							<TextField
								select
								label="Tax Account"
								variant="outlined"
								fullWidth
								value={taxAccountId ?? ""}
								onChange={(e) => setTaxAccountId(parseInt(e.target.value))}
								disabled={disabled}
							>
								{liabilityAccounts.map((account) => (
									<MenuItem key={account.id} value={account.id}>
										{account.name}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={props.onClose} disabled={disabled}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={submit} disabled={disabled || !canSubmit}>
					Add Tax
				</Button>
			</DialogActions>
		</Dialog>
	);
}
