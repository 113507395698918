import { NewTicketProduct } from "../../pages/customers/new-ticket/TicketProduct";
import { NewTicketTankFill } from "../../pages/customers/new-ticket/TicketTankFill";
import { Money, SafeMoney } from "../../utility/Money";
import { calculateTaxes } from "../accounting/TaxRule";
import { TicketPriceLockContract, TicketProduct, TicketQuoteItem, TicketQuoteType, TicketTankFill } from "../routing/DriverTicket";

export enum CustomerContractType {
    PriceLock = 1,
}

interface ContractCancellation {
    date: Date;
    reason: string;
    cancelledBy: string;
}

interface CustomerContractBase {
    id: string;
    customerId: string;
    createdBy: string;
    status: "Active" | "Expired" | "Pending" | "Cancelled" | "Completed";
    cancellation: ContractCancellation | null;
}

export interface PriceLockContract extends CustomerContractBase {
    type: CustomerContractType.PriceLock;
    startDate: Date;
    expiration: Date | null;
    productListingId: string;
    price: SafeMoney;
    priceCeiling: boolean;
    maxQuantity: number | null;
    quantityRemaining: number | null;
}

export type CustomerContract = PriceLockContract;

type SerializedCancellation = Omit<ContractCancellation, "date"> & { date: string };
type SerializedPriceLockContract = Omit<PriceLockContract, "startDate" | "expiration" | "cancellation" | "price"> & {
    startDate: string;
    expiration: string | null;
    cancellation: SerializedCancellation | null;
    price: number;
};
export type SerializedCustomerContract = SerializedPriceLockContract;

export function deserializeCustomerContract(serialized: SerializedCustomerContract): CustomerContract {
    return {
        ...serialized,
        startDate: new Date(serialized.startDate),
        expiration: serialized.expiration ? new Date(serialized.expiration) : null,
        cancellation: serialized.cancellation ? { ...serialized.cancellation, date: new Date(serialized.cancellation.date) } : null,
        price: Money.fromDecimal(serialized.price, 3),
    };
}

export function deserializeCustomerContracts(serialized: SerializedCustomerContract[]): CustomerContract[] {
    return serialized.map(deserializeCustomerContract);
}

export interface AppliedPriceLock {
	priceLock: PriceLockContract | TicketPriceLockContract;
	products: {
		name: string;
		isEstimate: boolean;
		originalQuantity: number;
		originalPrice: SafeMoney;
		originalSubtotal: SafeMoney;
		contractQuantity: number;
		contractPrice: SafeMoney;
		contractSubtotal: SafeMoney;
		priceDifference: SafeMoney;
		taxDifference: SafeMoney;
	}[];
	quantityUsed: number;
	quantityRemaining: number | null;
	priceDifference: SafeMoney;
	taxDifference: SafeMoney;
}


export function applyPriceLockToNewTicket(priceLock: PriceLockContract, tankFills: NewTicketTankFill[], ticketProducts: NewTicketProduct[]): AppliedPriceLock {
	const appliesToProducts = tankFills
		.map((tf) => ({
			productListingId: tf.listing.id,
			name: tf.listing.name,
			isEstimate: tf.isEstimate,
			quantity: tf.quantity ?? tf.estimatedUllage ?? 0,
			price: tf.price ?? Money.zero,
			taxRules: tf.taxRules.filter((tr) => tr.fixedAmount == null),
		}))
		.concat(
			ticketProducts.map((tp) => ({
				productListingId: tp.listing.id,
				name: tp.listing.name,
				isEstimate: false,
				quantity: tp.quantity ?? 0,
				price: tp.price ?? Money.zero,
				taxRules: tp.taxRules.filter((tr) => tr.fixedAmount == null),
			}))
		)
		.filter((p) => !p.price.isZero() && p.productListingId === priceLock.productListingId)
		.filter((p) => (priceLock.priceCeiling ? p.price.lessThan(priceLock.price) : true));

	if (priceLock.quantityRemaining == null) {
		const products = appliesToProducts.map((p) => ({
			name: p.name,
			isEstimate: p.isEstimate,
			originalQuantity: p.quantity,
			originalPrice: p.price,
			originalSubtotal: p.price.multiply(p.quantity),
			contractQuantity: p.quantity,
			contractPrice: priceLock.price,
			contractSubtotal: priceLock.price.multiply(p.quantity),
			priceDifference: priceLock.price.subtract(p.price).multiply(p.quantity),
			taxDifference: calculateTaxes(p.taxRules, priceLock.price.subtract(p.price).multiply(p.quantity)),
		}));
		return {
			priceLock: priceLock,
			products,
			quantityUsed: appliesToProducts.reduce((a, b) => a + b.quantity, 0),
			quantityRemaining: null,
			priceDifference: products.reduce((a, b) => a.add(b.priceDifference), Money.zero),
			taxDifference: products.reduce((a, b) => a.add(b.taxDifference), Money.zero),
		};
	}

	let quantityRemaining = priceLock.quantityRemaining;
	const products = [];
	for (const product of appliesToProducts) {
		if (quantityRemaining === 0) break;
		const contractQuantity = Math.min(quantityRemaining, product.quantity);
		quantityRemaining -= contractQuantity;
		products.push({
			name: product.name,
			isEstimate: product.isEstimate,
			originalQuantity: product.quantity,
			originalPrice: product.price,
			originalSubtotal: product.price.multiply(product.quantity),
			contractQuantity: contractQuantity,
			contractPrice: priceLock.price,
			contractSubtotal: priceLock.price.multiply(contractQuantity),
			priceDifference: priceLock.price.subtract(product.price).multiply(contractQuantity),
			taxDifference: calculateTaxes(product.taxRules, priceLock.price.subtract(product.price).multiply(contractQuantity)),
		});
	}
	return {
		priceLock: priceLock,
		products,
		quantityUsed: priceLock.quantityRemaining - quantityRemaining,
		quantityRemaining: quantityRemaining,
		priceDifference: products.reduce((a, b) => a.add(b.priceDifference), Money.zero),
		taxDifference: products.reduce((a, b) => a.add(b.taxDifference), Money.zero),
	};
}

export function applyPriceLock(priceLock: TicketPriceLockContract, quoteItems: TicketQuoteItem[]): AppliedPriceLock {
	const tankFills = quoteItems.filter((i) => i.type === TicketQuoteType.TankFill) as TicketTankFill[];
	const ticketProducts = quoteItems.filter((i) => i.type === TicketQuoteType.Product) as TicketProduct[];

	const appliesToProducts = tankFills
		.map((tf) => ({
			productListingId: tf.productListingId,
			name: tf.productName,
			isEstimate: tf.isEstimate,
			quantity: tf.quantity ?? tf.estimatedUllage ?? 0,
			price: tf.unitPrice,
			taxRules: tf.taxRules.filter((tr) => tr.fixedAmount == null),
		}))
		.concat(
			ticketProducts.map((tp) => ({
				productListingId: tp.productListingId,
				name: tp.productName,
				isEstimate: false,
				quantity: tp.quantity ?? 0,
				price: tp.unitPrice,
				taxRules: tp.taxRules.filter((tr) => tr.fixedAmount == null),
			}))
		)
		.filter((p) => !p.price.isZero() && p.productListingId === priceLock.productListingId)
		.filter((p) => (priceLock.priceCeiling ? p.price.lessThan(priceLock.price) : true));

	if (priceLock.maxQuantity == null || priceLock.quantityRemaining == null) {
		const products = appliesToProducts.map((p) => ({
			name: p.name,
			isEstimate: p.isEstimate,
			originalQuantity: p.quantity,
			originalPrice: p.price,
			originalSubtotal: p.price.multiply(p.quantity),
			contractQuantity: p.quantity,
			contractPrice: priceLock.price,
			contractSubtotal: priceLock.price.multiply(p.quantity),
			priceDifference: priceLock.price.subtract(p.price).multiply(p.quantity),
			taxDifference: calculateTaxes(p.taxRules, priceLock.price.subtract(p.price).multiply(p.quantity)),
		}));
		return {
			priceLock: priceLock,
			products,
			quantityUsed: appliesToProducts.reduce((a, b) => a + b.quantity, 0),
			quantityRemaining: null,
			priceDifference: products.reduce((a, b) => a.add(b.priceDifference), Money.zero),
			taxDifference: products.reduce((a, b) => a.add(b.taxDifference), Money.zero),
		};
	}

	let quantityRemaining = priceLock.quantityRemaining;
	const products = [];
	for (const product of appliesToProducts) {
		if (quantityRemaining === 0) break;
		const contractQuantity = Math.min(quantityRemaining, product.quantity);
		quantityRemaining -= contractQuantity;
		products.push({
			name: product.name,
			isEstimate: product.isEstimate,
			originalQuantity: product.quantity,
			originalPrice: product.price,
			originalSubtotal: product.price.multiply(product.quantity),
			contractQuantity: contractQuantity,
			contractPrice: priceLock.price,
			contractSubtotal: priceLock.price.multiply(contractQuantity),
			priceDifference: priceLock.price.subtract(product.price).multiply(contractQuantity),
			taxDifference: calculateTaxes(product.taxRules, priceLock.price.subtract(product.price).multiply(contractQuantity)),
		});
	}
	return {
		priceLock: priceLock,
		products,
		quantityUsed: priceLock.quantityRemaining - quantityRemaining,
		quantityRemaining: quantityRemaining,
		priceDifference: products.reduce((a, b) => a.add(b.priceDifference), Money.zero),
		taxDifference: products.reduce((a, b) => a.add(b.taxDifference), Money.zero),
	};
}