import Dinero from "dinero.js";

export type SafeMoney = Dinero.Dinero

const zero = Dinero({ amount: 0, precision: 2 });

export const Money = {
    zero: zero,
    fromDecimal: (numberAmount: number, precision?: number): SafeMoney => {
        const amount = Math.round(numberAmount * 10 ** (precision ?? 2));
        return precision ? Dinero({ amount, precision }) : Dinero({ amount });
    },
    print:(currency: SafeMoney, options?: { decimalScale?: number }) => {
        const decimalScale = options?.decimalScale ?? currency.getPrecision();
        const format = decimalScale > 0 ? `$0,0.${"0".repeat(decimalScale)}` : "$0,0";
        return currency.toFormat(format)
    },
    max: (...currencies: SafeMoney[]) => Dinero.maximum(currencies) as SafeMoney,
    min: (...currencies: SafeMoney[]) => Dinero.minimum(currencies) as SafeMoney,
    isPositive: (currency: SafeMoney) => currency.isPositive() && !currency.isZero(),
    isNegative: (currency: SafeMoney) => currency.isNegative() && !currency.isZero(),
};