import { Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { GridGrow } from "../../../components/GridGrow";
import { Price } from "../../../components/Price";
import { PaymentTerms } from "../../../entities/billing/PaymentTerms";
import { routes } from "../../../routes";
import { CreatePaymentTermsDialog } from "./CreatePaymentTermsDialog";
import { usePaymentTermsPage } from "./PaymentTermsPage";

export function PaymentTermsListView() {
    const history = useHistory();
    const context = usePaymentTermsPage();
    const { paymentTerms } = context;

    const [showCreate, setShowCreate] = React.useState(false);

    const onSelect = (paymentTermsId: string) => history.push(routes.business.resolve.paymentTermsDetailPage(paymentTermsId));

    
    

    return (
		<Grid container style={{ overflowY: "auto" }}>
			<Grid item>
				<Card>
					<CreatePaymentTermsDialog open={showCreate} onClose={() => setShowCreate(false)} />
					<CardContent>
						<Grid container>
							<Grid item>
								<Typography variant="h5">Payment Terms</Typography>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button variant="contained" color="primary" onClick={() => setShowCreate(true)}>
									+ Add Payment Terms
								</Button>
							</Grid>
						</Grid>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Days To Pay</TableCell>
									<TableCell>Description</TableCell>
                                    <TableCell>Overdue Fee</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{paymentTerms.map((paymentTerms) => (
									<TableRow key={paymentTerms.id} hover onClick={() => onSelect(paymentTerms.id)}>
										<TableCell>{paymentTerms.name} {paymentTerms.isDefault && <Typography variant="body2" color="textSecondary">[Default]</Typography>} </TableCell>
										<TableCell>{paymentTerms.dueDays}{paymentTerms.graceDays > 0 && ` + ${paymentTerms.graceDays} Grace Days`}</TableCell>
                                        <TableCell>{paymentTerms.description}</TableCell>
										<TableCell><DisplayOverdueFee paymentTerms={paymentTerms}/></TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export function DisplayOverdueFee (props: {paymentTerms: PaymentTerms}){
    const { overdueFee } = props.paymentTerms;
    if(!overdueFee) {
        return <>None</>;
    }

    return <>
        {overdueFee.percentFee && `${overdueFee.percentFee * 100}%`}
        {overdueFee.percentFee && overdueFee.fixedFee && " + "}
        {overdueFee.fixedFee && <Price value={overdueFee.fixedFee} />}
        {overdueFee.minimumFeeAmount && " (Minimum: "}
        {overdueFee.minimumFeeAmount && <Price value={overdueFee.minimumFeeAmount} />}
        {overdueFee.minimumFeeAmount && ")"}
    </>
}
