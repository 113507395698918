import React from "react";
import { ExemptionType, TaxExemption } from "../../entities/accounting/TaxExemption";
import { RedText } from "../RedText";

export function TaxExemptionStatus(props: { taxExemption: TaxExemption | null | undefined }) {
	const { taxExemption } = props;
    const type = taxExemption?.type === ExemptionType.Customer ? "Customer" : "Tank";
	if (taxExemption === undefined) return <>Loading...</>;
	if (taxExemption === null) return <>Not Tax Exempt</>;
	if (taxExemption.goodUntil === null) return <>{type} is Tax Exempt</>;
	if (taxExemption.valid) return <>{type} is Tax Exempt until {taxExemption.goodUntil.toLocaleDateString()}</>;
	return (
		<>
			{type} was Tax Exempt until {taxExemption.goodUntil.toLocaleDateString()} <RedText>[EXPIRED]</RedText>
		</>
	);
}