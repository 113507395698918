import React, { useMemo } from "react";
import { useTicketDetail } from "./DriverTicketDetailView";
import { TicketProduct } from "../../../entities/routing/DriverTicket";
import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import { Money, SafeMoney } from "../../../utility/Money";
import { Price } from "../../../components/Price";
import { BoxIconButton } from "../../../components/BoxIconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useAlert } from "../../../hooks/useAlert";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { toOptional } from "../../../utility/OptionalValue";
import { NumberTextField } from "../../../components/NumberTextField";
import { ButtonLink } from "../../../components/ButtonLink";
import { PriceTextField } from "../../../components/PriceTextField";
import { QuoteItemTaxDialog } from "./QuoteItemTaxDialog";

export function ProductQuoteItem(props: { ticketProduct: TicketProduct; isXs: boolean }) {
	const { ticketProduct, isXs } = props;
	const { unitPrice } = ticketProduct;

	const showSale = !isXs;

	const alert = useAlert();
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const price = unitPrice;
	const quantity = ticketProduct.quantity;

	const saleAmount = useMemo(() => {
		if (quantity == null) return null;
		return price.multiply(quantity);
	}, [quantity, price]);

	const onDelete = async () => {
		setDisabled(true);
		const result = await DriverTicketService.removeQuoteItem({ driverTicketId: ticket.id, quoteItemId: ticketProduct.id });
		setDisabled(false);

		if (result.success) {
			alert.success("Quote item removed");
			updateTicket(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<TableRow>
			<TableCell style={{ paddingLeft: 0, paddingRight: 5 }}>
				<Box display="flex" alignItems={showSale ? "flex-start" : "center"} flexDirection={showSale ? "column-reverse" : "row"}>
					{!isComplete && (
						<Box display="flex" width={showSale ? "100%" : undefined} marginRight={showSale ? 0 : 1}>
							<BoxIconButton color="secondary" onClick={onDelete} style={{ marginTop: showSale ? 6 : 0 }} disabled={disabled}>
								<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
							</BoxIconButton>
						</Box>
					)}
					<Box overflow="hidden" whiteSpace={showSale ? "nowrap" : undefined} textOverflow="ellipsis" maxWidth={showSale ? 165 : undefined} alignSelf="flex-start">
						<Typography variant="body1" noWrap={showSale ? true : undefined}>
							{ticketProduct.productName}
						</Typography>
					</Box>
				</Box>
			</TableCell>
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ProductQuantity ticketProduct={ticketProduct} />
			</TableCell>
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ProductPrice ticketProduct={ticketProduct} />
			</TableCell>
			{!props.isXs && (
				<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
					{saleAmount == null && "Pending"}
					{saleAmount != null && <Price value={saleAmount} />}
				</TableCell>
			)}
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<ProductTax product={ticketProduct} subtotal={saleAmount} />
			</TableCell>
		</TableRow>
	);
}

function ProductQuantity(props: { ticketProduct: TicketProduct }) {
	const { ticketProduct } = props;

	const alert = useAlert();
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const [editQuantity, setEditQuantity] = React.useState(false);
	const [quantity, setQuantity] = React.useState(ticketProduct.quantity);

	const onQuantitySubmit = async () => {
		setDisabled(true);
		const result = await DriverTicketService.updateQuoteProduct({ driverTicketId: ticket.id, quoteItemId: ticketProduct.id, quantity: toOptional(quantity) });
		setDisabled(false);

		if (result.success) {
			alert.success("Quantity updated");
			updateTicket(result.data);
			setEditQuantity(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onQuantityEscape = () => {
		setEditQuantity(false);
		setQuantity(ticketProduct.quantity);
	};

	if (isComplete) {
		return <>{ticketProduct.quantity}</>;
	}

	if (editQuantity) {
		return (
			<NumberTextField
				size="small"
				variant="outlined"
				disabled={disabled}
				value={quantity}
				onNumberChange={setQuantity}
				autoFocus
				onBlur={onQuantitySubmit}
				style={{ minWidth: 80 }}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
						onQuantitySubmit();
					} else if (e.key === "Escape") {
						e.stopPropagation();
						onQuantityEscape();
					}
				}}
			/>
		);
	}
	if (ticketProduct.quantity != null) {
		return (
			<ButtonLink onClick={() => setEditQuantity(true)} disabled={disabled}>
				{ticketProduct.quantity}
			</ButtonLink>
		);
	}
	return (
		<ButtonLink onClick={() => setEditQuantity(true)} disabled={disabled}>
			Pending
		</ButtonLink>
	);
}

function ProductPrice(props: { ticketProduct: TicketProduct }) {
	const { ticketProduct } = props;

	const alert = useAlert();
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const [editPrice, setEditPrice] = React.useState(false);
	const [priceValue, setPriceValue] = React.useState<number | null>(ticketProduct.unitPrice.toRoundedUnit(3));

	const price = ticketProduct.unitPrice;

	const onPriceSubmit = async () => {
		if (priceValue === null) {
			alert.error("Price is required");
			return;
		}

		setDisabled(true);
		const result = await DriverTicketService.updateQuoteProduct({ driverTicketId: ticket.id, quoteItemId: ticketProduct.id, unitPrice: toOptional(priceValue) });
		setDisabled(false);

		if (result.success) {
			alert.success("Price updated");
			updateTicket(result.data);
			setEditPrice(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onPriceEscape = () => {
		setEditPrice(false);
		setPriceValue(ticketProduct.unitPrice.toRoundedUnit(3));
	};

	if (isComplete) {
		return <Price value={price} />;
	}
	if (editPrice) {
		return (
			<div tabIndex={0} onBlur={onPriceSubmit}>
				<PriceTextField
					size="small"
					variant="outlined"
					value={priceValue}
					onPriceChanged={setPriceValue}
					autoFocus
					disabled={disabled}
					style={{ minWidth: 80 }}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onPriceSubmit();
						}
						if (e.key === "Escape") {
							e.stopPropagation();
							onPriceEscape();
						}
					}}
				/>
			</div>
		);
	}

	return (
		<ButtonLink onClick={() => setEditPrice(true)} disabled={disabled}>
			<Price value={price} decimalOptional doNotRound />
		</ButtonLink>
	);
}

function ProductTax(props: { product: TicketProduct; subtotal: SafeMoney | null }) {
	const { product } = props;

	const { ticket, disabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const [editTaxes, setEditTaxes] = React.useState(false);

	if (isComplete) {
		return <>{<Price value={product.taxAmount ?? Money.zero} />}</>;
	}

	return (
		<>
			{product.taxAmount ? (
				<ButtonLink onClick={() => setEditTaxes(true)}>
					<Price value={product.taxAmount} />
				</ButtonLink>
			) : (
				<ButtonLink onClick={() => setEditTaxes(true)} disabled={disabled}>
					{product.taxRules.length === 0 ? <Price value={Money.zero} /> : "TBD"}
				</ButtonLink>
			)}
			<QuoteItemTaxDialog quoteItem={product} subtotal={props.subtotal} open={editTaxes} onClose={() => setEditTaxes(false)} />
		</>
	);
}
