import { DispatchProPaymentsIntegration, IntegrationOption, OtodataIntegration, OtodataIntegrationOption, QualPayIntegration, QuickBooksOnlineIntegrationOption } from "../../entities/auth/IntegrationConfiguration";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreateQualPayIntegrationRequest {
	merchantId: number;
}

interface UpdateQuickBooksOnlineSettingsRequest {
	sync: boolean;
}

export const IntegrationService = {
	getIntegrationOptions: () => WebClient.GetOptional<IntegrationOption[]>(`${apiServer}/api/integrations`),
	connectToQualPay: (request: CreateQualPayIntegrationRequest) => WebClient.Post.Validated<QualPayIntegration>(`${apiServer}/api/integrations/qualpay`, request),
	connectToDispatchProPayments: (request: {merchantId: string}) => WebClient.Post.Validated<DispatchProPaymentsIntegration>(`${apiServer}/api/integrations/dispatch-pro-payments`, request),
	connectToOtoData: (request: {apiKey: string}) => WebClient.Post.Validated<OtodataIntegration>(`${apiServer}/api/integrations/otodata`, request),
	updateOtoData: (request: {apiKey: string}) => WebClient.Put.Validated<OtodataIntegrationOption>(`${apiServer}/api/integrations/otodata`, request),
	getQuickBooksAuthorizationUrl: () => WebClient.GetOptional<{ authorizationUrl: string }>(`${apiServer}/api/integrations/quickbooks-online/authorization-url`),
	updateQuickBooksOnlineIntegration: (request: UpdateQuickBooksOnlineSettingsRequest) =>
		WebClient.Put.Unvalidated<QuickBooksOnlineIntegrationOption>(`${apiServer}/api/integrations/quickbooks-online`, request),
};
