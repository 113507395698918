import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { routes } from "../../routes";
import { PageTitle } from "../../components/PageTitle";
import PlaceIcon from "@material-ui/icons/Place";
import ListIcon from "@material-ui/icons/List";
import NavigationIcon from "@material-ui/icons/Navigation";
import HomeIcon from "@material-ui/icons/Home";
import { BuilderPage } from "./BuilderPage";
import { GridGrow } from "../../components/GridGrow";

export function RouteBuilderArea() {
	return (
		<Box id="normalizer" height="100vh">
			<Box display="flex" height="100%">
				<LeftNav />
				<Switch>
					<Route exact path={routes.routeBuilder.builderPage}>
						<BuilderPage />
					</Route>
					<Route exact path={routes.routeBuilder.optimizerPage}>
						<OptimizerPage />
					</Route>
					<Route exact path={routes.routeBuilder.routeListPage}>
						<RouteListPage />
					</Route>
					<Redirect to={routes.routeBuilder.builderPage} />
				</Switch>
			</Box>
		</Box>
	);
}

function LeftNav() {
	const history = useHistory();
	return (
		<Paper style={{ width: 100, height: "100%" }} elevation={5}>
			<Grid direction="column" container style={{ height: "100%" }}>
				<Grid item>
					<Button style={{ width: "100%", height: "100px" }} onClick={() => history.push(routes.routeBuilder.builderPage)}>
						<Grid container direction="column" alignItems="center">
							<Grid item>
								<PlaceIcon fontSize="large" />
							</Grid>
							<Grid item>
								<Typography style={{ textTransform: "none" }}>Builder</Typography>
							</Grid>
						</Grid>
					</Button>
					<Button style={{ width: "100%", height: "100px" }} disabled onClick={() => history.push(routes.routeBuilder.optimizerPage)}>
						<Grid container direction="column" alignItems="center">
							<Grid item>
								<NavigationIcon fontSize="large" />
							</Grid>
							<Grid item>
								<Typography style={{ textTransform: "none" }}>Optimizer</Typography>
							</Grid>
						</Grid>
					</Button>
					<Button style={{ width: "100%", height: "100px" }} disabled onClick={() => history.push(routes.routeBuilder.routeListPage)}>
						<Grid container direction="column" alignItems="center">
							<Grid item>
								<ListIcon fontSize="large" />
							</Grid>
							<Grid item>
								<Typography style={{ textTransform: "none" }}>Routes</Typography>
							</Grid>
						</Grid>
					</Button>
				</Grid>
				<GridGrow />
				<Grid item>
					<Button style={{ width: "100%", height: "100px" }} onClick={() => history.push(routes.app.resolve.customerListPage())}>
						<Grid container direction="column" alignItems="center">
							<Grid item>
								<HomeIcon fontSize="large" />
							</Grid>
							<Grid item>
								<Typography style={{ textTransform: "none" }}>Home</Typography>
							</Grid>
						</Grid>
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

function OptimizerPage() {
	return (
		<Box flexDirection="column" flexGrow={1} display="flex">
			<PageTitle title="Optimizer" />
			<Header />
			<MainContent />
			<Footer />
		</Box>
	);
}

function RouteListPage() {
	return (
		<Box flexDirection="column" flexGrow={1} display="flex">
			<PageTitle title="Route List" />
			<Header />
			<MainContent />
			<Footer />
		</Box>
	);
}

function Header() {
	return (
		<Box height="100px" style={{ backgroundColor: "lightblue" }}>
			Header
		</Box>
	);
}

function MainContent() {
	return (
		<Box flexGrow={1} style={{ backgroundColor: "lightyellow" }}>
			Main Content
		</Box>
	);
}

function Footer() {
	return (
		<Box minHeight="100px" maxHeight="25vh" style={{ backgroundColor: "lightgreen" }}>
			Footer
		</Box>
	);
}
