import { Card, CardHeader, Grid, List, ListItem, ListItemText } from "@material-ui/core";
import { LoadingOverlay } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import Auth from "../../../authorization/Auth";
import { PageTitle } from "../../../components/PageTitle";
import { UserClaim } from "../../../entities/auth/UserClaim";
import { useAlert } from "../../../hooks/useAlert";
import { useAppUser } from "../../../providers/AppUserProvider";
import { routes } from "../../../routes";
import { AdminService, TenantAdminModel } from "../../../services/admin/AdminService";

export function AdminTenantListPage() {
	const alert = useAlert();
	const user = useAppUser();
	const isAuthorized = user.userClaims.some((c) => c === UserClaim.Super_Admin);

	const [tenants, setTenants] = React.useState<TenantAdminModel[]>();
    const [disabled, setDisabled] = React.useState<boolean>(false);

	useEffect(() => {
		const getTenants = async () => {
			const result = await AdminService.getTenants();
			if (result.success) setTenants(result.data);
			else alert.serverError(result);
		};
		if (isAuthorized) getTenants();
	}, [alert, isAuthorized]);

    const switchTenant = async (tenantId: string) => {
        setDisabled(true);
        const result = await AdminService.changeTenant(tenantId);
        if(result.success){
            Auth.setToken(result.data.jwt);
            window.location.href = routes.app.resolve.customerListPage();
        } else {
            alert.serverError(result);
            setDisabled(false);
        }
    }

	if (!isAuthorized) {
		return null;
	}

	if (!tenants) return <LoadingOverlay />;

	return (
		<>
			<PageTitle title="Tenants" />
			<Grid container>
				<Grid item md={6} sm={8} xs={12}>
					<Card>
						<CardHeader title="Switch Tenant" />
						<List>
							{tenants.map((t) => (
								<ListItem key={t.id} button disabled={disabled} onClick={() => switchTenant(t.id)} >
									<ListItemText primary={t.name} secondary={t.timezone}/>
								</ListItem>
							))}
						</List>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
