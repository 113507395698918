import { Modify } from "../../utility/modifyType";

export enum DeviceType {
    Unknown = 0,
    Vertical = 1,
    Horizontal = 2,
    UltraSonic = 3
}


export enum DeviceStatus {
    Unknown = 0,
    Ok = 1,
    Low_Alarm = 2,
    Critical_Low_Alarm = 3,
    Overfill_Alarm = 4,
    Rapid_Draw = 5,
    Fill_Detection = 6,
    Comm_Trouble = 7,
    Uninstalled = 8,
    Inactivity_Detection = 9
}


export enum TankFormType {
    Unknown = -1,
    HorizontalCylinder = 0,
    VerticalCylinder = 1,
    Rectangle = 2,
    Reserved = 3,
    VerticalOval = 4
}

export enum SensorTroubleStatus {
    Unknown = -1,
    None = 0,
    Sensor_Overload = 1,
    /// A level of 0% can be considered as ValueUnderSpecs
    Value_Under_Specs = 2,
    /// A level of 100% can be considered as ValueOverSpecs.
    Value_Over_Specs = 3,
    Sensor_Unreadable = 4,
    Sensor_Accuracy = 5
}

export interface OtodataDevice {
        // Device Info
        id: number;
        supplier: string;
        model: string;
        type: DeviceType;
        offset: number;
    
        // Device Status
        signalStrength: number;
        status: DeviceStatus;
        isInstalled: boolean;
    
        // Sensor
        sensorTrouble: SensorTroubleStatus;
        readingTrouble: boolean;
        temperature: number;
        lastRead: Date | null;
        lowAlarmThreshold: number;
        criticalLowAlarmThreshold: number;
    
    
        // Battery
        batteryLevel: number;
        batteryAlarm: boolean;
    
        // Optional Stuff?
        product: string | null;
        customerId: string | null;
        databaseId: string | null;
        generalLedgerLocation: string | null;
        name: string | null;
        address: string | null;
        city: string | null;
        region: string | null;
        postalCode: string | null;
        country: string | null;
        email: string | null;
        route: string | null;
        note: string | null;
        latitude: number;
        longitude: number;
        
        //Specs
        tankNumber: string | null;
        tankSerialNumber: string | null;
        tankFormType: TankFormType | null;
        measureDepth: number | null;
        measureHeight: number | null;
        measureWidth: number | null;
        capacity: number;
        
        
        // Gas Levels 
        lastFill: Date | null;
        lastLevel: number;
        ullage: number;
        correctedVolume: number;
        gallonsUsed72Hours: number;
        gallonsUsed48Hours: number;
        gallonsUsed24Hours: number;
        hoursToEmpty: number;
        highDrainAlarm: boolean;        
}

type SerializedOtodataDevice = Modify<OtodataDevice, {
    lastRead: string | null;
    lastFill: string | null;
}>;

export const deserializeOtodataDevice = (model: SerializedOtodataDevice): OtodataDevice => {
    return {
        ...model,
        lastRead: model.lastRead ? new Date(model.lastRead) : null,
        lastFill: model.lastFill ? new Date(model.lastFill) : null
    }
}

export const deserializeOtodataDevices = (models: SerializedOtodataDevice[]): OtodataDevice[] => models.map(deserializeOtodataDevice);

interface TankLevelLog {
    logDate: Date;
    sensorTrouble: SensorTroubleStatus;
    batteryLevel: number;
    temperature: number;
    levelPercent: number;
}

type SerializedTankLevelLog = Modify<TankLevelLog, {
    logDate: string;
}>;

export interface TankLevels {
    id: number;
    startDate: Date | null;
    endDate: Date | null;
    logs: TankLevelLog[];
}

type SerializedTankLevels = Modify<TankLevels, {
    startDate: string | null;
    endDate: string | null;
    logs: SerializedTankLevelLog[];
}>;

export const deserializeTankLevels = (model: SerializedTankLevels): TankLevels => {
    return {
        ...model,
        startDate: model.startDate ? new Date(model.startDate) : null,
        endDate: model.endDate ? new Date(model.endDate) : null,
        logs: model.logs.map(log => ({
            ...log,
            logDate: new Date(log.logDate)
        }))
    }
}
