import * as React from "react";
import { PageTitle } from "../../components/PageTitle";
import { Paper, Grid, Hidden, Typography, List, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { DriverStatus } from "../../entities/routing/DriverStatus";
import { useRoutePage } from "./RoutePage";

export function RouteListView() {
	const routePageContext = useRoutePage();
	const { drivers } = routePageContext;

	return (
		<Grid container>
			<PageTitle title="Select Route Driver" />
			<Grid item>
				<Paper style={{ minWidth: 250 }}>
					<Hidden smDown>
						<Typography variant="h5" style={{ padding: 10 }}>
							Driver Routes
						</Typography>
					</Hidden>
					<List>
						{drivers
							.filter((d) => d.status === DriverStatus.Active)
							.map((driver) => (
								<ListItem key={driver.id} button onClick={() => routePageContext.selectDriver(driver)}>
									<ListItemText primary={driver.name} />
									<ListItemSecondaryAction>
										<KeyboardArrowRightIcon />
									</ListItemSecondaryAction>
								</ListItem>
							))}
					</List>
				</Paper>
			</Grid>
		</Grid>
	);
}
