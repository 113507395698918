import { useState } from "react";
import { useServerErrorAlert } from "./useServerErrorAlert";
import { useSuccessAlert } from "./useSuccessAlert";
import { useValidationAlert } from "./useValidationAlert";

export function useAlert(){
    const success = useSuccessAlert();
    const validation = useValidationAlert();
    const serverError = useServerErrorAlert();
    const error = (message: string) => serverError({message});
    
    // wrap meta object in state since these should never rerender 
    // and this make it safe for use in useEffect
    const [alerts] = useState({
        success,
        validation,
        serverError,
        error
    })
    
    return alerts;
}