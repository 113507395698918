import React, { useState } from "react";
import {
	Box,
	Button,
	Grid,
	Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { TaxExemption } from "../../entities/accounting/TaxExemption";
import { useAlert } from "../../hooks/useAlert";
import { TaxExemptionService } from "../../services/accounting/TaxExemptionService";
import { ButtonLink } from "../ButtonLink";

export function UploadDocumentForm(props: { taxExemption: TaxExemption, onUpload: (taxExemption: TaxExemption) => void }) {
	const { taxExemption } = props;
    const alert = useAlert();

	const [disabled, setDisabled] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [expirationDate, setExpirationDate] = useState<Date | null>(null);

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			setFile(file);
		}
	};

	const onUpload = async () => {
		if (!file) {
			return;
		}
		setDisabled(true);
		const result = await TaxExemptionService.uploadDocument({taxExemptionId: taxExemption.id, file, expirationDate});
		setDisabled(false);
		if (result.success) {
		    alert.success("Document Uploaded");
            setFile(null);
            setExpirationDate(null);
            props.onUpload(result.data);
		} else if (result.validation) {
		    alert.validation(result);
		} else {
		    alert.serverError(result);
		}
	};

	return (
		<Box mt={1}>
			<Typography variant="h6">Upload Document</Typography>
			<Grid container spacing={2} alignItems="center">
				{!file && (
					<Grid item md={4} xs={12}>
						<input type="file" onChange={onFileChange} hidden id="UploadDocument" />
						<Button variant="contained" color="primary" component="label" htmlFor="UploadDocument" disabled={disabled}>
							Choose File
						</Button>
					</Grid>
				)}
				{file && (
					<Grid item md={4} xs={12}>
						<Typography>
							{file.name} <ButtonLink onClick={() => setFile(null)}>[Remove]</ButtonLink>
						</Typography>
					</Grid>
				)}
				<Grid item md={5} xs={12}>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="MM/dd/yyyy"
						label="Expiration Date"
						inputVariant="outlined"
						fullWidth
						value={expirationDate}
						disabled={disabled}
						onChange={setExpirationDate}
					/>
				</Grid>
				<Grid item md={3} xs={12}>
					<Button onClick={onUpload} color="primary" variant="contained" disabled={disabled || !file}>
						Upload
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}
