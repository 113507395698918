const greenToRedGradient = [
    "#ffe066",
    "#ffdd66",
    "#ffda66",
    "#ffd666",
    "#ffd366",
    "#ffd066",
    "#ffcd66",
    "#ffca66",
    "#ffc766",
    "#ffc466",
    "#ffc166",
    "#ffbe66",
    "#ffbb66",
    "#ffb866",
    "#ffb566",
    "#ffb266",
    "#ffb066",
    "#ffae66",
    "#ffad66",
    "#ffab66",
    "#ffaa66",
    "#ffa866",
    "#ffa766",
    "#ffa566",
    "#ffa466",
    "#ffa266",
    "#ffa166",
    "#ff9f66",
    "#ff9e66",
    "#ff9c66",
    "#ff9b66",
    "#ff9966",
    "#ff9866",
    "#ff9666",
    "#ff9566",
    "#ff9366",
    "#ff9266",
    "#ff9066",
    "#ff8f66",
    "#ff8d66",
    "#ff8c66",
    "#ff8a66",
    "#ff8866",
    "#ff8766",
    "#ff8566",
    "#ff8466",
    "#ff8266",
    "#ff8166",
    "#ff7f66",
    "#ff7e66",
    "#ff7c66",
    "#ff7b66",
    "#ff7966",
    "#ff7866",
    "#ff7666",
    "#ff7566",
    "#ff7366",
    "#ff7266",
    "#ff7066",
    "#ff6f66",
    "#ff6d66",
    "#ff6c66",
    "#ff6a66",
    "#ff6966",
    "#ff6766",
];

export const ColorGradient = {
	getUrgencyColor: (min: number, max: number, value: number) => {
		const gradientValue = Math.max(min, Math.min(max, value));
		const range = max - min;
		const percentage = (gradientValue - min) / range;
		const index = Math.floor(percentage * (greenToRedGradient.length - 1));
        // console.log("gradientValue", gradientValue, "range", range, "percentage", percentage, "index", index);
		return greenToRedGradient[index];
	},
};
