import React, { useMemo } from "react";
import { useTicketDetail } from "./DriverTicketDetailView";
import { TicketTankFill } from "../../../entities/routing/DriverTicket";
import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import { Money, SafeMoney } from "../../../utility/Money";
import { Price } from "../../../components/Price";
import { calculateTaxes } from "../../../entities/accounting/TaxRule";
import { BoxIconButton } from "../../../components/BoxIconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useAlert } from "../../../hooks/useAlert";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useTenant } from "../../../providers/TenantProvider";
import { toOptional } from "../../../utility/OptionalValue";
import { NumberTextField } from "../../../components/NumberTextField";
import { ButtonLink } from "../../../components/ButtonLink";
import { PriceTextField } from "../../../components/PriceTextField";
import { QuoteItemTaxDialog } from "./QuoteItemTaxDialog";

export function TankFillQuoteItem(props: { tankFill: TicketTankFill; multipleFills: boolean; isXs: boolean }) {
	const { tankFill, multipleFills, isXs } = props;
	const { unitPrice, reportedTankPercentage, tank } = tankFill;

	const showSale = !isXs;

	const alert = useAlert();
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const price = unitPrice;

	const { tenant } = useTenant();
	const { targetTankFillPercentage } = tenant;

	const totalUllage = useMemo(() => tank.gallons * targetTankFillPercentage, [tank.gallons, targetTankFillPercentage]);
	const estimatedUllage = useMemo(
		() => (reportedTankPercentage === null ? null : totalUllage - tank.gallons * reportedTankPercentage),
		[reportedTankPercentage, tank.gallons, totalUllage]
	);
	const isEstimate = tankFill.quantity == null && reportedTankPercentage != null;

	const quantity = useMemo(() => tankFill.quantity ?? estimatedUllage, [tankFill.quantity, estimatedUllage]);

	const saleAmount = useMemo(() => {
		if (quantity == null) return null;
		return price.multiply(quantity);
	}, [quantity, price]);

	const onDelete = async () => {
		setDisabled(true);
		const result = await DriverTicketService.removeQuoteItem({ driverTicketId: ticket.id, quoteItemId: tankFill.id });
		setDisabled(false);

		if (result.success) {
			alert.success("Quote item removed");
			updateTicket(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<TableRow>
			<TableCell style={{ paddingLeft: 0, paddingRight: 5 }}>
				<Box display="flex" alignItems={showSale ? "flex-start" : "center"} flexDirection={showSale ? "column-reverse" : "row"}>
					{!isComplete && (
						<Box display="flex" width={showSale ? "100%" : undefined} marginRight={showSale ? 0 : 1}>
							<BoxIconButton color="secondary" onClick={onDelete} style={{ marginTop: showSale ? 6 : 0 }} disabled={disabled}>
								<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
							</BoxIconButton>
						</Box>
					)}
					<Box overflow="hidden" whiteSpace={showSale ? "nowrap" : undefined} textOverflow="ellipsis" maxWidth={showSale ? 165 : undefined} alignSelf="flex-start">
						<Typography variant="body1" noWrap={showSale ? true : undefined}>
							{tankFill.productName}
						</Typography>
						{multipleFills && (
							<Typography variant="caption" color="textSecondary" noWrap={showSale ? true : undefined}>
								{tankFill.tank.gallons} gal {tankFill.tank.description ?? ""}
							</Typography>
						)}
					</Box>
				</Box>
			</TableCell>
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<TankFillQuantity tankFill={tankFill} />
				{estimatedUllage != null && (
					<>
						<br />
						<Typography variant="overline" color="textSecondary">
							~{estimatedUllage.toFixed(2)}
						</Typography>
					</>
				)}
			</TableCell>
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<TankFillPrice tankFill={tankFill} />
			</TableCell>
			{!props.isXs && (
				<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
					{saleAmount == null && "Pending"}
					{saleAmount != null && (
						<>
							{isEstimate ? "~" : ""}
							<Price value={saleAmount} />
						</>
					)}
				</TableCell>
			)}
			<TableCell align="right" style={{ paddingLeft: 5, paddingRight: 5 }}>
				<TankFillTax tankFill={tankFill} subtotal={saleAmount} isEstimate={isEstimate} />
			</TableCell>
		</TableRow>
	);
}

function TankFillQuantity(props: { tankFill: TicketTankFill }) {
	const { tankFill } = props;

	const alert = useAlert();
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const [editQuantity, setEditQuantity] = React.useState(false);
	const [quantity, setQuantity] = React.useState(tankFill.quantity);

	const onQuantitySubmit = async () => {
		setDisabled(true);
		const result = await DriverTicketService.updateTankFill({ driverTicketId: ticket.id, quoteItemId: tankFill.id, quantity: toOptional(quantity) });
		setDisabled(false);

		if (result.success) {
			alert.success("Quantity updated");
			updateTicket(result.data);
			setEditQuantity(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onQuantityEscape = () => {
		setEditQuantity(false);
		setQuantity(tankFill.quantity);
	};

	if (isComplete) {
		return <>{tankFill.quantity}</>;
	}

	if (editQuantity) {
		return (
			<NumberTextField
				size="small"
				variant="outlined"
				disabled={disabled}
				value={quantity}
				onNumberChange={setQuantity}
				autoFocus
				onBlur={onQuantitySubmit}
				style={{ minWidth: 80 }}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
						onQuantitySubmit();
					} else if (e.key === "Escape") {
						e.stopPropagation();
						onQuantityEscape();
					}
				}}
			/>
		);
	}
	if (tankFill.quantity) {
		return (
			<ButtonLink onClick={() => setEditQuantity(true)} disabled={disabled}>
				{tankFill.quantity}
			</ButtonLink>
		);
	}
	return (
		<ButtonLink onClick={() => setEditQuantity(true)} disabled={disabled}>
			Fill Up
		</ButtonLink>
	);
}

function TankFillPrice(props: { tankFill: TicketTankFill }) {
	const { tankFill } = props;

	const alert = useAlert();
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const [editPrice, setEditPrice] = React.useState(false);
	const [priceValue, setPriceValue] = React.useState<number | null>(tankFill.unitPrice.toRoundedUnit(3));

	const price = tankFill.unitPrice;

	const onPriceSubmit = async () => {
		if (priceValue === null) {
			alert.error("Price is required");
			return;
		}

		setDisabled(true);
		const result = await DriverTicketService.updateTankFill({ driverTicketId: ticket.id, quoteItemId: tankFill.id, unitPrice: toOptional(priceValue) });
		setDisabled(false);

		if (result.success) {
			alert.success("Price updated");
			updateTicket(result.data);
			setEditPrice(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onPriceEscape = () => {
		setEditPrice(false);
		setPriceValue(tankFill.unitPrice.toRoundedUnit(3));
	};

	if (isComplete || tankFill.contractId != null) {
		return (
			<>
				<Price value={price} decimalOptional doNotRound />
				{
					tankFill.contractId != null && (
						<Typography variant="overline" color="textSecondary" style={{ display: "block" }}>
							Locked
						</Typography>
					)
				}
			</>
		);
	}

	if (editPrice) {
		return (
			<div tabIndex={0} onBlur={onPriceSubmit}>
				<PriceTextField
					size="small"
					variant="outlined"
					value={priceValue}
					onPriceChanged={setPriceValue}
					autoFocus
					disabled={disabled}
					style={{ minWidth: 80 }}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onPriceSubmit();
						}
						if (e.key === "Escape") {
							e.stopPropagation();
							onPriceEscape();
						}
					}}
				/>
			</div>
		);
	}

	return (
		<ButtonLink onClick={() => setEditPrice(true)} disabled={disabled}>
			<Price value={price} decimalOptional doNotRound />
		</ButtonLink>
	);
}

function TankFillTax(props: { tankFill: TicketTankFill; subtotal: SafeMoney | null; isEstimate: boolean }) {
	const { tankFill, subtotal, isEstimate } = props;

	const { disabled, ticket } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const [editTaxes, setEditTaxes] = React.useState(false);

	const tax = useMemo(() => {
		if (tankFill.taxRules.length === 0) return Money.zero;
		if (tankFill.taxAmount) return tankFill.taxAmount;
		if (subtotal == null) return null;
		return calculateTaxes(tankFill.taxRules, subtotal);
	}, [tankFill.taxRules, tankFill.taxAmount, subtotal]);

	if (isComplete) {
		return <Price value={tax ?? Money.zero} />;
	}

	return (
		<>
			{tax ? (
				<ButtonLink onClick={() => setEditTaxes(true)} disabled={disabled}>
					{isEstimate && tankFill.taxRules.length > 0 ? "~" : ""}
					<Price value={tax} />
				</ButtonLink>
			) : (
				<ButtonLink onClick={() => setEditTaxes(true)}>{tankFill.taxRules.length === 0 ? <Price value={Money.zero} /> : "TBD"}</ButtonLink>
			)}
			<QuoteItemTaxDialog quoteItem={tankFill} subtotal={props.subtotal} open={editTaxes} onClose={() => setEditTaxes(false)} />
		</>
	);
}
