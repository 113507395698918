import React, { useState } from "react";
import { useAlert } from "../../hooks/useAlert";
import { subDays, subMonths } from "date-fns";
import { ReportService, TaxReport } from "../../services/report/ReportService";
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { PageTitle } from "../../components/PageTitle";
import { BackButton } from "../../components/BackButton";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Price } from "../../components/Price";
import { GridGrow } from "../../components/GridGrow";

function beginningOfLastMonth() {
	const beginningOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
	return subMonths(beginningOfMonth, 1);
}

function endOfLastMonth() {
	const beginningOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
	return subDays(beginningOfMonth, 1);
}

export function TaxReportPage() {
	const alert = useAlert();

	const [disabled, setDisabled] = useState(false);
	const [startDate, setStartDate] = useState(beginningOfLastMonth());
	const [endDate, setEndDate] = useState(endOfLastMonth());
	const [accounting, setAccounting] = useState<"Cash" | "Accrual">("Cash");
	const [report, setReport] = useState<TaxReport>();

	const generateReport = async () => {
		setDisabled(true);
		const result = await ReportService.taxReport({ startDate, endDate, accounting });
		setDisabled(false);

		if (result.success) {
			setReport(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const downloadReportAsCsv = () => {
		if(!report) return;

		const csv = [
			`Sales,${report.totalSalesAmount},Taxable,${report.totalTaxableAmount},Tax Collected,${report.totalTaxedAmount},Exempt,${report.totalExemptAmount}`,
			"",
			"Type,Rule,Taxable,Tax Collected",
			...report.items.map((item) => `${item.taxRuleId ? "Auto" : "Manual"},${item.ruleName},${item.taxableAmount},${item.taxedAmount}`),
		].join("\n");

		const blob = new Blob([csv], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("hidden", "");
		a.setAttribute("href", url);
		a.setAttribute("download", "tax-report.csv");
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Tax Report" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Tax Report" subheader="Report on taxes collected on a day/period." />
						</Grid>
					</Grid>

					<CardContent>
						<Grid container spacing={2}>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="Start Date"
									inputVariant="outlined"
									fullWidth
									value={startDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setStartDate(date);
									}}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									label="End Date"
									inputVariant="outlined"
									fullWidth
									value={endDate}
									disabled={disabled}
									onChange={(date) => {
										if (date) setEndDate(date);
									}}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<TextField
									select
									fullWidth
									label="Accounting"
									variant="outlined"
									value={accounting}
									onChange={(event) => setAccounting(event.target.value as "Cash" | "Accrual")}
								>
									<MenuItem value="Cash">Cash</MenuItem>
									<MenuItem value="Accrual" disabled>
										Accrual
									</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12}>
								<Grid container>
									<Grid item>
										<Button variant="contained" color="primary" disabled={disabled} onClick={generateReport}>
											Generate
										</Button>
									</Grid>
									<GridGrow />
									<Grid item>
										<Button variant="outlined" color="primary" disabled={disabled || report == null} onClick={downloadReportAsCsv}>
											Download
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			{report && (
				<Grid item sm={10} xs={12}>
					<Card>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										Sales: <Price value={report.totalSalesAmount} />
									</TableCell>
									<TableCell>
										Taxable: <Price value={report.totalTaxableAmount} />
									</TableCell>
									<TableCell>
										Tax Collected: <Price value={report.totalTaxedAmount} />
									</TableCell>
									<TableCell>
										Exempt: <Price value={report.totalExemptAmount} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>

						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Type</TableCell>
									<TableCell>Rule</TableCell>
									<TableCell>Taxable</TableCell>
									<TableCell>Tax Collected</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{report.items.map((item, i) => (
									<TableRow key={i}>
										<TableCell>{item.taxRuleId ? "Auto" : "Manual"}</TableCell>
										<TableCell>{item.ruleName}</TableCell>
										<TableCell>
											<Price value={item.taxableAmount} />
										</TableCell>
										<TableCell>
											<Price value={item.taxedAmount} />
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
