import React, { useState } from "react";
import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import EditIcon from "@material-ui/icons/Edit";
import { useGoogleAddressSuggestion } from "../../utility/useGoogleAddressSuggestion";
import { GoogleMapsAddress } from "../../entities/GoogleMapsAddress";
import { ManualAddressDialog } from "./ManualAddressDialog";
import { ManualAddress } from "../../entities/customer/ManualAddress";

interface Option extends google.maps.places.AutocompletePrediction {}

export type AutoCompleteAddress = {
	placeId?: string;
	address: string;
	street: string;
	city: string;
	state: string;
	postalCode: string;
	county: string;
	latitude: number;
	longitude: number;
};

type Props = TextFieldProps & {
	value?: string;
	onAddressChange: (address: AutoCompleteAddress) => void;
}

export function AddressAutoComplete(props: Props) {
	const { onAddressChange, value, ...textFieldProps } = props;
	const [places, setQuery, geoCodeService] = useGoogleAddressSuggestion();

	const [address, setAddress] = useState<string | undefined>(value ? value : undefined);
	const [showAutoComplete, setShowAutoComplete] = useState(value == null || value === "");
	const [showManualAddress, setShowManualAddress] = useState(false);
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	const onAddressSelected = (geocodedAddresses: google.maps.GeocoderResult[] | null) => {
		setDisableAutoComplete(false);
		if (!geocodedAddresses || geocodedAddresses.length === 0) {
			return;
		}
		const geocodedAddress = geocodedAddresses[0];
		const parts = geocodedAddress.address_components;
		const streetNumber = parts.find((p) => p.types.some((type) => type === "street_number"))?.long_name ?? "";
		const route = parts.find((p) => p.types.some((type) => type === "route"))?.short_name ?? "";
		const street = `${streetNumber} ${route}`;
		const city = parts.find((p) => p.types.some((type) => type === "locality"))?.short_name ?? "";
		const county = parts.find((p) => p.types.some((type) => type === "administrative_area_level_2"))?.short_name ?? "Unknown";
		const state = parts.find((p) => p.types.some((type) => type === "administrative_area_level_1"))?.short_name ?? "";
		const postalCode = parts.find((p) => p.types.some((type) => type === "postal_code"))?.long_name ?? "";
		const address: GoogleMapsAddress = {
			street,
			city,
			state,
			county,
			postalCode,
			address: `${street}, ${city} ${state}`,
			latitude: geocodedAddress.geometry.location.lat(),
			longitude: geocodedAddress.geometry.location.lng(),
			placeId: geocodedAddress.place_id,
		};
		setAddress(address.address);
		setShowAutoComplete(false);
		onAddressChange(address);
	};

	const onManualAddressSubmitted = (address: ManualAddress) => {
		const formatedAddress = `${address.street}, ${address.city} ${address.state}`;
		setAddress(formatedAddress);
		setShowAutoComplete(false);
		setShowManualAddress(false);

		const autoCompleteAddress: AutoCompleteAddress = {
			...address,
			address: formatedAddress,
		};
		onAddressChange(autoCompleteAddress);
	};

	if (!showAutoComplete) {
		return (
			<TextField
				{...textFieldProps}
				disabled
				variant={textFieldProps.variant ?? "outlined"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={() => setShowAutoComplete(true)}>
								<EditIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
				value={address}
			/>
		);
	}

	return (
		<>
			<ManualAddressDialog open={showManualAddress} onClose={() => setShowManualAddress(false)} onSubmit={onManualAddressSubmitted} />
			<Autocomplete<google.maps.places.AutocompletePrediction | string>
				getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
				filterOptions={(options, params) => (params.inputValue !== "" ? [...options, "Add Address Manually"] : options)}
				options={places}
				value={null}
				disabled={disableAutoComplete}
				// onClose={() => setShowAutoComplete(address == null)}
				onChange={(_, option) => {
					if (typeof option === "string") {
						setShowManualAddress(true);
						return;
					}

					if (geoCodeService && option) {
						setDisableAutoComplete(true);
						geoCodeService.geocode({ placeId: option.place_id }, onAddressSelected);
					}
				}}
				onInputChange={(_, newInputValue) => {
					setQuery(newInputValue);
				}}
				renderInput={(params) => {
					const { inputProps, ...otherParams } = params;
					return  <TextField {...textFieldProps} {...otherParams} variant={props.variant ?? "outlined"} inputProps={{...inputProps, role:"presentation", autoComplete:"off"}} />;
				}}
				renderOption={(option) => {
					if (typeof option === "string") {
						return option;
					}

					return <AddressOption option={option} />;
				}}
			/>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	textField: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		width: "25ch",
	},
}));

function AddressOption(props: { option: Option }) {
	const { option } = props;
	const classes = useStyles();
	const matches = option.structured_formatting.main_text_matched_substrings;
	const parts = parse(
		option.structured_formatting.main_text,
		matches.map((match) => [match.offset, match.offset + match.length])
	);

	return (
		<Grid container alignItems="center">
			<Grid item>
				<LocationOnIcon className={classes.icon} />
			</Grid>
			<Grid item xs>
				{parts.map((part, index) => (
					<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
						{part.text}
					</span>
				))}
				<Typography variant="body2" color="textSecondary">
					{option.structured_formatting.secondary_text}
				</Typography>
			</Grid>
		</Grid>
	);
}
