import { Card, CardHeader, List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { Enum } from "../../../utility/Enum";
import { AlertIcon } from "./AlertIcon";
import RouterIcon from "@material-ui/icons/Router";
import { DetailCardStyles } from "./DetailCardStyles";
import { OtodataDevice, DeviceStatus, DeviceType } from "../../../entities/routing/OtodataTankMonitor";

export function DeviceDetailCard(props: {device: OtodataDevice}){
    const { device } = props;
    const classes = DetailCardStyles();
    
    return(
        <Card>
            <CardHeader
                action={
                    <AlertIcon alert={device.status !== DeviceStatus.Ok}>
                        <RouterIcon fontSize={"large"} />
                    </AlertIcon>
                }
                title="Device"
                subheader={Enum.print(DeviceStatus, device.status)}
                className={classes.denseCardHeader}
            />
            <List>
                <ListItem>
                    <ListItemText primary="Model" secondary={`${device.supplier} ${device.model}`} />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Type" secondary={Enum.print(DeviceType, device.type)} />
                    {device.type === DeviceType.UltraSonic && <ListItemText primary="Offset" secondary={device.offset} />}
                </ListItem>
                <ListItem>
                    <ListItemText primary="Installed" secondary={device.isInstalled ? "Yes" : "No"} />
                    <ListItemText className={classes.sameLineLineItem} primary="Signal Strength" secondary={`${device.signalStrength}%`} />
                </ListItem>
            </List>
        </Card>
    );
}