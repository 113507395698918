import * as React from "react";
import { NumericFormat } from "react-number-format";
import { SafeMoney } from "../utility/Money";

interface BaseProps {
	doNotRound?: boolean;
	decimalOptional?: boolean;
}

interface Props extends BaseProps {
	value: number | SafeMoney;
}

export function Price(props: Props) {
	const { value, decimalOptional, doNotRound } = props;
	if(typeof(value) === "number") {
		return <NumberPrice value={value} doNotRound={doNotRound} decimalOptional={decimalOptional} />;
	}
	return <CurrencyPrice value={value} doNotRound={doNotRound} decimalOptional={decimalOptional} />;
}


interface NumberPriceProps extends BaseProps {
	value: number;
}

function NumberPrice(props: NumberPriceProps) {
	const { value, doNotRound, decimalOptional } = props;
	const decimalScale = doNotRound ? undefined : 2;
	const roundedValue = Math.round(value * 100) / 100;
	const fixedDecimalScale = decimalOptional == null || roundedValue % 1 !== 0;

	return <NumericFormat value={doNotRound ? value: roundedValue} displayType={"text"} thousandSeparator={true} prefix={"$"} fixedDecimalScale={fixedDecimalScale} decimalScale={decimalScale} />;
}

interface CurrencyPriceProps extends BaseProps {
	value: SafeMoney;
}

function CurrencyPrice(props: CurrencyPriceProps) {
	const { value, doNotRound, decimalOptional } = props;

	let decimalScale = doNotRound ? value.getPrecision() : decimalOptional && !value.hasSubUnits() ? 0 : 2;
	
	// If the last digit is a zero, we can drop the last digit when the decimal scale is 3
	const fractionalPart = value.getAmount() % Math.pow(10, value.getPrecision());
	if (decimalScale === 3 && fractionalPart % 10 === 0) {
		decimalScale = 2;
	}
	
	const zeros = decimalScale === 0 ? "" : "0".repeat(decimalScale);
	const format = zeros.length > 0 ? `$0,0.${zeros}` : "$0,0";
	
	return <>{value.toFormat(format)}</>;
}