import { TaxCriteriaType } from "../../entities/accounting/TaxCriteria";
import { TaxRule } from "../../entities/accounting/TaxRule";
import { Modify } from "../../utility/modifyType";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";


interface CreateTaxRuleRequest {
    name: string;
    ratePercent: number | null;
    fixedAmount: number | null;
    taxAccountId: number;
}

interface UpdateTaxRuleRequest {
    taxRuleId: number;
    name?: string;
    ratePercent?: number | null;
    fixedAmount?: number | null;
    taxAccountId?: number;
}

type SerializedUpdateTaxRuleRequest = Modify<UpdateTaxRuleRequest, {
    ratePercent?: OptionalValue<number | null>;
    fixedAmount?: OptionalValue<number | null>;
}>

const serializeUpdateRequest = (request: UpdateTaxRuleRequest): SerializedUpdateTaxRuleRequest => ({
    ...request,
    ratePercent: toOptional(request.ratePercent),
    fixedAmount: toOptional(request.fixedAmount)
})


interface AddTaxMatchCriteriaRequest {
    type: TaxCriteriaType;
    state?: string;
    zip?: string;
    county?: string;
    city?: string;
}


export const TaxRuleService = {
    getAll: () => WebClient.Get<TaxRule[]>(`${apiServer}/api/tax`),
    create: (request: CreateTaxRuleRequest) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax`, request),
    update: (request: UpdateTaxRuleRequest) => WebClient.Put.Validated<TaxRule>(`${apiServer}/api/tax`, serializeUpdateRequest(request)),
    createMatchGroup: (taxRuleId: number, request: AddTaxMatchCriteriaRequest) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax/${taxRuleId}/group`, request),
    deleteMatchGroup: (taxRuleId: number, groupId: string) => WebClient.Delete<TaxRule>(`${apiServer}/api/tax/${taxRuleId}/group/${groupId}`),
    addCriteriaToGroup: (taxRuleId: number, groupId: string, request: AddTaxMatchCriteriaRequest) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax/${taxRuleId}/group/${groupId}/criteria`, request),
    removeCriteriaFromGroup: (taxRuleId: number, groupId: string, criteriaId: string) => WebClient.Delete<TaxRule>(`${apiServer}/api/tax/${taxRuleId}/group/${groupId}/criteria/${criteriaId}`),
    addExclusion: (taxRuleId: number, request: AddTaxMatchCriteriaRequest) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax/${taxRuleId}/exclusion`, request),
    removeExclusion: (taxRuleId: number, criteriaId: string) => WebClient.Delete<TaxRule>(`${apiServer}/api/tax/${taxRuleId}/exclusion/${criteriaId}`),
    assignToProductLine: (taxRuleId: number, productLineId: string) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax/assign/product-line`, { taxRuleId, productLineId }),
    unassignFromProductLine: (taxRuleId: number, productLineId: string) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax/unassign/product-line`, { taxRuleId, productLineId }),
    assignToProductListing: (taxRuleId: number, productListingId: string) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax/assign/product-listing`, { taxRuleId, productListingId }),
    unassignFromProductListing: (taxRuleId: number, productListingId: string) => WebClient.Post.Validated<TaxRule>(`${apiServer}/api/tax/unassign/product-listing`, { taxRuleId, productListingId }),
}