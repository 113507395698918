import { TextField, TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { InputAttributes, NumericFormat, NumericFormatProps } from "react-number-format";

interface Props {
	onPriceChanged: (price: number | null) => void;
}

const CurrencyNumberFormat = React.forwardRef<NumericFormatProps<InputAttributes>>(function NumberFormatCustom(props, ref) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const anyProps = props as any;
	const [value, setValue] = React.useState<string | null>(anyProps.value ? anyProps.value.toString() : null);
	const [focused, setFocused] = React.useState(false);


	const hasDecimal = value != null && value.indexOf(".") !== -1;
	const numberOfDecimals = hasDecimal && value ? value.split(".")[1].length : 0;
	const fixedDecimalScale = !focused && numberOfDecimals <= 1;
	const decimalScale = numberOfDecimals < 2 ? 2 : 3;

	return (
		<NumericFormat
			{...props}
			onValueChange={v => setValue(v.value)}
			onFocus={() => setFocused(true)}
			onBlur={() => setFocused(false)}
			getInputRef={ref}
			thousandSeparator
			valueIsNumericString
			allowNegative={false}
			fixedDecimalScale={fixedDecimalScale}
			decimalScale={decimalScale}
			prefix="$"
		/>
	);
});

function getNumberValue(value: string) {
	const stringValue = value.toString().replace("$", "").replace(",", "");
	const parsedValue = Number(stringValue);
	return isNaN(parsedValue) || stringValue === "" ? null : parsedValue;
}

export function PriceTextField(props: TextFieldProps & Props) {
	const { onPriceChanged, ...textFieldProps } = props;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const numberValue = getNumberValue(event.target.value);
		onPriceChanged(numberValue);
	};

	return (
		<TextField
			{...textFieldProps}
			onChange={handleChange}
			onFocus={(event) => {
				event.target.select();
			}}
			placeholder={textFieldProps.placeholder || "$"}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				...textFieldProps.InputProps,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				inputComponent: CurrencyNumberFormat as any,
				inputMode: "numeric"
			}}
		/>
	);
}
