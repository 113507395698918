import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { PageTitle } from "../../components/PageTitle";
import { Invoice } from "../../entities/billing/Invoice";
import { GridGrow } from "../../components/GridGrow";
import { InvoiceDetailCard } from "./components/InvoiceDetailCard";
import { BackButton } from "../../components/BackButton";
import { InvoiceService } from "../../services/billing/InvoiceService";
import { useAlert } from "../../hooks/useAlert";
import { OnlineProfileService } from "../../services/business/OnlineProfileService";
import MailIcon from "@material-ui/icons/Mail";

interface Props {
	selectedInvoice: Invoice;
	onUpdate: (invoice: Invoice) => void;
}

export function InvoiceDetailPage(props: Props) {
	const { selectedInvoice } = props;
	const alert = useAlert();

	const { customerEmail, allowSendEmail } = selectedInvoice;
	const customerHasEmail = customerEmail != null && customerEmail !== "";

	const [disabled, setDisabled] = React.useState(false);
	const [canSendEmail, setCanSendEmail] = React.useState(false);

	useEffect(() => {
		async function checkIfEmailIsSetup() {
			const result = await OnlineProfileService.get();
			if (result.success && result.data != null) {
				setCanSendEmail(true);
			}
		}

		if (customerHasEmail && allowSendEmail) {
			checkIfEmailIsSetup();
		}
	}, [allowSendEmail, customerHasEmail]);

	const downloadInvoice = async () => {
		setDisabled(true);
		const result = await InvoiceService.download(selectedInvoice.id, `Invoice_${selectedInvoice.shortId}.pdf`);
		setDisabled(false);
		if (result.error) {
			console.error(result);
			alert.serverError(result);
		}
	};

	const emailInvoice = async () => {
		setDisabled(true);
		const result = await InvoiceService.emailInvoice(selectedInvoice.id);
		setDisabled(false);

		if (result.success)
			alert.success(
				`Invoice emailed to ${selectedInvoice.customerEmail}${selectedInvoice.customerEmailSecondary ? ` and ${selectedInvoice.customerEmailSecondary}` : ""}`
			);
		else if (result.validation) alert.validation(result);
		else alert.serverError(result);
	};

	return (
		<Grid container spacing={2} justify="center">
			<PageTitle title={`Invoice ${selectedInvoice.shortId}`} />
			<Grid item md={9} sm={10} xs={12}>
				<Grid container spacing={1} alignItems="center">
					<Grid item>
						<BackButton />
					</Grid>
					<Grid item>
						<Typography variant="h5">Invoice {selectedInvoice.shortId}</Typography>
					</Grid>
					<GridGrow />
					{customerHasEmail && (
						<Grid item>
							<Button variant="contained" color="default" disabled={disabled || !canSendEmail} onClick={emailInvoice} startIcon={<MailIcon />}>
								Send Email
							</Button>
						</Grid>
					)}
					<Grid item>
						<Button variant="contained" color="default" disabled={disabled} onClick={downloadInvoice}>
							Download PDF
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={9} sm={10} xs={12}>
				<InvoiceDetailCard invoice={selectedInvoice} onUpdate={props.onUpdate} />
			</Grid>
		</Grid>
	);
}
