import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

interface Props {
	size?: number | string;
	color?: "primary" | "secondary" | "inherit";
}

export function CenteredLoadingSpinner(props: Props) {
	return (
		<Grid container justify="center">
			<Grid item>
				<CircularProgress size={props.size ? props.size : "3rem"} color={props.color ? props.color : "secondary"} style={{ margin: 15 }} />
			</Grid>
		</Grid>
	);
}

export default CenteredLoadingSpinner;
