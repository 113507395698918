import React, { useMemo } from "react";
import { InvoiceDiscountItem, InvoiceFeeItem, InvoiceItem, InvoiceItemType, InvoicePaymentItem, InvoiceSalesItem } from "../../../entities/billing/Invoice";
import { Price } from "../../../components/Price";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { routes } from "../../../routes";
import { RedText } from "../../../components/RedText";
import { Strikethrough } from "../../../components/Strikethrough";
import { useHistory } from "react-router-dom";
import { Money } from "../../../utility/Money";

interface InvoiceItemRowProps {
	item: InvoiceItem;
	invoiceItems: InvoiceItem[];
	onShowSaleDetails: (invoiceItem: InvoiceSalesItem | InvoiceFeeItem) => void;
}

const Context = React.createContext<InvoiceItemRowProps>({} as InvoiceItemRowProps);

const useInvoiceItemContext = () => React.useContext(Context);

export function InvoiceItemRow(props: InvoiceItemRowProps) {
	const { item } = props;

	const getRow = () => {
		switch (item.type) {
			case InvoiceItemType.Sales:
				return <InvoiceSalesRow salesItem={item} />;
			case InvoiceItemType.Fee:
				return <InvoiceFeeRow feeItem={item} />;
			case InvoiceItemType.Payment:
				return <InvoicePaymentRow payment={item} />;
		}
	};

	return <Context.Provider value={props}>{getRow()}</Context.Provider>;
}

function InvoicePaymentRow(props: { payment: InvoicePaymentItem }) {
	const { payment: item } = props;
	const context = useInvoiceItemContext();
	const { invoiceItems } = context;
	const history = useHistory();

	const reversal = useMemo(() => invoiceItems.find((r) => r.type === InvoiceItemType.PaymentReversal && r.appliedToId === item.id), [item, invoiceItems]);

	const onSelect = () => history.push(routes.app.resolve.paymentDetailPage(item.transactionId));

	return (
		<TableRow hover onClick={onSelect}>
			<TableCell component="th" scope="row">
				{item.type === InvoiceItemType.Payment && (
					<>
						{item.description}
						{reversal && (
							<>
								<br />
								<RedText>{reversal.description}</RedText>
							</>
						)}
					</>
				)}
			</TableCell>
			<TableCell align="right">
				{item.created.toLocaleDateString()}
				{reversal && (
					<>
						<br />
						<RedText>{reversal.created.toLocaleDateString()}</RedText>
					</>
				)}
			</TableCell>
			<TableCell colSpan={4} align="right" style={{ textDecoration: reversal && "line-through" }}>
				<Price value={item.totalAmount} />
			</TableCell>
		</TableRow>
	);
}

function InvoiceSalesRow(props: { salesItem: InvoiceSalesItem }) {
	const { salesItem: item } = props;
	const context = useInvoiceItemContext();
	const { invoiceItems } = context;

	const discounts = useMemo(
		() => invoiceItems.filter((i) => i.type === InvoiceItemType.Discount && i.appliedToId === item.id) as InvoiceDiscountItem[],
		[item, invoiceItems]
	);
	const discountTotal = useMemo(() => discounts.reduce((a, b) => a.add(b.totalAmount), Money.zero), [discounts]);

	const onSelect = () => context.onShowSaleDetails(item);

	return (
		<TableRow hover onClick={onSelect}>
			<TableCell component="th" scope="row">
				{item.description}
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						Discount - {d.description}
					</React.Fragment>
				))}
			</TableCell>
			<TableCell align="right">
				{item.created.toLocaleDateString()}
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						{d.created.toLocaleDateString()}
					</React.Fragment>
				))}
			</TableCell>
			<TableCell align="right">{item.quantity}</TableCell>
			<TableCell align="right">
				<Price value={item.saleAmount} />
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						<Price value={d.saleAmount.multiply(-1)} />
					</React.Fragment>
				))}
			</TableCell>
			<TableCell align="right">
				{Money.isPositive(item.taxAmount) && <Price value={item.taxAmount} />}
				{item.taxAmount.isZero() && <Price value={item.taxAmount} />}
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						<Price value={d.taxAmount.multiply(-1)} />
					</React.Fragment>
				))}
			</TableCell>
			<TableCell align="right">
				{Money.isPositive(discountTotal) && (
					<>
						<Strikethrough>
							<Price value={item.totalAmount} />
						</Strikethrough>
						<br />
						<Price value={item.totalAmount.subtract(discountTotal)} />
					</>
				)}
				{discountTotal.isZero() && <Price value={item.totalAmount} />}
			</TableCell>
		</TableRow>
	);
}

function InvoiceFeeRow(props: { feeItem: InvoiceFeeItem }) {
	const { feeItem: item } = props;
	const context = useInvoiceItemContext();
	const { invoiceItems } = context;

	const discounts = useMemo(
		() => invoiceItems.filter((i) => i.type === InvoiceItemType.Discount && i.appliedToId === item.id) as InvoiceDiscountItem[],
		[item, invoiceItems]
	);
	const discountTotal = useMemo(() => discounts.reduce((a, b) => a.add(b.totalAmount), Money.zero), [discounts]);

	return (
		<TableRow hover onClick={() => context.onShowSaleDetails(item)}>
			<TableCell component="th" scope="row">
				{item.description}
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						Discount - {d.description}
					</React.Fragment>
				))}
			</TableCell>
			<TableCell align="right">
				{item.created.toLocaleDateString()}
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						{d.created.toLocaleDateString()}
					</React.Fragment>
				))}
			</TableCell>
			<TableCell />
			<TableCell align="right">
				{<Price value={item.saleAmount} />}
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						<Price value={d.saleAmount.multiply(-1)} />
					</React.Fragment>
				))}
			</TableCell>
			<TableCell align="right">
				{Money.isPositive(item.taxAmount) && <Price value={item.taxAmount} />}
				{item.taxAmount.isZero() && <Price value={item.taxAmount} />}
				{discounts.map((d) => (
					<React.Fragment key={d.id}>
						<br />
						<Price value={d.taxAmount.multiply(-1)} />
					</React.Fragment>
				))}
			</TableCell>
			<TableCell align="right">
				{Money.isPositive(discountTotal) && (
					<>
						<Strikethrough>
							<Price value={item.totalAmount} />
						</Strikethrough>
						<br />
						<Price value={item.totalAmount.subtract(discountTotal)} />
					</>
				)}
				{discountTotal.isZero() && <Price value={item.totalAmount} />}
			</TableCell>
		</TableRow>
	);
}
