import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

export function RedText(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) {
    const classes = useStyles();
	const { children, ...otherProps } = props;
	return (<span {...otherProps} className={classes.red}>{children}</span>);
}

const useStyles = makeStyles((theme: Theme) => ({
	red: {
		color: theme.palette.error.main,
	},
}));