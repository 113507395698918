import { Dialog, DialogTitle, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { Customer } from "../../../entities/customer/Customer";
import { CreateCustomerDialogView } from "./CreateCustomerDialogView";
import { BackButton } from "../../../components/BackButton";

interface Props {
	open: boolean;
	onClose: () => void;
	onCustomerAdded: (customer: Customer) => void;
}

export function AddCustomerDialog(props: Props) {
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down("xs"));
	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm" fullScreen={isXs}>
			<DialogTitle>
				{isXs && <BackButton onClick={props.onClose} />}
				Add Customer
			</DialogTitle>
			<CreateCustomerDialogView onCancel={props.onClose} onCustomerCreated={props.onCustomerAdded} />
		</Dialog>
	);
}
