import { Modify } from "../../utility/modifyType";

export interface SalesTransaction {
    id: string;
    customerId: string;
    created: Date;
    description: string;
    balanceChange: number;
    salesAmount: number;
    taxAmount: number;
    totalAmount: number;
    revenueAccountId: number | null;
    accountsReceivableAccountId: number;
    taxAccountId: number | null;
    productListingId: string | null;
    invoiceId: string | null;
}

type SerializedSalesTransaction = Modify<SalesTransaction, {
    created: string;
}>;

export function deserializeSalesTransaction(model: SerializedSalesTransaction): SalesTransaction {
    return {
        ...model,
        created: new Date(model.created)
    };
}
