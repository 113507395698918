import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useAlert } from "../../../hooks/useAlert";
import { SelectTagDialog } from "../../customers/dialog/SelectTagDialog";
import { TagBubble } from "../../../components/TagBubble";
import { TagService } from "../../../services/customer/TagService";
import { Tag } from "../../../entities/customer/Tag";
import { useTicketDetail } from "./DriverTicketDetailView";

export function TicketTags() {
	const [showSelectTag, setShowSelectTag] = useState(false);
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();

	const alert = useAlert();

	const onAddTag = async (tag: Tag) => {
		setShowSelectTag(false);
		setDisabled(true);
		const result = await TagService.addToTicket({ ticketId: ticket.id, tagId: tag.id });
		setDisabled(false);
		if (result.success) {
			updateTicket(result.data);
			alert.success("Tag added");
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	const onRemoveTag = async (tag: Tag) => {
		setDisabled(true);
		const result = await TagService.removeFromTicket({ ticketId: ticket.id, tagId: tag.id });
		setDisabled(false);
		if (result.success) {
			updateTicket(result.data);
			alert.success("Tag removed");
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	return (
		<>
			<SelectTagDialog open={showSelectTag} type="Driver Ticket" onClose={() => setShowSelectTag(false)} onTagSelected={onAddTag} disableTags={ticket.tags} />
			<Grid container alignItems="center" spacing={1}>
				<Grid item>
					<Button
						color="primary"
						disabled={disabled}
						variant="text"
						style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }}
						onClick={() => setShowSelectTag(true)}
					>
						Tags:
					</Button>
				</Grid>
				{ticket.tags.length === 0 && (
					<Grid item>
						<Typography variant="body1" color="textSecondary" style={{ fontSize: "1.15rem", paddingTop: 3 }}>
							None
						</Typography>
					</Grid>
				)}

				{ticket.tags.map((t) => (
					<Grid key={t.id} item>
						<TagBubble disabled={disabled} tag={t} onDelete={onRemoveTag} />
					</Grid>
				))}
			</Grid>
		</>
	);
}
