import { Card, CardHeader, List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { Enum } from "../../../utility/Enum";
import { AlertIcon } from "./AlertIcon";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import { DetailCardStyles } from "./DetailCardStyles";
import { OtodataDevice, SensorTroubleStatus } from "../../../entities/routing/OtodataTankMonitor";

export function SensorDetailCard(props: { device: OtodataDevice }) {
    const { device } = props;
    const classes = DetailCardStyles();

	return (
		<Card>
			<CardHeader
				action={
					<AlertIcon alert={device.sensorTrouble !== SensorTroubleStatus.None}>
						<RssFeedIcon fontSize={"large"} />
					</AlertIcon>
				}
				title="Sensor"
				subheader={device.sensorTrouble === SensorTroubleStatus.None ? "Ok" : Enum.print(SensorTroubleStatus, device.sensorTrouble)}
				className={classes.denseCardHeader}
			/>
			<List>
				<ListItem>
					<ListItemText primary="Last Read" secondary={device.lastRead ? device.lastRead.toLocaleString() : "Never"} />
				</ListItem>
				<ListItem>
					<ListItemText primary="Tempurature" secondary={`${Math.round((device.temperature * 9) / 5 + 32)}° F`} />
				</ListItem>
			</List>
		</Card>
	);
}
