import { deserializeDispatchProInvoice, deserializeDispatchProInvoices } from "../../entities/admin/DispatchProInvoice";
import { deserializeDispatchProPlan } from "../../entities/admin/DispatchProPlan";
import { Tenant } from "../../entities/auth/Tenant";
import { User } from "../../entities/auth/User";
import { UserClaim } from "../../entities/auth/UserClaim";
import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";
import { OptionalValue } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

export interface UpdateBusinessInfoRequest {
	name?: string;
	timezone?: string;
	officeAddress?: GeocodedAddress;
	mailingAddress?: GeocodedAddress;
	phoneNumber?: string;
}

interface UpdateBusinessPreferencesRequest {
	areaCode?: OptionalValue<string>;
	targetTankFillPercentage?: number;
	autoApplyUnallocatedPayments?: boolean;
}

interface InviteUserRequest {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	claims: UserClaim[];
}

interface UpdateUserRequest {
	userId: string;
	firstName: string;
	lastName: string;
	claims: UserClaim[];
}

interface UpdateUserStatusRequest {
	userId: string;
	disabled: boolean;
}

interface UpdateSurchargeConfiguration {
	default: number;
	onlinePercentage: number;
	productListingId: string;
} 

interface UpdateInvoicePreferencesRequest {
	headerText: string | null;
	footerText: string | null;
	pastDueNotice: boolean;
	showPrice: boolean;
	useTicketNumberForInvoiceCode: boolean;
	includePageNumbers: boolean;
	addReturnAddressToFooter: boolean;
	version: 1 | 2;
}

interface UpdateStatementPreferencesRequest {
	memo: string | null;
}

export const BusinessService = {
	getUsers: () => WebClient.Get<User[]>(`${apiServer}/api/business/users`),

	getUser: (userId: string) => WebClient.GetOptional<User>(`${apiServer}/api/business/users/${userId}`),

	updateUser(request: UpdateUserRequest){
		return WebClient.Put.Validated<User>(`${apiServer}/api/business/user`, request);
	},
	updateUserStatus(request: UpdateUserStatusRequest){
		return WebClient.Put.Validated<User>(`${apiServer}/api/business/user/status`, request);
	},
	deleteUser(userId: string) {
		return WebClient.Delete(`${apiServer}/api/business/users/${userId}`)
	},
	inviteUser(request: InviteUserRequest){
		return WebClient.Post.Validated<User>(`${apiServer}/api/business/invite-user`, request);
	},
	updateInfo: (request: UpdateBusinessInfoRequest) => WebClient.Put.Validated<Tenant>(`${apiServer}/api/business/business-info`, request),
	updatePreferences: (request: UpdateBusinessPreferencesRequest) => WebClient.Put.Validated<Tenant>(`${apiServer}/api/business/preferences`, request),
	updateSurchargeConfiguration: (request: UpdateSurchargeConfiguration) => WebClient.Put.Validated<Tenant>(`${apiServer}/api/business/surcharge`, request),
	updateInvoicePreferences: (request: UpdateInvoicePreferencesRequest) => WebClient.Put.Validated<Tenant>(`${apiServer}/api/business/invoice-preferences`, request),
	updateStatementPreferences: (request: UpdateStatementPreferencesRequest) => WebClient.Put.Validated<Tenant>(`${apiServer}/api/business/statement-preferences`, request),
	getTimezones: () => WebClient.Get<string[]>(`${apiServer}/api/business/timezones`),
	getPlan: () => WebClient.GetOptional(`${apiServer}/api/business/plan`, deserializeDispatchProPlan),
	setPlanBankAccount: (token: string) => WebClient.Put.Validated(`${apiServer}/api/business/plan/bank-account`, { token }, deserializeDispatchProPlan),
	getPlanInvoices: () => WebClient.Get(`${apiServer}/api/business/plan/invoices`, deserializeDispatchProInvoices),
	getPlanInvoice: (invoiceId: number) => WebClient.GetOptional(`${apiServer}/api/business/plan/invoices/${invoiceId}`, deserializeDispatchProInvoice),
};
