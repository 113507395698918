import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { Invoice } from "../../../entities/billing/Invoice";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { ProductCategoryType } from "../../../entities/products/ProductCategoryType";
import { PriceTextField } from "../../../components/PriceTextField";
import { InvoiceService } from "../../../services/billing/InvoiceService";
import { useAlert } from "../../../hooks/useAlert";
import { ProductLine } from "../../../entities/products/ProductLine";
import { NumberTextField } from "../../../components/NumberTextField";
import { Price } from "../../../components/Price";
import { Money } from "../../../utility/Money";

export function AddSaleToInvoiceDialog(props: { open: boolean; onClose: () => void; invoice: Invoice; onSaleAdded: (invoice: Invoice) => void }) {
	const { open, invoice } = props;
	const alert = useAlert();
	const productCatalog = useProductCatalog();
	const productLines = productCatalog.productCatalog.productLines;

	const [saleProductLines, setSaleProductLines] = useState<ProductLine[]>([]);
	const [selectedProductLineId, setSelectedProductLineId] = useState("None");
	const [selectedProductListingId, setSelectedProductListingId] = useState("None");
	const [quantityInput, setQuantityInput] = useState(1);
	const [unitPriceInput, setUnitPriceInput] = useState(0);
	const [description, setDescription] = useState("");
	const [disabled, setDisabled] = useState(false);

	const productListings = useMemo(() => saleProductLines.find((line) => line.id === selectedProductLineId)?.availableListings ?? [], [saleProductLines, selectedProductLineId]);
	const selectedProductListing = productListings.find((listing) => listing.id === selectedProductListingId);

	const unitPrice = useMemo(() => Money.fromDecimal(unitPriceInput, 3), [unitPriceInput]);

	useEffect(() => {
		setSaleProductLines(productLines.filter((line) => line.type !== ProductCategoryType.Fee));
	}, [productLines]);

	const clear = () => {
		setSelectedProductLineId("None");
		setSelectedProductListingId("None");
		setUnitPriceInput(0);
		setQuantityInput(1);
		setDescription("");
	};

	const selectProductLine = (productLineId: string) => {
		const productLine = saleProductLines.find((productLine) => productLine.id === productLineId);
		if (!productLine) return;

		setSelectedProductLineId(productLine.id);

		if (productLine.availableListings.length > 0) {
			const productListing = productLine.availableListings[0];
			setSelectedProductListingId(productListing.id);
			setUnitPriceInput(productListing.price);
			setDescription(productListing.name);
		} else {
			setSelectedProductListingId("None");
			setUnitPriceInput(0);
			setDescription("");
		}
	};

	const selectProductListing = (productListingId: string) => {
		const productListing = productListings.find((listing) => listing.id === productListingId);
		if (!productListing) return;

		setSelectedProductListingId(productListing.id);
		setUnitPriceInput(productListing.price);
		setDescription(productListing.name);
	};

	const canSubmit = selectedProductListing != null && quantityInput > 0 && unitPriceInput > 0 && description.trim().length > 0;

	const submit = async () => {
		if (!selectedProductListing) return;
		setDisabled(true);
		const result = await InvoiceService.addSale({ invoiceId: invoice.id, productListingId: selectedProductListing.id, quantity: quantityInput, unitPrice: unitPriceInput, description });
		setDisabled(false);

		if (result.success) {
			clear();
			props.onSaleAdded(result.data);
			alert.success("Sale added to invoice");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Add Sale to Invoice</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TextField
							select
							label="Product Line"
							variant="outlined"
							fullWidth
							disabled={disabled}
							value={selectedProductLineId}
							onChange={(e) => selectProductLine(e.target.value)}
						>
							<MenuItem value="None" disabled>
								Select Product Line
							</MenuItem>
							{saleProductLines.map((productLine) => (
								<MenuItem key={productLine.id} value={productLine.id}>
									{productLine.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={6}>
						<TextField
							select
							label="Listing"
							variant="outlined"
							fullWidth
							disabled={selectedProductLineId === "None" || disabled}
							value={selectedProductListingId}
							onChange={(e) => selectProductListing(e.target.value)}
						>
							<MenuItem value="None" disabled>
								Select Listing
							</MenuItem>
							{productListings.map((listing) => (
								<MenuItem key={listing.id} value={listing.id}>
									{listing.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item sm={6} xs={12}>
						<NumberTextField
							label="Quantity"
							variant="outlined"
							fullWidth
							disabled={!selectedProductListing || disabled}
							value={quantityInput}
							onNumberChange={(q) => setQuantityInput(q ?? 0)}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<PriceTextField
							label="Unit Price"
							variant="outlined"
							fullWidth
							disabled={!selectedProductListing || disabled}
							value={unitPriceInput}
							onPriceChanged={(price) => setUnitPriceInput(price ?? 0)}
							helperText={
								unitPriceInput > 0 && quantityInput > 0 &&  (
									<>
										Total: <Price value={unitPrice.multiply(quantityInput)} />
									</>
								)
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Description"
							variant="outlined"
							fullWidth
							disabled={!selectedProductListing || disabled}
							value={description}
							onChange={(event) => setDescription(event.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={props.onClose} disabled={disabled}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={submit} disabled={disabled || !canSubmit}>
					Add Sale
				</Button>
			</DialogActions>
		</Dialog>
	);
}
