import { Card, CardHeader, List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";

import WavesIcon from "@material-ui/icons/Waves";
import { AlertIcon } from "./AlertIcon";
import { DetailCardStyles } from "./DetailCardStyles";
import { OtodataDevice } from "../../../entities/routing/OtodataTankMonitor";

export function GasDetailCard(props: { device: OtodataDevice }) {
    const { device } = props;
    const classes = DetailCardStyles();

	return (
		<Card>
			<CardHeader
				action={
					<AlertIcon alert={device.highDrainAlarm}>
						<WavesIcon fontSize={"large"} />
					</AlertIcon>
				}
				title="Gas"
				subheader={device.highDrainAlarm ? "Possible Leak" : "Ok"}
				className={classes.denseCardHeader}
			/>
			<List>
				<ListItem>
					<ListItemText primary="Last Fill" secondary={device.lastFill ? device.lastFill.toLocaleString() : "Never"} />
				</ListItem>
				<ListItem>
					<ListItemText primary="Level" secondary={`${device.lastLevel}%`} />
					<ListItemText className={classes.sameLineLineItem} primary="Ullage" secondary={`${Math.round(device.ullage)} Gallons`} />
				</ListItem>
				{device.correctedVolume !== 0 && (
					<ListItem>
						<ListItemText primary="Corrected Volume" secondary={`${device.correctedVolume} Gallons`} />
					</ListItem>
				)}
				<ListItem>
					<ListItemText
						primary={`Used in last 72/48/24 Hours`}
						secondary={`${device.gallonsUsed72Hours}/${device.gallonsUsed48Hours}/${device.gallonsUsed24Hours} Gallons`}
					/>
				</ListItem>
				<ListItem>
					<ListItemText primary="Estimated Time Until Empty" secondary={`${device.hoursToEmpty} Hours`} />
				</ListItem>
			</List>
		</Card>
	);
}
