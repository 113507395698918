import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Divider,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Switch,
	TextField,
	Typography,
} from "@material-ui/core";
import React from "react";
import { GridGrow } from "../../../../components/GridGrow";
import { useCustomerDetailPage } from "../../CustomerDetailPage";
import { BackButton } from "../../../../components/BackButton";
import { CustomerContract, CustomerContractType, PriceLockContract } from "../../../../entities/customer/CustomerContract";
import { useProductCatalog } from "../../../../providers/ProductCatalogProvider";
import { ProductListing } from "../../../../entities/products/ProductListing";
import { ProductLine } from "../../../../entities/products/ProductLine";
import { Autocomplete } from "@material-ui/lab";
import { PriceTextField } from "../../../../components/PriceTextField";
import { NumberTextField } from "../../../../components/NumberTextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useAlert } from "../../../../hooks/useAlert";
import { ContractService } from "../../../../services/customer/ContractService";
import { routes } from "../../../../routes";
import { useHistory } from "react-router-dom";
import { Price } from "../../../../components/Price";

export function ContractsSection() {
	const history = useHistory();
	const customerContext = useCustomerDetailPage();
	const { profile } = customerContext;
	const { contracts } = profile;

	const [openAddContractDialog, setOpenAddContractDialog] = React.useState(false);

	const onSelect = (contract: CustomerContract) => history.push(routes.app.resolve.customerContractDetailPage(contract.customerId, contract.id));

	return (
		<>
			<Box pl={1} pr={2}>
				<Grid container spacing={1}>
					<Grid item>
						<Typography variant="overline">Contracts</Typography>
					</Grid>
					<GridGrow />

					<Grid item>
						<Button size="small" variant="outlined" onClick={() => setOpenAddContractDialog(true)}>
							Add Contract
						</Button>
					</Grid>
				</Grid>
			</Box>
			<List dense style={{ padding: 0 }}>
				{contracts.length === 0 && (
					<ListItem>
						<ListItemText secondary="None" />
					</ListItem>
				)}
				{contracts.map((contract) => (
					<PriceLockListItem key={contract.id} contract={contract} onSelect={onSelect} />
				))}
			</List>
			<AddContractDialog open={openAddContractDialog} onClose={() => setOpenAddContractDialog(false)} />
		</>
	);
}

function PriceLockListItem(props: { contract: PriceLockContract, onSelect: (contract: PriceLockContract) => void}) {
	const { contract } = props;
	

	const { productCatalog } = useProductCatalog();
	const product = productCatalog.getProductListing(contract.productListingId);
	const productName = product ? product.name : "Unknown Product";
	const type = contract.priceCeiling ? "Price Ceiling" : "Price Lock";

	return (
		<ListItem button onClick={() => props.onSelect(contract)}>
			<ListItemText
				primary={<>{type}: {productName} @ <Price value={contract.price}/></>}
				secondary={contract.status}
			/>
		</ListItem>
	);
}

function AddContractDialog(props: { open: boolean; onClose: () => void }) {
	const [view, setView] = React.useState<"select" | "price-lock">("select");

	const onBack = () => {
		if (view === "select") {
			props.onClose();
		} else {
			setView("select");
		}
	};

	const title = () => {
		switch (view) {
			case "select":
				return "Select Contract Type";
			case "price-lock":
				return "Setup Price Lock";
		}
	};

	const onSelect = (type: CustomerContractType) => {
		if (type === CustomerContractType.PriceLock) {
			setView("price-lock");
		}
	};

	return (
		<Dialog open={props.open} fullScreen>
			<Box m={1}>
				<Grid container spacing={1} alignItems="center">
					<Grid item>
						<BackButton onClick={onBack} />
					</Grid>
					<Grid item>
						<Typography variant="h6">{title()}</Typography>
					</Grid>
				</Grid>
			</Box>
			{view === "select" && <SelectContractTypeView onSelect={onSelect} />}
			{view === "price-lock" && <CreatePriceLockContractView />}
		</Dialog>
	);
}

function CreatePriceLockContractView() {
	const alert = useAlert();

	const history = useHistory();

	const customerContext = useCustomerDetailPage();
	const { profile } = customerContext;
	const { id: customerId } = profile.customer;

	const [showProductSelection, setShowProductSelection] = React.useState(false);
	const [selectedProduct, setSelectedProduct] = React.useState<ProductListing | null>(null);

	const [price, setPrice] = React.useState<number | null>(null);
	const [maxQuantity, setMaxQuantity] = React.useState<number | null>(null);
	const [priceCeiling, setPriceCeiling] = React.useState(false);

	const [startDate, setStartDate] = React.useState<Date | null>(new Date());
	const [expiration, setExpiration] = React.useState<Date | null>(null);

	const onSelectedProduct = (product: ProductListing) => {
		setSelectedProduct(product);
		setShowProductSelection(false);
		setPrice(product.price);
	};

	const disableInputs = selectedProduct == null;
	
	const createContract = async () => {
		if(selectedProduct == null) return;
		if(price == null){
			alert.error("Price is required");
			return;
		}
		if(startDate == null){
			alert.error("Start date is required");
			return;
		}

		const result = await ContractService.createPriceLockContract({
			productListingId: selectedProduct.id,
			customerId,
			price,
			maxQuantity,
			priceCeiling,
			startDate,
			expiration
		});

		if(result.success){
			history.push(routes.app.resolve.customerContractDetailPage(customerId, result.data.id));
		} else if(result.validation){
			alert.validation(result);
		} else {
			alert.serverError(result);
		}

	}

	return (
		<DialogContent>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3">Product</Typography>
				</Grid>
				<Grid item xs={12}>
					{selectedProduct != null && (
						<Typography variant="h6">
							{selectedProduct.name}
						</Typography>
					)}
					{showProductSelection === false && (
						<Button color="primary" variant={selectedProduct == null ? "contained" : "outlined"} onClick={() => setShowProductSelection(true)}>
							{selectedProduct == null ? "Select Product" : "Change Product"}
						</Button>
					)}

					{showProductSelection && <SelectProduct onSelected={onSelectedProduct} onCancel={() => setShowProductSelection(false)} />}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3">Price</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item md={3} sm={4} xs={12}>
							<PriceTextField
								label="Contract Price"
								fullWidth
								required
								disabled={disableInputs}
								value={price}
								onPriceChanged={setPrice}
								variant="outlined"
								helperText="Overrides the list price for this product."
							/>
						</Grid>
						<Grid item md={3} sm={4} xs={12}>
							<NumberTextField
								label="Max Quantity"
								fullWidth
								disabled={disableInputs}
								value={maxQuantity}
								onNumberChange={setMaxQuantity}
								variant="outlined"
								helperText="The maximum amount of product that can be purchased at this price. (Optional)"
							/>
						</Grid>
						<Grid item md={3} sm={4} xs={12}>
							<FormLabel component="legend">Price Ceiling Mode</FormLabel>
							<FormControlLabel
								control={
									<Switch
										checked={priceCeiling}
										color="primary"
										onChange={(e) => setPriceCeiling(e.target.checked)}
										name="priceCeiling"
										disabled={disableInputs}
									/>
								}
								label={priceCeiling ? "Enabled" : "Disabled"}
							/>
							<FormHelperText>This contract only applies if the list price is higher than the contract price</FormHelperText>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h3">
						Schedule
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item md={3} sm={4} xs={12}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yy"
								placeholder="MM/DD/YY"
								label="Start Date"
								inputVariant="outlined"
								fullWidth
								disabled={disableInputs}
								value={startDate}
								onChange={(date) => setStartDate(date)}
								onKeyDown={(event) => {
									if (event.key === "Backspace" && startDate === null) setStartDate(new Date());
									if (event.key === "Escape") {
										event.stopPropagation();
										setStartDate(new Date());
									}
								}}
								minDateMessage="Start date cannot be set in the past"
								minDate={new Date()}
								inputProps={{
									onFocus: (e) => e.target.select(),
								}}
							/>
							<FormHelperText>The date this contract becomes active</FormHelperText>
						</Grid>

						<Grid item md={3} sm={4} xs={12}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yy"
								placeholder="MM/DD/YY"
								label="End Date"
								inputVariant="outlined"
								fullWidth
								disabled={disableInputs}
								value={expiration}
								onChange={(date) => setExpiration(date)}
								minDateMessage="End date cannot be set in the past"
								minDate={new Date()}
								inputProps={{
									onFocus: (e) => e.target.select(),
								}}
							/>
							<FormHelperText>The date this contract expires (Optional)</FormHelperText>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Button color="primary" variant="contained" disabled={disableInputs} size="large" onClick={createContract}>
						Create Contract
					</Button>
				</Grid>
			</Grid>
		</DialogContent>
	);
}

function SelectProduct(props: { onSelected: (product: ProductListing) => void; onCancel: () => void }) {
	const { productCatalog } = useProductCatalog();

	const [selectedProductLine, setSelectedProductLine] = React.useState<ProductLine | null>(null);
	const [selectedProductListing, setSelectedProductListing] = React.useState<ProductListing | null>(null);

	const selectableProductLines = productCatalog.productLines;
	const selectableProductListings = selectedProductLine?.availableListings ?? [];

	const onSelectProductLine = (event: React.ChangeEvent<{ value: unknown }>) => {
		const productLineId = event.target.value as string;
		const productLine = productCatalog.productLines.find((x) => x.id === productLineId);
		setSelectedProductLine(productLine ?? null);
	};

	const onSelectProductListing = (_: React.ChangeEvent<{}>, value: ProductListing | null) => {
		setSelectedProductListing(value);
	};

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item>
				<TextField label="Product Line" select variant="outlined" style={{ minWidth: 200 }} value={selectedProductLine?.id ?? "None"} onChange={onSelectProductLine}>
					<MenuItem value="None">Select Product Line</MenuItem>
					{selectableProductLines.map((productLine) => (
						<MenuItem key={productLine.id} value={productLine.id}>
							{productLine.name}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item>
				{selectedProductLine == null && (
					<TextField label="Product" select variant="outlined" style={{ minWidth: 250 }} disabled>
						<MenuItem value="None">Select Product</MenuItem>
					</TextField>
				)}
				{selectedProductLine != null && (
					<Autocomplete
						options={selectableProductListings}
						getOptionLabel={(option) => option.name}
						onChange={onSelectProductListing}
						renderInput={(params) => <TextField {...params} label="Product Line" variant="outlined" style={{ minWidth: 250 }} />}
					/>
				)}
			</Grid>
			<Grid item>
				<Button
					size="large"
					color="primary"
					variant="contained"
					disabled={selectedProductListing == null}
					onClick={() => {
						if (selectedProductListing == null) return;
						props.onSelected(selectedProductListing);
					}}
				>
					Select
				</Button>
			</Grid>
			<Grid item>
				<Button size="large" color="secondary" variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
			</Grid>
		</Grid>
	);
}

function SelectContractTypeView(props: { onSelect: (type: CustomerContractType) => void }) {
	return (
		<List>
			<Divider />
			<ListItem button onClick={() => props.onSelect(CustomerContractType.PriceLock)}>
				<ListItemText
					primary="Price Lock"
					primaryTypographyProps={{ variant: "h6" }}
					secondary="Overrides list pricing for a product. Can be restricted by time or amount."
					secondaryTypographyProps={{ variant: "body2", style: { fontSize: 16 } }}
				/>
			</ListItem>
			<Divider />
		</List>
	);
}
