import { Button, FormHelperText, Grid, Typography } from "@material-ui/core";
import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { printDate } from "../../../utility/PrintDate";
import { useCreateTicketContext } from "./NewCreateTicketPage";

export function AddDueDateSection() {
	const { dueDate, setDueDate } = useCreateTicketContext();

	const [addMode, setAddMode] = React.useState(false);

	if (addMode) {
		return (
			<>
				<KeyboardDatePicker
					disableToolbar
					variant="inline"
					format="MM/dd/yy"
					placeholder="MM/DD/YY"
					label="Due Date"
					inputVariant="outlined"
					value={dueDate}
					onChange={(date) => {
						setDueDate(date);
						if (date != null && date.toString() !== "Invalid Date") setAddMode(false);
					}}
					onKeyDown={(event) => {
						if (event.key === "Backspace" && dueDate === null) setAddMode(false);

						if (event.key === "Escape") {
							event.stopPropagation();
							setAddMode(false);
						}
					}}
					minDateMessage="Due date cannot be set in the past"
					autoFocus
					size="small"
					minDate={new Date()}
					inputProps={{
						onFocus: (e) => e.target.select(),
					}}
				/>
				<FormHelperText>Specify date this ticket must be completed by</FormHelperText>
			</>
		);
	}

	return (
		<Grid container alignItems="center">
			<Grid item>
				<Button color="primary" variant="text" style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6}} onClick={() => setAddMode(true)}>
					Due Date:
				</Button>
			</Grid>
			<Grid item>
				<Typography variant="h6" color={dueDate ? "textPrimary" : "textSecondary"}>
					{dueDate ? printDate.shortDate(dueDate) : "None"}
				</Typography>
			</Grid>
		</Grid>
	);
}
