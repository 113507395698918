import { Card, Grid, CardHeader, CardContent, ListItem, ListItemText, ListItemSecondaryAction, List } from "@material-ui/core";
import React from "react";
import { GridGrow } from "../../../components/GridGrow";
import { HorizontalCentered } from "../../../components/HorizontalCentered";
import { CheckBatchService, UnBatchedChecks } from "../../../services/accounting/CheckBatchService";
import { KpiLoadingCard } from "./KpiLoadingCard";
import CheckIcon from "@material-ui/icons/Check";
import { Price } from "../../../components/Price";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { LinkButton } from "../../../components/LinkButton";

export function PendingCheckBatchCard() {
	const alert = useAlert();

	const [unBatchedChecks, setUnBatchedChecks] = React.useState<UnBatchedChecks>();

	React.useEffect(() => {
		async function loadUnBatchedChecks() {
			const result = await CheckBatchService.getPending();
			if (result.success) {
				setUnBatchedChecks(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadUnBatchedChecks();
	}, [alert]);

	if (!unBatchedChecks) return <KpiLoadingCard title="Pending Check Batch" />;

	if (unBatchedChecks.numberOfChecks === 0) return <NoPendingChecksCard />;

	return (
		<Card style={{ height: "100%" }}>
			<Grid container direction="column" justify="center" style={{ height: "100%" }}>
				<Grid item>
					<Grid container alignItems="center">
						<Grid item>
							<CardHeader title="Pending Check Batch" />
						</Grid>
						<GridGrow />
						<Grid item>
							<LinkButton variant="outlined" to={routes.app.resolve.checkBatchPage()} style={{marginRight: 10, marginLeft: 10}}>
								See All Batches
							</LinkButton>
						</Grid>
					</Grid>
				</Grid>
				<GridGrow>
					<Grid container direction="column" justify="center" style={{ height: "100%" }}>
						<Grid item>
							<CardContent>
								<List component="nav">
									<ListItem divider>
										<ListItemText primary="Number of Pending Checks" />
										<ListItemSecondaryAction>
											<ListItemText primary={unBatchedChecks.numberOfChecks} />
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem divider>
										<ListItemText primary="Pending Batch Amount" />
										<ListItemSecondaryAction>
											<ListItemText primary={<Price value={unBatchedChecks.totalAmount} />} />
										</ListItemSecondaryAction>
									</ListItem>
								</List>
                                <LinkButton variant="outlined" color="primary" to={routes.app.resolve.checkBatchCreatePage()} style={{marginTop: 10}}>
                                    Create Batch
                                </LinkButton>
							</CardContent>
						</Grid>
					</Grid>
				</GridGrow>
			</Grid>
		</Card>
	);
}

function NoPendingChecksCard() {
	return (
		<Card style={{ height: "100%" }}>
			<Grid container direction="column" style={{ height: "100%" }}>
				<Grid item>
                <Grid container alignItems="center">
						<Grid item>
							<CardHeader title="No Pending Check Batch" />
						</Grid>
						<GridGrow />
						<Grid item>
							<LinkButton variant="outlined" to={routes.app.resolve.checkBatchPage()} style={{marginRight: 10}}>
								See All Batches
							</LinkButton>
						</Grid>
					</Grid>
				</Grid>
				<GridGrow>
					<Grid container direction="column" justify="center" style={{ height: "100%" }}>
						<Grid item>
							<HorizontalCentered>
								<CheckIcon style={{ fontSize: "5rem" }} />
							</HorizontalCentered>
						</Grid>
					</Grid>
				</GridGrow>
			</Grid>
		</Card>
	);
}
