import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import { useGoogleAddressSuggestion } from "../../utility/useGoogleAddressSuggestion";
import { ManualAddressDialog } from "./ManualAddressDialog";
import { GeocodedAddress } from "../../entities/customer/GeocodedAddress";

interface Option extends google.maps.places.AutocompletePrediction {}

type Props = Omit<TextFieldProps, "onSelect"> & {
	onSelect: (address: GeocodedAddress) => void;
};

export function AddressLookupField(props: Props) {
	const { onSelect, ...textFieldProps } = props;
	const [places, setQuery, geoCodeService] = useGoogleAddressSuggestion();

	const [loadingAddress, setLoadingAddress] = useState(false);
	const [showManualAddress, setShowManualAddress] = useState(false);

	const disabled = loadingAddress || textFieldProps.disabled;

	const onAddressSelected = (geocodedAddresses: google.maps.GeocoderResult[] | null) => {
		setLoadingAddress(false);
		if (!geocodedAddresses || geocodedAddresses.length === 0) {
			return;
		}
		const geocodedAddress = geocodedAddresses[0];
		const parts = geocodedAddress.address_components;
		const streetNumber = parts.find((p) => p.types.some((type) => type === "street_number"))?.long_name ?? "";
		const route = parts.find((p) => p.types.some((type) => type === "route"))?.short_name ?? "";
		const street = `${streetNumber} ${route}`;
		const city = parts.find((p) => p.types.some((type) => type === "locality"))?.short_name ?? parts.find((p) => p.types.some((type) => type === "administrative_area_level_3"))?.short_name ?? "";
		const county = parts.find((p) => p.types.some((type) => type === "administrative_area_level_2"))?.short_name ?? "Unknown";
		const state = parts.find((p) => p.types.some((type) => type === "administrative_area_level_1"))?.short_name ?? "";
		const postalCode = parts.find((p) => p.types.some((type) => type === "postal_code"))?.long_name ?? "";
		const address: GeocodedAddress = {
			street,
			city,
			state,
			county,
			postalCode,
			latitude: geocodedAddress.geometry.location.lat(),
			longitude: geocodedAddress.geometry.location.lng(),
			googleMapsPlaceId: geocodedAddress.place_id,
		};
		onSelect(address);
	};

	return (
		<>
			<ManualAddressDialog open={showManualAddress} onClose={() => setShowManualAddress(false)} onSubmit={onSelect} />
			<Autocomplete<google.maps.places.AutocompletePrediction | string>
				getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
				filterOptions={(options, params) => (params.inputValue !== "" ? [...options, "Add Address Manually"] : options)}
				options={places}
				disabled={disabled}
				noOptionsText="Enter an address to see suggestions"
				clearOnBlur={false}
				onChange={(_, option) => {
					if (typeof option === "string") {
						setShowManualAddress(true);
						return;
					}

					if (geoCodeService && option) {
						setLoadingAddress(true);
						geoCodeService.geocode({ placeId: option.place_id }, onAddressSelected);
					}
				}}
				onInputChange={(_, newInputValue) => {
					setQuery(newInputValue);
				}}
				renderInput={(params) => {
					const { inputProps, ...otherParams } = params;
					return (
						<TextField
							{...textFieldProps}
							{...otherParams}
							placeholder={props.placeholder ?? "Enter Address"}
							autoFocus
							variant={props.variant ?? "outlined"}
							inputProps={{ ...inputProps, role: "presentation", autoComplete: "off" }}
						/>
					);
				}}
				renderOption={(option) => {
					if (typeof option === "string") {
						return option;
					}

					return <AddressOption option={option} />;
				}}
			/>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	textField: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		width: "25ch",
	},
}));

function AddressOption(props: { option: Option }) {
	const { option } = props;
	const classes = useStyles();
	const matches = option.structured_formatting.main_text_matched_substrings;
	const parts = parse(
		option.structured_formatting.main_text,
		matches.map((match) => [match.offset, match.offset + match.length])
	);

	return (
		<Grid container alignItems="center">
			<Grid item>
				<LocationOnIcon className={classes.icon} />
			</Grid>
			<Grid item xs>
				{parts.map((part, index) => (
					<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
						{part.text}
					</span>
				))}
				<Typography variant="body2" color="textSecondary">
					{option.structured_formatting.secondary_text}
				</Typography>
			</Grid>
		</Grid>
	);
}
