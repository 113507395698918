import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Invoice } from "../../../entities/billing/Invoice";
import { ProductListing } from "../../../entities/products/ProductListing";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { ProductCategoryType } from "../../../entities/products/ProductCategoryType";
import { PriceTextField } from "../../../components/PriceTextField";
import { InvoiceService } from "../../../services/billing/InvoiceService";
import { useAlert } from "../../../hooks/useAlert";

export  function AddFeeToInvoiceDialog(props: { open: boolean; onClose: () => void; invoice: Invoice; onFeeAdded: (invoice: Invoice) => void }) {
	const { open, invoice } = props;
	const alert = useAlert();
	const productCatalog = useProductCatalog();
	const productLines = productCatalog.productCatalog.productLines;

	const [feeOptions, setFeeOptions] = useState<ProductListing[]>([]);
	const [selectedFeeId, setSelectedFeeId] = useState("None");
	const [amount, setAmount] = useState(0);
	const [description, setDescription] = useState("");
	const [disabled, setDisabled] = useState(false);

	const selectedFee = feeOptions.find((fee) => fee.id === selectedFeeId);

	useEffect(() => {
		setFeeOptions(productLines.find((line) => line.type === ProductCategoryType.Fee)?.availableListings ?? []);
	}, [productLines]);

	const clear = () => {
		setSelectedFeeId("None");
		setAmount(0);
		setDescription("");
	};

	const onSelectedFeeChange = (event: React.ChangeEvent<{ value: string }>) => {
		const fee = feeOptions.find((fee) => fee.id === event.target.value);
		if (!fee) return;

		setSelectedFeeId(fee.id);
		setAmount(fee.price);
		setDescription(fee.name);
	};

	const canSubmit = selectedFee != null && amount > 0 && description.trim().length > 0;

	const submit = async () => {
		if (!selectedFee) return;
		setDisabled(true);
		const result = await InvoiceService.addFee({ invoiceId: invoice.id, feeProductListingId: selectedFee.id, amount, description });
		setDisabled(false);

		if (result.success) {
			clear();
			props.onFeeAdded(result.data);
			alert.success("Fee added to invoice");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={props.onClose} fullWidth maxWidth="sm">
			<DialogTitle>Add Fee to Invoice</DialogTitle>
			<DialogContent>
				<Grid container justify="center" style={{ marginBottom: 10 }}>
					<Grid item sm={6} xs={12}>
						<TextField select label="Fee" variant="outlined" fullWidth disabled={disabled} value={selectedFeeId} onChange={onSelectedFeeChange}>
							<MenuItem value="None" disabled>
								Select Fee
							</MenuItem>
							{feeOptions.map((fee) => (
								<MenuItem key={fee.id} value={fee.id}>
									{fee.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<PriceTextField
							label="Amount"
							variant="outlined"
							fullWidth
							disabled={!selectedFee || disabled}
							value={amount}
							onPriceChanged={(price) => setAmount(price ?? 0)}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							label="Description"
							variant="outlined"
							fullWidth
							disabled={!selectedFee || disabled}
							value={description}
							onChange={(event) => setDescription(event.target.value)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={props.onClose} disabled={disabled}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={submit} disabled={disabled || !canSubmit}>
					Add Fee
				</Button>
			</DialogActions>
		</Dialog>
	);
}
