import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { useAlert } from "../../hooks/useAlert";
import { ProductCatalogService } from "../../services/products/ProductCatalogService";

import { FieldValidationError, validationError } from "../../services/server/ServerValidationError";
import { useProductCatalog } from "../../providers/ProductCatalogProvider";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes";
import { GridGrow } from "../../components/GridGrow";
import { TableHeader } from "../../components/table/SortableTableHeader";

export function ProductCatalogDetailPage() {
	const alert = useAlert();
	const history = useHistory();

	const productCatalogPageContext = useProductCatalog();

	const [showDialog, setShowDialog] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [name, setName] = useState("");

	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	const onAddProductLine = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDisabled(true);
		const result = await ProductCatalogService.addProductLine({ name });
		setDisabled(false);

		if (result.success) {
			setShowDialog(false);
			productCatalogPageContext.addProductLine(result.data);
		} else if (result.validation) {
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};

	const onProductLineSelect = (productLineId: string) => {
		history.push(routes.business.resolve.productLinePage(productLineId));
	};


	return (
		<>
			<PageTitle title="Product Catalog" />

			<Grid container spacing={2} justify="flex-start">
				<Grid item lg={8} md={10} sm={12} xs={12}>
					<Grid container spacing={1} alignItems="center">
						<Grid item>
							<Typography variant="h2">Product Catalog</Typography>
						</Grid>
						<GridGrow />
						<Grid item>
							<Button color="primary" variant="contained" onClick={() => setShowDialog(true)}>
								+ Add Product Line
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={8} md={10} sm={12} xs={12}>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableHeader>Product Line</TableHeader>
									<TableHeader>Product Count</TableHeader>
								</TableRow>
							</TableHead>
							<TableBody>
								{productCatalogPageContext.productCatalog.productLines.map((productLine) => (
									<TableRow key={productLine.id} hover onClick={() => onProductLineSelect(productLine.id)} style={{ cursor: "pointer" }}>
										<TableCell>{productLine.name}</TableCell>
										<TableCell>{productLine.allListings.length}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<Dialog open={showDialog} onClose={() => setShowDialog(false)}>
				<DialogTitle>Add Product Line</DialogTitle>
				<form onSubmit={onAddProductLine}>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									disabled={disabled}
									label="Name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									error={validationError.isFieldInError(validationErrors, "Name")}
									helperText={validationError.getFieldError(validationErrors, "Name")}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button disabled={disabled} onClick={() => setShowDialog(false)}>
							Cancel
						</Button>
						<Button disabled={disabled} type="submit">
							Add
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
}
