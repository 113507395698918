import { Button, Card, CardActions, CardContent, CardHeader, FormHelperText, Grid, IconButton, MenuItem, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { ProductListing } from "../../entities/products/ProductListing";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useAlert } from "../../hooks/useAlert";
import { useState } from "react";
import { FieldValidationError, validationError } from "../../services/server/ServerValidationError";
import { ProductCatalogService } from "../../services/products/ProductCatalogService";
import { useEffect } from "react";

import { PriceTextField } from "../../components/PriceTextField";
import { Price } from "../../components/Price";
import { LedgerAccountService } from "../../services/accounting/LedgerAccountsService";
import { GeneralLedgerAccount, LedgerClassification } from "../../entities/accounting/GeneralLedgerAccount";
import { ClientLink } from "../../components/ClientLink";
import { routes } from "../../routes";
import { GridGrow } from "../../components/GridGrow";
import { RedButton } from "../../components/RedButton";
import { LoadingOverlay } from "@material-ui/data-grid";
import { useProductCatalog } from "../../providers/ProductCatalogProvider";

export function ProductListingDetailPage() {
	const params = useParams<{ productListingId: string }>();
	const { productListingId } = params;

	const alert = useAlert();

	const productCatalogContext = useProductCatalog();
	const [productListing, setProductListing] = useState<ProductListing | null>(null);
	const [accounts, setAccounts] = useState<GeneralLedgerAccount[] | null>(null);

	useEffect(() => {
		async function loadAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setAccounts(result.data.filter((a) => a.classification === LedgerClassification.Revenue));
			} else {
				alert.serverError(result);
			}
		}

		loadAccounts();
	}, [alert]);

	useEffect(() => {
		async function loadProductListing() {
			const result = await ProductCatalogService.getListing(productListingId);
			if (result.success) {
				setProductListing(result.data);
			} else {
				alert.serverError(result);
			}
		}

		loadProductListing();
	}, [productListingId, alert]);

	const onChange = (productListing: ProductListing) => {
		setProductListing(productListing);
		productCatalogContext.updateProductListing(productListing);
	};

	if (!productListing || !accounts || productCatalogContext.loadStatus !== "ready") return <LoadingOverlay />;

	return <ProductListingDetailView revenueAccounts={accounts} productListing={productListing} onChange={onChange} />;
}

interface Props {
	productListing: ProductListing;
	revenueAccounts: GeneralLedgerAccount[];
	onChange: (productListing: ProductListing) => void;
}

function ProductListingDetailView(props: Props) {
	const history = useHistory();
	const alert = useAlert();

	const { productListing, revenueAccounts: accounts } = props;

	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
	const [disabled, setDisabled] = useState(false);

	const [name, setName] = useState(productListing.name);
	const [price, setPrice] = useState<number | null>(productListing.price);
	const [unitName, setUnitName] = useState(productListing.unitName);
	const [productCode, setProductCode] = useState(productListing.productCode);
	const [revenueAccountId, setRevenueAccountId] = useState(productListing.revenueAccountId);

	const onUpdateListing = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (price == null) return;

		setDisabled(true);
		const result = await ProductCatalogService.updateProductListing({
			id: productListing.id,
			name,
			price,
			unitName,
			productCode,
			revenueAccountId,
		});
		setDisabled(false);
		if (result.success) {
			props.onChange(result.data);
			alert.success("Listing Updated");
		} else if (result.validation) {
			alert.validation(result);
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};

	const disableListing = async () => {
		setDisabled(true);
		const result = await ProductCatalogService.updateProductListing({ id: productListing.id, disabled: true });
		setDisabled(false);
		if (result.success) {
			props.onChange(result.data);
			alert.success("Listing Disabled");
		} else if (result.validation) {
			alert.validation(result);
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};
	const enableListing = async () => {
		setDisabled(true);
		const result = await ProductCatalogService.updateProductListing({ id: productListing.id, disabled: false });
		setDisabled(false);
		if (result.success) {
			props.onChange(result.data);
			alert.success("Listing Enabled");
		} else if (result.validation) {
			alert.validation(result);
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Grid container>
			<Grid item>
				<PageTitle title={productListing.name} />
				<Typography variant="h4" gutterBottom>
					<IconButton onClick={history.goBack}>
						<ArrowBackIcon />
					</IconButton>
					{productListing.name}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item lg={6} xs={12}>
						<Card>
							<Grid container>
								<Grid item>
									<CardHeader title="Edit Listing" />
								</Grid>
								<GridGrow />
								<Grid item>
									{productListing.disabled != null ? (
										<Button variant="outlined" onClick={enableListing}>
											Enable
										</Button>
									) : (
										<RedButton variant="outlined" onClick={disableListing}>
											Disable
										</RedButton>
									)}
								</Grid>
							</Grid>
							<form onSubmit={onUpdateListing}>
								<CardContent>
									<Grid container spacing={2}>
										<Grid item sm={6} xs={12}>
											<TextField
												required
												fullWidth
												disabled={disabled}
												label="Name"
												value={name}
												onChange={(e) => setName(e.target.value)}
												error={validationError.isFieldInError(validationErrors, "Name")}
												helperText={validationError.getFieldError(validationErrors, "Name")}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<PriceTextField
												required
												fullWidth
												disabled={disabled}
												label="Price"
												value={price}
												onPriceChanged={(price) => setPrice(price)}
												error={validationError.isFieldInError(validationErrors, "Price")}
												helperText={validationError.getFieldError(validationErrors, "Price")}
											/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField
												fullWidth
												disabled={disabled}
												label="Unit (Optional)"
												value={unitName}
												onChange={(e) => setUnitName(e.target.value)}
												error={validationError.isFieldInError(validationErrors, "UnitName")}
												helperText={validationError.getFieldError(validationErrors, "UnitName")}
											/>
											<FormHelperText>
												Example: {name} - <Price value={price ?? 0} doNotRound />
												{unitName?.trim() !== "" && <span> per {unitName}</span>}
											</FormHelperText>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField
												fullWidth
												disabled={disabled}
												label="Product Code (Optional)"
												value={productCode}
												onChange={(e) => setProductCode(e.target.value)}
												error={validationError.isFieldInError(validationErrors, "ProductCode")}
												helperText={validationError.getFieldError(validationErrors, "ProductCode")}
											/>
											<FormHelperText>Product code or SKU to categorize this product. Must be unique.</FormHelperText>
										</Grid>

										<Grid item sm={6} xs={12}>
											<TextField
												fullWidth
												disabled={disabled || !accounts}
												label="Revenue Account"
												value={revenueAccountId}
												onChange={(e) => setRevenueAccountId(Number(e.target.value))}
												select
											>
												{!accounts && <MenuItem value={0}>Loading...</MenuItem>}
												{accounts &&
													accounts.map((account) => (
														<MenuItem key={account.id} value={account.id}>
															{account.name}
														</MenuItem>
													))}
											</TextField>
											<FormHelperText>
												Revenue account to attribute sales to.{" "}
												<ClientLink to={routes.business.resolve.generalLedgerAccountsPage()}>See accounts</ClientLink>
											</FormHelperText>
										</Grid>
									</Grid>
								</CardContent>
								<CardActions>
									<Button type="submit" disabled={disabled} color="primary" variant="contained">
										Save
									</Button>
								</CardActions>
							</form>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
