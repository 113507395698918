import { Modify } from "../../utility/modifyType";
import { GeocodedAddress } from "./GeocodedAddress";
import { PhoneNumber } from "./PhoneNumber";
import { Tag } from "./Tag";

export interface Customer {
	id: string;
	customerNumber: number;
	customerCode: string | null;
	name: string;
	firstName: string;
	lastName: string;
	phoneNumber: PhoneNumber | null;
	phoneNumberSecondary: PhoneNumber | null;
	email: string | null;
	emailSecondary: string | null;
	allowSendEmail: boolean;
	inactive: boolean;
	address: GeocodedAddress;
	balance: number;
	lastStatementDate: Date | null;
	tags: Tag[];
	alert: string | null;
	deliveryInstructions: string | null;
}

export type SerializedCustomer = Modify<
	Customer,
	{
		phoneNumber: string | null;
		phoneNumberExtension: string | null;
		phoneNumberSecondary: string | null;
		phoneNumberSecondaryExtension: string | null;
		lastStatementDate: string | null;
		allowSendEmail: boolean | undefined; // TODO New field. Remove after March
	}
>;

export const deserializeCustomer = (serverModel: SerializedCustomer): Customer => {
	return {
		...serverModel,
		phoneNumber: serverModel.phoneNumber
			? {
					number: serverModel.phoneNumber,
					extension: serverModel.phoneNumberExtension,
			  }
			: null,
		phoneNumberSecondary: serverModel.phoneNumberSecondary
			? {
					number: serverModel.phoneNumberSecondary,
					extension: serverModel.phoneNumberSecondaryExtension,
			  }
			: null,
		lastStatementDate: serverModel.lastStatementDate ? new Date(serverModel.lastStatementDate) : null,
		allowSendEmail: serverModel.allowSendEmail ?? serverModel.email !== null,
	};
};

export const serializeCustomer = (customer: Customer): SerializedCustomer => {
	return {
		...customer,
		phoneNumber: customer.phoneNumber?.number ?? null,
		phoneNumberExtension: customer.phoneNumber?.extension ?? null,
		phoneNumberSecondary: customer.phoneNumberSecondary ? customer.phoneNumberSecondary.number : null,
		phoneNumberSecondaryExtension: customer.phoneNumberSecondary ? customer.phoneNumberSecondary.extension : null,
		lastStatementDate: customer.lastStatementDate ? customer.lastStatementDate.toISOString() : null,
	};
};
