import { create } from "zustand";
import { Driver } from "../../entities/routing/Driver";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { DriverTicketService } from "../../services/routing/DriverTicketService";

export interface RouteBuilderState {
    selectedDriver?: Driver;
    drivers: Driver[];
    setDrivers: (drivers: Driver[]) => void;
    tickets: DriverTicket[];
    loadRoute: (ticketIds: string[]) => Promise<void>;
    clearRoute: () => void;
    updateTicket: (ticket: DriverTicket) => void;
    routeLoadStatus: "loading" | "ready";
}

export const useRouteBuilderStore = create<RouteBuilderState>((set, get) => ({
    selectedDriver: undefined,
    drivers: [],
    setDrivers: (drivers) => set({ drivers }),
    tickets: [],
    loadRoute: async (ticketIds) => {
        const { tickets } = get() as RouteBuilderState;
        const remainingTickets = tickets.filter((ticket) => ticketIds.includes(ticket.id));
        const ticketIdsToLoad = ticketIds.filter((id) => !remainingTickets.some((ticket) => ticket.id === id));
        if (ticketIdsToLoad.length === 0) {
            set({ tickets: remainingTickets });
            return;
        }


        set({ routeLoadStatus: "loading" });
        const result = await DriverTicketService.getMany(ticketIdsToLoad);
        if(result.error){
            // Handle error
            return;
        }
        const updatedTickets = [...result.data, ...remainingTickets];
        set({ tickets: updatedTickets, routeLoadStatus: "ready" });
    },
    clearRoute: () => set({ tickets: [] }),
    updateTicket: (ticket) => set((state: RouteBuilderState) => ({ tickets: state.tickets.map((t) => (t.id === ticket.id ? ticket : t)) })),
    routeLoadStatus: "ready",
}));