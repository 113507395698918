import { Box, Button, FormHelperText, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { runningOnProd } from "../../AppEnv";
import { CreditCardPaymentMethod } from "../../entities/billing/PaymentMethod";
import { Customer } from "../../entities/customer/Customer";
import { useAlert } from "../../hooks/useAlert";
import { PaymentMethodService, QualpayPaymentConfiguration } from "../../services/billing/PaymentMethodService";
import { QpEmbeddedForm } from "../../utility/QualpayJsClient";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";

interface QualpayCreditCardInputProps {
	customer: Customer;
	onClose: () => void;
	onCreate: (creditCard: CreditCardPaymentMethod) => void;
}

export function QualpayCreditCardInput(props: QualpayCreditCardInputProps) {
	const { customer } = props;

	const alert = useAlert();
	const theme = useTheme();
	const [configuration, setConfiguration] = React.useState<QualpayPaymentConfiguration>();
	const [failed, setFailed] = React.useState(false);

	React.useEffect(() => {
		async function loadConfiguration() {
			const result = await PaymentMethodService.getQualpayPaymentConfiguration();
			if (result.success) {
				setConfiguration(result.data);
			} else {
				alert.serverError(result);
				setFailed(true);
			}
		}
		loadConfiguration();
	}, [alert]);

	const generateFormStyles = () => {
		const backgroundColor = theme.palette.background.paper;
		const htmlStyles = `html { background-color: ${backgroundColor}; }`;
		const bodyStyles = `body { background-color: ${backgroundColor}; font-family: 'Roboto'; }`;
		const bodyContentStyles = `body .content { padding: 0 !important; }`;
		const cardBrandStyles = " .card-type-icon { display: none !important; }";
		return htmlStyles + bodyStyles + bodyContentStyles + cardBrandStyles;
	};

	if (failed) {
		return <Typography>Failed to load Qualpay credit card input</Typography>;
	}

	if (!configuration) {
		return <CenteredLoadingSpinner />;
	}

	return <CreditCard configuration={configuration} formStyle={generateFormStyles()} customer={customer} onClose={props.onClose} onCreate={props.onCreate} />;
}

const creditCardFormId = "qualpay-credit-card-form";
declare const qpEmbeddedForm: QpEmbeddedForm;

interface CreditCardProps {
	configuration: QualpayPaymentConfiguration;
	formStyle: string;
	customer: Customer;
	onClose: () => void;
	onCreate: (creditCard: CreditCardPaymentMethod) => void;
}

function CreditCard(props: CreditCardProps) {
	const { configuration, formStyle, customer } = props;
	const customerId = customer.id;
	const { merchantId, transientKey } = configuration;
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.only("xs"));

	const qualpayInputStyle: React.CSSProperties = {
		height: 38,
		width: "100%",
		border: "1px solid #E2E2E2",
		borderRadius: 4,
		padding: "6px 12px",
		outline: 0,
		color: "#000000DE",
		background: "#FBFBFB",
	};

	const billingInfoRef = useRef<{ firstName: string; lastName: string; zipCode: string }>({
		firstName: customer.firstName,
		lastName: customer.lastName,
		zipCode: customer.address.postalCode,
	});

	const [firstName, setFirstName] = React.useState(customer.firstName);
	const [lastName, setLastName] = React.useState(customer.lastName);
	const [zipCode, setZipCode] = React.useState(customer.address.postalCode);

	const [disabled, setDisabled] = React.useState(false);
	const [errors, setErrors] = React.useState<string[]>([]);

	useEffect(() => {
		billingInfoRef.current = { firstName, lastName, zipCode };
	}, [firstName, lastName, zipCode]);

	useEffect(() => {
		qpEmbeddedForm.loadFrame(merchantId, {
			formId: creditCardFormId,
			mode: runningOnProd() ? "prod" : "test",
			onSuccess: (response) => {
				const { firstName, lastName, zipCode } = billingInfoRef.current;
				PaymentMethodService.addQualpayCreditCard({
					cardId: response.card_id,
					customerId: customerId,
					firstName,
					lastName,
					zipCode,
				})
					.then((result) => {
						if (result.success) {
							props.onCreate(result.data);
						} else if (result.validation) {
							setErrors(result.errors.flatMap((e) => e.errors));
						} else {
							setErrors(["Unexpected error - " + result.message]);
						}
					})
					.finally(() => {
						setDisabled(false);
					});
			},
			onError: (response) => {
				setDisabled(false);
				setErrors(response.detail);
			},
			preSubmit: () => {
				setDisabled(true);
				setErrors([]);
			},
			transientKey,
			tokenize: true,
			font: "Roboto",
			style: formStyle,
			achConfig: { enabled: false },
			formFields: { cvv2: { required: true } },
		});
		return () => qpEmbeddedForm.unloadFrame();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merchantId, transientKey, formStyle, customerId]);

	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
	};

	return (
		<form id={creditCardFormId} onSubmit={onSubmit} style={{ width: 400 }}>
			<FormHelperText>Name on Card</FormHelperText>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<input placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} style={qualpayInputStyle} required />
				</Grid>
				<Grid item xs={6}>
					<input placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} style={qualpayInputStyle} required />
				</Grid>
			</Grid>
			<FormHelperText style={{ marginTop: 7 }}>Card Number</FormHelperText>
			<div id="qp-embedded-container" style={{ height: isXs ? 135 : 90 }} />
			<Box style={{ maxWidth: 182 }}>
				<FormHelperText>Billing Zip Code</FormHelperText>
				<input placeholder="Zip Code" value={zipCode} onChange={(e) => setZipCode(e.target.value)} style={qualpayInputStyle} required />
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={12} style={{ minHeight: 50 }}>
					<Grid container spacing={2}>
						{errors.map((error, index) => (
							<Grid item key={index}>
								<Typography color="error" variant="overline">
									{error}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Grid>

				<Grid item>
					<Button variant="outlined" color="secondary" onClick={props.onClose} disabled={disabled}>
						Cancel
					</Button>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" type="submit" disabled={disabled}>
						Submit
					</Button>
				</Grid>
			</Grid>
		</form>
	);
}
