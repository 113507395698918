import React from "react";
import { Card, Grid, CardHeader, CardContent, List, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";

import { SearchableInvoice } from "../../../entities/billing/Invoice";
import { GridGrow } from "../../../components/GridGrow";
import { KpiLoadingCard } from "./KpiLoadingCard";
import { HorizontalCentered } from "../../../components/HorizontalCentered";
import CheckIcon from "@material-ui/icons/Check";
import { InvoiceService } from "../../../services/billing/InvoiceService";
import { useAlert } from "../../../hooks/useAlert";

export function OverdueInvoicesCard() {
	const alert = useAlert();

	const [overdueInvoices, setOverdueInvoices] = React.useState<SearchableInvoice[] | undefined>(undefined);
	const oneToThirtyDaysOverdue = React.useMemo(() => overdueInvoices?.filter((i) => i.daysOverdue >= 1 && i.daysOverdue <= 30)?.length ?? 0, [overdueInvoices]);
	const thirtyOneToSixtyDaysOverdue = React.useMemo(() => overdueInvoices?.filter((i) => i.daysOverdue >= 31 && i.daysOverdue <= 60)?.length ?? 0, [overdueInvoices]);
	const moreThanSixtyDaysOverdue = React.useMemo(() => overdueInvoices?.filter((i) => i.daysOverdue > 60)?.length ?? 0, [overdueInvoices]);

	React.useEffect(() => {
		async function loadOverdueInvoices() {
			const result = await InvoiceService.search({ status: "open", daysOverdue: 1, pageSize: undefined });
			if (result.success) {
				setOverdueInvoices(result.data.results);
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		}
		loadOverdueInvoices();
	}, [alert]);

	if (!overdueInvoices) return <KpiLoadingCard title="Overdue Invoices" />;

	if (overdueInvoices.length === 0) return <NoOverdueInvoicesCard />;

	return (
		<Card style={{ height: "100%" }}>
			<Grid container direction="column" justify="center" style={{ height: "100%" }}>
				<Grid item>
					<CardHeader title="Overdue Invoices" />
				</Grid>
				<GridGrow>
					<Grid container direction="column" justify="center" style={{ height: "100%" }}>
						<Grid item>
							<CardContent>
								<List component="nav" aria-label="mailbox folders">
									<ListItem divider>
										<ListItemText primary="1-30 days overdue" />
										<ListItemSecondaryAction>
											<ListItemText primary={oneToThirtyDaysOverdue} />
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem divider>
										<ListItemText primary="31-60 days overdue" />
										<ListItemSecondaryAction>
											<ListItemText primary={thirtyOneToSixtyDaysOverdue} />
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem>
										<ListItemText primary="> 60 days overdue" />
										<ListItemSecondaryAction>
											<ListItemText primary={moreThanSixtyDaysOverdue} />
										</ListItemSecondaryAction>
									</ListItem>
								</List>
							</CardContent>
						</Grid>
					</Grid>
				</GridGrow>
			</Grid>
		</Card>
	);
}

function NoOverdueInvoicesCard() {
	return (
		<Card style={{ height: "100%" }}>
			<Grid container direction="column" style={{ height: "100%" }}>
				<Grid item>
					<CardHeader title={"No Overdue Invoices"} />
				</Grid>
				<GridGrow>
					<Grid container direction="column" justify="center" style={{ height: "100%" }}>
						<Grid item>
							<HorizontalCentered>
								<CheckIcon style={{ fontSize: "5rem" }} />
							</HorizontalCentered>
						</Grid>
					</Grid>
				</GridGrow>
			</Grid>
		</Card>
	);
}
