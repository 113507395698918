import {
	Box,
	Typography,
	List,
	ListItemText,
	ListItem,
	Button,
	TextField,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Customer } from "../../../../entities/customer/Customer";
import CenteredLoadingSpinner from "../../../../components/CenteredLoadingSpinner";

import { useAlert } from "../../../../hooks/useAlert";
import { PaymentTerms } from "../../../../entities/billing/PaymentTerms";
import { PaymentTermsService } from "../../../../services/billing/PaymentTermsService";
import { DisplayOverdueFee } from "../../../business/payment-terms/PaymentTermsListView";
import { useCustomerPaymentCardContext } from "./CustomerPaymentCard";
import { useCustomerDetailPage } from "../../CustomerDetailPage";

export function PaymentTermsSection() {
	const customerContext = useCustomerDetailPage();
	const { profile, updateProfile } = customerContext;
	const context = useCustomerPaymentCardContext();
	const {  disabled } = context;

	const [assignPaymentTermsDialog, setAssignPaymentTermsDialog] = useState(false);

	const onUpdateTerms = (paymentTerms: PaymentTerms) => updateProfile(p => ({ ...p, paymentTerms }));


	return (
		<Box borderRight="3px solid rgba(0, 0, 0, 0.12)">
			<AssignPaymentTermsDialog
					open={assignPaymentTermsDialog}
					onClose={() => setAssignPaymentTermsDialog(false)}
					customer={profile.customer}
					currentTerms={profile.paymentTerms}
					onAssign={onUpdateTerms}
				/>
			<Box pl={1}>
				<Typography variant="overline">Payment Terms</Typography>
			</Box>
			<List dense style={{ padding: 0 }}>
				<ListItem button onClick={() => setAssignPaymentTermsDialog(true)} disabled={disabled}>
					<ListItemText primary={profile.paymentTerms.name} />
				</ListItem>
			</List>
			</Box>
	);
}

interface AssignPaymentTermsDialogProps {
	currentTerms: PaymentTerms;
	customer: Customer;
	open: boolean;
	onClose: () => void;
	onAssign: (paymentTerms: PaymentTerms) => void;
}

function AssignPaymentTermsDialog(props: AssignPaymentTermsDialogProps) {
	const { currentTerms, customer, open } = props;
	const alert = useAlert();
	const [paymentTerms, setPaymentTerms] = useState<PaymentTerms[]>();
	const [selectedPaymentTerms, setSelectedPaymentTerms] = useState<PaymentTerms>(currentTerms);
	const [disabled, setDisabled] = useState(false);

	const [edit, setEdit] = useState(false);

	useEffect(() => {
		async function loadPaymentTerms() {
			const result = await PaymentTermsService.getAll();
			if (result.success) {
				setPaymentTerms(result.data);
			} else {
				alert.serverError(result);
			}
		}
		if (open)
			loadPaymentTerms();
	}, [alert, open]);

	const onChange = (event: React.ChangeEvent<{ value: string }>) => {
		const selectedTerms = paymentTerms?.find((pt) => pt.id === event.target.value);
		if (selectedTerms) setSelectedPaymentTerms(selectedTerms);
	};

	const onAssign = async () => {
		if (selectedPaymentTerms.id === currentTerms.id) {
			onClose();
		} else {
			setDisabled(true);
			const result = await PaymentTermsService.assignToCustomer({ customerId: customer.id, paymentTermsId: selectedPaymentTerms.id });
			setDisabled(false);
			if (result.success) {
				props.onAssign(selectedPaymentTerms);
				onClose();
				alert.success(`Assigned ${selectedPaymentTerms.name} to ${customer.name}`);
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		}
	};

	const onClose = () => {
		setEdit(false);
		props.onClose();
	};

	return (
		<Dialog open={props.open} onClose={onClose}>
			{edit && (
				<>
					<DialogTitle>Change Payment Terms</DialogTitle>
					<DialogContent>
						{!paymentTerms && <CenteredLoadingSpinner color="primary" />}
						{paymentTerms && (
							<TextField select fullWidth variant="outlined" label="Payment Terms" value={selectedPaymentTerms.id} onChange={onChange} disabled={disabled}>
								{paymentTerms.map((pt) => (
									<MenuItem key={pt.id} value={pt.id}>
										{pt.name}
									</MenuItem>
								))}
							</TextField>
						)}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setEdit(false)} color="secondary" variant="outlined" disabled={disabled}>
							Cancel
						</Button>
						<Button onClick={onAssign} color="primary" variant="contained" disabled={disabled}>
							Assign
						</Button>
					</DialogActions>
				</>
			)}
			{!edit && (
				<>
					<DialogTitle style={{ paddingBottom: 5 }}>{currentTerms.name} Details</DialogTitle>
					<List>
						<ListItem>
							<ListItemText primary="Description" secondary={currentTerms.description} />
						</ListItem>
						<ListItem>
							<ListItemText primary="Days To Pay" secondary={currentTerms.dueDays} />
							<ListItemText primary="Grace Days" secondary={currentTerms.graceDays} />
						</ListItem>
						<ListItem>
							<ListItemText primary="Overdue Fee" secondary={<DisplayOverdueFee paymentTerms={currentTerms} />} />
						</ListItem>
						<ListItem>
							<ListItemText primary="Early Payment Discount" secondary={<DisplayOverdueFee paymentTerms={currentTerms} />} />
						</ListItem>
					</List>
					<DialogActions>
						<Button onClick={onClose} color="secondary" variant="outlined" disabled={disabled}>
							Close
						</Button>
						<Button onClick={() => setEdit(true)} color="primary" variant="contained" disabled={disabled}>
							Change Payment Terms
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}
