import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: theme.palette.primary.main,
		},
	})
);

export default function OverlayLoadingScreen() {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={true}>
			<CircularProgress size="5rem" color="inherit" />
		</Backdrop>
	);
}
