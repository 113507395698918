import { Box, Button, Card, CardHeader, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { routes } from "../../routes";
import { PropaneTankDetailsCard } from "./TankDetailCard";
import { usePropaneTankDetailPage } from "./PropaneTankDetailPage";
import { PropaneTankService } from "../../services/customer/PropaneTankService";
import { useAlert } from "../../hooks/useAlert";
import { TankFillConfigurationCard } from "./TankFillConfigurationCard";
import { TankRegulatorsCard } from "./TankRegulatorsCard";
import { TankFillHistoryCard } from "./TankFillHistoryCard";
import { GridGrow } from "../../components/GridGrow";
import { TransferTankDialog } from "./TransferTankDialog";
import { PropaneTank } from "../../entities/customer/PropaneTank";
import { TankMonitor } from "../../entities/routing/TankMonitor";

export function PropaneTankDetailView() {
	const context = usePropaneTankDetailPage();
	const alert = useAlert();
	const { propaneTank, customer, pageDisabled } = context;

	const [notes, setNotes] = React.useState(propaneTank.notes);
	const [showTransferDialog, setShowTransferDialog] = React.useState(false);

	const history = useHistory();

	const onUpdateNotes = async () => {
		context.updatePageDisabled(true);
		const result = await PropaneTankService.update({ propaneTankId: propaneTank.id, notes });
		context.updatePageDisabled(false);
		if (result.success) {
			context.updatePropaneTank(result.data);
			alert.success("Notes updated successfully");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onTankTransfer = (tank: PropaneTank) => {
		context.updatePropaneTank(tank);
		setShowTransferDialog(false);
	};

	return (
		<Grid container spacing={2}>
			<PageTitle title={`${propaneTank.gallons} Gal Tank - ${customer.name}`} />
			<TransferTankDialog open={showTransferDialog} onClose={() => setShowTransferDialog(false)} tank={propaneTank} onTransfer={onTankTransfer} />
			<Grid item xs={12}>
				<Grid container wrap="nowrap" spacing={3} alignContent="center" alignItems="center">
					<Grid item>
						<IconButton onClick={history.goBack} style={{ padding: 7 }}>
							<ArrowBackIcon />
						</IconButton>
					</Grid>
					<Grid item>
						<Typography variant="h5">
							<Link to={routes.app.resolve.customerDetailPage(customer.id)} style={{ cursor: "pointer", fontWeight: 500, color: "unset" }}>
								{customer.name}
							</Link>
							{"'"}s {propaneTank.gallons} Gallon Tank
						</Typography>
					</Grid>
					<GridGrow />
					<Grid item>
						<Button variant="outlined" onClick={() => setShowTransferDialog(true)}>
							Transfer Tank
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<PropaneTankDetailsCard />
			</Grid>
			<Grid item>
				<Card>
					<CardHeader title="Notes" style={{ paddingBottom: 0 }} />
					<Box m={1} minWidth={350}>
						<TextField
							multiline
							fullWidth
							variant="filled"
							placeholder="Tank Notes"
							value={notes}
							onChange={(e) => setNotes(e.target.value)}
							disabled={pageDisabled}
						/>
					</Box>
					<Button size="small" fullWidth variant="contained" style={{ borderRadius: 0 }} disabled={pageDisabled} onClick={onUpdateNotes}>
						Save Notes
					</Button>
				</Card>
			</Grid>
			<Grid item>
				<TankFillConfigurationCard />
			</Grid>
			<Grid item style={{ width: "100%", maxWidth: 400 }}>
				<TankRegulatorsCard />
			</Grid>
			{propaneTank.tankMonitor && (
				<Grid item style={{ width: "100%", maxWidth: 400 }}>
					<TankMonitorCard monitor={propaneTank.tankMonitor} />
				</Grid>
			)}
			{/* <Grid item style={{ width: "100%", maxWidth: 400 }}>
				<FixedTankRouteCard />
			</Grid> */}
			<Grid item md={5} sm={6} xs={12}>
				<TankFillHistoryCard />
			</Grid>
			<Grid item md={4} sm={6} xs={12}>
				<WeatherLocationCard location={propaneTank.weatherLocation} />
			</Grid>
		</Grid>
	);
}

function TankMonitorCard(props: { monitor: TankMonitor }) {
	const { monitor } = props;
	const history = useHistory();
	const alert = useAlert();

	const onClick = () => {
		if(monitor.brand === "Otodata") {
			history.push(routes.app.resolve.otodataTankMonitorDetailPage(monitor.id));
		} else {
			alert.error("Unknown Monitor type");
		}
	}
	return (
		<Card>
			<CardHeader title="Tank Monitor" subheader={`${monitor.brand} [${monitor.model}]`} />
			<List>
				<ListItem>
					<ListItemText primary="Tank Percent" secondary={monitor.lastReportedPercentFull ? `${monitor.lastReportedPercentFull}%`: "Not Reported"} /> 
					<ListItemText primary="Last Monitor Check-in" secondary={monitor.lastReportedAt ? monitor.lastReportedAt.toLocaleString() : "Not Reported"} />
				</ListItem>
				<ListItem>
					<ListItemText primary="Status" secondary={monitor.status} />
				</ListItem>
			</List>
			<Button variant="contained" color="default" fullWidth onClick={onClick}>
				View Monitor
			</Button>
		</Card>
	);
}

function WeatherLocationCard(props: { location: { id: number; name: string } | null }) {
	return (
		<Card>
			<CardHeader title="Degree Day Location" subheader={props.location?.name || "No Weather Location"} />
		</Card>
	);
}
