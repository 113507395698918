import { useParams } from "react-router-dom";
import { CustomerContract, CustomerContractType } from "../../../entities/customer/CustomerContract";
import React from "react";
import { ContractService } from "../../../services/customer/ContractService";
import { useAlert } from "../../../hooks/useAlert";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PriceLockContractDetailView } from "./PriceLockContractDetailView";

export function CustomerContractDetailPage(){
    const { contractId } = useParams<{ contractId: string }>();
    const alert = useAlert();

    const [contract, setContract] = React.useState<CustomerContract>();

    React.useEffect(() => {
        async function fetchContract() {
            const result = await ContractService.get(contractId);
            if(result.success) setContract(result.data);
            else alert.serverError(result);
        }
        fetchContract();
    }, [contractId, alert]);

    if(!contract) return <OverlayLoadingScreen/>;

    if(contract.type === CustomerContractType.PriceLock){
        return <PriceLockContractDetailView contract={contract} onUpdate={setContract}/>
    }

    return <div>Contract type not supported</div>
}