import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	InputAdornment,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../../components/BackButton";
import { ClientLink } from "../../../components/ClientLink";
import { GridGrow } from "../../../components/GridGrow";
import { NumberTextField } from "../../../components/NumberTextField";
import { PageTitle } from "../../../components/PageTitle";
import { PhoneNumberLink } from "../../../components/PhoneNumberLink";
import { TagBubble } from "../../../components/TagBubble";
import { DriverTicket, TicketPriceLockContract, TicketProduct, TicketQuoteItem, TicketQuoteType, TicketTankFill } from "../../../entities/routing/DriverTicket";
import { useAlert } from "../../../hooks/useAlert";
import { useOfflineTickets } from "../../../providers/OfflineTicketProvider";
import { routes } from "../../../routes";
import { StopLineItem } from "./StopLineItem";
import { Price } from "../../../components/Price";
import { RestrictToRole } from "../../../components/RestrictToRole";
import { ButtonLink } from "../../../components/ButtonLink";
import { PriceTextField } from "../../../components/PriceTextField";
import { Money } from "../../../utility/Money";
import { useOfflineStatus } from "../../../providers/OfflineStatusProvider";
import { printDate } from "../../../utility/PrintDate";
import { useAppUser } from "../../../providers/AppUserProvider";

interface StopTicketContext {
	driverTicket: DriverTicket;
	tankFills: TicketTankFill[];
	products: TicketProduct[];
	lineItems: StopLineItem[];

	setTankFills: React.Dispatch<React.SetStateAction<TicketTankFill[]>>;
	setProducts: React.Dispatch<React.SetStateAction<TicketProduct[]>>;
}

const StopTicketReactContext = React.createContext<StopTicketContext>({} as StopTicketContext);

function createStopItems(contracts: TicketPriceLockContract[], quoteItems: TicketQuoteItem[]): StopLineItem[] {
	const remainingQuoteItems = [...quoteItems];
	const stopItems: StopLineItem[] = [];

	for (const contract of contracts) {
		let quantityRemaining = contract.quantityRemaining ?? Number.MAX_SAFE_INTEGER;
		const matchContract = remainingQuoteItems.filter((f) => f.productListingId === contract.productListingId);
		for (const quoteItem of matchContract) {
			if (quantityRemaining === 0) {
				stopItems.push(quoteItem.type === TicketQuoteType.TankFill ? StopLineItem.createTankFill(quoteItem, null) : StopLineItem.createProduct(quoteItem, null));
				remainingQuoteItems.splice(remainingQuoteItems.indexOf(quoteItem), 1);
				continue;
			}

			const appliedQuantity = Math.min(quantityRemaining, quoteItem.quantity ?? 0);
			quantityRemaining -= appliedQuantity;

			const contractPrice = contract.priceCeiling && contract.price.greaterThan(quoteItem.unitPrice) ? quoteItem.unitPrice : contract.price;

			const contractQuoteItem: TicketQuoteItem = { ...quoteItem, quantity: appliedQuantity ? appliedQuantity : null, unitPrice: contractPrice };
			stopItems.push(
				contractQuoteItem.type === TicketQuoteType.TankFill
					? StopLineItem.createTankFill(contractQuoteItem, contract)
					: StopLineItem.createProduct(contractQuoteItem, contract)
			);

			const remainingQuantity = quoteItem.quantity ? quoteItem.quantity - appliedQuantity : 0;
			if (remainingQuantity > 0) {
				const remainingQuoteItem: TicketQuoteItem = { ...quoteItem, quantity: remainingQuantity };
				stopItems.push(
					remainingQuoteItem.type === TicketQuoteType.TankFill
						? StopLineItem.createTankFill(remainingQuoteItem, null)
						: StopLineItem.createProduct(remainingQuoteItem, null)
				);
			}

			remainingQuoteItems.splice(remainingQuoteItems.indexOf(quoteItem), 1);
		}
	}

	for (const quoteItem of remainingQuoteItems) {
		stopItems.push(quoteItem.type === TicketQuoteType.TankFill ? StopLineItem.createTankFill(quoteItem, null) : StopLineItem.createProduct(quoteItem, null));
	}

	return stopItems;
}

export function useStopTicketContext() {
	return React.useContext(StopTicketReactContext);
}

export function RouteStopTicketView(props: { driverTicket: DriverTicket }) {
	const { driverTicket } = props;
	const { priceLocks } = driverTicket;
	const offlineTicketContext = useOfflineTickets();
	const isOffline = useOfflineStatus();
	const history = useHistory();
	const alert = useAlert();

	const [completionInProgress, setCompletionInProgress] = React.useState(false);
	const [tankFills, setTankFills] = useState(driverTicket.quoteItems.filter((i) => i.type === TicketQuoteType.TankFill).map((i) => ({ ...i })) as TicketTankFill[]);
	const [products, setProducts] = useState(driverTicket.quoteItems.filter((i) => i.type === TicketQuoteType.Product).map((i) => ({ ...i })) as TicketProduct[]);
	const [confirmIncompleteItems, setConfirmIncompleteItems] = React.useState<StopLineItem[]>();

	const lineItems = useMemo(() => createStopItems(priceLocks, [...tankFills, ...products]), [priceLocks, tankFills, products]);

	const completeTicket = async () => {
		const incompleteItems = lineItems.filter((f) => f.quantity === null || f.quantity === 0);
		if (incompleteItems.length > 0 && !confirmIncompleteItems) {
			setConfirmIncompleteItems(incompleteItems);
			return;
		} else if (confirmIncompleteItems) {
			setConfirmIncompleteItems(undefined);
		}

		setCompletionInProgress(true);
		const result = await offlineTicketContext.completeDelivery({
			driverTicketId: driverTicket.id,
			quoteItems: lineItems.map((i) => ({ quoteItemId: i.quoteItemId, quantity: i.quantity, price: i.priceValue ?? 0, contractId: i.priceLock?.id ?? null })),
		});
		if (result === "offline") {
			alert.validation({ errors: [{ field: "Complete Ticket", errors: ["You are offline. This ticket will be completed when you are back online."] }] });
			offlineTicketContext.remove(driverTicket);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			history.replace(routes.app.resolve.driverRoutePage(driverTicket.driverId!));
		} else if (result.success) {
			alert.success("Ticket complete");
			offlineTicketContext.remove(driverTicket);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			history.replace(routes.app.resolve.driverRoutePage(driverTicket.driverId!));
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
		setCompletionInProgress(false);
	};

	return (
		<StopTicketReactContext.Provider value={{ driverTicket, tankFills, products, lineItems, setTankFills, setProducts }}>
			<PageTitle title={`Route Ticket`} />
			<ConfirmIncompleteDeliveryDialog quoteItems={confirmIncompleteItems} onCancel={() => setConfirmIncompleteItems(undefined)} onConfirm={completeTicket} />
			<Card style={{ height: "50px", position: "fixed", top: 0, left: 0, right: 0, zIndex: 1 }} elevation={4}>
				<Box style={{ height: "50px" }}>
					<Grid container alignItems="center" wrap="nowrap">
						<Grid item>
							<BackButton />
						</Grid>
						<GridGrow>
							{isOffline && (
								<Typography variant="overline" color="error" align="center" style={{ fontSize: "1rem", display: "block" }}>
									Offline
								</Typography>
							)}
						</GridGrow>
						<Grid item>
							<Button
								variant="outlined"
								onClick={() => history.push(routes.app.resolve.ticketDetailPage(driverTicket.id))}
								style={{ marginRight: 10 }}
								disabled={completionInProgress || isOffline}
							>
								Change Ticket
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Card>
			<Box style={{ position: "absolute", top: "50px", bottom: 0, left: 0, right: 0, overflowY: "auto" }}>
				<Grid container justify="center">
					<Grid item xl={5} lg={6} md={8} sm={10} xs={12}>
						<Card style={{ marginTop: 10, marginBottom: 10 }}>
							<TicketHeader />
							<Instructions />
							<Notes />

							<TankFills />

							<OrderSummary />
							<Button disabled={completionInProgress} fullWidth variant="contained" color="primary" style={{ marginTop: 20 }} onClick={completeTicket}>
								{completionInProgress ? "Completing ticket..." : "Complete Ticket"}
							</Button>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</StopTicketReactContext.Provider>
	);
}

function Notes() {
	const appUser = useAppUser();
	const offlineTicketContext = useOfflineTickets();
	const { driverTicket } = useStopTicketContext();
	const notes = driverTicket.ticketNotes;
	const alert = useAlert();

	const [addMode, setAddMode] = useState(false);
	const [newNote, setNewNote] = useState("");
	const [disabled, setDisabled] = useState(false);

	const onCancel = () => {
		setNewNote("");
		setAddMode(false);
	};

	const addNote = async () => {
		setDisabled(true);
		const result = await offlineTicketContext.addTicketNote({
			ticketId: driverTicket.id,
			customerId: driverTicket.customer.id,
			note: newNote,
		});
		setDisabled(false);

		if (result === "offline") {
			alert.validation({ errors: [{ field: "Add Note", errors: ["You are offline. This note will be added when you are back online."] }] });
			setNewNote("");
			setAddMode(false);
			offlineTicketContext.update({
				...driverTicket,
				ticketNotes: [
					...notes,
					{ id: `offline-${Math.floor(Math.random() * 10000) + 1}`, note: newNote, createdBy: `${appUser.firstName} ${appUser.lastName}`, created: new Date() },
				],
			});
		} else if (result.success) {
			setNewNote("");
			setAddMode(false);
			offlineTicketContext.update(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Box pl={1} pr={1}>
			<Grid container spacing={1}>
				{notes.map((note) => (
					<Grid item xs={12} sm={6} key={note.id}>
						<Card variant="outlined">
							<Box p={1} pt={0}>
								<Grid container spacing={1} alignItems="center">
									<Grid item>
										<Typography variant="body2" color="textSecondary">
											{note.createdBy}
										</Typography>
									</Grid>
									<GridGrow />
									<Grid item>
										<Typography variant="overline" color="textSecondary">
											{printDate.shortDate(note.created)}
										</Typography>
									</Grid>
								</Grid>
								<Typography variant="body1" style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
									{note.note}
								</Typography>
							</Box>
						</Card>
					</Grid>
				))}
				{!addMode && (
					<Grid item xs={12}>
						<Button variant="outlined" color="primary" onClick={() => setAddMode(true)}>
							Add Note
						</Button>
					</Grid>
				)}
				{addMode && (
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item sm={6} xs={12}>
								<TextField
									multiline
									fullWidth
									autoFocus
									variant="outlined"
									label="Note"
									value={newNote}
									onChange={(e) => setNewNote(e.target.value)}
									InputProps={{
										rows: 3,
									}}
									disabled={disabled}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item>
										<Button variant="contained" color="primary" onClick={addNote} disabled={disabled || newNote.trim() === ""}>
											Add
										</Button>
									</Grid>
									<Grid item>
										<Button variant="outlined" color="secondary" onClick={onCancel} disabled={disabled}>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}

function Instructions() {
	const { driverTicket } = useStopTicketContext();

	const hasInstructions = driverTicket.instructions !== null && driverTicket.instructions.trim() !== "";

	return (
		<Box p={1} pt={2}>
			<Typography variant="h6" color="textSecondary">
				Ticket Instructions
			</Typography>
			<Typography color={hasInstructions ? "textPrimary" : "textSecondary"} style={{ fontSize: "1.15rem", whiteSpace: "pre-wrap" }}>
				{hasInstructions ? driverTicket.instructions : "None"}
			</Typography>
		</Box>
	);
}

function OrderSummary() {
	const { lineItems } = useStopTicketContext();

	if (lineItems.length === 0) {
		return null;
	}

	const taxTotal = lineItems.some((i) => i.tax === null) ? null : lineItems.reduce((sum, i) => sum.add(i.tax ?? Money.zero), Money.zero);
	const subtotal = lineItems.some((i) => i.subtotal === null) ? null : lineItems.reduce((sum, i) => sum.add(i.subtotal ?? Money.zero), Money.zero);
	const total = subtotal && taxTotal ? subtotal.add(taxTotal) : null;

	return (
		<Grid container>
			{lineItems.map((i, index) => (
				<Grid item xs={12} key={index}>
					<Divider />
					<Box p={1}>
						<LineItem lineItem={i} />
					</Box>
				</Grid>
			))}
			<Grid item xs={12}>
				<Divider />
				<Grid container justify="flex-end" spacing={2} style={{ padding: 10 }}>
					<Grid item>
						<Typography variant="h6">Subtotal: {subtotal ? <Price value={subtotal} /> : "..."}</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6">Tax: {taxTotal ? <Price value={taxTotal} /> : "..."}</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6">Total: {total ? <Price value={total} /> : "..."}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

function LineItem(props: { lineItem: StopLineItem }) {
	const { lineItem } = props;
	const { setTankFills, setProducts } = useStopTicketContext();

	const [editQuantity, setEditQuantity] = useState(false);
	const [editPrice, setEditPrice] = useState(false);

	const updateQuantity = (quantity: number | null) => {
		const quantityValue = quantity === 0 ? null : quantity;
		if (lineItem.type.name === "TankFill") {
			setTankFills((tankFills) => tankFills.map((f) => (f.id === lineItem.quoteItemId ? { ...f, quantity: quantityValue } : f)));
		} else {
			setProducts((products) => products.map((f) => (f.id === lineItem.quoteItemId ? { ...f, quantity: quantityValue } : f)));
		}
	};

	const updatePrice = (price: number | null) => {
		const priceValue = price ?? 0;
		if (lineItem.type.name === "TankFill") {
			setTankFills((tankFills) => tankFills.map((f) => (f.id === lineItem.quoteItemId ? { ...f, unitPrice: Money.fromDecimal(priceValue, 3) } : f)));
		} else {
			setProducts((products) => products.map((f) => (f.id === lineItem.quoteItemId ? { ...f, unitPrice: Money.fromDecimal(priceValue, 3) } : f)));
		}
	};

	const pendingQuantity = lineItem.quantity === null;

	return (
		<Grid container spacing={1} alignItems="center">
			<Grid item xs={12}>
				<Grid container>
					<Grid item>
						<Typography style={{ fontSize: "1.10rem" }}>{lineItem.productName}</Typography>
					</Grid>
					{lineItem.type.name === "TankFill" && lineItem.type.tankDescription && (
						<>
							<GridGrow />
							<Grid item>
								<Typography variant="overline" style={{ fontSize: "1rem", lineHeight: 1.66 }} color="textSecondary">
									{lineItem.type.tankDescription}
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			{editQuantity && (
				<Grid item xs={12}>
					<Grid container item md={3} sm={4} xs={12}>
						<NumberTextField
							fullWidth
							onNumberChange={updateQuantity}
							value={lineItem.quantity}
							label="Change Quantity"
							variant="outlined"
							autoFocus
							onBlur={() => setEditQuantity(false)}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									setEditQuantity(false);
								} else if (e.key === "Escape") {
									setEditQuantity(false);
								}
							}}
						/>
					</Grid>
				</Grid>
			)}
			{editPrice && (
				<Grid item xs={12}>
					<Grid container item md={3} sm={4} xs={12}>
						<div onBlur={() => setEditPrice(false)}>
							<PriceTextField
								fullWidth
								onPriceChanged={updatePrice}
								value={lineItem.priceValue}
								label="Change Price"
								variant="outlined"
								autoFocus
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										setEditPrice(false);
									} else if (e.key === "Escape") {
										setEditPrice(false);
									}
								}}
							/>
						</div>
					</Grid>
				</Grid>
			)}

			<Grid item xs={3}>
				<Typography>
					<ButtonLink onClick={() => setEditQuantity(true)}>Quantity:</ButtonLink>
				</Typography>
				<Typography color={pendingQuantity ? "textSecondary" : "textPrimary"}>{pendingQuantity ? "Pending" : lineItem.quantity}</Typography>
			</Grid>
			<Grid item xs={3}>
				<Typography>
					<ButtonLink onClick={() => setEditPrice(true)} disabled={lineItem.priceLock != null}>
						Price:
					</ButtonLink>
				</Typography>
				<Typography>
					<Price value={lineItem.price} doNotRound />
				</Typography>
				{lineItem.priceLock && (
					<Typography variant="overline" color="textSecondary">
						Locked
					</Typography>
				)}
			</Grid>
			<Grid item xs={3}>
				<Typography>Tax:</Typography>
				<Typography color={pendingQuantity ? "textSecondary" : "textPrimary"}>{lineItem.tax ? <Price value={lineItem.tax} /> : "..."}</Typography>
			</Grid>
			<Grid item xs={3}>
				<Typography>{lineItem.subtotal && <Price value={lineItem.subtotal} />}</Typography>
			</Grid>
		</Grid>
	);
}

function TankFills() {
	const { tankFills } = useStopTicketContext();

	return (
		<Box p={1}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant="h6" color="textSecondary">
						Tank Fills
					</Typography>
				</Grid>
				{tankFills.map((tankFill, index) => (
					<Grid item md={3} sm={4} xs={6} key={index}>
						<TankFill tankFill={tankFill} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

function TankFill(props: { tankFill: TicketTankFill }) {
	const { tankFill } = props;
	const { setTankFills } = useStopTicketContext();

	const [amountToDeliver] = useState((tankFill.quantity ?? 0) === 0 ? "Fill up" : `${tankFill.quantity} gal`);

	const updateAmountDelivered = (amountDelivered: number | null) => {
		setTankFills((tankFills) =>
			tankFills.map((i) => {
				if (i.id === tankFill.id) {
					return { ...i, quantity: amountDelivered };
				}
				return i;
			})
		);
	};

	return (
		<>
			<NumberTextField
				fullWidth
				onNumberChange={updateAmountDelivered}
				value={tankFill.quantity}
				label={`${tankFill.tank.gallons} Tank`}
				variant="outlined"
				InputLabelProps={{
					style: { fontSize: "1.15rem" },
				}}
				InputProps={{ endAdornment: <InputAdornment position="end">Gal</InputAdornment> }}
			/>
			<Typography style={{ paddingLeft: 5, display: "block", fontSize: ".85rem", lineHeight: 1.66, textTransform: "none" }} variant="overline">
				{`${amountToDeliver} ${tankFill.tank.description ? `- ${tankFill.tank.description}` : ""}`}
			</Typography>
			{tankFill.tank.lastFillDate && tankFill.tank.lastFillAmount && (
				<Typography color="textSecondary" style={{ paddingLeft: 5, display: "block", fontSize: ".85rem", lineHeight: 1.66, textTransform: "none" }} variant="overline">
					Last: {printDate.shortDate(new Date(tankFill.tank.lastFillDate))} - {tankFill.tank.lastFillAmount}
				</Typography>
			)}
		</>
	);
}

function TicketHeader() {
	const { driverTicket } = useStopTicketContext();
	return (
		<Box pt={1} pl={1} pr={1}>
			<Grid container spacing={1}>
				<Grid item>
					<Box>
						<Typography variant="h6" color="textSecondary">
							Customer
						</Typography>
						<RestrictToRole office unauthorized={<Typography>{driverTicket.customer.name}</Typography>}>
							<Typography>
								<ClientLink to={routes.app.resolve.customerDetailPage(driverTicket.customer.id)}>{driverTicket.customer.name}</ClientLink>
							</Typography>
						</RestrictToRole>
						{driverTicket.customer.phoneNumber && (
							<Typography>
								<PhoneNumberLink phoneNumber={driverTicket.customer.phoneNumber} />
							</Typography>
						)}
						<Typography>{driverTicket.location.street}</Typography>
						<Typography>
							{driverTicket.location.city}, {driverTicket.location.state} {driverTicket.location.postalCode}
						</Typography>
						{driverTicket.customer.balance > 0 && (
							<Typography>
								<TagBubble tag={{ color: "red", id: 0, text: "Outstanding Balance", type: "Customer" }} />
							</Typography>
						)}

						{driverTicket.tags.length > 0 && (
							<Box style={{ padding: 10 }}>
								<Grid container spacing={1}>
									{driverTicket.tags.map((tag) => (
										<Grid item key={tag.id}>
											<TagBubble tag={tag} />
										</Grid>
									))}
								</Grid>
							</Box>
						)}
					</Box>
				</Grid>
				<GridGrow />
				<Grid item>
					<DeliveryInstructions />
				</Grid>
			</Grid>
		</Box>
	);
}

function DeliveryInstructions() {
	const { driverTicket } = useStopTicketContext();
	const offlineTicketContext = useOfflineTickets();
	const alert = useAlert();

	const [editMode, setEditMode] = React.useState(false);
	const [instructions, setInstructions] = React.useState(driverTicket.customer.deliveryInstructions);

	const onSave = async () => {
		const deliveryInstructions = instructions == null || instructions.trim() === "" ? null : instructions;
		const result = await offlineTicketContext.updateDeliveryInstructions({ customerId: driverTicket.customer.id, deliveryInstructions });
		if (result === "offline") {
			offlineTicketContext.update({
				...driverTicket,
				customer: { ...driverTicket.customer, deliveryInstructions },
			});
			alert.validation({ errors: [{ field: "Delivery Instructions", errors: ["You are offline. These instructions will be updated when you are back online."] }] });
		} else if (result.success) {
			offlineTicketContext.update({
				...driverTicket,
				customer: result.data,
			});
			alert.success("Delivery instructions updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
		setEditMode(false);
	};

	if (editMode) {
		return (
			<Box>
				<TextField
					style={{ marginTop: 5 }}
					size="small"
					label="Delivery Instructions"
					variant="outlined"
					fullWidth
					autoFocus
					multiline
					rows={3}
					helperText="Add useful info for future trips to this location"
					value={instructions}
					onChange={(e) => setInstructions(e.target.value)}
					onFocus={(e) => (e.target.selectionStart = e.target.selectionEnd = e.target.value.length)}
					onBlur={() => onSave()}
				/>
			</Box>
		);
	}

	return (
		<Box>
			<Button color="primary" variant="text" style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }} onClick={() => setEditMode(true)}>
				Delivery Instructions
			</Button>
			{driverTicket.customer.deliveryInstructions && <Typography style={{ whiteSpace: "pre-wrap", fontSize: "1.15rem" }}>{driverTicket.customer.deliveryInstructions}</Typography>}
			{!driverTicket.customer.deliveryInstructions && <Typography color="textSecondary">Add useful info for future <br/>trips to this location</Typography>}
			
		</Box>
	);
}

function ConfirmIncompleteDeliveryDialog(props: { quoteItems?: StopLineItem[]; onConfirm: () => void; onCancel: () => void }) {
	return (
		<Dialog open={props.quoteItems != null} onClose={props.onCancel}>
			<DialogTitle>Confirm Incomplete Delivery</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">
					You have not completed all of the items on this ticket. This will remove the following items from the ticket:
				</DialogContentText>
				<Grid container spacing={1}>
					{props.quoteItems?.map((l, i) => (
						<Grid item xs={12} key={i}>
							<Typography variant="overline" style={{ display: "block", fontSize: "1.15rem" }}>
								{l.productName}
								{l.type.name === "TankFill" && <> - {l.type.tankDescription}</>}
							</Typography>
						</Grid>
					))}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} color="secondary" variant="outlined">
					Cancel
				</Button>
				<Button onClick={props.onConfirm} color="primary" variant="contained">
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
