import { Money, SafeMoney } from "../../utility/Money";

export interface TransactionExportBatch {
    id: string;
    batchNumber: number;
    created: Date;
    transactionCount: number;
    downloadDate: Date | null;
    firstTransactionDate: Date;
    lastTransactionDate: Date;
}

type SerializedTransactionExportBatch = Omit<TransactionExportBatch, 'created' | 'downloadDate' | 'firstTransactionDate' | 'lastTransactionDate'> & {
    created: string;
    downloadDate: string | null;
    firstTransactionDate: string;
    lastTransactionDate: string;
};

export const deserializeTransactionExportBatch = (serialized: SerializedTransactionExportBatch): TransactionExportBatch => ({
    ...serialized,
    created: new Date(serialized.created),
    downloadDate: serialized.downloadDate ? new Date(serialized.downloadDate) : null,
    firstTransactionDate: new Date(serialized.firstTransactionDate),
    lastTransactionDate: new Date(serialized.lastTransactionDate),
});

export const deserializeTransactionExportBatches = (serialized: SerializedTransactionExportBatch[]): TransactionExportBatch[] => serialized.map(deserializeTransactionExportBatch);

interface TransactionExportGroupItem {
    date: Date;
    isCredit: boolean;
    ledgerAccountId: number;
    ledgerAccountName: string;
    ledgerAccountType: string;
    accountName: string;
    description: string;
    amount: SafeMoney;
}

type SerializedTransactionExportGroupItem = Omit<TransactionExportGroupItem, 'date' | 'amount'> & {
    date: string;
    amount: number;
};

const deserializeTransactionExportGroupItem = (serialized: SerializedTransactionExportGroupItem): TransactionExportGroupItem => ({
    ...serialized,
    date: new Date(serialized.date),
    amount: Money.fromDecimal(serialized.amount),
});
    

export interface TransactionExportGroup {
    transactionId: string;
    date: Date;
    type: string;
    customerCode: string;
    customerBalanceChange: SafeMoney;
    items: TransactionExportGroupItem[];
}

type SerializedTransactionExportGroup = Omit<TransactionExportGroup, 'date' | 'customerBalanceChange'| 'items'> & {
    date: string;
    customerBalanceChange: number;
    items: SerializedTransactionExportGroupItem[];
};

const deserializeTransactionExportGroup = (serialized: SerializedTransactionExportGroup): TransactionExportGroup => ({
    ...serialized,
    date: new Date(serialized.date),
    customerBalanceChange: Money.fromDecimal(serialized.customerBalanceChange),
    items: serialized.items.map(deserializeTransactionExportGroupItem),
});

export interface BatchedTransactionExport {
    groups: TransactionExportGroup[];
}

type SerializedBatchedTransactionExport = Omit<BatchedTransactionExport, 'groups'> & {
    groups: SerializedTransactionExportGroup[];
};

export const deserializeBatchedTransactionExport = (serialized: SerializedBatchedTransactionExport): BatchedTransactionExport => ({
    ...serialized,
    groups: serialized.groups.map(deserializeTransactionExportGroup),
});