import { Box, Button, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { CustomerTank } from "../../../services/customer/PropaneTankService";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { printDate } from "../../../utility/PrintDate";
import { PercentTextField } from "../../../components/PercentTextField";
import { useTenant } from "../../../providers/TenantProvider";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { ProductCategoryType } from "../../../entities/products/ProductCategoryType";
import { ProductLine } from "../../../entities/products/ProductLine";
import { ProductListing } from "../../../entities/products/ProductListing";
import { BoxIconButton } from "../../../components/BoxIconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useCreateTicketContext } from "./NewCreateTicketPage";
import { NewTicketTankFill } from "./TicketTankFill";
import { GridGrow } from "../../../components/GridGrow";
import { ExemptionType } from "../../../entities/accounting/TaxExemption";
import { Price } from "../../../components/Price";

export function TicketTankSection() {
	const customerContext = useCustomerDetailPage();
	const { profile } = customerContext;
	const tanks = profile.tanks;

	const { productCatalog } = useProductCatalog();
	const { productLines } = productCatalog;
	const propaneProductLine = productLines.find((pl) => pl.type === ProductCategoryType.Propane);

	if (propaneProductLine === undefined || propaneProductLine.availableListings.length === 0) {
		return null;
	}

	return (
		<>
			<Typography variant="h6" gutterBottom>
				Add Tank Fill:
			</Typography>
			<Grid container spacing={1}>
				{tanks.map((tank) => (
					<Grid item key={tank.id} sm={6} xs={12}>
						<TankCard tank={tank} propaneProductLine={propaneProductLine} />
					</Grid>
				))}
			</Grid>
		</>
	);
}

function estimateUllage(gasLevel: number | null, tank: CustomerTank, targetTankFillPercentage: number) {
	if (gasLevel === null || gasLevel === 0) {
		return null;
	}
	return tank.gallons * targetTankFillPercentage - tank.gallons * gasLevel;
}

function TankCard(props: { tank: CustomerTank; propaneProductLine: ProductLine }) {
	const { tank } = props;
	const customerContext = useCustomerDetailPage();
	const { taxExemptions } = customerContext.profile;
	const customerAddress = customerContext.profile.customer.address;

	const ticketContext = useCreateTicketContext();
	const { tankFills, setTankFills, setAddress } = ticketContext;

	const { tenant } = useTenant();
	const { targetTankFillPercentage } = tenant;

	const { productCatalog } = useProductCatalog();

	const [gasLevel, setGasLevel] = React.useState<number | null>(tank.lastReading ? tank.lastReading.percentRemaining /100 : null);
	const [estimatedUllage, setEstimatedUllage] = React.useState<number | null>(estimateUllage(gasLevel, tank, targetTankFillPercentage));

	const tankFill = tankFills.find((t) => t.tank.id === tank.id);

	function createTankFill(propaneListing: ProductListing) {
		const taxExemption = taxExemptions.find((te) => te.type === ExemptionType.Customer && te.valid) 
		?? taxExemptions.find((te) => te.type === ExemptionType.PropaneTank && te.tankId === tank.id && te.valid) ?? null;
		const taxRules = taxExemption ? [] : productCatalog.getTaxRules(propaneListing, tank.address);
		const tankFill = NewTicketTankFill.create(tank, propaneListing, taxRules, taxExemption);
		if(gasLevel){
			return tankFill.copy({ gasLevel, estimatedUllage });
		}
		return tankFill;
	}

	const onDelete = () => {
		setTankFills((tf) => tf.filter((t) => t.tank.id !== tank.id));
		const updatedTankFills = tankFills.filter((t) => t.tank.id !== tank.id);
		const address = updatedTankFills.length > 0 ? updatedTankFills[updatedTankFills.length - 1].tank.address : customerAddress;
		setAddress(address);
	};

	const onAdd = () => {
		const newTankFill = createTankFill(props.propaneProductLine.availableListings[0]);
		setTankFills((tf) => tf.concat([newTankFill]));
		setAddress(tank.address);
	};

	const onChangeGasLevel = (percent: number | null) => {
		const gasLevel = percent === null || percent === 0 ? null : percent;
		const estimatedUllage = estimateUllage(gasLevel, tank, targetTankFillPercentage);
		setGasLevel(gasLevel);
		setEstimatedUllage(estimatedUllage);
		setTankFills((tf) => tf.map((t) => (t.tank.id === tank.id ? t.copy({ gasLevel, estimatedUllage }) : t)));
	};
	const cellStyle = { padding: "4px 10px" };

	return (
		<Card variant="outlined">
			<Box m={1}>
				<Grid container spacing={2}>
					<Grid item>
						<Box mb={1}>
							<Grid container>
								{tankFill != null && (
									<Grid item>
										<Box mr={1}>
											<BoxIconButton color="secondary" onClick={onDelete}>
												<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
											</BoxIconButton>
										</Box>
									</Grid>
								)}
								<Grid item>
									<Typography>{tank.gallons} gal</Typography>
								</Grid>
							</Grid>
							{tank.description != null && <Typography variant="body2">{tank.description}</Typography>}
						</Box>

						{tankFill != null && (
							<PercentTextField
								variant="outlined"
								size="small"
								label="Gas Level"
								value={gasLevel}
								fullWidth
								style={{ maxWidth: 100 }}
								onPercentChange={onChangeGasLevel}
								helperText={estimatedUllage == null ? "" : `~${estimatedUllage.toFixed(2)} gal`}
							/>
						)}
						{tankFill == null && (
							<Button variant="outlined" size="small" onClick={onAdd} style={{ marginTop: 8 }}>
								Add
							</Button>
						)}
					</Grid>
					{tank.lastThreeFills.length > 0 && (
						<GridGrow>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>History</TableCell>
										<TableCell>Gal</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tank.lastThreeFills.map((fill, i) => (
										<TableRow key={i}>
											<TableCell style={cellStyle}>{printDate.shortDate(fill.timeOfFill)}</TableCell>
											<TableCell style={cellStyle}>{fill.amountFilled}</TableCell>
											<TableCell style={cellStyle}>{fill.price > 0 && <Price value={fill.price} decimalOptional/>  }</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</GridGrow>
					)}
				</Grid>
			</Box>
		</Card>
	);
}
