import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../../components/BackButton";
import { PageTitle } from "../../../../components/PageTitle";
import { OtodataIntegrationOption } from "../../../../entities/auth/IntegrationConfiguration";
import { useAlert } from "../../../../hooks/useAlert";
import { IntegrationService } from "../../../../services/auth/IntegrationService";

interface Props {
	option: OtodataIntegrationOption;
	onUpdate: (option: OtodataIntegrationOption) => void
}

export function ConfigureOtodataView(props: Props) {
	const { option } = props;
	const alert = useAlert();
	const [apiKey, setApiKey] = React.useState<string | null>(null);
	const [disabled, setDisabled] = React.useState(false);

	const submit = async () => {
		if (!apiKey || apiKey.trim() === "") {
			alert.validation({ errors: [{ field: "apiKey", errors: ["Api key is required"] }] });
			return;
		}
		setDisabled(true);
		const result = await IntegrationService.updateOtoData({ apiKey });
		if (result.success) {
			alert.success("Updated your Otodata integration!");
			props.onUpdate(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
		setDisabled(false);
	};


	return (
		<Grid container justify="center">
			<PageTitle title="Integrations - Otodata" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton  />
						</Grid>
						<Grid item>
							<CardHeader title="Update Otodata Integration" />
						</Grid>
					</Grid>
					<CardContent>
                        <Grid container justify="center">
                            <Grid item>
                                <img src={option.largeLogoUrl} alt="Otodata Logo" style={{maxWidth: "100%", maxHeight: 150, marginBottom: 15}} />
                            </Grid>
                        </Grid>
						<Typography variant="body1">{option.description}</Typography>
						<Typography variant="body1">
							Otodata is already integrated with your account. Please enter a new API key below to update your configuration.
						</Typography>
						<Grid container alignItems="center" style={{marginTop: 15}}>
							<Grid item lg={5} sm={6} xs={8}>
								<form
									onSubmit={(e) => {
										e.preventDefault();
										submit();
									}}
								>
									<TextField
										variant="outlined"
										label="API Key"
										value={apiKey}
										onChange={e => setApiKey(e.target.value)}
										autoFocus
										disabled={disabled}
										fullWidth
									/>
								</form>
							</Grid>
							<Grid md={3} sm={2} xs={4}>
								<Button
									color="primary"
									fullWidth
									variant="contained"
									disabled={disabled}
									style={{ height: 55, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
									onClick={submit}
								>
									Update
								</Button>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
