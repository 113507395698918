import { Grid, Card, CardHeader, Typography, Button, Switch, FormControlLabel, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { BackButton } from "../../../../components/BackButton";
import { PageTitle } from "../../../../components/PageTitle";
import { QuickBooksOnlineIntegrationOption } from "../../../../entities/auth/IntegrationConfiguration";
import { useAlert } from "../../../../hooks/useAlert";
import { IntegrationService } from "../../../../services/auth/IntegrationService";

interface Props {
	option: QuickBooksOnlineIntegrationOption;
	onUpdate: (option: QuickBooksOnlineIntegrationOption) => void
}

export function ConfigureQuickBooksOnlineView(props: Props) {
	const { option } = props;
	const alert = useAlert();

	const [authorizationUrl, setAuthorizationUrl] = React.useState<string>();

	useEffect(() => {
		async function getAuthorizationUrl() {
			const result = await IntegrationService.getQuickBooksAuthorizationUrl();
			if (result.success) {
				setAuthorizationUrl(result.data.authorizationUrl);
			} else {
				alert.serverError(result);
			}
		}
		getAuthorizationUrl();
	}, [alert]);

	const onConnect = () => {
		if (authorizationUrl) {
			window.location.href = authorizationUrl;
		}
	};

	const onChangeAutoSync = async (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const result = await IntegrationService.updateQuickBooksOnlineIntegration({ sync: checked });
		if (result.success) {
			alert.success(`QuickBooks Online sync has been ${checked ? "enabled" : "disabled"}`);
			props.onUpdate(result.data);
		} else {
			alert.serverError(result);
		}
	}

	return (
		<Grid container justify="center">
			<PageTitle title="Integrations - QuickBooks Online" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Configure QuickBooks Online" />
						</Grid>
					</Grid>

					<Grid container justify="center">
						<Grid item style={{ backgroundColor: "white", borderRadius: 5 }}>
							<img src={option.largeLogoUrl} alt="QuickBooks Logo" style={{ maxWidth: "100%", maxHeight: 150, marginBottom: 15 }} />
						</Grid>
					</Grid>
					<Typography variant="body1" style={{ padding: 15 }}>
						{option.description}
					</Typography>

					<Box m={2}>
						<Typography variant="h6">
							Settings
						</Typography>
						<FormControlLabel label="Auto Sync" control={<Switch color="primary" checked={option.sync} onChange={onChangeAutoSync} />} />
					</Box>

					<Typography variant="body1" style={{ padding: 15 }}>
						Your QuickBooks account is currently connected to your account. If you would like to connect a different QuickBooks account, please click the button
						below.
					</Typography>

					<Button
						color="secondary"
						fullWidth
						variant="contained"
						disabled={!authorizationUrl}
						style={{ height: 55, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
						onClick={onConnect}
					>
						{authorizationUrl && "Change QuickBooks Connection"}
						{!authorizationUrl && "Loading..."}
					</Button>
				</Card>
			</Grid>
		</Grid>
	);
}
