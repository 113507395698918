import { Dialog, DialogTitle, DialogContent, Grid, TextField, Typography, Button, MenuItem, DialogActions } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PercentTextField } from "../../../components/PercentTextField";
import { PriceTextField } from "../../../components/PriceTextField";
import { GeneralLedgerAccount, LedgerClassification } from "../../../entities/accounting/GeneralLedgerAccount";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { LedgerAccountService } from "../../../services/accounting/LedgerAccountsService";
import { TaxRuleService } from "../../../services/accounting/TaxRuleService";
import { useTaxRulePage } from "./TaxRulePage";

export function CreateTaxRuleDialog(props: { open: boolean; onClose: () => void }) {
	const { open, onClose } = props;
	const context = useTaxRulePage();
	const alert = useAlert();
	const history = useHistory();

	const [ledgerAccounts, setLedgerAccounts] = React.useState<GeneralLedgerAccount[]>();
	const liabilityAccounts = ledgerAccounts?.filter((x) => x.classification === LedgerClassification.Liability) ?? [];
	const hasLiabilityAccounts = liabilityAccounts.length > 0;

	const [name, setName] = React.useState("");
	const [ratePercent, setRatePercent] = React.useState<number | null>(null);
	const [fixedAmount, setFixedAmount] = React.useState<number | null>(null);
	const [taxAccountId, setTaxAccountId] = React.useState<number | "None">("None");

	const [disabled, setDisabled] = React.useState(false);

	const saveDisabled = disabled || !name || !hasLiabilityAccounts || taxAccountId === "None" || (ratePercent === 0 && fixedAmount === 0);

	useEffect(() => {
		async function loadAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setLedgerAccounts(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadAccounts();
	}, [alert]);

	const onSelectTaxAccountId = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as number | "None";
		setTaxAccountId(value);
	};

	const onSave = async () => {
		if (taxAccountId === "None") return;

		setDisabled(true);
		const result = await TaxRuleService.create({
			name,
			ratePercent: ratePercent === 0 ? null : ratePercent,
			fixedAmount: fixedAmount === 0 ? null : fixedAmount,
			taxAccountId,
		});
		setDisabled(false);
		if (result.success) {
			context.addTaxRule(result.data);
			alert.success("Tax rule created");
			props.onClose();
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Create tax rule</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<TextField
							label="Rule Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
							disabled={disabled}
							helperText="This will show up on detailed invoices"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						{!hasLiabilityAccounts && (
							<>
								<Typography variant="body2">No liability accounts found</Typography>
								<Button variant="contained" color="primary" onClick={() => history.push(routes.business.resolve.generalLedgerAccountsPage())}>
									Create liability account
								</Button>
							</>
						)}
						{hasLiabilityAccounts && (
							<TextField
								select
								label="Tax Account"
								fullWidth
								helperText="Liability account to record taxes"
								value={taxAccountId}
								onChange={onSelectTaxAccountId}
							>
								<MenuItem value="None">Select Tax Account</MenuItem>
								{liabilityAccounts.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.name}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					<Grid item sm={6} xs={12}>
						<PercentTextField
							label="Rate Percent"
							value={ratePercent}
							onPercentChange={setRatePercent}
							fullWidth
							disabled={disabled}
							helperText="Example: 5% sales tax"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<PriceTextField
							label="Fixed Amount"
							value={fixedAmount}
							onPriceChanged={p => setFixedAmount(p ?? 0)}
							fullWidth
							disabled={disabled}
							helperText="Example: $1.50 per item"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} disabled={disabled} variant="outlined" color="secondary">
					Cancel
				</Button>
				<Button onClick={onSave} disabled={saveDisabled} variant="contained" color="primary">
					Create Rule
				</Button>
			</DialogActions>
		</Dialog>
	);
}
