import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { LedgerClassification } from "../../../../entities/accounting/GeneralLedgerAccount";

interface Props {
    onChange: (classification: LedgerClassification) => void;
    value: LedgerClassification;
}

export function SelectLedgerClassification(props: Props) {
    const { onChange, value: classification } = props;

    const onChangeClassification = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(Number(e.target.value));
	};

	return (
		<TextField variant="outlined" label="Classification" value={classification} select onChange={onChangeClassification} fullWidth>
			<MenuItem value={LedgerClassification.Asset}>Asset</MenuItem>
			<MenuItem value={LedgerClassification.Liability}>Liability</MenuItem>
			<MenuItem value={LedgerClassification.Equity}>Equity</MenuItem>
			<MenuItem value={LedgerClassification.Revenue}>Revenue</MenuItem>
			<MenuItem value={LedgerClassification.Expense}>Expense</MenuItem>
		</TextField>
	);
}
