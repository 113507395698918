import React, { useEffect, useRef } from "react";
import { OnlineProfile } from "../../../entities/business/OnlineProfile";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import { OnlineProfileService } from "../../../services/business/OnlineProfileService";
import { useAlert } from "../../../hooks/useAlert";
import { CreateOnlineProfilePage } from "./CreateOnlineProfilePage";
import { GridGrow } from "../../../components/GridGrow";
import { useAppUser } from "../../../providers/AppUserProvider";

export function OnlineProfilePage() {
	const alert = useAlert();

	const [onlineProfile, setOnlineProfile] = React.useState<OnlineProfile | null>();

	useEffect(() => {
		async function loadOnlineProfile() {
			const result = await OnlineProfileService.get();
			console.log(result);
			if (result.success) setOnlineProfile(result.data);
			else alert.serverError(result);
		}
		loadOnlineProfile();
	}, [alert]);

	if (onlineProfile === undefined) return <OverlayLoadingScreen />;

	if (onlineProfile === null) return <CreateOnlineProfilePage onCreated={setOnlineProfile} />;

	return <OnlineProfileDetailPage onlineProfile={onlineProfile} onUpdated={(onlineProfile) => setOnlineProfile(onlineProfile)} />;
}

function OnlineProfileDetailPage(props: { onlineProfile: OnlineProfile; onUpdated: (onlineProfile: OnlineProfile) => void }) {
	const { onlineProfile } = props;

	return (
		<Grid container justify="center" spacing={3}>
			<Grid item xs={12} md={8}>
				<Card>
					<CardHeader title="Online Profile Details" />
					<CardContent>
						<Typography variant="body1" gutterBottom>
							Your Online Profile is the public face of your business. This is where your customers will go to view their invoices, make payments, and more. You
							can customize your online profile by uploading a logo, and change the email address that your email notifications are sent from.
						</Typography>
						<Typography variant="body1" gutterBottom>
							Email sent from: {onlineProfile.subdomain}@gasbill.online
						</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={8} lg={6}>
				<UploadLogoCard onlineProfile={onlineProfile} onUpdated={props.onUpdated} />
			</Grid>
			<Grid item xs={12} md={8} lg={6}>
				<Box mb={2}>
					<HostedPayOnlinePageCard onlineProfile={onlineProfile} onUpdated={props.onUpdated} />
				</Box>

				<InvoiceEmailCard onlineProfile={onlineProfile} onUpdated={props.onUpdated} />
			</Grid>
		</Grid>
	);
}

function HostedPayOnlinePageCard(props: { onlineProfile: OnlineProfile; onUpdated: (onlineProfile: OnlineProfile) => void }) {
	const { onlineProfile } = props;
	const alert = useAlert();

	const [loading, setLoading] = React.useState<boolean>(false);
	const [pageEnabled, setPageEnabled] = React.useState(onlineProfile.payOnlineSettings.pageEnabled);
	const [addPaymentInstructionsToInvoice, setAddPaymentInstructionsToInvoice] = React.useState(onlineProfile.payOnlineSettings.addPaymentInstructionsToInvoice);
	const [addPaymentInstructionsToStatement, setAddPaymentInstructionsToStatement] = React.useState(onlineProfile.payOnlineSettings.addPaymentInstructionsToStatement);

	const saveChanges = async () => {
		setLoading(true);
		const result = await OnlineProfileService.updatePayOnlineSettings({
			pageEnabled,
			addPaymentInstructionsToInvoice,
			addPaymentInstructionsToStatement,
		});
		setLoading(false);

		if (result.success) {
			alert.success("Pay Online Settings Updated");
			props.onUpdated(result.data);
		} else if (result.validation) alert.validation(result);
		else alert.serverError(result);
	};

	return (
		<Card>
			<CardHeader title="Hosted Pay Online Page" />
			<CardContent>
				<Typography variant="body1" gutterBottom>
					Your customers can pay their invoices or statement balances online using a credit card. It will be customized with your logo and branding, and payments
					will be automatically reconciled in Dispatch Pro.
				</Typography>
				<Typography variant="caption" gutterBottom>
					Note: Online payment notification email is sent to the email address specified in the Invoice Email settings.
				</Typography>
				<Typography variant="body1" gutterBottom>
					Your online payment page is located at:
					<br />
					{onlineProfile.subdomain}.gasbill.online/pay{!props.onlineProfile.payOnlineSettings.pageEnabled && <> (currently disabled)</>}
				</Typography>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControlLabel
							control={<Checkbox checked={pageEnabled} onChange={(event) => setPageEnabled(event.target.checked)} color="primary" disabled={loading} />}
							label="Enable Hosted Pay Online Page"
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={addPaymentInstructionsToInvoice}
									onChange={(event) => setAddPaymentInstructionsToInvoice(event.target.checked)}
									color="primary"
									disabled={loading}
								/>
							}
							label="Add payment instructions to your invoices."
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={addPaymentInstructionsToStatement}
									onChange={(event) => setAddPaymentInstructionsToStatement(event.target.checked)}
									color="primary"
									disabled={loading}
								/>
							}
							label="Add payment instructions to your statements."
						/>
					</Grid>
					<Grid item xs={12}>
						<Button variant="contained" color="primary" onClick={saveChanges} disabled={loading}>
							Save Changes
						</Button>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function InvoiceEmailCard(props: { onlineProfile: OnlineProfile; onUpdated: (onlineProfile: OnlineProfile) => void }) {
	const alert = useAlert();
	const user = useAppUser();
	const { onlineProfile } = props;
	const { invoiceEmailSettings: settings } = onlineProfile;

	const [loading, setLoading] = React.useState<boolean>(false);
	const [replyTo, setReplyTo] = React.useState<string>(settings.replyTo ?? "");
	const [bcc, setBcc] = React.useState<string>(settings.bcc ?? "");
	const [subject, setSubject] = React.useState<string>(settings.subject);
	const [header, setHeader] = React.useState<string>(settings.header);
	const [onlinePaymentNotificationEmail, setOnlinePaymentNotificationEmail] = React.useState(settings.onlinePaymentNotificationEmail ?? "");

	const onSubmit = async () => {
		setLoading(true);
		const result = await OnlineProfileService.updateInvoiceEmailSettings({ replyTo, bcc, subject, header, onlinePaymentNotificationEmail });
		setLoading(false);

		if (result.success) {
			alert.success("Invoice Email Settings Updated");
			props.onUpdated(result.data);
		} else if (result.validation) alert.validation(result);
		else alert.serverError(result);
	};

	const sendSample = async () => {
		setLoading(true);
		const result = await OnlineProfileService.sendSampleInvoiceEmail();
		setLoading(false);

		if (result.success) {
			alert.success(`Emailed a recent invoice to ${user.email}`);
		} else alert.serverError(result);
	};

	return (
		<Card>
			<CardHeader title="Invoice Email" />
			<CardContent>
				<Typography variant="body1" gutterBottom>
					Customize the invoice email address that your customers will see when they receive an invoice from you.
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextField
							label="Reply To"
							variant="outlined"
							fullWidth
							disabled={loading}
							value={replyTo}
							onChange={(e) => setReplyTo(e.target.value)}
							helperText="The email address that any replies to your invoice emails will be sent to."
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="BCC"
							variant="outlined"
							fullWidth
							disabled={loading}
							value={bcc}
							onChange={(e) => setBcc(e.target.value)}
							helperText="Send a copy of all invoice emails to this email address."
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Subject"
							variant="outlined"
							fullWidth
							required
							disabled={loading}
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
							helperText="The subject line of your invoice emails."
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Header"
							variant="outlined"
							fullWidth
							disabled={loading}
							value={header}
							onChange={(e) => setHeader(e.target.value)}
							helperText="Message displayed at the top of your invoice emails."
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							label="Online Payment Notification Email"
							variant="outlined"
							fullWidth
							disabled={loading}
							value={onlinePaymentNotificationEmail}
							onChange={(e) => setOnlinePaymentNotificationEmail(e.target.value)}
							helperText="Send a copy of all online payment notifications to this email address."
						/>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item>
								<Button variant="contained" color="primary" onClick={onSubmit} disabled={loading}>
									Save Changes
								</Button>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button variant="outlined" color="primary" onClick={sendSample} disabled={loading}>
									See Sample Invoice
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function UploadLogoCard(props: { onlineProfile: OnlineProfile; onUpdated: (onlineProfile: OnlineProfile) => void }) {
	const alert = useAlert();

	const { onlineProfile } = props;

	const [loading, setLoading] = React.useState<boolean>(false);

	const fileInput = useRef<HTMLInputElement>(null);

	const handleUploadClick = () => fileInput.current?.click();

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null;
		if (!file) return;

		console.log(file);
		setLoading(true);
		const result = await OnlineProfileService.uploadLogo(file);
		setLoading(false);

		if (result.success) {
			alert.success("Logo Uploaded");
			props.onUpdated(result.data);
		} else if (result.validation) alert.validation(result);
		else alert.serverError(result);
	};

	return (
		<Card>
			<CardHeader title="Logo" />
			<CardContent>
				<Typography variant="body1" gutterBottom>
					Upload a logo to be displayed on your invoices and email notifications. Must be 16:9 aspect ratio.
				</Typography>
				{onlineProfile.logo16X9Url && <img src={onlineProfile.logo16X9Url} alt="Logo" style={{ maxWidth: "100%" }} />}
				{!onlineProfile.logo16X9Url && (
					<Typography variant="body1" gutterBottom>
						No logo uploaded.
					</Typography>
				)}
				<div>
					<input type="file" ref={fileInput} style={{ display: "none" }} accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
					<Button variant="contained" color="primary" onClick={handleUploadClick} disabled={loading}>
						Upload Logo
					</Button>
				</div>
			</CardContent>
		</Card>
	);
}
