import React, { useEffect, useState } from "react";
import { JwtUser } from "../entities/auth/JwtUser";
import { Tenant } from "../entities/auth/Tenant";
import { useCachedOfflineState } from "../hooks/useCachedOfflineState";
import { AccountService } from "../services/business/AccountService";
import { createServerError, ServerError } from "../services/server/WebClient";
import { AppDataContext, AppDataLoadStatus } from "./AppDataProviders";
import { useAppUser } from "./AppUserProvider";
import { useOfflineStatus } from "./OfflineStatusProvider";

interface TenantContext extends AppDataContext {
	tenant: Tenant;
	update: (tenant: Tenant) => void;
}

const TenantReactContext = React.createContext<TenantContext>({} as TenantContext);
TenantReactContext.displayName = "TenantAppData";

export function useTenant() {
	return React.useContext(TenantReactContext);
}

const defaultTenant = (user: JwtUser): Tenant => ({
	id: user.tenantId,
	name: "My Business",
	timezone: "America/Denver",
	officeAddress: null,
	mailingAddress: null,
	phoneNumber: null,
	targetTankFillPercentage: 75,
	surchargeSettings: null,
	autoApplyUnallocatedPayments: true,
	invoicePreferences: {
		headerText: null,
		footerText: null,
		pastDueNotice: false,
		showPrice: true,
		useTicketNumberForInvoiceCode: false,
		addReturnAddressToFooter: false,
		includePageNumbers: false,
		version: 1,
	},
	statementPreferences: {
		memo: null,
	},
});

export function TenantProvider(props: React.PropsWithChildren<{}>) {
	const isOffline = useOfflineStatus();
	const user = useAppUser();

	const [tenant, setTenant] = useCachedOfflineState<Tenant, Tenant>(
		`offline-tenant-${user.tenantId}`,
		defaultTenant(user),
		(t) => t,
		(t) => t
	);
	const [loadStatus, setLoadStatus] = useState<AppDataLoadStatus>(isOffline ? "offline" : "loading");
	const [serverError, setServerError] = useState<ServerError | null>(null);

	// Only attempt load on first mount
	useEffect(() => {
		if (!isOffline) {
			AccountService.getUserTenant()
				.then((result) => {
					if (result.success) {
						setTenant(result.data);
						setLoadStatus("ready");
					} else {
						setServerError(result);
						setLoadStatus("error");
					}
				})
				.catch((e: string) => {
					console.error(e);
					setServerError(createServerError(e, 999));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TenantReactContext.Provider
			value={{
				tenant,
				update: setTenant,
				loadStatus,
				serverError,
			}}
		>
			{props.children}
		</TenantReactContext.Provider>
	);
}
