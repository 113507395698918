import { Card, Grid, CardHeader, CardContent, ListItem, ListItemText, ListItemSecondaryAction, List } from "@material-ui/core";
import React from "react";
import { GridGrow } from "../../../components/GridGrow";
import { HorizontalCentered } from "../../../components/HorizontalCentered";
import { KpiLoadingCard } from "./KpiLoadingCard";
import CheckIcon from "@material-ui/icons/Check";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { LinkButton } from "../../../components/LinkButton";
import { TransactionExportBatchService } from "../../../services/accounting/TransactionExportBatchService";

export function PendingTransactionExportBatchCard() {
	const alert = useAlert();

	const [stats, setStats] = React.useState<{count: number, lastBatchDate: Date | null}>();

	React.useEffect(() => {
		async function loadUnBatchedChecks() {
			const result = await TransactionExportBatchService.getUnBatchedExportsStats();
			if (result.success) {
				setStats(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadUnBatchedChecks();
	}, [alert]);

	if (!stats) return <KpiLoadingCard title="Pending Transaction Batch" />;

	if (stats.count === 0) return <NoPendingTransactionsCard />;

	return (
		<Card style={{ height: "100%" }}>
			<Grid container direction="column" justify="center" style={{ height: "100%" }}>
				<Grid item>
					<Grid container alignItems="center">
						<Grid item>
							<CardHeader title="Pending Transaction Batch" />
						</Grid>
						<GridGrow />
						<Grid item>
							<LinkButton variant="outlined" to={routes.app.resolve.transactionExportBatchPage()} style={{marginRight: 10, marginLeft: 10}}>
								See All Batches
							</LinkButton>
						</Grid>
					</Grid>
				</Grid>
				<GridGrow>
					<Grid container direction="column" justify="center" style={{ height: "100%" }}>
						<Grid item>
							<CardContent>
								<List component="nav">
									<ListItem divider>
										<ListItemText primary="Number of Pending Transactions" />
										<ListItemSecondaryAction>
											<ListItemText primary={stats.count} />
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem divider>
										<ListItemText primary="Last Export" />
										<ListItemSecondaryAction>
											<ListItemText primary={stats.lastBatchDate ? stats.lastBatchDate.toLocaleDateString() : "Never"} />
										</ListItemSecondaryAction>
									</ListItem>
								</List>
                                <LinkButton variant="outlined" color="primary" to={routes.app.resolve.transactionExportBatchCreatePage()} style={{marginTop: 10}}>
                                    Create Batch
                                </LinkButton>
							</CardContent>
						</Grid>
					</Grid>
				</GridGrow>
			</Grid>
		</Card>
	);
}

function NoPendingTransactionsCard() {
	return (
		<Card style={{ height: "100%" }}>
			<Grid container direction="column" style={{ height: "100%" }}>
				<Grid item>
                <Grid container alignItems="center">
						<Grid item>
							<CardHeader title="No Pending Transaction Batch" />
						</Grid>
						<GridGrow />
						<Grid item>
							<LinkButton variant="outlined" to={routes.app.resolve.transactionExportBatchPage()} style={{marginRight: 10}}>
								See All Batches
							</LinkButton>
						</Grid>
					</Grid>
				</Grid>
				<GridGrow>
					<Grid container direction="column" justify="center" style={{ height: "100%" }}>
						<Grid item>
							<HorizontalCentered>
								<CheckIcon style={{ fontSize: "5rem" }} />
							</HorizontalCentered>
						</Grid>
					</Grid>
				</GridGrow>
			</Grid>
		</Card>
	);
}
