import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import { Customer } from "../../../entities/customer/Customer";
import { useAlert } from "../../../hooks/useAlert";
import { TransactionService } from "../../../services/accounting/TransactionService";

interface Props {
	open: boolean;
	onClose: () => void;
	customer: Customer;
	paymentTransactionId: string;
}

export function EmailReceiptDialog(props: Props) {
    const alert = useAlert();
    const [email, setEmail] = React.useState(`${props.customer.email ?? ""}${props.customer.emailSecondary ? `;${props.customer.emailSecondary}` : ""}`);
    const [disabled, setDisabled] = React.useState(false);

    const sendReceipt = async (e : React.FormEvent) => {
        e.preventDefault();
        setDisabled(true);
        const result = await TransactionService.emailReceipt({ paymentTransactionId: props.paymentTransactionId, email });
        setDisabled(false);
        if(result.success) {
            alert.success("Receipt emailed");
            props.onClose();
        } else if(result.validation){
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Receipt</DialogTitle>
			<form onSubmit={sendReceipt}>
            <DialogContent>
				<DialogContentText>Would you like to email a receipt to the customer?</DialogContentText>
                <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth variant="outlined" />
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="secondary" variant="outlined" disabled={disabled}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={disabled || email.trim() === ""}>
                    Send Email
                </Button>
			</DialogActions>
            </form>
		</Dialog>
	);
}
