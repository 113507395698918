import { Money, SafeMoney } from "../../utility/Money";
import { GeocodedAddress } from "../customer/GeocodedAddress";
import { TaxCriteria, TaxCriteriaType, printTaxCriteria } from "./TaxCriteria";

export interface TaxMatchGroup {
	id: string;
	criteria: TaxCriteria[];
}

export interface TaxRule {
	id: number;
	name: string;
	ratePercent: number | null;
	fixedAmount: number | null;
	matchGroups: TaxMatchGroup[];
	exclusions: TaxCriteria[];
	assignedProductListings: { listingId: string; productLineId: string; name: string }[];
	assignedProductLines: { productLineId: string; name: string }[];
	taxAccountId: number;
	taxAccountName: string;
}

export const matchesTaxRule = (taxRule: TaxRule, address: GeocodedAddress) =>
	taxRule.matchGroups.some((group) =>
		group.criteria.every((criteria) => matchesTaxCriteria(criteria, address) && !taxRule.exclusions.some((exclusion) => matchesTaxCriteria(exclusion, address)))
	);

export const calculateTax = (taxRule: { ratePercent: number | null; fixedAmount: number | null }, subtotal: SafeMoney) => {
	let tax = Money.zero;
	// Round subtotal to 2 decimal places before calculating tax
	const basis = subtotal.convertPrecision(2, "HALF_AWAY_FROM_ZERO");
	if (taxRule.ratePercent) {
		tax = tax.add(basis.multiply(taxRule.ratePercent, "HALF_AWAY_FROM_ZERO"));
	}
	if (taxRule.fixedAmount) {
		tax = tax.add(Money.fromDecimal(taxRule.fixedAmount));
	}
	return tax;
};

export const calculateTaxes = (taxRules: { ratePercent: number | null; fixedAmount: number | null }[], subtotal: SafeMoney) => {
	if (taxRules.length === 0) {
		return Money.zero;
	}
	return taxRules.reduce((sum, taxRule) => {
		const tax = calculateTax(taxRule, subtotal);
		return sum.add(tax);
	}, Money.zero);
};

const matchesTaxCriteria = (criteria: TaxCriteria, address: GeocodedAddress) => {
	if (criteria.type === TaxCriteriaType.StateMatch) {
		return criteria.state.trim().toUpperCase() === address.state.trim().toUpperCase();
	}
	if (criteria.type === TaxCriteriaType.ZipMatch) {
		return criteria.zip.trim() === address.postalCode.trim();
	}
	if (criteria.type === TaxCriteriaType.CountyMatch) {
		return address.county.toLowerCase().includes(criteria.county.toLowerCase());
	}
	if (criteria.type === TaxCriteriaType.CityMatch) {
		return criteria.city.toLowerCase() === address.city.toLowerCase();
	}
	return false;
};

export const printTaxRate = (taxRule: { ratePercent: number | null; fixedAmount: number | null }) => {
	let amount = "";
	if (taxRule.ratePercent) {
		amount = `${(taxRule.ratePercent * 100).toFixed(2)}%`;
	}
	if (taxRule.fixedAmount) {
		if (amount) {
			amount += " + ";
		}
		amount += `$${taxRule.fixedAmount.toFixed(2)}`;
	}
	return amount;
};

export const printCriteriaSummary = (taxRule: TaxRule) => {
	if (taxRule.matchGroups.length === 0) return "No criteria";

	let description = "";

	for (let i = 0; i < taxRule.matchGroups.length; i++) {
		if (i > 0) description += " OR ";
		const group = taxRule.matchGroups[i];
		description += printTaxMatchGroup(group);
	}
	if (taxRule.exclusions.length > 0) {
		description += ".";
		description += " Excludes ";
		description += taxRule.exclusions.map(printTaxCriteria).join(" and ");
	}
	return description;
};

export const printTaxMatchGroup = (group: TaxMatchGroup) => group.criteria.map(printTaxCriteria).join(" and ");
