import React, { useEffect, useState } from "react";
import { Card, CardHeader, Grid, Tab, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Area, CartesianGrid, Tooltip, XAxis, YAxis, AreaChart, ResponsiveContainer } from "recharts";
import { KeyboardDatePicker } from "@material-ui/pickers";
import CardContent from "@material-ui/core/CardContent";
import { Tabs } from "@material-ui/core";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { subDays, startOfToday, endOfToday } from "date-fns";
import { useServerErrorAlert } from "../../../hooks/useServerErrorAlert";
import { TankLevels } from "../../../entities/routing/OtodataTankMonitor";
import { TankMonitorService } from "../../../services/routing/TankMonitorService";

interface Logs {
	date: string;
	dateDate: Date;
	gasLevel: number;
	temperature: number;
	batteryLevel: number;
}

export function TankLevelsCard(props: { deviceId: number }) {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const setServerError = useServerErrorAlert();

	const { deviceId } = props;

	const [tankLevels, setTankLevels] = useState<TankLevels | undefined>();
	const [logs, setLogs] = useState<Logs[]>([]);
	const [startDate, setStartDate] = useState<Date>(subDays(startOfToday(), 7));
	const [endDate, setEndDate] = useState<Date>(endOfToday());

	const [tabIndex, setTabIndex] = useState(0);

	useEffect(() => {
		async function loadTankLevels(deviceId: number) {
			setTankLevels(undefined);
			const response = await TankMonitorService.getOtodataTankLevels({ id: deviceId, startDate, endDate });

			if (response.success) {
				setTankLevels(response.data);
				const logs: Logs[] = response.data.logs.map((l) => ({
					date: l.logDate.toLocaleDateString(),
					dateDate: l.logDate,
					batteryLevel: l.batteryLevel,
					gasLevel: l.levelPercent,
					temperature: l.temperature,
				}));
				setLogs(logs);
			} else {
				setServerError(response);
			}
		}

		loadTankLevels(deviceId);
	}, [deviceId, startDate, endDate, setServerError]);

	return (
		<Card>
			<CardHeader
				style={{ paddingBottom: 0 }}
				title={
					<Grid container spacing={2}>
						<Grid item md={4} xs={12}>
							<Typography variant="h5">Tank Levels</Typography>
						</Grid>
						<Grid item md={8} xs={12}>
							<Grid container justify={mobile ? undefined : "flex-end"} alignItems={mobile ? undefined : "flex-end"}>
								<Grid item>
									<Grid container spacing={1} justify={mobile ? undefined : "flex-end"} alignItems={mobile ? undefined : "flex-end"}>
										<Grid item>
											<KeyboardDatePicker
												disableToolbar
												variant="inline"
												format="MM/dd/yyyy"
												label="Start Date"
												inputVariant="outlined"
												fullWidth
												value={startDate}
												onChange={(date) => {
													if (date) {
														setStartDate(date);
													}
												}}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
											/>
										</Grid>
										<Grid item>
											<KeyboardDatePicker
												disableToolbar
												variant="inline"
												format="MM/dd/yyyy"
												label="End Date"
												inputVariant="outlined"
												fullWidth
												value={endDate}
												onChange={(date) => {
													if (date) {
														setEndDate(date);
													}
												}}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item md={2} xs={12}>
						<Tabs orientation={mobile ? "horizontal" : "vertical"} value={tabIndex} onChange={(_, v: number) => setTabIndex(v)}>
							<Tab label="Gas" />
							<Tab label="Temp" />
							<Tab label="Battery" />
						</Tabs>
					</Grid>
					<Grid item md={10} xs={12}>
						{tankLevels && (
							<>
								{tabIndex === 0 && <GasLevels logs={logs} />}
								{tabIndex === 1 && <TempLevels logs={logs} />}
								{tabIndex === 2 && <BatteryLevels logs={logs} />}
							</>
						)}
						{!tankLevels && <CenteredLoadingSpinner />}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function GasLevels(props: { logs: Logs[] }) {
	const { logs } = props;
	const theme = useTheme();
	const color = theme.palette.secondary.main;
	return (
		<ResponsiveContainer width="100%" height={250}>
			<AreaChart data={logs} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor={color} stopOpacity={0.8} />
						<stop offset="95%" stopColor={color} stopOpacity={0.2} />
					</linearGradient>
				</defs>
				<XAxis dataKey="date" />
				<YAxis domain={[0, 100]} tickFormatter={(t) => `${t}%`} />
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip />
				<Area type="monotone" dataKey="gasLevel" stroke={color} fillOpacity={1} fill="url(#colorUv)" />
			</AreaChart>
		</ResponsiveContainer>
	);
}

function TempLevels(props: { logs: Logs[] }) {
	const { logs } = props;
	const theme = useTheme();
	const color = theme.palette.secondary.main;
	return (
		<ResponsiveContainer width="100%" height={250}>
			<AreaChart data={logs} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor={color} stopOpacity={0.8} />
						<stop offset="95%" stopColor={color} stopOpacity={0.2} />
					</linearGradient>
				</defs>
				<XAxis dataKey="date" />
				<YAxis tickFormatter={(t) => `${t} F`} domain={["dataMin - 10", "dataMax + 10"]} allowDataOverflow={true} />
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip />
				<Area type="monotone" dataKey="temperature" stroke={color} fillOpacity={1} fill="url(#colorUv)" />
			</AreaChart>
		</ResponsiveContainer>
	);
}

function BatteryLevels(props: { logs: Logs[] }) {
	const { logs } = props;
	const theme = useTheme();
	const color = theme.palette.secondary.main;
	return (
		<ResponsiveContainer width="100%" height={250}>
			<AreaChart data={logs} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor={color} stopOpacity={0.8} />
						<stop offset="95%" stopColor={color} stopOpacity={0.2} />
					</linearGradient>
				</defs>
				<XAxis dataKey="date" />
				<YAxis tickFormatter={(t) => `${t} V`} type="number" domain={[0, "dataMax + 0.8"]} allowDataOverflow={true} />
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip />
				<Area type="monotone" dataKey="batteryLevel" stroke={color} fillOpacity={1} fill="url(#colorUv)" />
			</AreaChart>
		</ResponsiveContainer>
	);
}
