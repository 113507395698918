import { Modify } from "../../utility/modifyType";

export enum MonitorType {
    Otodata = 1
}

interface TankMonitorBase {
    id: string;
    tenantId: string;
    tankId: string;
    externalId: string;
    lastReportedPercentFull: number | null;
    lastReportedAt: Date | null;
    alert: string | null;
    createdAt: Date;
    updatedAt: Date;
}

interface DispatchProOtodataMonitor extends TankMonitorBase {
    type: MonitorType.Otodata;
    brand: "Otodata";
    name: string | null;
    model: string | null;
    status: string | null;
}

export type TankMonitor = DispatchProOtodataMonitor;

export type SerializedTankMonitor = Modify<TankMonitor, {
    lastReportedAt: string | null;
    createdAt: string;
    updatedAt: string;
}>;

export const deserializeTankMonitor = (model: SerializedTankMonitor): TankMonitor => {
    return {
        ...model,
        lastReportedAt: model.lastReportedAt ? new Date(model.lastReportedAt) : null,
        createdAt: new Date(model.createdAt),
        updatedAt: new Date(model.updatedAt)
    };
}

export const deserializeTankMonitors = (models: SerializedTankMonitor[]): TankMonitor[] => models.map(deserializeTankMonitor);

export interface CustomerTankMonitor {
    id: string;
    tankId: string;
    tankLocation: string | null;
    tankSerialNumber: string | null;
    gallonCapacity: number;
    customerId: string;
    customerName: string;
    externalId: string;
    type: MonitorType;
    lastReportedPercentFull: number | null;
    lastReportedAt: Date | null;
    alert: string | null;
}

type SerializedCustomerTankMonitor = Modify<CustomerTankMonitor, {
    lastReportedAt: string | null;
}>;

export const deserializeCustomerTankMonitor = (model: SerializedCustomerTankMonitor): CustomerTankMonitor => {
    return {
        ...model,
        lastReportedAt: model.lastReportedAt ? new Date(model.lastReportedAt) : null
    };
}

export const deserializeCustomerTankMonitors = (models: SerializedCustomerTankMonitor[]): CustomerTankMonitor[] => models.map(deserializeCustomerTankMonitor);
