import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { User } from "../../../entities/auth/User";
import { routes } from "../../../routes";
import { BusinessService } from "../../../services/business/BusinessService";
import { NotFoundResult, ServerError } from "../../../services/server/WebClient";
import { UserDetailView } from "./UserDetailView";
import { UserListView } from "./UserListView";

export function UserPage(){
    const [users, setUsers] = React.useState<User[]>();
    const [serverError, setServerError] = useState<ServerError | NotFoundResult>();

    useEffect(() => {
		async function fetchUsers() {
			const result = await BusinessService.getUsers();
			if (result.success) {
				setUsers(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchUsers();
	}, []);

    if(serverError)
        return <ServerErrorView serverError={serverError} />;

    if(!users)
        return <OverlayLoadingScreen />;

    return <UserPageProvider users={users} setUsers={setUsers}>
        <UserPageRouter />
    </UserPageProvider>
}

interface UserPageContext {
    users: User[];
    selectedUser: User | undefined;
    addUser: (user: User) => void;
    updateUser: (user: User) => void;
    deleteUser: (user: User) => void;
}

const UserPageReactContext = React.createContext<UserPageContext>({} as UserPageContext);
export const useUserPage = () => React.useContext(UserPageReactContext);

function UserPageProvider(props: PropsWithChildren<{users: User[], setUsers: (users: User[]) => void}>){
    const {users, setUsers} = props;

    const params = useParams<{ userId: string | undefined }>();
    const {userId} = params;

    const selectedUser = useMemo(() => users.find(u => u.id === userId), [users, userId]);

    const addUser = (user: User) => setUsers([...users, user]);
    const updateUser = (user: User) => setUsers(users.map(u => u.id === user.id ? user : u));
    const deleteUser = (user: User) => setUsers(users.filter(u => u.id !== user.id));
    

    return <UserPageReactContext.Provider value={{users, selectedUser, addUser, updateUser, deleteUser}}>
        {props.children}
    </UserPageReactContext.Provider>
}

function UserPageRouter(){
    const {selectedUser } = useUserPage()
	return (
		<Switch>
			<Route exact path={routes.business.userPage}>
				<PageTitle title="Users" />
				<UserListView />
			</Route>
			{
				selectedUser &&
				<Route exact path={routes.business.userDetailPage}>
					<PageTitle title={`${selectedUser.firstName} ${selectedUser.lastName}`} />
					<UserDetailView user={selectedUser} />
				</Route>
			}
		</Switch>
	);
}