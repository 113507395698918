import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

type Props = ButtonProps & {
	to: string;
    newTab?: boolean;
};

export function LinkButton(props: React.PropsWithChildren<Props>) {
	const { to, children, newTab, ...buttonProps } = props;

    const target = newTab ? "_blank" : undefined;

    if (target) {
        return (
            <a href={to} target={target} style={{ all: "unset"}}>
                <Button {...buttonProps}>{children}</Button>
            </a>
        );
    }
        
	return (
		<Link to={to} style={{ all: "unset"}}>
			<Button {...buttonProps}>{children}</Button>
		</Link>
	);
}
