import { Link, LinkProps } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface ClientLinkProps {
	to: string;
	style?: React.CSSProperties;
	color?: "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
	disabled?: boolean;
	newTab?: boolean;
	underline?: "none" | "hover" | "always";
}

export function ClientLink(props: React.PropsWithChildren<ClientLinkProps>) {
	const { newTab, to, disabled } = props;

	const linkProps: LinkProps & { to: string } = {
		color: disabled ? "textSecondary" : props.color ?? "textPrimary",
		style: { cursor: disabled ? "no-drop" : "pointer", ...props.style },
		underline: props.underline ?? "always",
		target: newTab ? "_blank" : undefined,
		to: to
	};


    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (disabled) {
            e.preventDefault();
        }
    };

	return (
		<Link {...linkProps} component={RouterLink} onClick={onClick}>
			{props.children}
		</Link>
	);
}
