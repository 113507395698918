import React from "react";
import { startOfDay, endOfDay, differenceInDays, startOfToday, endOfToday } from "date-fns";
import { RedText } from "../../../components/RedText";
import { TimeBetween } from "../../../components/TimeBetween";
import { DriverTicket } from "../../../entities/routing/DriverTicket";

export function TicketStatus(props: { ticket: DriverTicket }) {
	const { ticket } = props;
	const today = startOfToday();

    if(ticket.timeOfCompletion && ticket.driverName) return <>Completed by {ticket.driverName}</>;
    if(ticket.timeOfCompletion) return <>Complete</>;

	if (ticket.timeOfDelivery) {
		return <>Delivered {ticket.driverName ? `by ${ticket.driverName}` : ""}</>;
	}

	if (ticket.driverName) {
		return <>Assigned to {ticket.driverName}</>;
	}

	if (ticket.dueBy) {
		const endOfDate = endOfDay(ticket.dueBy);
		if(endOfDate === endOfToday()) return <RedText>Due today</RedText>;

		if (today < endOfDate) return <>Due by {ticket.dueBy.toLocaleDateString()}</>;

		return (
			<RedText>
				<TimeBetween start={today} end={endOfDate} /> overdue
			</RedText>
		);
	}

	const daysSinceCreated = differenceInDays(today, startOfDay(ticket.created));
	if (daysSinceCreated >= 1) {
		return <>Added {daysSinceCreated} day{daysSinceCreated > 1 && "s"} ago</>
	}

	return <>Added today</>;
}