interface CustomerNoteBase {
    id: string;
    customerId: string;
    note: string;
    created: Date;
    createdBy: string;
}

export enum CustomerNoteType {
    Account = 1,
    Tank = 2,
    Ticket = 3
}

export interface CustomerAccountNote extends CustomerNoteBase {
    type: CustomerNoteType.Account;
}

export interface TankNote extends CustomerNoteBase {
    type: CustomerNoteType.Tank;
    tankId: string;
}

export interface TicketNote extends CustomerNoteBase {
    type: CustomerNoteType.Ticket;
    ticketId: string;
}

export type CustomerNote = CustomerAccountNote | TankNote | TicketNote;

type SerializedCustomerNoteBase = Omit<CustomerNoteBase, 'created'> & {
    created: string;
};

type SerializedCustomerAccountNote = SerializedCustomerNoteBase & {
    type: CustomerNoteType.Account;
};


type SerializedTankNote = SerializedCustomerNoteBase & {
    type: CustomerNoteType.Tank;
    tankId: string;
};

type SerializedTicketNote = SerializedCustomerNoteBase & {
    type: CustomerNoteType.Ticket;
    ticketId: string;
};

type SerializedCustomerNote = SerializedCustomerAccountNote | SerializedTankNote | SerializedTicketNote;

export function deserializeCustomerAccountNote (note: SerializedCustomerAccountNote): CustomerAccountNote {
    return {
        ...note,
        created: new Date(note.created)
    };
}

export function deserializeCustomerNote (note: SerializedCustomerNote): CustomerNote {
    return {
        ...note,
        created: new Date(note.created)
    };
}

export function deserializeCustomerNotes (notes: SerializedCustomerNote[]): CustomerNote[] {
    return notes.map(deserializeCustomerNote);
}

