import { Button, Card, CardContent, CardHeader, Grid, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { BackButton } from "../../components/BackButton";
import { PriceTextField } from "../../components/PriceTextField";
import { InvoiceReport, ReportService } from "../../services/report/ReportService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { NumberTextField } from "../../components/NumberTextField";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { ClientLink } from "../../components/ClientLink";
import { Price } from "../../components/Price";
import { GridGrow } from "../../components/GridGrow";

export function InvoiceReportPage() {
	const alert = useAlert();

	const [minBalance, setMinBalance] = useState<number | null>(null);
	const [minDate, setMinDate] = useState<Date | null>(null);
	const [maxDate, setMaxDate] = useState<Date | null>(null);
	const [minDaysOverdue, setMinDaysOverdue] = useState<number | null>(null);
	const [status, setStatus] = useState<"All" | "Open" | "Closed">("Open");

	const [disabled, setDisabled] = useState(false);
	const [report, setReport] = useState<InvoiceReport>();

	const downloadReport = async () => {
		setDisabled(true);
		const report = await ReportService.invoiceReport({
			minBalance,
			minDate,
			maxDate,
			minDaysOverdue,
			status,
		});
		setDisabled(false);
		if (report.success) {
			setReport(report.data);
		} else {
			alert.serverError(report);
		}
	};

	const downloadInvoicePdfs = async () => {
		if (!report) return;
        setDisabled(true);
		const result = await ReportService.bulkDownloadInvoicePdf({
			invoiceIds: report.items.map((i) => i.invoiceId),
		});
        setDisabled(false);
		if (result.error) {
			alert.serverError(result);
		}
	};

	return (
		<Grid container justify="center" spacing={2}>
			<PageTitle title="Invoice Report" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Invoice Report" subheader="Report on invoices and bulk download." />
						</Grid>
					</Grid>
					<CardContent>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								downloadReport();
							}}
						>
							<Grid container spacing={2}>
								<Grid item sm={4} xs={12}>
									<PriceTextField
										label="Minimum Balance"
										fullWidth
										onPriceChanged={(p) => setMinBalance(p)}
										value={minBalance}
										variant="outlined"
										disabled={disabled}
									/>
								</Grid>
								<Grid item sm={4} xs={12}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										label="Min Date"
										inputVariant="outlined"
										disableFuture
										fullWidth
										helperText="Only invoices issued on or after this date will be included"
										value={minDate}
										disabled={disabled}
										onChange={setMinDate}
									/>
								</Grid>
								<Grid item sm={4} xs={12}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										label="Max Date"
										inputVariant="outlined"
										disableFuture
										fullWidth
										helperText="Only invoices issued on or before this date will be included"
										value={maxDate}
										disabled={disabled}
										onChange={setMaxDate}
									/>
								</Grid>
								<Grid item sm={4} xs={12}>
									<NumberTextField
										label="Minimum Days Overdue"
										fullWidth
										variant="outlined"
										value={minDaysOverdue}
										disabled={disabled}
										onNumberChange={setMinDaysOverdue}
										helperText="Only invoices that are at least many days overdue will be included"
									/>
								</Grid>
								<Grid item sm={4} xs={12}>
									<TextField
										select
										fullWidth
										label="Status"
										variant="outlined"
										value={status}
										disabled={disabled}
										onChange={(e) => {
											if (e.target.value === "All" || e.target.value === "Open" || e.target.value === "Closed") {
												setStatus(e.target.value);
											}
										}}
									>
										<MenuItem value={"All"}>All</MenuItem>
										<MenuItem value={"Open"}>Open</MenuItem>
										<MenuItem value={"Closed"}>Closed</MenuItem>
									</TextField>
								</Grid>
								<Grid item xs={12}>
									<Grid container>
										<Grid item>
											<Button type="submit" variant="contained" color="primary" disabled={disabled} onClick={downloadReport}>
												Generate
											</Button>
										</Grid>
										<GridGrow />
										<Grid item>
											<Button variant="outlined" color="primary" disabled={!report || report.items.length === 0|| disabled} onClick={downloadInvoicePdfs}>
												Download PDFs
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Grid>

			{report && (
				<Grid item md={8} sm={10} xs={12}>
					<InvoiceReportView report={report} />
				</Grid>
			)}
		</Grid>
	);
}

function InvoiceReportView(props: { report: InvoiceReport }) {
	const { report } = props;
	return (
		<Card>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Invoice</TableCell>
						<TableCell>Customer</TableCell>
						<TableCell>Issued</TableCell>
						<TableCell>Amount</TableCell>
						<TableCell>Payment</TableCell>
						<TableCell>Balance</TableCell>
						<TableCell>Days Overdue</TableCell>
						<TableCell>Due Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell colSpan={2}>Invoices: {report.items.length}</TableCell>
						<TableCell colSpan={2}>
							Total Billed: <Price value={report.totalBilledAmount} />{" "}
						</TableCell>
						<TableCell colSpan={2}>
							Total Paid: <Price value={report.totalPayments} />{" "}
						</TableCell>
						<TableCell colSpan={2}>
							Total Balance: <Price value={report.totalBalance} />{" "}
						</TableCell>
					</TableRow>
					{report.items.map((item) => (
						<TableRow key={item.invoiceId}>
							<TableCell>
								<ClientLink newTab to={routes.app.resolve.invoiceDetailPage(item.invoiceId)}>
									{item.invoiceShortId}
								</ClientLink>
							</TableCell>
							<TableCell>
								<ClientLink newTab to={routes.app.resolve.customerDetailPage(item.customerId)}>
									{item.customerName}
								</ClientLink>
							</TableCell>
							<TableCell>{item.issuedOn.toLocaleDateString()}</TableCell>
							<TableCell>
								<Price value={item.subTotal} />
							</TableCell>
							<TableCell>
								<Price value={item.paymentTotal} />
							</TableCell>
							<TableCell>
								<Price value={item.balance} />
							</TableCell>
							<TableCell>{item.daysOverdue}</TableCell>
							<TableCell>{item.dueBy.toLocaleDateString()}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Card>
	);
}
