import * as React from "react";
import { IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

export function PrivacyPolicy() {
	const history = useHistory();
	return (
		<div style={{ margin: 30 }}>
			<Typography>
				<IconButton onClick={history.goBack}>
					<ArrowBackIcon />
				</IconButton>
			</Typography>
			<Typography variant="h1" align="center" gutterBottom>
				Privacy Policy
			</Typography>
			<Typography>
				Protecting your private information is our priority. This Statement of Privacy applies to Dispatch Pro, and Dispatch Pro, LLC and governs data collection and
				usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Dispatch Pro, LLC include dispatchpro.app and Dispatch Pro. The
				Dispatch Pro application is a business management application. By using the Dispatch Pro application, you consent to the data practices described in this
				statement.
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				Collection of your Personal Information
			</Typography>
			<Typography gutterBottom>
				In order to better provide you with products and services offered, Dispatch Pro may collect personally identifiable information, such as your:
			</Typography>
			<Typography gutterBottom style={{ marginLeft: 10 }}>
				-{"\u00a0\u00a0\u00a0"}First and Last Name
				<br />-{"\u00a0\u00a0\u00a0"}Mailing Address
				<br />-{"\u00a0\u00a0\u00a0"}E-mail Address
				<br />-{"\u00a0\u00a0\u00a0"}Phone Number
				<br />-{"\u00a0\u00a0\u00a0"}Employer
				<br />-{"\u00a0\u00a0\u00a0"}Job Title
			</Typography>
			<Typography gutterBottom>
				If you purchase Dispatch {"Pro's"} products and services, we collect billing and credit card information. This information is used to complete the purchase
				transaction.
			</Typography>
			<Typography gutterBottom>
				We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal
				information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest
				sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your
				credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to,
				communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information
				in the future.
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				Use of your Personal Information
			</Typography>
			<Typography gutterBottom>Dispatch Pro collects and uses your personal information to operate and deliver the services you have requested.</Typography>
			<Typography gutterBottom>
				Dispatch Pro may also use your personally identifiable information to inform you of other products or services available from Dispatch Pro and its affiliates.
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				Sharing Information with Third Parties
			</Typography>
			<Typography gutterBottom>Dispatch Pro does not sell, rent or lease its customer lists to third parties.</Typography>
			<Typography gutterBottom>
				Dispatch Pro may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for
				deliveries. All such third parties are prohibited from using your personal information except to provide these services to Dispatch Pro, and they are required
				to maintain the confidentiality of your information.
			</Typography>
			<Typography gutterBottom>
				Dispatch Pro may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to:
				(a) conform to the edicts of the law or comply with legal process served on Dispatch Pro or the site; (b) protect and defend the rights or property of Dispatch
				Pro; and/or (c) act under exigent circumstances to protect the personal safety of users of Dispatch Pro, or the public.
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				Right to Deletion
			</Typography>
			<Typography gutterBottom>
				Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
				<ul>
					<li>Delete your personal information from our records; and</li>
					<li>Direct any service providers to delete your personal information from their records.</li>
				</ul>
			</Typography>
			<Typography gutterBottom>
				Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
				<ul>
					<li>
						Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in
						accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business
						relationship with you, or otherwise perform a contract between you and us;
					</li>
					<li>
						Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;
					</li>
					<li>Debug to identify and repair errors that impair existing intended functionality;</li>
					<li>
						Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;
					</li>
					<li>Comply with the California Electronic Communications Privacy Act;</li>
					<li>
						Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics
						and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we
						have obtained your informed consent;
					</li>
					<li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
					<li>Comply with an existing legal obligation; or</li>
					<li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
				</ul>
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				Children Under Thirteen
			</Typography>
			<Typography gutterBottom>
				Dispatch Pro does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you
				must ask your parent or guardian for permission to use this application.
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				E-mail Communications
			</Typography>
			<Typography gutterBottom>
				From time to time, Dispatch Pro may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys,
				and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Dispatch Pro or click on a link
				therein.
			</Typography>
			<Typography gutterBottom>
				If you would like to stop receiving marketing or promotional communications via email from Dispatch Pro, you may opt out of such communications by clicking on
				the UNSUBSCRIBE button or by emailing support@dispatchpro.app.
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				External Data Storage Sites
			</Typography>
			<Typography gutterBottom>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				Changes to this Statement
			</Typography>
			<Typography gutterBottom>
				Dispatch Pro reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal
				information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our application, and/or by updating
				any privacy information. Your continued use of the application and/or Services available after such modifications will constitute your: (a) acknowledgment of
				the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
			</Typography>
			<Typography variant="h3" style={{ marginTop: 30, marginBottom: 10 }}>
				Contact Information
			</Typography>
			<Typography gutterBottom>
				Dispatch Pro welcomes your questions or comments regarding this Statement of Privacy. If you believe that Dispatch Pro has not adhered to this Statement,
				please contact Dispatch Pro at:
			</Typography>
			<Typography gutterBottom>
				Dispatch Pro, LLC <br />
				5402 La Porte Dr <br />
				Colorado Springs, Colorado 80918
			</Typography>
			<Typography gutterBottom>
				Email Address: <br />
				support@dispatchpro.app
			</Typography>
			<Typography gutterBottom>
				Telephone number: <br />
				6013254647
			</Typography>
			<Typography gutterBottom>Effective as of July 26, 2021</Typography>
		</div>
	);
}
