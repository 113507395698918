import { Button, Card, CardContent, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../../components/BackButton";
import { QuickBooksDesktopLedgerAccount, getAvailableLedgerAccountTypes } from "../../../../entities/accounting/GeneralLedgerAccount";
import { useAlert } from "../../../../hooks/useAlert";
import { LedgerAccountService } from "../../../../services/accounting/LedgerAccountsService";
import { useLedgerAccountPage } from "../LedgerAccountPage";
import { SelectLedgerType } from "./SelectLedgerType";


export function QuickBooksDesktopLedgerAccountDetailView(props: { account: QuickBooksDesktopLedgerAccount }) {
	const { account } = props;
    const alert = useAlert();
    const context = useLedgerAccountPage();

	const [name, setName] = React.useState(account.name);
	const [quickBooksAccountName, setQuickBooksAccountName] = React.useState(account.quickBooksAccountName);
	const [type, setType] = React.useState(account.type ?? getAvailableLedgerAccountTypes(account.classification)[0]);

    const [disabled, setDisabled] = React.useState(false);

    const onSave = async () => {
        setDisabled(true);
        const result = await LedgerAccountService.updateQuickBooksDesktopAccount({accountId: account.id, name, type, quickBooksAccountName});
        setDisabled(false);
        if(result.success) {
            context.updateLedgerAccount(result.data);
            alert.success("Account Updated");
        } else if(result.validation){
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

	return (
		<Grid container>
			<Grid item xl={4} sm={6} xs={12} >
				<Card>
					<CardContent>
						<Grid container alignItems="center">
                            <Grid item><BackButton/></Grid>
                            <Grid item> <Typography variant="h5">QuickBooks Account</Typography></Grid>
                        </Grid>
						<Grid container spacing={2}>
							<Grid item sm={6} xs={12}>
								<TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth disabled={disabled}/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<SelectLedgerType classification={account.classification} value={type} onChange={setType} disabled={disabled}/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField label="QuickBooks Account Name" value={quickBooksAccountName} onChange={(e) => setQuickBooksAccountName(e.target.value)} fullWidth disabled={disabled}/>
							</Grid>
						</Grid>
					</CardContent>
                    <Button variant="contained" color="primary" fullWidth disabled={disabled} onClick={onSave}>Save</Button>
				</Card>
			</Grid>
		</Grid>
	);
}