import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import React, { useEffect } from "react";
import { BusinessService } from "../services/business/BusinessService";
import { Modify } from "../utility/modifyType";

type Props = Modify<TextFieldProps, {
    value: string;
    onChange: (timezone: string) => void;
}>;

export function TimezoneSelect(props: Props){
    const [timezones, setTimezones] = React.useState<string[]>();

    useEffect(() => {
        async function fetch(){
            const response = await BusinessService.getTimezones();
            if (response.success){
                setTimezones(response.data);
            }
        }
        fetch();
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value);
    
    if(!timezones)
        return <TextField {...props} value="Loading Timezones" onChange={() =>{}} disabled label={props.label ?? "Timezone"} />;

    return <TextField {...props} onChange={onChange} select label={props.label ?? "Timezone"}>
        {
            timezones.map(timezone => <MenuItem key={timezone} value={timezone}>{timezone}</MenuItem>)
        }
    </TextField>;
}