import { FormControl, FormHelperText, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { PriceTextField } from "../../components/PriceTextField";
import { ProductLine } from "../../entities/products/ProductLine";
import { useAlert } from "../../hooks/useAlert";
import { ProductCatalogService } from "../../services/products/ProductCatalogService";

import { FieldValidationError, validationError } from "../../services/server/ServerValidationError";
import { Price } from "../../components/Price";
import { LedgerAccountService } from "../../services/accounting/LedgerAccountsService";
import { GeneralLedgerAccount, LedgerClassification } from "../../entities/accounting/GeneralLedgerAccount";
import { useProductCatalog } from "../../providers/ProductCatalogProvider";
import { LoadingOverlay } from "@material-ui/data-grid";
import { routes } from "../../routes";
import { GridGrow } from "../../components/GridGrow";
import { BackButton } from "../../components/BackButton";
import { SearchTextField } from "../../components/SearchTextField";
import { ProductListing } from "../../entities/products/ProductListing";
import { SortableTableHeader, TableHeader } from "../../components/table/SortableTableHeader";
import { LinkTableRow } from "../../components/LinkTableRow";
import { orderBy } from "../../utility/orderBy";

export function ProductLineDetailPage() {
	const params = useParams<{ productLineId: string }>();
	const productLineId = params.productLineId;

	const productCatalogContext = useProductCatalog();
	const { productCatalog, loadStatus } = productCatalogContext;
	const productLines = productCatalog.productLines;

	const productLine = React.useMemo(() => productLines.find((pl) => pl.id === productLineId), [productLines, productLineId]);

	if (!productLine || loadStatus === "loading") {
		return <LoadingOverlay />;
	}

	return <ProductLineDetailView productLine={productLine} />;
}

function applySearchFilter(productListings: ProductListing[], search: string) {
	if (search.trim() === "") {
		return productListings;
	}

	const searchLower = search.toLowerCase();
	return productListings.filter(
		(pl) =>
			pl.name.toLowerCase().includes(searchLower) ||
			(pl.productCode && pl.productCode.toLowerCase().includes(searchLower)) ||
			(pl.unitName && pl.unitName.toLowerCase().includes(searchLower)) ||
			pl.price.toString().includes(searchLower)
	);
}

function applySort(productListings: ProductListing[], sortBy: string, order: "asc" | "desc") {
	if (sortBy === "Name") {
		return productListings.sort(orderBy.string((pl) => pl.name, order));
	}
	if (sortBy === "Price") {
		return productListings.sort(orderBy.number((pl) => pl.price, order));
	}
	if (sortBy === "Unit") {
		return productListings.sort(orderBy.optional.string((pl) => pl.unitName, order, "optionalEnd"));
	}
	if (sortBy === "Code") {
		return productListings.sort(orderBy.optional.string((pl) => pl.productCode, order, "optionalEnd"));
	}
	if (sortBy === "Status") {
		return productListings.sort(orderBy.boolean((pl) => pl.disabled != null, order));
	}
	return productListings;
}
function ProductLineDetailView(props: { productLine: ProductLine }) {
	const { productLine } = props;

	const history = useHistory<{ productName: string } | undefined>();
	const location = history.location;
	const query = new URLSearchParams(location.search);
	const search = query.get("search") ?? "";
	const pageNumber = Number(query.get("page") ?? "1");
	const rowsPerPage = Number(query.get("show") ?? "10");
	const order = (query.get("order") ?? "asc") as "asc" | "desc";
	const sortBy = query.get("sort") ?? "Name";

	const [createListingName] = useState(history.location.state?.productName);
	const [showDialog, setShowDialog] = useState(createListingName != null);

	const filteredProductListings = applySearchFilter(productLine.allListings, search);
	const sortedProductListings = applySort(filteredProductListings, sortBy, order);
	const displayProductListings = sortedProductListings.slice((pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage);

	const onSearchTextChanged = (search: string) => {
		query.set("search", search);
		query.set("page", "1");
		history.replace({ search: query.toString() });
	};

	const onPageChange = (page: number) => {
		query.set("page", page.toString());
		history.replace({ search: query.toString() });
	};

	const onRowCountChange = (rowsPerPage: number) => {
		query.set("show", rowsPerPage.toString());
		query.set("page", "1");
		history.replace({ search: query.toString() });
	};
	const onSortByChange = (property: string, order: "asc" | "desc") => {
		query.set("sort", property);
		query.set("order", order);
		history.replace({ search: query.toString() });
	};

	return (
		<>
			<PageTitle title={productLine.name} />
			<Grid container spacing={2} justify="flex-start">
				<Grid item lg={8} md={10} sm={12} xs={12}>
					<Grid container spacing={1} alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<Typography variant="h2">{productLine.name}</Typography>
						</Grid>
						<GridGrow />
						<Grid item>
							<SearchTextField value={search} onChange={onSearchTextChanged} />
						</Grid>
						<Grid item>
							<Button color="primary" variant="contained" onClick={() => setShowDialog(true)}>
								+ Add Listing
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={8} md={10} sm={12} xs={12}>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[10, 20, 50]}
										colSpan={5}
										count={filteredProductListings.length}
										rowsPerPage={rowsPerPage}
										labelRowsPerPage={"Show"}
										page={pageNumber - 1}
										onChangePage={(_, page) => onPageChange(page + 1)}
										onChangeRowsPerPage={(e) => onRowCountChange(Number(e.target.value))}
									/>
								</TableRow>
							</TableHead>
							<SortableTableHeader order={order} orderBy={sortBy}>
								<TableHeader property="Name" onSort={onSortByChange}>
									Product
								</TableHeader>
								<TableHeader property="Price" onSort={onSortByChange}>
									Price
								</TableHeader>
								<TableHeader property="Unit" onSort={onSortByChange}>
									Unit
								</TableHeader>
								<TableHeader property="Code" onSort={onSortByChange}>
									Code
								</TableHeader>
								<TableHeader property="Status" onSort={onSortByChange}>
									Status
								</TableHeader>
							</SortableTableHeader>
							<TableBody>
								{displayProductListings.map((listing) => (
									<LinkTableRow key={listing.id} to={routes.business.resolve.productListingPage(productLine.id, listing.id)}>
										<TableCell width="100%">{listing.name}</TableCell>
										<TableCell><Price value={listing.price} /></TableCell>
										<TableCell>
											{listing.unitName ?? ""}
										</TableCell>
										<TableCell>{listing.productCode ?? ""}</TableCell>
										<TableCell>{listing.disabled ? "Inactive" : "Active"}</TableCell>
									</LinkTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<AddListingDialog productLine={productLine} createListingName={createListingName} show={showDialog} onClose={() => setShowDialog(false)} />
		</>
	);
}

function AddListingDialog(props: { productLine: ProductLine; createListingName?: string; show: boolean; onClose: () => void }) {
	const { productLine, createListingName, show: showDialog } = props;
	const history = useHistory();
	const alert = useAlert();

	const productCatalogPageContext = useProductCatalog();

	const [disabled, setDisabled] = useState(false);
	const [name, setName] = useState(createListingName ?? "");
	const [price, setPrice] = useState(0);
	const [unitName, setUnitName] = useState("");
	const [productCode, setProductCode] = useState("");
	const [revenueAccounts, setRevenueAccounts] = useState<GeneralLedgerAccount[]>();
	const [selectedRevenueAccountId, setSelectedRevenueAccountId] = useState<number>();
	const selectedRevenueAccount = revenueAccounts?.find((a) => a.id === selectedRevenueAccountId);

	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	React.useEffect(() => {
		async function fetchLedgerAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setRevenueAccounts(result.data.filter((a) => a.classification === LedgerClassification.Revenue));
			} else {
				alert.serverError(result);
			}
		}
		fetchLedgerAccounts();
	}, [alert]);

	const onAddProductListing = async (event: React.FormEvent<HTMLFormElement>) => {
		if (!selectedRevenueAccount) {
			return;
		}
		event.preventDefault();

		setDisabled(true);
		const result = await ProductCatalogService.addProductListing({
			productLineId: productLine.id,
			name,
			price,
			unitName,
			productCode,
			revenueAccountId: selectedRevenueAccount.id,
		});
		setDisabled(false);

		if (result.success) {
			props.onClose();
			productCatalogPageContext.addProductListing(result.data);
			if (createListingName != null) {
				history.goBack();
				history.goBack();
			}
		} else if (result.validation) {
			setValidationErrors(result.errors);
		} else {
			alert.serverError(result);
		}
	};
	const onChangeRevenueAccount = (event: React.ChangeEvent<{ value: string }>) => setSelectedRevenueAccountId(Number(event.target.value));

	const canSubmit = name.trim() !== "" && selectedRevenueAccountId != null;

	return (
		<Dialog open={showDialog} onClose={props.onClose}>
			<DialogTitle>Add Product Listing</DialogTitle>
			<form onSubmit={onAddProductListing}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								disabled={disabled}
								label="Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "Name")}
								helperText={validationError.getFieldError(validationErrors, "Name")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<PriceTextField
								required
								fullWidth
								disabled={disabled}
								label="Price"
								value={price}
								onPriceChanged={(price) => setPrice(price ?? 0)}
								error={validationError.isFieldInError(validationErrors, "Price")}
								helperText={validationError.getFieldError(validationErrors, "Price")}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<FormControl fullWidth>
								<TextField
									required
									fullWidth
									disabled={disabled || !revenueAccounts}
									select
									label="Revenue Account"
									value={selectedRevenueAccountId}
									onChange={onChangeRevenueAccount}
									error={validationError.isFieldInError(validationErrors, "RevenueAccountId")}
									helperText={validationError.getFieldError(validationErrors, "RevenueAccountId")}
								>
									{!revenueAccounts && <MenuItem value={0}>Loading...</MenuItem>}
									{revenueAccounts?.map((a) => (
										<MenuItem key={a.id} value={a.id}>
											{a.name}
										</MenuItem>
									))}
								</TextField>
								<FormHelperText>Which account should sales go to?</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="Unit (Optional)"
								value={unitName}
								onChange={(e) => setUnitName(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "UnitName")}
								helperText={validationError.getFieldError(validationErrors, "UnitName")}
							/>
							<FormHelperText>
								Example: {name} - <Price value={price} />
								{unitName.trim() !== "" && <span> per {unitName}</span>}
							</FormHelperText>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								disabled={disabled}
								label="Product Code (Optional)"
								value={productCode}
								onChange={(e) => setProductCode(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "ProductCode")}
								helperText={validationError.getFieldError(validationErrors, "ProductCode")}
							/>
							<FormHelperText>Product code or SKU to categorize this product. Must be unique.</FormHelperText>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button disabled={disabled} onClick={props.onClose} color="secondary" variant="outlined">
						Cancel
					</Button>
					<Button disabled={disabled || !canSubmit} type="submit" color="primary" variant="contained">
						Add
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
