import React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import { CenteredLoadingSpinner } from "../../../components/CenteredLoadingSpinner";

export function KpiLoadingCard(props: { title: string }) {
	return (
		<Card style={{ height: "100%", minHeight: "266px" }}>
			<CardHeader title={props.title ?? "Loading..."} />
			<Grid container direction="column" justify="center" style={{ height: "100%" }}>
				<Grid item>
                    <CenteredLoadingSpinner size={75} />
				</Grid>
			</Grid>
		</Card>
	);
}