import { Tenant } from "../../entities/auth/Tenant";
import { User } from "../../entities/auth/User";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

export interface ChangePasswordRequest {
	password: string;
}

interface ChangeContactRequest {
	firstName: string;
	lastName: string;
	phoneNumber: string | null;
	enableNotifications: boolean;
}

export const AccountService = {
	getUserTenant: () => {
        return WebClient.Get<Tenant>(`${apiServer}/api/account`);
    },
	getUser: (userId: string) => {
		return WebClient.GetOptional<User>(`${apiServer}/api/account/user/${userId}`);
	},
	changePassword(password: string) {
        const request: ChangePasswordRequest = { password };
		return WebClient.Post.Validated(`${apiServer}/api/account/change-password`, request);
	},
	changeContact(request: ChangeContactRequest) {
		return WebClient.Post.Validated(`${apiServer}/api/account/change-contact`, request);
	},
	toggleSmsNotifications() {
		return WebClient.Put.Validated<boolean>(`${apiServer}/api/account/toggle-notifications`, {});
	},
	enableSmsNotifications(phoneNumber: string){
		return WebClient.Post.Validated(`${apiServer}/api/account/enable-notifications`, {phoneNumber});
	}
};