import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../../components/BackButton";
import { PageTitle } from "../../../../components/PageTitle";
import { DispatchProPaymentsIntegrationOption, DispatchProPaymentsIntegration } from "../../../../entities/auth/IntegrationConfiguration";
import { useAlert } from "../../../../hooks/useAlert";
import { IntegrationService } from "../../../../services/auth/IntegrationService";
import { RestrictToRole } from "../../../../components/RestrictToRole";

interface Props {
	option: DispatchProPaymentsIntegrationOption;
	onConnect: (configuration: DispatchProPaymentsIntegration) => void;
}

export function ConnectDPPaymentsView(props: Props) {
	const { option } = props;
	const alert = useAlert();
	const [merchantId, setMerchantId] = React.useState<string | null>(null);
	const [disabled, setDisabled] = React.useState(false);

	const submit = async () => {
		if (!merchantId) {
			alert.validation({ errors: [{ field: "merchantId", errors: ["Merchant ID is required"] }] });
			return;
		}
		setDisabled(true);
		const result = await IntegrationService.connectToDispatchProPayments({ merchantId });
		if (result.success) {
			alert.success("Connected to DispatchPro Payments!");
			props.onConnect(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
		setDisabled(false);
	};

	return (
		<Grid container justify="center">
			<PageTitle title="Integrations - DispatchPro Payments" />
			<Grid item lg={6} md={8} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="DispatchPro Payments" />
						</Grid>
					</Grid>
					<CardContent>
						<Grid container justify="center">
							<Grid item>
								<img src={option.largeLogoUrl} alt="DispatchPro Payments Logo" style={{ maxWidth: "100%", maxHeight: 150, marginBottom: 15 }} />
							</Grid>
						</Grid>
						<Typography variant="body1">{option.description}</Typography>
						<Typography variant="body1" color="textSecondary" style={{ fontSize: "0.8rem", paddingTop: 10, paddingBottom: 10 }}>
							*Automatic connection is not supported at this time. Please contact DispatchPro Support if you are interested in making us your primary payment
							processor
						</Typography>
						<RestrictToRole superAdmin>
							<Grid container alignItems="center" style={{ marginTop: 15 }}>
								<Grid item lg={5} sm={6} xs={8}>
									<form
										onSubmit={(e) => {
											e.preventDefault();
											submit();
										}}
									>
										<TextField
											variant="outlined"
											label="Merchant ID"
											value={merchantId ?? ""}
											onChange={(e) => setMerchantId(e.target.value)}
											autoFocus
											disabled={disabled}
											fullWidth
										/>
									</form>
								</Grid>
								<Grid md={3} sm={2} xs={4}>
									<Button
										color="primary"
										fullWidth
										variant="contained"
										disabled={disabled}
										style={{ height: 55, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
										onClick={submit}
									>
										Connect
									</Button>
								</Grid>
							</Grid>
						</RestrictToRole>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
