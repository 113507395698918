import Auth from "../../authorization/Auth";
import { User } from "../../entities/auth/User";
import { CustomAxios } from "../server/CustomAxios";
import { apiServer } from "../server/Setting";
import { ServerError, SuccessResult, WebClient } from "../server/WebClient";

export interface SignInRequest {
	email: string;
	password: string;
	ignoreRefreshToken?: boolean;
}

interface AcceptInviteRequest {
	userId: string, 
	token: string;
	firstName: string;
	lastName: string;
	password: string;
}

interface ForgotPasswordRequest {
	email: string;
}

interface AuthResult {
	jwt: string;
}

export const AuthService = {
	async attemptTokenRefreshAndSet(): Promise<AuthResult | undefined> {
		const axiosClient = CustomAxios.create();
		const response = await axiosClient.get(`${apiServer}/api/auth/refresh`, { withCredentials: true });
		const result = response.data as ServerError | SuccessResult<AuthResult>;
		if (result.success) {
			console.log("Token Refresh Success")
			Auth.setToken(result.data.jwt);
			return result.data;
		}
	},
	async getJwt() {
		const token = Auth.getSavedToken();
		const user = Auth.getJwtUser();

		if (!token || !user || user.expiration.getTime() < new Date().getTime()) {
			const authResult = await AuthService.attemptTokenRefreshAndSet();
			if (authResult) {
				return authResult.jwt;
			}
			if(token){
				return token;
			}
			throw new Error("Cannot refresh token");
		}
		return token;
	},
	async signIn(request: SignInRequest) {
		const result = await WebClient.Post.Validated<AuthResult>(`${apiServer}/api/auth/sign-in`, request);
		if (result.success) {
			Auth.setToken(result.data.jwt);
			return result;
		}
		return result;
	},
	signOut(savePath?: boolean) {
		Auth.clearToken();
		let signOutUrl = `${apiServer}/sign-out`;
		if (savePath) {
			signOutUrl += `?redirect=${encodeURI(window.location.pathname + window.location.search)}`;
		}
		window.location.assign(signOutUrl);
	},
	forgotPassword(email: string){
		const request: ForgotPasswordRequest = { email };
		return WebClient.Post.Unvalidated(`${apiServer}/api/auth/forgot-password`, request);
	},
	async validForgottenPasswordResetRequest(userId: string, token: string){
		const result = await WebClient.Post.Validated(`${apiServer}/api/auth/forgot-password-reset-check`, {userId, token});
		return result.success;
	},
	forgottenPasswordReset(userId: string, token: string, password: string){
		return WebClient.Post.Validated(`${apiServer}/api/auth/forgot-password-reset`, {userId, token, password});
	},
	validUserInviteRequest(userId: string, token: string){
		return WebClient.Post.Unvalidated<User | null>(`${apiServer}/api/auth/invite-user-check`, {userId, token});
	},
	async acceptInvite(request: AcceptInviteRequest){
		const result = await WebClient.Post.Validated<AuthResult>(`${apiServer}/api/auth/accept-invite`, request);
		if (result.success) {
			Auth.setToken(result.data.jwt);
			return result;
		}
		return result;
	}
};

export default AuthService;
