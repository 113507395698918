import { throttle } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTenant } from "../providers/TenantProvider";

type ReturnType = [google.maps.places.AutocompletePrediction[], (query: string) => void, google.maps.Geocoder | undefined];

export function useGoogleAddressSuggestion(): ReturnType {
	const tenant = useTenant();
	const address = tenant.loadStatus === "ready" && tenant.tenant.officeAddress ? tenant.tenant.officeAddress : null;
	const [autoCompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | undefined>();
	const [placesService, setPlacesService] = useState<google.maps.Geocoder | undefined>();
	const [places, setPlaces] = useState<google.maps.places.AutocompletePrediction[]>([]);
	const [query, setQuery] = useState("");

	useEffect(() => {
		async function loadGoogleLibraries() {
			const [placesLibrary, geocodingLibrary] = await Promise.all([
				google.maps.importLibrary("places") as Promise<google.maps.PlacesLibrary>,
				google.maps.importLibrary("geocoding") as Promise<google.maps.GeocodingLibrary>,
			]);
			setAutocompleteService(new placesLibrary.AutocompleteService());
			setPlacesService(new geocodingLibrary.Geocoder());
		}
		loadGoogleLibraries();
	}, []);

	const fetchPredictions = useMemo(
		() =>
			throttle((request: { input: string }, callback: (results: google.maps.places.AutocompletePrediction[] | null) => void) => {
				if (autoCompleteService){
					autoCompleteService.getPlacePredictions(
						{
							...request,
							componentRestrictions: { country: "us" },
							location: address ? new google.maps.LatLng(address.latitude, address.longitude) : undefined,
							radius: address ? 50000 : undefined,
							types: ["address"],
						},
						callback
					);
				}
			}, 100),
		[address, autoCompleteService]
	);

	const processPredictions = (results: google.maps.places.AutocompletePrediction[] | null) => {
		const onlyAddresses = results?.filter((r) => !r.types.some((t) => t === "route")) ?? [];
		setPlaces(onlyAddresses);
	};

	useEffect(() => {
		const placeQuery = query.trim();
		if (placeQuery !== "") {
			fetchPredictions({ input: placeQuery }, processPredictions);
		}
	}, [query, fetchPredictions]);

	return [places, setQuery, placesService];
}
