import { Modify } from "../../utility/modifyType";
import { matchesTaxRule, TaxRule } from "../accounting/TaxRule";
import { GeocodedAddress } from "../customer/GeocodedAddress";
import { deserializeProductLine, ProductLine, SerializedProductLine, serializeProductLine } from "./ProductLine";
import { ProductListing } from "./ProductListing";

export interface ProductCatalogData {
	productLines: ProductLine[];
	taxRules: TaxRule[];
}

export class ProductCatalog {
	productLines: ProductLine[];
	taxRules: TaxRule[];

	constructor(public readonly data: ProductCatalogData) {
		this.productLines = data.productLines;
		this.taxRules = data.taxRules;
	}

	// Empty product catalog
	static empty = new ProductCatalog({ productLines: [], taxRules: [] });

	// Get applicable tax rules for a product listing
	getTaxRules = (listing: ProductListing, address: GeocodedAddress): TaxRule[] =>
		this.taxRules
			.filter(
				(rule) =>
					rule.assignedProductLines.some((assignedLine) => assignedLine.productLineId === listing.productLineId) ||
					rule.assignedProductListings.some((assignedListing) => assignedListing.listingId === listing.id)
			)
			.reduce((accumulator: TaxRule[], current: TaxRule) => {
				if (!accumulator.some((rule) => rule.id === current.id)) {
					accumulator.push(current);
				}
				return accumulator;
			}, [])
			.filter((rule) => matchesTaxRule(rule, address));

	getProductListing = (listingId: string): ProductListing | undefined => this.productLines.flatMap((line) => line.allListings).find((listing) => listing.id === listingId);
}

export type SerializedProductCatalogData = Modify<ProductCatalogData, { productLines: SerializedProductLine[] }>;
export const deserializeProductCatalogData = (model: SerializedProductCatalogData): ProductCatalogData => ({
	...model,
	productLines: model.productLines.map(deserializeProductLine),
});
export const serializeProductCatalogData = (model: ProductCatalogData): SerializedProductCatalogData => ({
	...model,
	productLines: model.productLines.map(serializeProductLine),
});
export const deserializeProductCatalog = (model: SerializedProductCatalogData): ProductCatalog => new ProductCatalog(deserializeProductCatalogData(model));
