import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { UserListViewConfig } from "../../../components/listView/configs/UserListViewConfig";
import { ListView } from "../../../components/listView/ListView";
import { User } from "../../../entities/auth/User";
import { routes } from "../../../routes";
import { AddUserDialog } from "./AddUserDialog";
import { useUserPage } from "./UserPage";

export function UserListView() {
    const history = useHistory();
	const userContext = useUserPage();
	const { users } = userContext;
	const [showAddUserDialog, setShowAddUserDialog] = useState(false);

	const onUserAdded = (user: User) => {
		if (users) {
			userContext.addUser(user);
		}
		setShowAddUserDialog(false);
	};

	return (
		<Grid container>
			<Grid item>
				<ListView
					listViewConfig={UserListViewConfig}
					onSelect={(user) => history.push(routes.business.resolve.userDetailPage(user.id))}
					title="Users"
					tableConfig={{ data: { users } }}
					action={
						<>
							<AddUserDialog key={users.length} open={showAddUserDialog} onClose={() => setShowAddUserDialog(false)} onUserAdded={onUserAdded} />
							<Button color="primary" variant="contained" onClick={() => setShowAddUserDialog(true)}>
								+ Add User
							</Button>
						</>
					}
				/>
			</Grid>
		</Grid>
	);
}
