import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	FormControlLabel,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@material-ui/core";
import React from "react";
import { LinkTableRow } from "../../components/LinkTableRow";
import { PageTitle } from "../../components/PageTitle";
import { TableHeader } from "../../components/table/SortableTableHeader";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { PropaneTankService, SearchablePropaneTank } from "../../services/customer/PropaneTankService";
import { formatAddress } from "../../entities/customer/GeocodedAddress";

export function PropaneTankSearchPage() {
	const alert = useAlert();

	const [serialNumber, setSerialNumber] = React.useState("");
	const [isRental, setIsRental] = React.useState(false);

	const [disabled, setDisabled] = React.useState(false);
	const [pageSize, setPageSize] = React.useState(10);
	const [page, setPage] = React.useState(1);
	const [totalCount, setTotalCount] = React.useState(0);

	const [propaneTanks, setPropaneTanks] = React.useState<SearchablePropaneTank[]>();

	const searchTanks = async () => {
		await loadResults(1, pageSize);
	};

	const onPageSizeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const pageSize = parseInt(event.target.value);
		setPageSize(pageSize);
		await loadResults(1, pageSize);
	};

	const loadResults = async (page: number, pageSize: number) => {
		setDisabled(true);
		const result = await PropaneTankService.search({
			serialNumber: serialNumber.trim(),
			isRental: isRental,
			page,
			pageSize: pageSize,
		});
		setDisabled(false);
		if (result.success) {
			setPropaneTanks(result.data.results);
			setTotalCount(result.data.totalCount);
			setPage(result.data.page);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Grid container spacing={2} justify="center">
			<PageTitle title="Search Tanks" />
			<Grid item md={8} sm={10} xs={12}>
				<Card>
					<CardHeader title="Search Tanks" />
					<form>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item sm={4} xs={12}>
									<TextField
										variant="outlined"
										disabled={disabled}
										fullWidth
										label="Serial Number"
										value={serialNumber}
										onChange={(e) => setSerialNumber(e.target.value)}
									/>
								</Grid>
								<Grid item sm={4} xs={12}>
									<FormControlLabel
										control={<Checkbox color="primary" checked={isRental} onChange={(e) => setIsRental(e.target.checked)} />}
										label="Is Rental"
									/>
								</Grid>
								<Grid item xs={12}>
									<Button variant="contained" type="submit" color="primary" onClick={searchTanks} disabled={disabled}>
										Search
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</form>
				</Card>
			</Grid>
			{propaneTanks !== undefined && (
				<Grid item md={8} sm={10} xs={12}>
					<Card>
						{propaneTanks.length === 0 && <CardHeader title="No Tanks Found" />}
						{propaneTanks.length > 0 && (
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TablePagination
												rowsPerPageOptions={[10, 20, 50]}
												colSpan={6}
												nextIconButtonProps={{ disabled }}
												backIconButtonProps={{ disabled: disabled || page === 1 }}
												count={totalCount}
												rowsPerPage={pageSize}
												labelRowsPerPage={"Show"}
												page={page - 1}
												onChangePage={(_, page) => loadResults(page + 1, pageSize)}
												onChangeRowsPerPage={onPageSizeChange}
											/>
										</TableRow>
										<TableRow>
											<TableHeader>Description</TableHeader>
											<TableHeader>Capacity</TableHeader>
											<TableHeader>Serial Number</TableHeader>
											<TableHeader>Rental</TableHeader>
											<TableHeader>Customer</TableHeader>
											<TableHeader>Location</TableHeader>
										</TableRow>
									</TableHead>

									<TableBody>
										{propaneTanks.map((tank) => (
											<LinkTableRow key={tank.id} to={routes.app.resolve.propaneTankDetailPage(tank.id)} newTab>
												<TableCell>{tank.description}</TableCell>
												<TableCell>{tank.gallons} Gallons</TableCell>
												<TableCell>{tank.serialNumber}</TableCell>
												<TableCell>{tank.isRental ? "Yes" : "No"}</TableCell>
												<TableCell>{tank.customerName}</TableCell>
												<TableCell>{formatAddress(tank.address)}</TableCell>
											</LinkTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Card>
				</Grid>
			)}
		</Grid>
	);
}
