import { Box, Button, CircularProgress, Dialog, Grid, List, ListItem, ListItemText, MenuItem, Paper, TextField, Typography, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { PageTitle } from "../../components/PageTitle";
import { useTenant } from "../../providers/TenantProvider";
import { BuilderPageState, useBuilderPageStore } from "./BuilderPageStore";
import { GridGrow } from "../../components/GridGrow";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CreateIcon from "@material-ui/icons/Create";
import ErrorIcon from "@material-ui/icons/Error";
import { RouteBuilderState, useRouteBuilderStore } from "./RouteBuilderStore";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { formatAddress } from "../../entities/customer/GeocodedAddress";
import { DriverTicketDetailView } from "../tickets/ticket-detail/DriverTicketDetailView";

export function BuilderPage() {
	const theme = useTheme();
	const loaded = useBuilderPageStore((state: BuilderPageState) => state.loaded);

	useEffect(() => {
		useBuilderPageStore.getState().initializeStore(theme);
	}, [theme]);

	if (!loaded) return <Loader />;

	return (
		<Box flexDirection="column" flexGrow={1} display="flex">
			<PageTitle title="Route Builder" />
			<Header />
			<MainContent />
			<Footer />
		</Box>
	);
}

function Loader() {
	return (
		<Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
			<CircularProgress size={100} />
		</Box>
	);
}

function Header() {
	const setSelectionMode = useBuilderPageStore((state: BuilderPageState) => state.setSelectionMode);
	const selectionMode = useBuilderPageStore((state: BuilderPageState) => state.selectionMode);
	const clearSelection = useBuilderPageStore((state: BuilderPageState) => state.clearSelection);

	const toggleCircle = () => (selectionMode === "circle" ? setSelectionMode("single") : setSelectionMode("circle"));
	const toggleRectangle = () => (selectionMode === "rectangle" ? setSelectionMode("single") : setSelectionMode("rectangle"));
	const togglePolygon = () => (selectionMode === "polygon" ? setSelectionMode("single") : setSelectionMode("polygon"));

	return (
		<Box style={{ height: "100px", padding: 10 }}>
			<Grid container spacing={2} justify="center" alignItems="center" style={{ height: "100%" }}>
				<Grid item>
					<Grid container>
						<Grid xs={12} item>
							<Typography variant="body1" align="center">
								Select Many
							</Typography>
						</Grid>
						<Grid xs={12} item>
							<Grid container spacing={2} justify="center">
								<Grid item>
									<Button variant={selectionMode === "circle" ? "contained" : "outlined"} color="primary" onClick={toggleCircle} size="small">
										<RadioButtonUncheckedIcon />
									</Button>
								</Grid>
								<Grid item>
									<Button variant={selectionMode === "rectangle" ? "contained" : "outlined"} color="primary" onClick={toggleRectangle} size="small">
										<CheckBoxOutlineBlankIcon />
									</Button>
								</Grid>
								<Grid item>
									<Button variant={selectionMode === "polygon" ? "contained" : "outlined"} color="primary" onClick={togglePolygon} size="small">
										<CreateIcon />
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<GridGrow />
				<Grid item>
					<Button variant="contained" color="primary" onClick={clearSelection}>
						Clear Selection
					</Button>
				</Grid>
				<Grid item>
					<Box style={{ width: 350 }} />
				</Grid>
			</Grid>
		</Box>
	);
}

function MainContent() {
	const tenant = useTenant();
	const office = tenant.tenant.officeAddress;
	const mapElement = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!mapElement.current) {
			console.log("Map div not ready");
			return;
		}
		const state = useBuilderPageStore.getState() as BuilderPageState;
		state.initializeMap(mapElement.current, office);
		return () => {
			state.unmountMap();
		}
	}, [office]);

	// Map and side panel
	return (
		<Grid container style={{ flexGrow: 1 }}>
			<div id="map" ref={mapElement} style={{ flexGrow: 1 }} />
			<Grid item>
				<SidePanel />
			</Grid>
		</Grid>
	);
}

function SidePanel() {
	const tickets = useRouteBuilderStore((state: RouteBuilderState) => state.tickets) as DriverTicket[];
	const status = useRouteBuilderStore((state: RouteBuilderState) => state.routeLoadStatus) as "loading" | "ready";

	const [selectedTicket, setSelectedTicket] = React.useState<DriverTicket | null>(null);

	if (status === "loading") {
		return (
			<Paper style={{ width: 350, height: "100%" }}>
				<Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
					<Grid item>
						<CircularProgress />
					</Grid>
				</Grid>
			</Paper>
		);
	}

	if (tickets.length === 0) {
		return (
			<Paper style={{ width: 350, height: "100%" }}>
				<Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
					<Grid item xs={12}>
						<Grid container justify="center" alignItems="center">
							<Grid item>
								<ErrorIcon style={{ fontSize: 50, opacity: 0.5 }} />
							</Grid>
							<Grid item xs={12}>
								<Typography align="center" variant="h6">No Tickets Selected</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);
	}

	return (
		<Paper style={{ position: "relative", height: "100%", width: 350 }}>
			<Typography variant="h6" align="center" style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
				Selected Tickets
			</Typography>
			<div style={{ position: "absolute", top: "35px", bottom: "50px", left: 0, right: 0, overflowY: "auto" }}>
				<List>
					{tickets.map((ticket, i) => (
						<ListItem button key={i} onClick={() => setSelectedTicket(ticket)}>
							<ListItemText primary={ticket.customer.name} secondary={formatAddress(ticket.location)} />
						</ListItem>
					))}
				</List>
			</div>
			<Button variant="contained" color="primary" fullWidth style={{ height: 50, position: "absolute", bottom: 0, left: 0, right: 0 }}>
				Optimize
			</Button>
			<EditTicketDialog ticket={selectedTicket} onClose={() => setSelectedTicket(null)} onUpdated={(ticket) => setSelectedTicket(ticket)} />
		</Paper>
	);
}

function EditTicketDialog(props: { ticket: DriverTicket | null, onClose: () => void, onUpdated: (ticket: DriverTicket) => void
}) {
	const { ticket } = props;
	const updateTicket = useRouteBuilderStore((state: RouteBuilderState) => state.updateTicket);

	const update = (ticket: DriverTicket) => {
		props.onUpdated(ticket);
		updateTicket(ticket);
	}


	return (<Dialog open={ticket !== null} onClose={props.onClose} maxWidth="md" fullWidth>
		{ticket && <DriverTicketDetailView fullScreen ticket={ticket} updateTicket={update} onBack={props.onClose} />}
	</Dialog>)
}

function Footer() {
	const [mapType, setMapType] = React.useState("roadmap");
	const changeMapType = useBuilderPageStore((state: BuilderPageState) => state.setMapType);

	const handleMapTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as string;
		if(value !== "roadmap" && value !== "satellite") return;
		setMapType(value);
		changeMapType(value);
	};

	return (
		<Box height={100} padding={1}>
			<Grid container alignItems="center" style={{ height: "100%" }}>
				<Grid item>
					<Typography align="center" variant="overline">
						Map Options
					</Typography>
					<Grid container spacing={2}>
						<Grid item>
							<TextField select label="Map Type" variant="outlined" size="small" style={{width: 125}} value={mapType} onChange={handleMapTypeChange}>
								<MenuItem value="roadmap">Road</MenuItem>
								<MenuItem value="satellite">Satellite</MenuItem>
							</TextField>
						</Grid>
						<Grid item>
						<TextField select label="Visualization" variant="outlined" size="small" style={{width: 125}} value="age">
								<MenuItem value="age">Ticket Age</MenuItem>
							</TextField>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
