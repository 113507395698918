import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, TextField, Button, DialogActions, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { PhoneNumberLink } from "../../components/PhoneNumberLink";
import { RedText } from "../../components/RedText";
import { Customer } from "../../entities/customer/Customer";
import { PropaneTank } from "../../entities/customer/PropaneTank";
import { useAlert } from "../../hooks/useAlert";
import { CustomerService } from "../../services/customer/CustomerService";
import PersonIcon from "@material-ui/icons/Person";
import LocationIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import { PropaneTankService } from "../../services/customer/PropaneTankService";

interface Props {
	tank: PropaneTank;
	open: boolean;
	onClose: () => void;
	onTransfer: (tank: PropaneTank) => void;
}

export function TransferTankDialog(props: Props) {
	const { tank, open } = props;
	const alert = useAlert();

	const [customerCode, setCustomerCode] = useState("");
	const [customer, setCustomer] = useState<Customer | null>();
	const [disabled, setDisabled] = useState(false);

	const lookupCustomer = async () => {
		setCustomer(undefined);
		setDisabled(true);
		const result = await CustomerService.getByCode(customerCode.trim());
		setDisabled(false);
		if (result.success) {
			setCustomer(result.data);
		} else {
			alert.serverError(result);
		}
	};

	const transferTank = async () => {
		if (customer == null) return;
		setDisabled(true);
		const result = await PropaneTankService.transfer({propaneTankId: tank.id, customerId: customer.id});
		setDisabled(false);
		if (result.success) {
			props.onTransfer(result.data);
			alert.success("Tank transferred successfully");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const canSubmit = customer != null && !disabled && customer.id !== tank.customerId;

	return (
		<Dialog open={open} onClose={() => props.onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Transfer Tank</DialogTitle>
			<DialogContent>
				<DialogContentText>Transfer this tank and all of its history to another customer?</DialogContentText>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						lookupCustomer();
					}}
				>
					<Grid container>
						<Grid item>
							<TextField
								label="Customer Code"
								value={customerCode}
								onChange={(e) => setCustomerCode(e.target.value)}
								disabled={disabled}
								fullWidth
								style={{ maxWidth: 150 }}
								variant="outlined"
							/>
						</Grid>
						<Grid item>
							<Button type="submit" variant="contained" disabled={disabled || customerCode.trim() === ""} style={{ padding: 16 }}>
								Lookup Customer
							</Button>
						</Grid>
					</Grid>
				</form>
				<Box minHeight={"150px"} mt={2}>
					{customer === null && <RedText>Customer not found </RedText>}
					{customer && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item>
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<PersonIcon style={{ display: "block" }} />
											</Grid>
											<Grid item>
												<Typography>{customer.name}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container wrap="nowrap" alignItems="center" spacing={1}>
									<Grid item>
										<PhoneIcon style={{ display: "block" }} />
									</Grid>
									<Grid item>
										<Typography>
											{customer.phoneNumber ? <PhoneNumberLink phoneNumber={customer.phoneNumber} /> : "None"}{" "}
											{customer.phoneNumberSecondary && (
												<>
													<span style={{ paddingLeft: 5, paddingRight: 5 }}>or</span> <PhoneNumberLink phoneNumber={customer.phoneNumberSecondary} />
												</>
											)}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid container wrap="nowrap" alignItems="center" spacing={1}>
									<Grid item>
										<LocationIcon style={{ display: "block" }} />
									</Grid>
									<Grid item>
										<Typography>{customer.address.street}</Typography>
										<Typography>
											{customer.address.city}, {customer.address.state} {customer.address.postalCode}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.onClose()} color="secondary" variant="outlined" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={transferTank} color="primary" variant="contained" disabled={!canSubmit}>
					Transfer
				</Button>
			</DialogActions>
		</Dialog>
	);
}
