import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { User } from "../../../entities/auth/User";
import EmailIcon from "@material-ui/icons/Email";
import { FieldValidationError, validationError } from "../../../services/server/ServerValidationError";
import { BusinessService } from "../../../services/business/BusinessService";
import { UserClaim } from "../../../entities/auth/UserClaim";
import { useAlert } from "../../../hooks/useAlert";

interface Props {
	open: boolean;
	onClose: () => void;
	onUserAdded: (user: User) => void;
}

export function AddUserDialog(props: Props) {
	const alert = useAlert();

	const [disabled, setDisabled] = useState(false);
	const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");

	const [isOffice, setIsOffice] = useState(false);
	const [isDriver, setIsDriver] = useState(false);
	const [isAccounting, setIsAccounting] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const claims: UserClaim[] = [];

		if (isOffice) {
			claims.push(UserClaim.Office);
		}

		if (isDriver) {
			claims.push(UserClaim.Driver);
		}

		if (isAccounting) {
			claims.push(UserClaim.Accounting);
		}

		if (isAdmin) {
			claims.push(UserClaim.Admin);
		}

		setDisabled(true);
		const result = await BusinessService.inviteUser({ firstName, lastName, email, claims });
		setDisabled(false);

		if (result.success) {
			alert.success(`${result.data.fullName} has been emailed an invite`);
			props.onUserAdded(result.data);
		} else if (result.validation) {
			setValidationErrors(result.errors);
			const nonFieldErrors = validationError.getErrorsExcludingFields(result.errors, ["FirstName", "LastName", "Email", "Claims"]);
			if (nonFieldErrors.length > 0) {
				alert.validation({ errors: nonFieldErrors });
			}
		} else {
			alert.serverError(result);
		}
	};

	const onIsAdminChange = (isAdmin: boolean) => {
		if (isAdmin) {
			setIsOffice(false);
			setIsAccounting(false);
		}
		setIsAdmin(isAdmin);
	};

	const canSubmit = Boolean(firstName && lastName && email && (isOffice || isDriver || isAdmin));

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="md">
			<DialogTitle>Add User</DialogTitle>
			<form onSubmit={onSubmit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item lg={6} xs={12}>
							<TextField
								required
								fullWidth
								disabled={disabled}
								label="First Name"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "FirstName")}
								helperText={validationError.getFieldError(validationErrors, "FirstName")}
							/>
						</Grid>
						<Grid item lg={6} xs={12}>
							<TextField
								required
								fullWidth
								disabled={disabled}
								label="Last Name"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "LastName")}
								helperText={validationError.getFieldError(validationErrors, "LastName")}
							/>
						</Grid>
						<Grid item lg={6} xs={12}>
							<TextField
								required
								fullWidth
								disabled={disabled}
								label="Email"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								error={validationError.isFieldInError(validationErrors, "Email")}
								helperText={validationError.getFieldError(validationErrors, "Email")}
							/>
						</Grid>
						<Grid item lg={6} xs={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Roles</FormLabel>
								{validationError.isFieldInError(validationErrors, "Claims") && (
									<FormHelperText error>{validationError.getFieldError(validationErrors, "Claims")}</FormHelperText>
								)}
								<Tooltip title={<Typography>Can manage customers and orders</Typography>}>
									<FormControlLabel
										control={<Checkbox color="primary" checked={isOffice} disabled={isAdmin || disabled}  onChange={(e) => setIsOffice(e.target.checked)} />}
										label="Office"
									/>
								</Tooltip>
								<Tooltip title={<Typography>Can take payments and manage money. Can also do anything Office can do.</Typography>}>
									<FormControlLabel
										control={<Checkbox color="primary" checked={isAccounting} disabled={isAdmin || disabled}  onChange={(e) => setIsAccounting(e.target.checked)} />}
										label="Accounting"
									/>
								</Tooltip>
								<Tooltip title={<Typography>Is available as a driver that can handle orders</Typography>}>
									<FormControlLabel
										control={<Checkbox color="primary" checked={isDriver} disabled={disabled} onChange={(e) => setIsDriver(e.target.checked)} />}
										label="Driver"
									/>
								</Tooltip>
								<Tooltip title={<Typography>Has access to everything including users management and billing</Typography>}>
									<FormControlLabel
										control={<Checkbox color="primary" checked={isAdmin}  disabled={disabled}onChange={(e) => onIsAdminChange(e.target.checked)} />}
										label="Administrator"
									/>
								</Tooltip>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button disabled={disabled} onClick={props.onClose}>
						Cancel
					</Button>
					<Button disabled={!canSubmit || disabled} type="submit" endIcon={<EmailIcon />}>
						Email Invite
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
