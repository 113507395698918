import React from "react";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, TextField, Typography } from "@material-ui/core";
import { CustomerContractType, PriceLockContract } from "../../../entities/customer/CustomerContract";
import { BackButton } from "../../../components/BackButton";
import { GridGrow } from "../../../components/GridGrow";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { Price } from "../../../components/Price";
import { RedButton } from "../../../components/RedButton";
import { ContractService } from "../../../services/customer/ContractService";
import { useAlert } from "../../../hooks/useAlert";

export function PriceLockContractDetailView(props: { contract: PriceLockContract; onUpdate: (contract: PriceLockContract) => void }) {
	const { contract } = props;

	const { productCatalog } = useProductCatalog();
	const product = productCatalog.getProductListing(contract.productListingId);
	const productName = product ? product.name : "Unknown Product";
	const listPrice = product ? product.price : 0;

	return (
		<Grid container justify="center">
			<Grid item md={8} sm={10} xs={12}>
				<Card style={{ padding: 16 }}>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Grid container alignItems="center">
								<Grid item>
									<BackButton />
								</Grid>
								<Grid item>
									<Typography variant="h5">Price Lock Contract</Typography>
								</Grid>
								<GridGrow />
								<Grid item>
									<Typography variant="overline">Status: {contract.status}</Typography>
									<br />
									<Typography variant="overline">Created By: {contract.createdBy}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h3">Product</Typography>
							<Typography variant="body1">{productName}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h3">Price</Typography>
							<Grid container spacing={4}>
								<Grid item>
									<Typography variant="h6">Contract Price</Typography>
									<Typography variant="body1">
										<Price value={contract.price} />
									</Typography>
									<FormHelperText>
										Price locked in for the duration of the contract.
										<br />
										Current list price: <Price value={listPrice} />
									</FormHelperText>
								</Grid>
								{contract.maxQuantity && (
									<Grid item>
										<Typography variant="h6">Max Quantity</Typography>
										<Typography variant="body1">{contract.maxQuantity}</Typography>
										<Typography variant="h6">Remaining Quantity</Typography>
										<Typography variant="body1">{contract.quantityRemaining ?? 0}</Typography>
										<FormHelperText>Maximum quantity that can be purchased at the contract price.</FormHelperText>
									</Grid>
								)}
								<Grid item>
									<Typography variant="h6">Price Lock Mode</Typography>
									<Typography variant="body1">{contract.priceCeiling ? "Ceiling" : "Fixed"}</Typography>
									<FormHelperText>
										{contract.priceCeiling
											? "Price will not exceed the contract price."
											: "Price will always be the contract price, regardless of list price."}
									</FormHelperText>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h3">Duration</Typography>
							<Grid container spacing={4}>
								<Grid item>
									<Typography variant="h6">Start Date</Typography>
									<Typography variant="body1">{contract.startDate.toLocaleDateString()}</Typography>
									{contract.startDate > new Date() && <FormHelperText>Contract will not be active until this date.</FormHelperText>}
								</Grid>
								<Grid item>
									<Typography variant="h6">Expires</Typography>
									<Typography variant="body1">{contract.expiration?.toLocaleDateString() ?? "Never"}</Typography>
									{contract.expiration && contract.expiration < new Date() && <FormHelperText>Contract has expired.</FormHelperText>}
									{contract.expiration && contract.expiration > new Date() && <FormHelperText>Contract will end on this date.</FormHelperText>}
									{!contract.expiration && <FormHelperText>Contract will not expire.</FormHelperText>}
								</Grid>
							</Grid>
						</Grid>
						{(contract.status === "Active" || contract.status === "Pending") && (
							<CancelContractAction contract={contract} onUpdate={(contract) => props.onUpdate(contract)} />
						)}
						{contract.cancellation && (
							<Grid item xs={12}>
								<Typography variant="h3">Cancellation</Typography>
								<Grid container spacing={4}>
									<Grid item>
										<Typography variant="h6">Cancellation Date</Typography>
										<Typography variant="body1">{contract.cancellation.date.toLocaleDateString()}</Typography>
									</Grid>
									<Grid item>
										<Typography variant="h6">Reason</Typography>
										<Typography variant="body1">{contract.cancellation.reason.trim() === "" ? "None provided" : contract.cancellation.reason}</Typography>
									</Grid>
									<Grid item>
										<Typography variant="h6">Canceled By</Typography>
										<Typography variant="body1">{contract.cancellation.cancelledBy}</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Card>
			</Grid>
		</Grid>
	);
}

function CancelContractAction(props: { contract: PriceLockContract; onUpdate: (contract: PriceLockContract) => void }) {
	const alert = useAlert();
	const [showCancelDialog, setShowCancelDialog] = React.useState(false);
	const [disabled, setDisabled] = React.useState(false);
	const [reason, setReason] = React.useState("");

	const cancelContract = async () => {
		setDisabled(true);
		const result = await ContractService.cancel({ contractId: props.contract.id, reason });
		setDisabled(false);
		if (result.success) {
			if (result.data.type === CustomerContractType.PriceLock) {
				props.onUpdate(result.data);
			}
			alert.success("Contract canceled");
			setShowCancelDialog(false);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Grid item xs={12}>
			<RedButton size="large" onClick={() => setShowCancelDialog(true)}>
				Cancel Contract
			</RedButton>
			<Dialog open={showCancelDialog} onClose={() => setShowCancelDialog(false)} maxWidth="sm" fullWidth>
				<DialogTitle>Cancel Contract</DialogTitle>
				<DialogContent>
					<DialogContentText>This will cancel the contract and prevent it from being used with future orders.</DialogContentText>
					<TextField
						label="Reason"
						variant="outlined"
						value={reason}
						onChange={(e) => setReason(e.target.value)}
						fullWidth
						helperText="Record a reason for canceling this contract. (Optional)"
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="secondary" onClick={() => setShowCancelDialog(false)} disabled={disabled}>
						Cancel
					</Button>
					<Button variant="contained" color="primary" onClick={cancelContract} disabled={disabled}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}
