import { Box, Button, Card, Dialog, Divider, Grid, List, ListItem, ListItemText, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CenteredLoadingSpinner from "../../../components/CenteredLoadingSpinner";
import { GridGrow } from "../../../components/GridGrow";
import { TagBubble } from "../../../components/TagBubble";
import { DriverTicket } from "../../../entities/routing/DriverTicket";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";

import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { printDate } from "../../../utility/PrintDate";
import { TicketStatus } from "../../tickets/common/TicketStatus";
import { useCustomerDetailPage } from "../CustomerDetailPage";
import { NewCreateTicketPage } from "../new-ticket/NewCreateTicketPage";

export function CustomerTicketsCard() {
	const context = useCustomerDetailPage();
	const { customer, hideDeleteButton } = context;
	const alert = useAlert();
	const history = useHistory();
	const theme = useTheme();

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const showAddTicket = query.get("dialog") === "addTicket";

	const [tickets, setTickets] = useState<DriverTicket[]>();

	useEffect(() => {
		async function fetch() {
			const result = await DriverTicketService.getRecentCustomerTickets(customer.id);
			if (result.success) {
				setTickets(result.data);
				if (result.data.length > 0) hideDeleteButton();
			} else {
				alert.serverError(result);
			}
		}
		fetch();
	}, [alert, customer, hideDeleteButton]);

	const onSelectTicket = (ticket: DriverTicket) => {
		history.push(routes.app.resolve.ticketDetailPage(ticket.id));
	};
	const onViewAllTickets = () => {
		history.push(routes.app.resolve.ticketSearchPage({ customerCode: customer.customerCode }));
	};

	const onTicketAdded = (ticket: DriverTicket) => {
		if (tickets) setTickets([...tickets, ticket]);
		closeAddTicket();
	};

	const openAddTicket = () => {
		history.push(routes.app.resolve.customerDetailPage(customer.id) + "?dialog=addTicket");
	};

	const closeAddTicket = () => {
		history.replace(routes.app.resolve.customerDetailPage(customer.id));
	};

	return (
		<Card>
			<Dialog
				open={showAddTicket}
				fullScreen
				PaperProps={{
					style: {
						backgroundColor: theme.palette.background.default,
						boxShadow: "none",
						width: "100%",
					},
				}}
			>
				<NewCreateTicketPage onClose={closeAddTicket} onTicketAdded={onTicketAdded} />
			</Dialog>
			<Box paddingLeft={2} paddingTop={1} paddingRight={2}>
				<Grid container>
					<Grid item>
						<Typography variant="h6">Tickets</Typography>
					</Grid>
					<GridGrow />
					<Grid>
						<Button size="small" variant="outlined" onClick={onViewAllTickets}>
							View All Tickets
						</Button>
					</Grid>
				</Grid>
			</Box>
			{!tickets ? (
				<CenteredLoadingSpinner color="primary" />
			) : (
				<List dense style={{ paddingBottom: 0 }}>
					{tickets.length === 0 && (
						<ListItem>
							<ListItemText primary={`No open tickets`} />
						</ListItem>
					)}
					{tickets.map((ticket, index) => (
						<React.Fragment key={ticket.id}>
							{index !== 0 && <Divider component="li" />}
							<TicketListItem ticket={ticket} onSelect={onSelectTicket} />
						</React.Fragment>
					))}
				</List>
			)}

			<Button fullWidth size="small" variant="contained" color="primary" disabled={!tickets} onClick={openAddTicket} style={{ borderRadius: 0 }}>
				Add Ticket
			</Button>
		</Card>
	);
}

function TicketListItem(props: { ticket: DriverTicket; onSelect: (ticket: DriverTicket) => void }) {
	const { ticket } = props;

	const ticketDate = () => {
		const { created, timeOfDelivery } = ticket;
		if (timeOfDelivery) return `Delivered ${printDate.shortDate(timeOfDelivery, true)}`;
		return `Created ${printDate.shortDate(created, true)}`;
	};

	const secondaryText = () => {
		const { driverId, timeOfDelivery, timeOfCompletion, ticketNumber } = ticket;
		if (timeOfCompletion && ticketNumber) return `# ${ticketNumber}`;
		if (timeOfCompletion) return null;
		if (timeOfDelivery) return `Pending Review`;
		if (driverId) return `Pending Delivery`;
		return `Unassigned`;
	};

	return (
		<ListItem button onClick={() => props.onSelect(ticket)}>
			<ListItemText primary={<TicketStatus ticket={ticket} />} secondary={secondaryText()} />
			{ticket.tags.length > 0 && ticket.tags.map((tag) => <TagBubble tag={tag} key={tag.id} />)}
			<ListItemText style={{ textAlign: "right" }} secondary={ticketDate()} />
		</ListItem>
	);
}
