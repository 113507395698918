import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { DriverTicket } from "../../../entities/routing/DriverTicket";
import { useAlert } from "../../../hooks/useAlert";

import { DriverTicketService } from "../../../services/routing/DriverTicketService";

export function CancelTicketDialog(props: { open: boolean; onClose: () => void; onTicketCanceled: (ticket: DriverTicket) => void; ticket: DriverTicket }) {
	const { ticket } = props;

    const alert = useAlert();
    const [disabled, setDisabled] = useState(false);

    const onCancelTicket = async () => {
		setDisabled(true);
		const result = await DriverTicketService.cancelTicket(ticket.id);
		setDisabled(false);

		if (result.success) {
			props.onTicketCanceled(ticket)
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

    const onClose = ()  => {
        if(!disabled){
            props.onClose();
        }
    }
	
	return (
		<Dialog open={props.open} onClose={onClose}>
			<DialogTitle>Confirm Cancellation</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Cancelling an ticket will remove it entirely. This action is permanent, and cannot be reversed. Are you sure you want cancel the ticket for{" "}
					{ticket.customer.name}?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" disabled={disabled}>
					No
				</Button>
				<Button onClick={onCancelTicket} color="primary" disabled={disabled}>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
}