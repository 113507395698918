import React, { useMemo, useState } from "react";
import { useTicketDetail } from "./DriverTicketDetailView";
import { TicketQuoteType, TicketTankFill } from "../../../entities/routing/DriverTicket";
import {
	Box,
	Button,
	Card,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Popover,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { BoxIconButton } from "../../../components/BoxIconButton";
import { PercentTextField } from "../../../components/PercentTextField";
import { useTenant } from "../../../providers/TenantProvider";
import CloseIcon from "@material-ui/icons/Close";
import { CustomerTank, PropaneTankService } from "../../../services/customer/PropaneTankService";
import { useAlert } from "../../../hooks/useAlert";
import { GridGrow } from "../../../components/GridGrow";
import { Price } from "../../../components/Price";
import { printDate } from "../../../utility/PrintDate";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { toOptional } from "../../../utility/OptionalValue";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { ProductCategoryType } from "../../../entities/products/ProductCategoryType";
import { ProductListing } from "../../../entities/products/ProductListing";

export function TankFillDetails() {
	const alert = useAlert();
	const { ticket } = useTicketDetail();
	const customerId = ticket.customer.id;

	const [customerTanks, setCustomerTanks] = React.useState<CustomerTank[]>();
	const tankFills = ticket.quoteItems.filter((item) => item.type === TicketQuoteType.TankFill) as TicketTankFill[];

	const { productCatalog } = useProductCatalog();
	const propaneListing = useMemo(() => {
		const line = productCatalog.productLines.find((pl) => pl.type === ProductCategoryType.Propane);
		if (!line || line.availableListings.length === 0) return null;
		return line.availableListings[0];
	}, [productCatalog]);

	React.useEffect(() => {
		async function getCustomerTanks() {
			const result = await PropaneTankService.getCustomerTanks(customerId);
			if (result.success) {
				setCustomerTanks(result.data);
			} else {
				alert.serverError(result);
			}
		}

		getCustomerTanks();
	}, [customerId, alert]);

	if (!propaneListing) return null;

	return (
		<Box>
			<Typography variant="h6">Tank Fills:</Typography>
			{!customerTanks && <CircularProgress size="3rem" style={{ margin: 15 }} />}
			{customerTanks && (
				<Grid container spacing={1}>
					{tankFills.map((tankFill) => (
						<Grid item key={tankFill.id} sm={6} xs={12}>
							<TankFillCard tankFill={tankFill} tanks={customerTanks} />
						</Grid>
					))}
					<Grid item xs={12}>
						<AddTankFill tanks={customerTanks} propaneListing={propaneListing} />
					</Grid>
				</Grid>
			)}
		</Box>
	);
}

function AddTankFill(props: { tanks: CustomerTank[]; propaneListing: ProductListing }) {
	const { tanks } = props;
	const alert = useAlert();
	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const tankFills = ticket.quoteItems.filter((item) => item.type === TicketQuoteType.TankFill) as TicketTankFill[];
	const remainingTanks = tanks.filter((t) => !tankFills.some((f) => f.tank.id === t.id));

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (remainingTanks.length === 0) return;
		if (remainingTanks.length === 1) {
			addTankFill(remainingTanks[0]);
			return;
		}
		setAnchorEl(event.currentTarget);
	};

	const addTankFill = async (tank: CustomerTank) => {
		setAnchorEl(null);
		setDisabled(true);
		const result = await DriverTicketService.addTankFill({
			driverTicketId: ticket.id,
			tankId: tank.id,
			productListingId: props.propaneListing.id,
		});
		setDisabled(false);

		if (result.success) {
			updateTicket(result.data);
			alert.success("Tank fill added");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	if (isComplete) return null;

	return (
		<Box>
			<Button variant="outlined" color="primary" disabled={disabled || remainingTanks.length === 0} onClick={handleClick}>
				Add Tank Fill
			</Button>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<List>
					{remainingTanks.map((tank, i) => (
						<React.Fragment key={tank.id}>
							{i > 0 && <Divider />}
							<ListItem button onClick={() => addTankFill(tank)}>
								<ListItemText primary={`${tank.gallons} gal`} secondary={tank.description ?? undefined} />
							</ListItem>
						</React.Fragment>
					))}
				</List>
			</Popover>
		</Box>
	);
}

function TankFillCard(props: { tankFill: TicketTankFill; tanks: CustomerTank[] }) {
	const { tankFill } = props;
	const { tank } = tankFill;

	const alert = useAlert();

	const { ticket, updateTicket, disabled, setDisabled } = useTicketDetail();
	const isComplete = ticket.timeOfCompletion !== null;

	const { tenant } = useTenant();
	const { targetTankFillPercentage } = tenant;

	const [gasLevel, setGasLevel] = React.useState(tankFill.reportedTankPercentage);
	const totalUllage = useMemo(() => tank.gallons * targetTankFillPercentage, [tank.gallons, targetTankFillPercentage]);
	const estimatedUllage = useMemo(() => (gasLevel === null ? null : totalUllage - tank.gallons * gasLevel), [gasLevel, tank.gallons, totalUllage]);
	const lastThreeFills = props.tanks.find((t) => t.id === tank.id)?.lastThreeFills ?? [];

	const saveGasLevel = async () => {
		if (gasLevel === tankFill.reportedTankPercentage) return;

		setDisabled(true);
		const result = await DriverTicketService.updateTankFill({
			driverTicketId: ticket.id,
			quoteItemId: tankFill.id,
			reportedTankPercentage: toOptional(gasLevel),
		});
		setDisabled(false);

		if (result.success) {
			updateTicket(result.data);
			alert.success("Gas level updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onDelete = async () => {
		setDisabled(true);
		const result = await DriverTicketService.removeQuoteItem({
			driverTicketId: ticket.id,
			quoteItemId: tankFill.id,
		});
		setDisabled(false);

		if (result.success) {
			updateTicket(result.data);
			alert.success("Tank fill removed");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const cellStyle = { padding: "4px 10px" };

	return (
		<Card variant="outlined">
			<Box m={1}>
				<Grid container spacing={2}>
					<Grid item>
						<Box mb={1}>
							<Grid container>
								{!isComplete && (
									<Grid item>
										<Box mr={1}>
											<BoxIconButton color="secondary" onClick={onDelete} disabled={disabled}>
												<CloseIcon style={{ width: "0.70em", height: "0.70em" }} />
											</BoxIconButton>
										</Box>
									</Grid>
								)}
								<Grid item>
									<Typography>{tank.gallons} gal</Typography>
								</Grid>
							</Grid>
							{tank.description != null && <Typography variant="body2">{tank.description}</Typography>}
						</Box>
						{isComplete && tankFill.reportedTankPercentage != null && (
							<Typography variant="body2" color="textSecondary">
								Reported Level:
								<br />
								{tankFill.reportedTankPercentage * 100}%
							</Typography>
						)}

						{!isComplete && (
							<PercentTextField
								variant="outlined"
								size="small"
								label="Gas Level"
								value={gasLevel}
								fullWidth
								style={{ maxWidth: 100 }}
								onPercentChange={setGasLevel}
								disabled={disabled}
								helperText={estimatedUllage == null ? "" : `~${estimatedUllage.toFixed(2)} gal`}
								onBlur={saveGasLevel}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										saveGasLevel();
									}

									if (event.key === "Escape") {
										event.stopPropagation();
										setGasLevel(tankFill.reportedTankPercentage);
									}
								}}
							/>
						)}
					</Grid>
					{lastThreeFills.length > 0 && (
						<GridGrow>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>History</TableCell>
										<TableCell>Gal</TableCell>
										<TableCell>Price</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{lastThreeFills.map((fill, i) => (
										<TableRow key={i}>
											<TableCell style={cellStyle}>{printDate.shortDate(fill.timeOfFill)}</TableCell>
											<TableCell style={cellStyle}>{fill.amountFilled}</TableCell>
											<TableCell style={cellStyle}>{fill.price > 0 && <Price value={fill.price} decimalOptional />}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</GridGrow>
					)}
				</Grid>
			</Box>
		</Card>
	);
}
