import { Grid, Typography, Card, TableHead, TableRow, TableCell, TableBody, Table, IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import React from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../components/BackButton";
import { LinkButton } from "../../../components/LinkButton";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { Price } from "../../../components/Price";
import { CheckBatch } from "../../../entities/accounting/CheckBatch";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { CheckBatchService } from "../../../services/accounting/CheckBatchService";


export function CheckBatchDetailPage() {
	const alert = useAlert();
	const params = useParams<{ checkBatchId: string }>();

	const checkBatchId = params.checkBatchId;

	const [checkBatch, setCheckBatch] = React.useState<CheckBatch>();

	React.useEffect(() => {
		async function loadCheckBatch() {
			const result = await CheckBatchService.get(checkBatchId);
			if (result.success) {
				setCheckBatch(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadCheckBatch();
	}, [alert, checkBatchId]);

	const print = () => {
		if(!checkBatch) return;
		const printWindow = window.open("", "Print Check Batch", "width=800,height=600");
		if (printWindow) {
			printWindow.document.write(`
			<html>
				<head>
					<title>Print Check Batch</title>
					<style>
						@page {
							size: auto;
							margin: 5mm;
						}
						body {
							margin: 5mm;
						}
						table {
							width: 100%;
							border-collapse: collapse;
							page-break-inside: auto;
						}
						tr {
							page-break-inside: avoid;
							page-break-after: auto;
						}
						table, th, td {
							border: 1px solid black;
						}
						th, td {
							padding: 5px;
						}
						th {
							text-align: left;
						}
						thead {
							display: table-header-group;
						}
					</style>
				</head>
				<body>
					<h4>Check Batch ${checkBatch.batchNumber}</h4>
					<p>Created: ${checkBatch.created.toLocaleString()}</p>`);
			printWindow.document.write("<table>");
			printWindow.document.write("<thead>");
			printWindow.document.write("<tr>");
			printWindow.document.write("<th>Date</th>");
			printWindow.document.write("<th>Amount</th>");
			printWindow.document.write("<th>Check Number</th>");
			printWindow.document.write("</tr>");
			printWindow.document.write("</thead>");
			printWindow.document.write("<tbody>");
			checkBatch.checks.forEach((check) => {
				printWindow.document.write("<tr>");
				printWindow.document.write(`<td>${check.created.toLocaleDateString()}<br/>${check.created.toLocaleTimeString()}</td>`);
				printWindow.document.write(`<td>${check.amount.toFormat("$0,0.00")}</td>`);
				printWindow.document.write(`<td>${check.checkNumber ?? "None"}</td>`);
				printWindow.document.write("</tr>");
			}
			);
			printWindow.document.write("</tbody>");
			printWindow.document.write("<tfoot>");
			printWindow.document.write("<tr>");
			printWindow.document.write("<td>Checks:</td>");
			printWindow.document.write(`<td colspan="2">${checkBatch.checks.length}</td>`);
			printWindow.document.write("</tr>");
			printWindow.document.write("<tr>");
			printWindow.document.write("<td>Total:</td>");
			printWindow.document.write(`<td colspan="2">${checkBatch.totalAmount.toFormat("$0,0.00")}</td>`);
			printWindow.document.write("</tr>");
			printWindow.document.write("</tfoot>");
			printWindow.document.write("</table>");
			printWindow.document.write("</body>");
			printWindow.document.write("</html>");
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}
	};

	if (!checkBatch) return <OverlayLoadingScreen />;

	return (
		<>
			<PageTitle title={`Check Batch ${checkBatch.batchNumber}`} />
			<Grid container>
				<Grid item>
					<BackButton />
				</Grid>
				<Grid item>
					<Typography variant="h2">Check Batch {checkBatch.batchNumber}</Typography>
					<Typography variant="overline" gutterBottom>
						Created: {checkBatch.created.toLocaleString()}
					</Typography>
				</Grid>
				<Grid item>
					<IconButton onClick={print}>
						<PrintIcon />
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xl={6} lg={7} md={8} sm={10} xs={12}>
							<Card>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Amount</TableCell>
											<TableCell>Check Number</TableCell>
											<TableCell />
										</TableRow>
									</TableHead>
									<TableBody>
										{checkBatch.checks.length === 0 && (
											<TableRow>
												<TableCell colSpan={4} style={{ textAlign: "center" }}>
													No checks found.
												</TableCell>
											</TableRow>
										)}
										{checkBatch.checks.map((check) => (
											<TableRow key={check.id}>
												<TableCell style={{ fontSize: 15 }}>{check.created.toLocaleDateString()}<br/>{check.created.toLocaleTimeString()}</TableCell>
												<TableCell style={{ fontSize: 20 }}>
													<Price value={check.amount} />
												</TableCell>
												<TableCell style={{ fontSize: 20 }}>{check.checkNumber ?? "None"}</TableCell>
												<TableCell>
													<LinkButton to={routes.app.resolve.paymentDetailPage(check.id)} newTab>
														See Details
													</LinkButton>
												</TableCell>
											</TableRow>
										))}
										<TableRow>
											<TableCell style={{ textAlign: "right" }}>Checks:</TableCell>
											<TableCell colSpan={4} style={{ fontSize: 25 }}>
												{checkBatch.checkCount}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell style={{ textAlign: "right" }}>Batch Amount:</TableCell>
											<TableCell colSpan={4} style={{ fontSize: 25 }}>
												<Price value={checkBatch.totalAmount} />
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}
