import React, { useState } from "react";
import { Button, Grid, Hidden, makeStyles, Paper, TextField } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import AuthService from "../../services/auth/AuthService";

import { validationError } from "../../services/server/ServerValidationError";
import { ClientLink } from "../../components/ClientLink";
import { routes } from "../../routes";
import { GridGrow } from "../../components/GridGrow";
import { useAlert } from "../../hooks/useAlert";
import { apiServer } from "../../services/server/Setting";

export const useSignInStyles = makeStyles(() => ({
	signInPaper: {
		paddingTop: 15,
		paddingBottom: 30,
		paddingLeft: 30,
		paddingRight: 30,
		margin: 8,
		maxWidth: 450,
	},
}));

export function SignIn() {
	const classes = useSignInStyles();
	const location = useLocation();
	const alert = useAlert();
	const [enabled, setEnabled] = useState(true);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState<string | undefined>();
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState<string | undefined>();

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const urlParams = new URLSearchParams(location.search);
		const ignoreRefreshToken = urlParams.get("ignoreRefreshToken") != null;
		setEnabled(false);
		const result = await AuthService.signIn({
			email,
			password,
			ignoreRefreshToken,
		});

		if (result.success) {
			const serverRedirect = urlParams.get("serverRedirect");
			if (serverRedirect) {
				const decodedPath = decodeURIComponent(serverRedirect);
				const redirectUrl = `${apiServer}${decodedPath}`;
				window.location.replace(redirectUrl);
			} else {
				let redirect = "/app"
				const clientRedirect = urlParams.get("redirect");
				if (clientRedirect) {
					redirect = decodeURIComponent(clientRedirect);
				}
				window.location.replace(redirect);
			}
		} else if (result.validation) {
			setPasswordError(validationError.getFieldError(result.errors, "Password"));
			setEmailError(validationError.getFieldError(result.errors, "Email"));
			setEnabled(true);
		} else {
			alert.serverError(result);
			setEnabled(true);
		}
	};

	const onChangeEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setEmailError(undefined);
		setEmail(event.target.value);
	};

	const onChangePassword = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setPasswordError(undefined);
		setPassword(event.target.value);
	};

	return (
		<Grid style={{ height: "100vh" }} container direction="column" justify="center" alignItems="center">
			<Grid item>
				<Paper className={classes.signInPaper}>
					<Grid container direction="column" justify="center" alignItems="center">
						<div style={{ maxWidth: 350 }}>
							<img alt="Dispatch Pro" src="/images/logo/full/Logo280x1080.png" style={{ maxWidth: "100%", height: "auto", marginBottom: 15 }} />
						</div>
					</Grid>
					<form onSubmit={onSubmit}>
						<TextField
							label="Email"
							id="login-email"
							value={email}
							onChange={onChangeEmail}
							error={emailError != null}
							helperText={emailError}
							margin="normal"
							fullWidth
							required
							type="email"
							disabled={!enabled}
						/>
						<TextField
							label="Password"
							id="login-password"
							value={password}
							onChange={onChangePassword}
							error={passwordError != null}
							helperText={passwordError}
							margin="normal"
							fullWidth
							required
							type="password"
							disabled={!enabled}
						/>
						<ClientLink to={routes.public.forgotPasswordPage} style={{ fontWeight: 500 }}>
							Forgot Password?
						</ClientLink>
						<Hidden smDown>
							<Grid container direction="row" justify="space-between" alignItems="center" style={{ paddingTop: 30 }}>
								<Button id="login-button" variant="contained" size="medium" color="primary" type="submit" disabled={!enabled} style={{ minWidth: 150 }}>
									Sign In
								</Button>
							</Grid>
						</Hidden>
						<Hidden mdUp>
							<Button id="login-button" fullWidth variant="contained" size="medium" color="primary" type="submit" disabled={!enabled} style={{ marginTop: 20 }}>
								Sign In
							</Button>
						</Hidden>
					</form>
					<Grid container style={{ marginTop: 30 }}>
						<Grid item>
							<ClientLink to={routes.public.termsOfUse}>Terms of Use</ClientLink>
						</Grid>
						<GridGrow />
						<Grid item>
							<ClientLink to={routes.public.privacyPolicy}>Privacy Policy</ClientLink>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
}

export default SignIn;
