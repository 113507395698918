import { GoogleMap } from "../../utility/GoogleMap";

export interface GeocodedAddress {
	googleMapsPlaceId?: string;
	street: string;
	city: string;
	state: string;
	postalCode: string;
	county: string;
	latitude: number;
	longitude: number;
}

export function formatAddress(address: GeocodedAddress) {
	const { street, city, state, postalCode } = address;
	return `${street} ${city}, ${state} ${postalCode}`;
}

export function navigateToAddressLink(address: GeocodedAddress){
	const formattedAddress = formatAddress(address);
	const { latitude, longitude } = address;
	if(address.googleMapsPlaceId){
		return GoogleMap.generateNavigationUrl.fromPlaceId(formattedAddress, address.googleMapsPlaceId);
	}
	if(latitude && longitude){
		return GoogleMap.generateNavigationUrl.fromCoordinates(latitude, longitude);
	}

	return GoogleMap.generateNavigationUrl.fromAddress(formattedAddress);
}