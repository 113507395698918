import { Modify } from "../../utility/modifyType";

export interface PaymentReversalTransaction{
    id: string;
    reversedPaymentId: string;
    created: Date;
    description: string;
    balanceChange: number;
    refundTransactionId: string | null;
    paymentProcessor: string | null;
}

type SerializedPaymentReversalTransaction = Modify<PaymentReversalTransaction, {
    created: string;
}>;

export function deserializePaymentReversalTransaction(model: SerializedPaymentReversalTransaction): PaymentReversalTransaction {
    return {
        ...model,
        created: new Date(model.created)
    };
}