import React from "react";
import { Button, ButtonProps, makeStyles, Theme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

export function RedButton(props: React.PropsWithChildren<ButtonProps>) {
    const classes = useStyles();

	const getClass = () => {
		switch (props.variant) {
			case "contained":
				return classes.contained;
			case "outlined":
				return classes.outlined;
			case "text":
				return classes.text;
			default:
				return classes.contained;
		}
	}


	return (<Button {...props} className={getClass()} variant={props.variant ?? "contained"}>{props.children}</Button>);
}

const useStyles = makeStyles((theme: Theme) => ({
	contained: {
		backgroundColor: theme.palette.error.main,
		color: "#fff",
		"&:hover, &.Mui-focusVisible": { backgroundColor: red[700] }
	},
	outlined: {
		borderColor: theme.palette.error.main,
		color: theme.palette.error.main,
		"&:hover, &.Mui-focusVisible": { backgroundColor: red[700], color: "#fff" }
	},
	text: {
		color: theme.palette.error.main,
	}

}));