import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { appVersion } from "../appVersion";
import { FieldValidationError } from "../services/server/ServerValidationError";
import { WebClient } from "../services/server/WebClient";
import { useOfflineStatus } from "./OfflineStatusProvider";

export type OffineRequest = {
	id: string;
	name: string;
	url: string;
	method: "POST" | "PUT";
	payload: unknown;
};

type EnqueuedOffineRequest = OffineRequest & {
	date: string;
	attempts: number;
};

type ServerErrorOffineResult = EnqueuedOffineRequest & {
	message: string;
	statusCode: number;
};

type ValidationErrorOffineResult = EnqueuedOffineRequest & {
	errors: FieldValidationError[];
};

export interface OfflineRequestManager {
	pendingRequests: OffineRequest[];
	serverErrorResults: ServerErrorOffineResult[];
	validationResults: ValidationErrorOffineResult[];
	addRequestToQueue: (request: OffineRequest) => void;
	dismissRequest: (request: OffineRequest) => void;
}

const OffineRequestManagerReactContext = createContext<OfflineRequestManager>({} as OfflineRequestManager);

export function useOfflineRequestManager() {
	return useContext(OffineRequestManagerReactContext);
}

const pendingRequestsStorageKey = "pending-offline-requests";
const serverErrorResultsStorageKey = "server-error-offline-results";
const validationResultsStorageKey = "validation-offline-results";

const maxAttempts = 3;

export function OfflineRequestManagerProvider(props: PropsWithChildren<{}>) {
	const isOffline = useOfflineStatus();

	const [pendingRequests, setPendingRequests] = useState<EnqueuedOffineRequest[]>(() => {
		try {
			const storedOfflineRequests = window.localStorage.getItem(pendingRequestsStorageKey);
			return storedOfflineRequests != null ? JSON.parse(storedOfflineRequests) : [];
		} catch (e) {
			console.error(e);
			return [];
		}
	});
	const [serverErrorResults, setServerErrorResults] = useState<ServerErrorOffineResult[]>(() => {
		try {
			const storedErrorResults = window.localStorage.getItem(serverErrorResultsStorageKey);
			return storedErrorResults != null ? JSON.parse(storedErrorResults) : [];
		} catch (e) {
			console.error(e);
			return [];
		}
	});
	const [validationResults, setValidationResults] = useState<ValidationErrorOffineResult[]>(() => {
		try {
			const storedValidationResults = window.localStorage.getItem(validationResultsStorageKey);
			return storedValidationResults != null ? JSON.parse(storedValidationResults) : [];
		} catch (e) {
			console.error(e);
			return [];
		}
	});

	const [inProgressRequest, setInProgressRequest] = useState<EnqueuedOffineRequest>();

	// A bit blunt, but should keep our stored localstorage schema from drifting or blowing up over time
	const [version] = useState(() => {
		const schemaVersion = window.localStorage.getItem("schema-version");
		if (schemaVersion == null) window.localStorage.setItem("schema-version", appVersion);
		return schemaVersion ?? appVersion;
	});
	useEffect(() => {
		if (version !== appVersion && !isOffline && pendingRequests.length === 0) {
			console.log("Clearing the cache...");
			// ignore offline error results from clear since we can't be certain if they've been handled yet
			const ignoreKeys = [serverErrorResultsStorageKey, validationResultsStorageKey, "dispatch-pro-auth-token"];
			Object.keys(localStorage)
				.filter((key) => !ignoreKeys.includes(key))
				.forEach((key) => localStorage.removeItem(key));
			// To ensure we properly reload the data, we'll hard refresh
			window.location.reload();
		}
	}, [isOffline, pendingRequests, version]);

	useEffect(() => {
		window.localStorage.setItem(pendingRequestsStorageKey, JSON.stringify(pendingRequests));
	}, [pendingRequests]);
	useEffect(() => {
		window.localStorage.setItem(serverErrorResultsStorageKey, JSON.stringify(serverErrorResults));
	}, [serverErrorResults]);
	useEffect(() => {
		window.localStorage.setItem(validationResultsStorageKey, JSON.stringify(validationResults));
	}, [validationResults]);

	// Processes pendingRequests 1 at a time when not offline
	useEffect(() => {
		if (pendingRequests.length > 0 && !isOffline && !inProgressRequest) {
			const request = pendingRequests[0];
			setInProgressRequest(request);

			console.log("setInProgressRequest", request);
			const apiRequest = request.method === "POST" ? WebClient.Post.Validated(request.url, request.payload) : WebClient.Put.Validated(request.url, request.payload);
			apiRequest
				.then((result) => {
					console.log("InProgressRequest - result", result);
					if (result.success) {
						// Clear from queue on success
						setPendingRequests((r) => r.filter((r) => r.id !== request.id));
					} else {
						if (request.attempts + 1 < maxAttempts) {
							// Increment attempts if it hasn't hit it's retry limit
							setPendingRequests((r) => r.map((r) => (r.id !== request.id ? r : { ...request, attempts: request.attempts + 1 })));
						} else {
							// Too many failures, so it should be cleared from pending and moved to appropriate failure category
							setPendingRequests((r) => r.filter((r) => r.id !== request.id));
							if (result.validation) {
								setValidationResults((r) => [...r, { ...request, errors: result.errors }]);
							} else {
								setServerErrorResults((r) => [...r, { ...request, statusCode: result.statusCode, message: result.message }]);
							}
						}
					}
				})
				.catch((error) => {
					console.log("InProgressRequest - app error", error);
					setServerErrorResults((r) => [...r, { ...request, statusCode: 999, message: error }]);
				})
				.finally(() => setInProgressRequest(undefined));
		}
	}, [pendingRequests, isOffline, inProgressRequest]);

	const addRequestToQueue = (request: OffineRequest) => {
		const enqueuedOffineRequest: EnqueuedOffineRequest = {
			...request,
			date: new Date().toString(),
			attempts: 0,
		};

		console.log("addRequestToQueue", enqueuedOffineRequest);

		// We avoid duplicates by removing any previous requests with the same id before adding.
		// This also effictively makes this a retry as well
		setPendingRequests((requests) => [...requests.filter((r) => r.id !== request.id), enqueuedOffineRequest]);
		setServerErrorResults((requests) => [...requests.filter((r) => r.id !== request.id)]);
		setValidationResults((requests) => [...requests.filter((r) => r.id !== request.id)]);
	};

	const dismissRequest = (request: OffineRequest) => {
		setPendingRequests((requests) => [...requests.filter((r) => r.id !== request.id)]);
		setServerErrorResults((requests) => [...requests.filter((r) => r.id !== request.id)]);
		setValidationResults((requests) => [...requests.filter((r) => r.id !== request.id)]);
	};

	return (
		<OffineRequestManagerReactContext.Provider
			value={{
				pendingRequests,
				serverErrorResults,
				validationResults,
				addRequestToQueue,
				dismissRequest,
			}}
		>
			{props.children}
		</OffineRequestManagerReactContext.Provider>
	);
}
