import { ListViewProps } from "./ListView";
import { ListViewConfig } from "./ListViewConfig";

export type ListViewState<TData, TTableData> = {
	search: string;
	selectedTabNumber: number | undefined;
	listViewConfig: ListViewConfig<TData, TTableData>;
	baseData: TData;
	displayedData: TTableData[];
};

const displayedDataReducer = <TData, TTableData>(state: ListViewState<TData, TTableData>): TTableData[] => {
	const {listViewConfig, baseData, selectedTabNumber, search} = state;
	const filteredData = listViewConfig.filter(baseData, selectedTabNumber);
	const searchedData = listViewConfig.search(filteredData, search);
	const sortedData = listViewConfig.sort(searchedData, selectedTabNumber);
	return sortedData;
} 

export type ListViewAction<TData> =
	| { type: "base_data_changed"; baseData: TData }
	| { type: "search_changed"; query: string }
	| { type: "tab_changed"; tabNumber: number };

export function ListViewReducer<TData, TTableData>(state: ListViewState<TData, TTableData>, action: ListViewAction<TData>): ListViewState<TData, TTableData> {
	const updatedState: ListViewState<TData, TTableData> = {...state}; 
	switch (action.type) {
		case "base_data_changed":
			updatedState.baseData = action.baseData;
			break;
		case "search_changed":
			updatedState.search = action.query;
			break;
		case "tab_changed":
			updatedState.selectedTabNumber = action.tabNumber;
			break;
	}

	updatedState.displayedData = displayedDataReducer(updatedState);
	return updatedState;
}

export const getListViewReducerInitState = <TData, TTableData>(props: ListViewProps<TData, TTableData>) => {
	const initState: ListViewState<TData, TTableData> = {
		baseData: props.tableConfig.data,
		displayedData: [],
		listViewConfig: props.listViewConfig, 
		search: "",
		selectedTabNumber: undefined
	};
	initState.displayedData = displayedDataReducer(initState);
	return initState;
}