import React, { useMemo } from "react";
import { UserClaim } from "../entities/auth/UserClaim";
import { useAppUser } from "../providers/AppUserProvider";


interface Props {
    office?: boolean;
    driver?: boolean;
    admin?: boolean;
    accounting?: boolean;
    superAdmin?: boolean;
    unauthorized?: React.ReactNode;
}

export function RestrictToRole(props: React.PropsWithChildren<Props>){
    const user = useAppUser();
    const { userClaims } = user;

    const isSuperAdmin = useMemo(() => userClaims.some(c => c === UserClaim.Super_Admin), [userClaims]);
    const isAdmin = useMemo(() => userClaims.some(c => c === UserClaim.Admin || c === UserClaim.Super_Admin), [userClaims]);
    const isOffice = useMemo( () => userClaims.some(c => c === UserClaim.Office), [userClaims]);
    const isAccounting = useMemo( () => userClaims.some(c => c === UserClaim.Accounting), [userClaims]);
    const isDriver = useMemo( () => userClaims.some(c => c === UserClaim.Driver), [userClaims]);

    if(props.superAdmin){
        return isSuperAdmin ? <>{props.children}</> : null;
    }

    if(props.admin && isAdmin){
        return <>{props.children}</>;
    }

    const canSeeAccounting = isAdmin || isAccounting;
    if(props.accounting && canSeeAccounting){
        return <>{props.children}</>;
    }

    const canSeeOffice = isAdmin || isOffice || isAccounting;
    if(props.office && canSeeOffice){
        return <>{props.children}</>;
    }

    const canSeeDriver = isAdmin || isDriver;
    if(props.driver && canSeeDriver){
        return <>{props.children}</>;
    }

    if(props.unauthorized){
        return <>{props.unauthorized}</>;
    }

    return null;
}