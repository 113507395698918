import { Box, Button, FormHelperText, Typography } from "@material-ui/core";
import React from "react";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useTicketDetail } from "./DriverTicketDetailView";
import { printDate } from "../../../utility/PrintDate";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useAlert } from "../../../hooks/useAlert";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export function TicketDeliveryDate() {
	const alert = useAlert();
	const context = useTicketDetail();
	const { ticket, disabled, setDisabled, updateTicket } = context;

	const [editMode, setEditMode] = React.useState(false);
	const [deliveryDate, setDeliveryDate] = React.useState<MaterialUiPickersDate>(ticket.timeOfDelivery);

	const updateDeliveryDate = async (timeOfDelivery: Date | null) => {
		setDisabled(true);
		const result = await DriverTicketService.update({ driverTicketId: ticket.id, timeOfDelivery });
		setDisabled(false);

		if (result.success) {
			updateTicket(result.data);
			alert.success("Delivery date updated");
			setEditMode(false);
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	if (ticket.timeOfCompletion) {
		return (
			<Box>
				<Typography variant="h6">
					Delivery Date:
				</Typography>
				<Typography variant="h6">{ticket.timeOfDelivery?.toLocaleDateString() ?? "Pending"}</Typography>
				<Typography color="textSecondary" variant="body1">
					Reviewed {printDate.shortDate(ticket.timeOfCompletion)}
				</Typography>
			</Box>
		);
	}

	return (
		<Box>
			<Button
				color="primary"
				disabled={disabled}
				variant="text"
				style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }}
				onClick={() => setEditMode(true)}
			>
				Delivery Date:
			</Button>
			{editMode && (
				<>
					<KeyboardDatePicker
						disableToolbar
						disabled={disabled}
						variant="inline"
						format="MM/dd/yy"
						placeholder="MM/DD/YY"
						label="Delivery Date"
						inputVariant="outlined"
						value={ticket.timeOfDelivery}
						onChange={(date) => {
							setDeliveryDate(date);
							if (date != null && date.toString() !== "Invalid Date") {
								updateDeliveryDate(date);
							}
						}}
						onKeyDown={(event) => {
							if (event.key === "Backspace" && deliveryDate === null) {
								updateDeliveryDate(null);
							}

							if (event.key === "Escape") {
								event.stopPropagation();
								setEditMode(false);
							}
						}}
						autoFocus
						size="small"
						inputProps={{
							onFocus: (e) => e.target.select(),
						}}
					/>
					<FormHelperText>Date ticket was delivered</FormHelperText>
				</>
			)}
			{!editMode && (
				<Typography variant="h6" color={ticket.timeOfDelivery ? undefined : "textSecondary"}>
					{ticket.timeOfDelivery?.toLocaleDateString() ?? "Pending"}
				</Typography>
			)}
		</Box>
	);
}
