
export interface VehicleInspectionItem {
    name: string;
    satisfactory: boolean;
    notes: string | null;
}

export interface VehicleInspection {
    id: string;
    truckId: string;
    truckName: string;
    userId: string;
    inspectedBy: string;
    inspectionListName: string;
    createdAt: Date;
    notes: string | null;
    satisfactory: boolean;
    items: VehicleInspectionItem[];
}

export type SerializedVehicleInspection = Omit<VehicleInspection, 'createdAt'> & { createdAt: string };

export function deserializeVehicleInspection(serialized: SerializedVehicleInspection): VehicleInspection {
    return {
        ...serialized,
        createdAt: new Date(serialized.createdAt),
    };
}