import { Button, Typography } from "@material-ui/core";
import React from "react";
import { CreditCardPaymentMethod } from "../../entities/billing/PaymentMethod";
import { Customer } from "../../entities/customer/Customer";
import { useAppUser } from "../../providers/AppUserProvider";
import { QualpayCreditCardInput } from "./QualpayCreditCardInput";
import { DispatchProCardInput } from "./DispatchProCardInput";

interface CreditCardInputProps {
	customer: Customer;
	onClose: () => void;
	onCreate: (creditCard: CreditCardPaymentMethod) => void;
}

export function CreditCardInput(props: CreditCardInputProps) {
	const { customer } = props;
	const user = useAppUser();
	if (user.paymentProcessor === "qualpay") {
		return <QualpayCreditCardInput customer={customer} onClose={props.onClose} onCreate={props.onCreate} />;
	}
	if (user.paymentProcessor === "dispatchpro") {
		return <DispatchProCardInput customer={customer} onClose={props.onClose} onCreate={props.onCreate} />;
	}
	return (
		<>
			<Typography>Credit Card payment has not been enabled on this account</Typography>
			<Button variant="outlined" color="secondary" onClick={props.onClose}>
				Cancel
			</Button>
		</>
	);
}
