import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { NotFoundResult, ServerError, SuccessResult, ValidationError, createServerError } from "./WebClient";
import { apiServer } from "./Setting";
import Auth from "../../authorization/Auth";

const createOfflineServerError = (message: string): ServerError => ({ message, statusCode: 0, type: "error", error: true, success: false, validation: false, offline: true });

const serializeAxiosError = (axiosError: AxiosError): ServerError | ValidationError | NotFoundResult => {
	if (axiosError.code === "ERR_NETWORK" || axiosError.response === undefined) {
		return createOfflineServerError("Cannot reach DispatchPro. Please try again.");
	}
	const response = axiosError.response;

	if (response.status === 404) {
		return createServerError("Request was submitted to the incorrect location.", 404);
	}
	if (response.status === 405) {
		return createServerError("Request was submitted with an incorrect method.", 405);
	}
	if (response.status === 401) {
		return createServerError("Sign in required", 401);
	}
	if (response.data === undefined) {
		return createServerError("Unknown error", 500);
	}

	const data = response.data as ServerError & { status: number } | ValidationError 
	if ("errors" in data) {
		const validationResult: ValidationError = {
			type: "validation",
			errors: data.errors,
			error: false,
			success: false,
			validation: true,
		};
		return validationResult;
	}

	const errorMessage = data.message ?? axiosError.response?.headers["x-error-result"] ?? "Unknown error";
	if(data.status === 404){
		return {
			type: "missing",
			message: errorMessage,
			statusCode: 404,
			error: true,
			success: false,
			validation: false,
			offline: false,
		}
	}

	return createServerError(errorMessage, response.status);
};

const onFulfilled = (response: AxiosResponse) => {
	const success: SuccessResult<unknown> = {
		type: "success",
		data: response.data,
		error: false,
		success: true,
		validation: false,
	};
	return { ...response, data: success };
};

interface onRejectedInstance {
	axiosInstance: AxiosInstance;
	interceptorId: number;
}

const onRejected = async (error: Error | AxiosError<ServerError | ValidationError>, instance: onRejectedInstance) => {
	if (!axios.isAxiosError(error)) {
		return Promise.reject(error);
	}

	const response = error.response;
	if (!response || !response.headers || response.headers["x-refresh-jwt"] !== "true") {
		return Promise.resolve({ ...error, data: serializeAxiosError(error) });
	}

	instance.axiosInstance.interceptors.response.eject(instance.interceptorId); // Remove the interceptor to avoid a loop

	try {
		console.log("Refreshing User");
		const refreshInstance = axios.create();
		const response = await refreshInstance.get(`${apiServer}/api/auth/refresh`, { withCredentials: true });
		if (response.status === 200) {
			console.log("User Refreshed");
			const token = response.data.jwt;
			Auth.setToken(token);
			if (error.config && error.config.headers) {
				error.config.headers.Authorization = `Bearer ${token}`; // Set the new token in the request headers
			}
			const retryResult = await refreshInstance.request(error.config); // Retry the request

			if (retryResult.status === 200) {
				return onFulfilled(retryResult);
			}
		} else {
			console.log("User Refresh Failed. Signing Out");
			console.log(response);
			// AuthService.signOut(true);
			return Promise.resolve({ ...error, data: createServerError("User session needs refresh. Please logout and back in.", 500) });
		}
	} catch (error) {
		console.log("JWT Refresh Failed");
		console.log(error);
		// AuthService.signOut(true);
		return Promise.resolve({ error: true, success: false, validation: false, data: createServerError("User session needs refresh. Please logout and back in.", 500) });
	}

	return Promise.resolve({ ...error, data: serializeAxiosError(error) });
};

const createAxiosWithInterceptors = () => {
	const instance = axios.create();

	const onRejectedInstance: onRejectedInstance = {
		axiosInstance: instance,
		interceptorId: 0,
	};

	const interceptorId = instance.interceptors.response.use(onFulfilled, (error) => onRejected(error, onRejectedInstance));

	onRejectedInstance.interceptorId = interceptorId;

	return instance;
};

export const CustomAxios = {
	create: () => createAxiosWithInterceptors(),
};
