import { Box, Typography, List, ListItem, ListItemText, Button } from "@material-ui/core";
import React, { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { routes } from "../../../../routes";
import { RedText } from "../../../../components/RedText";
import { Price } from "../../../../components/Price";
import { orderBy } from "../../../../utility/orderBy";
import { SearchableInvoice } from "../../../../entities/billing/Invoice";
import { useCustomerPaymentCardContext } from "./CustomerPaymentCard";

export function InvoicesSection() {
	const history = useHistory();
	const context = useCustomerPaymentCardContext();
	const { invoices, disabled } = context;

	const displayInvoices = useMemo(() => {
		if (!invoices) return [];
		const orderedInvoices = invoices.sort(orderBy.date((i) => i.issuedOn, "Descending"));
		const openInvoices = orderedInvoices.filter((i) => !i.closed);
		const invoicesThatCanStillFit = 3 - openInvoices.length;
		if (invoicesThatCanStillFit <= 0) return openInvoices;
		return openInvoices.concat(orderedInvoices.filter((i) => i.closed).slice(0, invoicesThatCanStillFit)).sort(orderBy.date((i) => i.issuedOn, "Descending"));
	}, [invoices]);


	const primaryText = (invoice: SearchableInvoice) => {
		if (invoice.closed) {
			return (
				<>
					{invoice.shortId} - <Price decimalOptional value={invoice.total} />
				</>
			);
		}

		return (
			<>
				{invoice.shortId} - Owes <Price decimalOptional value={invoice.balance} />
				{!invoice.balance.equalsTo(invoice.total) && (
					<>
						{" "}
						of <Price decimalOptional value={invoice.total} />
					</>
				)}
			</>
		);
	};

	const secondaryText = (invoice: SearchableInvoice) => {
		if (invoice.closed) {
			return null;
		}

		if(invoice.overdue)
			return <RedText>{invoice.status}</RedText>;

		return <>Due by {invoice.dueBy.toLocaleDateString()}</>;
	};

	const titleText = () => {
		if (!invoices) return "Loading Invoices...";
		if (invoices && invoices.length === 0) return "No Invoices Yet";
		return (
			<>
				Recent Invoices
			</>
		);
	};

	const viewAllInvoices = () => history.push(routes.app.resolve.invoiceSearchPage({customerCode: context.customer.customerCode ?? undefined}));
	const viewPayment = (invoice: SearchableInvoice) => history.push(routes.app.resolve.invoiceDetailPage(invoice.id));

	return (
		<>
			<Box pl={1}>
				<Typography variant="overline">{titleText()}</Typography>
			</Box>
			<List dense style={{ padding: 0 }}>
				{displayInvoices.map((invoice) => (
					<ListItem button key={invoice.id} onClick={() => viewPayment(invoice)} disabled={disabled}>
						<ListItemText primary={primaryText(invoice)} secondary={secondaryText(invoice)} />
						<ListItemText secondary={invoice.issuedOn.toLocaleDateString()} secondaryTypographyProps={{ align: "right" }} />
					</ListItem>
				))}
			</List>
			<Button variant="outlined" size="small" style={{marginLeft: 15}} disabled={disabled} onClick={viewAllInvoices}>
					View All Invoices
				</Button>
		</>
	);
}