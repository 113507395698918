import React from "react";
import { SearchableInvoice } from "../../../entities/billing/Invoice";
import { orderBy } from "../../../utility/orderBy";
import { HighlightedText } from "../../HighlightedText";
import { ResponsiveTableColumn } from "../../ResponsiveTable";
import { ListViewConfig } from "../ListViewConfig";
import { Price } from "../../Price";


type InvoiceDataType = {
	invoices: SearchableInvoice[];
};

const tableColumns = (_data: InvoiceDataType, query: string): ResponsiveTableColumn<SearchableInvoice>[] => {
	const invoiceId: ResponsiveTableColumn<SearchableInvoice> = {
		header: "Id",
		selector: (i) => <HighlightedText query={query}>{i.shortId}</HighlightedText>,
	};
	const customer: ResponsiveTableColumn<SearchableInvoice> = {
		header: "Customer",
		selector: (i) => i.customerName,
	};
	const dateCreated: ResponsiveTableColumn<SearchableInvoice> = {
		header: "Issued",
		selector: (i) => i.issuedOn.toLocaleDateString(),
	};
	const dueDate: ResponsiveTableColumn<SearchableInvoice> = {
		header: "Due",
		selector: (i) => i.dueBy.toLocaleDateString(),
	};
	const balance: ResponsiveTableColumn<SearchableInvoice> = {
		header: "Balance",
		selector: (i) => <Price value={i.balance} />,
	};
	const total: ResponsiveTableColumn<SearchableInvoice> = {
		header: "Total",
		selector: (i) => <Price value={i.total} />,
	};

	return [invoiceId, customer, balance, total, dueDate, dateCreated];
};

const filter = (data: InvoiceDataType): SearchableInvoice[] =>{
	const { invoices } = data;
	return invoices;
};

const search = (invoices: SearchableInvoice[], query: string) => {
	const q = query.toLowerCase().trim();
	if (q) {
		return invoices.filter((o) => o.shortId.toLowerCase().includes(q) || o.customerName.toLowerCase().includes(q));
	}
	return invoices;
};

const sort = (invoices: SearchableInvoice[]) => {
	return [...invoices].sort(orderBy.date(o => o.dueBy, 'Ascending'));
};

const emptyMessage = (): string => "All invoices are paid";

export const InvoiceListViewConfig: ListViewConfig<InvoiceDataType, SearchableInvoice> = {
	tableColumns,
	filter,
	search,
	sort,
	emptyMessage,
};