import { Divider, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../routes";
import { ReactComponent as WhiteLogo } from "./logos/DPLogoWhite.svg";
import { ReactComponent as GreyLogo } from "./logos/DPLogoGrey.svg";
import { GridGrow } from "../components/GridGrow";
import { appVersion } from "../appVersion";
import { RestrictToRole } from "../components/RestrictToRole";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BusinessIcon from '@material-ui/icons/Business';

export default function AdminDrawerItems(props: { onClose: () => void }) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();


	const onRouteSelected = (path: string) => {
		history.push(path);
		props.onClose();
	};

	return (
		<Grid container direction="column" style={{ height: "100%" }}>
			<Grid item>
				<Hidden mdUp>
					<div className={classes.drawerHeader}>
						<WhiteLogo style={{ height: 55, width: "auto", padding: 10, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					</div>
					<Divider />
				</Hidden>
				<List>
					<ListItem button onClick={() => onRouteSelected("/app")}>
						<ListItemIcon>
							<ArrowBackIcon />
						</ListItemIcon>
						<ListItemText>Back</ListItemText>
					</ListItem>
					<RestrictToRole superAdmin>
						<ListItem button onClick={() => onRouteSelected(routes.admin.resolve.tenantListPage())}>
							<ListItemIcon>
								<BusinessIcon />
							</ListItemIcon>
							<ListItemText>Tenants</ListItemText>
						</ListItem>
					</RestrictToRole>
				</List>
			</Grid>
			<GridGrow />
			<Grid item>
				<Hidden smDown>
					{theme.palette.type === "dark" ? (
						<WhiteLogo style={{ height: 30, width: "auto", marginBottom: 5, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					) : (
						<GreyLogo style={{ height: 30, width: "auto", marginBottom: 5, marginLeft: "auto", marginRight: "auto", display: "block" }} id="logo-header" />
					)}
				</Hidden>
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<Typography align="center" variant="overline" style={{ textTransform: "unset", fontWeight: 300 }}>
							DispatchPro {appVersion}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	drawerHeader: {
		...theme.mixins.toolbar,
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.getContrastText(theme.palette.secondary.main),
	},
	nestedItem: {
		paddingLeft: theme.spacing(4),
	},
}));
