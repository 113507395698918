import { Link, Theme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { PhoneNumber } from "../entities/customer/PhoneNumber";
import { formatPhoneNumber } from "../utility/StringHelpers";

export function PhoneNumberLink(props: { phoneNumber: PhoneNumber }) {
    const { phoneNumber } = props;
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
	return (
		<Link href={`tel:${phoneNumber.number.replace(/\D/g, "")}`} color="inherit" onClick={(e: React.SyntheticEvent) => e.stopPropagation()} underline={isMobile ? "always" : "hover"}>
			{formatPhoneNumber(phoneNumber)}
		</Link>
	);
}
