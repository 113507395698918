import {
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
	Button,
	MenuItem,
	CardActions,
	CardHeader,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { BackButton } from "../../../components/BackButton";
import { PercentTextField } from "../../../components/PercentTextField";
import { PriceTextField } from "../../../components/PriceTextField";
import { GeneralLedgerAccount, LedgerClassification } from "../../../entities/accounting/GeneralLedgerAccount";
import { TaxRule } from "../../../entities/accounting/TaxRule";
import { useAlert } from "../../../hooks/useAlert";
import { LedgerAccountService } from "../../../services/accounting/LedgerAccountsService";
import { TaxRuleService } from "../../../services/accounting/TaxRuleService";
import { useTaxRulePage } from "./TaxRulePage";
import { AppliedToCard } from "./AppliedToCard";
import { TaxMatchCriteriaCard } from "./TaxMatchCriteriaCard";

interface TaxRuleDetailContext {
 taxRule: TaxRule;
 updateTaxRule: (taxRule: TaxRule) => void;
}
const TaxRuleDetailReactContext = React.createContext<TaxRuleDetailContext>({} as TaxRuleDetailContext);
export const useTaxRuleDetailContext = () => React.useContext(TaxRuleDetailReactContext);



export function TaxRuleDetailView(props: { taxRule: TaxRule }) {
	const { taxRule } = props;
	const context = useTaxRulePage();

	const missingMatchCriteria = taxRule.matchGroups.length === 0;
	const missingAssignment = taxRule.assignedProductLines.length === 0 && taxRule.assignedProductListings.length === 0;
		
	return (
		<TaxRuleDetailReactContext.Provider value={{ taxRule, updateTaxRule: context.updateTaxRule }}>
		<Grid container justify="center">
			<Grid item xl={6} lg={8} md={10} xs={12}>
				<EditTaxRuleCard  />
				{missingMatchCriteria && (
					<Typography variant="h6" color="error">
						No match criteria have been added.
					</Typography>
				)}
				{missingAssignment && (
					<Typography variant="h6" color="error">
						Rule has not been assigned to anything in your product catalog.
					</Typography>
				)}
				{(missingMatchCriteria || missingAssignment) && (
					<Typography variant="h6" color="error">
						This tax rule will not be applied to any transactions.
					</Typography>
				)}
				<TaxMatchCriteriaCard />
				<AppliedToCard />
			</Grid>
		</Grid>
		</TaxRuleDetailReactContext.Provider>
	);
}


function EditTaxRuleCard() {
	const { taxRule } = useTaxRuleDetailContext();
	const context = useTaxRulePage();
	const alert = useAlert();

	const [ledgerAccounts, setLedgerAccounts] = React.useState<GeneralLedgerAccount[]>();
	const liabilityAccounts = ledgerAccounts?.filter((x) => x.classification === LedgerClassification.Liability);

	const [name, setName] = React.useState(taxRule.name);
	const [ratePercent, setRatePercent] = React.useState(taxRule.ratePercent);
	const [fixedAmount, setFixedAmount] = React.useState(taxRule.fixedAmount);
	const [taxAccountId, setTaxAccountId] = React.useState<number>(taxRule.taxAccountId);

	const [disabled, setDisabled] = React.useState(false);

	const saveDisabled = disabled || !name || (ratePercent === 0 && fixedAmount === 0);

	useEffect(() => {
		async function loadAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				setLedgerAccounts(result.data);
			} else {
				alert.serverError(result);
			}
		}
		loadAccounts();
	}, [alert]);

	const onSelectTaxAccountId = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as number;
		setTaxAccountId(value);
	};

	const onSave = async () => {
		setDisabled(true);
		const newLocal = {
			taxRuleId: taxRule.id,
			name,
			ratePercent: ratePercent === 0 ? null : ratePercent,
			fixedAmount: fixedAmount === 0 ? null : fixedAmount,
			taxAccountId,
		};
		const result = await TaxRuleService.update(newLocal);
		setDisabled(false);
		if (result.success) {
			context.updateTaxRule(result.data);
			alert.success("Tax rule updated");
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Card>
			<Grid container alignItems="center">
				<Grid item>
					<BackButton />
				</Grid>
				<Grid item>
					<CardHeader title="Update tax rule" />
				</Grid>
			</Grid>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<TextField
							label="Rule Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
							disabled={disabled}
							helperText="This will show up on detailed invoices"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						{!liabilityAccounts && <Typography>Loading Tax Accounts...</Typography>}
						{liabilityAccounts && (
							<TextField
								select
								label="Tax Account"
								fullWidth
								helperText="Liability account to record taxes"
								value={taxAccountId}
								onChange={onSelectTaxAccountId}
							>
								{liabilityAccounts.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.name}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					<Grid item sm={6} xs={12}>
						<PercentTextField
							label="Rate Percent"
							value={ratePercent}
							onPercentChange={setRatePercent}
							fullWidth
							disabled={disabled}
							helperText="Example: 5% sales tax"
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<PriceTextField
							label="Fixed Amount"
							value={fixedAmount}
							onPriceChanged={setFixedAmount}
							fullWidth
							disabled={disabled}
							helperText="Example: $1.50 per item"
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions>
				<Button onClick={onSave} disabled={saveDisabled} variant="contained" color="primary">
					Save
				</Button>
			</CardActions>
		</Card>
	);
}
