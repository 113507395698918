import { Modify } from "../../utility/modifyType"

interface TankFillAlert {
    date: Date
    driverTicketId: string
}

type SerializedTankFillAlert = Modify<TankFillAlert, {
    date: string;
}>;

function deserializeTankFillAlert(serverModel: SerializedTankFillAlert): TankFillAlert {
    return {
        ...serverModel,
        date: new Date(serverModel.date)
    }
}

interface TankFillConfigurationBase {
    id: string
    tenantId: string
    tankId: string
    type: "ScheduledFill",
    alert: TankFillAlert | null
}

export type ScheduledFillPeriodType = "Days" | "Weeks" | "Months";

export type ScheduledFill = Modify<TankFillConfigurationBase, {
    type: "ScheduledFill";
    startDate: Date; 
    nextFillDate: Date; 
    coldPeriodType: ScheduledFillPeriodType;
    coldPeriodValue: number;
    warmPeriodType: ScheduledFillPeriodType;
    warmPeriodValue: number;
    businessDaysBeforeAlert: number;
}>;

export type SerializedScheduledFill = Modify<ScheduledFill, {
    startDate: string;
    nextFillDate: string;
    alert: SerializedTankFillAlert | null;
}>;

export type TankFillConfiguration = ScheduledFill;
export type SerializedTankFillConfiguration = SerializedScheduledFill;

export const deserializeTankFillConfiguration = (serverModel: SerializedTankFillConfiguration): TankFillConfiguration => {
    return {
        ...serverModel,
        startDate: new Date(serverModel.startDate),
        nextFillDate: new Date(serverModel.nextFillDate),
        alert: serverModel.alert ? deserializeTankFillAlert(serverModel.alert) : null
    }
}

export const deserializeTankFillConfigurations = (serverModel: SerializedTankFillConfiguration[]): TankFillConfiguration[] => {
    return serverModel.map(deserializeTankFillConfiguration);
}