import React, { CSSProperties, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import { useAppUser } from "../../../providers/AppUserProvider";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { CreditCardInput } from "../../../components/payment/CreditCardInput";
import { CreditCardPaymentMethod, PaymentMethod } from "../../../entities/billing/PaymentMethod";
import { Customer } from "../../../entities/customer/Customer";

interface AddPaymentMethodDialogProps {
	open: boolean;
	customer: Customer;
	onClose: () => void;
	onCreate: (paymentMethod: PaymentMethod) => void;
}

export function AddPaymentMethodDialog(props: AddPaymentMethodDialogProps) {
	const { customer } = props;
	const user = useAppUser();

	const canAddPaymentMethods = user.paymentProcessor !== "none";
	const [paymentType, setPaymentType] = useState<"card" | "bank">("card");

	const onClose = () => {
		// setPaymentType(undefined);
		props.onClose();
	};

	const onCreate = (paymentMethod: PaymentMethod) => {
		props.onCreate(paymentMethod);
		// setPaymentType(undefined);
	};

	return (
		<Dialog open={props.open} onClose={onClose}>
			{!canAddPaymentMethods && <NoProcessorConfigured onClose={onClose} />}
			{canAddPaymentMethods && !paymentType && <SelectPaymentType onSelect={setPaymentType} onClose={onClose} />}
			{canAddPaymentMethods && paymentType === "card" && <CreditCard customer={customer} onClose={onClose} onCreate={onCreate} />}
			{/* {canAddPaymentMethods && paymentType === "bank" && <BankAccount customer={customer} onClose={onClose} onCreate={onCreate} />} */}
		</Dialog>
	);
}

interface CreditCardProps {
	customer: Customer;
	onClose: () => void;
	onCreate: (creditCard: CreditCardPaymentMethod) => void;
}

function CreditCard(props: CreditCardProps) {
	const { customer } = props;
	return (
		<>
			<DialogTitle style={{ paddingBottom: 0 }}>Add Credit Card</DialogTitle>
			<DialogContent>
				<CreditCardInput customer={customer} onClose={props.onClose} onCreate={props.onCreate} />
			</DialogContent>
		</>
	);
}


function NoProcessorConfigured(props: { onClose: () => void }) {
	return (
		<>
			<DialogTitle>Payment Processor Not Configured</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">
					Your organization has not configured a payment processor. Please contact DispatchPro support if you are interested in taking credit cards / bank transfers.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={props.onClose}>
					Close
				</Button>
			</DialogActions>
		</>
	);
}

function SelectPaymentType(props: { onSelect: (mode: "card" | "bank") => void; onClose: () => void }) {
	const bigButtonIcon: CSSProperties = {
		height: "1.5em",
		width: "1.5em",
	};

	return (
		<>
			<DialogTitle>Choose A Payment Type</DialogTitle>
			<DialogContent>
				<Button onClick={() => props.onSelect("card")}>
					<Grid container>
						<Grid item xs={12}>
							<CreditCardIcon style={bigButtonIcon} />
						</Grid>
						<Grid item xs={12}>
							Credit Card
						</Grid>
					</Grid>
				</Button>
				<Button onClick={() => props.onSelect("bank")}>
					<Grid container>
						<Grid item xs={12}>
							<AccountBalanceIcon style={bigButtonIcon} />
						</Grid>
						<Grid item xs={12}>
							Bank Account
						</Grid>
					</Grid>
				</Button>
			</DialogContent>
		</>
	);
}
