interface addScriptRequest {
	src: string;
	id: string;
	onLoad?: () => void;
}

export const addScript = (request: addScriptRequest) => {
	const { src, id, onLoad } = request;
	const existing = document.getElementById(id);
	if (existing) {
		return existing;
	} else {
		const script = document.createElement("script");
		script.src = src;
		script.id = id;
		script.async = true;
		if (onLoad) {
			script.onload = onLoad;
		}
		document.head.appendChild(script);
		return script;
	}
};

interface addStylesheetRequest {
	src: string;
	id: string;
	onLoad?: () => void;
}

export const addStylesheet = (request: addStylesheetRequest) => {
	const { src, id, onLoad } = request;
	const existing = document.getElementById(id);
	if (existing) {
		return existing;
	} else {
		const link = document.createElement("link");
		link.rel = "stylesheet";
		link.type = "text/css";
		link.href = src;
		link.id = id;
		if (onLoad) {
			link.onload = onLoad;
		}
		document.head.appendChild(link);
		return link;
	}
};

export const removeElement = (id: string) => {
	const script = document.getElementById(id);
	if (script) {
		document.body.removeChild(script);
	}
};
