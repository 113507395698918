import { TableRow, TableRowProps } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

type Props = TableRowProps & {
	to: string;
	newTab?: boolean;
};

export function LinkTableRow(props: React.PropsWithChildren<Props>) {
	const { to, children, hover, newTab, style, ...rowProps } = props;
	const history = useHistory();

	let rowStyle: React.CSSProperties = { cursor: "pointer" };
	if (props.style) {
		rowStyle = { ...style, ...props.style };
	}

	const onClick = () => {
		if (newTab) {
			window.open(to, "_blank");
		} else {
			history.push(to);
		}
	};

	return (
		<TableRow hover={hover ?? true} {...rowProps} onClick={onClick} style={rowStyle}>
			{children}
		</TableRow>
	);
}
