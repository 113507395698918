import { isDefined } from "ts-is-present";
import { getDispatchProModule } from "../entities/DispatchProModule";
import { JwtUser } from "../entities/auth/JwtUser";
import { getUserClaim, UserClaim } from "../entities/auth/UserClaim";
import { IntegrationType } from "../entities/auth/IntegrationConfiguration";

const jwt_key = "dispatch-pro-auth-token";

export interface JWTPayload {
	nameid: string;
	tenantId: string;
	timezone: string;
	given_name: string;
	family_name: string;
	email: string;
	optedIntoNotifications: string;
	phone?: string;
	userClaimIds: string;
	moduleAccessIds: string;
	enabledIntegrations: string;
	driverId?: string; 
	appSignIn?: string;
	exp: number;
}

function getPaymentProcessor(integrations: IntegrationType[]) {
	if(integrations.includes(IntegrationType.DispatchProPayments)){
		return "dispatchpro";
	}
	if(integrations.includes(IntegrationType.Qualpay)){
		return "qualpay";
	}
	return "none";
}

const deserializeToken = (jwt: string): JwtUser => {
	const payloadBase64 = jwt.split(".")[1];
	const payload = atob(payloadBase64);
	const jwtPayload = JSON.parse(payload) as JWTPayload;

	const userClaims = jwtPayload.userClaimIds
	.split(",")
	.map((id) => getUserClaim(parseInt(id, 10)))
	.filter(isDefined);
	const enabledIntegrations = (jwtPayload.enabledIntegrations ?? "").split(",").map((id) => parseInt(id, 10) as IntegrationType)

	return {
		id: jwtPayload.nameid,
		tenantId: jwtPayload.tenantId,
		firstName: jwtPayload.given_name,
		lastName: jwtPayload.family_name,
		email: jwtPayload.email,
		timezone: jwtPayload.timezone,
		phoneNumber: jwtPayload.phone,
		optedIntoNotifications: jwtPayload.optedIntoNotifications === "1",
		userClaims,
		moduleAccess: jwtPayload.moduleAccessIds
			.split(",")
			.map((id) => getDispatchProModule(parseInt(id, 10)))
			.filter(isDefined),
		enabledIntegrations,
		paymentProcessor: getPaymentProcessor(enabledIntegrations),
		driverId: jwtPayload.driverId,
		driverOnly: jwtPayload.driverId != null && userClaims.length === 1 && userClaims[0] === UserClaim.Driver,
		appSignIn: jwtPayload.appSignIn === "1",
		expiration: new Date(jwtPayload.exp * 1000)
	};
};

export const Auth = {
	setToken(token: string) {
		window.localStorage.setItem(jwt_key, token);
		return deserializeToken(token);
	},
	clearToken() {
		window.localStorage.removeItem(jwt_key);
	},
	signedIn() {
		return window.localStorage.getItem(jwt_key) != null;
	},
	getSavedToken() {
		return window.localStorage.getItem(jwt_key);
	},
	getJwtUser(): JwtUser | undefined {
		const token = this.getSavedToken();
		if (!token) {
			return;
		}

		return deserializeToken(token);
	},
	checkForRefreshTokenCookie(){

	}
};

export default Auth;
