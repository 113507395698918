import React, { useEffect, useMemo } from "react";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Driver } from "../../entities/routing/Driver";
import { PageTitle } from "../../components/PageTitle";
import { RestrictToRole } from "../../components/RestrictToRole";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { AccountService } from "../../services/business/AccountService";
import { useAlert } from "../../hooks/useAlert";
import AuthService from "../../services/auth/AuthService";
import { useState } from "react";
import { EnableSmsNotificationsDialog } from "./dialog/EnableSmsNotificationsDialog";
import { useOfflineStatus } from "../../providers/OfflineStatusProvider";
import { useOfflineTickets } from "../../providers/OfflineTicketProvider";
import { OrderableRouteStops } from "./OrderableRouteStops";
import { orderBy } from "../../utility/orderBy";
import { BackButton } from "../../components/BackButton";
import DoneIcon from "@material-ui/icons/Done";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { useAppUser } from "../../providers/AppUserProvider";
import { useUser } from "../../hooks/useUser";
import { OptimizeRouteDialog } from "./dialog/OptimizeRouteDialog";
import { VisualizeRouteDialog } from "./dialog/VisualizeRouteDialog";
import RefreshIcon from "@material-ui/icons/Refresh";
import { printDate } from "../../utility/PrintDate";

interface Props {
	selectedDriver: Driver;
}

export function RouteDetailView(props: Props) {
	const { selectedDriver } = props;
	const openTicketContext = useOfflineTickets();
	const { openTickets } = openTicketContext;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const user = useAppUser();

	const driverId = selectedDriver.id;
	const driverIsUser = user.driverId === driverId;

	const stops = useMemo(
		() =>
			openTickets
				.filter((s) => s.driverId === driverId)
				.filter((s) => s.timeOfDelivery == null)
				.sort(orderBy.number((s) => s.routeSequence, "Ascending")),
		[openTickets, driverId]
	);

	const onOrderChanged = (orderedTickets: DriverTicket[]) => {
		openTicketContext.changeOrder(driverId, orderedTickets);
	};

	return (
		<>
			<PageTitle title={"Route"} />
			{isMobile && (
				<>
					<Grid container wrap="nowrap" alignItems="center" style={{ marginBottom: 10 }}>
						<Grid item xs={2}>
							<RestrictToRole office>
								<BackButton />
							</RestrictToRole>
						</Grid>
						<Grid item xs={8}>
							<Typography variant="h5" align="center">
								{driverIsUser ? "My Route" : `${selectedDriver.name}`}
							</Typography>
						</Grid>
						<Grid item xs={2}></Grid>
					</Grid>
					<Grid container justify="center">
						<Grid item>
							<RouteActions selectedDriver={selectedDriver} stops={stops} />
						</Grid>
					</Grid>
				</>
			)}
			{!isMobile && (
				<Grid container justify="flex-end">
					<Grid item md={4}>
						<Grid container alignItems="center" justify="flex-start">
							<Grid item>
								<RestrictToRole office>
									<BackButton />
								</RestrictToRole>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={4}>
						<Typography variant="h5" align="center">
							{driverIsUser ? "My Route" : `${selectedDriver.name}`}
						</Typography>
					</Grid>
					<Grid item md={4}>
						<Grid container alignItems="center" justify="flex-end">
							<Grid item>
								<RouteActions selectedDriver={selectedDriver} stops={stops} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}

			{stops.length > 0 && (
				<Grid container justify="center" style={{ marginTop: 10 }}>
					<Grid item xl={5} lg={5} md={7} sm={8} xs={12}>
						<OrderableRouteStops driverTickets={stops} onOrderChanged={onOrderChanged} />
					</Grid>
				</Grid>
			)}
			{stops.length === 0 && (
				<Grid container direction="column" alignItems="center" justify="center" style={{ height: "inherit", paddingLeft: 48 }}>
					<Grid item>
						<DoneIcon style={{ fontSize: "6rem" }} color="primary" />
					</Grid>
					<Grid item>
						<Typography variant="h2" align="center">
							All Stops Complete
						</Typography>
					</Grid>
				</Grid>
			)}
		</>
	);
}

function RouteActions(props: { selectedDriver: Driver; stops: DriverTicket[] }) {
	const { selectedDriver, stops } = props;

	const alert = useAlert();
	const isOffline = useOfflineStatus();
	const user = useAppUser();
	const [, setUser] = useUser();
	const openTicketContext = useOfflineTickets();

	const driverIsUser = user.driverId === selectedDriver.id;

	const [showVisualizeRouteDialog, setShowVisualizeRouteDialog] = useState(false);
	const [showRouteOptimizationDialog, setShowRouteOptimizationDialog] = useState(false);
	const [enableNotificationsOpen, setEnableNotificationsOpen] = useState(false);
	const [refreshing, setRefreshing] = useState(false);
	const [lastRefresh, setLastRefresh] = useState(printDate.timeAgo(openTicketContext.lastRefresh));

	useEffect(() => {
		const interval = setInterval(() => {
			setLastRefresh(printDate.timeAgo(openTicketContext.lastRefresh));
		}, 1000);
		return () => clearInterval(interval);
	}, [openTicketContext.lastRefresh]);

	const onNotificationToggle = async () => {
		if (user.phoneNumber) {
			const result = await AccountService.toggleSmsNotifications();
			if (result.success) {
				alert.success(`SMS Notifications ${user.optedIntoNotifications ? "Disabled" : "Enabled"}`);
				const result = await AuthService.attemptTokenRefreshAndSet();
				if (result) {
					setUser(result.jwt);
				}
			} else if (result.validation) {
				alert.validation(result);
			} else {
				alert.serverError(result);
			}
		} else {
			setEnableNotificationsOpen(true);
		}
	};

	const refreshTickets = async () => {
		setRefreshing(true);
		const result = await openTicketContext.refresh();
		if (result === "offline") {
			alert.error("You are offline");
		} else if (result === null) {
			alert.success("Tickets Refreshed");
		} else {
			alert.serverError(result);
		}
		setRefreshing(false);
	};

	return (
		<Grid container spacing={2}>
			<EnableSmsNotificationsDialog open={enableNotificationsOpen} onClose={() => setEnableNotificationsOpen(false)} />
			<OptimizeRouteDialog open={showRouteOptimizationDialog} onClose={() => setShowRouteOptimizationDialog(false)} driverId={selectedDriver.id} stops={stops} />
			<VisualizeRouteDialog open={showVisualizeRouteDialog} onClose={() => setShowVisualizeRouteDialog(false)} stops={stops} />
			{driverIsUser && (
				<Grid item>
					<div style={{ width: "100%", maxWidth: 102 }}>
						<Button
							size="small"
							variant="outlined"
							color={user.optedIntoNotifications ? "default" : "secondary"}
							disabled={isOffline}
							onClick={onNotificationToggle}
							startIcon={user.optedIntoNotifications ? <NotificationsIcon /> : <NotificationsOffIcon />}
						>
							{user.optedIntoNotifications ? "SMS On" : "SMS Off"}
						</Button>
					</div>
				</Grid>
			)}

			<Grid item>
				<Button size="small" variant="outlined" color="primary" disabled={isOffline} onClick={() => setShowVisualizeRouteDialog(true)}>
					Visualize
				</Button>
			</Grid>
			<Grid item>
				<Button size="small" variant="outlined" color="primary" disabled={isOffline} onClick={() => setShowRouteOptimizationDialog(true)}>
					Optimize
				</Button>
			</Grid>
			<Grid item>
				<Button size="small" variant="outlined" color="primary" disabled={isOffline || refreshing} onClick={refreshTickets} startIcon={<RefreshIcon />}>
					Refresh
				</Button>
				<Typography variant="caption" align="center" style={{ display: "block" }}>
					{lastRefresh}
				</Typography>
			</Grid>
		</Grid>
	);
}
