import React, {  } from "react";
import { OnlineProfile } from "../../../entities/business/OnlineProfile";
import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import { OnlineProfileService } from "../../../services/business/OnlineProfileService";
import { useAlert } from "../../../hooks/useAlert";

export function CreateOnlineProfilePage(props: { onCreated: (onlineProfile: OnlineProfile) => void }) {
	const alert = useAlert();

	const [subdomain, setSubdomain] = React.useState<string>("");
	const [loading, setLoading] = React.useState<boolean>(false);

	const onSubmit = async () => {
		setLoading(true);
		const result = await OnlineProfileService.create({ subdomain });
		setLoading(false);

		if (result.success) props.onCreated(result.data);
		else if (result.validation) alert.validation(result);
		else alert.serverError(result);
	};

	return (
		<Grid container justify="center">
			<Grid item xs={12} md={8} lg={6}>
				<Card>
					<CardHeader title="Create Online Profile" />
					<CardContent>
						<Typography variant="body1" gutterBottom>
							Create an online profile for your business to enable online services such as email notifications, online payments, and more. To get started, enter
							a subdomain for your online profile.
						</Typography>
						<Typography variant="body1" gutterBottom>
							This will be the URL that your customers will use to access your online profile. For example, if you enter &#39;example&#39; as your subdomain,
							then your email notifications will be sent from &#39;example@gasbill.online&#39;.
						</Typography>
						<TextField
							label="Subdomain"
							margin="normal"
							variant="outlined"
							fullWidth
							required
							disabled={loading}
							value={subdomain}
							onChange={(e) => setSubdomain(e.target.value)}
							helperText="Must be between 3 and 20 characters long, and can only contain letters, numbers, and dashes."
						/>
						<Button variant="contained" color="primary" fullWidth onClick={onSubmit} disabled={loading}>
							Create Online Profile
						</Button>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
