import { Box, Button, FormHelperText, Typography } from "@material-ui/core";
import React from "react";
import { endOfDay } from "date-fns";
import { RedText } from "../../../components/RedText";
import { TimeBetween } from "../../../components/TimeBetween";
import { DriverTicketService } from "../../../services/routing/DriverTicketService";
import { useTicketDetail } from "./DriverTicketDetailView";
import { useAlert } from "../../../hooks/useAlert";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { KeyboardDatePicker } from "@material-ui/pickers";

export function TicketDueDate() {
	const alert = useAlert();
	const context = useTicketDetail();
	const { ticket, disabled, setDisabled, updateTicket } = context;

	const [editMode, setEditMode] = React.useState(false);
	const [dueDate, setDueDate] = React.useState<MaterialUiPickersDate>(ticket.dueBy);

	const updateDueDate = async (dueBy: Date | null) => {
		setDisabled(true);
		const result = await DriverTicketService.update({ driverTicketId: ticket.id, dueBy });
		setDisabled(false);

		if (result.success) {
			updateTicket(result.data);
			alert.success("Due date updated");
			setEditMode(false);
		} else if (result.error) {
			alert.serverError(result);
		} else {
			alert.validation(result);
		}
	};

	if (ticket.timeOfCompletion) {
		return (
			<Box>
				<Typography variant="h6">Due Date:</Typography>
				<Typography variant="h6" color={ticket.dueBy ? undefined : "textSecondary"}>
					{ticket.dueBy?.toLocaleDateString() ?? "None"}
				</Typography>
			</Box>
		);
	}

	return (
		<Box>
			<Button
				color="primary"
				disabled={disabled}
				variant="text"
				style={{ fontSize: "1.25rem", textTransform: "none", padding: 0, paddingRight: 6 }}
				onClick={() => setEditMode(true)}
			>
				Due Date:
			</Button>
			{editMode && (
				<>
					<KeyboardDatePicker
						disableToolbar
						disabled={disabled}
						variant="inline"
						format="MM/dd/yy"
						placeholder="MM/DD/YY"
						label="Due Date"
						inputVariant="outlined"
						value={ticket.timeOfDelivery}
						onChange={(date) => {
							setDueDate(date);
							if (date != null && date.toString() !== "Invalid Date") {
								updateDueDate(date);
							}
						}}
						onKeyDown={(event) => {
							if (event.key === "Backspace" && dueDate === null) {
								updateDueDate(null);
							}

							if (event.key === "Escape") {
								event.stopPropagation();
								setEditMode(false);
							}
						}}
						autoFocus
						
						size="small"
						inputProps={{
							onFocus: (e) => e.target.select(),
						}}
					/>
					<FormHelperText>Date ticket should be complete</FormHelperText>
				</>
			)}
			{!editMode && (
				<Typography variant="h6" color={ticket.dueBy ? undefined : "textSecondary"}>
					{ticket.dueBy?.toLocaleDateString() ?? "None"}
				</Typography>
			)}
			<Typography variant="body1">
				<Overdue date={ticket.dueBy} />
			</Typography>
		</Box>
	);
}

function Overdue(props: { date: Date | null }) {
	const { date } = props;
	if (!date) return null;

	const endOfDate = endOfDay(date);
	const now = new Date();
	if (now < endOfDate) return null;

	return (
		<RedText>
			<TimeBetween start={endOfDate} end={now} /> overdue
		</RedText>
	);
}
