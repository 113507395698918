import React from "react";
import { useHistory } from "react-router-dom";
import { InvoiceListViewConfig } from "../../components/listView/configs/InvoiceListViewConfig";
import { ListView } from "../../components/listView/ListView";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../components/ServerErrorView";
import { SearchableInvoice } from "../../entities/billing/Invoice";
import { routes } from "../../routes";
import { InvoiceService } from "../../services/billing/InvoiceService";
import { NotFoundResult, ServerError } from "../../services/server/WebClient";

export function InvoiceListPage() {
	const history = useHistory();
	const [serverError, setServerError] = React.useState<ServerError | NotFoundResult>();
	const [invoices, setInvoices] = React.useState<SearchableInvoice[]>();

	React.useEffect(() => {
		async function fetch() {
			const result = await InvoiceService.getOpen();
			if (result.success) {
				setInvoices(result.data);
			} else {
				setServerError(result);
			}
		}
		fetch();
	}, []);

	const onInvoiceSelected = (invoice: SearchableInvoice) => {
		history.push(routes.app.resolve.invoiceDetailPage(invoice.id));
	};

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!invoices) {
		return <OverlayLoadingScreen />
	}

	return (
		<ListView
			title="Open Invoices"
			listViewConfig={InvoiceListViewConfig}
			tableConfig={{ data: { invoices } }}
			onSelect={onInvoiceSelected}
		/>
	);
}
