import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { GridGrow } from "../../../components/GridGrow";
import { LedgerAccountType, LedgerClassification, LedgerIntegration } from "../../../entities/accounting/GeneralLedgerAccount";
import { routes } from "../../../routes";
import { Enum } from "../../../utility/Enum";
import { useLedgerAccountPage } from "./LedgerAccountPage";
import { useAppUser } from "../../../providers/AppUserProvider";
import { IntegrationType } from "../../../entities/auth/IntegrationConfiguration";
import { AddQuickBooksDesktopAccountView } from "./components/AddQuickBooksDesktopAccountView";
import { AddQuickBooksOnlineAccountView } from "./components/AddQuickBooksOnlineAccountView";
import { AddDispatchProLedgerAccountView } from "./components/AddDispatchProLedgerAccountView";

export function LedgerAccountListView() {
	const history = useHistory();
	const context = useLedgerAccountPage();
	const { ledgerAccounts } = context;

	const [showAddDialog, setShowAddDialog] = React.useState(false);

	const onSelect = (ledgerAccountId: number) => history.push(routes.business.resolve.generalLedgerAccountDetailPage(ledgerAccountId));

	return (
		<Grid container style={{ overflowY: "auto" }}>
			<Grid item>
				<Card>
					<AddLedgerAccountDialog open={showAddDialog} onClose={() => setShowAddDialog(false)} />
					<CardContent>
						<Grid container>
							<Grid item>
								<Typography variant="h5">Ledger Accounts</Typography>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button variant="contained" color="primary" onClick={() => setShowAddDialog(true)}>
									+ Add Account
								</Button>
							</Grid>
						</Grid>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Classification</TableCell>
									<TableCell>Integration</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ledgerAccounts.map((ledgerAccount) => (
									<TableRow key={ledgerAccount.id} hover onClick={() => onSelect(ledgerAccount.id)}>
										<TableCell>{ledgerAccount.name}</TableCell>
										<TableCell>{Enum.print(LedgerAccountType, ledgerAccount.type)}</TableCell>
										<TableCell>{Enum.print(LedgerClassification, ledgerAccount.classification)}</TableCell>
										<TableCell>{Enum.print(LedgerIntegration, ledgerAccount.integration)}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

interface AddLedgerAccountDialogProps {
	open: boolean;
	onClose: () => void;
}

function AddLedgerAccountDialog(props: AddLedgerAccountDialogProps) {
	const appUser = useAppUser();
	const [integration, setIntegration] = React.useState<LedgerIntegration>();

	const title = () => {
		switch (integration) {
			case LedgerIntegration.None:
				return "Add Ledger Account";
			case LedgerIntegration.QuickBooks_Desktop:
				return "Add QuickBooks Desktop Account";
			case LedgerIntegration.QuickBooks_Online:
				return "Add QuickBooks Online Account";
			case undefined:
				return "Select Accounting Integration";
			default:
				return "Add Ledger Account";
		}
	};

	const onClose = () => {
		setIntegration(undefined);
		props.onClose();
	};

	return (
		<Dialog open={props.open} onClose={onClose} fullWidth>
			<DialogTitle>{title()}</DialogTitle>
			{!integration && (
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item>
							<Button variant="contained" color="primary" onClick={() => setIntegration(LedgerIntegration.None)}>
								None
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="primary" onClick={() => setIntegration(LedgerIntegration.QuickBooks_Desktop)}>
								QuickBooks
							</Button>
						</Grid>
						{
							appUser.enabledIntegrations.includes(IntegrationType.QuickBooksOnline) && (
								<Grid item>
									<Button variant="contained" color="primary" onClick={() => setIntegration(LedgerIntegration.QuickBooks_Online)}>
										QuickBooks Online
									</Button>
								</Grid>
							)
						}
					</Grid>
				</DialogContent>
			)}
			{integration === LedgerIntegration.None && <AddDispatchProLedgerAccountView onClose={props.onClose} />}
			{integration === LedgerIntegration.QuickBooks_Desktop && <AddQuickBooksDesktopAccountView onClose={props.onClose} />}
			{integration === LedgerIntegration.QuickBooks_Online && <AddQuickBooksOnlineAccountView onClose={props.onClose} />}
		</Dialog>
	);
}

