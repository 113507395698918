import { Card, CardHeader, List, ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { AlertIcon } from "./AlertIcon";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { DetailCardStyles } from "./DetailCardStyles";
import { OtodataDevice } from "../../../entities/routing/OtodataTankMonitor";

export const showOtherInfoDetailCard = (device: OtodataDevice) => {
    return device.product ||
    device.customerId ||
    device.databaseId ||
    device.generalLedgerLocation ||
    device.name ||
    device.address ||
    device.city ||
    device.region ||
    device.postalCode ||
    device.country ||
    device.email ||
    device.route ||
    device.note ||
    device.latitude !== 0 ||
    device.longitude !== 0
};

export function OtherInfoDetailCard(props: { device: OtodataDevice }) {
	const { device } = props;
	const classes = DetailCardStyles();

	return (
		<Card>
			<CardHeader
				action={
					<AlertIcon>
						<LibraryBooksIcon fontSize={"large"} />
					</AlertIcon>
				}
				title="Other Info"
				className={classes.denseCardHeader}
			/>
			<List>
				{device.product && (
					<ListItem>
						<ListItemText primary="Product" secondary={device.product} />
					</ListItem>
				)}
				{device.customerId && (
					<ListItem>
						<ListItemText primary="Customer" secondary={device.customerId} />
					</ListItem>
				)}
				{device.databaseId && (
					<ListItem>
						<ListItemText primary="Database" secondary={device.databaseId} />
					</ListItem>
				)}
				{device.generalLedgerLocation && (
					<ListItem>
						<ListItemText primary="General Ledger Location" secondary={device.generalLedgerLocation} />
					</ListItem>
				)}
				{device.name && (
					<ListItem>
						<ListItemText primary="Name" secondary={device.name} />
					</ListItem>
				)}
				{device.address && (
					<ListItem>
						<ListItemText primary="Address" secondary={device.address} />
					</ListItem>
				)}
				{device.city && (
					<ListItem>
						<ListItemText primary="City" secondary={device.city} />
					</ListItem>
				)}
				{device.region && (
					<ListItem>
						<ListItemText primary="Region" secondary={device.region} />
					</ListItem>
				)}
				{device.postalCode && (
					<ListItem>
						<ListItemText primary="Zip" secondary={device.postalCode} />
					</ListItem>
				)}
				{device.country && (
					<ListItem>
						<ListItemText primary="Country" secondary={device.country} />
					</ListItem>
				)}
				{device.email && (
					<ListItem>
						<ListItemText primary="Email" secondary={device.email} />
					</ListItem>
				)}
				{device.route && (
					<ListItem>
						<ListItemText primary="Route" secondary={device.route} />
					</ListItem>
				)}
				{device.note && (
					<ListItem>
						<ListItemText primary="Note" secondary={device.note} />
					</ListItem>
				)}
				{device.latitude !== 0 && (
					<ListItem>
						<ListItemText primary="Latitude" secondary={device.latitude} />
					</ListItem>
				)}
				{device.longitude !== 0 && (
					<ListItem>
						<ListItemText primary="Longitude" secondary={device.longitude} />
					</ListItem>
				)}
			</List>
		</Card>
	);
}
