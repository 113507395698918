import { AppEnv, getAppEnv } from "../../AppEnv";

const resolveApi = () => {
    const env = getAppEnv();
    if(env === AppEnv.Prod)
        return "https://api.dispatchpro.app";
        
    if(env === AppEnv.Dev)
        return "https://api.dispatchpro.dev";

    return "https://localhost:5001";
}

export const apiServer = resolveApi();