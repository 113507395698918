import React, {  } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { routes } from "../../routes";
import { CustomerDetailPage } from "./CustomerDetailPage";
import { CustomerListView } from "./CustomerListView";

export function CustomerPage() {
	const params = useParams<{ customerId?: string }>();
	const { customerId } = params;
	return (
		<Switch>
			<Route exact path={routes.app.customerPage}>
				<PageTitle title="Customers" />
				<CustomerListView />
			</Route>
			{
				customerId &&
				<Route exact path={routes.app.customerDetailPage}>
					<CustomerDetailPage customerId={customerId} />
				</Route>
			}
			<Redirect to={routes.app.customerPage} />
		</Switch>
	);
}