import { fade, makeStyles, Theme } from "@material-ui/core";
import React from "react";

export function HighlightedText(props: React.PropsWithChildren<{ query: string | undefined | null }>) {
	const { query, children } = props;
	const classes = useStyles();
	if (!query || !children || (typeof children !== "number" && typeof children !== "string") || query.trim() === "" || query.includes("\\")) {
		return <>{children}</>;
	}
	const value = typeof children === "number" ? children.toString() : children;
	const queryLength = query.length;

	const result: React.ReactNode[] = [];
	const queryExpr = new RegExp(query, "gi");
	let lastMatchIndexEnd = 0;
	let match = null;
	while ((match = queryExpr.exec(value)) !== null) {
		const unmatchedLength = match.index - lastMatchIndexEnd;
		if (unmatchedLength > 0) {
			const unmatched = value.substring(lastMatchIndexEnd, lastMatchIndexEnd + unmatchedLength);
			result.push(<span key={lastMatchIndexEnd}>{unmatched}</span>);
		}
		result.push(
			<mark className={classes.highlight} key={match.index}>
				{value.substring(match.index, match.index + queryLength)}
			</mark>
		);
		lastMatchIndexEnd = match.index + queryLength;
	}
	if (lastMatchIndexEnd < value.length) {
		result.push(<span key={lastMatchIndexEnd}>{value.substring(lastMatchIndexEnd)}</span>);
	}
	return <>{result}</>;
}

const useStyles = makeStyles((theme: Theme) => ({
	highlight: {
		backgroundColor: fade(theme.palette.primary.main, 0.7),
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
}));
