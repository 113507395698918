import { Card, CardContent, Grid, MenuItem, Table, TableBody, TableCell, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { DisplayNumber } from "../../components/DisplayNumber";
import { GridGrow } from "../../components/GridGrow";
import { LinkTableRow } from "../../components/LinkTableRow";
import { useAlert } from "../../hooks/useAlert";
import { routes } from "../../routes";
import { PropaneTankService, TankFillHistory } from "../../services/customer/PropaneTankService";
import { usePropaneTankDetailPage } from "./PropaneTankDetailPage";

export function TankFillHistoryCard() {
	const alert = useAlert();
	const context = usePropaneTankDetailPage();
	const { propaneTank } = context;
	const propaneTankId = propaneTank.id;
	const customerId = propaneTank.customerId;

	const [tankFillHistory, setTankFillHistory] = React.useState<TankFillHistory[]>();
	const [selectedYear, setSelectedYear] = React.useState<number | null>(null);
	const [page, setPage] = React.useState(0);

	const filteredTankFillHistory = React.useMemo(() => {
		if (!tankFillHistory) return [];
		if (!selectedYear) return tankFillHistory;
		return tankFillHistory.filter((t) => t.dateFilled.getFullYear() === selectedYear);
	}, [tankFillHistory, selectedYear]);

	const filteredAmountFilled = React.useMemo(() => filteredTankFillHistory.reduce((a, b) => a + b.amountFilled, 0), [filteredTankFillHistory]);
	const pagedTankFillHistory = React.useMemo(() => filteredTankFillHistory.slice(page * 5, page * 5 + 5), [filteredTankFillHistory, page]);

	const oneYearAgoDate = React.useMemo(() => {
		const date = new Date();
		date.setFullYear(date.getFullYear() - 1);
		return date;
	}, []);
	const fillAmountPastYear = React.useMemo(() => {
		if (!tankFillHistory) return 0;
		return tankFillHistory.filter((t) => t.dateFilled >= oneYearAgoDate).reduce((a, b) => a + b.amountFilled, 0);
	}, [oneYearAgoDate, tankFillHistory]);

	const years = React.useMemo(() => {
		if (!tankFillHistory) return [];
		const years = tankFillHistory.map((t) => t.dateFilled.getFullYear());
		return [...new Set(years)].sort((a, b) => b - a);
	}, [tankFillHistory]);

	useEffect(() => {
		async function getTankFillHistory() {
			const result = await PropaneTankService.getTankFillHistory(customerId, propaneTankId);
			if (result.success) {
				setTankFillHistory(result.data);
			} else {
				alert.serverError(result);
			}
		}
		getTankFillHistory();
	}, [alert, customerId, propaneTankId]);

	return (
		<Card>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item>
						<Typography variant="h5">Tank Fill History</Typography>
					</Grid>
					<GridGrow/>
					<Grid item>
						<TextField
							select
							label="Year"
							variant="outlined"
							style={{ minWidth: 100 }}
							size="small"
							value={selectedYear ?? "All"}
							onChange={(e) => (e.target.value === "All" ? setSelectedYear(null) : setSelectedYear(Number(e.target.value)))}
						>
							<MenuItem key="All" value="All">
								All
							</MenuItem>
							{years.map((year) => (
								<MenuItem key={year} value={year}>
									{year}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
				{!tankFillHistory && <Typography>Loading...</Typography>}
				{tankFillHistory && (
					<>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell>Filled {selectedYear ?? "All Time"}: <DisplayNumber value={filteredAmountFilled} decimalScale={2} /> Gal</TableCell>
									<TableCell>Last 365 Days: <DisplayNumber value={fillAmountPastYear} decimalScale={2} /> Gal</TableCell>
								</TableRow>
							</TableBody>
						</Table>
						<Table size="small">
							<TableBody>
								<TableRow>
									{filteredTankFillHistory.length === 0 ? (
										<TableCell colSpan={3}>No tank fills found</TableCell>
									) : (
										<TablePagination
											colSpan={3}
											count={filteredTankFillHistory.length}
											rowsPerPage={5}
											page={page}
											rowsPerPageOptions={[]}
											onChangePage={(_, newPage) => setPage(newPage)}
										/>
									)}
								</TableRow>
								{pagedTankFillHistory.map((tankFill, i) => (
									tankFill.driverTicketId ?
									<LinkTableRow key={i} to={routes.app.resolve.ticketDetailPage(tankFill.driverTicketId)}>
										<TableCell>{tankFill.dateFilled.toLocaleString()}</TableCell>
										<TableCell>
											{tankFill.amountFilled} Gal {tankFill.tankPercentage && <>[Ending level {tankFill.tankPercentage * 100}%]</>}
										</TableCell>
										<TableCell>{tankFill.driverName ?? tankFill.completedBy}</TableCell>
									</LinkTableRow>
									:
									<TableRow key={i}>
										<TableCell>{tankFill.dateFilled.toLocaleString()}</TableCell>
										<TableCell>
											{tankFill.amountFilled} Gal {tankFill.tankPercentage && <>[Ending level {tankFill.tankPercentage * 100}%]</>}
										</TableCell>
										<TableCell>{tankFill.driverName ?? tankFill.completedBy}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</>
				)}
			</CardContent>
		</Card>
	);
}
