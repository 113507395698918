import { Money, SafeMoney } from "../../utility/Money";

export interface DispatchProPlanLineItem {
    id: string;
    name: string;
    term: "Monthly" | "Annually";
    price: SafeMoney;
    type: "Flat" | "Number of Drivers";
    description: string;
}

type SerializedDispatchProPlanLineItem = Omit<DispatchProPlanLineItem, "price"> & { price: number };

function deserializeDispatchProPlanLineItem(model: SerializedDispatchProPlanLineItem): DispatchProPlanLineItem {
    return {
        ...model,
        price: Money.fromDecimal(model.price),
    };
}

export interface DispatchProPlan {
    id: string;
    name: string;
    description: string;
    paymentMethodDescription: string | null;
    lineItems: DispatchProPlanLineItem[];
    startDate: Date;
    nextChargeDate: Date;
    daysUntilNextCharge: number;
    daysOverdue: number;
}

type SerializedDispatchProPlan = Omit<DispatchProPlan, "startDate" | "nextChargeDate" | "lineItems"> & {
    startDate: string;
    nextChargeDate: string;
    lineItems: SerializedDispatchProPlanLineItem[];
};

export function deserializeDispatchProPlan(model: SerializedDispatchProPlan): DispatchProPlan {
    return {
        ...model,
        startDate: new Date(model.startDate),
        nextChargeDate: new Date(model.nextChargeDate),
        lineItems: model.lineItems.map(deserializeDispatchProPlanLineItem),
    };
}