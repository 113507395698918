import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../components/BackButton";
import { PriceTextField } from "../../../components/PriceTextField";
import { Price } from "../../../components/Price";
import { calculateTax, printTaxRate } from "../../../entities/accounting/TaxRule";
import { SafeMoney } from "../../../utility/Money";
import CloseIcon from "@material-ui/icons/Close";
import { GeneralLedgerAccount, LedgerAccountType } from "../../../entities/accounting/GeneralLedgerAccount";
import { LedgerAccountService } from "../../../services/accounting/LedgerAccountsService";
import { useAlert } from "../../../hooks/useAlert";
import { useTicketDetail } from "./DriverTicketDetailView";
import { TicketQuoteItem, TicketQuoteType } from "../../../entities/routing/DriverTicket";
import { DriverTicketService, TicketTaxRule } from "../../../services/routing/DriverTicketService";

interface Props {
    open: boolean;
    quoteItem: TicketQuoteItem;
    subtotal: SafeMoney | null;
    onClose: () => void;
}

export function QuoteItemTaxDialog(props: Props){
    const { quoteItem, subtotal } = props;

    const alert = useAlert();
	const { updateTicket, setDisabled } = useTicketDetail();

	const theme = useTheme();
	const xsWidth = useMediaQuery(theme.breakpoints.only("xs"));

	const [addTax, setAddTax] = React.useState(false);

	const onDelete = async (index: number) => {
        const taxRules = quoteItem.taxRules.filter((_, i) => i !== index);

        setDisabled(true);
        const result = await (
            quoteItem.type === TicketQuoteType.Product?
            DriverTicketService.updateQuoteProduct({ 
                driverTicketId: quoteItem.driverTicketId, 
                quoteItemId: quoteItem.id, 
                taxRules
            }) :
            DriverTicketService.updateTankFill({ 
                driverTicketId: quoteItem.driverTicketId, 
                quoteItemId: quoteItem.id, 
                taxRules
            })
        );
        setDisabled(false);

        if (result.success) {
            alert.success("Tax rule removed");
            updateTicket(result.data);
        } else if (result.validation) {
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

	const onReset = async () =>{
        setDisabled(true);
        const result = await DriverTicketService.recalculateQuoteItemTax({
            driverTicketId: quoteItem.driverTicketId,
            quoteItemId: quoteItem.id
        });
        setDisabled(false);

        if (result.success) {
            alert.success("Tax rules reset");
            updateTicket(result.data);
        } else if (result.validation) {
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }

	const onAddTax = async (taxRule: TicketTaxRule) => {
		const taxRules = [...quoteItem.taxRules, taxRule];

        setDisabled(true);
        const result = await (
            quoteItem.type === TicketQuoteType.Product?
            DriverTicketService.updateQuoteProduct({ 
                driverTicketId: quoteItem.driverTicketId, 
                quoteItemId: quoteItem.id, 
                taxRules
            }) :
            DriverTicketService.updateTankFill({ 
                driverTicketId: quoteItem.driverTicketId, 
                quoteItemId: quoteItem.id, 
                taxRules
            })
        );
        setDisabled(false);

        if (result.success) {
            alert.success("Tax rule added");
            updateTicket(result.data);
        } else if (result.validation) {
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
		setAddTax(false);
	};

	const closeDialog = () => {
		setAddTax(false);
		props.onClose();
	};

	const View = () => {
		if (addTax) {
			return <AddTaxView onAddTax={onAddTax} onCancel={() => setAddTax(false)} />;
		}
		return <TaxListView taxRules={quoteItem.taxRules} subtotal={subtotal} onDelete={onDelete} onReset={onReset} onAddTax={() => setAddTax(true)} />;
	};

	return (
		<Dialog open={props.open} onClose={closeDialog} fullScreen={xsWidth} maxWidth="xs" fullWidth>
			<Box m={1}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<BackButton onClick={closeDialog} />
					</Grid>
					<Grid item>
						<Typography variant="h6">Taxes</Typography>
					</Grid>
				</Grid>
			</Box>

			<View />
		</Dialog>
	);
}

interface TaxListViewProps {
	taxRules: TicketTaxRule[];
	subtotal: SafeMoney | null;
	onDelete: (index: number) => void;
	onReset: () => void;
	onAddTax: () => void;
}

function TaxListView(props: TaxListViewProps) {
	const { taxRules, subtotal } = props;

    const { disabled } = useTicketDetail();

	return (
		<>
			<List>
				{taxRules.map((taxRule, i) => (
					<React.Fragment key={i}>
						{i === 0 && <Divider variant="middle" />}
						<ListItem>
							<ListItemText
								primary={taxRule.name}
								secondary={
									<>
										{printTaxRate(taxRule)}
									</>
								}
							/>

							<ListItemText primary={subtotal == null ? "Pending" : <Price value={calculateTax(taxRule, subtotal)} />} />

							<ListItemSecondaryAction>
								<IconButton edge="end" onClick={() => props.onDelete(i)} disabled={disabled}>
									<CloseIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						<Divider variant="middle" />
					</React.Fragment>
				))}
			</List>
			<DialogContent>
				{taxRules.length === 0 && (
					<Typography variant="h6" color="textSecondary">
						No taxes applied
					</Typography>
				)}
				<Box mt={2} mb={2}>
					<Grid container spacing={3}>
						<Grid item>
							<Button variant="outlined" color="primary" onClick={props.onAddTax} disabled={disabled}>
								Add Tax
							</Button>
						</Grid>
						<Grid item>
							<Button variant="outlined" onClick={props.onReset} disabled={disabled}>
								Reset
							</Button>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</>
	);
}

function AddTaxView(props: { onAddTax: (taxRule: TicketTaxRule) => void; onCancel: () => void }) {
	const alert = useAlert();
	const [name, setName] = React.useState("");
	const [amount, setAmount] = React.useState<number | null>(null);
	const [account, setAccount] = React.useState<GeneralLedgerAccount | null>(null);
	const [taxAccounts, setTaxAccounts] = React.useState<GeneralLedgerAccount[]>();

	React.useEffect(() => {
		async function getTaxAccounts() {
			const result = await LedgerAccountService.getAll();
			if (result.success) {
				const accounts = result.data.filter((a) => a.type === LedgerAccountType.Tax);
				setTaxAccounts(accounts);
				if (accounts.length === 1) {
					setAccount(accounts[0]);
				}
			} else {
				alert.serverError(result);
			}
		}
		getTaxAccounts();
	}, [alert]);

	const saveTax = () => {
		if (account == null) {
			alert.error("Please select a tax account");
			return;
		}
		if (!amount || amount <= 0) {
			alert.error("Please enter a tax amount");
			return;
		}
		if (name.trim() === "") {
			alert.error("Please enter a tax name");
			return;
		}

		const taxRule: TicketTaxRule = {
            taxRuleId: null,
			name,
			fixedAmount: amount,
			ratePercent: null,
			taxAccountId: account.id,
			taxAccountName: account.name,
		};
		props.onAddTax(taxRule);
	};

	return (
		<>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField label="Tax Name" variant="outlined" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
					</Grid>
					<Grid item xs={12}>
						<PriceTextField label="Tax Amount" variant="outlined" fullWidth value={amount} onPriceChanged={setAmount} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="Tax Account"
							variant="outlined"
							fullWidth
							select
							value={account?.id ?? "None"}
							error={taxAccounts?.length === 0}
							helperText={taxAccounts?.length === 0 ? "No tax accounts found" : undefined}
							onChange={(e) => {
								const id = parseInt(e.target.value);
								const account = taxAccounts?.find((a) => a.id === id);
								setAccount(account ?? null);
							}}
						>
							<MenuItem value="None">Select Tax Account</MenuItem>
							{taxAccounts?.map((a) => (
								<MenuItem key={a.id} value={a.id}>
									{a.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
				<Box mb={2} mt={2}>
					<Grid container spacing={3}>
						<Grid item>
							<Button variant="contained" color="primary" onClick={saveTax} disabled={taxAccounts == null || taxAccounts.length === 0}>
								Save
							</Button>
						</Grid>
						<Grid item>
							<Button variant="outlined" color="secondary" onClick={props.onCancel}>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</>
	);
}
