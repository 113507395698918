import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Geolocation } from "../../utility/Geolocation";

type CurrentMap = {
    map: google.maps.Map | null;
    marker: google.maps.Marker | null
    geoCoder: google.maps.Geocoder | null
};

export interface PickedLocation {
    latitude: number;
    longitude: number;
    street?: string;
    city?: string;
	state?: string;
	postalCode?: string;
	county?: string;
}
interface LatLongPickerProps {
    height: number;
    startLocation?: { lat: number, lng: number };
    geoCodeResult?: boolean
    onSelect: (location: PickedLocation) => void;
}

export function LatLongMapPicker(props: LatLongPickerProps){
    const { startLocation} = props;
    const mapDiv = useRef<HTMLDivElement | null>(null);
    const currentMap = useRef<CurrentMap>({map: null, marker: null, geoCoder: null});

    const [currentLocation, setCurrentLocation] = useState<{ lat: number, lng: number } | undefined>(startLocation);
    const [locationError, setLocationError] = useState<string>();

    useEffect(() => {
        if(startLocation === undefined)
            requestLocation();  
    }, [startLocation])

    useEffect(() => {
        async function loadMap() {
            const library = await (google.maps.importLibrary("maps") as Promise<google.maps.MapsLibrary>);
            const element = mapDiv.current;
            if(element){
                const newMap = new library.Map(element, {
                    zoom: 12,
                    center: currentLocation,
                    mapTypeId: google.maps.MapTypeId.HYBRID
                });
                google.maps.event.addListener(newMap, 'click', onClickMap);
                currentMap.current.map = newMap;
                currentMap.current.geoCoder = new google.maps.Geocoder();

                if(startLocation){
                    const position = new google.maps.LatLng(startLocation.lat, startLocation.lng);
                    currentMap.current.marker = new google.maps.Marker({
                        position,
                        animation: google.maps.Animation.DROP,
                        map: newMap
                    });
                    newMap.panTo(position);
                }
            }
        }
        if(currentLocation && !currentMap.current.map){
            loadMap();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation, startLocation]);

    const requestLocation = () => {
        Geolocation.requestLocation(result => {
            if(typeof result === "string"){
                setLocationError(result);
            } else {
                setCurrentLocation({lat: result.latitude, lng: result.longitude})
                setLocationError(undefined);
            }
        })
    }

    const onClickMap = (event: {latLng: google.maps.LatLng}) => {
        const { map, marker, geoCoder} = currentMap.current;
        if(!map){
            return;
        }
        if(marker){
            marker.setMap(null);
        }

        const position = event.latLng
        currentMap.current.marker = new google.maps.Marker({
            position,
            animation: google.maps.Animation.DROP,
            map
        });
        map.panTo(position);

        const location: PickedLocation = {
            latitude: position.lat(),
            longitude: position.lng()
        }

        if(props.geoCodeResult && geoCoder) {
            geoCoder.geocode({ location: position }, addresses => onGeocodeResult(addresses, location))
        } else{
            props.onSelect(location);
        }
    }
    const onGeocodeResult = (geocodedAddresses: google.maps.GeocoderResult[] | null, location: PickedLocation) => {
		if (!geocodedAddresses || geocodedAddresses.length === 0) {
            props.onSelect(location);
			return;
		}
		const geocodedAddress = geocodedAddresses[0];
		const parts = geocodedAddress.address_components;
		const streetNumber = parts.find((p) => p.types.some((type) => type === "street_number"))?.long_name ?? "";
		const route = parts.find((p) => p.types.some((type) => type === "route"))?.short_name ?? "";
		location.street = `${streetNumber} ${route}`;
		location.city = parts.find((p) => p.types.some((type) => type === "locality"))?.short_name;
		location.county = parts.find((p) => p.types.some((type) => type === "administrative_area_level_2"))?.short_name;
		location.state = parts.find((p) => p.types.some((type) => type === "administrative_area_level_1"))?.short_name;
		location.postalCode = parts.find((p) => p.types.some((type) => type === "postal_code"))?.long_name;
		
        props.onSelect(location);
	};

    if(locationError){
        return (
            <div style={{marginTop: 20, marginBottom: 20}}>
                <Typography>{locationError}</Typography>
                <Button variant="contained" color="primary" onClick={requestLocation}>Allow Location</Button>
            </div>
        )
    }

    return (
    <div style={{width: "100%"}}>
        <div id="manualAddressMap" style={{height: props.height}} ref={mapDiv}/>
    </div>
    );
}