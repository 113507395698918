import { Box, Button, Dialog, DialogContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTicketDetail } from "./DriverTicketDetailView";

export function EmailInvoiceConfirmation(props: { open: boolean; onClose: () => void; onConfirm: (email: string | null) => void }) {
	const context = useTicketDetail();
	const { ticket } = context;

	const firstEmail = ticket.customer.email;
	const secondEmail = ticket.customer.emailSecondary;

	const sendToOnFileEmails = () => {
		const emails = [];
		if (firstEmail) emails.push(firstEmail);
		if (secondEmail) emails.push(secondEmail);
		props.onConfirm(emails.join(";"));
	}

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<Box p={2}>
				<Typography variant="h6" gutterBottom>
					Email Invoice
				</Typography>
			</Box>
			<DialogContent>
				<Typography variant="body1">Would you like to email the invoice to {firstEmail}{secondEmail ? ` and ${secondEmail}` : ""}?</Typography>
			</DialogContent>
			<Box p={2}>
				<Grid container spacing={2}>
					<Grid item>
						<Button variant="outlined" color="secondary" onClick={() => props.onConfirm(null)}>
							No
						</Button>
					</Grid>
					<Grid item>
						<Button variant="contained" color="primary" onClick={sendToOnFileEmails}>
							Yes
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
}