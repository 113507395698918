import { Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React from "react";
import { ButtonLink } from "../../../components/ButtonLink";
import { DisplayNumber } from "../../../components/DisplayNumber";
import { EditableField } from "../../../components/SubmitTextField";
import { Truck } from "../../../entities/routing/Truck";
import { VehicleInspectionList } from "../../../entities/routing/VehicleInspectionList";
import { useAlert } from "../../../hooks/useAlert";
import { TruckService } from "../../../services/routing/TruckService";
import { VehicleInspectionListService } from "../../../services/routing/VehicleInspectionListService";

export function TruckDetailCard(props: { truck: Truck; onUpdate: (truck: Truck) => void }) {
    const { truck } = props;

	return (
		<Card>
            <CardHeader title="Truck information" />
			<CardContent style={{paddingTop: 0}}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Grid container alignItems="center" justify="space-between" spacing={1}>
							<Grid item>
								<EditableField<Truck>
									type="text"
									label="Name"
									value={truck.name}
									update={{
										request: (name) => TruckService.update({ id: truck.id, name: name }),
										onSuccess: props.onUpdate,
										successMessage: "Name updated",
										required: true,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography variant="h6">Name:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography variant="h6">{truck.name}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item>
								<Grid item>
									<Grid container wrap="nowrap" alignItems="center" spacing={1}>
										<Grid item>
											<Typography variant="h6">Type:</Typography>
										</Grid>
										<Grid item>
											<Typography variant="h6">{truck.type}</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={1} alignItems="center" alignContent="center">
							<Grid item xs={12}>
								<Typography style={{ fontWeight: "lighter", fontSize: 20 }} color="textSecondary">
									{truck.type} Details
								</Typography>
							</Grid>
							<Grid item sm={4} xs={6}>
								<EditableField<Truck>
									type="number"
									value={truck.capacity}
									size="small"
									label="Capacity"
									update={{
										request: (capacity) => TruckService.update({ id: truck.id, capacity }),
										onSuccess: props.onUpdate,
										successMessage: "Truck Capacity updated",
										required: false,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography>Capacity:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography>
													{truck.capacity ? (
														<>
															<DisplayNumber value={truck.capacity} /> Gallons
														</>
													) : (
														<NotSet />
													)}
												</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={1} alignItems="center" alignContent="center">
							<Grid item xs={12}>
								<Typography style={{ fontWeight: "lighter", fontSize: 20 }} color="textSecondary">
									Truck Description
								</Typography>
							</Grid>
							<Grid item sm={4} xs={6}>
								<EditableField<Truck>
									type="text"
									value={truck.make}
									size="small"
									label="Make"
									update={{
										request: (make) => TruckService.update({ id: truck.id, make }),
										onSuccess: props.onUpdate,
										successMessage: "Truck Make updated",
										required: false,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography>Make:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography>{truck.make ?? <NotSet />}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item sm={4} xs={6}>
								<EditableField<Truck>
									type="text"
									value={truck.model}
									size="small"
									label="Model"
									update={{
										request: (model) => TruckService.update({ id: truck.id, model }),
										onSuccess: props.onUpdate,
										successMessage: "Truck Model updated",
										required: false,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography>Model:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography>{truck.model ?? <NotSet />}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item sm={4} xs={6}>
								<EditableField<Truck>
									type="text"
									label="Year"
									size="small"
									value={truck.year}
									update={{
										request: (year) => TruckService.update({ id: truck.id, year }),
										onSuccess: props.onUpdate,
										successMessage: "Truck Year updated",
										required: false,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography>Year:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography>{truck.year ?? <NotSet />}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item sm={4} xs={6}>
								<EditableField<Truck>
									type="text"
									size="small"
									label="Color"
									value={truck.color}
									update={{
										request: (color) => TruckService.update({ id: truck.id, color }),
										onSuccess: props.onUpdate,
										successMessage: "Truck Color updated",
										required: false,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography>Color:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography>{truck.color ?? <NotSet />}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={1} alignItems="center" alignContent="center">
							<Grid item xs={12}>
								<Typography style={{ fontWeight: "lighter", fontSize: 20 }} color="textSecondary">
									Identifiers
								</Typography>
							</Grid>
							<Grid item sm={4} xs={6}>
								<EditableField<Truck>
									type="text"
									label="License Plate"
									size="small"
									value={truck.licensePlate}
									update={{
										request: (licensePlate) => TruckService.update({ id: truck.id, licensePlate }),
										onSuccess: props.onUpdate,
										successMessage: "License Plate updated",
										required: false,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography>License Plate:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography>{truck.licensePlate ?? <NotSet />}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item sm={4} xs={6}>
								<EditableField<Truck>
									type="text"
									label="VIN"
									size="small"
									value={truck.vin}
									update={{
										request: (vin) => TruckService.update({ id: truck.id, vin }),
										onSuccess: props.onUpdate,
										successMessage: "Truck VIN updated",
										required: false,
									}}
									view={(onEdit) => (
										<Grid container wrap="nowrap" alignItems="center" spacing={1}>
											<Grid item>
												<ButtonLink onClick={onEdit}>
													<Typography>VIN #:</Typography>
												</ButtonLink>
											</Grid>
											<Grid item>
												<Typography>{truck.vin ?? <NotSet />}</Typography>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<TruckInspectionList truck={truck} onUpdate={props.onUpdate} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}



function NotSet() {
	return <Typography color="textSecondary">Not Set</Typography>;
}

function TruckInspectionList(props: { truck: Truck, onUpdate: (truck: Truck) => void }) {
	const { truck } = props;
	const alert = useAlert();

	const [edit, setEdit] = React.useState(false);
	const [inspectionLists, setInspectionLists] = React.useState<VehicleInspectionList[]>();

	const [disabled, setDisabled] = React.useState(false);

	const inspectionList = inspectionLists?.find((list) => list.id === truck.inspectionListId);

	React.useEffect(() => {
		async function fetchInspections() {
			const result = await VehicleInspectionListService.getAll();
			if (result.success) {
				setInspectionLists(result.data);
			} else {
				alert.serverError(result);
			}
		}
		fetchInspections();
	}, [alert]);

	const onSelect = async (id: string) => {
		const list = inspectionLists?.find((list) => list.id === id);
		setDisabled(true);
		const result = await TruckService.assignInspectionList({ truckId: truck.id, inspectionListId: list?.id ?? null });
		setDisabled(false);
		if (result.success) {
			setEdit(false);
			alert.success("Inspection List Assigned");
			props.onUpdate(result.data);
		} else if(result.validation){
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	}

	return (
		<Grid container spacing={1} alignItems="center" alignContent="center">
			<Grid item xs={12}>
				<Typography style={{ fontWeight: "lighter", fontSize: 20 }} color="textSecondary">
					Inspection List
				</Typography>
			</Grid>
			<Grid item sm={4} xs={6}>
				{inspectionLists == null && (
					<>
						<Grid container wrap="nowrap" alignItems="center" spacing={1}>
							<Grid item>
								<Typography>Assigned List:</Typography>
							</Grid>
							<Grid item>
								<Typography>Loading...</Typography>
							</Grid>
						</Grid>
					</>
				)}
				{inspectionLists != null && !edit && (
					<Grid container wrap="nowrap" alignItems="center" spacing={1}>
					<Grid item>
						<ButtonLink onClick={() => setEdit(true)}>
							<Typography>Assigned List:</Typography>
						</ButtonLink>
					</Grid>
					<Grid item>
						<Typography>{inspectionList?.name ?? <NotSet/>}</Typography>
					</Grid>
				</Grid>
				)}
				{inspectionLists != null && edit && (
					<TextField select variant="outlined" fullWidth label="Assigned List" value={inspectionList?.id ?? "None"} onChange={(e) => onSelect(e.target.value as string)} disabled={disabled}>
						<MenuItem value="None">None</MenuItem>
						{inspectionLists.map((list) => (
							<MenuItem key={list.id} value={list.id}>
								{list.name}
							</MenuItem>
						))}
					</TextField>
				)}
			</Grid>
		</Grid>
	);
}
