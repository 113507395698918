
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import React, { PropsWithChildren, useState } from "react";
import { Modify } from "../../utility/modifyType";

export type TableSortOrder = "asc" | "desc";

interface SortableTableHeaderContext {
  order: TableSortOrder;
  setOrder: (order: TableSortOrder) => void;
  orderBy: string | null;
  setOrderBy: (orderBy: string) => void;
}

const SortableTableHeaderReactContext = React.createContext<SortableTableHeaderContext>({} as SortableTableHeaderContext);

interface SortableTableHeaderProps {
  orderBy?: string | null;
  order?: TableSortOrder;
}


export function SortableTableHeader(props: PropsWithChildren<SortableTableHeaderProps>) {  
  const [order, setOrder] = useState<TableSortOrder>(props.order ?? "desc");
  const [orderBy, setOrderBy] = useState<string | null>(props.orderBy ?? null);

  return (
    <TableHead>
      <TableRow>
        <SortableTableHeaderReactContext.Provider value={{order, setOrder, orderBy, setOrderBy}}>
          {props.children}
        </SortableTableHeaderReactContext.Provider>
      </TableRow>
    </TableHead>
  );
}


interface TableHeaderProps {
  align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  defaultSortOrder?: TableSortOrder;
  onSort?: undefined;
  property?: undefined;
}


type SortableHeaderProps = Modify<TableHeaderProps, {
  onSort: (property: string, order: TableSortOrder) => void;
  property: string;
}>;



export function TableHeader(props: PropsWithChildren<SortableHeaderProps | TableHeaderProps>) {
  const { property, align } = props;
  const context = React.useContext(SortableTableHeaderReactContext);
  const { order, orderBy: orderByProperty } = context;

  const switchOrder = (curOrder : TableSortOrder) => (curOrder === "asc" ? "desc" : "asc");

  function nextDirection(): TableSortOrder{
    if (property && orderByProperty !== property) 
      return props.defaultSortOrder || "asc";

    return switchOrder(order);
  }
  
  function onSort() {
    if (!property || !props.onSort) return; 

    const dir = nextDirection();
    props.onSort(property, dir);
    context.setOrder(dir);
    context.setOrderBy(property);
  }

  return (
    <TableCell
      align={align}
      sortDirection={orderByProperty === property ? order : false}
    >
      {
        props.onSort ? 
        <TableSortLabel
          active={orderByProperty === property}
          direction={orderByProperty === property ? order : undefined}
          onClick={onSort}
        >
          {props.children}
        </TableSortLabel>
        :
        props.children
      }
    </TableCell>
  );
}
