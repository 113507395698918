import React from "react";
import { PaymentTerms } from "../../../entities/billing/PaymentTerms";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { useProductCatalog } from "../../../providers/ProductCatalogProvider";
import { ProductLine } from "../../../entities/products/ProductLine";
import { ProductListing } from "../../../entities/products/ProductListing";
import { Autocomplete } from "@material-ui/lab";
import { PercentTextField } from "../../../components/PercentTextField";
import { PriceTextField } from "../../../components/PriceTextField";
import { NumberTextField } from "../../../components/NumberTextField";
import { useAlert } from "../../../hooks/useAlert";
import { PaymentTermsService } from "../../../services/billing/PaymentTermsService";

interface Props {
    paymentTerms: PaymentTerms;
	open: boolean;
	onClose: () => void;
	onAdd: (paymentTerms: PaymentTerms) => void;
}

export function AddOverdueFeeDialog(props: Props) {
	const { productCatalog } = useProductCatalog();
    const alert = useAlert();

	const [selectedProductLine, setSelectedProductLine] = React.useState<ProductLine | null>(null);
	const [selectedProductListing, setSelectedProductListing] = React.useState<ProductListing | null>(null);

	const [name, setName] = React.useState<string | null>(null);
	const [feePercentage, setFeePercentage] = React.useState<number | null>(null);
	const [feeAmount, setFeeAmount] = React.useState<number | null>(null);
    const [minimumFeeAmount, setMinimumFeeAmount] = React.useState<number | null>(null);
	const [daysToApply, setDaysToApply] = React.useState<number | null>(30);
    const [disabled, setDisabled] = React.useState(false);

	const selectableProductLines = productCatalog.productLines;
	const selectableProductListings = selectedProductLine?.availableListings ?? [];

	const onSelectProductLine = (event: React.ChangeEvent<{ value: unknown }>) => {
		const productLineId = event.target.value as string;
		const productLine = productCatalog.productLines.find((x) => x.id === productLineId);
		setSelectedProductLine(productLine ?? null);
	};

	const onSelectProductListing = (_: React.ChangeEvent<{}>, value: ProductListing | null) => {
		setSelectedProductListing(value);
		if (value) {
			setName(value.name);
		}
	};



    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if(selectedProductListing == null) {
            alert.error("Please select a product");
            return;
        }
        if(daysToApply == null) {
            alert.error("Please enter the number of days to re-apply the fee");
            return;
        }
        setDisabled(true);
        const result = await PaymentTermsService.addOverdueFee({
            paymentTermsId: props.paymentTerms.id,
            productListingId: selectedProductListing.id,
            name: name ?? "",
            percentFee: feePercentage,
            fixedFee: feeAmount,
            minimumFeeAmount: minimumFeeAmount,
            daysToApply: daysToApply
        });
        setDisabled(false);
        if(result.success) {
            alert.success("Overdue Fee Added");
            props.onAdd(result.data);
            props.onClose();
        } else if(result.validation) {
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    }


	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
            <form onSubmit={onSubmit}>
			<DialogTitle>Overdue Fee</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="body1">Select a the product to associate with this overdue fee</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item>
								<TextField
									label="Product Line"
									select
									variant="outlined"
									style={{ minWidth: 200 }}
									value={selectedProductLine?.id ?? "None"}
									onChange={onSelectProductLine}
								>
									<MenuItem value="None">Select Product Line</MenuItem>
									{selectableProductLines.map((productLine) => (
										<MenuItem key={productLine.id} value={productLine.id}>
											{productLine.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item>
								{selectedProductLine == null && (
									<TextField label="Product" select variant="outlined" style={{ minWidth: 250 }} disabled>
										<MenuItem value="None">Select Product</MenuItem>
									</TextField>
								)}
								{selectedProductLine != null && (
									<Autocomplete
										options={selectableProductListings}
										getOptionLabel={(option) => option.name}
										onChange={onSelectProductListing}
										renderInput={(params) => <TextField {...params} label="Product Line" variant="outlined" style={{ minWidth: 250 }} />}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">What name should the fee to have when applied to an invoice?</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField label="Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">Fees can be a percentage of the outstanding invoice balance and/or a fixed fee</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item>
								<PercentTextField label="Fee Percentage" variant="outlined" value={feePercentage} onPercentChange={(percent) => setFeePercentage(percent)} />
							</Grid>
							<Grid item>
								<PriceTextField label="Fee Amount" variant="outlined" value={feeAmount} onPriceChanged={(amount) => setFeeAmount(amount)} />
							</Grid>
                            <Grid item>
								<PriceTextField label="Minimum Fee Amount" variant="outlined" value={minimumFeeAmount} onPriceChanged={(amount) => setMinimumFeeAmount(amount)} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">How many days should pass before the fee is applied again?</Typography>
					</Grid>
					<Grid item xs={12}>
						<NumberTextField
							label="Days to Apply"
							variant="outlined"
							value={daysToApply}
							onNumberChange={(days) => setDaysToApply(days)}
							helperText="If set to 0, it will only apply once"
						/>
					</Grid>
				</Grid>
			</DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="outlined" color="secondary" disabled={disabled}>
                    Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={disabled}>
                    Add Fee
                </Button>
            </DialogActions>
            </form>
		</Dialog>
	);
}
