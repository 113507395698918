import { deserializePaymentReversalTransaction } from "../../entities/accounting/PaymentReversalTransaction";
import { deserializePaymentTransaction, deserializePaymentTransactions } from "../../entities/accounting/PaymentTransaction";
import { deserializeSalesTransaction } from "../../entities/accounting/SalesTransaction";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface SearchPaymentTransactionsRequest {
	type: "all" | "check" | "cash";
	customerCode?: string;
	customerId?: string;
	amount?: number;
	checkNumber?: string;
	maxResults?: number;
}

interface AllocatePaymentRequest {
	paymentTransactionId: string;
	allocations: { invoiceId: string; amountApplied: number }[];
}

interface ReversePaymentRequest {
	paymentTransactionId: string;
	reason: string | null;
	isChargeback: boolean;
}

export const TransactionService = {
	getPaymentTransaction: (id: string) => WebClient.GetOptional(`${apiServer}/api/transactions/payments/${id}`, deserializePaymentTransaction),
	getSalesTransaction: (id: string) => WebClient.GetOptional(`${apiServer}/api/transactions/sales/${id}`, deserializeSalesTransaction),
	searchPaymentTransactions: (request: SearchPaymentTransactionsRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/transactions/search/payments`, request, deserializePaymentTransactions),
	getPaymentReversalTransaction: (id: string) => WebClient.GetOptional(`${apiServer}/api/transactions/payment-reversals/${id}`, deserializePaymentReversalTransaction),
	allocatePayment: (request: AllocatePaymentRequest) =>
		WebClient.Put.Validated(`${apiServer}/api/transactions/payments/${request.paymentTransactionId}`, request, deserializePaymentTransaction),
	reversePaymentTransaction: (request: ReversePaymentRequest) =>
		WebClient.Post.Validated(`${apiServer}/api/transactions/payments/reversal`, request, deserializePaymentTransaction),
	downloadReceipt: (paymentTransactionId: string, fileName: string) =>
		WebClient.Download.Get(`${apiServer}/api/transactions/payments/${paymentTransactionId}/receipt`, fileName, "application/pdf"),
	emailReceipt: (request: {paymentTransactionId: string, email: string}) =>
		WebClient.Post.Validated<boolean>(`${apiServer}/api/transactions/payments/email-receipt`, request),
};
