import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { TaxRule } from "../../../entities/accounting/TaxRule";
import { routes } from "../../../routes";
import { TaxRuleService } from "../../../services/accounting/TaxRuleService";
import { NotFoundResult, ServerError } from "../../../services/server/WebClient";
import { TaxRuleDetailView } from "./TaxRuleDetailView";
import { TaxRuleListView } from "./TaxRuleListView";

export function TaxRulePage() {
	const [taxRules, setTaxRules] = React.useState<TaxRule[]>();
	const [serverError, setServerError] = React.useState<ServerError | NotFoundResult>();

	useEffect(() => {
		async function fetchTaxRules() {
			const result = await TaxRuleService.getAll();
			if (result.success) {
				setTaxRules(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchTaxRules();
	}, []);

	if (serverError) return <ServerErrorView serverError={serverError} />;
	if (!taxRules) return <OverlayLoadingScreen />;

	return (
		<TaxRulePageProvider taxRules={taxRules} setTaxRules={setTaxRules}>
			<TaxRulePageRouter />
		</TaxRulePageProvider>
	);
}

interface TaxRulePageContext {
	taxRules: TaxRule[];
	selectedTaxRule: TaxRule | undefined;
	addTaxRule: (taxRule: TaxRule) => void;
	updateTaxRule: (taxRule: TaxRule) => void;
}

const TaxRulePageReactContext = React.createContext<TaxRulePageContext>({} as TaxRulePageContext);
export const useTaxRulePage = () => React.useContext(TaxRulePageReactContext);

function TaxRulePageProvider(props: PropsWithChildren<{ taxRules: TaxRule[]; setTaxRules: (taxRules: TaxRule[]) => void }>) {
	const { taxRules, setTaxRules } = props;
	const params = useParams<{ taxRuleId: string | undefined }>();
	const taxRuleId = isNaN(Number(params.taxRuleId)) ? undefined : Number(params.taxRuleId);

	const selectedTaxRule = useMemo(() => taxRules.find((u) => u.id === taxRuleId), [taxRules, taxRuleId]);

	const addTaxRule = (taxRule: TaxRule) => setTaxRules([...taxRules, taxRule]);
	const updateTaxRule = (taxRule: TaxRule) => setTaxRules(taxRules.map((u) => (u.id === taxRule.id ? taxRule : u)));

	return <TaxRulePageReactContext.Provider value={{ taxRules, selectedTaxRule, addTaxRule, updateTaxRule }}>{props.children}</TaxRulePageReactContext.Provider>;
}

function TaxRulePageRouter() {
	const { selectedTaxRule } = useTaxRulePage();
	return (
		<Switch>
			{selectedTaxRule && (
				<Route path={routes.business.taxRuleDetailPage}>
					<TaxRuleDetailView taxRule={selectedTaxRule} />
				</Route>
			)}
			<Route path={routes.business.taxRulesPage}>
				<TaxRuleListView />
			</Route>
		</Switch>
	);
}
