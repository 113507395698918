import React, { useMemo } from "react";
import { IntegrationOption, IntegrationType } from "../../entities/auth/IntegrationConfiguration";
import { NotFoundResult, ServerError } from "../../services/server/WebClient";
import { CustomerTankMonitor, MonitorType } from "../../entities/routing/TankMonitor";
import { TankMonitorService } from "../../services/routing/TankMonitorService";
import { IntegrationService } from "../../services/auth/IntegrationService";
import { ServerErrorView } from "../../components/ServerErrorView";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { Grid, List, ListItem, Paper, Typography } from "@material-ui/core";
import { GridGrow } from "../../components/GridGrow";
import { DispatchProMonitorsListView } from "./DispatchProMonitorsListView";
import { OtodataDevicesListView } from "./OtodataDevicesListView";
import { Route, Switch, useHistory } from "react-router-dom";
import { routes } from "../../routes";

export function TankMonitorsPage() {
	const history = useHistory();
	const [integrations, setIntegrations] = React.useState<IntegrationOption[]>();
	const [customerMonitors, setCustomerMonitors] = React.useState<CustomerTankMonitor[]>();
	const [serverError, setServerError] = React.useState<ServerError | NotFoundResult>();

	const otodataEnabled = useMemo(() => integrations?.some((i) => i.type === IntegrationType.Otodata && i.configuration != null), [integrations]);
	const assignedOtodataDeviceIds = useMemo(
		() => new Set(customerMonitors?.filter((m) => m.type === MonitorType.Otodata).map((m) => Number(m.externalId)) ?? []),
		[customerMonitors]
	);

	React.useEffect(() => {
		async function fetch() {
			const result = await TankMonitorService.getCustomerMonitors();
			if (result.success) {
				setCustomerMonitors(result.data);
			} else {
				setServerError(result);
			}
		}
		fetch();
	}, []);

	React.useEffect(() => {
		async function fetch() {
			const result = await IntegrationService.getIntegrationOptions();
			if (result.success) {
				setIntegrations(result.data);
			} else {
				setServerError(result);
			}
		}
		fetch();
	}, []);

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!customerMonitors || !integrations) {
		return <OverlayLoadingScreen />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item>
				<Paper>
					<List>
						<ListItem button onClick={() => history.replace(routes.app.resolve.tankMonitorDispatchProListPage())}>
							Dispatch Pro
						</ListItem>
						{otodataEnabled && (
							<ListItem button onClick={() => history.replace(routes.app.resolve.tankMonitorOtodataListPage())}>
								Otodata
							</ListItem>
						)}
					</List>
				</Paper>
			</Grid>
			<GridGrow>
				<Switch>
					<Route exact path={routes.app.tankMonitorDispatchProListPage}>
						<DispatchProMonitorsListView customerMonitors={customerMonitors} />
					</Route>
					<Route exact path={routes.app.tankMonitorOtodataListPage}>
						<OtodataDevicesListView assignedOtodataDeviceIds={assignedOtodataDeviceIds} />
					</Route>
					<Route>
						<Typography>Select a tank monitor</Typography>
					</Route>
				</Switch>
			</GridGrow>
		</Grid>
	);
}
