import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles(() => ({
	boxIconButton: {
		minWidth: "unset",
		padding: 2,
	},
}));

export function BoxIconButton(props: React.PropsWithChildren<ButtonProps>) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { className, children, ...restButtonProps } = props;
	const classes = useStyles();
	return (
		<Button className={classes.boxIconButton} variant="contained" {...restButtonProps}>
			{children}
		</Button>
	);
}
