import { Modify } from "../../utility/modifyType";
import { SafeMoney, Money } from "../../utility/Money";

interface PaymentTransactionAllocation{
    invoiceId: string;
    invoiceShortId: string;
    invoicePaymentId: string;
    amountApplied: SafeMoney;
}

type SerializedPaymentTransactionAllocation = Modify<PaymentTransactionAllocation, {
    amountApplied: number;
}>;

function deserializePaymentTransactionAllocation(model: SerializedPaymentTransactionAllocation): PaymentTransactionAllocation {
    return {
        ...model,
        amountApplied: Money.fromDecimal(model.amountApplied),
    };
}

interface PaymentTransactionBase {
    id: string;
    description: string;
    created: Date;
    customerId: string;
    accountsReceivableAccountId: number;
    assetAccountId: number;
    amount: number;
    unallocatedAmount: number;
    allocations: PaymentTransactionAllocation[];
    reversalTransactionId: string | null;
}

interface PaymentTransactionCash extends PaymentTransactionBase {
    type: "Cash";
}

interface PaymentTransactionCheck extends PaymentTransactionBase {
    type: "Check";
    checkNumber: string;
}

interface PaymentTransactionCard extends PaymentTransactionBase {
    type: "Card";
    surchargeAmount: number | null;
    cardDescription: string;
    chargeTransactionId: string;
    paymentProcessor: string;
}

export type PaymentTransaction = PaymentTransactionCash | PaymentTransactionCheck | PaymentTransactionCard;

type SerializedPaymentTransaction = Modify<PaymentTransaction, {
    created: string;
    allocations: SerializedPaymentTransactionAllocation[];
}>;


export function deserializePaymentTransaction(model: SerializedPaymentTransaction): PaymentTransaction {
    const base: PaymentTransactionBase = {
        ...model,
        created: new Date(model.created),
        allocations: model.allocations.map(deserializePaymentTransactionAllocation),
    };

    return base as PaymentTransaction;
}

export function deserializePaymentTransactions(serializedPaymentTransactions: SerializedPaymentTransaction[]): PaymentTransaction[] {
    return serializedPaymentTransactions.map(deserializePaymentTransaction);
}