import React, { PropsWithChildren, useMemo } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import OverlayLoadingScreen from "../../components/OverlayLoadingScreen";
import { ServerErrorView } from "../../components/ServerErrorView";
import { Driver } from "../../entities/routing/Driver";
import { DriverTicket } from "../../entities/routing/DriverTicket";
import { useDrivers } from "../../providers/DriverProvider";
import { routes } from "../../routes";
import { RouteDetailView } from "./RouteDetailView";
import { RouteListView } from "./RouteListView";
import { useOfflineTickets } from "../../providers/OfflineTicketProvider";
import { RouteStopTicketView } from "./route-stop-ticket/RouteStopTicketView";
import { Dialog, useTheme } from "@material-ui/core";

export function RoutePage() {
	const driverDataContext = useDrivers();
	const openTicketContext = useOfflineTickets();
	const { loadStatus: driverStatus, serverError: driversServerError } = driverDataContext;
	const { loadStatus: ticketStatus, serverError: ticketServerError } = openTicketContext;

	if (driversServerError) {
		return <ServerErrorView serverError={driversServerError} />;
	}

	if (ticketServerError) {
		return <ServerErrorView serverError={ticketServerError} />;
	}

	if (driverStatus === "loading" || ticketStatus === "loading") {
		return <OverlayLoadingScreen />;
	}

	return (
		<RoutePageContextProvider>
			<RoutePageRouter />
		</RoutePageContextProvider>
	);
}

interface RoutePageContext {
	drivers: Driver[];
	selectedDriver: Driver | undefined;
	selectedDriverTicket: DriverTicket | undefined;
	selectDriver: (driver: Driver) => void;
	selectTicket: (driverTicket: DriverTicket) => void;
}

const RoutePageReactContext = React.createContext<RoutePageContext>({} as RoutePageContext);

export function useRoutePage() {
	return React.useContext(RoutePageReactContext);
}

function RoutePageContextProvider(props: PropsWithChildren<{}>) {
	const params = useParams<{ driverId: string | undefined; ticketId: string | undefined }>();
	const history = useHistory();
	const driverContext = useDrivers();
	const { drivers } = driverContext;
	const openTicketContext = useOfflineTickets();
	const { openTickets } = openTicketContext;
	const routeTickets = useMemo(() => openTickets.filter((t) => t.driverId !== undefined && t.timeOfDelivery == null), [openTickets]);

	const selectedDriver = useMemo(() => drivers.find((o) => o.id === params.driverId), [drivers, params.driverId]);
	const selectedDriverTicket = useMemo(() => routeTickets.find((t) => t.id === params.ticketId), [routeTickets, params.ticketId]);

	const selectDriver = (driver: Driver) => {
		history.push(routes.app.resolve.driverRoutePage(driver.id));
	};

	const selectStop = (driverTicket: DriverTicket) => {
		history.push(routes.app.resolve.routeTicketPage(driverTicket.id));
	};

	return (
		<RoutePageReactContext.Provider
			value={{
				drivers,
				selectedDriver,
				selectedDriverTicket,
				selectDriver,
				selectTicket: selectStop,
			}}
		>
			{props.children}
		</RoutePageReactContext.Provider>
	);
}

function RoutePageRouter() {
	const { selectedDriver, selectedDriverTicket } = useRoutePage();

	const theme = useTheme();

	return (
		<Switch>
			<Route path={routes.app.routeListPage} exact>
				<RouteListView />
			</Route>
			{selectedDriver && (
				<Route path={routes.app.driverRoutePage} exact>
					<RouteDetailView selectedDriver={selectedDriver} />
				</Route>
			)}
			{selectedDriverTicket && (
				<Route path={routes.app.routeTicketPage} exact>
					<Dialog open={true} fullScreen PaperProps={{ style: { backgroundColor: theme.palette.background.default } }}>
						<RouteStopTicketView driverTicket={selectedDriverTicket} />
					</Dialog>
				</Route>
			)}
			<Redirect to={routes.app.routeListPage} />
		</Switch>
	);
}
