import {
	Box,
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { DisplayNumber } from "../../../components/DisplayNumber";
import { GridGrow } from "../../../components/GridGrow";
import { LinkTableRow } from "../../../components/LinkTableRow";
import { NumberTextField } from "../../../components/NumberTextField";
import OverlayLoadingScreen from "../../../components/OverlayLoadingScreen";
import { PageTitle } from "../../../components/PageTitle";
import { ServerErrorView } from "../../../components/ServerErrorView";
import { Truck, TruckType } from "../../../entities/routing/Truck";
import { VehicleInspectionList } from "../../../entities/routing/VehicleInspectionList";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { TruckService } from "../../../services/routing/TruckService";
import { VehicleInspectionListService } from "../../../services/routing/VehicleInspectionListService";
import { ServerError } from "../../../services/server/WebClient";

export function TrucksPage() {
	const [trucks, setTrucks] = React.useState<Truck[]>();
	const [inspectionLists, setInspectionLists] = React.useState<VehicleInspectionList[]>();
	const [serverError, setServerError] = React.useState<ServerError>();

	React.useEffect(() => {
		async function fetchTrucks() {
			const result = await TruckService.getAll();
			if (result.success) {
				setTrucks(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchTrucks();
	}, []);

	React.useEffect(() => {
		async function fetchInspectionLists() {
			const result = await VehicleInspectionListService.getAll();
			if (result.success) {
				setInspectionLists(result.data);
			} else {
				setServerError(result);
			}
		}
		fetchInspectionLists();
	}, []);

	if (serverError) {
		return <ServerErrorView serverError={serverError} />;
	}

	if (!trucks || !inspectionLists) {
		return <OverlayLoadingScreen />;
	}

	return <TruckListView trucks={trucks} inspectionLists={inspectionLists} />;
}

function TruckListView(props: { trucks: Truck[]; inspectionLists: VehicleInspectionList[] }) {
	const { trucks, inspectionLists } = props;

	const [showAddTruckDialog, setShowAddTruckDialog] = React.useState(false);
	const [showAddListDialog, setShowAddListDialog] = React.useState(false);

	return (
		<Grid container spacing={2}>
			<PageTitle title="Trucks" />
			<AddTruckDialog open={showAddTruckDialog} onClose={() => setShowAddTruckDialog(false)} />
			<AddListDialog open={showAddListDialog} onClose={() => setShowAddListDialog(false)} />
			<Grid item lg={6} md={7} sm={10} xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item>
								<Typography variant="h5">Trucks</Typography>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button variant="contained" color="primary" onClick={() => setShowAddTruckDialog(true)}>
									Add Truck
								</Button>
							</Grid>
						</Grid>
					</CardContent>
					{trucks.length === 0 ? (
						<Box mt={2} mb={2}>
							<Typography variant="h6" align="center">
								No trucks found
							</Typography>
						</Box>
					) : (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Capacity</TableCell>
									<TableCell>Status</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{trucks.map((truck) => (
									<LinkTableRow key={truck.id} to={routes.business.resolve.truckDetailPage(truck.id)}>
										<TableCell>{truck.name}</TableCell>
										<TableCell>{truck.type}</TableCell>
										<TableCell>
											{truck.capacity ? (
												<>
													<DisplayNumber value={truck.capacity} /> Gallons
												</>
											) : (
												"None"
											)}
										</TableCell>
										<TableCell>{truck.inService ? "In Service" : "Retired"}</TableCell>
									</LinkTableRow>
								))}
							</TableBody>
						</Table>
					)}
				</Card>
			</Grid>
			<Grid item lg={6} md={5} sm={10} xs={12}>
				<Card>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item>
								<Typography variant="h5">Inspection Lists</Typography>
							</Grid>
							<GridGrow />
							<Grid item>
								<Button variant="contained" color="primary" onClick={() => setShowAddListDialog(true)}>
									Add List
								</Button>
							</Grid>
						</Grid>
					</CardContent>
					{inspectionLists.length === 0 && (
						<Box mt={2} mb={2}>
							<Typography variant="h6" align="center">
								No vehicle inspection lists found
							</Typography>
						</Box>
					)}
                    {inspectionLists.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Trucks</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {inspectionLists.map((list) => (
                                    <LinkTableRow key={list.id} to={routes.business.resolve.inspectionListDetailPage(list.id)}>
                                        <TableCell>{list.name}</TableCell>
                                        <TableCell>
                                            {list.assignedTrucks.map(t => t.name).join(", ")}
                                            {
                                                list.assignedTrucks.length === 0 && (
                                                    <>No trucks assigned</>
                                                )
                                            }
                                        </TableCell>
                                    </LinkTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
				</Card>
			</Grid>
		</Grid>
	);
}

function AddTruckDialog(props: { open: boolean; onClose: () => void }) {
	const { open, onClose } = props;
	const alert = useAlert();
	const history = useHistory();

	const [name, setName] = React.useState("");
	const [type, setType] = React.useState<TruckType>("Bobtail");
	const [capacity, setCapacity] = React.useState<number | null>(null);

	const [disabled, setDisabled] = React.useState(false);

	const addTruck = async () => {
		setDisabled(true);
		const result = await TruckService.create({ name, type, capacity });
		setDisabled(false);
		if (result.success) {
			alert.success("Truck added successfully");
			history.push(routes.business.resolve.truckDetailPage(result.data.id));
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Add Truck</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField label="Name" required variant="outlined" value={name} onChange={(e) => setName(e.target.value)} fullWidth disabled={disabled} />
					</Grid>
					<Grid item xs={12}>
						<TextField label="Type" variant="outlined" select value={type} onChange={(e) => setType(e.target.value as TruckType)} fullWidth disabled={disabled}>
							<MenuItem value="Bobtail">Bobtail</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<NumberTextField
							label="Capacity"
							required
							helperText="Gallons"
							variant="outlined"
							value={capacity}
							onNumberChange={setCapacity}
							fullWidth
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined" color="secondary" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={addTruck} variant="contained" color="primary" disabled={disabled}>
					Add Truck
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function AddListDialog(props: { open: boolean; onClose: () => void }) {
	const { open, onClose } = props;
	const alert = useAlert();
	const history = useHistory();

	const [name, setName] = React.useState("");

	const [disabled, setDisabled] = React.useState(false);

	const addList = async () => {
		setDisabled(true);
		const result = await VehicleInspectionListService.create(name);
		setDisabled(false);
		if (result.success) {
			alert.success("List added successfully");
			history.push(routes.business.resolve.inspectionListDetailPage(result.data.id));
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogTitle>Add List</DialogTitle>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					addList();
				}}
			>
				<DialogContent>
					<DialogContentText>Create a new vehicle inspection list.</DialogContentText>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField label="List Name" required variant="outlined" value={name} onChange={(e) => setName(e.target.value)} fullWidth disabled={disabled} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} variant="outlined" color="secondary" disabled={disabled}>
						Cancel
					</Button>
					<Button variant="contained" color="primary" type="submit" disabled={disabled}>
						Add List
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
